/**
 * SelectMimicrySet component
 *
 * @author: exode <hello@exode.ru>
 */

import React, { ReactElement } from 'react';

import { SelectMimicryOptions, UtilSelectMimicryPageStore } from '@/pages/Utils/SelectMimicry/store';

import { If, Link } from '@/cutils';
import { RoutePathType } from '@/router/paths';

import { Icon20Dropdown } from '@vkontakte/icons';
import { SelectMimicry, SelectMimicryProps, Spinner } from '@exode.ru/vkui';

import { ModalCardProps } from '@/components/Atoms/ModalCard';


interface Props extends SelectMimicryProps {
    from: RoutePathType;
    title?: string;
    options?: SelectMimicryOptions[];
    parentComponent?: ReactElement;
    component?: ReactElement;
    selected?: string | string[] | number[];
    mode?: 'page' | 'modal';
    pageOnMobile?: boolean;
    loading?: boolean;
    rawModalCardProps?: ModalCardProps;
}


const SelectMimicrySet = (props: Props) => {

    const {
        title,
        from,
        options,
        parentComponent,
        component,
        selected,
        mode = 'page',
        pageOnMobile = false,
        loading = false,
        rawModalCardProps,
        ...rest
    } = props;

    const merge = () => {
        UtilSelectMimicryPageStore.merge({
            title,
            options,
            from,
            component,
            selected,
            rawModalCardProps,
        });
    };

    const after = loading ? <Spinner size="small"/> : <Icon20Dropdown/>;

    const viewComponent = parentComponent
        ? parentComponent
        : <SelectMimicry after={after} {...rest}>{selected}</SelectMimicry>;

    return (
        <div onClick={merge}>
            <If is={mode === 'page'}>
                <Link pushPage={{ id: '/utils/page', params: { from } }}>
                    {viewComponent}
                </Link>
            </If>

            <If is={pageOnMobile && mode === 'modal'}>
                <Link pushModal={{ id: 'select-modal' }} pushPageMobile={{ id: '/utils/page', params: { from } }}>
                    {viewComponent}
                </Link>
            </If>

            <If is={!pageOnMobile && mode === 'modal'}>
                <Link pushModal={{ id: 'select-modal' }}>
                    {viewComponent}
                </Link>
            </If>
        </div>
    );
};


export { SelectMimicrySet };
