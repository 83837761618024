/**
 * SchoolSubscription component
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { useSchoolListenUpdateSubscription } from '@/codegen/graphql';

import { handleSchoolUpdateSubscription } from './helper';


const SchoolSubscription = () => {

    const schoolId = window.exode.school?.id;

    if (!schoolId) {
        return <></>;
    }

    useSchoolListenUpdateSubscription({
        variables: { schoolId },
        onData: async ({ data: { data } }) => {
            if (data?.schoolListenUpdate) {
                handleSchoolUpdateSubscription(data.schoolListenUpdate);
            }
        },
    });

    return <></>;
};


export { SchoolSubscription };
