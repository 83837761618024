/**
 * NotificationSignal component
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { observer } from 'mobx-react';

import { ConfigStore } from '@/store/core/config';

import { If } from '@/cutils';
import { useI18n } from '@/hooks/core';
import { useNotificationRead } from '@/hooks/apollo';
import { NotificationStatus } from '@/codegen/graphql';

import { IconButton, Spinner } from '@exode.ru/vkui';
import { Icon16DoneCircle } from '@vkontakte/icons';
import { TextTooltip } from '@exode.ru/vkui/unstable';

import { Container } from './NotificationSignal.styled';


interface Props {
    notificationId: number;
    status: NotificationStatus;
    className?: string;
}


const NotificationSignal = observer((props: Props) => {

    const { notificationId, status } = props;

    const { t } = useI18n('components.Profile.Notification.Signal');

    const { readNotification, readNotificationLoading } = useNotificationRead(notificationId);

    const content = status === NotificationStatus.Unread
        ? <div className="dot"/>
        : <Icon16DoneCircle fill="var(--accent)"/>;

    return (
        <TextTooltip placement={ConfigStore.isDesktop ? 'bottom' : 'left'} text={t('markAsRead')}>
            <IconButton disabled={readNotificationLoading} onClick={readNotification} className={[
                props.className,
                status === NotificationStatus.Read ? 'pointer-events-none' : '',
                'flex justify-center items-center w-[32px] h-[32px] absolute right-3 m:rounded-full',
            ].join(' ')}>
                <Container>
                    <If is={readNotificationLoading}>
                        <Spinner className="text-accent" size="small"/>
                    </If>

                    <If is={!readNotificationLoading}>
                        <>{content}</>
                    </If>
                </Container>
            </IconButton>
        </TextTooltip>
    );
});


export { NotificationSignal };
