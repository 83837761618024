/**
 * UserInviteModal
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { EXODE_BIZ_SUPPORT_TG_URL, IS_MARKETPLACE } from '@/root/src/env';

import { useI18n } from '@/hooks/core';
import { Link, Modal } from '@/cutils';

import { Button, Title } from '@exode.ru/vkui';
import { Icon16Message, Icon28MoneyWadOutline, Icon28Rocket, Icon36Users } from '@vkontakte/icons';

import { InlineInfoCaptionText } from '@/components/Atoms/InlineInfoCaptionText';

import { UserInviteView } from '@/pages/Profile/Share/views/UserInviteView';


interface Props {
    id: string;
}


const UserInviteModal = (props: Props) => {

    const { t } = useI18n('modals.User.Invite');

    return (
        <Modal.Card id={props.id}
                    actionsLayout="vertical"
                    subheader={!IS_MARKETPLACE ? t('recommendAndGetBenefits') : t('toGetRewards')}
                    actions={!IS_MARKETPLACE
                        ? [
                            <Link toOuter={EXODE_BIZ_SUPPORT_TG_URL}>
                                <Button size="l" mode="commerce" before={<Icon28MoneyWadOutline/>}>
                                    {t('startEarnMoney')}
                                </Button>
                            </Link>,
                            <InlineInfoCaptionText className="m:px-3 mt-3"
                                                   icon={<Icon16Message/>}
                                                   text={t('startConversation')}/>,
                        ]
                        : undefined}
                    icon={(
                        <Modal.CardIcon icon={(
                            !IS_MARKETPLACE
                                ? <Icon28Rocket width={36} height={36} fill="var(--white)"/>
                                : <Icon36Users fill="var(--white)"/>
                        )}/>
                    )}
                    header={(
                        <Title className="modal-title" level="2" weight="3">
                            {!IS_MARKETPLACE ? t('partnerProgram') : t('invitingFriends')}
                        </Title>
                    )}>
            <Modal.Body isScrollable>
                <UserInviteView/>
            </Modal.Body>
        </Modal.Card>
    );
};


export { UserInviteModal };
