/**
 * TournamentCardSkeleton component
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { Responsive } from '@/cutils';

import { Skeleton } from '@mui/material';


const TournamentCardSkeleton = () => {
    return (
        <>
            <Responsive.Desktop>
                <div className="rounded-[14px] max-h-[77px] flex items-center p-3 mb-3 last:mb-0 bg-button-muted">
                    <Skeleton variant="circular" width={77} height={77}/>

                    <div className="flex justify-between items-center flex-1 ml-4">
                        <div>
                            <Skeleton variant="text" width={120} height={30}/>
                            <Skeleton variant="text" width={140} height={20}/>
                            <Skeleton variant="text" width={160} height={24}/>
                        </div>

                        <div className="flex flex-col items-end gap-5">
                            <Skeleton variant="text" width={60} height={30}/>

                            <div className="flex items-center">
                                <Skeleton variant="circular" width={20} height={20}/>
                                <Skeleton variant="circular" width={20} height={20}/>
                                <Skeleton variant="circular" width={20} height={20}/>
                                <Skeleton className="ml-2" variant="text" width={30} height={30}/>
                            </div>
                        </div>
                    </div>
                </div>
            </Responsive.Desktop>

            <Responsive.Mobile>
                <div className="rounded-[14px] flex items-center bg-button-muted p-3 mb-3">
                    <Skeleton variant="circular" width={77} height={77}/>

                    <div className="flex justify-between items-center flex-1 ml-4">
                        <div>
                            <Skeleton variant="text" width={120} height={30}/>
                            <Skeleton variant="text" width={80} height={20}/>
                            <Skeleton variant="text" width={100} height={24}/>
                        </div>

                        <div className="flex flex-col items-end gap-5">
                            <Skeleton variant="text" width={60} height={30}/>

                            <div className="flex items-center">
                                <Skeleton variant="circular" width={20} height={20}/>
                                <Skeleton variant="circular" width={20} height={20}/>
                                <Skeleton variant="circular" width={20} height={20}/>
                                <Skeleton className="ml-2" variant="text" width={30} height={30}/>
                            </div>
                        </div>
                    </div>
                </div>
            </Responsive.Mobile>
        </>
    );
};


export { TournamentCardSkeleton };
