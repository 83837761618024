/**
 * ProfileWalletDepositModal
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { useI18n } from '@/hooks/core';

import { Form, Formik } from 'formik';

import { Modal } from '@/cutils';

import { Icon56MoneyTransferOutline } from '@vkontakte/icons';
import { Button, FormItem, Input, Title } from '@exode.ru/vkui';


interface Props {
    id: string;
}


const ProfileWalletDepositModal = (props: Props) => {

    const { t } = useI18n('modals.Profile.Wallet.ProfileWalletDeposit');

    return (
        <Modal.Card id={props.id}
                    icon={<Modal.CardIcon icon={<Icon56MoneyTransferOutline className="text-white"/>}/>}
                    header={<Title className="modal-title" level="2" weight="3">{t('topUpOfBalance')}</Title>}>
            <Modal.Body isScrollable>
                <Formik enableReinitialize
                        validateOnBlur
                        validateOnChange
                        validateOnMount
                        initialValues={{ amount: '100' }}
                        onSubmit={(values) => {
                            console.log('[values]', values);
                        }}>
                    {({ handleChange, handleSubmit, values }) => (
                        <Form onSubmit={handleSubmit}>
                            <FormItem top={t('selectAmount')}>
                                <Input onChange={handleChange}
                                       autoFocus={true}
                                       type="text"
                                       name="amount"
                                       value={values.amount}
                                       inputMode="numeric"
                                       placeholder={t('fromToTillEx')}/>
                            </FormItem>

                            <FormItem>
                                <Button size="l" stretched type="submit">
                                    {t('topUp')}
                                </Button>
                            </FormItem>
                        </Form>
                    )}
                </Formik>
            </Modal.Body>
        </Modal.Card>
    );
};


export { ProfileWalletDepositModal };
