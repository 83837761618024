/**
 * TournamentMembersSubscription component
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import {
    TournamentMembersDocument,
    TournamentMembersQuery,
    useTournamentListenNewMembersSubscription,
} from '@/codegen/graphql';

import { tournamentMembersListConfig } from '@/pages/Tournament/Lobby/constants';


interface Props {
    tournamentId: number;
}


const TournamentMembersSubscription = (props: Props) => {

    const { tournamentId } = props;

    const variables = {
        ...tournamentMembersListConfig,
        tournamentId,
    };

    useTournamentListenNewMembersSubscription({
        variables: { tournamentId },
        onData: ({ data: { data }, client: { cache } }) => {
            if (!data) {
                return;
            }

            const { tournamentListenNewMember: newMember } = data;

            const cachedMembers = cache.readQuery<TournamentMembersQuery>({
                query: TournamentMembersDocument,
                variables,
            });

            if (!cachedMembers) {
                return console.warn('[Cache]: cachedMembers отсутствуют в кэше');
            }

            cache.modify({
                id: `TournamentEntity:${tournamentId}`,
                fields: {
                    countMembers: (value) => newMember.tournament.countMembers ?? value,
                },
            });

            cache.writeQuery<TournamentMembersQuery>({
                query: TournamentMembersDocument,
                variables,
                data: {
                    tournamentLobbyFindManyMembers: {
                        __typename: 'ListMemberTournamentOutput',
                        count: newMember.tournament.countMembers
                            ?? ((cachedMembers?.tournamentLobbyFindManyMembers.count ?? 1) + 1),
                        items: [
                            ...(cachedMembers?.tournamentLobbyFindManyMembers.items ?? []),
                            newMember,
                        ],
                    },
                },
            });
        },
    });

    return (<></>);
};


export { TournamentMembersSubscription };
