/**
 * ManageSchoolLegalDocModal
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { Page } from '@/pages/Core';
import { useI18n } from '@/hooks/core';
import { useStableModalParams } from '@/router/index';

import { Title } from '@exode.ru/vkui';
import { Graphql, If, Modal } from '@/cutils';
import { SellerOrganizationDocumentFindOneQuery } from '@/codegen/graphql';

import { LegalDocView } from '@/pages/Manage/School/Legal/views/LegalDocView';


interface Props {
    id: string;
}


const ManageSchoolLegalDocModal = (props: Props) => {

    const [ { documentId } ] = useStableModalParams();

    const { t } = useI18n('modals.Manage.School.LegalDocModal');

    return (
        <Modal.Card id={props.id} subheader={t('youCanDownloadTheDocumentsHere')} header={(
            <Title className="modal-title" level="2" weight="3">
                {documentId ? t('editingDocument') : t('uploadingDocument')}
            </Title>
        )}>
            <Modal.Body isScrollable className="p-3">
                <If is={!documentId}>
                    <LegalDocView/>
                </If>

                <If is={!!documentId}>
                    <SellerOrganizationDocumentFindOneQuery children={(result) => (
                        <>
                            <Graphql.Loading result={result}>
                                <Page.Spinner/>
                            </Graphql.Loading>

                            <Graphql.Success result={result}>
                                {({ sellerOrganizationDocumentFindOne: document }) => (
                                    <LegalDocView document={document}/>
                                )}
                            </Graphql.Success>

                            <Graphql.Error result={result}/>
                        </>
                    )} variables={{ documentId: +documentId }}/>
                </If>
            </Modal.Body>
        </Modal.Card>
    );
};


export { ManageSchoolLegalDocModal };
