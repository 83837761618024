/**
 * Core page constant file
 *
 * @author: exode <hello@exode.ru>
 */

export const pageRow = {
    classNames: {
        iframeVerticalCenter: 'd:flex d:items-center d:justify-center d:py-[30px] d:w-[400px] d:mx-auto',
        gradientBanner: [
            'bg-black bg-cover bg-center m:bg-left-top bg-no-repeat',
            'm:m-3 m:mb-0 m:!rounded-2xl overflow-hidden',
        ].join(' '),
    },
};
