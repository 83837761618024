/**
 * Use i18n
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import type { $Tuple } from 'react-i18next/helpers';
import i18n, { FlatNamespace, KeyPrefix } from 'i18next';
import { FallbackNs, useTranslation, UseTranslationOptions, UseTranslationResponse } from 'react-i18next';

import { SERVE_DOMAIN } from '@/root/src/env';

import { Language } from '@/codegen/graphql';
import { ConfigStore } from '@/store/core/config';

import { Storage } from '@/api/storage';
import { Translate } from '@/utils/Translate';
import { translateResources } from '@/libs/i18n/resources';
import { AppServeDomain, TranslateJson } from '@/shared/types';


/**
 * Default fallback language
 */
export const getFallbackLng: () => 'ru' | 'uz' = () => {
    switch (SERVE_DOMAIN) {
        case AppServeDomain.Ru:
            return 'ru';

        case AppServeDomain.Uz:
        case AppServeDomain.Biz:
            return 'uz';

        default:
            return 'ru';
    }
};

export const useI18n = <
    Ns extends FlatNamespace | $Tuple<FlatNamespace> | undefined = undefined,
    KPrefix extends KeyPrefix<FallbackNs<Ns>> = undefined,
>(
    ns?: Ns,
    options?: UseTranslationOptions<KPrefix>,
): UseTranslationResponse<FallbackNs<Ns>, KPrefix> => {
    return useTranslation(
        ns,
        {
            keyPrefix: ns && [ `${ns}`?.split('.')?.[0], `${ns}`.split('.').slice(1).join('.') ].join(':'),
            ...options,
        },
    );
};

/**
 * Alias for useI18n without parsing of auto-generating
 */
export const useI18nStatic = useI18n;

/**
 * Get translated
 * @param {Record<, any> | undefined | null} data
 */
export const getTranslated = (
    data: TranslateJson | undefined | null,
) => {
    return Translate.getTranslated(data, ConfigStore.language);
};

/**
 * Get saved or navigator language
 */
export const getLanguage: () => Language = () => {
    const saved = Storage.get('user:language');

    const systemLanguage = (navigator.language || (navigator as any).userLanguage).substring(0, 2);

    const language = _.capitalize(saved ? saved : (systemLanguage || getFallbackLng()));

    return (
        (translateResources as Record<any, any>)[language?.toLowerCase()]
            ? language
            : _.capitalize(getFallbackLng())
    ) as Language;
};

/**
 * Set language
 * @param {keyof typeof translateResources} language
 */
export const setLanguage = (language: Language) => {
    i18n.changeLanguage(language.toLowerCase());

    Storage.set('user:language', language);

    ConfigStore.merge({ language });
};
