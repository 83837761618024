/**
 * IconButton styled container
 *
 * @author: exode <hello@exode.ru>
 */

import styled from 'styled-components';

import { IconButtonProps } from '@/components/Atoms/IconButton';


export const Container = styled.div<IconButtonProps>`
  .IconButton {
    display: flex;
    justify-content: center;
    align-items: center;
    height: ${props => props.height + 'px'};
    width: ${props => props.width + 'px'};
    background: ${props => props.background || 'var(--white)'};

    use {
      fill: currentcolor;
      color: var(--accent);
    }
  }
`;
