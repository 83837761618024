/**
 * OtpInputWrapper styled container
 *
 * @author: exode <hello@exode.ru>
 */

import styled from 'styled-components';


export const Container = styled.div`
  input {
    background: var(--input_background) !important;
  }
`;
