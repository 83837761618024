/**
 * AvatarChip component
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { Container } from './AvatarChip.styled';


export interface AvatarChipProps {
    size: number;
    children?: React.ReactElement;
    top?: number;
    left?: number;
    className?: string;
}


const AvatarChip = (props: AvatarChipProps) => {

    const { children, className } = props;

    return (
        <Container {...props} className={className}>
            {children}
        </Container>
    );
};


export { AvatarChip };
