/**
 * Socket.io
 *
 * @author: exode <hello@exode.ru>
 */

import socketIOClient from 'socket.io-client';

import { ConfigStore } from '@/store/core/config';

import { wsUrl } from '@/api/constants';
import { gqlWsClient } from '@/api/graphql';
import { basicRequestHeaders } from '@/api/headers';

import { DocumentEvent } from '@/types/window';


/** Regular socket */
export const socket = socketIOClient(wsUrl, {
    auth: basicRequestHeaders(),
    transports: [ 'websocket' ],
});

export const reconnectSocket = () => {
    socket.auth = basicRequestHeaders();
    socket.disconnect().connect();

    gqlWsClient.terminate();
};

/**
 * Connect-disconnect management:
 */

const disconnected = {
    gql: false,
    sio: false,
};

/** Gql ws: */

gqlWsClient.on('connected', () => {
    console.log('Gql ws: connected');

    ConfigStore.setDisconnected('gql', false);

    document.dispatchEvent(
        new CustomEvent(DocumentEvent.WsConnected),
    );

    if (disconnected.gql) {
        document.dispatchEvent(
            new CustomEvent(DocumentEvent.WsConnectedAfterDisconnect),
        );

        disconnected.gql = false;
    }
});

gqlWsClient.on('closed', () => {
    console.log('Gql ws: disconnected');

    disconnected.gql = true;

    ConfigStore.setDisconnected('gql', true);

    document.dispatchEvent(
        new CustomEvent(DocumentEvent.WsDisconnected),
    );
});

gqlWsClient.on('error', (error) => {
    console.error('Gql ws: error', error);
});

/** Socket.io: */

socket.on('connect', () => {
    console.log('SocketIO: connected');

    ConfigStore.setDisconnected('sio', false);

    if (disconnected.sio) {
        disconnected.sio = false;
    }
});

socket.on('disconnect', () => {
    console.log('SocketIO: disconnected');

    disconnected.sio = true;

    ConfigStore.setDisconnected('sio', true);
});

socket.on('error', (msg: string) => {
    console.error('SocketIO: error', msg);
});
