/**
 * SocialShareButtons
 *
 * @author: exode <hello@exode.ru>
 */

import React, { ReactElement } from 'react';

import { IS_NATIVE, IS_WEB } from '@/root/src/env';

import { DomainSwitcher, If } from '@/cutils';
import { UserStateKey } from '@/codegen/graphql';
import { useGetUserState } from '@/hooks/apollo';

import { Icon28AddCircleFillBlue } from '@vkontakte/icons';
import { HorizontalCell, HorizontalScroll, List } from '@exode.ru/vkui';
import { OKIcon, OKShareButton, TelegramIcon, TelegramShareButton, VKIcon, VKShareButton } from 'react-share';

import { VkPublishButtons } from '@/components/Atoms/VkPublishButtons';
import { NativeShareButton } from '@/components/Native/NativeShareButton';


interface Props {
    link: string;
    showVkPublish?: boolean;
    header?: ReactElement;
    className?: string;
}


const SocialShareButtons = (props: Props) => {

    const { link, showVkPublish, header, className } = props;

    const [ userVkToken, vkTokenLoading ] = useGetUserState(UserStateKey.VkToken);

    const isShowVkPublish = !!showVkPublish && !!userVkToken;

    return (
        <If is={IS_WEB || IS_NATIVE || isShowVkPublish}>
            <div className={[ 'flex flex-col gap-6', className ].join(' ')}>
                <If is={IS_WEB || IS_NATIVE}>
                    <>{header}</>

                    <HorizontalScroll showArrows
                                      className="grid mb-2"
                                      getScrollToLeft={(i) => i - 120}
                                      getScrollToRight={(i) => i + 120}>
                        <List className="flex justify-center">
                            <If is={IS_WEB}>
                                <TelegramShareButton url={link}>
                                    <HorizontalCell header="Telegram">
                                        <TelegramIcon round size={48}/>
                                    </HorizontalCell>
                                </TelegramShareButton>

                                <If is={!vkTokenLoading && !userVkToken}>
                                    <VKShareButton url={link}>
                                        <HorizontalCell header="VK">
                                            <VKIcon round bgStyle={{ fill: 'var(--accent)' }} size={48}/>
                                        </HorizontalCell>
                                    </VKShareButton>
                                </If>

                                <DomainSwitcher Ru={(
                                    <OKShareButton url={link}>
                                        <HorizontalCell header="OK">
                                            <OKIcon round size={48}/>
                                        </HorizontalCell>
                                    </OKShareButton>
                                )}/>
                            </If>

                            <If is={IS_NATIVE}>
                                <NativeShareButton options={{ url: link }}>
                                    <div>
                                        <HorizontalCell header="Telegram">
                                            <TelegramIcon round size={48}/>
                                        </HorizontalCell>
                                    </div>
                                </NativeShareButton>

                                <NativeShareButton options={{ url: link }}>
                                    <div>
                                        <HorizontalCell header="VK">
                                            <VKIcon round bgStyle={{ fill: 'var(--accent)' }} size={48}/>
                                        </HorizontalCell>
                                    </div>
                                </NativeShareButton>

                                <DomainSwitcher Ru={(
                                    <NativeShareButton options={{ url: link }}>
                                        <div>
                                            <HorizontalCell header="OK">
                                                <OKIcon round size={48}/>
                                            </HorizontalCell>
                                        </div>
                                    </NativeShareButton>
                                )}/>

                                <NativeShareButton options={{ url: link }}>
                                    <div>
                                        <HorizontalCell header="Другое">
                                            <Icon28AddCircleFillBlue width={48} height={48}/>
                                        </HorizontalCell>
                                    </div>
                                </NativeShareButton>
                            </If>
                        </List>
                    </HorizontalScroll>
                </If>

                <If is={isShowVkPublish}>
                    <VkPublishButtons layout="horizontal"/>
                </If>
            </div>
        </If>
    );
};


export { SocialShareButtons };
