/**
 * Product types
 *
 * @author: exode <hello@exode.ru>
 */

export enum ProductCurrency {
    Free = 'Free',
    Exes = 'Exes',
    Rub = 'Rub',
    Uzs = 'Uzs',
    Kzt = 'Kzt',
    Usd = 'Usd',
    Eur = 'Eur',
}
