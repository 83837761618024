/**
 * ResizeHandlerPart
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { Icon16ArrowsUpDown } from '@vkontakte/icons';


const ResizeHandlerPart = () => {
    return (
        <div style={{ transform: 'rotate(-45deg)' }}
             className="absolute p-1 overflow-hidden z-[101] bg-content rounded-full vk-rounded shadow-lg">
            <Icon16ArrowsUpDown width={18} height={18} fill="var(--accent)"/>
        </div>
    );
};


export { ResizeHandlerPart };
