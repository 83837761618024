/**
 * FeedContentHeader component
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { observer, RouterStore } from '@/store/core/router';

import { Link } from '@/cutils';

import { Tabs, TabsItem } from '@exode.ru/vkui';

import { Container } from './FeedContentHeader.styled';


const FeedContentHeader = observer(() => {

    const activeFont = 'bg-clip-text text-gradient panel-feed__active';

    return (
        <Container>
            <Tabs>
                <Link pushPage={{ id: '/' }}>
                    <TabsItem selected={RouterStore.viewId === '/'}>
                        <span className={RouterStore.viewId === '/' ? activeFont : ''}>
                            Моя лента
                        </span>
                    </TabsItem>
                </Link>

                <Link pushPage={{ id: '/actual' }}>
                    <TabsItem selected={RouterStore.viewId === '/actual'}>
                        <span className={RouterStore.viewId === '/actual' ? activeFont : ''}>
                            Актуальное
                        </span>
                    </TabsItem>
                </Link>
            </Tabs>
        </Container>
    );
});


export { FeedContentHeader };
