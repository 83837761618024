/**
 * ProfileMeta
 *
 * @author: exode <hello@exode.ru>
 */

import { PageParams } from 'router.tsx';

import { gql } from '@apollo/client';

import { MetaPropsType } from '@/types/page';
import { RoutePathType } from '@/router/paths';
import { MetaRequestOptions } from '@/root/src/meta';

import { serverApolloClient } from '@/api/ssr-api';
import { ProfileEntity } from '@/codegen/graphql';


export const profileMetaRequest = async (
    id: RoutePathType,
    params: PageParams,
    options?: MetaRequestOptions,
): Promise<Partial<MetaPropsType>> => {
    const query = gql`
        query ProfileInfoAuth($userId: Int!) {
            profileFindOne(userId: $userId) {
                fullName
                title
                user {
                    domain
                }
                avatar {
                    maximum
                }
            }
        }
    `;

    const variables = params.userId.match('[a-zA-Z]+')
        ? { domain: params.userId }
        : { userId: +params.userId };

    const { data: { profileFindOne } } = await serverApolloClient.query({
        query,
        variables,
        fetchPolicy: options?.fetchPolicy || 'no-cache',
    });

    const { fullName, avatar, title, user } = profileFindOne as ProfileEntity;

    return {
        title: fullName + ' • Страница профиля',
        description: [ user.domain, title ].join(' • '),
        image: avatar?.maximum || '',
    };
};
