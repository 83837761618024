/**
 * LaunchPriceSearchSelect
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { useI18n } from '@/hooks/core';

import { FilterPriceProductInput, useProductFindManyPricesQuery } from '@/codegen/graphql';

import { SearchSelect } from '@/components/Atoms/SearchSelect';


interface Props {
    onSelect: (params: any) => void;
    selectedLaunchPriceIds?: number[];
    filter?: FilterPriceProductInput;
}


const LaunchPriceSearchSelect = (props: Props) => {

    const { selectedLaunchPriceIds, onSelect, filter = {} } = props;

    /** Transform filter */
    filter.launchIds = filter?.launchIds?.filter(e => e);

    const { t } = useI18n('components.Course.LaunchSearchSelect');

    const isSkip = !filter?.productId && !filter?.launchIds?.length;

    const { data, loading } = useProductFindManyPricesQuery({
        skip: isSkip,
        fetchPolicy: 'network-only',
        onError: error => console.error(error),
        variables: {
            list: { take: 100 },
            filter: { ...filter },
        },
    });

    const options = data?.productPriceFindMany.items
        ?.map(({ id, title, icon }) => ({
            value: id,
            label: title || '',
            avatar: icon || '',
        }));

    return (
        <SearchSelect avatarType="icon"
                      loading={loading}
                      searchable={false}
                      onSelect={onSelect}
                      options={options || []}
                      searchCallback={() => {}}
                      placeholder={t('nameOfTariff')}
                      emptyText={t('chooseLaunchFirst')}
                      selectedIds={selectedLaunchPriceIds}
                      initialSearch={{
                          skip: isSkip,
                          deps: [ JSON.stringify(filter?.launchIds) ],
                      }}/>
    );
};


export { LaunchPriceSearchSelect };
