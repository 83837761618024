/**
 * CardRequisiteRow
 *
 * @author: exode <hello@exode.ru>
 */

import React, { ReactElement } from 'react';

import { GqlResult } from '@/types/graphql';

import { useI18n } from '@/hooks/core';

import { PayoutRequisiteFindOneActiveQueryResult } from '@/codegen/graphql';

import { Icon24PenOutline, Icon28BankOutline } from '@vkontakte/icons';

import { GradientButtonCard } from '@/components/Atoms/GradientButtonCard';


interface Props {
    requisite: GqlResult<PayoutRequisiteFindOneActiveQueryResult>['payoutRequisiteFindOneActive'];
    wholeIsClickable?: boolean;
    afterWrapper?: (after: ReactElement) => ReactElement;
}


const CardRequisiteRow = (props: Props) => {

    const {
        requisite,
        wholeIsClickable = true,
        afterWrapper = (children: ReactElement) => children,
    } = props;

    const { t } = useI18n('components.Seller.Balance.CardRequisiteRow');

    return (
        <GradientButtonCard transparent
                            isLoading={false}
                            hideBeforeOnMobile
                            stopPropagation={false}
                            className="card-clear-after"
                            afterWrapperClassName="!mr-3"
                            wholeIsClickable={wholeIsClickable}
                            header={`${t('bankAccountNumber')} ${requisite?.bankAccountNumber}`}
                            after={afterWrapper(<Icon24PenOutline fill="var(--accent)"/>)}
                            subheader={<span className="flex pr-[30px]">{requisite?.bankName}</span>}
                            before={<Icon28BankOutline width={36} height={36} fill="var(--accent)"/>}/>
    );
};


export { CardRequisiteRow };
