/**
 * UseTournamentMessage
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import { customAlphabet } from 'nanoid';

import { UserAuthStore } from '@/store/user/auth';
import { ConfigStore } from '@/store/core/config';
import { ProfileStore } from '@/store/user/profile';

import { TournamentLobbyPageStore } from '@/pages/Tournament/Lobby/store';

import { GqlResult } from '@/types/graphql';
import { ObservableQuery, useApolloClient } from '@apollo/client';

import {
    TournamentListenMessagesDocument,
    TournamentListenMessagesSubscription,
    TournamentMessageInput,
    TournamentMessagesDocument,
    TournamentMessagesQuery,
    TournamentMessagesQueryResult,
    TournamentMessagesQueryVariables,
    useSendTournamentMessageMutation,
} from '@/codegen/graphql';


/**
 * Хук подписки на новые сообщения турнира
 */
export const useTournamentMessage = (
    tournamentId: number,
    subscribe?: ObservableQuery<TournamentMessagesQuery>['subscribeToMore'],
) => {

    const subscribeToNewMessages = () => {
        subscribe?.({
            document: TournamentListenMessagesDocument,
            variables: { tournamentId },
            updateQuery: (prev, { subscriptionData }) => {
                const {
                    tournamentListenNewLobbyMessage: newMessage,
                } = subscriptionData.data as unknown as TournamentListenMessagesSubscription;

                return Object.assign({}, prev, {
                    tournamentLobbyFindManyMessages: {
                        __typename: 'ListMessageTournamentOutput',
                        count: (prev.tournamentLobbyFindManyMessages.count || 0) + 1,
                        items: [
                            newMessage.message.uuid !== ConfigStore.tabUuid && newMessage,
                            ...(prev.tournamentLobbyFindManyMessages.items || []),
                        ].filter(e => e),
                    },
                });
            },
            onError(error) {
                console.error(error);
            },
        });
    };

    const { cache } = useApolloClient();

    const [ sendMessage, { loading: sendTournamentMessageLoading } ] = useSendTournamentMessageMutation({
        onError: (error) => console.error(error),
    });

    const getCachedMessages = (cacheVariables: TournamentMessagesQueryVariables) => {
        return cache.readQuery<TournamentMessagesQuery>({
            variables: cacheVariables,
            query: TournamentMessagesDocument,
        });
    };

    const sendTournamentMessage = async (
        tournamentId: number,
        message: TournamentMessageInput,
    ) => {
        const localMessageId = +(customAlphabet('1234567890', 18))();

        const cacheVariables = {
            tournamentId,
            list: TournamentLobbyPageStore.chatList,
        };

        const mutationVariables = {
            tournamentId,
            message,
        };

        const newTournamentMessage = {
            __typename: 'TournamentMessageEntity' as const,
            id: localMessageId,
            message: {
                __typename: 'TournamentMessagePayload' as const,
                ...mutationVariables.message,
            },
            user: {
                __ref: `UserEntity:${UserAuthStore.user?.id}`,
                id: UserAuthStore.user?.id as number,
                lastOnlineAt: new Date(),
                profile: {
                    __ref: `ProfileEntity:${ProfileStore.id}`,
                    ..._.pick(ProfileStore, [
                        'id',
                        'fullName',
                        'title',
                        'emojiTitle',
                    ]),
                    titleState: {
                        ...ProfileStore.titleState,
                        __typename: 'ProfileTitleState' as const,
                    },
                    avatar: {
                        id: ProfileStore.avatar.id || 0,
                        medium: ProfileStore.avatar.medium || '',
                    },
                },
            },
        };

        const cachedMessages = getCachedMessages(cacheVariables);

        cache.writeQuery<TournamentMessagesQuery>({
            variables: cacheVariables,
            query: TournamentMessagesDocument,
            data: {
                tournamentLobbyFindManyMessages: {
                    __typename: 'ListMessageTournamentOutput',
                    count: (cachedMessages?.tournamentLobbyFindManyMessages.count || 0) + 1,
                    items: [
                        newTournamentMessage,
                        ...(cachedMessages?.tournamentLobbyFindManyMessages.items ?? []),
                    ],
                },
            },
        });

        await sendMessage({
            variables: {
                messagePayload: mutationVariables,
            },
            onError(error) {
                console.error(error);
            },
            update: (cache, { data }) => {
                const cachedMessages = getCachedMessages(cacheVariables);

                const newMessageItems = [
                    ...(cachedMessages?.tournamentLobbyFindManyMessages.items || []).map((message) => (
                        message.id !== localMessageId ? message : data?.tournamentLobbySendMessage
                    )),
                ] as GqlResult<TournamentMessagesQueryResult>['tournamentLobbyFindManyMessages']['items'];

                data?.tournamentLobbySendMessage && cache.writeQuery<TournamentMessagesQuery>({
                    variables: cacheVariables,
                    query: TournamentMessagesDocument,
                    data: {
                        tournamentLobbyFindManyMessages: {
                            __typename: 'ListMessageTournamentOutput',
                            count: (cachedMessages?.tournamentLobbyFindManyMessages.count || 0) + 1,
                            items: newMessageItems,
                        },
                    },
                });
            },
        });
    };

    return {
        subscribeToNewMessages,
        sendTournamentMessage,
        sendTournamentMessageLoading,
    };
};
