/**
 * DynamicVkIcon
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import {
    Icon2818CircleOutline,
    Icon28AddCircleOutline,
    Icon28AddSquareOutline,
    Icon28AdvertisingOutline,
    Icon28AllCategoriesOutline,
    Icon28ArchiveCheckOutline,
    Icon28ArticleOutline,
    Icon28BankOutline,
    Icon28BookSpreadOutline,
    Icon28BrainOutline,
    Icon28ClapperboardOutline,
    Icon28CubeBoxOutline,
    Icon28CupOutline,
    Icon28DiamondOutline,
    Icon28DiscountOutline,
    Icon28DocumentTextOutline,
    Icon28DumbbellsOutline,
    Icon28EducationOutline,
    Icon28FavoriteOutline,
    Icon28FireOutline,
    Icon28FlashOutline,
    Icon28FortuneCookieOutline,
    Icon28GiftOutline,
    Icon28LifebuoyOutline,
    Icon28LightbulbStarOutline,
    Icon28MegaphoneOutline,
    Icon28MessageOutline,
    Icon28MoneyTransferOutline,
    Icon28More,
    Icon28MusicOutline,
    Icon28PaymentCardOutline,
    Icon28ShoppingCartOutline,
    Icon28SquareStackUpOutline,
    Icon28TagOutline,
    Icon28UserAddOutline,
    Icon28UserOutline,
    Icon28UserStarOutline,
    Icon28WalletOutline,
    Icon28WorkOutline,
} from '@vkontakte/icons';


interface Props {
    vkIconName: string | undefined | null;
}


export const dynamicIcons = {
    'Icon28AllCategoriesOutline': <Icon28AllCategoriesOutline/>,
    'Icon2818CircleOutline': <Icon2818CircleOutline/>,
    'Icon28AddCircleOutline': <Icon28AddCircleOutline/>,
    'Icon28AddSquareOutline': <Icon28AddSquareOutline/>,
    'Icon28AdvertisingOutline': <Icon28AdvertisingOutline/>,
    'Icon28ArchiveCheckOutline': <Icon28ArchiveCheckOutline/>,
    'Icon28ArticleOutline': <Icon28ArticleOutline/>,
    'Icon28BankOutline': <Icon28BankOutline/>,
    'Icon28BrainOutline': <Icon28BrainOutline/>,
    'Icon28ClapperboardOutline': <Icon28ClapperboardOutline/>,
    'Icon28DiamondOutline': <Icon28DiamondOutline/>,
    'Icon28CubeBoxOutline': <Icon28CubeBoxOutline/>,
    'Icon28DiscountOutline': <Icon28DiscountOutline/>,
    'Icon28FireOutline': <Icon28FireOutline/>,
    'Icon28GiftOutline': <Icon28GiftOutline/>,
    'Icon28LightbulbStarOutline': <Icon28LightbulbStarOutline/>,
    'Icon28MessageOutline': <Icon28MessageOutline/>,
    'Icon28ShoppingCartOutline': <Icon28ShoppingCartOutline/>,
    'Icon28SquareStackUpOutline': <Icon28SquareStackUpOutline/>,
    'Icon28PaymentCardOutline': <Icon28PaymentCardOutline/>,
    'Icon28UserStarOutline': <Icon28UserStarOutline/>,
    'Icon28More': <Icon28More/>,
    'Icon28EducationOutline': <Icon28EducationOutline/>,
    'Icon28DocumentTextOutline': <Icon28DocumentTextOutline/>,
    'Icon28BookSpreadOutline': <Icon28BookSpreadOutline/>,
    'Icon28CupOutline': <Icon28CupOutline/>,
    'Icon28MoneyTransferOutline': <Icon28MoneyTransferOutline/>,
    'Icon28UserOutline': <Icon28UserOutline/>,
    'Icon28WalletOutline': <Icon28WalletOutline/>,
    'Icon28FavoriteOutline': <Icon28FavoriteOutline/>,
    'Icon28DumbbellsOutline': <Icon28DumbbellsOutline/>,
    'Icon28FlashOutline': <Icon28FlashOutline/>,
    'Icon28FortuneCookieOutline': <Icon28FortuneCookieOutline/>,
    'Icon28LifebuoyOutline': <Icon28LifebuoyOutline/>,
    'Icon28MegaphoneOutline': <Icon28MegaphoneOutline/>,
    'Icon28MusicOutline': <Icon28MusicOutline/>,
    'Icon28TagOutline': <Icon28TagOutline/>,
    'Icon28UserAddOutline': <Icon28UserAddOutline/>,
    'Icon28WorkOutline': <Icon28WorkOutline/>,
};

const DynamicVkIcon = (props: Props) => {

    const dynamicIcon = dynamicIcons[props.vkIconName as keyof typeof dynamicIcons]
        || dynamicIcons.Icon28AllCategoriesOutline;

    return React.cloneElement(dynamicIcon, props);
};


export { DynamicVkIcon };
