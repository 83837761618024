/**
 * TournamentService
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import moment from 'moment';

import { APP_URL } from '@/root/src/env';

import { Url } from '@/utils';
import { pluralCategory } from '@/libs/plurals';

import { PageStore } from '@/pages/Core';
import { RoutePathType } from '@/router/paths';
import { UserAuthStore } from '@/store/user/auth';
import { RouterStore } from '@/store/core/router';
import { TournamentTabStore } from '@/pages/Tournament/Tournament/store';

import { TournamentState } from '@/codegen/graphql';

import { Router } from '@/services/Utils/Router';
import { BaseService } from '@/services/Core/Base';

import { defaultFilter } from '@/pages/Tournament/Tournament/filter';


class TournamentService extends BaseService {

    /**
     * Получение диапазона категорий
     * @param {Array<number>} categoriesLength
     * @returns {Array<string>}
     */
    static getCategoryLength(categoriesLength: number) {
        return !categoriesLength
            ? 'Все категории'
            : `${categoriesLength} ${pluralCategory(categoriesLength)}`;
    }

    /**
     * Определение страницы для редиректа в зависимости от статуса турнира
     * @param {{state: , isMember: boolean}} tournament
     * @param {'pushPage' | 'replacePage'} method
     */
    static openAppropriatePageByState(
        tournament: {
            id: string | number;
            state: TournamentState;
            isMember: boolean;
            userFinished?: boolean;
            inviteKey?: string | null;
        },
        method: 'pushPage' | 'replacePage',
    ) {
        const tournamentId = `${tournament.id}`;

        const { state, isMember, userFinished, inviteKey } = tournament;

        const openCardInfoModal = () => {
            const infoModalIsOpen = Router.hasModal('tournament-card-info');

            if (!infoModalIsOpen) {
                Router.pushModal(
                    'tournament-card-info',
                    inviteKey
                        ? { tournamentId, inviteKey }
                        : { tournamentId },
                );
            }
        };

        if (!isMember && state !== TournamentState.Result) {
            const tournamentTabPage: RoutePathType[] = [
                '/tournaments',
                '/tournaments/:page([0-9]+)',
            ];

            if (!tournamentTabPage.includes(RouterStore.pageId)) {
                Router.replacePage('/tournaments');
            }

            return openCardInfoModal();
        }

        switch (state) {
            case TournamentState.Cancel:
            case TournamentState.Waiting:
                openCardInfoModal();
                break;

            case TournamentState.StartLobby:
                Router[method]('/tournament/lobby/:tournamentId([0-9]+)', { tournamentId });
                break;

            case TournamentState.Process:
                (_.isBoolean(userFinished) && userFinished)
                    ? Router[method]('/tournament/lobby/:tournamentId([0-9]+)', { tournamentId })
                    : Router[method]('/tournament/process/:tournamentId([0-9]+)', { tournamentId });
                break;

            case TournamentState.Result:
                Router[method]('/tournament/result/:tournamentId([0-9]+)/:page([0-9]+)', { tournamentId });
                break;
        }
    }

    /**
     * Получение и установка фильтров турниров
     * @param {PageStore} store
     * @returns {}
     */
    static getAndSetFilterByParams(store: PageStore) {
        const filterKeys = _.keys(_.pick(
            store.state.filter,
            [ 'type', 'subject', 'bet' ],
        ));

        const isFilter = filterKeys.every(item => Router.urlQueryParams.includes(item));

        if (UserAuthStore.isLoggedIn && isFilter) {
            const { types, startModes, bet } = Url.getParams();

            const filterFromParams = {
                types: [ types ],
                startModes: [ startModes ],
                bet: +bet,
            };

            store.mergeFilter(filterFromParams);
            TournamentTabStore.mergeTemporaryFilter(filterFromParams);
        } else {
            store.mergeFilter(defaultFilter);
        }
    }

    /**
     * Get an invitation link
     * @param {number} tournamentId
     * @param {string} inviteKey
     * @param {boolean} accessByInviteKey
     * @returns {string}
     */
    static getInviteLink(
        tournamentId: number | string,
        inviteKey: string | undefined | null,
        accessByInviteKey = true,
    ) {
        return [
            `${APP_URL}/tournament/invite/${tournamentId}`,
            accessByInviteKey ? `?inviteKey=${inviteKey}` : '',
        ].filter(e => e).join('');
    }

    /**
     * Get tournament start at date
     * @param {string} minutes
     * @returns {string}
     */
    static getTournamentStartAtDate(minutes: string) {
        return moment()
            .utc()
            .add(minutes, 'm')
            .format();
    }

    /**
     * Define can join
     * @param {} state
     */
    static canJoin(state: TournamentState) {
        switch (state) {
            case TournamentState.Cancel:
            case TournamentState.Process:
            case TournamentState.Result:
                return false;

            default:
                return true;
        }
    }

}


export { TournamentService };
