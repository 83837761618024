/**
 * TablePart
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { If } from '@/cutils';

import { InnerHtml } from '@/components/Atoms/InnerHtml';


const TablePart = ({ data }: any) => {

    const [ headers = [], ...rows ] = data?.content || [];

    return (
        <table className="editor-js-table" style={{ borderSpacing: '1px 2px', margin: '5px 0px' }}>
            <thead>
            <tr>
                {headers.map((header: string, index: number) => (
                    <th key={index} style={{
                        minWidth: '100px',
                        padding: '8px 15px',
                        borderRadius: '8px 8px 0px 0px',
                        background: 'var(--button_muted_background)',
                        fontSize: '16px',
                    }}>
                        <InnerHtml content={header} include={[ 'timeCodes' ]} className="inline"/>
                    </th>
                ))}
            </tr>
            </thead>

            <tbody>
            {(rows || []).map((row: any[], rowIndex: number) => (
                <If is={!!row && !!row?.length}>
                    <tr key={rowIndex}>
                        {(row || []).map((cell, cellIndex) => (
                            <td key={cellIndex} style={{
                                minWidth: '100px',
                                padding: '8px 15px',
                                borderRadius: '0px',
                                textAlign: 'left',
                                fontSize: '16px',
                                background: 'var(--background_content)',
                                border: 'var(--thin-border) solid var(--input_border)',
                            }}>
                                <InnerHtml content={cell} include={[ 'timeCodes' ]} className="inline"/>
                            </td>
                        ))}
                    </tr>
                </If>
            ))}
            </tbody>
        </table>
    );
};


export { TablePart };
