/**
 * Interfaces
 *
 * @author: exode <hello@exode.ru>
 */

export enum CategorySwitcher {
    Categories = 'Categories',
    Selections = 'Selections',
}
