/**
 * PreferenceThemeToggle component
 *
 * @author: exode <hello@exode.ru>
 */

import React, { ReactElement } from 'react';

import { IS_VK } from '@/root/src/env';
import { observer, PreferenceStore } from '@/store/preference/preference';

import { useNative } from '@/hooks/core';

import { PanelHeaderButton } from '@exode.ru/vkui';
import { Icon28MoonOutline, Icon28SunOutline } from '@vkontakte/icons';


interface Props {
    children?: ReactElement;
}


const PreferenceThemeToggle = observer((props: Props) => {

    const toggleScheme = async () => {
        await PreferenceStore.toggleScheme();
    };

    const { triggerHaptic } = useNative();

    const onClick = async () => {
        triggerHaptic();

        await toggleScheme();
    };

    const button = props.children || (
        <PanelHeaderButton>
            {PreferenceStore.isDark ? <Icon28SunOutline/> : <Icon28MoonOutline/>}
        </PanelHeaderButton>
    );

    if (IS_VK) {
        return <></>;
    }

    return React.cloneElement(button, { onClick });
});


export { PreferenceThemeToggle };
