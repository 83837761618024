/**
 * Filter useDiscounts
 *
 * @author: exode <hello@exode.ru>
 */

import { useUrlFilters } from '@/hooks/core';

import { StoreCommonType } from '@/types/store';


export const defaultDiscountsFilter = {
    productIds: [] as number[],
};

export const dtoFilterDiscountsTransformer = (filter: typeof defaultDiscountsFilter) => filter;

export const useDiscountsFilter = (
    store: StoreCommonType,
    filter: Partial<typeof defaultDiscountsFilter>,
) => useUrlFilters(
    { ...defaultDiscountsFilter, ...filter },
    [],
    dtoFilterDiscountsTransformer,
    { store },
);
