/**
 * EducationTab store
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import { makeObservable } from 'mobx';
import { observer, PageStore } from '@/pages/Core';

import {
    CourseParticipationType,
    CourseType,
    EducationSubject,
    ProductCurrency,
    SortDirection,
} from '@/codegen/graphql';


/** Перемещен в store для решения circular dependency */
export const defaultFilter = {
    search: '',
    manage: false,
    types: [] as CourseType[],
    categoryIds: [] as number[],
    subjects: [] as EducationSubject[],
    participation: CourseParticipationType.NotParticipant,
    product: {
        showInCatalog: true,
        currencies: [] as ProductCurrency[],
    },
};

export const dtoFilterTransformer = (filter: typeof defaultFilter) => {
    return {
        ..._.omit(filter, [ 'categoryIds' ]),
        contentCategoryIds: filter.categoryIds,
    };
};


class EducationTab extends PageStore {

    constructor() {
        super();
        makeObservable(this);
    }

    state = {
        list: {
            skip: 0,
            take: 15,
        },
        filter: dtoFilterTransformer(defaultFilter),
        sort: {
            publishedAt: SortDirection.Desc,
        },
    };

}

const EducationTabStore = new EducationTab();


export { observer, EducationTabStore };
