/**
 * MainTab
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { PageSpace } from '@/shared/types';
import { Auth, Platform, Redirect, Responsive } from '@/cutils';

import { CatalogTab } from '@/tabs/Catalog';


const MainTab = () => {
    return (
        <>
            <Platform.BizWelcome>
                <Redirect replace to="/manage/my-schools"/>
            </Platform.BizWelcome>

            <Platform.If platforms={[
                PageSpace.SchoolPlatform,
                PageSpace.MarketplacePlatform,
            ]}>
                <Responsive.Mobile>
                    <Auth>
                        <Redirect replace to="/education"/>
                    </Auth>
                </Responsive.Mobile>
            </Platform.If>

            <CatalogTab/>
        </>
    );
};


export { MainTab };
