/**
 * Main tab routing
 *
 * @author: exode <hello@exode.ru>
 */

import { IS_BIZ_WELCOME, IS_VK, IS_WEB } from '@/root/src/env';


/**
 * Определение роутинга для главной страницы;
 * @param {'logo' | 'page'} element
 * @param {boolean} isLoggedIn
 */
export const mainPageRouting = (
    element: 'logo' | 'page',
    isLoggedIn: boolean,
) => {
    const routing = {
        logo: {
            web: {
                auth: IS_BIZ_WELCOME ? '/manage/my-schools' : '/education',
                unauth: '/login',
            },
            vk: {
                auth: '/tournaments',
                unauth: '/tournaments',
            },
            default: {
                auth: '/education',
                unauth: '/education',
            },
        },
        page: {
            web: {
                auth: '/education',
                unauth: '/education',

            },
            vk: {
                auth: '/tournaments',
                unauth: '/tournaments',
            },
            default: {
                auth: '/education',
                unauth: '/education',
            },
        },

    } as const;

    return routing[element]
        [IS_WEB ? 'web' : (IS_VK ? 'vk' : 'default')]
        [isLoggedIn ? 'auth' : 'unauth'];
};
