/**
 * FloatingButton component
 *
 * @author: exode <hello@exode.ru>
 */

import React, { HTMLAttributes, ReactElement, ReactNode } from 'react';

import { Avatar, Counter } from '@exode.ru/vkui';
import { Icon36Add } from '@vkontakte/icons';

import { If } from '@/cutils';

import { Container } from './FloatingButton.styled';


export interface FloatingButtonProps extends HTMLAttributes<HTMLDivElement> {
    forDesktop?: boolean;
    icon?: ReactElement;
    size?: number;
    className?: string;
    badge?: ReactNode;
    badgeBg?: string;
    badgeColor?: string;
    isLeftSided?: boolean;
}


const FloatingButton = (props: FloatingButtonProps) => {

    const { className, badge, icon = <Icon36Add/>, size = 60 } = props;

    return (
        <Container className={className} {...props}>
            <If is={!!badge}>
                <Counter>{badge}</Counter>
            </If>

            <Avatar size={size} className="shadow-md">
                {icon}
            </Avatar>
        </Container>
    );
};


export { FloatingButton };
