/**
 * BookmarkRoutes
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { PageSpace } from '@/shared/types';

import { RouterItemsType } from '@/types/router';
import { lazyWithRetry } from '@/helpers/react';


const BookmarkListPage = lazyWithRetry(() => import('@/pages/Bookmark/List'));


const BookmarkRoutes: RouterItemsType = {
    '/bookmarks': {
        view: <BookmarkListPage/>,
        auth: [],
        spaces: [
            PageSpace.MarketplacePlatform,
        ],
    },
};


export { BookmarkRoutes };
