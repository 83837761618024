/**
 * IconVk component
 *
 * @author: exode <hello@exode.ru>
 */

import React, { SVGProps } from 'react';


const IconVk = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" width={28} height={28}
         {...props}
    >
        <path d="M5.436 10.957c-2.242 0-3.949 2.204-3.33 4.371 1.609 5.649 3.995 11.18 7.556 15.53 3.561 4.349 8.378 7.5 14.537 8.126 2.05.208 3.819-1.463 3.819-3.49v-4.047c0-.252.172-.432.369-.437l.084-.002c.707 0 2.16.886 3.437 2.263 1.277 1.378 2.406 3.083 2.95 4.028v.002a3.439 3.439 0 0 0 2.978 1.71h4.873c2.427.003 4.051-2.74 2.863-4.863-1.864-3.335-5.585-6.634-7.445-8.279 3.409-3.743 5.668-7.822 6.67-10.527.756-2.044-.803-4.344-3-4.344h-3.479c-1.512 0-2.921.737-3.498 1.916-2.707 5.548-5.12 7.585-6.82 9.053v-7.528c0-1.877-1.558-3.426-3.43-3.43L18.48 11c-1.94-.002-3.143 2.393-1.981 3.947l1.176 1.574.002.004c.201.267.308.586.308.914v.002l.024 6.278c-2.423-2.556-5.056-7.968-5.85-10.383v-.002a3.465 3.465 0 0 0-3.271-2.367h-.002l-3.448-.01zm0 3 3.443.01c.212 0 .367.115.43.304.986 3 3.577 8.714 6.863 11.915.853.83 2.105 1 3.076.605.97-.394 1.762-1.416 1.762-2.615v-.002l-.026-6.735c0-.98-.32-1.934-.91-2.716l-.002-.004-.537-.717 5.03.008c.262 0 .435.171.435.43v7.603c0 2.435 3.066 3.834 4.91 2.242h.002c1.69-1.46 4.68-4.065 7.604-10.055.067-.138.008-.232.802-.232h3.479c.202 0 .262.097.185.303-.85 2.298-3.021 6.306-6.24 9.773-.992 1.07-.951 2.835.17 3.828 1.758 1.555 5.595 5.124 7.041 7.71.132.236.033.4-.242.4h-4.875a.427.427 0 0 1-.379-.21c-.65-1.129-1.841-2.944-3.348-4.57-1.506-1.625-3.3-3.224-5.638-3.224-.067 0-.12 0-.162.002h-.006c-1.862.052-3.285 1.632-3.285 3.437v4.047c0 .311-.24.534-.516.506-5.324-.54-9.336-3.156-12.52-7.043C8.8 25.07 6.526 19.895 4.99 14.506c-.086-.301.099-.549.446-.549z"
              transform="scale(5.33333)"
              fill="var(--accent)"
              strokeMiterlimit={10}
              fontFamily="none"
              fontWeight="none"
              fontSize="none"
              textAnchor="none"
              style={{
                  mixBlendMode: 'normal',
              }}/>
    </svg>
);


export { IconVk };
