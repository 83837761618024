/**
 * ContextView
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { Page } from '@/pages/Core';

import { UserSecureBanner } from '@/components/Promotion/ContextBanners';


const ContextView = () => {
    return (
        <Page.ContextWrapper>
            <UserSecureBanner/>
        </Page.ContextWrapper>
    );
};


export { ContextView };
