/**
 * NotificationSnackbar styled container
 *
 * @author: exode <hello@exode.ru>
 */

import styled from 'styled-components';

import { Container as SnackbarContainer } from '../DefaultSnackbar/DefaultSnackbar.styled';


export const Container = styled(SnackbarContainer)`
    .vkuiRichCell {
        padding: 0;
    }

    .Snackbar {
        position: relative !important;
    }

    .Snackbar__in {
        padding-top: 0;
        padding-bottom: 0;

        .Snackbar__body {
            min-width: 100%;
            max-height: 175px;
            max-width: calc(100% - 65px);
            position: relative;
            box-shadow: var(--vkui--elevation3, 0 0 8px rgba(0, 0, 0, .12), 0 16px 16px rgba(0, 0, 0, .16)) !important;

            & .vkuiButton__content {
                color: var(--button_primary_foreground, var(--vkui--color_text_contrast));
            }
        }
    }

    .ModalDismissButton {
        width: unset;
        height: unset;
        right: -40px;
        top: 10px;
        background-color: var(--action_sheet_separator);
        border-radius: 50%;
        padding: 3px;

        &::before {
            background-color: unset;
        }
    }
`;
