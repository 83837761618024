/**
 * ProfileView
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { GetUserProfileQuery } from '@/codegen/graphql';

import { observer, ProfileStore } from '@/store/user/profile';

import { Icon28ChevronRightOutline } from '@vkontakte/icons';

import { Graphql, Link } from '@/cutils';

import { ProfilePartial, User, UserCellProps } from '@/components/Atoms/User';


interface Props {
    userCellProps?: Partial<UserCellProps>;
}


const ProfileView = observer((props: Props) => {

    const { userCellProps } = props;

    const { user } = ProfileStore;

    const commonProps = {
        mode: 'simple',
        userId: user?.id,
        lastOnlineAt: user?.lastOnlineAt,
        after: <Icon28ChevronRightOutline/>,
        profile: ProfileStore as ProfilePartial,
        subtitle: [ user?.domain, ProfileStore?.title ].filter(e => e).join(' • '),
    } as const;

    return (
        <GetUserProfileQuery children={(result) => (
            <>
                <Graphql.Loading result={result}>
                    <Link pushPage={{ id: '/@:userId([0-9_A-Za-z]+)', params: { userId: `${user?.id}` } }}>
                        <User.Cell {...commonProps} {...userCellProps}/>
                    </Link>
                </Graphql.Loading>

                <Graphql.Success result={result}>
                    {({ profileFindOne: profile }) => (
                        <Link pushPage={{
                            id: '/@:userId([0-9_A-Za-z]+)',
                            params: { userId: `${profile?.user?.id || user?.id}` },
                        }}>
                            <User.Cell {...commonProps} {...userCellProps} profile={profile}
                                       userId={profile ? profile?.user?.id : user?.id}
                                       nameProps={{ showStatusSet: true, setStatusSize: 'small' }}
                                       subtitle={[ user?.domain, profile?.title ].filter(e => e).join(' • ')}/>
                        </Link>
                    )}
                </Graphql.Success>

                <Graphql.Error result={result}>
                    <Link pushPage={{ id: '/@:userId([0-9_A-Za-z]+)', params: { userId: `${user?.id}` } }}>
                        <User.Cell {...commonProps} {...userCellProps}/>
                    </Link>
                </Graphql.Error>
            </>
        )} variables={{ userId: user?.id }}/>
    );
});


export { ProfileView };
