/**
 * DesktopPanelHeader styled container
 *
 * @author: exode <hello@exode.ru>
 */

import styled from 'styled-components';

import { bgContent } from '@/styles/modules/background';
import { TextTooltip } from '@exode.ru/vkui/dist/unstable';


export const Container = styled.div<{ withShadow?: boolean }>`
    .PanelHeader__in {
        padding: 0;
        ${bgContent(0.7)};
        backdrop-filter: blur(7px);
        -webkit-backdrop-filter: blur(7px);
        border-bottom: var(--thin-border) solid var(--separator_common, var(--vkui--color_separator_primary));

        .Search {
            background: transparent;
        }

        .vkuiAvatar {
            background-color: transparent;
        }
    }

    .FixedLayout {
        box-shadow: ${({ withShadow }) => withShadow ? 'initial' : 'none'} !important;
    }

    .PanelHeader__content,
    .viewport__wrapper {
        display: flex;
        align-items: center;
        height: 100%;
    }
`;

export const HeaderItem = styled.div`
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    height: var(--panelheader_height);
    width: var(--panelheader_height);
    min-width: var(--panelheader_height);

    &:hover {
        background-color: var(--background_hover);
    }

    > .vkuiTabbarItem {
        overflow: visible;
        cursor: pointer;

        .vkuiTabbarItem__label .vkuiCounter {
            left: calc(100% - 12px);
            z-index: 3;
        }

        .vkuiTabbarItem__tappable {
            display: none;
        }
    }

    &.bg-transparent {
        .vkuiAvatar__img {
            background-color: transparent;
        }
    }

    .TabbarItem__in {
        padding: 0 9px;
    }

    & > .vkuiTabbarItem .vkuiTabbarItem__label .vkuiCounter {
        left: unset;

        &.small-count {
            right: -5px;
        }
    }
`;

export const StyledTooltip = styled(TextTooltip)`
    background: var(--blue_primary) !important;

    .vkuiTextTooltip__arrow {
        color: var(--blue_primary) !important
    }
`;
