/**
 * ConfirmationContext
 *
 * @author: exode <hello@exode.ru>
 */

import React, { createContext, ReactElement, useContext } from 'react';

import { FormikProps } from 'formik';


interface ConfirmationContext {
    formikRef?: React.Ref<FormikProps<any>>;
}

interface ConfirmationProviderProps {
    value: ConfirmationContext;
    children: ReactElement | ReactElement[];
}


/**
 * Confirmation context
 */
const ConfirmationContext = createContext<ConfirmationContext>({
    formikRef: null,
});

/**
 * Confirmation provider
 * @constructor
 */
const ConfirmationProvider = (props: ConfirmationProviderProps) => {
    return (
        <ConfirmationContext.Provider value={props.value}>
            {props.children}
        </ConfirmationContext.Provider>
    );
};

/**
 * UseConfirmationContext hook
 * @returns {ConfirmationContext}
 */
const useConfirmationContext = (): ConfirmationContext => useContext(ConfirmationContext);


export { ConfirmationProvider, useConfirmationContext };
