/**
 * RndVideo styled container
 *
 * @author: exode <hello@exode.ru>
 */

import styled from 'styled-components';


export const Wrapper = styled.div`
  .plyr--video {
    min-height: 180px !important;
  }
`;


export const ControlPanelWrapper = styled.div`
  background: linear-gradient(transparent, rgba(0, 0, 0, .75));
  border-bottom-left-radius: var(--vkui--size_border_radius_paper--regular);
  border-bottom-right-radius: var(--vkui--size_border_radius_paper--regular);
`;
