/**
 * DesktopPanelHeader component
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { observer } from 'mobx-react';
import { PageStore } from '@/store/core/page';

import { Responsive } from '@/cutils';

import { PanelHeader } from '@exode.ru/vkui';

import { Container } from './DesktopPanelHeader.styled';

import { LeftPart } from './parts/LeftPart';
import { RightPart } from './parts/RightPart';
import { ContentPart } from './parts/ContentPart';


const DesktopPanelHeader = observer(() => {
    return (
        <Responsive.Desktop>
            <Container withShadow={PageStore.panelHeader.withShadow}>
                <PanelHeader separator={false} className="viewport centered">
                    {/** Note: header width should be equal to regular, except next array: */}
                    <div className="viewport__wrapper"
                         data-page-mode={[ 'slim-menu' ].includes(PageStore.mode) ? PageStore.mode : 'regular'}>
                        <LeftPart/>
                        <ContentPart/>
                        <RightPart/>
                    </div>
                </PanelHeader>
            </Container>
        </Responsive.Desktop>
    );
});


export { DesktopPanelHeader };
