/**
 * UserAvatar component
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import React from 'react';

import LazyLoad from 'react-lazyload';

import { observer } from '@/store/user/online';
import { RouterStore } from '@/store/core/router';

import { Avatar as VkUiAvatar, AvatarProps, calcInitialsAvatarColor, InitialsAvatar } from '@exode.ru/vkui';

import { If } from '@/cutils';
import { Router } from '@/services/Utils/Router';
import { useUserOnline } from '@/hooks/user';

import { ImageLoader } from '@/components/Atoms/ImageLoader';

import { Container } from './UserAvatar.styled';
import { AvatarVerifiedPart } from './parts/AvatarVerifiedPart';


interface BaseAvatarProps extends AvatarProps {
    /** Name of user (entity) for the first letter */
    name: string;
    /** Link to image */
    src: string;
    /** Color of badge */
    badgeColor?: string;
    /** Background color of badge (not self, just around) */
    badgeBg?: string;
    /** Lazy load */
    lazy?: boolean;
    /** Click handler */
    onClick?: () => void;
}

export interface EntityTypeProps extends BaseAvatarProps {
    /** Entity id (for avatar) */
    entityId: string | number;
}

export interface UserTypeProps extends BaseAvatarProps {
    /** User id for online track and link to profile */
    userId: number;
    /** If it has link - will proceed to the profile page of userId */
    hasLink?: boolean;
    /** Is user verified */
    verified?: boolean;
    /** Show user online badge or custom (default true) */
    showUserOnlineBadge?: boolean;
}

export type UserAvatarProps = UserTypeProps | EntityTypeProps;


const UserAvatar = observer((props: UserAvatarProps) => {

    const {
        userId,
        src,
        name,
        className,
        children,
        badgeBg,
        hasLink,
        badgeColor,
        onClick,
        entityId,
        verified = false,
        showUserOnlineBadge = !!userId,
        ...rest
    } = props as UserTypeProps & EntityTypeProps;

    const lazy = RouterStore.isModal
        ? false
        : (_.isBoolean(props.lazy) ? props.lazy : true);

    /** If provided userId - start to track online status */
    const { isOnline } = _.isFinite(+userId) && userId && showUserOnlineBadge
        ? useUserOnline(userId)
        : { isOnline: false };

    const badge = showUserOnlineBadge
        ? (!verified
            ? (isOnline ? 'online' : undefined)
            : <AvatarVerifiedPart isOnline={isOnline}/>)
        : props.badge;

    const handleClick = () => {
        onClick?.();

        if (hasLink) {
            Router.pushPage('/@:userId([0-9_A-Za-z]+)', { userId: `${userId}` });
        }
    };

    const placeholder = (
        <InitialsAvatar badge={badge}
                        size={rest.size}
                        className="overflow-visible"
                        gradientColor={calcInitialsAvatarColor(
                            _.isFinite(+userId) ? userId : +(entityId || 0),
                        )}
                        {...rest}>
            <span className="text-white">
                {name.charAt(0)}
                {children}
            </span>
        </InitialsAvatar>
    );

    const imageLoader = (
        <ImageLoader src={src}
                     loaded={<VkUiAvatar {...rest} badge={badge} src={src} children={children}/>}
                     placeholder={placeholder}/>
    );

    return (
        <Container badgeBg={badgeBg}
                   onClick={handleClick}
                   badgeColor={badgeColor}
                   className={[ className, hasLink ? 'cursor-pointer' : '' ].join(' ')}>
            <If is={lazy}>
                <LazyLoad height={rest.size} placeholder={placeholder}>
                    {imageLoader}
                </LazyLoad>
            </If>

            <If is={!lazy}>
                {imageLoader}
            </If>
        </Container>
    );
});


export { UserAvatar };
