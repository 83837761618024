/**
 * TournamentFiltersModal
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { Modal } from '@/cutils';

import { FiltersFormView } from './views/FilterFormView';


interface Props {
    id: string;
}


const TournamentFiltersModal = (props: Props) => {
    return (
        <Modal.Card id={props.id}>
            <Modal.Body isScrollable>
                <FiltersFormView/>
            </Modal.Body>
        </Modal.Card>
    );
};


export { TournamentFiltersModal };
