/**
 * InfoRowWrapper
 *
 * @author: exode <hello@exode.ru>
 */

import React, { ReactElement } from 'react';

import { If } from '@/cutils';

import { VerticalSeparator } from '@/components/Atoms/ContentCard';


interface Props {
    cells: ReactElement[];
    className?: string;
}


const InfoRowWrapper = (props: Props) => {

    const { cells, className } = props;

    return (
        <div className={[
            className,
            'flex items-center py-3 vk-rounded thin-border justify-around',
        ].join(' ')}>
            {cells.map((cell, index) => (
                <>
                    <div className="flex-1 px-4">
                        {cell}
                    </div>

                    <If is={index !== cells.length - 1}>
                        <VerticalSeparator height={36}/>
                    </If>
                </>
            ))}
        </div>
    );
};


export { InfoRowWrapper };
