/**
 * ContractsBanner
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { Link } from '@/cutils';

import { useI18n } from '@/hooks/core';

import { Button } from '@exode.ru/vkui';
import { Icon28ArticleOutline } from '@vkontakte/icons';

import { InnerHtml } from '@/components/Atoms/InnerHtml';
import { GradientBanner, GradientBannerImage } from '@/components/Atoms/GradientBanner';


export const ContractsBanner = () => {

    const { t } = useI18n('components.Promotion.ContextBanners');

    return (
        <GradientBanner imageTheme="light"
                        gradient="bg-content"
                        header={t('organizationInformation')}
                        subheader={(<InnerHtml content={t('yourRequisitesInn&OtherDocuments')}/>)}
                        image={(
                            <GradientBannerImage className="mt-14 -ml-12 banner__image">
                                <Icon28ArticleOutline width={140}
                                                      height={140}
                                                      style={{ marginRight: -20, transform: 'rotate(20deg)' }}/>
                            </GradientBannerImage>
                        )}
                        actions={(
                            <Link pushPage={{ id: '/manage/seller' }}>
                                <Button mode="primary" size="m">{t('information')}</Button>
                            </Link>
                        )}/>
    );
};
