/**
 * Apollo configuration
 *
 * @author: exode <hello@exode.ru>
 */

import toast from 'react-hot-toast';

import { createClient } from 'graphql-ws';

import { onError } from '@apollo/client/link/error';
import { getMainDefinition } from '@apollo/client/utilities';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { ApolloClient, ApolloLink, concat, from, HttpLink, InMemoryCache, split } from '@apollo/client';

import omitDeep from 'omit-deep-lodash';

import { IS_PROD } from '@/root/src/env';

import { basicRequestHeaders } from '@/api/headers';
import { graphQlApiUrl, wsGraphqlUrl } from '@/api/constants';

import { Icon20BracketsSlashOutline } from '@vkontakte/icons';


/**
 * Append client headers
 * @returns {{Authorization: string}}
 */
const graphQlHeaders = () => {
    return basicRequestHeaders();
};

/**
 * Gql ws client
 * @type {Client}
 */
export const gqlWsClient = createClient({
    lazy: false,
    url: wsGraphqlUrl,
    retryAttempts: Number.MAX_SAFE_INTEGER,
    connectionParams: graphQlHeaders,
    shouldRetry: () => true,
});

/**
 * Gql ws link
 * @type {GraphQLWsLink}
 */
export const gqlWsLink = new GraphQLWsLink(gqlWsClient);

/**
 * Http apollo client link
 * @type {HttpLink}
 */
const httpLink = new HttpLink({ uri: graphQlApiUrl });

/**
 * Toggle http and ws connection for subscribers and queries/mutations
 * @type {ApolloLink}
 */
const splitLink = split(({ query }) => {
        const definition = getMainDefinition(query);

        return (
            definition.kind === 'OperationDefinition' &&
            definition.operation === 'subscription'
        );
    },
    gqlWsLink,
    httpLink,
);

const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (!IS_PROD) {
        const errorMessage = networkError?.message
            || graphQLErrors?.map(({ message }) => message).join(' | ')
            || 'GQL error';

        toast.error(errorMessage, {
            position: 'bottom-right',
            icon: <Icon20BracketsSlashOutline width={20} height={20}/>,
        });
    }

    console.log(
        `⚠️ [${networkError ? 'Network' : 'GQL'} error]:`,
        networkError || graphQLErrors,
    );
});

/**
 * Http middleware
 * @type {ApolloLink}
 */
const headerMiddleware = new ApolloLink((operation, forward) => {
    const operationType = (operation: any) => operation.query.definitions[0].operation;

    if ([ 'mutation', 'query' ].includes(operationType(operation))) {
        operation.variables = omitDeep(operation.variables, '__typename');
    }

    operation.setContext(({ headers = {} }) => ({
        headers: { ...headers, ...graphQlHeaders() },
    }));

    return forward(operation);
});


/**
 * Apollo client
 * @type {ApolloClient<>}
 */
export const apolloClient = new ApolloClient({
    cache: new InMemoryCache(),
    link: from([ errorLink, concat(headerMiddleware, splitLink) ]),
    connectToDevTools: !IS_PROD,
});

/**
 * Get GraphQL cause
 * @param error
 * @returns {any}
 */
export const getGqlCause = (error: any) => {
    return error?.graphQLErrors[0]?.extensions?.exception?.response?.cause;
};
