/**
 * UpdateTitleProfileInput
 *
 * @author: exode <hello@exode.ru>
 */

import { IsDate, IsNotEmpty, IsOptional, IsString, MaxLength, MinDate, MinLength, ValidateIf } from 'class-validator';


export class UpdateTitleProfileInput {

    @IsString()
    @MinLength(1)
    @MaxLength(25)
    @ValidateIf((__, v) => !!v)
    readonly manualTitle: string | null;

    @IsNotEmpty()
    @IsString()
    @ValidateIf((__, v) => v !== null)
    readonly manualEmojiTitle: string | null;

    @IsOptional()
    @IsDate()
    @MinDate(() => new Date(), {
        message: () => `Выберите дату и время в будущем`,
    })
    readonly manualExpiredAt?: Date | null;

}
