/**
 * TournamentMeta
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import moment from 'moment';

import { PageParams } from 'router.tsx';

import { RoutePathType } from '@/router/paths';

import { gql } from '@apollo/client';
import { MetaPropsType } from '@/types/page';
import { MetaRequestOptions } from '@/root/src/meta';
import { tournamentTypeName } from '@/types/tournament';
import { educationSubjects, subjectPublicImages } from '@/types/subject';

import { serverApolloClient } from '@/api/ssr-api';
import { EducationSubject, TournamentEntity, TournamentStartMode } from '@/codegen/graphql';


export const tournamentMetaRequest = async (
    id: RoutePathType,
    params: PageParams,
    options?: MetaRequestOptions,
): Promise<Partial<MetaPropsType>> => {

    const query = gql`
        query TournamentFindOne($tournamentId:Int!) {
            tournamentFindOne(tournamentId: $tournamentId) {
                subject
                type
                startMode
                startAt
                categories {
                    subjectCategory {
                        name
                    }
                }
            }
        }
    `;

    const variables = { tournamentId: +params.tournamentId };

    const { data: { tournamentFindOne } } = await serverApolloClient.query({
        query,
        variables,
        fetchPolicy: options?.fetchPolicy || 'no-cache',
    });

    const { subject, type, startMode, startAt, categories } = tournamentFindOne as TournamentEntity;

    const subjectName = educationSubjects[subject as EducationSubject];

    const startAtText = startMode === TournamentStartMode.Scheduled
        ? `Старт ${moment(startAt).format('DD.MM.YYYY, в h:mm:ss')}`
        : 'Начинает создатель турнира';

    return {
        title: [ tournamentTypeName[type], subjectName ].join(' • '),
        description: [
            tournamentTypeName[type],
            startAtText,
            categories?.map(({ subjectCategory }) => subjectCategory.name).join(', '),
        ].join(' • '),
        keywords: [ tournamentTypeName[type] ].join(', '),
        image: subjectPublicImages[subject],
    };
};

export const tournamentMetaSoftRequest = async (id: RoutePathType, params: PageParams): Promise<Partial<MetaPropsType>> => {
    const meta = await tournamentMetaRequest(id, params);

    return _.omit(meta, [ 'title' ]);
};
