/**
 * AppButtonPart component
 *
 * @author: exode <hello@exode.ru>
 */

import React, { ReactElement } from 'react';

import { Link } from '@/cutils';
import { Button, ButtonProps } from '@exode.ru/vkui';


interface Props {
    text: string;
    icon: ReactElement;
    buttonProps?: ButtonProps;
}


const AppButtonPart = (props: Props) => {
    return (
        <Link pushPageMobile={{ id: '/app' }} pushModal={{ id: 'available-in-app' }}>
            <Button size="m"
                    mode="outline"
                    appearance="accent"
                    className="pr-[2px] min-w-[135px] border thick-border"
                    before={props.icon} {...props.buttonProps}>
                {props.text}
            </Button>
        </Link>
    );
};


export { AppButtonPart };
