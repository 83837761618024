/**
 * SubjectCategoriesTooltip
 *
 * @author: exode <hello@exode.ru>
 */

import React, { ReactElement } from 'react';

import { GqlResult } from '@/types/graphql';

import { Subhead } from '@exode.ru/vkui';
import { RichTooltip } from '@exode.ru/vkui/unstable';

import { TournamentFindOneQueryResult } from '@/codegen/graphql';


interface Props {
    children: ReactElement;
    categories: GqlResult<TournamentFindOneQueryResult>['tournamentFindOne']['categories'];
}


const SubjectCategoriesTooltip = (props: Props) => {

    const { children, categories } = props;

    return (
        <RichTooltip placement="bottom"
                     appearance="black"
                     shown={categories?.length === 0 ? false : undefined}
                     content={(
                         <Subhead className="max-w-[180px] p-2 flex flex-col gap-1">
                             {categories?.map(({ subjectCategory: { name, id } }) => (
                                 <li className="list-none" key={id}>
                                     {name}
                                 </li>
                             ))}
                         </Subhead>
                     )}>
            {children}
        </RichTooltip>
    );
};


export { SubjectCategoriesTooltip };
