/**
 * SessionItem component
 *
 * @author: exode <hello@exode.ru>
 */

import moment from 'moment';

import React from 'react';

import { If } from '@/cutils';
import { useSession } from '@/hooks/apollo';
import { SessionListItem } from '@/types/session';
import { useConfirm, useI18n } from '@/hooks/core';

import { SimpleCell, SimpleCellProps, Text } from '@exode.ru/vkui';

import { SessionInfo } from '@/components/User/Settings/SessionInfo';


interface Props extends Partial<SimpleCellProps> {
    session: SessionListItem;
}


export const SessionItem = (props: Props) => {

    const { t } = useI18n('components.User.Settings.SessionItem');

    const {
        after,
        onClick,
        session,
        indicator,
        session: {
            id,
            ip,
            alive,
            isCurrent,
            launcher,
            location,
            createdAt,
            lastActivityAt,
        },
        ...rest
    } = props;

    const { killSessionById, killSessionByIdLoading } = useSession();

    const getComponent = (
        isConfirmation = false,
        onClick?: React.MouseEventHandler<HTMLElement>,
    ) => (
        <SimpleCell multiline
                    key={id}
                    after={after}
                    onClick={onClick}
                    disabled={!!isCurrent || isConfirmation}
                    subtitle={<SessionInfo.Name launcher={launcher} createdAt={createdAt}/>}
                    before={(
                        <SessionInfo.Icon isAlive={alive}
                                          launcher={launcher}
                                          fill={isCurrent ? 'var(--dynamic_green)' : undefined}/>
                    )}
                    indicator={indicator || (isCurrent
                        ? (
                            <Text weight="regular" className="m:overflow-visible m:mr-1 fs-15">
                                {t('current')}
                            </Text>
                        )
                        : !isConfirmation && (
                        <Text weight="regular" className="hidden d:flex fs-15">
                            {t('activeAt')} {moment(lastActivityAt).format('DD.MM.YY в HH:mm').toLowerCase()}
                        </Text>
                    ))} {...rest}>
            <If is={!!location.country && !!location.city}>
                <>{location.country}, {location.city}</>
            </If>

            <If is={!location.country || !location.city}>
                <>{t('loginFromIp')} {ip}</>
            </If>

            <If is={isConfirmation}>
                <div className="fs-14 mt-0.5 text-secondary">
                    {t('activeAt')} {moment(lastActivityAt).format('DD.MM.YY в HH:mm').toLowerCase()}
                </div>
            </If>
        </SimpleCell>
    );

    const { openConfirm: openConfirmKillSessionById } = useConfirm({
        onConfirm: () => killSessionById(session.id),
        title: t('killSessionTitle'),
        subtitle: t('killSessionConfirm'),
        isLoading: killSessionByIdLoading,
        children: getComponent(true),
        buttons: {
            confirm: { text: t('killSession') },
        },
    });

    return getComponent(
        false,
        onClick
        || (
            !isCurrent
                ? () => openConfirmKillSessionById()
                : undefined
        ),
    );
};
