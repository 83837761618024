/**
 * CompanyInfoView
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { If } from '@/cutils';
import { CommonPlatformConfig } from '@/shared/types';

import { Link as VkUiLink, MiniInfoCell, Separator } from '@exode.ru/vkui';

import {
    Icon20ArticleOutline,
    Icon20CommunityName,
    Icon20GlobeOutline,
    Icon20GovernmentOutline,
    Icon20MentionOutline,
    Icon20PhoneOutline,
    Icon20PlaceOutline,
    Icon20RectangleInfoOutline,
} from '@vkontakte/icons';


interface Props {
    companyInfo: CommonPlatformConfig['organization']['info'];
}


const CompanyInfoView = (props: Props) => {

    const { companyInfo } = props;

    return (
        <>
            <div style={{ height: 15 }}/>

            <MiniInfoCell before={<Icon20CommunityName/>} textWrap="full" textLevel="primary">
                {companyInfo.name}
            </MiniInfoCell>

            <If is={!!companyInfo.site}>
                <MiniInfoCell before={<Icon20GlobeOutline/>} textWrap="full" textLevel="primary">
                    {companyInfo.site}
                </MiniInfoCell>
            </If>

            <If is={!!companyInfo.registrationNumber}>
                <MiniInfoCell before={<Icon20GovernmentOutline/>} textWrap="full" textLevel="primary">
                    {companyInfo.registrationNumber}
                </MiniInfoCell>
            </If>

            <If is={!!companyInfo.tel}>
                <MiniInfoCell before={<Icon20PhoneOutline/>} textWrap="full" textLevel="primary">
                    {companyInfo.tel}
                </MiniInfoCell>
            </If>

            <If is={!!companyInfo.inn}>
                <MiniInfoCell before={<Icon20RectangleInfoOutline/>} textWrap="full" textLevel="primary">
                    {companyInfo.inn}
                </MiniInfoCell>
            </If>

            <If is={!!companyInfo.fullName}>
                <MiniInfoCell before={<Icon20ArticleOutline/>} textWrap="full" textLevel="primary">
                    {companyInfo.fullName}
                </MiniInfoCell>
            </If>

            <Separator style={{ marginTop: 12, marginBottom: 12 }}/>

            <If is={!!companyInfo.email}>
                <MiniInfoCell before={<Icon20MentionOutline/>}>
                    <VkUiLink href={`mailto:${companyInfo.email}`} className="no-underline">
                        {companyInfo.email}
                    </VkUiLink>
                </MiniInfoCell>
            </If>

            <If is={!!companyInfo.address}>
                <MiniInfoCell before={<Icon20PlaceOutline/>}>
                    {companyInfo.address}
                </MiniInfoCell>
            </If>
        </>
    );
};


export { CompanyInfoView };
