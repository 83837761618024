/**
 * Use banner
 *
 * @author: exode <hello@exode.ru>
 */

import { useState } from 'react';

import { Storage } from '@/api/storage';

import { LocalStorageKeyType } from '@/types/config';
import { PromoBannerLsKey } from '@/types/promotion';


const useBanner = (
    storageKey: LocalStorageKeyType,
    bannerKey: PromoBannerLsKey,
) => {
    const [ show, setShow ] = useState(!Storage.get(storageKey)?.includes(bannerKey));

    const closeBanner = () => {
        const banners = Storage.get(storageKey);

        setShow(false);
        Storage.set(storageKey, [ ...banners ?? [], bannerKey ]);
    };

    return { show, closeBanner };
};


export { useBanner };
