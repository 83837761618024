/**
 * NativeService
 *
 * @author: exode <hello@exode.ru>
 */

import { isJSON } from 'class-validator';

import { IS_NATIVE } from '@/root/src/env';

import { DocumentEvent } from '@/types/window';
import { NativeBridgeEvent } from '@/shared/types';

import { UserAuthStore } from '@/store/user/auth';
import { PreferenceStore } from '@/store/preference/preference';

import { Storage } from '@/api/storage';
import { sendToBridge } from '@/hooks/core';
import { BaseService } from '@/services/Core/Base';
import { NativeBrowserService } from '@/services/Native/Browser';
import { NativeFirebaseService } from '@/services/Native/Firebase';


class NativeService extends BaseService {

    /**
     * Регистрация сервиса
     */
    static register() {
        if (!this.isNative) {
            return;
        }

        this.addBridgeMessageListener();
        this.syncWebViewToNativeBridge();
    }

    /**
     * Инициализация сервиса
     */
    static init() {
        if (!this.isNative) {
            return;
        }
    }

    /**
     * Проверка платформы native
     * @protected
     */
    protected static get isNative() {
        return IS_NATIVE && window.ReactNativeWebView;
    }

    /**
     * Отправка события через мост
     * @param {NativeBridgeEvent} message
     */
    static sendToBridge(message: NativeBridgeEvent) {
        sendToBridge(message);
    }

    /**
     * Добавления прослушивание событий через мост
     */
    static addBridgeMessageListener() {
        window.addEventListener('message', async (nativeEvent) => {
            try {
                if (isJSON(nativeEvent?.data)) {
                    const event = JSON.parse(nativeEvent.data) as NativeBridgeEvent;

                    await this.handleBridgeEvent(event);
                }
            } catch (e) {}
        });
    }

    /**
     * Синхронизация при изменении состояния в WebView
     */
    static syncWebViewToNativeBridge() {
        const onAuthStateChanged = () => {
            this.sendToBridge({
                module: 'NativeCore',
                method: 'authStateChanged',
                payload: {
                    isLoggedIn: UserAuthStore.isLoggedIn,
                    token: Storage.get('user:auth-token'),
                },
            });
        };

        const onPreferenceSchemeChanged = () => {
            this.sendToBridge({
                module: 'NativeCore',
                method: 'preferenceSchemeChanged',
                payload: {
                    scheme: PreferenceStore.scheme,
                },
            });
        };

        /** Trigger at once to native */
        onAuthStateChanged();

        if (Storage.get('preference:scheme')) {
            onPreferenceSchemeChanged();
        }

        document.addEventListener(DocumentEvent.AuthStateChanged, onAuthStateChanged);
        document.addEventListener(DocumentEvent.PreferenceSchemeChanged, onPreferenceSchemeChanged);
    }

    /**
     * Handle bridge event
     * @param {NativeBridgeEvent} event
     */
    static async handleBridgeEvent(event: NativeBridgeEvent) {
        switch (event.module) {
            case 'NativeCore':
                switch (event.method) {
                    case 'handleOpenUrl':
                        await NativeBrowserService.handleOpenUrl(event.payload?.url);

                        break;

                    case 'nativePreferenceSchemeChanged':
                        await PreferenceStore.setScheme(
                            event.payload?.colorScheme === 'light'
                                ? 'bright_light'
                                : 'space_gray',
                            true,
                            false,
                        );

                        break;
                }

                break;

            case 'FirebaseMessaging':
                switch (event.method) {
                    case 'handleNewFcmToken':
                        NativeFirebaseService.handleNewFcmToken(event.payload?.token);

                        break;
                }

                break;
        }
    }

}


export { NativeService };
