/**
 * ShareTournamentResultBanner
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { UserAuthStore } from '@/store/user/auth';

import { GetPlacesQueryResult } from '@/codegen/graphql';
import { GqlResult } from '@/types/graphql';

import { Button } from '@exode.ru/vkui';

import { Link } from '@/cutils';

import { TournamentResultBanner } from '@/images/kits';
import { GradientBanner } from '@/components/Atoms/GradientBanner';


interface Props {
    tournamentId: number;
    result: GqlResult<GetPlacesQueryResult>['tournamentResultFindMany'];
    className?: string;
}


const ShareTournamentResultBanner = (props: Props) => {

    const { tournamentId, className, result: { items } } = props;

    const winnerIds = items?.map(({ user }) => user.id);

    const subheader = winnerIds?.includes(UserAuthStore.user!.id)
        ? <span>Расскажите о своей победе<br/>в турнире всему миру!</span>
        : <span>Играйте с друзьями, создавайте<br/>турниры и готовьтесь вместе!</span>;

    return (
        <GradientBanner imageTheme="dark"
                        gradient="bg-content"
                        className={className}
                        header="Поделиться результатом"
                        subheader={subheader}
                        bgImage={TournamentResultBanner}
                        actions={(
                            <Link pushModal={{
                                id: 'tournament-share',
                                params: {
                                    tournamentId: `${tournamentId}`,
                                    inviteKey: items?.[0]?.tournament.uuid || '',
                                },
                            }}>
                                <Button mode="primary" size="m">
                                    Поделиться
                                </Button>
                            </Link>
                        )}/>
    );
};


export { ShareTournamentResultBanner };
