/**
 * StickyObserver util
 *
 * @author: exode <hello@exode.ru>
 */

import React, { cloneElement, ReactElement, useEffect, useRef, useState } from 'react';


interface Props {
    children: ReactElement;
    stackedProps: Partial<any> & React.Attributes;
    unStackedProps?: Partial<any> & React.Attributes;
}


const StickyObserver = (props: Props) => {

    const { children, stackedProps, unStackedProps = {} } = props;

    const [ stacked, setStacked ] = useState(false);
    const [ side, setSide ] = useState<'top' | 'bottom' | null>(null);

    const ref = useRef<HTMLElement>();

    useEffect(() => {
        if (!ref?.current) {
            return;
        }

        const observer = new IntersectionObserver(
            ([ e ]) => {
                const isStacked = !e.isIntersecting;

                setStacked(isStacked);

                isStacked && setSide(e.boundingClientRect.top < 0 ? 'top' : 'bottom');
            },
            { threshold: [ 1 ] },
        );

        observer.observe(ref.current);

        return () => {
            observer.disconnect();
        };
    }, [ ref ]);

    return cloneElement(children, {
        ref,
        getRootRef: ref,
        ...(stacked ? { ...stackedProps, side, ['data-side']: side } : unStackedProps),
    });
};


export { StickyObserver };
