/**
 * CourseCardSkeleton
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { Skeleton } from '@mui/material';

import { Container } from './CourseCardSkeleton.styled';


interface Props {
    className?: string;
    minHeight?: number;
}


const CourseCardSkeleton = (props: Props) => {

    const { className, minHeight } = props;

    return (
        <Container style={{ minHeight }}
                   className={[ 'p-5 m:p-4 bg-content vk-rounded thin-border', className ].join(' ')}>
            <div className="flex justify-between">
                <div>
                    <div className="flex gap-2">
                        <Skeleton variant="text" width={120} height={20}/>
                        <Skeleton variant="text" width={120} height={20}/>
                    </div>

                    <Skeleton className="rounded-lg" variant="text" width={245} height={72}/>
                </div>

                <Skeleton className="rounded-lg" width={64} height={64} variant="rectangular"/>
            </div>

            <div className="h-6"/>

            <div className="flex gap-2">
                <Skeleton className="rounded-lg" variant="text" width={70} height={20}/>
                <Skeleton className="rounded-lg" variant="text" width={70} height={20}/>
            </div>
        </Container>
    );
};


export { CourseCardSkeleton };
