/**
 * TournamentsTab
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { Page } from '@/pages/Core';

import { ReadyAndUpcomingTournamentsDocument, ScheduledTournamentsDocument } from '@/codegen/graphql';

import { Link, PullToRefresh, Responsive } from '@/cutils';

import { FloatingButton } from '@/components/Atoms/FloatingButton';

import { HeaderView } from './views/HeaderView';
import { ContextView } from './views/ContextView';
import { BlitzTournamentView } from './views/BlitzTournamentView';
import { ReadyAndUpcomingTournamentsView } from './views/ReadyAndUpcomingTournamentsView';
import { ScheduledTournamentView } from './views/ScheduledTournamentView';


const TournamentTab = () => {
    return (
        <Page.Wrapper>
            <Page.Head>
                <HeaderView/>
            </Page.Head>

            <Page.Content withTabbar>
                <PullToRefresh queries={[ ReadyAndUpcomingTournamentsDocument, ScheduledTournamentsDocument ]}>
                    <Page.Wrapper params={[ 'page' ]}>
                        <ReadyAndUpcomingTournamentsView/>

                        <BlitzTournamentView/>

                        <ScheduledTournamentView/>

                        <Responsive.Mobile>
                            <Link pushPage={{ id: '/tournaments/create' }}>
                                <FloatingButton/>
                            </Link>
                        </Responsive.Mobile>
                    </Page.Wrapper>
                </PullToRefresh>
            </Page.Content>

            <Page.Context>
                <ContextView/>
            </Page.Context>
        </Page.Wrapper>
    );
};


export { TournamentTab };
