/**
 * ComponentHelper
 *
 * @author: exode <hello@exode.ru>
 */

import DOMPurify from 'dompurify';

import linkifyHtml from 'linkify-html';

import React, { ReactElement } from 'react';

import { Parse } from '@/utils';

import { InnerHtmlProps } from '@/components/Atoms/InnerHtml';


interface NamedChildrenProps {
    cname: string;
}


class ComponentHelper {

    /**
     * Getting all child components by their static property "cname"
     * grouped by their name (if there are several, then the latter overrides)
     * @param {React.ReactNode} children
     * @returns {{[p: string]: any}}
     */
    static getNamedChildren(children: React.ReactNode) {
        const result: { [key: string]: ReactElement | JSX.Element } = {};

        React.Children.forEach(children, (child: any) => {
            const element = child as ReactElement;
            const type = element.type as unknown as NamedChildrenProps;
            const name = type?.cname;

            if (!name) {
                return;
            }

            result[name] = element;
        });

        return result;
    }

    /**
     * Sanitize HTML (XSS)
     * @param {string} html
     * @returns {string}
     */
    static sanitizeHtml(html: string) {
        return DOMPurify.sanitize(html);
    }

    /**
     * Common parse content
     * @param {string | undefined} content
     * @param {InnerHtmlProps['include']} include
     * @param {'timeCodes'[]} exclude
     * @returns {string}
     */
    static commonParseContent(
        content: string | undefined = '',
        include?: InnerHtmlProps['include'],
        exclude?: InnerHtmlProps['exclude'],
    ) {
        const result = { content };

        /** IMPORTANT: Order matters */
        const parsers = [
            include?.includes('nlToBr') && Parse.nlToBr,
            this.sanitizeHtml,
            !exclude?.includes('fillLinks') && this.linkifyHtml,
            include?.includes('timeCodes') && Parse.parseTimeCodes,
        ].filter(e => e) as Function[];

        for (const parser of parsers) {
            result.content = parser(result.content);
        }

        return result.content;
    }

    /**
     * Linkify html
     * @param {string} html
     * @returns {string}
     */
    static linkifyHtml(html: string) {
        return linkifyHtml(html, {
            target: '_blank',
            rel: 'noopener noreferrer',
            className: 'text-accent underline',
        });
    }

}


export { ComponentHelper };
