/**
 * TournamentCreateModal
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { Modal } from '@/cutils';

import { TournamentCreationFormView } from '@/pages/Tournament/Creation/views/TournamentCreationFormView';


interface Props {
    id: string;
}


const TournamentCreateModal = (props: Props) => {
    return (
        <Modal.Card id={props.id}>
            <Modal.Body isScrollable className="mt-0">
                <TournamentCreationFormView/>
            </Modal.Body>
        </Modal.Card>
    );
};


export { TournamentCreateModal };
