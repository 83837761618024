/**
 * ContextMenuItems component
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import React, { cloneElement, ReactElement, ReactNode } from 'react';

import { Page } from '@/pages/Core';
import { useI18n } from '@/hooks/core';
import { observer } from '@/store/user/seller';

import { useLocation } from '@/router/index';
import { PageAction } from '@/services/Utils/Router';

import { If, Link } from '@/cutils';
import { ContextMenuTab } from '@/types/manage';

import { Icon24ArrowUturnLeftOutline } from '@vkontakte/icons';
import { Button, ButtonProps, List, SimpleCell, SplitCol, Text } from '@exode.ru/vkui';

import { ContextMenuItemsContainer } from '@/components/Atoms/ContextMenuItems/ContextMenuItems.styled';


export interface ContextMenuItemsProps {
    tabs: ContextMenuTab[];
    statusComponent?: ReactElement;
    header?: ReactNode;
    loading?: boolean;
    after?: ReactNode;
    actions?: ReactNode;
    subActions?: ReactNode;
    backTo?: PageAction;
    backToIcon?: ReactNode;
    backToText?: string;
    backToProps?: ButtonProps;
    withSeparator?: boolean;
}


const ContextMenuItems = observer((props: ContextMenuItemsProps) => {

    const { t } = useI18n('components.Atoms.ContextMenuItems');

    const {
        loading,
        after,
        tabs,
        header,
        statusComponent,
        actions,
        subActions,
        backTo,
        backToProps,
        backToText = t('back'),
        backToIcon = <Icon24ArrowUturnLeftOutline/>,
        withSeparator = !!actions || !subActions || !!backTo,
    } = props;

    const { route: { pageId, params } } = useLocation();

    const className = (item: ContextMenuTab) => {
        const { path, extraPaths, exactParams, parentPaths } = item;

        const isCurrentPage = pageId === path.id;

        const activeConditions = [
            !exactParams && isCurrentPage,
            !exactParams && extraPaths?.some((p) => p === pageId),
            !exactParams && parentPaths?.some((p) => pageId.includes(p)),
            exactParams && isCurrentPage && params[exactParams.key] === exactParams.value,
        ];

        return activeConditions?.some(e => e) ? 'active' : '';
    };

    return (
        <ContextMenuItemsContainer withHeader={!!header}>
            <SplitCol className="viewport__right-menu">
                {statusComponent && cloneElement(statusComponent, { after })}

                <Page.Row header={header}
                          className="select-none"
                          data-test="context.menu-items"
                          separator={withSeparator ? undefined : 'hide'}>
                    {tabs.filter(e => !e.hidden).map((tab, i) => {
                        const { title, path, subfields, exactParams, parentPaths, ...rest } = tab;

                        return (
                            <React.Fragment key={i}>
                                <Link pushPage={path as PageAction}>
                                    <SimpleCell expandable
                                                className={className(tab)}
                                                after={((_.isFunction(after) && loading)
                                                        ? after(loading, pageId.includes(path.id))
                                                        : after
                                                )}
                                                {...rest}>
                                        {title}
                                    </SimpleCell>
                                </Link>

                                <If is={!!subfields && !!parentPaths && parentPaths.some((p) => pageId.includes(p))}>
                                    <List className="flex flex-col gap-3 my-2 ml-7 text-subhead">
                                        {subfields?.map((subfield, i) => (
                                            <Link key={subfield.text + i} pushPage={subfield.path as PageAction}>
                                                <Text weight="regular" className={(
                                                    [ subfield.path.id, ...(subfield.extraPaths || []) ].includes(pageId)
                                                        ? 'text-primary'
                                                        : ''
                                                )}>
                                                    {subfield.text}
                                                </Text>
                                            </Link>
                                        ))}
                                    </List>
                                </If>
                            </React.Fragment>
                        );
                    })}
                </Page.Row>

                <div className="flex flex-col gap-4">
                    <>{actions}</>

                    <If is={!!backTo}>
                        <Link pushPage={backTo}>
                            <Button stretched size="l" before={backToIcon} data-test="button.back" {...backToProps}>
                                {backToText}
                            </Button>
                        </Link>
                    </If>

                    <>{subActions}</>
                </div>
            </SplitCol>
        </ContextMenuItemsContainer>
    );
});


export { ContextMenuItems };
