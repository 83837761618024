/**
 * Popout util
 *
 * @author: exode <hello@exode.ru>
 */

interface Props {
    children: any;
    id: string;
    withDarkness?: boolean;
}


const Popout = (props: Props) => {
    return props.children;
};


export { Popout };
