/**
 * InviteView
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { FriendshipStatus, ListFriendsByStatusQuery } from '@/codegen/graphql';

import { Header } from '@exode.ru/vkui';

import { Graphql } from '@/cutils';
import { Page } from '@/pages/Core';

import { SocialShareButtons } from '@/components/Atoms/SocialShareButtons';

import { InviteFriendsView } from '@/modals/Tornament/Friends/views/InviteFriendsView';
import { friendsByStatusListConfig } from '@/pages/Profile/Friends/views/FriendsContentView';


interface Props {
    link: string;
    tournamentId: number;
}


const InviteView = (props: Props) => {
    return (
        <>
            <ListFriendsByStatusQuery children={(result) => (
                <>
                    <Graphql.Loading result={result}>
                        <Page.Spinner/>
                    </Graphql.Loading>

                    <Graphql.Success result={result}>
                        {({ friendshipFindMany: friends }) => (
                            <InviteFriendsView tournamentId={props.tournamentId} friends={friends}/>
                        )}
                    </Graphql.Success>
                </>
            )} variables={{
                ...friendsByStatusListConfig,
                filter: { status: FriendshipStatus.Confirmed },
            }}/>

            <SocialShareButtons showVkPublish link={props.link} header={(
                <Header mode="secondary" className="mt-1">
                    Социальные сети
                </Header>
            )}/>
        </>
    );
};


export { InviteView };
