/**
 * SectionPart
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import React, { cloneElement, Fragment } from 'react';

import { observer } from '@/store/user/auth';
import { SellerStore } from '@/store/user/seller';

import { Separator, SimpleCell, Title } from '@exode.ru/vkui';

import { If, Link, Portal } from '@/cutils';
import { useI18nStatic } from '@/hooks/core';
import { Router } from '@/services/Utils/Router';

import { DrawerSectionType, manageDrawerSections, ManageServiceItem } from '@/types/manage';

import { MenuIconPart } from './MenuIcon';

import { MenuItem } from '../DesktopManageServicesList.styled';


interface Props {
    section: DrawerSectionType;
}


const SectionPart = observer((props: Props) => {

    const { section } = props;

    const { t: tStatic } = useI18nStatic('static.types.drawer');

    const items = manageDrawerSections(tStatic)[section].items;

    return (
        <>
            {_.entries(items).map(([ __, subSection ], index) => {

                const {
                    icon,
                    title,
                    path,
                    spaces,
                    subfields,
                } = subSection;

                const isActiveByPageId = Router.isActiveBySpaces(spaces);

                return (
                    <Fragment key={index}>
                        <Link pushPage={path && !_.isEmpty(path) ? path : undefined}>
                            <MenuItem className={isActiveByPageId ? 'bg-accent text-white' : ''} onClick={() => {
                                SellerStore.toggleDrawer(false);
                                _.isEmpty(path) && SellerStore.openSection(section);
                            }}>
                                <MenuIconPart title={title} icon={cloneElement(icon, {
                                    fill: isActiveByPageId ? 'var(--white)' : 'currentColor',
                                })}/>
                            </MenuItem>
                        </Link>

                        <If is={SellerStore.drawer.section === section}>
                            <Portal id="menu:context-inner">
                                <>
                                    {(subfields as ManageServiceItem['subfields'])?.map((field, i) => {
                                        if (field.type === 'item') {
                                            return (
                                                <div key={i} onClick={() => SellerStore.toggleDrawer(false)}>
                                                    <Link key={i} pushPage={field.path}>
                                                        <SimpleCell before={field.icon} subtitle={field.description}>
                                                            {field.title}
                                                        </SimpleCell>
                                                    </Link>
                                                </div>
                                            );
                                        }

                                        return (
                                            <Fragment key={i}>
                                                <If is={!!field.divider}>
                                                    <Separator wide className="pt-3 pb-2"/>
                                                </If>

                                                <Title level="2"
                                                       weight="regular"
                                                       className="px-4 text-secondary uppercase">
                                                    <span className="fs-12">
                                                        {field.section}
                                                    </span>
                                                </Title>
                                            </Fragment>
                                        );
                                    })}
                                </>
                            </Portal>
                        </If>
                    </Fragment>
                );
            })}

            <If is={!_.isEmpty(items)}>
                <Separator className="w-full my-3 opacity-25 dark:opacity-75"/>
            </If>
        </>
    );
});


export { SectionPart };
