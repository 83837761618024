/**
 * VideoPart
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { APITypes } from 'plyr-react';

import { useParseToPlyrSources } from '@/hooks/core';

import { Plyr } from '@/components/Atoms/Plyr';
import { RndVideoOrientation } from '@/components/Atoms/RndVideo';


interface Props {
    videoLink: string;
    plyrVideoRef: React.RefObject<APITypes>;
    orientation?: RndVideoOrientation;
}


const VideoPart = React.memo((props: Props) => {

    const { videoLink, plyrVideoRef, orientation } = props;

    return (
        <Plyr.Wrapper styles={{ orientation, boxShadow: 'none', roundedOnMobile: true }}>
            <Plyr.Video ref={plyrVideoRef}
                        deps={[ videoLink ]}
                        source={useParseToPlyrSources(videoLink)}
                        options={{ controls: [], hideControls: false, autoplay: false, muted: false }}/>
        </Plyr.Wrapper>
    );
});


export { VideoPart };
