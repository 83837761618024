/**
 * Content element shared types
 *
 * @author: exode <hello@exode.ru>
 */

/** EditorJs block content */
export interface EditorJsBlockContent {
    time: number;
    version: string;
    blocks: Record<any, any>[];
}

/** Video content */
export interface VideoContent {
    type?: VideoContentType;
    duration?: number;
    location?: string;

    timecodes?: {
        time: string;
        description: string;
    }[];

    meta?: {
        size?: number | null;
        encoding?: string | null;
        mimeType?: string | null;
        originalName?: string | null;
    };
}

export enum VideoContentType {
    Local = 'Local',
    ThirdParty = 'ThirdParty',
}

/** Audio content */
export interface AudioContent {
    duration?: number;
    location?: string;

    timecodes?: {
        time: string;
        description: string;
    }[];

    meta?: {
        size?: number | null;
        encoding?: string | null;
        mimeType?: string | null;
        originalName?: string | null;
    };
}
