/**
 * Accordion component
 *
 * @author: exode <hello@exode.ru>
 */

import React, { ReactElement, useState } from 'react';

import { Tappable, TitleProps } from '@exode.ru/vkui';
import { Title } from '@exode.ru/vkui/tokenized';
import { Icon20Add, Icon24MinusOutline } from '@vkontakte/icons';

import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { Accordion as MuiAccordion, SxProps } from '@mui/material';

import { If, SvgComponent } from '@/cutils';

import { Container } from './Accordion.styled';


export interface AccordionProps {
    children: ReactElement | ReactElement[];
    rawCell?: boolean;
    header?: ReactElement;
    before?: ReactElement;
    title?: string;
    expandedTitle?: string;
    titleWeight?: TitleProps['weight'];
    verticalSpacing?: string;
    verticalPadding?: string;
    horizontalPadding?: string;
    muiSx?: SxProps;
    muiClassName?: string;
    headerSpacing?: string;
    iconOpen?: ReactElement;
    iconClose?: ReactElement;
    isExpanded?: boolean;
    titleClassName?: string;
    tappableClassName?: string;
}


const Accordion = (props: AccordionProps) => {

    const {
        before,
        title,
        expandedTitle,
        header,
        children,
        muiSx,
        muiClassName,
        iconClose,
        iconOpen,
        titleClassName,
        tappableClassName,
        isExpanded = false,
        titleWeight = '2',
        ...rest
    } = props;

    const [ expanded, setExpanded ] = useState(isExpanded);

    const icon = expanded
        ? (
            <SvgComponent element={iconClose || <Icon24MinusOutline/>}
                          svgProps={{ width: 24, height: 24, fill: 'var(--accent)' }}/>
        )
        : (
            <SvgComponent element={iconOpen || <Icon20Add/>}
                          svgProps={{ width: 24, height: 24, fill: 'var(--accent)' }}/>
        );

    return (
        <Container {...rest}>
            <MuiAccordion sx={muiSx}
                          className={muiClassName}
                          expanded={expanded}
                          onChange={() => setExpanded(prev => !prev)}>
                <>
                    <If is={!!props.rawCell}>
                        <Tappable data-accordion-expander=""
                                  className={tappableClassName}
                                  onClick={() => setExpanded(!expanded)}>
                            {header && React.cloneElement(header, { after: icon })}
                        </Tappable>
                    </If>

                    <If is={!props.rawCell}>
                        <Tappable className={tappableClassName}>
                            <AccordionSummary expandIcon={icon}>
                                <If is={!!title}>
                                    <div className="flex items-center gap-2">
                                        {before}

                                        <Title level="3" weight={titleWeight} className={titleClassName}>
                                            {expanded && expandedTitle ? expandedTitle : title}
                                        </Title>
                                    </div>
                                </If>
                                {header}
                            </AccordionSummary>
                        </Tappable>
                    </If>
                </>

                <AccordionDetails>
                    {children}
                </AccordionDetails>
            </MuiAccordion>
        </Container>
    );
};


export { Accordion };
