/**
 * TournamentFriendsModal
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { useStableModalParams } from '@/router/index';

import { FriendshipStatus, ListFriendsByStatusQuery } from '@/codegen/graphql';

import { Page } from '@/pages/Core';
import { Title } from '@exode.ru/vkui';
import { Graphql, Modal } from '@/cutils';

import { friendsByStatusListConfig } from '@/pages/Profile/Friends/views/FriendsContentView';
import { InviteFriendsView } from './views/InviteFriendsView';


interface Props {
    id: string;
}


const TournamentFriendsModal = (props: Props) => {

    const [ { tournamentId } ] = useStableModalParams();

    return (
        <Modal.Card id={props.id}
                    header={<Title className="modal-title" level="2" weight="3">Пригласить друзей</Title>}>
            <Modal.Body isScrollable fullHeight>
                <ListFriendsByStatusQuery children={(result) => (
                    <>
                        <Graphql.Loading result={result}>
                            <Page.Spinner/>
                        </Graphql.Loading>

                        <Graphql.Success result={result}>
                            {({ friendshipFindMany: friends }) => (
                                <InviteFriendsView tournamentId={+tournamentId} friends={friends}/>
                            )}
                        </Graphql.Success>

                        <Graphql.Error result={result}/>
                    </>
                )} variables={{
                    ...friendsByStatusListConfig,
                    filter: { status: FriendshipStatus.Confirmed },
                }}/>
            </Modal.Body>
        </Modal.Card>
    );
};


export { TournamentFriendsModal };
