/**
 * App profile subscriptions
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { observer, SubscriptionStore } from '@/store/subscription/subscription';

import { ProfileStatusSubscription } from './ProfileStatusSubscription';


const AppProfileSubscriptions = observer(() => {
    return (
        <>
            {[ ...SubscriptionStore.profileIds ].map((profileId) => (
                <ProfileStatusSubscription key={profileId} profileId={profileId}/>
            ))}
        </>
    );
});


export { AppProfileSubscriptions };
