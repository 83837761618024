/**
 * Graphql util
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import moment from 'moment';

import React, { ReactElement, useEffect, useRef, useState } from 'react';

import Countdown from 'react-countdown';

import { Exact } from '@/codegen/graphql';
import { ApolloError, QueryResult } from '@apollo/client';

import { If } from '@/cutils';
import { useI18n } from '@/hooks/core';
import { Spinner } from '@exode.ru/vkui';

import { InnerHtml } from '@/components/Atoms/InnerHtml';
import { ErrorFallback } from '@/components/App/ErrorFallback';


type Result<T> = QueryResult<T, Exact<any>>;

interface SuccessProps<T> {
    children: (t: NonNullable<T>) => any;
    result: Result<T>;
}

interface LoadingProps<T> {
    children: ReactElement | ReactElement[];
    result: Result<T>;
}

interface ErrorProps<T> {
    children?: ((e: ApolloError) => any) | ReactElement | ReactElement[];
    result: Result<T>;
    disableRefetch?: boolean;
}


/**
 * Success renderer
 * @param {SuccessProps} props
 * @constructor
 */
const Success = <T, >(props: SuccessProps<T>) => {

    const { children, result: { data, loading, error } } = props;

    const success = !loading && !error && data;

    return (
        <>
            {success && children(data as NonNullable<T>)}
        </>
    );
};

/**
 * Loading renderer
 * @param {LoadingProps} props
 * @constructor
 */
const Loading = <T, >(props: LoadingProps<T>) => {

    const { children, result: { loading } } = props;

    return (
        <>
            {loading && children}
        </>
    );
};

/**
 * Error renderer
 * @param {ErrorProps} props
 * @constructor
 */
const Error = <T, >(props: ErrorProps<T>) => {

    const {
        children,
        disableRefetch = false,
        result: { error, refetch },
    } = props;

    const { t } = useI18n('components.Utils');

    const timeoutRef = useRef<NodeJS.Timeout>();
    const countdownRef = useRef<Countdown | null>(null);

    const getRefetchAt = () => moment().add(9, 'seconds');

    const clearRefetchTimer = () => {
        timeoutRef.current && clearTimeout(timeoutRef.current);
    };

    const [ refetchAt, setRefetchAt ] = useState(getRefetchAt());

    const timeoutCb = async () => {
        try {
            await refetch();
        } catch (e) {
            clearRefetchTimer();
            timeoutRef.current = setTimeout(timeoutCb, 9000);
        }
    };

    useEffect(() => {
        if (children && error && !disableRefetch) {
            timeoutRef.current = setTimeout(timeoutCb, 9000);
        }

        return () => {
            clearRefetchTimer();
        };
    }, [ children, error ]);

    if (!children && error) {
        return (
            <ErrorFallback message={error?.message} onClick={() => refetch()} caption={(
                <Countdown ref={countdownRef} autoStart={!disableRefetch} date={refetchAt.toDate()} onComplete={(
                    async () => {
                        try {
                            await refetch();
                        } catch (e) {}

                        setRefetchAt(getRefetchAt());
                        countdownRef.current?.api?.start();
                    }
                )} renderer={({ seconds }) => (
                    <>
                        <If is={seconds > 0}>
                            <InnerHtml content={t('autoRetryAfter', { seconds })}/>
                        </If>

                        <If is={seconds === 0}>
                            <Spinner size="small"/>
                        </If>
                    </>
                )}/>
            )}/>
        );
    }

    if (_.isFunction(children)) {
        return (<>{error && children(error)}</>);
    }

    return (<>{error && children}</>);
};


const Graphql = {
    Success,
    Loading,
    Error,
};


export { Graphql };
