/**
 * TypingItem styled container
 *
 * @author: exode <hello@exode.ru>
 */

import styled from 'styled-components';


export const Container = styled.div`
  color: var(--accent);
  display: flex;
  align-items: center;
  gap: 0.5rem;

  .dot {
    width: 5px;
    height: 5px;
    margin: 0 2px;
    background: var(--accent);
    border-radius: 50%;
    opacity: 0;
    animation: loadingFade 1.25s infinite;
  }

  .dot:nth-child(1) {
    animation-delay: 0s;
  }

  .dot:nth-child(2) {
    animation-delay: 0.2s;
  }

  .dot:nth-child(3) {
    animation-delay: 0.4s;
  }

  @keyframes loadingFade {
    0% {
      opacity: 0.05;
    }
    50% {
      opacity: 0.8;
    }
    100% {
      opacity: 0.05;
    }
  }
`;
