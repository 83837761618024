/**
 * SubjectCategorySelector
 *
 * @author: exode <hello@exode.ru>
 */

import React, { useState } from 'react';

import { useI18n } from '@/hooks/core';
import { observer } from '@/pages/Core';

import { If } from '@/cutils';

import { SegmentedControl } from '@exode.ru/vkui';

import { FilterCategorySubjectInput } from '@/codegen/graphql';

import { CategoryPart } from '@/components/Subject/CategorySelector/parts/CategoryPart';
import { SelectionPart } from '@/components/Subject/CategorySelector/parts/SelectionPart';

import { CategorySwitcher } from './interfaces';


export interface SubjectCategorySelectorProps {
    selectedSubjectCategoryIds: number[];
    onCategorySelect: (subjectCategoryIds: number[]) => void;
    filter: FilterCategorySubjectInput;
}


const SubjectCategorySelector = observer((props: SubjectCategorySelectorProps) => {

    const { t } = useI18n('components.Subject.CategorySelector');

    const [ switcher, setSwitcher ] = useState<CategorySwitcher>(CategorySwitcher.Categories);

    return (
        <>
            <SegmentedControl value={switcher}
                              onChange={(value) => setSwitcher(value as CategorySwitcher)}
                              className="thin-border mb-4 sticky top-0 bg-modal-content z-[1] m:mx-2"
                              options={[
                                  {
                                      label: t('categories'),
                                      value: CategorySwitcher.Categories,
                                  },
                                  {
                                      label: t('selections'),
                                      value: CategorySwitcher.Selections,
                                  },
                              ]}/>

            <If is={switcher === CategorySwitcher.Categories}>
                <CategoryPart {...props}/>
            </If>

            <If is={switcher === CategorySwitcher.Selections}>
                <SelectionPart {...props}/>
            </If>
        </>
    );
});


export { SubjectCategorySelector };
