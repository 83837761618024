/**
 * ChangeVisibilityItem
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { If } from '@/cutils';

import { IconButton } from '@mui/material';

import { Spinner } from '@exode.ru/vkui';
import { Icon24HideOutline, Icon24ViewOutline } from '@vkontakte/icons';


interface Props {
    visible: boolean;
    loading: boolean;
    onChange: (visible: boolean) => void;
    onClick?: () => void;
    className?: string;
}


const ChangeVisibilityItem = (props: Props) => {

    const { visible, loading, onChange, onClick, className } = props;

    return (
        <IconButton className={className} onClick={() => {
            onClick?.();

            onChange(!visible);
        }}>
            <If is={!loading}>
                {(visible
                        ? <Icon24ViewOutline fill="var(--accent)"/>
                        : <Icon24HideOutline fill="var(--accent)"/>
                )}
            </If>

            <If is={loading}>
                <Spinner/>
            </If>
        </IconButton>
    );
};


export { ChangeVisibilityItem };
