/**
 * UpdatePasswordUnauthInput
 *
 * @author: exode <hello@exode.ru>
 */

import { IsBoolean, IsNotEmpty, MinLength } from 'class-validator';

import { isMinMax, isNotEmpty } from '../constants';


export class UpdatePasswordUnauthInput {

    @MinLength(6, { message: isMinMax(6, 'Пароль', 'min') })
    @IsNotEmpty({ message: isNotEmpty('Пароль') })
    readonly password: string;

    @IsBoolean()
    readonly logout: boolean;

}
