/**
 * ErrorBoundary component
 *
 * @author: exode <hello@exode.ru>
 */

import React, { Component, ErrorInfo, ReactElement } from 'react';

import { ErrorFallback } from '@/components/App/ErrorFallback';


interface Props {
    children: ReactElement | ReactElement[];
    fallbackComponent?: ReactElement;
}

interface State {
    isError: boolean;
    error?: string;
}


class ErrorBoundary extends Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            isError: false,
            error: '',
        };
    }

    static getDerivedStateFromError(error: Error) {
        return error && { isError: true };
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        console.log('⚠️', error, errorInfo);

        this.setState({
            ...this.state,
            error: [
                `Location: ${location.href}`,
                `SchoolID: ${window.exode.school?.id || '-'}`,
                `SellerID: ${localStorage.getItem('seller:id') || '-'}`,
                `UserID: ${JSON.parse(localStorage.getItem('store:user-profile') || '{}')?.user?.id}`,
                `Error: ${error.stack?.toString()}`,
            ].join('\n'),
        });
    }

    render() {
        if (this.state.isError) {
            return (
                this.props.fallbackComponent
                || <ErrorFallback isBoundary errorInfo={this.state.error} onClick={() => window.location.reload()}/>
            );
        }

        return this.props.children;
    }
}


export { ErrorBoundary };
