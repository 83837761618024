/**
 * MarketplaceMenuPart
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { useI18n } from '@/hooks/core';

import { PathsList } from '@/shared/types';
import { ContentCategorySlug } from '@/types/content';

import { ProductCurrency, WalletGetBalanceQuery } from '@/codegen/graphql';

import { useLocation } from '@/router/index';
import { RoutePathType } from '@/router/paths';
import { Auth, Graphql, Link } from '@/cutils';

import { Separator, SimpleCell, Spinner } from '@exode.ru/vkui';
import { Icon24CalendarOutline, Icon24GameOutline, Icon24UsersOutline, Icon24WalletOutline } from '@vkontakte/icons';

import { AmountText } from '@/components/Atoms/AmountText';
import { TagInlineText } from '@/components/Atoms/TagInlineText';
import { UserContentCategoryToggle } from '@/components/Content/UserContentCategoryToggle';

import { PartnerPart } from './PartnerPart';


const MarketplaceMenuPart = () => {

    const { t } = useI18n('components.Desktop.Navigation.Menu');

    const { route: { pageId } } = useLocation();

    //TODO:school Move to page spaces
    const isCurrentPathClassName = (paths: RoutePathType[]) => (
        paths.includes(pageId as RoutePathType) ? 'bg-highlighted' : ''
    );

    return (
        <>
            <UserContentCategoryToggle slugs={[ ContentCategorySlug.Ege ]}>
                <Link pushPage={{ id: '/tournaments' }}>
                    <SimpleCell expandable before={<Icon24GameOutline/>} className={(
                        isCurrentPathClassName(PathsList.filter((
                            (path) => path.includes('/tournament') && !path.includes('manage')
                        )))
                    )}>
                        Турниры ЕГЭ
                    </SimpleCell>
                </Link>
            </UserContentCategoryToggle>

            <Link pushPage={{ id: '/friends' }}>
                <SimpleCell expandable
                            before={<Icon24UsersOutline/>}
                            className={isCurrentPathClassName([ '/friends' ])}>
                    {t('friends')}
                </SimpleCell>
            </Link>

            <UserContentCategoryToggle slugs={[ ContentCategorySlug.Ege ]}>
                <Link pushPage={{ id: '/exams-timeout' }}>
                    <SimpleCell expandable
                                before={<Icon24CalendarOutline/>}
                                className={isCurrentPathClassName([ '/exams-timeout' ])}>
                        До ЕГЭ осталось
                    </SimpleCell>
                </Link>
            </UserContentCategoryToggle>

            <Auth>
                <Link pushPage={{ id: '/wallet' }}>
                    <SimpleCell expandable before={<Icon24WalletOutline/>} after={(
                        <WalletGetBalanceQuery children={(result) => (
                            <>
                                <Graphql.Loading result={result}>
                                    <Spinner size="small"/>
                                </Graphql.Loading>

                                <Graphql.Success result={result}>
                                    {({ walletGetBalance: balance }) => (
                                        <TagInlineText gradient="gray" size="m" content={(
                                            <AmountText semibold={false}
                                                        amount={balance}
                                                        currency={ProductCurrency.Exes}/>
                                        )}/>
                                    )}
                                </Graphql.Success>
                            </>
                        )}/>
                    )} className={(
                        isCurrentPathClassName([
                            '/wallet',
                            '/wallet/:page([0-9]+)',
                        ])
                    )}>
                        {t('wallet')}
                    </SimpleCell>
                </Link>
            </Auth>

            <Separator className="w-full my-3"/>

            <PartnerPart placement="desktop"/>
        </>
    );
};


export { MarketplaceMenuPart };
