/**
 * Session types
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { useI18n } from '@/hooks/core/useI18n';

import { MyUserSettingsQueryResult, SessionLauncher } from '@/codegen/graphql';

import {
    Icon16LogoAndroid,
    Icon16LogoApple,
    Icon28GlobeOutline,
    Icon28LaptopOutline,
    Icon28LogoVkOutline,
} from '@vkontakte/icons';

import { GqlResult } from '@/types/graphql';


export type SessionListItem = GqlResult<MyUserSettingsQueryResult>['sessionGetActiveSessions'][number];

export const sessionIconsAndName = () => {

    const { t } = useI18n('types.session');

    return {
        [SessionLauncher.Ios]: {
            icon: <Icon16LogoApple width={28} height={28}/>,
            name: t('iOSApplication'),
        },
        [SessionLauncher.Android]: {
            icon: <Icon16LogoAndroid width={28} height={28}/>,
            name: t('android'),
        },
        [SessionLauncher.Web]: {
            icon: <Icon28GlobeOutline/>,
            name: t('website'),
        },
        [SessionLauncher.Vk]: {
            icon: <Icon28LogoVkOutline/>,
            name: t('vkMiniApps'),
        },
        [SessionLauncher.MacApp]: {
            icon: <Icon28LaptopOutline/>,
            name: t('macOSApplication'),
        },
        [SessionLauncher.WindowsApp]: {
            icon: <Icon28LaptopOutline/>,
            name: t('windowsApplication'),
        },
        [SessionLauncher.LinuxApp]: {
            icon: <Icon28LaptopOutline/>,
            name: t('linuxApplication'),
        },
    } as const;
};
