/**
 * SellerItem
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { SvgComponent } from '@/cutils';

import {
    SellerOfCurrentUser,
    sellerOrganizationForm,
    SellersOfCurrentUser,
    sellerType,
    sellerTypeIcons,
} from '@/types/seller';

import { Footnote, RichCell, RichCellProps } from '@exode.ru/vkui';

import { SellerAvatar } from '@/components/Seller/Avatar/SellerAvatar';


interface Props extends RichCellProps {
    seller: SellerOfCurrentUser | SellersOfCurrentUser[number];
    sellerId?: number;
    active?: boolean;
}


export const SellerItem = (props: Props) => {

    const { seller, active, ...rest } = props;

    const sellerTypes = sellerType();

    const sellerNameClassName = [
        active ? 'text-accent' : '',
        'w-[200px] whitespace-normal text-ellipsis line-clamp-1 font-semibold',
    ].join(' ');

    return (
        <RichCell {...rest} data-test={`seller.${seller?.id}`}
                  className={`cursor-pointer px-2 select-none ${active ? 'bg-hover pointer-events-none' : ''}`}
                  before={(
                      <SellerAvatar sellerId={seller?.id || 0}
                                    sellerName={seller?.organization?.name}
                                    sellerOrganizationLogo={seller?.organization?.logo}/>
                  )}
                  subhead={(
                      seller?.type && (
                          <div className="flex items-center">
                              <span className="mr-1">
                                  {sellerTypes[seller.type]}
                              </span>

                              <SvgComponent svgProps={{ width: 15, height: 15 }}
                                            element={sellerTypeIcons[seller.type]}/>
                          </div>
                      )
                  )}
                  caption={(
                      <Footnote weight="2"
                                className="w-[210px] overflow-hidden whitespace-normal text-ellipsis line-clamp-1">
                          {seller?.organization?.form && sellerOrganizationForm[seller.organization.form]}
                      </Footnote>
                  )}>
            <span className={sellerNameClassName}>
                {seller?.organization?.name}
            </span>
        </RichCell>
    );
};
