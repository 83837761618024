/**
 * Hotkeys util
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { useHotkeys } from 'react-hotkeys-hook';

import { RouterStore } from '@/store/core/router';
import { ConfigStore } from '@/store/core/config';
import { PreferenceStore } from '@/store/preference/preference';

import { setLanguage } from '@/hooks/core';
import { Router } from '@/services/Utils/Router';

import { Language } from '@/codegen/graphql';


const Hotkeys = () => {

    useHotkeys('ctrl+k,cmd+k', () => {
        const { modal } = RouterStore.params;

        const opened = modal === 'user-menu';

        !opened && Router.pushModal('user-menu');
        opened && Router.replaceModal();
    });

    useHotkeys('alt+t', () => {
        (() => PreferenceStore.toggleScheme())();
    });

    useHotkeys('alt+l', () => {
        setLanguage(ConfigStore.language === Language.Ru ? Language.Uz : Language.Ru);
    });

    return <></>;
};


export { Hotkeys };
