/**
 * SchoolManageCreateUserSchema
 *
 * @author: exode <hello@exode.ru>
 */

import * as Yup from 'yup';

import { phone } from '@exode-team/phone';

import { Parse } from '@/utils';


export const SchoolManageCreateUserSchema = Yup.object().shape({
    users: Yup.object().shape({
        accounts: Yup.array().of(
            Yup.object().shape({
                email: Yup.string()
                    .when('type', {
                        is: (v: string) => v === 'phone',
                        then: (schema) => schema.optional(),
                        otherwise: (schema) => schema
                            .email('Неправильный email')
                            .matches(/@[^.]*\./, { message: 'Неправильный email' })
                            .required('Email не должен быть пустым')
                            .min(5, 'Поле должно содержать минимум 5 символов')
                            .max(50, 'Поле должно содержать максимум 50 символов'),
                    }),
                phone: Yup.string()
                    .when('type', {
                        is: (v: string) => v === 'email',
                        then: (schema) => schema.optional(),
                        otherwise: (schema) => schema
                            .required('Номер телефона не должен быть пустым')
                            .min(5, 'Поле должно содержать минимум 5 символов')
                            .max(50, 'Поле должно содержать максимум 50 символов')
                            .test(
                                'phone',
                                'Неверный номер',
                                (value) => {
                                    const { isValid } = phone(Parse.getPhone(value));

                                    return isValid;
                                },
                            ),
                    }),
            }),
        ),
    }),
    enrollments: Yup.object().shape({
        accesses: Yup.array().of(
            Yup.object().shape({
                productId: Yup.number().required(),
                launchId: Yup.number().required(),
                priceId: Yup.number().required(),
            }),
        ),
    }),
});
