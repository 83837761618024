/**
 * CurrentSellerGuard component
 *
 * @author: exode <hello@exode.ru>
 */

import React, { ReactElement } from 'react';

import { If, Link } from '@/cutils';
import { PageAction } from '@/services/Utils/Router';

import { IconButton } from '@mui/material';
import { Icon24PenOutline } from '@vkontakte/icons';
import { useSellerContext } from '@/components/App/SellerProvider';


interface Props {
    sellerId?: number;
    pushPage?: PageAction;
    force?: boolean;
    children?: ReactElement;
}


const CurrentSellerGuard = (props: Props) => {

    const { sellerId, pushPage, force } = props;

    const { seller } = useSellerContext();

    const children = props.children || (
        <IconButton className="!-mt-2 absolute">
            <Icon24PenOutline fill="var(--accent)"/>
        </IconButton>
    );

    return (
        <If is={!!sellerId && seller?.id === sellerId || !!force}>
            <Link pushPage={pushPage}>
                {children}
            </Link>
        </If>
    );
};


export { CurrentSellerGuard };
