/**
 * CompanyDetailsModal
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { useI18n } from '@/hooks/core';

import { observer, PlatformStore } from '@/store/platform';

import { DomainSwitcher, Modal } from '@/cutils';

import { Button, Title } from '@exode.ru/vkui';

import { CompanyInfoView } from '@/pages/Company/About/views/CompanyInfoView';


interface Props {
    id: string;
}


const CompanyDetailsModal = observer((props: Props) => {

    const { COMMON } = PlatformStore;

    const { t } = useI18n('modals.Company.Details');

    return (
        <Modal.Card id={props.id}
                    header={<Title className="modal-title" level="2" weight="3">{t('aboutCompany')}</Title>}
                    actions={[
                        <Button href={COMMON?.organization.info.messengerSupportUrl} key="1" mode="secondary" size="l">
                            {t('writeToSupport')}
                        </Button>,
                    ]}>
            <Modal.Body>
                <DomainSwitcher Global={COMMON && (
                    <CompanyInfoView companyInfo={COMMON?.organization.info}/>
                )}/>
            </Modal.Body>
        </Modal.Card>
    );
});


export { CompanyDetailsModal };
