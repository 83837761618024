/**
 * Filter useCatalogCourseFilter
 *
 * @author: exode <hello@exode.ru>
 */

import { useUrlFilters } from '@/hooks/core';

import { CatalogTabStore, defaultFilter, dtoFilterTransformer } from './store';

import { FilterCourseInput } from '@/codegen/graphql';


export const catalogCourseCardCrossPageParams = [
    'page',
    'categoryIds',
];

export const useCatalogCourseFilter = () => useUrlFilters<
    typeof defaultFilter,
    FilterCourseInput,
    typeof CatalogTabStore
>(
    defaultFilter,
    [],
    dtoFilterTransformer,
    { store: CatalogTabStore },
);

