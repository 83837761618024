/**
 * TournamentQuickActionsModal
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { Link, Modal } from '@/cutils';

import {
    Icon24EducationOutline,
    Icon24GameOutline,
    Icon24MoneyRequestOutline,
    Icon28FaceRecognitionOutline,
} from '@vkontakte/icons';

import { Button, Header, List, MiniInfoCell, Title } from '@exode.ru/vkui';


interface Props {
    id: string;
}


const TournamentQuickActionsModal = (props: Props) => {
    return (
        <Modal.Card id={props.id}
                    actionsLayout="vertical"
                    icon={<Modal.CardIcon size={130} iconSize={96} icon={<Icon24MoneyRequestOutline fill="white"/>}/>}
                    header={<Title className="modal-title" level="2" weight="3">Действия с кошельком</Title>}
                    actions={[
                        <Link key="deposit" pushModal={{ id: 'profile-wallet-deposit' }}>
                            <Button size="l" mode="primary" onClick={() => {}}>
                                Пополнить
                            </Button>
                        </Link>,
                        <Link key="transfer" pushPage={{ id: '/wallet/send' }}>
                            <Button size="l" mode="secondary" onClick={() => {}}>
                                Перевести
                            </Button>
                        </Link>,
                    ]}>
            <Header className="mt-3" mode="secondary">
                На что потратить экзы?
            </Header>

            <Modal.Body isScrollable>
                <List>
                    <MiniInfoCell textWrap="full"
                                  textLevel="secondary"
                                  before={<Icon24GameOutline fill="var(--accent)"/>}>
                        <Title className="text-primary" weight="3" level="3">Турниры</Title>
                        Соревнования на скорость и ум, учитесь и играйте одновременно!
                    </MiniInfoCell>

                    <MiniInfoCell textWrap="full"
                                  textLevel="secondary"
                                  before={<Icon24EducationOutline fill="var(--accent)"/>}>
                        <Title className="text-primary" weight="3" level="3">Курсы</Title>
                        В exode есть текстовые, аудио и видео-курсы. Приятной подготовки к ЕГЭ вместе с exode.
                    </MiniInfoCell>

                    <MiniInfoCell textWrap="full"
                                  textLevel="secondary"
                                  before={<Icon28FaceRecognitionOutline fill="var(--accent)" width={24} height={24}/>}>
                        <Title className="text-primary" weight="3" level="3">Друзья</Title>
                        Переводите друзьям экзы, чтобы вместе участвовать в турнирах и готовиться к ЕГЭ, или просто
                        сделать приятно.
                    </MiniInfoCell>
                </List>
            </Modal.Body>
        </Modal.Card>
    );
};


export { TournamentQuickActionsModal };
