/**
 * UseUpdateUserManage
 *
 * @author: exode <hello@exode.ru>
 */

import { UpdateUserInput, UserEntity, useUserManageUpdateMutation } from '@/codegen/graphql';

import { Notify } from '@/cutils';
import { useBatchUpdateCacheFields, useI18n } from '@/hooks/core';


export const useUpdateUserManage = () => {

    const { t } = useI18n('hooks.apollo.user');

    const [ _update, { loading: updateUserLoading, error: updateUserError } ] = useUserManageUpdateMutation();

    const updateUserManage = async (
        { userId, ...rest }: UpdateUserInput & { userId: number },
    ) => {
        await _update({
            variables: { userId, userPartial: { ...rest } },
            update(cache, { data }) {
                data && cache.modify({
                    id: cache.identify(data.userManageUpdate),
                    fields: useBatchUpdateCacheFields<UserEntity>(data.userManageUpdate),
                });
            },
            onError(error) {
                console.error(error);
            },
            onCompleted() {
                Notify.vkui({
                    appearance: 'success',
                    message: t('changesSavedSuccessfully'),
                });
            },
        });
    };

    return {
        updateUserManage,
        updateUserError,
        updateUserLoading,
    };
};
