/**
 * ProfileNotificationCard styled container
 *
 * @author: exode <hello@exode.ru>
 */

import styled from 'styled-components';


export const Container = styled.div<{ isInSnackbar?: boolean }>`
    position: relative;
    
  .vkuiRichCell__content {
    max-width: ${({ isInSnackbar }) => isInSnackbar ? '100%' : '90%'}
  }

  .vkuiParagraph {
    max-width: ${({ isInSnackbar }) => isInSnackbar ? '100%' : '90%'}
  }
`;
