/**
 * CatalogCoursesHeaderItem
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import React from 'react';

import { Responsive } from '@/cutils';
import { useI18n } from '@/hooks/core';

import { Counter, Header } from '@exode.ru/vkui';

import { SectionTitle } from '@/components/Landing/SectionTitle';

import { gradient } from '@/styles/modules/gradient';


interface Props {
    count: number;
    isLoading?: boolean;
}


const CatalogCoursesHeaderItem = (props: Props) => {

    const { t } = useI18n('tabs.Catalog.items');

    const { count, isLoading } = props;

    return (
        <>
            <Responsive.Desktop>
                <SectionTitle title={[
                    isLoading
                        ? t('coursesLoading')
                        : t('coursesCount', { count }),
                ].filter(e => e).join(' ')}/>
            </Responsive.Desktop>

            <Responsive.Mobile>
                <Header className="flex mb-2" aside={(
                    !_.isNil(count)
                        ? (
                            <Counter size="s" mode="primary" className={[
                                'px-1 py-0.5 bg-gradient-to-r text-white',
                                gradient.blue600ToPurple500Gradient,
                            ].join(' ')}>
                                {t('courses', { count })}
                            </Counter>
                        )
                        : null
                )}>
                    <span className="text-underline-marker h-after-4">
                        {t('courses')}
                    </span>
                </Header>
            </Responsive.Mobile>
        </>
    );
};


export { CatalogCoursesHeaderItem };
