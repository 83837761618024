/**
 * CartService
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import { apolloClient } from '@/api/graphql';

import {
    CartFindOneDocument,
    CartFindOneQueryResult,
    CartProduct,
    CartUpsertDocument,
    CartUpsertMutationVariables,
} from '@/codegen/graphql';

import { ShopStore } from '@/store/user/shop';
import { UserAuthStore } from '@/store/user/auth';
import { RouterStore } from '@/store/core/router';

import { Router } from '@/services/Utils/Router';
import { BaseService } from '@/services/Core/Base';

import { GqlResult } from '@/types/graphql';
import { DocumentEvent } from '@/types/window';


class CartService extends BaseService {

    /**
     * Регистрация сервиса
     */
    static register() {
        const { pageId } = RouterStore;

        document.addEventListener(
            DocumentEvent.AuthStateChanged,
            async () => {
                /** Игнорируем страницу успешной оплаты (ignore merge already payed cart items) */
                if (pageId !== '/payment/:invoiceUuid([0-9_A-Za-z_-]+)') {
                    if (UserAuthStore.isLoggedIn) {
                        await this.upsert(true);
                    }
                }
            },
        );
    }

    /**
     * Инициализация сервиса
     */
    static async init() {
        if (!UserAuthStore.isLoggedIn) {
            return;
        }

        await this.refetchRemoteCart();
    }

    /**
     * Refetch with remote cart
     * @returns {Promise<void>}
     */
    static async refetchRemoteCart() {
        try {
            const { data, error } = await apolloClient.query<GqlResult<CartFindOneQueryResult>>({
                query: CartFindOneDocument,
            });

            const { products } = data.cartFindOne || {};

            if (error || !products) {
                return null;
            }

            ShopStore.setCart(products);

            return data.cartFindOne;
        } catch (e) {
            return null;
        }
    }

    /**
     * Upsert cart
     * @returns {Promise<void>}
     */
    static async upsert(merge = false) {
        if (!UserAuthStore.isLoggedIn) {
            return;
        }

        try {
            await apolloClient.mutate<CartUpsertMutationVariables>({
                mutation: CartUpsertDocument,
                variables: {
                    merge,
                    cart: {
                        products: ShopStore.cart,
                    },
                },
            });

            if (merge) {
                await this.refetchRemoteCart();
            }

        } catch (e) {

        }
    }

    /**
     * Добавление элемента в корзину
     */
    static async addToCart(
        product: CartProduct,
        proceedToCart = true,
    ) {
        if (!product.priceId || !product.productId) {
            return;
        }

        const productInCart = ShopStore.checkInCart(
            product.productId,
            product.priceId,
            'product',
        );

        const tryProceedToCart = () => {
            if (proceedToCart) {
                Router.pushPage('/cart');
            }
        };

        if (productInCart) {
            ShopStore.setCart([
                ...ShopStore.cart.filter(({ productId }) => productId !== product.productId),
                product,
            ]);

            tryProceedToCart();

            return await CartService.upsert();
        }

        const productAndPriceInCart = ShopStore.checkInCart(
            product.productId,
            product.priceId,
            'price',
        );

        if (productAndPriceInCart) {
            return;
        }

        ShopStore.setCart([ ...ShopStore.cart, product ]);

        tryProceedToCart();

        await CartService.upsert();
    }

    /**
     * Удаление элемента из корзины
     */
    static async removeFromCart(productId: number) {
        const cart = ShopStore.cart;

        if (!cart || _.isEmpty(cart)) {
            return;
        }

        if (_.find(cart, { productId })) {
            const newCart = cart.filter((item) => productId !== item.productId);

            ShopStore.setCart(newCart);

            await CartService.upsert();
        }
    }

}


export { CartService };
