/**
 * ExistUserListItem
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { GqlResult } from '@/types/graphql';
import { SchoolUserManageCreateManyMutationResult } from '@/codegen/graphql';

import { Cell } from '@exode.ru/vkui';

import { User } from '@/components/Atoms/User';
import { UserAvatar } from '@/components/Atoms/UserAvatar';


interface Props {
    existUsers: GqlResult<SchoolUserManageCreateManyMutationResult>['schoolUserManageCreateMany']['exist'];
}


const ExistUserListItem = (props: Props) => {

    const { existUsers } = props;

    return (
        <div className="px-2 my-4">
            {existUsers.map(({ profile }) => (
                <Cell hasHover={false} before={(
                    <UserAvatar size={42}
                                className="mr-3"
                                userId={profile.id}
                                name={profile.fullName || ''}
                                src={profile.avatar.medium || ''}/>
                )}>
                    <User.Name profile={profile}/>
                </Cell>
            ))}
        </div>
    );
};


export { ExistUserListItem };
