/**
 * HeaderView
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { Link } from '@/cutils';
import { Page } from '@/pages/Core';
import { useI18n } from '@/hooks/core';

import { Button } from '@exode.ru/vkui';
import { Icon24AddSquareOutline } from '@vkontakte/icons';


const HeaderView = () => {

    const { t } = useI18n('pages.Manage.School.Users.views');

    return (
        <Page.MainHeader title={t('allSchoolUsers')} subtitle={t('fullUserInformationOfYourSchool')} actions={(
            <Link pushModal={{ id: 'manage-user-create' }}>
                <Button size="m" mode="commerce" data-test="user.add" before={<Icon24AddSquareOutline/>}>
                    {t('addUsers')}
                </Button>
            </Link>
        )}/>
    );
};


export { HeaderView };
