/**
 * LaunchBadgeStatusIcon component
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { SvgComponent } from '@/cutils';

import { ProductLaunchByDateStatus } from '@/codegen/graphql';

import { productLaunchByDateStatusColor, productLaunchByDateStatusIcon } from '@/types/product';


interface Props {
    status: ProductLaunchByDateStatus;
    size?: number;
    iconSize?: number;
    className?: string;
}


const LaunchBadgeStatusIcon = (props: Props) => {

    const { status, size, className = '', iconSize = 14 } = props;

    return (
        <div style={{
            minWidth: size,
            minHeight: size,
            background: productLaunchByDateStatusColor[status],
        }} className={[
            className,
            'p-0.5 rounded-full',
            'flex items-center justify-center',
        ].join(' ')}>
            <SvgComponent element={productLaunchByDateStatusIcon[status]} svgProps={{
                width: iconSize,
                height: iconSize,
                fill: 'var(--white)',
            }}/>
        </div>
    );
};


export { LaunchBadgeStatusIcon };
