/**
 * BlitzTournamentView
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { Page } from '@/pages/Core';

import { UsersOnlineQuery } from '@/codegen/graphql';

import { Counter, Header, List } from '@exode.ru/vkui';

import { Graphql } from '@/cutils';
import { gradient } from '@/styles/modules/gradient';
import { TournamentBlitzCard } from '@/components/Tournament/BlitzCard';


const BlitzTournamentView = () => {
    return (
        <Page.Row>
            <Header mode="primary" aside={(
                <Counter mode="primary" size="s" className={[
                    gradient.green500ToLime600,
                    'px-1 py-0.5 bg-gradient-to-r text-white',
                ].join(' ')}>
                    <UsersOnlineQuery children={(result) => (
                        <>
                            <Graphql.Loading result={result}>
                                <>Загрузка...</>
                            </Graphql.Loading>

                            <Graphql.Success result={result}>
                                {({ userCountUsersOnline: usersCountOnline }) => (
                                    <>{usersCountOnline} в сети</>
                                )}
                            </Graphql.Success>

                            <Graphql.Error result={result}>
                                <>Ошибка...</>
                            </Graphql.Error>
                        </>
                    )}/>
                </Counter>
            )}>
                <span className="text-underline-marker h-after-4">
                    Быстрый поиск соперника
                </span>
            </Header>

            <List className="d:pt-2 d:pb-3 px-3 m:mt-1">
                <TournamentBlitzCard/>
            </List>
        </Page.Row>
    );
};


export { BlitzTournamentView };
