/**
 * ManageSellerManagersPage store
 *
 * @author: exode <hello@exode.ru>
 */

import { SellerManagerType } from '@/codegen/graphql';

import { makeObservable } from 'mobx';
import { observer, PageStore } from '@/pages/Core';


class ManageSellerManagersPage extends PageStore {

    constructor() {
        super();
        makeObservable(this);
    }

    state = {
        list: {
            managers: {
                skip: 0,
                take: 15,
            },
            productManagers: {
                skip: 0,
                take: 15,
            },
        },
        filter: {
            managers: {
                types: [ SellerManagerType.Seller ],
            },
            productManagers: {},
        },
        sort: {},
    };

}

const ManageSellerManagersPageStore = new ManageSellerManagersPage();


export { observer, ManageSellerManagersPageStore };
