/**
 * DiscountHeaderView
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { Page } from '@/pages/Core';
import { useI18n } from '@/hooks/core';
import { Router } from '@/services/Utils/Router';

import { Button } from '@exode.ru/vkui';
import { Icon24AddSquareOutline } from '@vkontakte/icons';


interface Props {
    productId: number;
}


const DiscountHeaderView = (props: Props) => {

    const { productId } = props;

    const { t } = useI18n('pages.Manage.Product.Discount');

    return (
        <Page.MainHeader subtitle={t('discountSubtitle')} title={t('promoCodes')} actions={(
            <div className="flex gap-4">
                <Button size="m"
                        appearance="positive"
                        data-test="promocode.create"
                        before={<Icon24AddSquareOutline/>}
                        onClick={() => Router.pushModal(
                            'manage-product-discount',
                            {
                                discountId: '',
                                productId: `${productId}`,
                            },
                        )}>
                    {t('createPromoCode')}
                </Button>
            </div>
        )}/>
    );
};


export { DiscountHeaderView };
