/**
 * DefaultSnackbar component
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import React, { forwardRef, ReactElement } from 'react';

import { SnackbarContent, SnackbarMessage } from 'notistack';

import { Notify } from '@/cutils';

import { Icon16Cancel, Icon16Done } from '@vkontakte/icons';
import { Avatar, Snackbar as VkUiSnackbar } from '@exode.ru/vkui';
import { SnackbarProps as VkUiSnackbarProps } from '@exode.ru/vkui/dist/components/Snackbar/Snackbar';

import { BaseSnackbarProps } from '../interfaces';

import { Container } from './DefaultSnackbar.styled';


export interface DefaultSnackbarProps extends BaseSnackbarProps {
    message: SnackbarMessage;
    icon?: ReactElement;
    onClick?: () => void;
    closeOnClick?: boolean;
    appearance?: 'primary' | 'muted' | 'error' | 'success';

    vkuiOptions?: Omit<VkUiSnackbarProps, 'onClose' | 'onActionClick'> & {
        onActionClick?: (
            handleCloseSnackbar: () => void,
            event: React.MouseEvent,
        ) => void;
    };
}


const DefaultSnackbar = forwardRef<HTMLDivElement, DefaultSnackbarProps>((props: DefaultSnackbarProps, ref) => {

    const {
        message,
        options,
        vkuiOptions,
        icon,
        onClick,
        appearance = 'primary',
        closeOnClick = true,
        ...rest
    } = props;

    const handleCloseSnackbar = () => {
        Notify.closeSnackbar(options?.key || props.id);
    };

    const handleClick = () => {
        _.isFunction(onClick) && onClick();

        closeOnClick && handleCloseSnackbar();
    };

    return (
        <SnackbarContent ref={ref} role="alert"  {...rest}>
            <Container appearance={appearance}>
                <VkUiSnackbar {...vkuiOptions} onClick={handleClick}
                              onClose={handleCloseSnackbar}
                              duration={Notify.calcDuration(options)}
                              layout={vkuiOptions?.layout || 'vertical'}
                              onActionClick={(event) => {
                                  props.vkuiOptions?.onActionClick?.(handleCloseSnackbar, event);
                              }}
                              before={(vkuiOptions?.before || (
                                  <Avatar size={24} className={props.vkuiOptions?.action ? 'mt-[3px]' : ''}>
                                      {icon || (appearance === 'error'
                                          ? <Icon16Cancel/>
                                          : <Icon16Done/>)
                                      }
                                  </Avatar>
                              ))}>
                    {message}
                </VkUiSnackbar>
            </Container>
        </SnackbarContent>
    );
});


export { DefaultSnackbar };
