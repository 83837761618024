/**
 * ContentCardAfterIconButton styled container
 *
 * @author: exode <hello@exode.ru>
 */

import { css } from 'styled-components';

import { responsive } from '@/styles/modules/responsive';


export const contentCardAfterIconButtonStyle = css`
.right-button {
    position: absolute;
    height: 100%;
    width: 65px;
    right: 0;
    z-index: 100;
    
    &.slim-on-mobile {
        ${responsive([ 'mobile' ])} {
          width: 48px;
        }
    }
    
    .IconButton {
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 0 var(--vkui--size_border_radius_paper--regular) var(--vkui--size_border_radius_paper--regular) 0;
    }
    
    .vkuiIcon {
      color: var(--accent);
    }
}
`;
