/**
 * MainContentView
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { ManageProductDiscountsPageStore } from '../store';

import { Page } from '@/pages/Core';
import { useLocation } from '@/router/index';
import { useI18n, usePagination } from '@/hooks/core';

import { DiscountListView } from './DiscountListView';
import { DiscountHeaderView } from './DiscountHeaderView';

import { useDiscountsFilter } from '../filter';


const MainContentView = () => {

    const { t } = useI18n('pages.Manage.Product.Discount');

    const { route: { params: { productId } } } = useLocation();

    const pagination = usePagination({
        store: ManageProductDiscountsPageStore,
        path: '/manage/product/:productId([0-9]+)/discounts/:page([0-9]+)',
    });

    const filter = useDiscountsFilter(
        ManageProductDiscountsPageStore,
        { productIds: [ +productId ] },
    );

    return (
        <>
            <Page.Breadcrumbs breadcrumbs={[
                { title: t('promoCodes') },
            ]}/>

            <DiscountHeaderView productId={+productId}/>

            <DiscountListView productId={+productId} filter={filter} pagination={pagination}/>
        </>
    );
};


export { MainContentView };
