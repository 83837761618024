/**
 * FriendsPossibleView
 *
 * @author: exode <hello@exode.ru>
 */

import React, { useState } from 'react';

import { Page } from '@/pages/Core';

import { FriendshipStatus, PossibleFriendsListQueryResult } from '@/codegen/graphql';

import { Button, Header } from '@exode.ru/vkui';
import { Icon28UserAddBadgeOutline } from '@vkontakte/icons';

import { If, Link } from '@/cutils';
import { useI18n } from '@/hooks/core';
import { GqlResult } from '@/types/graphql';
import { useSetFriendship } from '@/hooks/apollo';

import { User } from '@/components/Atoms/User';
import { Placeholder } from '@/components/Atoms/Placeholder';


interface Props {
    friends: GqlResult<PossibleFriendsListQueryResult>['friendshipFindManyPossible'];
    isContext?: boolean;
}


const FriendsPossibleView = (props: Props) => {

    const { isContext, friends: { items } } = props;

    const { t } = useI18n('pages.Profile.Friends');

    const { setFriendship, setFriendshipLoading } = useSetFriendship();

    const [ loadingId, setLoadingId ] = useState(0);

    const handleSetFriendship = (friendshipStatus: FriendshipStatus, toId: number) => {
        setLoadingId(toId);

        return friendshipStatus === FriendshipStatus.Requested
            ? setFriendship(toId, FriendshipStatus.Canceled)
            : setFriendship(toId, FriendshipStatus.Requested);
    };

    return (
        <Page.Row>
            <Header mode="secondary">
                {t('possibleFriends')}
            </Header>

            {items?.map(({ id, profile, friendshipStatus, lastOnlineAt }) => (
                <User.Cell disabled
                           key={id}
                           mode="rich"
                           userId={id}
                           profile={profile}
                           lastOnlineAt={lastOnlineAt}
                           avatarSize={isContext ? 48 : 72}
                           avatarProps={{ hasLink: true }}
                           actions={(
                               <>
                                   <Button mode="primary"
                                           loading={loadingId === id && setFriendshipLoading}
                                           onClick={() => handleSetFriendship(friendshipStatus!, id)}>
                                       {(
                                           friendshipStatus === FriendshipStatus.Requested
                                               ? t('sendFriendshipRequest')
                                               : t('addToFriend')
                                       )}
                                   </Button>

                                   <Link pushPage={{ id: '/chat', params: { personalUserId: `${id}` } }}>
                                       <Button mode="secondary">
                                           {t('TextDM')}
                                       </Button>
                                   </Link>
                               </>
                           )}/>
            ))}

            <If is={!items?.length}>
                <Placeholder iconSize={72}
                             header={t('noPossibleFriends')}
                             content={t('toSeePossibleFriends')}
                             icon={<Icon28UserAddBadgeOutline className="text-accent opacity-[0.85]"/>}/>
            </If>

            <div className="pb-3"/>
        </Page.Row>
    );
};


export { FriendsPossibleView };
