/**
 * ProfilePart
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { ProfileView } from '@/tabs/Menu/views/ProfileView';


const ProfilePart = () => {
    return (
        <div data-test="auth.login.loggedInIndicator">
            <ProfileView userCellProps={{
                avatarSize: 40,
                after: undefined,
                avatarProps: { badgeBg: 'var(--background_page)' },
            }}/>
        </div>
    );
};


export { ProfilePart };
