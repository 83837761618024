/**
 * WikiRoutes
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { PageSpace } from '@/shared/types';

import { RouterItemsType } from '@/types/router';
import { lazyWithRetry } from '@/helpers/react';


const WikiCategoriesListPage = lazyWithRetry(() => import('@/pages/Wiki/CategoriesList'));


const WikiRoutes: RouterItemsType = {
    '/wiki': {
        view: <WikiCategoriesListPage/>,
        spaces: [
            PageSpace.MarketplacePlatform,
        ],
    },
};


export { WikiRoutes };
