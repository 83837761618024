/**
 * UserOnlineCountSubscription component
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { useUserOnlineCountSubscription } from '@/codegen/graphql';


const UserOnlineCountSubscription = () => {

    useUserOnlineCountSubscription({
        onData: ({ data: { data }, client: { cache } }) => {
            cache.modify({
                id: cache.identify({ __typename: 'Query' }),
                fields: {
                    userCountUsersOnline: () => (data?.userListenCountUserOnline || 0),
                },
            });
        },
    });

    return (<></>);
};


export { UserOnlineCountSubscription };
