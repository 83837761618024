/**
 * ManageCoursePricingPage store
 *
 * @author: exode <hello@exode.ru>
 */

import { makeObservable } from 'mobx';
import { observer, PageStore } from '@/pages/Core';

import { defaultPricingListFilter, dtoFilterPricingListTransformer } from './filter';


class ManageCoursePricingPage extends PageStore {

    constructor() {
        super();
        makeObservable(this);
    }

    state = {
        list: {
            skip: 0,
            take: 15,
        },
        filter: dtoFilterPricingListTransformer(defaultPricingListFilter),
        sort: {},
    };

}

const ManageCoursePricingPageStore = new ManageCoursePricingPage();


export { observer, ManageCoursePricingPageStore };
