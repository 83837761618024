/**
 * Helper
 *
 * @author: exode <hello@exode.ru>
 */

import { Router } from 'router.tsx';

import { MetaContent } from '@/root/src/meta';


export const matchPath = (location: string) => {
    for (const pageId in MetaContent.pages) {
        if (!MetaContent.pages.hasOwnProperty(pageId)) {
            continue;
        }

        const match = Router.matchPath(location, pageId);

        if (match && match.isExact) {
            return match;
        }
    }

    return undefined;
};
