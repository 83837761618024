/**
 * UseTournamentSetState
 *
 * @author: exode <hello@exode.ru>
 */

import { useApolloClient } from '@apollo/client';

import { useBatchUpdateCacheFields } from '@/hooks/core';

import { TournamentStateChangeEvent } from '@/types/tournament';

import { TournamentEntity, useTournamentListenStateSubscription } from '@/codegen/graphql';


interface Props {
    onStateChange?: (
        next: TournamentStateChangeEvent,
    ) => void;
}


export const useTournamentSetState = (
    tournamentId: number,
    options: Props,
) => {

    const { cache } = useApolloClient();

    useTournamentListenStateSubscription({
        variables: { tournamentId },
        onError: error => console.error(error),
        onData: ({ data: { data } }) => {
            const { tournamentListenTournamentState: tournament } = data || {};

            if (tournament) {
                cache.modify({
                    id: `TournamentEntity:${tournament.id}`,
                    fields: useBatchUpdateCacheFields<TournamentEntity>(tournament),
                });

                options.onStateChange?.(tournament);
            }
        },
    });
};
