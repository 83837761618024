/**
 * Use confirm
 *
 * @author: exode <hello@exode.ru>
 */

import { Router } from '@/services/Utils/Router';

import { ConfirmationModalStore, ConfirmModalProps } from '@/modals/Utils/Confirmation';


/**
 * Confirmation
 * @param {ConfirmModalProps} props
 */
const useConfirm = (props: ConfirmModalProps) => {

    const openConfirm = (partial?: Partial<ConfirmModalProps>) => {
        ConfirmationModalStore.openConfirm({
            ...props,
            ...partial,
        });

        Router.pushModal('confirm-modal');
    };

    return { openConfirm };
};

/**
 * Prompt
 * @param {ConfirmModalProps} props
 */
const usePrompt = (props: ConfirmModalProps) => {

    const { openConfirm: openPrompt } = useConfirm(props);

    return { openPrompt };
};


export { useConfirm, usePrompt };
