/**
 * GradientButtonCard
 *
 * @author: exode <hello@exode.ru>
 */

import React, { ReactElement, ReactNode } from 'react';

import { ConfigStore, observer } from '@/store/core/config';

import { If, Responsive, SvgComponent } from '@/cutils';
import { Button, ButtonProps, Spinner } from '@exode.ru/vkui';

import { GradientBanner, GradientBannerProps } from '@/components/Atoms/GradientBanner';


interface Props extends GradientBannerProps {
    header: ReactNode;
    subheader: ReactNode;
    button?: ButtonProps;
    onClick?: () => void;
    stopPropagation?: boolean;
    before?: ReactElement;
    afterIcon?: ReactElement;
    isLoading?: boolean;
    className?: string;
    wholeIsClickable?: boolean;
    afterWrapper?: (children: ReactElement) => ReactElement;
    afterWrapperClassName?: string;
}


const GradientButtonCard = observer((props: Props) => {

    const {
        header,
        subheader,
        before,
        afterIcon,
        onClick,
        gradient,
        button,
        className,
        afterWrapperClassName,
        isLoading = false,
        stopPropagation = true,
        wholeIsClickable = false,
        after = (
            <>
                <If is={!!button}>
                    <Responsive.Desktop>
                        <Button size="m"
                                loading={isLoading}
                                appearance="positive"
                                disabled={isLoading}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    onClick?.();
                                }} {...button}/>
                    </Responsive.Desktop>
                </If>

                <If is={!!afterIcon}>
                    <Responsive.Mobile force={!button}>
                        <div>
                            <If is={isLoading}>
                                <Spinner className="text-primary bg-content-25--blur px-0.5 py-1 rounded-full"/>
                            </If>

                            <If is={!isLoading}>
                                <SvgComponent element={afterIcon || <></>} svgProps={{ className: 'xs:hidden' }}/>
                            </If>
                        </div>
                    </Responsive.Mobile>
                </If>
            </>
        ),
        afterWrapper = (children: ReactElement) => (
            <div style={{ transform: 'translate(0px, 50%)' }} className={[
                afterWrapperClassName,
                'mr-4 h-[32px] flex items-center justify-center',
            ].join(' ')}>
                {children}
            </div>
        ),
        ...rest
    } = props;

    const { isDesktop } = ConfigStore;

    return (
        <GradientBanner header={header}
                        gradient={gradient}
                        imageTheme="light"
                        subheader={subheader}
                        after={afterWrapper(after)}
                        before={before && <div className="ml-0.5">{before}</div>}
                        onClick={(e) => {
                            if (stopPropagation) {
                                e.stopPropagation();
                            }

                            return (isDesktop && !wholeIsClickable)
                                ? null
                                : !isLoading && onClick?.();
                        }}
                        className={[
                            className,
                            '!mt-0 m:cursor-pointer text-primary',
                            !isDesktop ? 'card-clear-after' : '',
                            wholeIsClickable ? 'cursor-pointer' : '',
                        ].join(' ')} {...rest}/>
    );
});


export { GradientButtonCard };
