/**
 * ProfileSetStatusModal
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { Modal } from '@/cutils';

import { useI18n } from '@/hooks/core';

import { Title } from '@exode.ru/vkui';

import { ProfileSetStatusView } from '@/pages/Profile/Profile/views/ProfileSetStatusView';


interface Props {
    id: string;
}


const ProfileSetStatusModal = (props: Props) => {

    const { t } = useI18n('modals.Profile.ProfileSetStatus');

    return (
        <Modal.Card id={props.id} subheader={t('setYourProfilesPublicStatus')} header={(
            <Title className="modal-title" level="2" weight="3">
                {t('profileStatus')}
            </Title>
        )}>
            <Modal.Body className="p-3">
                <ProfileSetStatusView/>
            </Modal.Body>
        </Modal.Card>
    );
};


export { ProfileSetStatusModal };
