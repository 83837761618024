/**
 * DesktopNavigationScroll component
 *
 * @author: exode <hello@exode.ru>
 */

import React, { useEffect, useState } from 'react';

import { ScrollHelper } from '@/helpers/ui';
import { DocumentEvent } from '@/types/window';

import { Icon28ChevronDownOutline, Icon28ChevronUpOutline } from '@vkontakte/icons';

import { Container } from './DesktopNavigationScroll.styled';


const DesktopNavigationScroll = () => {

    const deltaTop = 200;

    const [ scrollTop, setScrollTop ] = useState<number>(0);
    const [ lastScrollTop, setLastScrollTop ] = useState<number>(0);

    const pageIsScrollable = document.body.scrollHeight >= window.outerHeight + deltaTop;

    const isOverDelta = scrollTop > deltaTop || lastScrollTop;
    const isBackInterval = scrollTop < deltaTop;

    const onScroll = () => setScrollTop(ScrollHelper.scrollTop);
    const onPageUpdate = () => setLastScrollTop(0);

    const handleScrollTop = () => {
        setLastScrollTop(ScrollHelper.scrollTop);
        ScrollHelper.to(0, true);
    };

    const handleScrollBack = () => {
        ScrollHelper.to(lastScrollTop, true);
        setTimeout(() => setLastScrollTop(0), 200);
    };

    useEffect(() => {
        window.addEventListener('scroll', onScroll, { passive: true });
        document.addEventListener(DocumentEvent.RouterBeforeUpdate, onPageUpdate, { passive: true });

        return () => {
            window.removeEventListener('scroll', onScroll);
            document.removeEventListener('router:before:update', onPageUpdate);
        };
    });

    if (!pageIsScrollable || !isOverDelta) {
        return <></>;
    }

    return (
        <Container onClick={lastScrollTop && isBackInterval ? handleScrollBack : handleScrollTop}>
            {(lastScrollTop && isBackInterval
                    ? <Icon28ChevronDownOutline fill="var(--accent)"/>
                    : <Icon28ChevronUpOutline fill="var(--accent)"/>
            )}
        </Container>
    );
};


export { DesktopNavigationScroll };
