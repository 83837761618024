/**
 * Server api
 *
 * @author: exode <hello@exode.ru>
 */

import fetch from 'cross-fetch';

import { graphQlApiUrl } from '@/api/constants';

import { ApolloClient, HttpLink, InMemoryCache } from '@apollo/client';


export const serverApolloClient = new ApolloClient({
    ssrMode: true,
    cache: new InMemoryCache(),
    link: new HttpLink({ uri: graphQlApiUrl, fetch }),
});
