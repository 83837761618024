/**
 * Styled helpers
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import { CSSProperties } from 'react';


export const spreadStyle = (style?: CSSProperties) => {
    return style && Object.entries(style)
        .map(([ key, value ]) => `${_.kebabCase(key)}: ${value};`)
        .join(' ');
};
