/**
 * Nestjs graphql mock
 *
 * @author: exode <hello@exode.ru>
 */

/**
 * Mock InterfaceType
 * @param options
 * @returns {ClassDecorator}
 * @constructor
 */
export function InterfaceType(options?: any): ClassDecorator;
export function InterfaceType(name: string, options?: any): ClassDecorator;
export function InterfaceType(nameOrOptions?: string | any, interfaceOptions?: any): ClassDecorator {
    return (target) => {};
}

/**
 * Mock ObjectType
 * @param options
 * @returns {ClassDecorator}
 * @constructor
 */
export function ObjectType(options?: any): ClassDecorator;
export function ObjectType(name: string, options?: any): ClassDecorator;
export function ObjectType(nameOrOptions?: string | any, interfaceOptions?: any): ClassDecorator {
    return (target) => {};
}

/**
 * Mock InputType
 * @param options
 * @returns {ClassDecorator}
 * @constructor
 */
export function InputType(options?: any): ClassDecorator;
export function InputType(name: string, options?: any): ClassDecorator;
export function InputType(nameOrOptions?: string | any, interfaceOptions?: any): ClassDecorator {
    return (target) => {};
}

/**
 * Mock PartialType
 * @returns {ClassDecorator}
 * @constructor
 * @param classRef
 * @param decorator
 */
export function PartialType<T>(classRef: any, decorator?: any): any {

}


/**
 * Mock Field
 * @returns {any}
 * @constructor
 */
export function Field(): any;
export function Field(options: any): any;
export function Field(returnTypeFunction?: any, options?: any): any;
export function Field(typeOrOptions?: any, fieldOptions?: any) {
    return (
        prototype: Object,
        propertyKey?: string,
        descriptor?: TypedPropertyDescriptor<any>,
    ) => {};
}

/**
 * Mock Extensions
 * @returns {any}
 * @constructor
 */
export function Extensions(): any;
export function Extensions(options: any): any;
export function Extensions(returnTypeFunction?: any, options?: any): any;
export function Extensions(typeOrOptions?: any, fieldOptions?: any) {
    return (
        prototype: Object,
        propertyKey?: string,
        descriptor?: TypedPropertyDescriptor<any>,
    ) => {};
}

export const Int = {};
