/**
 * FooterPart
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { observer, SchoolStore } from '@/store/platform';

import { useI18n } from '@/hooks/core';
import { RoutePathType } from '@/router/paths';

import { If, Platform } from '@/cutils';
import { schoolContactRole } from '@/types/school';
import { SchoolContactRole } from '@/codegen/graphql';

import { marketplaceSections } from './SectionsPart';
import { FooterSocialsPart } from './FooterSocialsPart';
import { FooterBlockItemPart } from './FooterBlockItemPart';


const FooterPart = observer(() => {

    const schoolContactRoles = schoolContactRole();

    const { t } = useI18n('components.Navigation.Block.Footer.parts');

    return (
        <>
            <Platform.Market>
                {marketplaceSections().map((section, index) => (
                    <FooterBlockItemPart key={index} section={section}/>
                ))}

                <FooterSocialsPart/>
            </Platform.Market>

            <Platform.School>
                {SchoolStore?.blocks?.footer?.map((section, index) => (
                    <FooterBlockItemPart key={index} section={{
                        ...section,
                        items: section.items?.map(item => ({
                            ...item,
                            link: item.link as RoutePathType,
                        })),
                    }}/>
                ))}

                <If is={!!SchoolStore?.school?.contacts?.length}>
                    <FooterBlockItemPart section={{
                        title: t('contacts'),
                        items: SchoolStore?.school?.contacts
                            .filter((contact) => contact.role !== SchoolContactRole.Support)
                            .map((contact) => ({
                                mode: 'contact',
                                link: contact.link as RoutePathType,
                                contact: {
                                    role: schoolContactRoles[contact.role as SchoolContactRole],
                                    name: contact.name,
                                    phone: contact.phone || '',
                                    email: contact.email || '',
                                },
                            })) || [],
                    }}/>
                </If>
            </Platform.School>
        </>
    );
});


export { FooterPart };
