/**
 * UserAccessItem
 *
 * @author: exode <hello@exode.ru>
 */

import React, { Dispatch, Fragment, SetStateAction } from 'react';

import { FieldArray, FormikHelpers } from 'formik';

import { ConfigStore, observer } from '@/store/core/config';

import { If } from '@/cutils';
import { useI18n } from '@/hooks/core';
import { useSchoolUser } from '@/hooks/apollo';
import { TaskBuilderFormValues } from '@/types/task';

import { ProductLaunchStatus } from '@/codegen/graphql';

import { Button, FormItem, FormLayoutGroup, Separator, SimpleCell } from '@exode.ru/vkui';
import { Icon24AddSquareOutline, Icon24ChevronDown, Icon24ChevronUp, Icon24DeleteOutline } from '@vkontakte/icons';

import { ConfirmButton } from '@/components/Atoms/ConfirmButton';
import { CourseSearchSelect } from '@/components/Course/CourseSearchSelect';
import { LaunchSearchSelect } from '@/components/Product/LaunchSearchSelect';
import { LaunchPriceSearchSelect } from '@/components/Product/LaunchPriceSearchSelect';


interface Props {
    showUserAccess: Record<number, boolean>;
    setFieldValue: FormikHelpers<TaskBuilderFormValues>['setFieldValue'];
    setShowUserAccess: Dispatch<SetStateAction<{ [key: number]: boolean; }>>;
    values: ReturnType<typeof useSchoolUser>['initialValuesForCreate']['enrollments']['accesses'];
}


const UserAccessItem = observer((props: Props) => {

    const {
        values,
        setFieldValue,
        showUserAccess,
        setShowUserAccess,
    } = props;

    const { t } = useI18n('pages.Manage.School.Users.items');

    const toggleUserInfo = (index: number) => {
        setShowUserAccess((prevState) => ({
            ...prevState,
            [index]: !prevState[index],
        }));
    };

    return (
        <FieldArray name="enrollments.accesses">
            {({ push, remove }) => (
                <>
                    {values?.map(({ productId, launchId, priceId }, index: number) => (
                        <Fragment key={index}>
                            <SimpleCell hasHover={false}
                                        hasActive={false}
                                        onClick={() => toggleUserInfo(index)}
                                        className="bg-hover thin-border vk-rounded cursor-pointer mt-5"
                                        after={(
                                            <div className="flex items-center gap-2">
                                                {showUserAccess[index] ? <Icon24ChevronUp/> : <Icon24ChevronDown/>}

                                                <ConfirmButton stopPropagation
                                                               text={t('deleteUser')}
                                                               data-test="user.delete"
                                                               className="rounded-full"
                                                               callback={() => remove(index)}
                                                               initialIcon={<Icon24DeleteOutline fill="var(--accent)"/>}
                                                               confirmIcon={(
                                                                   <Icon24DeleteOutline fill="var(--destructive)"/>
                                                               )}/>
                                            </div>
                                        )}>
                                {t('courseIndex', { index: index + 1 })}
                            </SimpleCell>

                            <If is={showUserAccess[index]}>
                                <FormLayoutGroup className="p-0 mt-5"
                                                 mode={ConfigStore.isDesktop ? 'horizontal' : 'vertical'}>
                                    <FormItem top={t('searchByCourses')} className="p-0">
                                        <CourseSearchSelect selectedCourseIds={[ productId ]}
                                                            data-test="user-create.courses-select"
                                                            onSelect={({ value }) => {
                                                                setFieldValue(
                                                                    `enrollments.accesses[${index}]`,
                                                                    {
                                                                        ...values[index],
                                                                        productId: value,
                                                                    },
                                                                );
                                                            }}
                                                            ignoreCourseIds={(
                                                                values
                                                                    .map(({ productId }) => productId)
                                                                    .filter((e) => e !== productId)
                                                            )}
                                                            filter={{
                                                                product: {
                                                                    launch: {
                                                                        statuses: [ ProductLaunchStatus.Published ],
                                                                    },
                                                                },
                                                            }}/>
                                    </FormItem>

                                    <FormItem top={t('searchByLaunches')} className="p-0 mt-5">
                                        <LaunchSearchSelect selectedLaunchIds={[ launchId ]}
                                                            data-test="user-create.launches-select"
                                                            onSelect={({ value }) => {
                                                                setFieldValue(
                                                                    `enrollments.accesses[${index}]`,
                                                                    {
                                                                        ...values[index],
                                                                        launchId: value,
                                                                    },
                                                                );
                                                            }}
                                                            filter={{
                                                                productIds: [ productId ],
                                                                statuses: [ ProductLaunchStatus.Published ],
                                                            }}/>
                                    </FormItem>
                                </FormLayoutGroup>

                                <FormItem top={t('searchByLaunchRates')} className="p-0 mt-5">
                                    <LaunchPriceSearchSelect selectedLaunchPriceIds={[ priceId ]}
                                                             data-test="user-create.prices-select"
                                                             filter={{
                                                                 productId,
                                                                 active: true,
                                                                 launchIds: [ launchId ],
                                                             }}
                                                             onSelect={({ value }) => {
                                                                 setFieldValue(
                                                                     `enrollments.accesses[${index}]`,
                                                                     {
                                                                         ...values[index],
                                                                         priceId: value,
                                                                     },
                                                                 );
                                                             }}/>
                                </FormItem>
                            </If>

                            <If is={index < (values?.length || 0) - 1}>
                                <Separator wide className="mt-5 mb-2"/>
                            </If>
                        </Fragment>
                    ))}

                    <div>
                        <Button stretched
                                size="l"
                                type="button"
                                mode="secondary"
                                className="mt-5"
                                data-test="user.add"
                                disabled={values.length > 10}
                                before={<Icon24AddSquareOutline/>}
                                onClick={() => {
                                    push({});

                                    setShowUserAccess((prevState) => ({
                                        ...prevState,
                                        [values?.length]: true,
                                    }));
                                }}>
                            {t('add')}
                        </Button>
                    </div>
                </>
            )}
        </FieldArray>
    );
});


export { UserAccessItem };
