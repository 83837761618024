/**
 * TournamentLobbyPage store
 *
 * @author: exode <hello@exode.ru>
 */

import { ReactElement } from 'react';

import { makeObservable } from 'mobx';

import { observer, PageStore } from '@/pages/Core';


class TournamentLobbyPage extends PageStore {

    constructor() {
        super();
        makeObservable(this);
    }

    state = {
        input: {
            broadcast: null as ReactElement | null,
        },
    };

    chatList = { skip: 0, take: 100 };

    addToStore(broadcast: ReactElement) {
        if (this.state.input.broadcast === null) {
            this.state.input.broadcast = broadcast;
        }
    }

}

const TournamentLobbyPageStore = new TournamentLobbyPage();


export { observer, TournamentLobbyPageStore };
