/**
 * ListPart
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { If } from '@/cutils';

import { InnerHtml } from '@/components/Atoms/InnerHtml';


const Parent: React.FC<{
    mode: 'ordered' | 'unordered'
}> = (props) => {

    const { mode, ...rest } = props;

    return mode === 'ordered'
        ? <ol {...rest}/>
        : <ul {...rest}/>;
};

const ListPart = ({ data, ...rest }: any) => {
    return (
        <If is={!!data}>
            <Parent mode={data.style} {...rest}>
                {data.items?.map((item: string, index: number) => (
                    <li key={index}>
                        <InnerHtml content={item} include={[ 'timeCodes' ]}/>
                    </li>
                ))}
            </Parent>
        </If>
    );
};


export { ListPart };
