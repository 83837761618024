/**
 * ChatCellAvatarPart
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { If } from '@/cutils';
import { ChatType } from '@/codegen/graphql';

import { calcInitialsAvatarColor, InitialsAvatar } from '@exode.ru/vkui';

import { UserAvatar } from '@/components/Atoms/UserAvatar';

import { ChatCellItemProps } from '../items/ChatCellItem';


interface Props extends ChatCellItemProps {

}


const ChatCellAvatarPart = (props: Props) => {

    const { chat, isActive } = props;

    const { id, icon, name, personalCompanionUserId } = chat;

    return (
        <>
            <If is={chat.type !== ChatType.Personal}>
                <If is={!!icon}>
                    <UserAvatar size={50}
                                lazy={false}
                                entityId={chat.id}
                                src={icon as string}
                                name={`${chat.name}`}
                                className="my-auto ml-0.5"/>
                </If>

                <If is={!icon}>
                    <InitialsAvatar className="my-auto ml-0.5" size={50} gradientColor={calcInitialsAvatarColor(+id)}>
                        {name?.charAt(0)}
                    </InitialsAvatar>
                </If>
            </If>

            <If is={chat.type === ChatType.Personal}>
                <UserAvatar size={50}
                            lazy={false}
                            src={icon || ''}
                            name={name || ''}
                            className="my-auto ml-0.5"
                            userId={personalCompanionUserId || 0}
                            badgeBg={isActive ? 'var(--background_selected)' : ''}/>
            </If>
        </>
    );
};


export { ChatCellAvatarPart };
