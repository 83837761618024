/**
 * SimpleCellTree styled container
 *
 * @author: exode <hello@exode.ru>
 */

import styled from 'styled-components';


export const Container = styled.div`
    .items-list {
        position: relative;
        margin: 4px 0 4px 34px;

        &:after {
            content: '';
            width: 4px;
            left: 0;
            top: 0;
            position: absolute;
            height: 100%;
            border-radius: 3px;
            opacity: 0.36;
            background: var(--icon_secondary);
        }

        > *,
        .Cell__content {
            border-top-left-radius: 0 !important;
            border-bottom-left-radius: 0 !important;
        }
    }
`;
