/**
 * TagsItem component
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { If } from '@/cutils';

import { Avatar, Caption } from '@exode.ru/vkui';

import { FeedbackRateAndParticipantCount } from '@/components/Course/CourseCard';

import { CourseCardProps } from '../CourseCard';


interface Props {
    course: CourseCardProps['course'];
}


const TagsItem = (props: Props) => {

    const {
        course,
        course: {
            product,
            contentCategory,
        },
    } = props;

    return (
        <div className="gap-4 tags-wrapper m-5 m:m-4 w-[calc(100%_-_128px)]">
            <If is={!!course.contentCategory}>
                <div className="tag-item w-max" style={{ maxWidth: 'clamp(50%, calc(100% - 77px), 100%)' }}>
                    <If is={!!contentCategory?.icon}>
                        <Avatar size={16} src={contentCategory?.icon || ''}/>
                    </If>

                    <Caption level="2" className="overflow-hidden text-ellipsis whitespace-nowrap">
                        {course.contentCategory?.name}
                    </Caption>
                </div>
            </If>

            <div className="tag-item">
                <FeedbackRateAndParticipantCount course={course}
                                                 className="text-muted"
                                                 rate={product?.feedbackRating}
                                                 participants={product?.participantsCount}/>
            </div>
        </div>
    );
};


export { TagsItem };
