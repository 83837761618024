/**
 * App socket subscriptions
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { Auth } from '@/cutils';

import { PingPongSubscription } from './PingPongSubscription';


const AppSocketSubscriptions = () => {
    return (
        <Auth>
            <PingPongSubscription/>
        </Auth>
    );
};


export { AppSocketSubscriptions };
