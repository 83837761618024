/**
 * TournamentTab store
 *
 * @author: exode <hello@exode.ru>
 */

import { makeObservable } from 'mobx';
import { observer } from 'mobx-react';

import { PageStore } from '@/pages/Core';

import { defaultFilter, dtoFilterTransformer } from '@/pages/Tournament/Tournament/filter';
import { SortDirection, TournamentState, TournamentType } from '@/codegen/graphql';


class TournamentTab extends PageStore {

    constructor() {
        super();
        makeObservable(this);
    }

    state = {
        input: {},
        list: {
            upcoming: { skip: 0, take: 10 },
            scheduled: { skip: 0, take: 15 },
        },
        temporaryFilter: defaultFilter,
        filter: dtoFilterTransformer(defaultFilter),
        sort: {
            id: SortDirection.Desc,
            state: [
                TournamentState.Process,
                TournamentState.StartLobby,
            ],
        },
    };

    handleTemporaryFilterChange(
        name: keyof typeof TournamentTabStore['state']['temporaryFilter'],
        value: any,
    ) {
        this.state.temporaryFilter[name] = value as never;
    }

    setTournamentFilterTypes(value: TournamentType) {
        this.state.temporaryFilter.types = [ value ];
    }

    mergeTemporaryFilter(partial: Partial<this['state']['filter']>) {
        Object.assign(this.state.temporaryFilter, partial);
    }

}

const TournamentTabStore = new TournamentTab();


export { observer, TournamentTabStore };
