/**
 * ImageBlurOnLoad
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';


interface Props {
    width: number | string | undefined;
    height: number | string | undefined;
    maxHeight: number | string;
    location?: string | null;
    name?: string | null;
    blur?: boolean;
    className?: string;
    wrapperClassName?: string;
}


const ImageBlurOnLoad = (props: Props) => {

    const {
        name,
        blur,
        width,
        height,
        maxHeight,
        location,
        className,
        wrapperClassName,
    } = props;

    return (
        <div style={blur ? { filter: 'blur(2px)' } : {}} className={wrapperClassName}>
            <img width={width}
                 height={height}
                 src={location || ''}
                 style={{ maxHeight }}
                 alt={name || `image`}
                 className={[ 'object-cover vk-rounded shadow-md', className ].join(' ')}/>
        </div>
    );
};


export { ImageBlurOnLoad };
