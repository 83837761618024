/**
 * TournamentLobbyChatSubscription
 *
 * @author: exode <hello@exode.ru>
 */

import React, { useEffect } from 'react';

import { useTournamentMessage } from '@/hooks/apollo';

import { useTournamentMessagesQuery } from '@/codegen/graphql';

import { TournamentLobbyPageStore } from '@/pages/Tournament/Lobby/store';


interface Props {
    tournamentId: number;
}


const TournamentChatMessagesSubscription = (props: Props) => {

    const { tournamentId } = props;

    const { subscribeToMore } = useTournamentMessagesQuery({
        variables: {
            tournamentId,
            list: TournamentLobbyPageStore.chatList,
        },
    });

    const { subscribeToNewMessages } = useTournamentMessage(tournamentId, subscribeToMore);

    useEffect(() => {
        subscribeToNewMessages();
    }, []);

    return (<></>);
};


export { TournamentChatMessagesSubscription };
