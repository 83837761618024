/**
 * IconExodeIcon component
 *
 * @author: exode <hello@exode.ru>
 */

import React, { SVGProps } from 'react';


const IconExodeIcon = (props: SVGProps<SVGSVGElement>) => {
    return (
        <svg width={512} height={512} viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M349 0H163C72.9776 0 0 72.9776 0 163V349C0 439.022 72.9776 512 163 512H349C439.022 512 512 439.022 512 349V163C512 72.9776 439.022 0 349 0Z"
                  fill="url(#paint0_linear_1649_6612)"/>
            <path d="M375.373 266.393C375.373 269.393 375.373 271.183 375.373 272.943C375.373 284.783 375.063 296.613 375.373 308.443C375.613 319.043 371.943 327.193 362.593 332.633C344.893 342.943 327.313 353.443 309.593 363.683C296.183 371.423 282.533 378.753 269.133 386.503C260.223 391.663 251.753 391.773 242.723 386.563C211.983 368.857 181.166 351.293 150.272 333.873C141.002 328.673 136.882 321.583 137.002 310.773C137.402 275.623 137.152 240.453 137.162 205.293C137.162 203.123 137.302 200.963 137.282 198.793C137.282 191.273 140.472 185.883 147.092 182.113C165.622 171.563 184.002 160.773 202.463 150.103C216.733 141.853 231.113 133.773 245.293 125.363C252.633 121.003 259.673 120.853 267.003 125.083C299.003 143.553 331.133 161.873 363.053 180.503C373.663 186.693 378.513 201.043 373.563 212.363C371.863 216.253 367.803 219.683 364.013 221.973C341.463 235.523 318.723 248.763 295.943 261.973C274.003 274.633 252.003 287.073 230.073 299.713C226.073 302.043 222.333 304.893 218.393 307.383C215.583 309.163 215.393 310.813 218.333 312.513C229.826 319.247 241.319 325.96 252.813 332.653C255.973 334.503 258.813 333.573 261.703 331.873C297.863 310.913 334.039 289.99 370.233 269.103C371.503 268.373 372.823 267.733 375.373 266.393ZM188.262 240.053H188.192C188.192 246.373 188.192 252.693 188.192 259.053C188.192 262.883 189.432 263.883 193.003 261.803C225.783 242.777 258.586 223.777 291.413 204.803C295.833 202.243 295.963 201.063 292.023 198.803C281.333 192.733 270.683 186.563 259.803 180.803C258.658 180.299 257.419 180.045 256.169 180.059C254.918 180.073 253.685 180.354 252.553 180.883C232.293 192.403 212.243 204.243 192.003 215.793C190.733 216.38 189.68 217.352 188.992 218.571C188.305 219.789 188.018 221.193 188.172 222.583C188.382 228.403 188.262 234.223 188.262 240.053Z"
                  fill="white"/>
            <defs>
                <linearGradient id="paint0_linear_1649_6612"
                                x1={493.359}
                                y1={-126.854}
                                x2={7.62519}
                                y2={578.956}
                                gradientUnits="userSpaceOnUse">
                    <stop offset={0.409949} stopColor="#FF9A44"/>
                    <stop offset={0.879014} stopColor="#FD2A48"/>
                </linearGradient>
            </defs>
        </svg>
    );
};


export { IconExodeIcon };
