/**
 * SessionActionsDropdown
 *
 * @author: exode <hello@exode.ru>
 */

import React, { useState } from 'react';

import { If } from '@/cutils';
import { useI18n } from '@/hooks/core';
import { useSchoolSession } from '@/hooks/apollo';

import { IconButton } from '@mui/material';
import { CellButton, Spinner } from '@exode.ru/vkui';
import { Dropdown } from '@exode.ru/vkui/dist/unstable';
import { Icon24MoreHorizontal, Icon28DoorArrowRightOutline } from '@vkontakte/icons';

import { ConfirmButton } from '@/components/Atoms/ConfirmButton';


interface Props {
    sessionId: number;
}


const SessionActionsDropdown = (props: Props) => {

    const { sessionId } = props;

    const [ showDropdown, setShowDropdown ] = useState(false);

    const { t } = useI18n('pages.Manage.School.Users.views.EditUserFormView');

    const { killSessionById, killSessionByIdLoading } = useSchoolSession();

    return (
        <Dropdown action="click"
                  shown={showDropdown}
                  className="overflow-hidden"
                  onShownChange={setShowDropdown}
                  content={(
                      <ConfirmButton loading={killSessionByIdLoading}
                                     confirmProps={{ children: t('confirmKillSession') }}
                                     callback={() => {
                                         killSessionById(sessionId);
                                         setShowDropdown(false);
                                     }}>
                          <CellButton centered
                                      mode="danger"
                                      data-test="settings.log-out"
                                      before={<Icon28DoorArrowRightOutline/>}>
                              {t('killSession')}
                          </CellButton>
                      </ConfirmButton>
                  )}>
            <div className={[
                showDropdown ? 'bg-hover rounded-full thin-border !visible' : '',
            ].join(' ')}>
                <IconButton disabled={killSessionByIdLoading}>
                    <If is={!killSessionByIdLoading}>
                        <Icon24MoreHorizontal width={24} fill="var(--accent)"/>
                    </If>

                    <If is={!!killSessionByIdLoading}>
                        <Spinner/>
                    </If>
                </IconButton>
            </div>
        </Dropdown>
    );
};


export { SessionActionsDropdown };
