/**
 * String util
 *
 * @author: exode <hello@exode.ru>
 */

class StringUtil {

    /**
     * Get selected text
     * @returns {string | undefined}
     */
    static getSelection() {
        return window?.getSelection?.()?.toString();
    }

}


export { StringUtil };
