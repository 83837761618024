/**
 * EmptyCourseListView
 *
 * @author: exode <hello@exode.ru>
 */

import React, { ReactElement } from 'react';

import { If } from '@/cutils';
import { Page } from '@/pages/Core';
import { useI18n } from '@/hooks/core';

import { Icon24PlayCards2Outline } from '@vkontakte/icons';

import { Placeholder, PlaceholderProps } from '@/components/Atoms/Placeholder';


interface Props {
    wrapPageRaw?: boolean;
    placeholderProps?: PlaceholderProps;
}


const EmptyCourseListView = (props: Props) => {

    const { placeholderProps, wrapPageRaw = true } = props;

    const { t } = useI18n('tabs.Education.views');

    const wrapper = (component: ReactElement) => {
        return (
            <>
                <If is={wrapPageRaw}>
                    <Page.Row fullHeight>
                        {component}
                    </Page.Row>
                </If>

                <If is={!wrapPageRaw}>
                    {component}
                </If>
            </>
        );
    };

    return wrapper(
        <Placeholder iconSize={120}
                     innerPadding="0px"
                     header={t('coursesNotFound')}
                     content={t('mostLikelyTheyAreBeingCreatedRightNow')}
                     icon={<Icon24PlayCards2Outline className="text-accent opacity-[0.85]"/>}
                     {...placeholderProps}/>,
    );
};


export { EmptyCourseListView };
