/**
 * VideoTagPart
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { If } from '@/cutils';

import { CourseService } from '@/services/Course/Course';

import { TagInlineText } from '@/components/Atoms/TagInlineText';


interface Props {
    duration: number;
    reverse?: boolean;
}


const VideoTagPart = (props: Props) => {

    const { duration, reverse } = props;

    return (
        <>
            <If is={!reverse}>
                <span>{' • '}</span>
            </If>

            <TagInlineText gradient="gray" className="min-w-[29px]" content={CourseService.formatDuration(duration)}/>

            <If is={!!reverse}>
                <span>{' • '}</span>
            </If>
        </>
    );
};


export { VideoTagPart };
