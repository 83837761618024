/**
 * UserPasswordPage store
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import { makeObservable } from 'mobx';
import { apolloClient } from '@/api/graphql';
import { observer, PageStore } from '@/pages/Core';

import toast from 'react-hot-toast';
import { FormikHelpers } from 'formik';

import { Router } from '@/services/Utils/Router';
import { UserAuthService } from '@/services/User/Auth';


class UserPasswordPage extends PageStore {

    constructor() {
        super();
        makeObservable(this);
    }

    state = {
        input: {
            password: '',
            confirmPassword: '',
        },
        loading: {
            updatePassword: false,
        },
    };

    async updatePassword(formik: FormikHelpers<UserPasswordPage['state']['input']>) {
        const { input } = this.state;

        const { success, exception, payload } = await this.wrapService('updatePassword', {
            service: UserAuthService.updatePassword({ password: input.password, logout: false }),
        });

        if (!success) {
            if (exception) {
                formik.setErrors({ password: exception.message, confirmPassword: ' ' });
            }

            return false;
        }

        if (success && !_.isEmpty(payload)) {
            Router.replacePage('/settings');

            this.state.input = {
                password: '',
                confirmPassword: '',
            };

            formik.resetForm();
            toast.success('Пароль успешно изменен');

            apolloClient.cache.modify({
                id: `PasswordEntity:${payload?.previous?.id}`,
                fields: {
                    updatedAt: () => new Date(),
                },
            });
        }
    }

}

const UserPasswordPageStore = new UserPasswordPage();


export { observer, UserPasswordPageStore };
