/**
 * ModalBody component
 *
 * @author: exode <hello@exode.ru>
 */

import React, { ReactElement } from 'react';


export interface ModalBodyProps {
    children: ReactElement | ReactElement[];
    className?: string;
    fullHeight?: boolean;
    isScrollable?: boolean;
}


const ModalBody = (props: ModalBodyProps) => {

    const {
        children,
        className,
        fullHeight = false,
        isScrollable = false,
    } = props;

    return (
        <div className={[
            className,
            fullHeight ? 'h-[100vh]' : '',
            isScrollable ? 'modal-body-scrollable mt-3' : '',
        ].join(' ')}>
            {children}
        </div>
    );
};


export { ModalBody };
