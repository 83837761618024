/**
 * PullToRefresh util
 *
 * @author: exode <hello@exode.ru>
 */

import React, { ReactElement, useCallback, useState } from 'react';

import { RefetchQueriesInclude, useApolloClient } from '@apollo/client';

import { PullToRefresh as VkUiPullToRefresh } from '@exode.ru/vkui';


interface Props {
    children: ReactElement | ReactElement[];
    queries?: RefetchQueriesInclude;
}


const PullToRefresh = (props: Props) => {

    const { children, queries } = props;

    const apolloClient = useApolloClient();

    const [ isFetching, setFetching ] = useState(false);

    const onRefresh = useCallback(async () => {
        setFetching(true);

        await apolloClient.refetchQueries({ include: queries || 'active' });

        setFetching(false);
    }, [ queries ]);

    return (
        <VkUiPullToRefresh onRefresh={onRefresh} isFetching={isFetching}>
            {children}
        </VkUiPullToRefresh>
    );
};


export { PullToRefresh };
