/**
 * ProgressItem component
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { CourseService } from '@/services/Course/Course';

import { If } from '@/cutils';
import { ArrayUtil } from '@/utils';
import { useI18n } from '@/hooks/core';

import { Icon16Flag } from '@vkontakte/icons';
import { Caption, List } from '@exode.ru/vkui';

import { CourseCardProps } from '../CourseCard';
import { ProgressLessonLineItem } from './ProgressLessonLineItem';


export interface ProgressItemProps {
    course: CourseCardProps['course'];
    multiLine: boolean;
}


const ProgressItem = (props: ProgressItemProps) => {

    const { t } = useI18n('components.Course.CourseCard');

    const {
        course,
        multiLine,
        course: { currentLesson, lessons },
    } = props;

    const {
        totalCount,
        completedCount,
    } = CourseService.getLessonsCount(lessons);

    return (
        <>
            <div className={[
                multiLine ? 'mt-8' : '',
                'flex items-center mb-3 d:mb-0 gap-1.5',
            ].join(' ')}>
                <Icon16Flag className="mt-[1px] min-w-[16px]"/>

                <span className="fs-14 whitespace-nowrap">
                    {completedCount} / {totalCount}
                </span>

                <span>•</span>

                <Caption className="fs-14 pr-2 overflow-ellipsis">
                    {(
                        currentLesson
                            ? currentLesson?.name
                            : t('lessons', { count: totalCount || 0 })
                    )}
                </Caption>
            </div>

            <List className="progress-list mb-0.5">
                {ArrayUtil.groupByParent(
                    lessons || [],
                    'id',
                    'parent.id',
                ).map((parent, index) => (
                    <React.Fragment key={parent.id}>
                        <If is={index > 0}>
                            <div className="ml-[2px]"/>
                        </If>

                        <If is={parent.withContent}>
                            <ProgressLessonLineItem course={course} lesson={parent} currentLesson={currentLesson}/>
                        </If>

                        {parent.items?.map((lesson) => (
                            <ProgressLessonLineItem key={lesson.id}
                                                    course={course}
                                                    lesson={lesson}
                                                    currentLesson={currentLesson}/>
                        ))}
                    </React.Fragment>
                ))}
            </List>
        </>
    );
};


export { ProgressItem };
