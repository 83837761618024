/**
 * FilterInput component
 *
 * @author: exode <hello@exode.ru>
 */

import React, { ChangeEvent, useEffect, useState } from 'react';

import { Input, InputProps } from '@exode.ru/vkui';


interface Props extends InputProps {
    regExp: RegExp;
    callback?: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    callOnSideEffect?: boolean;
}


const FilterInput = (props: Props) => {

    const { regExp, callback, value, ...rest } = props;

    const [ localValue, setLocalValue ] = useState(props.value);

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;

        if (value === '' || regExp.test(value)) {
            callback?.(event);
            setLocalValue(value);
        }
    };

    useEffect(() => {
        if (!props.callOnSideEffect) {
            return;
        }

        const event = {
            target: {
                name: props.name as string,
                value: props.value as any,
            },
        };

        if (props.value !== localValue && regExp.test(props.value as string)) {
            callback?.(event as any);
        }
    }, [ props.value ]);

    return <Input {...rest} value={value || ''} onChange={handleChange}/>;
};


export { FilterInput };
