/**
 * ChatSyncAfterConnect component
 *
 * @author: exode <hello@exode.ru>
 */

import React, { useEffect } from 'react';

import { DocumentEvent } from '@/types/window';
import { useApolloClient } from '@apollo/client';

import { chatRefetchDocsOnConnect } from '@/components/Subscriptions/Chat/constants';


const ChatSyncAfterConnect = () => {

    const apolloClient = useApolloClient();

    useEffect(() => {
        const onConnectOnce = async () => {
            apolloClient?.refetchQueries({
                include: chatRefetchDocsOnConnect,
            });
        };

        document.addEventListener(
            DocumentEvent.WsConnected,
            onConnectOnce,
            { once: true },
        );

        return () => {
            document.removeEventListener(
                DocumentEvent.WsConnected,
                onConnectOnce,
            );
        };
    }, []);

    return (<></>);
};


export { ChatSyncAfterConnect };
