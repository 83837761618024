/**
 * MenuItemsPart (main part)
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { observer } from '@/store/user/auth';
import { PageStore } from '@/store/core/page';

import { If, Platform } from '@/cutils';

import { SplitCol } from '@exode.ru/vkui';

import { PanelHeaderSpace } from '@/components/Panel/Header';

import { SchoolMenuPart } from './SchoolMenuPart';
import { CommonMenuPart } from './CommonMenuPart';
import { MarketplaceMenuPart } from './MarketplaceMenuPart';

import { Container } from '../DesktopNavigationMenu.styled';


interface Props {
    wrapped?: boolean;
}


const MenuItemsPart = observer((props: Props) => {
    return (
        <Container className="sticky top-header h-fit">
            <SplitCol spaced className={[
                'viewport__left-menu',
                PageStore.footer ? 'pb-[3.25rem]' : '',
            ].join(' ')}>
                <If is={!props.wrapped}>
                    <PanelHeaderSpace offset={12}/>
                </If>

                <CommonMenuPart/>

                <Platform.Market>
                    <MarketplaceMenuPart/>
                </Platform.Market>

                <Platform.School>
                    <SchoolMenuPart/>
                </Platform.School>
            </SplitCol>
        </Container>
    );
});


export { MenuItemsPart };
