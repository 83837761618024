/**
 * SchoolManageSubscription component
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { useSchoolManageListenUpdateSubscription } from '@/codegen/graphql';

import { handleSchoolUpdateSubscription } from './helper';


const SchoolManageSubscription = () => {

    const schoolId = window.exode.school?.id;

    if (!schoolId) {
        return <></>;
    }

    useSchoolManageListenUpdateSubscription({
        variables: { schoolId },
        onData: async ({ data: { data } }) => {
            if (data?.schoolListenUpdate) {
                handleSchoolUpdateSubscription(data.schoolListenUpdate);
            }
        },
    });

    return <></>;
};


export { SchoolManageSubscription };
