/**
 * CardRequisitePlaceholderItem
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { useI18n } from '@/hooks/core';

import { Link } from '@/cutils';

import { Icon28BankOutline } from '@vkontakte/icons';

import { Placeholder } from '@/components/Atoms/Placeholder';


const CardRequisitePlaceholderItem = () => {
    // TODO:Dynamic translation
    const { t } = useI18n('pages.Manage.Payout.items');

    return (
        <Placeholder iconSize={72}
                     innerPadding="0px"
                     header={t('noActiveRequisites')}
                     icon={<Icon28BankOutline className="text-accent opacity-[0.85]"/>}
                     content={(
                         <div className="m:w-full w-9/10">
                             Пройдите полную{' '}
                             <Link pushPage={{ id: '/manage/seller' }}>
                                 <span className="text-accent">
                                     верификацию продавца
                                 </span>
                             </Link>
                         </div>
                     )}/>
    );
};


export { CardRequisitePlaceholderItem };
