/**
 * CategoryCellItem
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { useI18n } from '@/hooks/core';
import { useSchoolDiscipline } from '@/hooks/apollo';

import { ContentCategoryItem } from '@/types/content';

import { Icon24AddSquareOutline } from '@vkontakte/icons';
import { Cell, IconButton, Spinner } from '@exode.ru/vkui';

import { ShowOnHover } from '@/components/Atoms/Styled';
import { EditableTextArea } from '@/components/Atoms/EditableTextArea';

import { ChangeVisibilityItem } from './ChangeVisibilityItem';


interface Props {
    category: ContentCategoryItem;
}


const CategoryCellItem = (props: Props) => {

    const { category } = props;

    const { t } = useI18n('pages.Manage.School.Disciplines');

    const {
        changeCategoryVisibility,
        categoryUpdateLoading: categoryVisibilityUpdateLoading,
    } = useSchoolDiscipline();

    const {
        categoryUpdate,
        subjectCreate,
        subjectCreateLoading,
    } = useSchoolDiscipline();

    return (
        <ShowOnHover>
            <Cell after={(
                <div className="flex items-center">
                    <ChangeVisibilityItem visible={category.visible}
                                          data-test="descipline.hide-category"
                                          loading={categoryVisibilityUpdateLoading}
                                          onChange={(visible) => changeCategoryVisibility(
                                              category.id,
                                              visible,
                                          )}/>

                    <IconButton disabled={subjectCreateLoading}
                                data-test="descipline.add-subject"
                                onClick={() => subjectCreate({
                                    icon: '',
                                    visible: true,
                                    name: t('enterName'),
                                    contentCategoryId: category.id,
                                })}>
                        {subjectCreateLoading
                            ? <Spinner/>
                            : <Icon24AddSquareOutline fill="var(--accent)"/>
                        }
                    </IconButton>
                </div>
            )}>
                <EditableTextArea value={category.name}
                                  className="w-[300px]"
                                  dataTest="discipline.category"
                                  placeholder={t('enterCategoryName')}
                                  data-test="descipline.edit-category"
                                  onSave={(name) => categoryUpdate(category.id, { name })}/>
            </Cell>
        </ShowOnHover>
    );
};


export { CategoryCellItem };
