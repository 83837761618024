/**
 * Plyr styled container
 *
 * @author: exode <hello@exode.ru>
 */

import styled from 'styled-components';

import { responsive } from '@/styles/modules/responsive';

import { PlyrWrapperProps } from './Plyr';


export const Container = styled.div<PlyrWrapperProps>`
    aspect-ratio: ${({ styles }) => styles?.orientation === 'horizontal' ? 9 / 16 : 16 / 9};

    ${responsive([ 'tablet' ])} {
        .plyr__control {
            :hover {
                background: transparent !important;
            }
        }
    }

    ${responsive([ 'gt-tablet' ])} {
        padding: 0.03em;
        overflow: hidden;
        box-shadow: ${({ styles }) => styles?.boxShadow || 'inset 0 0 0 var(--thin-border) var(--input_border)'};
        border-radius: var(--vkui--size_border_radius_paper--regular);
    }

    .plyr--video {
        width: ${({ styles }) => styles?.width || '100%'};
        height: ${({ styles }) => styles?.height || 'auto'};
        aspect-ratio: ${({ styles }) => styles?.orientation === 'horizontal' ? 9 / 16 : 16 / 9};

        box-shadow: var(--vkui--elevation3, 0 2px 24px 0 rgba(0, 0, 0, .08), 0 0 2px 0 rgba(0, 0, 0, .08));
        border-radius: ${({ styles }) => styles?.roundedOnMobile
                ? 'var(--vkui--size_border_radius_paper--regular)'
                : 'initial'};

        ${responsive([ 'gt-tablet' ])} {
            border-radius: var(--vkui--size_border_radius_paper--regular);

            .plyr__video-wrapper {
                border-radius: var(--vkui--size_border_radius_paper--regular);
            }
        }

        ${responsive([ 'mobile' ])} {
            min-height: 150px;
        }

        .plyr__video-wrapper {
            border-radius: ${({ styles }) => styles?.roundedOnMobile
                    ? 'var(--vkui--size_border_radius_paper--regular)'
                    : 'initial'};
        }
    }
`;
