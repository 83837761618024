/**
 * FileRow styled container
 *
 * @author: exode <hello@exode.ru>
 */

import styled from 'styled-components';


export const Container = styled.div`
    .vkuiSimpleCell__main {
        padding: 5px !important;
        padding-left: 8px !important;
    }

    .vkuiSimpleCell--sizeY-compact > .vkuiAvatar--sz-28,
    .vkuiSimpleCell--sizeY-compact > .vkuiAvatar--sz-32 {
        margin-right: 0;
    }

    .vkuiAvatar {
        background: none;
    }

    .vkuiAvatar--shadow:after {
        box-shadow: none;
    }
`;
