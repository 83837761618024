/**
 * Helper
 *
 * @author: exode <hello@exode.ru>
 */

import { SchoolItem } from '@/types/school';

import { PlatformStore, SchoolStore } from '@/store/platform';

import { ObjectUtil } from '@/utils';
import { moveToDomainSchool } from '@/hooks/apollo';
import { SchoolService } from '@/services/School/School';


export const handleSchoolUpdateSubscription = (
    school: SchoolItem,
) => {
    if (!ObjectUtil.isEqual(
        school.preferenceSettings?.colorVariables,
        SchoolStore.preferences?.colorVariables,
    )) {
        SchoolService.applyColorVariables(school.preferenceSettings?.colorVariables);
    }

    SchoolStore.merge({ school });

    PlatformStore.merge({ COMMON: school.platformConfig });

    if (school.baseDomain !== window.exode.school?.baseDomain) {
        /** SSR will redirect automatically */
        moveToDomainSchool({ reloadOnly: true });
    }
};

