/**
 * TournamentMiniCard component
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { educationSubjectColors, educationSubjectImages, educationSubjects, parseSubjectColor } from '@/types/subject';

import { Maybe, Scalars, TournamentEntity, TournamentState } from '@/codegen/graphql';

import { Time } from '@/utils';
import { usePlatform } from '@/hooks/core';
import { If, SvgComponent } from '@/cutils';

import { Avatar, getClassName, HorizontalCell } from '@exode.ru/vkui';

import { PulsePoint } from '@/components/Atoms/PulsePoint';
import { TournamentLink } from '@/components/Tournament/Link';

import { Container, SubjectImageShadow } from './TournamentMiniCard.styled';


export interface TournamentMiniCardProps extends Pick<TournamentEntity,
    'id' |
    'state' |
    'subject' |
    'isMember'> {
    startAt?: Maybe<Scalars['Date']>;
}


const TournamentMiniCard = (props: TournamentMiniCardProps) => {

    const { id, subject, state, isMember, startAt } = props;

    const containerClass = getClassName('vkuiHorizontalCell', usePlatform());

    return (
        <Container className={containerClass}>
            <SubjectImageShadow shadowColor={parseSubjectColor(subject, 100)}>
                <HorizontalCell size="l" disabled subtitle={(
                    state === TournamentState.Process
                        ? 'Сейчас идет'
                        : Time.parseRelative(startAt as unknown as Date, 'D MMM в HH:mm')
                )} header={(
                    <span className="Title flex gap-2 items-center text-opacity-70">
                        {educationSubjects[subject]}

                        <If is={state === TournamentState.Process}>
                            <>{' '}</>
                            <PulsePoint/>
                        </If>
                    </span>
                )}>
                    <TournamentLink id={id} state={state} isMember={!!isMember}>
                        <Avatar mode="app"
                                size={110}
                                shadow={false}
                                className={`bg-gradient-to-br ${educationSubjectColors[subject]}`}>
                            <div className="flex flex-col">
                                <SvgComponent svgProps={{ width: 65, height: 65 }}
                                              element={educationSubjectImages[subject]}/>
                            </div>
                        </Avatar>
                    </TournamentLink>
                </HorizontalCell>
            </SubjectImageShadow>
        </Container>
    );
};


export { TournamentMiniCard };
