/**
 * UpdatePasswordInput
 *
 * @author: exode <hello@exode.ru>
 */

import {
    IsNotEmpty,
    MinLength,
    registerDecorator,
    ValidationArguments,
    ValidationOptions,
    ValidatorConstraint,
    ValidatorConstraintInterface,
} from 'class-validator';

import { isMinMax, isNotEmpty } from '../constants';


export function Match(property: string, validationOptions?: ValidationOptions) {
    return (object: any, propertyName: string) => {
        registerDecorator({
            target: object.constructor,
            propertyName,
            options: validationOptions,
            constraints: [ property ],
            validator: MatchConstraint,
        });
    };
}

@ValidatorConstraint({ name: 'Match' })
export class MatchConstraint implements ValidatorConstraintInterface {

    validate(value: any, args: ValidationArguments) {
        const [ relatedPropertyName ] = args.constraints;
        const relatedValue = (args.object as any)[relatedPropertyName];
        return value === relatedValue;
    }

    defaultMessage(args: ValidationArguments) {
        const [ relatedPropertyName ] = args.constraints;
        return `${relatedPropertyName} and ${args.property} don't match`;
    }
}

export class UpdatePasswordInput {

    @MinLength(6, { message: isMinMax(6, 'Пароль', 'min') })
    @IsNotEmpty({ message: isNotEmpty('Пароль') })
    readonly password: string;

    @Match('password', { message: 'Пароли должны совпадать' })
    @MinLength(6, { message: isMinMax(6, 'Пароль', 'min') })
    @IsNotEmpty({ message: isNotEmpty('Пароль') })
    readonly confirmPassword: string;

}
