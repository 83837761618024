/**
 * CompanyRoutes
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { PageSpace } from '@/shared/types';

import { RouterItemsType } from '@/types/router';
import { lazyWithRetry } from '@/helpers/react';


const CompanyAboutPage = lazyWithRetry(() => import('@/pages/Company/About'));
const CompanySupportPage = lazyWithRetry(() => import('@/pages/Company/Support'));


const CompanyRoutes: RouterItemsType = {
    '/about': {
        view: <CompanyAboutPage/>,
        type: 'tab',
        tab: 'menu',
        spaces: [
            PageSpace.MarketplacePlatform,
        ],
    },
    '/support': {
        view: <CompanySupportPage/>,
        type: 'iframe',
        tab: 'menu',
        spaces: [
            PageSpace.BizWelcomePlatform,
            PageSpace.MarketplacePlatform,
        ],
    },
    '/support/account-deletion-request': {
        view: <CompanySupportPage/>,
        spaces: [
            PageSpace.MarketplacePlatform,
        ],
    },
};


export { CompanyRoutes };
