/**
 * HomeWorkTagPart
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { If } from '@/cutils';
import { useI18n } from '@/hooks/core';

import { TagInlineText } from '@/components/Atoms/TagInlineText';


interface Props {
    reverse?: boolean;
}


const HomeWorkTagPart = (props: Props) => {

    const { reverse } = props;

    const { t } = useI18n('components.Course.LessonCard');

    return (
        <>
            <If is={!reverse}>
                <span>{' • '}</span>
            </If>

            <TagInlineText gradient="green" className="min-w-[18px]" content={t('homework')}/>

            <If is={!!reverse}>
                <span>{' • '}</span>
            </If>
        </>
    );
};


export { HomeWorkTagPart };
