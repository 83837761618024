/**
 * AvatarChip styled container
 *
 * @author: exode <hello@exode.ru>
 */

import styled from 'styled-components';

import { AvatarChipProps } from './AvatarChip';


export const Container = styled.div<AvatarChipProps>`
  position: absolute;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  top: ${props => props.top + 'px'};
  left: ${props => props.left + 'px'};
  width: ${props => props.size + 'px'};
  height: ${props => props.size + 'px'};
  border-radius: 50%;

  use {
    fill: currentcolor;
    color: var(--accent);
  }
`;
