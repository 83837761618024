/**
 * ProfileFriendsModal
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { useStableModalParams } from '@/router/index';

import { FriendshipStatus, ListFriendsByStatusQuery } from '@/codegen/graphql';

import { Page } from '@/pages/Core';
import { useI18n } from '@/hooks/core';
import { Graphql, Modal } from '@/cutils';

import { Title } from '@exode.ru/vkui';
import { FriendsListView } from '@/pages/Profile/Profile/views/FriendsListView';
import { friendsByStatusListConfig } from '@/pages/Profile/Friends/views/FriendsContentView';


interface Props {
    id: string;
}


const ProfileFriendsModal = (props: Props) => {

    const { t } = useI18n('modals.Profile.Friends');

    const [ { userId } ] = useStableModalParams();

    return (
        <Modal.Card id={props.id}
                    header={<Title className="modal-title" level="2" weight="3">{t('friendsList')}</Title>}>
            <Modal.Body fullHeight isScrollable>
                <ListFriendsByStatusQuery children={(result) => (
                    <>
                        <Graphql.Loading result={result}>
                            <Page.Spinner/>
                        </Graphql.Loading>

                        <Graphql.Success result={result}>
                            {({ friendshipFindMany: friends }) => (
                                <FriendsListView {...friends} />
                            )}
                        </Graphql.Success>

                        <Graphql.Error result={result}/>
                    </>
                )} variables={{
                    ...friendsByStatusListConfig,
                    filter: { status: FriendshipStatus.Confirmed },
                    relUserId: +userId,
                }} skip={!userId}/>
            </Modal.Body>
        </Modal.Card>
    );
};


export { ProfileFriendsModal };
