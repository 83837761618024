/**
 * TournamentShareModal
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { useStableModalParams } from '@/router/index';

import { TournamentService } from '@/services/Tournament/Tournament';

import { Graphql, Modal } from '@/cutils';
import { TournamentFindOneQuery } from '@/codegen/graphql';

import { Title } from '@exode.ru/vkui';
import { Icon24Share } from '@vkontakte/icons';

import { CopyToClipboard } from '@/components/Atoms/CopyToClipboard';
import { SocialShareButtons } from '@/components/Atoms/SocialShareButtons';


interface Props {
    id: string;
}


const TournamentShareModal = (props: Props) => {

    const [ { tournamentId, inviteKey } ] = useStableModalParams();

    return (
        <Modal.Card id={props.id}
                    subheader="Вы делитесь ссылкой для приглашения на турнир"
                    icon={<Modal.CardIcon icon={<Icon24Share fill="var(--white)"/>}/>}
                    header={(
                        <Title className="modal-title" level="2" weight="3">
                            Поделиться турниром
                        </Title>
                    )}>
            <Modal.Body isScrollable>
                <TournamentFindOneQuery children={(result) => (
                    <>
                        <Graphql.Success result={result}>
                            {({ tournamentFindOne: { uuid, accessByInviteKey } }) => {
                                const link = TournamentService.getInviteLink(
                                    tournamentId,
                                    uuid || inviteKey,
                                    accessByInviteKey,
                                );

                                return (
                                    <div className="flex flex-col gap-6 mt-3">
                                        <CopyToClipboard link={link}/>
                                        <SocialShareButtons showVkPublish link={link}/>
                                    </div>
                                );
                            }}
                        </Graphql.Success>
                    </>
                )} variables={{ tournamentId: +tournamentId }}/>
            </Modal.Body>
        </Modal.Card>
    );
};


export { TournamentShareModal };
