/**
 * SubjectService
 *
 * @author: exode <hello@exode.ru>
 */

import { BaseService } from '@/services/Core/Base';

import { EducationSubject, SubjectEntity } from '@/codegen/graphql';


class SubjectService extends BaseService {

    /**
     *
     * @param {Partial<SubjectEntity>[]} userSubjects
     * @param {EducationSubject} subject
     * @returns {}
     */
    static onSelectUserSubjects(
        subject: EducationSubject,
        userSubjects: Pick<SubjectEntity, 'subject'>[],
    ) {
        const selected = userSubjects.map(item => item.subject);
        const isSelected = selected.includes(subject);

        const filtered = selected.filter(e => e !== subject);
        const chosen = [ ...selected, subject ];

        const mutated = isSelected ? filtered : chosen;

        return { selected, isSelected, mutated, filtered, chosen };
    }

}


export { SubjectService };
