/**
 * CustomQuoteTool
 *
 * @author: exode <hello@exode.ru>
 */

import Quote from '@editorjs/quote';


class CustomQuoteTool extends Quote {

    get settings() {
        return [];
    }

}


export { CustomQuoteTool };
