/**
 * EditorJs style overwrite
 *
 * @author: exode <hello@exode.ru>
 */

export const style = {
    table: {
        th: {
            fontSize: 13,
            borderRadius: '8px 8px 0 0',
            background: 'var(--button_muted_background)',
        },
        td: {
            fontSize: 13,
            borderRadius: 0,
            background: 'var(--background_content)',
            border: 'var(--thin-border) solid var(--input_border)',
        },
    },


};

export const classNames = {
    table: {
        table: 'editor-js-table',
    },
    image: {
        img: 'vk-rounded thin-border object-contain',
        figure: 'vk-rounded !bg-transparent',
        figcaption: 'vk-rounded',
    },
};
