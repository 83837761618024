/**
 * PermanentRender component index file
 *
 * Компонента находится в корне приложения и отрендерена постоянно
 * Аналог провайдера (SocketProvider, ConfigProvider и тд, только не прокидывает контекст)
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { Hotkeys, Platform, Preload } from '@/cutils';
import { RndVideo } from '@/components/Atoms/RndVideo';
import { DialogRoot } from '@/components/App/DialogRoot';
import { CartFloatButton } from '@/components/Payment/CartFloatButton';
import { PreferenceStatusBarColor } from '@/components/Preference/StatusBarColor';


export const PermanentRender = React.memo(() => {
    return (
        <>
            <Hotkeys/>
            <Platform.Detector/>
            <Preload/>

            <PreferenceStatusBarColor/>

            <RndVideo withHandler/>

            <CartFloatButton/>

            <DialogRoot/>
        </>
    );
});
