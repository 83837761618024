/**
 * ContentCategorySearchSelect
 *
 * @author: exode <hello@exode.ru>
 */

import React, { useEffect } from 'react';

import { useI18n } from '@/hooks/core';

import { FilterCategoryContentInput, useContentCategoryFindManyLazyQuery } from '@/codegen/graphql';

import { SearchSelect, SelectSearchOption } from '@/components/Atoms/SearchSelect';


interface Props {
    onSelect: (params: SelectSearchOption) => void;
    filter?: FilterCategoryContentInput;
    selectedContentCategoryIds?: number[];
}


const ContentCategorySearchSelect = (props: Props) => {

    const { onSelect, filter, selectedContentCategoryIds } = props;

    const { t } = useI18n('components.Content.CategorySearchSelect');

    const [ search, { data, loading, variables } ] = useContentCategoryFindManyLazyQuery({
        fetchPolicy: 'network-only',
        onError: (error) => console.error(error),
    });

    const emptyText = variables?.filter?.search
        ? t('searchDidNotGiveAnyResults')
        : t('startEnteringCategoryName');

    const options = data?.contentCategoryFindMany.items?.map(({ id, name }) => ({
        value: id,
        label: name || '',
    }));

    const searchCallback = (value: string) => search({
        variables: {
            list: {},
            filter: { ...filter, search: value },
        },
    });

    useEffect(() => {
        searchCallback('');
    }, []);

    return (
        <SearchSelect clearOnSelect
                      loading={loading}
                      onSelect={onSelect}
                      emptyText={emptyText}
                      options={options || []}
                      searchCallback={searchCallback}
                      placeholder={t('searchByCategory')}
                      selectedIds={selectedContentCategoryIds}
                      initialSearch={{ deps: [], skip: false }}
                      dataTest="content.search-by-content-category"/>
    );
};


export { ContentCategorySearchSelect };
