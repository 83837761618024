/**
 * NotificationIcon component
 *
 * @author: exode <hello@exode.ru>
 */

import React, { ReactElement } from 'react';

import { Maybe, NotificationType } from '@/codegen/graphql';

import { Avatar } from '@exode.ru/vkui';
import { If, Link, SvgComponent } from '@/cutils';
import { UserAvatar } from '@/components/Atoms/UserAvatar';

import {
    Icon28DiamondOutline,
    Icon28KeySquareOutline,
    Icon28MoneyRequestOutline,
    Icon28PaymentCardOutline,
    Icon28StorefrontOutline,
    Icon28User,
    Icon28UserAddedOutline,
    Icon28UserAddOutline,
    Icon28UserIncomingOutline,
} from '@vkontakte/icons';

import { gradient } from '@/styles/modules/gradient';


interface Props {
    actorId: number;
    notificationType: NotificationType;
    iconUrl?: Maybe<string>;
}

interface Notification {
    icon: ReactElement;
    gradient: string;
}


const NotificationIcon = (props: Props) => {

    const { notificationType, iconUrl, actorId } = props;

    const userTypeNotifications = [
        NotificationType.UserFriendRequestConfirm,
        NotificationType.UserIncomeFriendRequest,
    ];

    const isUserNotification = userTypeNotifications.includes(notificationType);

    const categories: { [key in NotificationType]: Notification } = {
        [NotificationType.CourseNewLesson]: {
            icon: <Icon28DiamondOutline className="text-white"/>,
            gradient: gradient.green500ToLime600,
        },
        [NotificationType.PaymentReleased]: {
            icon: <Icon28PaymentCardOutline className="text-white"/>,
            gradient: gradient.green500ToLime600,
        },
        [NotificationType.UserFriendRequestConfirm]: {
            icon: <Icon28UserAddedOutline className="text-white"/>,
            gradient: gradient.lime600ToGreen400,
        },
        [NotificationType.UserIncomeFriendRequest]: {
            icon: <Icon28UserIncomingOutline className="text-white"/>,
            gradient: gradient.green500ToLime600,
        },
        [NotificationType.UserSellerBecomeRequestNewStatus]: {
            icon: <Icon28StorefrontOutline className="text-white"/>,
            gradient: gradient.purple500ToBlue500,
        },
        [NotificationType.UserVkFriendInExodeNow]: {
            icon: <Icon28UserAddedOutline className="text-white"/>,
            gradient: gradient.lime600ToGreen400,
        },
        [NotificationType.WalletNewOperation]: {
            icon: <Icon28MoneyRequestOutline className="text-white"/>,
            gradient: gradient.purple500ToBlue500,
        },
        [NotificationType.TournamentInvitation]: {
            icon: <Icon28UserAddOutline className="text-white"/>,
            gradient: gradient.purple500ToBlue500,
        },
        [NotificationType.SessionNewLogin]: {
            icon: <Icon28KeySquareOutline className="text-white"/>,
            gradient: gradient.lime600ToGreen400,
        },
    };

    const icon = categories[notificationType].icon;
    const gradientBg = `bg-gradient-to-r ${categories[notificationType].gradient}`;

    const badge = (
        <Avatar size={24} className={gradientBg} shadow>
            <SvgComponent element={icon} svgProps={{ fill: 'white', width: 16, height: 16 }}/>
        </Avatar>
    );

    const placeholder = (
        <Avatar shadow size={48} badge={badge}>
            <Icon28User fill="var(--white)" width={32} height={32}/>
        </Avatar>
    );

    return (
        <>
            <If is={isUserNotification}>
                <Link pushPage={{ id: '/@:userId([0-9_A-Za-z]+)', params: { userId: `${actorId}` } }}>
                    <div className="mt-1.5">
                        <UserAvatar name=""
                                    size={48}
                                    badge={badge}
                                    userId={actorId}
                                    hasLink={false}
                                    src={iconUrl || ''}
                                    children={placeholder}
                                    showUserOnlineBadge={false}/>
                    </div>
                </Link>
            </If>

            <If is={!isUserNotification}>
                <Avatar shadow size={48} className={gradientBg}>
                    {icon}
                </Avatar>
            </If>
        </>
    );
};


export { NotificationIcon };
