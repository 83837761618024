/**
 * DateTimeTextInterval
 *
 * @author: exode <hello@exode.ru>
 */

import moment from 'moment';

import React from 'react';


interface Props {
    from?: Date;
    to?: Date;
    format?: string;
    bothIsNullText?: string;
}


const DateTimeTextInterval = (props: Props) => {

    const { from, to, bothIsNullText, format = 'DD.MM.YY HH:mm' } = props;

    if (!from && !to && bothIsNullText) {
        return <>{bothIsNullText}</>;
    }

    return (
        <span>
            {from ? moment(from).format(format) : '-∞'}
            {' - '}
            {to ? moment(to).format(format) : '+∞'}
        </span>
    );
};


export { DateTimeTextInterval };
