/**
 * Meta util
 *
 * @author: exode <hello@exode.ru>
 */

import { useEffect } from 'react';

import { IS_SCHOOL } from '@/root/src/env';

import { SchoolStore } from '@/store/platform';
import { observer, PreferenceStore } from '@/store/preference/preference';

import { PageStore } from '@/store/core/page';

import { Head } from '@/cutils';


const Meta = observer(() => {

    const props = {
        ...PageStore.tags,
        color: PreferenceStore.tabThemeColor,
        icon: IS_SCHOOL
            ? (SchoolStore.school?.iconUrl || '')
            : PageStore.tags.icon,
    };

    useEffect(() => {
        document.getElementById('favicon')?.setAttribute('href', props.icon);
    }, [ props.icon ]);

    return Head.Meta(props);
});


export { Meta };
