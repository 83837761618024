/**
 * StoryModal
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import React, { useLayoutEffect, useState } from 'react';

import Stories from 'react-insta-stories';

import { SchoolStore } from '@/store/platform';
import { UserAuthStore } from '@/store/user/auth';
import { EducationTabStore } from '@/tabs/Education/store';
import { ConfigStore, observer } from '@/store/core/config';

import { useLocation } from '@/router/index';

import { IS_NATIVE, IS_VK } from '@/root/src/env';

import { Time } from '@/utils';
import { usePlatform } from '@/hooks/core';
import { If, Modal, Responsive } from '@/cutils';

import { Router } from '@/services/Utils/Router';

import { Icon24CancelOutline, Icon28ChevronRightOutline } from '@vkontakte/icons';

import { StoriesStatus, useStoriesFindManyQuery, useStoriesUpsertViewMutation } from '@/codegen/graphql';

import { IconButton } from '@/components/Atoms/IconButton';
import { useModalContext } from '@/components/App/ModalProvider';

import styles from './StoryModal.module.scss';
import { Container } from './StoryModal.styled';


interface Props {
    id: string;
}


const StoryModal = observer((props: Props) => {

    const platform = usePlatform();

    const { modalState } = useModalContext();
    const { route: { pageId, params: { storyIndex } } } = useLocation();

    const [ storiesIndex, setStoriesIndex ] = useState(+storyIndex);

    const [ _viewStory ] = useStoriesUpsertViewMutation({
        onError: error => console.error(error),
    });

    const { data, loading } = useStoriesFindManyQuery({
        onError: error => console.error(error),
        variables: {
            list: { ...EducationTabStore.state.list },
            sort: { ...EducationTabStore.state.sort },
            filter: pageId === '/@:userId([0-9_A-Za-z]+)'
                ? {
                    sellerIds: [ modalState.sellerId ].filter(e => e),
                    statuses: [ StoriesStatus.Published ],
                }
                : { statuses: [ StoriesStatus.Published ] },
        },
    });

    const stories = data?.storiesFindMany.items?.map((stories) => {
        const {
            id,
            url,
            duration,
            createdAt,
            seller,
            user,
            title,
            hasView,
        } = stories;

        return {
            id,
            url,
            hasView,
            duration: duration * 1000,
            header: {
                heading: (user.profile.fullName) + ' — ' + title,
                subheading: Time.parseRelative(createdAt),
                profileImage: (
                    seller?.organization?.logo
                    || user.profile.avatar.medium
                    || SchoolStore.school?.iconUrl
                    || ''
                ),
            },
            storyContent: {
                height: '100%',
                objectFit: 'contain',
            },
        };
    }) || [];

    const viewStory = async () => {
        const currentStory = data?.storiesFindMany?.items?.[storiesIndex];

        if (!currentStory?.hasView) {
            await _viewStory({ variables: { storiesId: currentStory?.id ?? 0 } });
        }
    };

    const handleSwitch = async (direction: 'next' | 'prev') => {
        if (direction === 'next') {
            storiesIndex === ((stories?.length || 0) - 1)
                ? Router.replacePopout()
                : (() => {
                    Router.updateParams({ storyIndex: `${+storyIndex + 1}` });
                    setStoriesIndex(prev => prev + 1);
                })();

        } else if (direction === 'prev' && storiesIndex !== 0) {
            Router.updateParams({ storyIndex: `${+storyIndex - 1}` });
            setStoriesIndex(prev => prev - 1);
        }
    };

    useLayoutEffect(() => {
        if (data && UserAuthStore.isLoggedIn) {
            viewStory().then();
        }
    }, [ storiesIndex, data, UserAuthStore.isLoggedIn ]);

    return (
        <Modal.Card className={styles.storyModal} id={props.id}>
            <Modal.Body className="flex items-center d:max-h-[1000px]">
                <If is={!!stories && !_.isEmpty(stories) && !loading}>
                    <If is={!ConfigStore.isMobileOnly}>
                        <If is={storiesIndex > 0}>
                            <IconButton className="!absolute !m:hidden opacity-50 hover:opacity-70"
                                        onClick={() => handleSwitch('prev')}
                                        style={{ left: '-65px', transform: 'rotate(180deg)' }}>
                                <Icon28ChevronRightOutline fill="black" style={{ marginLeft: 2 }}/>
                            </IconButton>
                        </If>

                        <If is={storiesIndex < stories.length - 1}>
                            <IconButton style={{ right: '-65px' }}
                                        className="!absolute !m:hidden opacity-50 hover:opacity-70"
                                        onClick={() => handleSwitch('next')}>
                                <Icon28ChevronRightOutline fill="black" style={{ marginLeft: 2 }}/>
                            </IconButton>
                        </If>
                    </If>

                    <Container>
                        <Stories keyboardNavigation
                                 stories={stories || []}
                                 currentIndex={storiesIndex}
                                 onAllStoriesEnd={() => Router.replacePopout()}
                                 width={ConfigStore.isMobileOnly ? '100vw' : 440}
                                 onStoryStart={(i: number) => {
                                     if (i !== storiesIndex) {
                                         setStoriesIndex(i);

                                         Router.updateParams({ storyIndex: `${i}` });
                                     }
                                 }}
                                 height={(
                                     ConfigStore.isMobileOnly
                                         ? `calc(100vh - env(safe-area-inset-bottom, 0px) - ${!IS_NATIVE && platform === 'ios' ? '25px' : '0px'})`
                                         : 'calc(100vh - 70px)'
                                 )}
                                 storyContainerStyles={{
                                     background: 'black',
                                     overflow: 'hidden',
                                     borderRadius: !ConfigStore.isMobileOnly || IS_NATIVE ? 12 : 0,
                                     maxHeight: ConfigStore.isMobileOnly ? 'calc(var(--app-height))' : '1000px',
                                 }}/>
                    </Container>

                    <Responsive.Tablet>
                        <div onClick={() => Router.replaceModal()} className={[
                            '!absolute !z-[1002] bg-gray-100 bg-opacity-50 shadow rounded-full p-2 m-2 cursor-pointer',
                            IS_VK ? '!top-[45px] !right-[10px]' : '!top-[12px] !right-[10px]',
                        ].join(' ')}>
                            <Icon24CancelOutline fill="white" width={28} height={28}/>
                        </div>
                    </Responsive.Tablet>
                </If>
            </Modal.Body>
        </Modal.Card>
    );
});


export { StoryModal };
