/**
 * AvatarVerifiedPart component
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { Responsive } from '@/cutils';

import { Icon16DoneCircle } from '@vkontakte/icons';

import { AvatarChip } from '@/components/Atoms/AvatarChip';


interface Props {
    isOnline: boolean;
}


const AvatarVerifiedPart = (props: Props) => {

    const { isOnline } = props;

    const fill = isOnline ? 'var(--dynamic_green)' : '';

    return (
        <>
            <Responsive.Mobile>
                <AvatarChip size={26} top={-14} left={-12} className="bg-content m:hidden">
                    <Icon16DoneCircle width={20} height={20} fill={fill}/>
                </AvatarChip>
            </Responsive.Mobile>

            <Responsive.Desktop>
                <AvatarChip size={26} top={-14} left={-12} className="bg-content m:hidden">
                    <Icon16DoneCircle width={20} height={20} fill={fill}/>
                </AvatarChip>
            </Responsive.Desktop>
        </>
    );
};


export { AvatarVerifiedPart };
