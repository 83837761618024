/**
 * UpdateCourseInput
 *
 * @author: exode <hello@exode.ru>
 */

import { IsString, MinLength } from 'class-validator';

import { isMinMax } from '@/libs/class-validator/constants';


export class UpdateCourseInput {

    @IsString()
    @MinLength(5, { message: isMinMax(5, 'Название', 'min', 'должно') })
    readonly name: string;

    @IsString()
    @MinLength(10, { message: isMinMax(10, 'Описание', 'min', 'должно') })
    readonly description: string;

}
