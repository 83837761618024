/**
 * Seller store
 *
 * @author: exode <hello@exode.ru>
 */

import { makeAutoObservable } from 'mobx';
import { observer } from 'mobx-react';

import { Storage } from '@/api/storage';

import { Permission, SellerBecomeRequestStatus } from '@/codegen/graphql';


class Seller {

    constructor() {
        makeAutoObservable(this);
    }

    drawer = {
        /** Текущая вкладка в панели seller */
        section: '',
        /** Состояние панели seller */
        open: false,
    };

    /** Текущий статус заявки */
    requestStatus: SellerBecomeRequestStatus | undefined = Storage.get('seller:become-partner-status') || undefined;

    /** Текущие права менеджера */
    managerPermissions?: Permission[];

    /** Получение прав менеджера */
    get permissions() {
        return this.managerPermissions || [];
    }

    /** Открытие drawer с переключением секции */
    openSection(section: string) {
        this.toggleDrawer(true);
        this.toggleSection(section);
    }

    /** Переключение открытие drawer */
    toggleDrawer(isOpen: boolean) {
        this.drawer.open = isOpen;
    }

    /** Переключение секции drawer */
    toggleSection(section: string) {
        this.drawer.section = section;
    }

    /**
     * Merge and persist
     * @param seller
     */
    merge(seller: Partial<Seller>) {
        Object.assign(this, seller);

        Storage.persistStore(SellerStore, 'store:seller');
    }

}

const SellerStore = new Seller();

Storage.recoverStore(SellerStore, 'store:seller', [ 'drawer' ]);


export { observer, SellerStore };
