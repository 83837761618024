/**
 * DataSecureBanner
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { Link } from '@/cutils';
import { useI18n } from '@/hooks/core';

import { InnerHtml } from '@/components/Atoms/InnerHtml';
import { GradientBanner } from '@/components/Atoms/GradientBanner';

import { Button } from '@exode.ru/vkui';


const DataSecureBanner = () => {

    const { t } = useI18n('components.Promotion.ContextBanners');

    return (
        <GradientBanner imageTheme="light"
                        gradient="bg-content"
                        header={t('contentSecurity')}
                        subheader={<InnerHtml content={t('didYouKnowWhyAreWeRestrictAccess?')}/>}
                        actions={(
                            <Link blank toOuter="https://vk.cc/ci4MJe">
                                <Button mode="primary" size="m">{t('know')}</Button>
                            </Link>
                        )}/>
    );
};


export { DataSecureBanner };
