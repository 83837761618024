/**
 * StatusSubViewWrapper styled container
 *
 * @author: exode <hello@exode.ru>
 */

import styled from 'styled-components';


const StatusSubViewWrapper = styled.div`
    .Banner__in {
        padding: 0;
    }
`;


export { StatusSubViewWrapper };
