/**
 * PhotoEditorModalStore
 *
 * @author: exode <hello@exode.ru>
 */

import { observer } from 'mobx-react';

import { makeObservable, observable } from 'mobx';

import { CropperProps } from 'react-mobile-cropper';

import { ModalStore } from '@/modals/Core/store';


export interface PhotoEditorProps {
    cropper: CropperProps;
    onSubmit: ((value: File) => void) | null;
    onCancel: (() => void) | null;
}


const defaultPhotoEditorProps: PhotoEditorProps = {
    cropper: {},
    onSubmit: null,
    onCancel: null,
};


class PhotoEditor extends ModalStore {

    constructor() {
        super();
        makeObservable(this);
    }

    @observable
    photoEditorProps: PhotoEditorProps = defaultPhotoEditorProps;

    openPhotoEdit(props: PhotoEditorProps) {
        this.photoEditorProps = props;
    }

    resetPhotoEditor() {
        this.photoEditorProps = defaultPhotoEditorProps;
    }

}

const PhotoEditorModalStore = new PhotoEditor();


export { observer, PhotoEditorModalStore };
