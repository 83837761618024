/**
 * ProfileNotificationActionButton component
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import {
    FriendshipStatus,
    NotificationAction,
    NotificationActionMethod,
    useGetTournamentStateLazyQuery,
} from '@/codegen/graphql';

import { If } from '@/cutils';
import { Button } from '@exode.ru/vkui';
import { useSetFriendship } from '@/hooks/apollo';
import { Router } from '@/services/Utils/Router';
import { TournamentService } from '@/services/Tournament/Tournament';

import { Container } from './ProfileNotificationActionButton.styled';


interface Props extends NotificationAction {
    index: number;
}


const ProfileNotificationActionButton = (props: Props) => {

    const { title, arguments: methodArguments, method, index } = props;

    const mode = index === 1 ? 'secondary' : 'primary';

    /** Friendship */
    const { setFriendship, setFriendshipLoading } = useSetFriendship();

    /** Tournament */
    const [ getTournamentState, { loading: tournamentStateLoading } ] = useGetTournamentStateLazyQuery({
        variables: { tournamentId: +methodArguments.tournamentId },
        onError: (error) => console.error(error),
    });

    const handleTournamentOpen = async () => {
        const { data } = await getTournamentState();

        const { state, isMember } = data?.tournamentFindOne || {};

        TournamentService.openAppropriatePageByState({
            id: methodArguments.tournamentId,
            state: state!,
            isMember: !!isMember,
            inviteKey: methodArguments.inviteKey,
            userFinished: false,
        }, 'replacePage');
    };

    /** Payment */
    const pushToPayment = () => {
        Router.pushPage('/payment/:invoiceUuid([0-9_A-Za-z_-]+)', {
            invoiceUuid: methodArguments.invoiceUuid,
        });
    };

    return (
        <Container>
            <If is={[ method ].includes(NotificationActionMethod.TournamentOpen)}>
                <Button mode={mode}
                        key={index + title}
                        onClick={handleTournamentOpen}
                        loading={tournamentStateLoading}
                        disabled={tournamentStateLoading}>
                    {title}
                </Button>
            </If>

            <If is={[ method ].includes(NotificationActionMethod.FriendshipConfirm)}>
                <Button mode={mode}
                        loading={setFriendshipLoading}
                        key={index + title}
                        disabled={setFriendshipLoading}
                        onClick={() => setFriendship(+methodArguments.fromId, FriendshipStatus.Confirmed)}>
                    {title}
                </Button>
            </If>

            <If is={[ method ].includes(NotificationActionMethod.PaymentReleasedOpen)}>
                <Button mode={mode} onClick={pushToPayment} key={index + title}>
                    {title}
                </Button>
            </If>
        </Container>
    );
};


export { ProfileNotificationActionButton };
