/**
 * FriendsContextView
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { Page } from '@/pages/Core';

import { InvitationBanner } from '@/components/Promotion/ContextBanners';


export const possibleFriendsListConfig = { list: { skip: 0, take: 20 } };

const FriendsContextView = () => {
    return (
        <Page.ContextWrapper>
            <InvitationBanner/>
        </Page.ContextWrapper>
    );
};


export { FriendsContextView };
