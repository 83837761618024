/**
 * Internationalization
 *
 * @author: exode <hello@exode.ru>
 */

import i18n from 'i18next';

import { initReactI18next } from 'react-i18next';
import { PluralRules } from '@formatjs/intl-pluralrules';

import { ConfigStore } from '@/store/core/config';

import { translateResources } from '@/libs/i18n/resources';
import { getFallbackLng, getLanguage } from '@/hooks/core/useI18n';


i18n.use(initReactI18next);

/**
 * Plural rules
 */
const pluralRules = {
    ru: (count: number) => new PluralRules('ru').select(count),
    uz: (count: number) => new PluralRules('uz').select(count),
} as const;

/**
 * Plural formatting
 * @param {string} value
 * @param {number} count
 */
const formatPlural = (value: string, count: number) => {
    const key = pluralRules[i18n.language as keyof typeof pluralRules](count);

    return i18n.t(`${value}_${key}`, { count });
};

/**
 * Initialize i18n
 */

ConfigStore.merge({ language: getLanguage() });

i18n.init({
    fallbackLng: getFallbackLng(),
    lng: getLanguage().toLowerCase(),
    resources: translateResources,
    interpolation: {
        escapeValue: false,
        format: (value, format, __, options) => {
            return format === 'plural'
                ? formatPlural(value, options?.count)
                : value;
        },
    },
}).catch(e => console.log(e));


export { i18n };
