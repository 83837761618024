/**
 * CreateUserFormView
 *
 * @author: exode <hello@exode.ru>
 */

import React, { useState } from 'react';

import { Formik } from 'formik';

import { SchoolManageCreateUserSchema } from '@/libs/class-validator';

import { useSchoolUser } from '@/hooks/apollo';
import { Router } from '@/services/Utils/Router';
import { useFormikDraft, useI18n, usePrompt } from '@/hooks/core';

import { AutoSaveForm, Notify } from '@/cutils';
import { Button, FormItem, Header, SegmentedControl, Separator } from '@exode.ru/vkui';

import { StickyButton } from '@/components/Atoms/StickyButton';
import { RecoverDraft } from '@/components/Atoms/RecoverDraft';

import { UserInfoItem } from '../items/UserInfoItem';
import { UserAccessItem } from '../items/UserAccessItem';
import { ExistUserListItem } from '../items/ExistUserListItem';


const CreateUserFormView = () => {

    const { t } = useI18n('pages.Manage.School.Users.views');

    const {
        initialValuesForCreate,
        schoolUserManageCreate,
        schoolUserManageCreateLoading,
    } = useSchoolUser();

    const [ showUserInfo, setShowUserInfo ] = useState<Record<number, boolean>>({ 0: true });
    const [ showUserAccess, setShowUserAccess ] = useState<Record<number, boolean>>({ 0: true });

    const {
        showDraft,
        handleRestore,
        handleSaveDraft,
        handleDeleteDraft,
    } = useFormikDraft({
        storageKey: 'draft:school-user-create',
    });

    const { openPrompt: openExistUsers } = usePrompt({
        onConfirm: () => {},
        title: t('existUsers'),
        subtitle: t('existUsersSubtitle'),
        buttons: {
            cancel: { hidden: true },
            confirm: {
                props: {
                    mode: 'secondary',
                },
            },
        },
    });

    const prepareDtoAccounts = (users: (typeof initialValuesForCreate['users'])) => (
        users.accounts.map((account) => (
            { login: account[account.type] }
        ))
    );

    const onSubmit = (values: typeof initialValuesForCreate) => {
        return schoolUserManageCreate(
            { accounts: prepareDtoAccounts(values.users) },
            values.enrollments,

            async ({ exist, created }) => {
                handleDeleteDraft();

                await Router.replaceModalWithTimeout();

                Notify.toast.success(
                    t('usersCreated', { count: created?.length }),
                );

                if (exist.length) {
                    openExistUsers({
                        children: (
                            <ExistUserListItem existUsers={exist}/>
                        ),
                    });
                }
            },
        );
    };

    return (
        <Formik validateOnBlur
                validateOnMount
                validateOnChange
                onSubmit={onSubmit}
                initialValues={initialValuesForCreate}
                validationSchema={SchoolManageCreateUserSchema}>
            {({
                  values,
                  errors,
                  touched,
                  isValid,
                  setValues,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  setFieldValue,
              }) => (
                <AutoSaveForm className="flex flex-col justify-between h-full"
                              handleSaveDraft={(values) => handleSaveDraft(values)}>
                    <div>
                        <RecoverDraft className="mb-1 pt-2"
                                      showDraft={showDraft}
                                      setValues={setValues}
                                      handleRestore={handleRestore}
                                      handleDeleteDraft={handleDeleteDraft}/>

                        <FormItem className="p-0 next-release">
                            <SegmentedControl size="l"
                                              name="type"
                                              value={'single'}
                                              className="thin-border pointer-events-none"
                                              onChange={(value) => setFieldValue('type', value)}
                                              options={[
                                                  {
                                                      value: 'single',
                                                      label: t('single'),
                                                  },
                                                  {
                                                      value: 'multiple',
                                                      label: t('multiple'),
                                                  },
                                              ]}/>
                        </FormItem>

                        <UserInfoItem errors={errors}
                                      touched={touched}
                                      values={values.users}
                                      showUserInfo={showUserInfo}
                                      setFieldValue={setFieldValue}
                                      setShowUserInfo={setShowUserInfo}
                                      formikHandlers={{ handleBlur, handleChange }}/>

                        <Separator wide className="mt-5 mb-2"/>

                        <Header className="p-0">
                            {t('accessToCourses')}
                        </Header>

                        <UserAccessItem setFieldValue={setFieldValue}
                                        showUserAccess={showUserAccess}
                                        values={values.enrollments.accesses}
                                        setShowUserAccess={setShowUserAccess}/>
                    </div>

                    <StickyButton className="px-0 pb-3">
                        <Button size="l"
                                stretched
                                type="submit"
                                onClick={() => handleSubmit()}
                                loading={schoolUserManageCreateLoading}
                                data-test="user-create.send-invitations"
                                disabled={schoolUserManageCreateLoading || !isValid}>
                            {t('sendInvitations')}
                        </Button>
                    </StickyButton>
                </AutoSaveForm>
            )}
        </Formik>
    );
};


export { CreateUserFormView };
