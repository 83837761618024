/**
 * TournamentSubjectSelectModalStore
 *
 * @author: exode <hello@exode.ru>
 */

import { makeObservable } from 'mobx';
import { observer } from 'mobx-react';

import { ModalStore } from '@/modals/Core/store';


class TournamentSubjectSelect extends ModalStore {

    constructor() {
        super();
        makeObservable(this);
    }

}

const TournamentSubjectSelectStore = new TournamentSubjectSelect();


export { observer, TournamentSubjectSelectStore };
