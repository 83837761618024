/**
 * LandingRoutes
 *
 * @author: exode <hello@exode.ru>
 */

import { RouterItemsType } from '@/types/router';


const LandingRoutes: RouterItemsType = {};


export { LandingRoutes };
