/**
 * CourseCardSkeleton styled container
 *
 * @author: exode <hello@exode.ru>
 */

import styled from 'styled-components';

import { responsive } from '@/styles/modules/responsive';


export const Container = styled.div`
    ${responsive([ 'mobile', 'lt-tablet' ])} {
        margin-left: var(--vkui--size_field_horizontal_padding--regular);
        margin-right: var(--vkui--size_field_horizontal_padding--regular);
    }
`;
