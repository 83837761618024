/**
 * FileUpload styled container
 *
 * @author: exode <hello@exode.ru>
 */

import styled from 'styled-components';

import { FileUploadProps } from './FileUpload';


interface Props extends Pick<FileUploadProps,
    'height' |
    'width' |
    'previewImage' |
    'previewDoc' |
    'previewVideo' |
    'className'> {
    hideInputLabel: boolean;
}


export const Container = styled.div<Props>`
    &.no-children {
        position: relative;
        width: ${({ width }) => width || 'auto'};
        max-height: 500px;
        min-height: ${({ previewVideo, height }) => !previewVideo && height || '34px'};

        border: ${({ previewVideo }) => !previewVideo && (
                'var(--thin-border) solid var(--field_border, var(--vkui--color_field_border_alpha))'
        )};

        border-radius: var(--vkui--size_border_radius_paper--regular);
        background-color: var(--field_background, var(--vkui--color_field_background));

        .preview.Avatar {
            border-radius: calc(var(--vkui--size_border_radius_paper--regular) - 1px) !important;
        }
    }

    &:hover {
        border-color: var(--icon_tertiary_alpha, var(--vkui--color_field_border_alpha--hover));
    }

    &:active {
        border-color: var(--accent, var(--vkui--color_stroke_accent));
    }

    input[type="file"] {
        display: none;
    }

    .file-upload {
        width: 100%;
        height: 100%;
        min-height: ${({ height }) => height || 'auto'};
        display: ${({ hideInputLabel }) => hideInputLabel ? 'none' : 'flex'};
        justify-content: center;
        align-items: center;
        cursor: pointer;

        .vkuiIcon {
            display: ${({ previewImage, previewDoc }) => (previewImage?.src || previewDoc?.src) ? 'none' : 'flex'};
        }

        &:hover {
            .vkuiIcon {
                display: ${({ previewDoc }) => previewDoc?.src ? 'none' : 'flex'};
            }
        }
    }

    & .preview {
        width: 100% !important;
        height: ${({ previewVideo, height }) => !previewVideo && height} !important;
        border-radius: var(--vkui--size_border_radius_paper--regular) !important;

        & .vkuiAvatar__img {
            object-fit: ${({ previewImage }) => previewImage?.objectFit || 'cover'};
        }
    }

    video {
        border-radius: var(--vkui--size_border_radius_paper--regular);
    }

    .vkuiChip {
        max-width: calc(100% - 10px) !important;

        &--sizeY-compact {
            height: 20px
        }

        &__content {
            font-size: 10px;
            display: block;
        }
    }
`;
