/**
 * MenuTabHeaderPart
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { APP_DOMAIN } from '@/root/src/env';

import { Page } from '@/pages/Core';
import { Platform } from '@/cutils';
import { useI18n } from '@/hooks/core';

import { PanelHeader } from '@exode.ru/vkui';

import { PreferenceThemeToggle } from '@/components/Preference/Theme';


const MenuTabHeaderPart = () => {

    const after = <PreferenceThemeToggle/>;

    const { t } = useI18n('components.Panel.Header.Tab.parts');

    return (
        <PanelHeader after={after} separator={false} className="tab-title-gradient">
            <Page.HeaderContentWrapper>
                <Platform.Market>
                    <>{APP_DOMAIN}</>
                </Platform.Market>

                <Platform.School>
                    {t('menu')}
                </Platform.School>
            </Page.HeaderContentWrapper>
        </PanelHeader>
    );
};


export { MenuTabHeaderPart };
