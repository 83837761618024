/**
 * CourseService
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import moment from 'moment';

import { Maybe } from 'graphql/jsutils/Maybe';

import { CourseLessonItems } from '@/types/course';
import { ProductAccessProgressItem } from '@/types/product';

import { CourseProgressLessonStatus, CourseProgressType } from '@/codegen/graphql';

import { BaseService } from '@/services/Core/Base';


class CourseService extends BaseService {

    /**
     * Регистрация сервиса
     */
    static register() {
        window.addEventListener('beforeunload', async () => {});
    }

    /**
     * Инициализация сервиса
     */
    static init() {

    }

    /**
     * Преобразование длительности из секунд в часы + минуты или минуты + секунды
     * @param {number} seconds
     * @return {string}
     */
    static formatDuration(seconds: number) {
        return moment.utc(seconds * 1000).format(seconds < 3200 ? 'mm:ss' : 'HH:mm:ss');
    }

    /**
     * Получение продолжительности курса
     * @param {Date} from
     * @param {Date} to
     * @return {string}
     */
    static getDuration(
        from: Date,
        to: Date,
    ) {
        return moment(to).diff(moment(moment() > moment(from) ? undefined : from), 'days');
    }

    /**
     * Проверка возможности начать следующий урок (если он FeatureDisabled)
     * @param {{progressType: }} course
     * @param {{withPractice: boolean, canStartNextLesson: boolean} | undefined | null} previous
     * @param { | undefined | null} progressStatus
     * @param {{withDemo?: boolean}} options
     */
    static canStartNextFeatureDisabled(
        course: {
            progressType: CourseProgressType;
        },
        previous: {
            withPractice: boolean;
            canStartNextLesson: boolean;
        } | undefined | null,
        progressStatus: CourseProgressLessonStatus | undefined | null,
        options: { withDemo?: boolean } = {},
    ) {
        return previous?.canStartNextLesson
            && !this.nextLessonStartManually(course, previous)
            && [
                CourseProgressLessonStatus.NotInitialized,
                CourseProgressLessonStatus.FeatureDisabled,
            ].includes(progressStatus!)
            || options?.withDemo && [
                CourseProgressLessonStatus.AvailableAsDemo,
            ].includes(progressStatus!);
    }

    /**
     * Start the next lesson only manually
     * @param {{progressType: }} course
     * @param {Maybe<{withPractice: boolean}>} currentLesson
     */
    static nextLessonStartManually(
        course: {
            progressType: CourseProgressType;
        },
        currentLesson: Maybe<{
            withPractice: boolean;
        }>,
    ) {
        return course.progressType === CourseProgressType.AfterCheck
            && !!currentLesson?.withPractice;
    }

    /**
     * Вычисление order относительно списка урока
     * @param {CourseLessonItems} lessons
     * @param {number | null} currentLessonId
     */
    static getLessonPublishedOrder(
        lessons: CourseLessonItems,
        currentLessonId?: number | null,
    ) {
        const index = _.findIndex(lessons, { id: currentLessonId || 0 });

        return _.isFinite(index) ? index + 1 : 0;
    }

    /**
     * Кол-во модулей
     * @param {Array<{parent?: {id: number} | null}>} lessons
     * @returns {{totalCount: number}}
     */
    static getModulesCount(
        lessons: Array<{
            parent?: { id: number } | null;
        }>,
    ) {
        return {
            totalCount: lessons.filter((e) => !e.parent).length,
        };
    }

    /**
     * Кол-во уроков (всех и завершенных)
     * @param {CourseLessonItems} lessons
     */
    static getLessonsCount(
        lessons: Array<{
            withContent: boolean;
            myProgressStatus?: CourseLessonItems[number]['myProgressStatus']
        }>,
    ) {
        const completedLessons = _.filter(lessons, {
            myProgressStatus: CourseProgressLessonStatus.Completed,
        });

        return {
            completedCount: completedLessons.length,
            totalCount: lessons.filter((e => e.withContent))?.length,
        };
    }

    /**
     * Урок завершен
     * @param {{myProgressStatus?:  | null}} lesson
     */
    static lessonIsCompleted(
        lesson?: {
            myProgressStatus?: CourseProgressLessonStatus | null
        } | null,
    ) {
        return lesson?.myProgressStatus === CourseProgressLessonStatus.Completed;
    }

    /**
     * Текущий урок по прогрессу
     * @param {ProductAccessProgressItem['participant']} participant
     * @param {ProductAccessProgressItem['product']} product
     */
    static getCurrentLessonByProgress(
        participant: ProductAccessProgressItem['participant'],
        product: ProductAccessProgressItem['product'],
    ) {
        return participant?.courseProgresses
            ?.filter((e) => _.map(product.course?.lessons, 'id').includes(e?.lesson?.id))
            ?.at(-1)?.lesson;
    }

}


export { CourseService };
