/**
 * FormikValuesSubscriber
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import { FormikValues, useFormikContext } from 'formik';

import React, { useEffect, useRef, useState } from 'react';


interface SubscriberProps {
    values: FormikValues;
    nestedFields?: string[];
}

interface SubscriberState {
    message: string;
    isNested: boolean;
    valuesChanged: boolean | undefined;
    nestedFields: Array<{ [key: string]: boolean }> | [];
}

interface ObserverProps {
    onChange: (values: any) => void;
}


const FormikValuesSubscriber = ({ values, nestedFields }: SubscriberProps) => {

    const [ state, setState ] = useState<SubscriberState>({
        valuesChanged: false,
        isNested: false,
        message: '',
        nestedFields: [],
    });

    const prevValues = useRef<FormikValues>(values);

    const checkIfValuesHaveChanged = () => {
        const isEqual = _.isEqual(prevValues, values);

        if (nestedFields && !_.isEmpty(nestedFields)) {
            if (nestedFields.some(field => !values.hasOwnProperty(field))) {
                return setState(({
                    valuesChanged: undefined,
                    isNested: true,
                    message: 'Some values do not exist in formik values object',
                    nestedFields: [],
                }));
            }

            nestedFields.map(field => {
                const isEqualNested = _.isEqual(values[field], prevValues.current[field]);

                if (isEqualNested && isEqual) {
                    return setState(prev => ({
                        valuesChanged: false,
                        isNested: true,
                        message: 'Values did not change',
                        nestedFields: [ ...prev.nestedFields, { [field]: false } ],
                    }));
                }

                if (isEqualNested && !isEqual) {
                    prevValues.current = values;

                    return setState(prev => ({
                        valuesChanged: true,
                        isNested: true,
                        message: 'Values did change in not nested field',
                        nestedFields: [ ...prev.nestedFields, { [field]: false } ],
                    }));
                }

                if (!isEqualNested || !isEqual) {
                    prevValues.current = values;

                    return setState(prev => ({
                        valuesChanged: true,
                        isNested: true,
                        message: 'Values did change in nested field',
                        nestedFields: [ ...prev.nestedFields, { [field]: true } ],
                    }));
                }
            });
        }

        if (isEqual && !nestedFields || _.isEmpty(nestedFields)) {
            setState({
                valuesChanged: false,
                isNested: false,
                message: 'Values did not change',
                nestedFields: [],
            });
        }

        if (!isEqual && !nestedFields || _.isEmpty(nestedFields)) {
            prevValues.current = values;

            return setState({
                valuesChanged: true,
                isNested: false,
                message: 'Values did change',
                nestedFields: [],
            });
        }
    };

    useEffect(() => {
        values && checkIfValuesHaveChanged();
    }, [ values ]);

    return state;
};

const FormikValuesObserver = (props: ObserverProps) => {

    const { values, initialValues } = useFormikContext();

    useEffect(() => {
        if (!_.isEqual(values, initialValues) && !_.isEmpty(values)) {
            props.onChange(values);
        }
    }, [ values ]);

    return <></>;
};


const FormikHelper = {
    ValuesSubscriber: FormikValuesSubscriber,
    ValuesObserver: FormikValuesObserver,
};


export { FormikHelper };
