/**
 * TaskRoutes
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { PageSpace } from '@/shared/types';

import { lazyWithRetry } from '@/helpers/react';
import { RouterItemsType } from '@/types/router';


const TaskPage = lazyWithRetry(() => import('@/pages/Task/Task'));


const TaskRoutes: RouterItemsType = {
    '/task/:taskUuid([0-9_A-Za-z_-]+)': {
        view: <TaskPage/>,
        auth: [],
        spaces: [
            PageSpace.SchoolPlatform,
            PageSpace.MarketplacePlatform,
        ],
    },
};


export { TaskRoutes };
