/**
 * QrCode component
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { IProps, QRCode } from 'react-qrcode-logo';

import { Container } from './QrCode.styled';


interface Props extends IProps {
    className?: string;
}


const QrCode = (props: Props) => {
    return (
        <Container className={props.className}>
            <QRCode bgColor="transparent"
                    value={props.value}
                    logoImage={props.logoImage || ''}
                    eyeRadius={10}
                    logoWidth={40}
                    logoHeight={40}
                    qrStyle="squares" {...props}/>
        </Container>
    );
};


export { QrCode };
