/**
 * SellerBecomeRequestInput
 *
 * @author: exode <hello@exode.ru>
 */

import * as Yup from 'yup';

import { SellerBecomeRequestDocType, SellerOrganizationForm, SellerType } from '@/codegen/graphql';

import { regex } from '@/utils';
import { checkIsOrganization } from '@/types/seller';

import { isMinMax, isNotEmpty } from '../constants';


export const SellerBecomeRequestInputSchema = Yup.object().shape({
    type: Yup.string()
        .oneOf(Object.values(SellerType)),

    organizationForm: Yup.string()
        .oneOf(Object.values(SellerOrganizationForm)),

    bankAccountNumber: Yup.string()
        .required(isNotEmpty('Расчетный счет'))
        .matches(regex.bankAccountNumber, {
            message: 'Номер счета невалидный',
        })
        .min(1, isMinMax(1, 'Расчетный счет', 'min')),

    bic: Yup.string()
        .required(isNotEmpty('БИК'))
        .matches(/^\d{9}$/, isMinMax(9, 'БИК', 'min')),

    inn: Yup.string()
        .required(isNotEmpty('ИНН'))
        .matches(/^\d{10,12}$/, isMinMax(10, 'ИНН', 'min')),

    firstName: Yup.string()
        .when('organizationForm', {
            is: (organizationForm: SellerOrganizationForm) => !checkIsOrganization(organizationForm),
            then: () => Yup.string().required(isNotEmpty('Имя')),
            otherwise: () => Yup.string().nullable(),
        }),

    lastName: Yup.string()
        .when('organizationForm', {
            is: (organizationForm: SellerOrganizationForm) => !checkIsOrganization(organizationForm),
            then: () => Yup.string().required(isNotEmpty('Фамилия', 'пустой')),
            otherwise: () => Yup.string().nullable(),
        }),

    surname: Yup.string()
        .when('organizationForm', {
            is: (organizationForm: SellerOrganizationForm) => !checkIsOrganization(organizationForm),
            then: () => Yup.string().required(isNotEmpty('Отчество', 'пустым')),
            otherwise: () => Yup.string().nullable(),
        }),

    passport: Yup.object()
        .when('organizationForm', {
            is: (organizationForm: SellerOrganizationForm) => !checkIsOrganization(organizationForm),
            then: () => Yup.object().shape({
                registration: Yup.string()
                    .required(isNotEmpty('Адрес')),

                issuedBy: Yup.string()
                    .required(isNotEmpty('Название подразделения')),

                dateOfIssue: Yup.date()
                    .required(isNotEmpty('Дата выдачи', 'пустой')),

                issuedByCode: Yup.string()
                    .required(isNotEmpty('Код'))
                    .min(6, isMinMax(6, 'Код', 'min')),

                number: Yup.string()
                    .required(isNotEmpty('Номер'))
                    .min(6, isMinMax(6, 'Номер', 'min')),

                series: Yup.string()
                    .required(isNotEmpty('Серия', 'пустой'))
                    .min(4, isMinMax(4, 'Серия', 'min', 'должна')),

                pages: Yup.array()
                    .max(2)
                    .min(2, 'Необходимо загрузить обе страницы паспорта')
                    .of(Yup.object().shape({
                        url: Yup.string().required('Фото паспорта обязательны'),
                        page: Yup.string().oneOf(Object.values(SellerBecomeRequestDocType)),
                    })),
            }),
        }),
});
