/**
 * UseSchoolAcquiring
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import { ArrayUtil } from '@/utils';
import { apolloClient } from '@/api/graphql';

import { GqlResult } from '@/types/graphql';
import { DraggingIndexes } from '@/types/common';
import { SchoolAcquiringListItem } from '@/types/school';

import {
    CreateAcquiringInput,
    SchoolAcquiringCreateMutationResult,
    SchoolAcquiringFindManyDocument,
    SchoolAcquiringFindManyQuery,
    SchoolAcquiringUpdateMutationResult,
    UpdateAcquiringInput,
    useSchoolAcquiringCreateMutation,
    useSchoolAcquiringUpdateMutation,
    useSchoolAcquiringUpdateOrderMutation,
} from '@/codegen/graphql';


export const useSchoolAcquiring = () => {

    const getCachedAcquiring = () => {
        const variables = {
            list: {},
            filter: {},
        };

        const cachedAcquiring = apolloClient.cache.readQuery<SchoolAcquiringFindManyQuery>({
            variables,
            query: SchoolAcquiringFindManyDocument,
        });

        return {
            variables,
            cachedAcquiring: cachedAcquiring?.schoolAcquiringFindMany,
        };
    };

    const [ _createAcquiring, {
        loading: createAcquiringLoading,
        error: createAcquiringError,
    } ] = useSchoolAcquiringCreateMutation();

    const createAcquiring = (
        acquiring: CreateAcquiringInput,
        onCompleted?: (
            school: GqlResult<SchoolAcquiringCreateMutationResult>['schoolAcquiringCreate'],
        ) => void,
    ) => {
        return _createAcquiring({
            variables: { acquiring },
            onCompleted: ({ schoolAcquiringCreate }) => {
                onCompleted?.(schoolAcquiringCreate);
            },
            update: (cache, { data }) => {
                const variables = { list: {}, filter: {} };

                const cachedAcquirings = cache.readQuery<SchoolAcquiringFindManyQuery>({
                    variables,
                    query: SchoolAcquiringFindManyDocument,
                });

                if (!cachedAcquirings) {
                    return console.warn('[Cache]: cachedAcquirings отсутствуют в кэше');
                }

                const {
                    items,
                    count,
                    ...restCache
                } = cachedAcquirings.schoolAcquiringFindMany;

                data?.schoolAcquiringCreate && cache.writeQuery<SchoolAcquiringFindManyQuery>({
                    variables,
                    query: SchoolAcquiringFindManyDocument,
                    data: {
                        schoolAcquiringFindMany: {
                            ...restCache,
                            count: count + 1,
                            items: [
                                ...(items || []),
                                data.schoolAcquiringCreate,
                            ],
                        },
                    },
                });
            },
        });
    };

    const [ _updateAcquiring, {
        loading: updateAcquiringLoading,
        error: updateAcquiringError,
    } ] = useSchoolAcquiringUpdateMutation();

    const updateAcquiring = (
        acquiringId: number,
        acquiring: UpdateAcquiringInput,
        onCompleted?: (
            school: GqlResult<SchoolAcquiringUpdateMutationResult>['schoolAcquiringUpdate'],
        ) => void,
    ) => {
        return _updateAcquiring({
            variables: { acquiringId, acquiring },
            onCompleted: ({ schoolAcquiringUpdate }) => {
                onCompleted?.(schoolAcquiringUpdate);
            },
        });
    };

    const entityCrossFields = [
        'name',
        'note',
        'active',
        'description',
    ];

    const [ _reorderSchoolAcquiring, {
        loading: reorderSchoolAcquiringLoading,
        error: reorderSchoolAcquiringError,
    } ] = useSchoolAcquiringUpdateOrderMutation();

    const reorderSchoolAcquiringList = (
        indexes: DraggingIndexes,
        reorderList: SchoolAcquiringListItem[],
    ) => {
        if (reorderList && !_.isEmpty(reorderList)) {
            const items = ArrayUtil.reorderList<typeof reorderList>(indexes, reorderList);

            const { variables, cachedAcquiring } = getCachedAcquiring();

            if (cachedAcquiring) {
                apolloClient.cache.writeQuery<SchoolAcquiringFindManyQuery>({
                    variables,
                    query: SchoolAcquiringFindManyDocument,
                    data: {
                        schoolAcquiringFindMany: {
                            items,
                            page: cachedAcquiring.page || 1,
                            pages: cachedAcquiring.pages || 1,
                            count: cachedAcquiring.count || 1,
                        },
                    },
                });
            }

            const acquiringIds = items?.map(({ id }) => id);

            return _reorderSchoolAcquiring({
                variables: { acquiringIds },
            });
        }
    };

    return {
        createAcquiring,
        createAcquiringLoading,
        createAcquiringError,
        updateAcquiring,
        updateAcquiringLoading,
        updateAcquiringError,
        entityCrossFields,
        reorderSchoolAcquiringList,
        reorderSchoolAcquiringLoading,
        reorderSchoolAcquiringError,
    };
};
