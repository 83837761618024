/**
 * ProfileService
 *
 * @author: exode <hello@exode.ru>
 */

import { RestApi } from '@/api/restapi';

import { BaseService } from '@/services/Core/Base';

import { GetSelfUserOutput } from '@/codegen/graphql';


class ProfileService extends BaseService {

    /**
     * Получение полной информации профиля о себе.
     * Происходит после получения токена (авторизации).
     * @returns {}
     */
    static async getSelfProfileInfo() {
        const result = await RestApi.post('profile/get/self');

        return this.parse<GetSelfUserOutput>(result);
    }

}


export { ProfileService };
