/**
 * Use dialog
 *
 * @author: exode <hello@exode.ru>
 */

import { useContext } from 'react';

import { Router } from '@/services/Utils/Router';

import { useStore } from '@/pages/Core';
import { RouterStore } from '@/store/core/router';

import { ModalRootContext } from '@exode.ru/vkui';

import { DialogModalProps, DialogModalStore } from '@/modals/Utils/Dialog';


const useDialog = (props: DialogModalProps) => {

    const { store } = useStore(DialogModalStore);

    const modalContext = useContext(ModalRootContext);

    const handleModalClose = () => {
        store.merge({
            muiIsOpened: false,
        });

        modalContext.onClose?.();

        if (props.mode === 'mui') {
            setTimeout(() => {
                store.resetDialog();

                store.merge({ muiIsOpened: true });

                Router.updateParams({
                    muiDialog: 'false',
                });
            }, 200);
        }
    };

    const openDialog = () => {
        DialogModalStore.open(props);

        props.mode === 'vk'
            ? Router.pushModal('dialog-modal')
            : Router.pushPage(RouterStore.pageId, {
                ...Router.routerParams,
                muiDialog: 'true',
            });
    };

    const closeDialog = () => {
        DialogModalStore.close();
    };

    return {
        openDialog,
        closeDialog,
        handleModalClose,
    };
};


export { useDialog };
