/**
 * NativeBrowserService
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import { RouterStore } from '@/store/core/router';
import { UserAuthStore } from '@/store/user/auth';

import { Time, Url } from '@/utils';

import { Router } from '@/services/Utils/Router';
import { NativeService } from '@/services/Native/Native';

import { matchPath } from '@/router/helper';
import { RoutePathType } from '@/router/paths';


class NativeBrowserService extends NativeService {

    /**
     * Соответствие названия action и его обработчика в handleOpenUrls
     */
    static readonly actionOpenUrlMapping = {
        'login-by-token': (params: any) => {
            NativeBrowserService.closeBrowser();

            return UserAuthStore.handleRedirectTokenAfterLogin(
                params.token,
                Router.routerParams,
            );
        },
    } as const;

    /**
     * Открытие браузера внутри приложения (in app)
     * @param {string} url
     * @param systemBrowser
     */
    static openUrl(
        url: string,
        systemBrowser = false,
    ) {
        if (!this.isNative) {
            return;
        }

        NativeService.sendToBridge({
            module: 'InAppBrowser',
            method: 'openUrl',
            payload: { url, systemBrowser },
        });
    }

    /**
     * Закрытие in app браузера
     */
    static closeBrowser() {
        if (!this.isNative) {
            return;
        }

        NativeService.sendToBridge({
            module: 'InAppBrowser',
            method: 'closeBrowser',
        });
    }

    /**
     * Открытие приложения по ссылке
     * @param {string} openedUrl
     */
    static async handleOpenUrl(openedUrl: string) {
        await Time.timer(1);

        const url = new URL(openedUrl);

        const actionHandler = async (params: Record<any, any>) => {
            const action = params.action as keyof typeof NativeBrowserService.actionOpenUrlMapping;

            const handler = this.actionOpenUrlMapping[action];

            return _.isFunction(handler) && await handler(params);
        };

        if (url.protocol === 'exodeapp:') {
            const parsed = Url.parseQuery(url.search);
            const params = Url.parseQuery(parsed.data || '');

            return actionHandler(params);
        }

        const match = matchPath(url.pathname);

        const id = (match?.path || '/') as RoutePathType;

        const data = decodeURIComponent(url.search.replace(/\?/g, ''));
        const params = Url.parseQuery(data);

        const next = {
            pageId: id,
            params: { ...params, ...match?.params },
        };

        const current = {
            pageId: RouterStore.pageId,
            params: _.cloneDeep(RouterStore.params),
        };

        const { equal } = Router.comparePages(next, current);

        Router[equal ? 'replacePage' : 'pushPage'](id, match?.params || {});

        return actionHandler(params);
    }

}


export { NativeBrowserService };
