/**
 * FriendsSearchView
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import React from 'react';

import { If, Link } from '@/cutils';
import { useI18n } from '@/hooks/core';
import { GqlResult } from '@/types/graphql';
import { SearchProfileQueryResult } from '@/codegen/graphql';

import { Header, List } from '@exode.ru/vkui';
import { Icon24PawOutline, Icon28ChevronRightOutline } from '@vkontakte/icons';

import { User } from '@/components/Atoms/User';
import { Placeholder } from '@/components/Atoms/Placeholder';
import { UserListSkeleton } from '@/components/Atoms/Skeleton/UserList';


interface Props {
    profiles?: GqlResult<SearchProfileQueryResult>['profileFindMany'];
    loading?: boolean;
}


const FriendsSearchView = (props: Props) => {

    const { profiles, loading } = props;

    const { t } = useI18n('pages.Profile.Friends');

    return (
        <>
            <If is={!!loading}>
                <UserListSkeleton wrapRow mode="friends"/>
            </If>

            <If is={!loading}>
                <If is={_.isEmpty(profiles)}>
                    <Placeholder iconSize={120}
                                 innerPadding="0px"
                                 className="min-h-[300px]"
                                 header={t('noSearchResults')}
                                 icon={<Icon24PawOutline className="text-accent opacity-[0.85]"/>}
                                 content={(
                                     <span className="m:w-full w-9/10 flex mx-auto">
                                         {t('tryToChangeFilter')}
                                     </span>
                                 )}/>
                </If>

                <If is={!_.isEmpty(profiles)}>
                    <Header mode="secondary">
                        {t('searchResults')}
                    </Header>

                    <List>
                        {profiles?.items?.map((profile) => (
                            <Link key={profile.id} pushPage={{
                                id: '/@:userId([0-9_A-Za-z]+)',
                                params: { userId: `${profile?.user.id}` },
                            }}>
                                <User.Cell mode="simple"
                                           avatarSize={48}
                                           profile={profile}
                                           userId={profile.user.id}
                                           avatarProps={{ className: 'my-1' }}
                                           lastOnlineAt={profile.user.lastOnlineAt}
                                           after={<Icon28ChevronRightOutline width={28} height={28}/>}/>
                            </Link>
                        ))}
                    </List>
                </If>
            </If>
        </>
    );
};


export { FriendsSearchView };
