/**
 * SingleVariantPart
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { If, Link } from '@/cutils';

import { Radio, SimpleCell } from '@exode.ru/vkui';

import { Option } from '../AdaptiveSelect';


interface Props {
    option: Option;
    onSelect: Function;
    isActionSheets?: boolean;
    useInModal?: boolean;
    loading?: boolean;
}


const SingleVariantPart = (props: Props) => {

    const { option, onSelect, isActionSheets, useInModal, loading } = props;

    const classNameDisabled = `${option.disabled && 'opacity-50 cursor-not-allowed'}`;

    return (
        <>
            <If is={!!isActionSheets}>
                <Link key={option.value} replaceModal={useInModal}>
                    <SimpleCell after={option.after}
                                onClick={() => onSelect(option)}
                                disabled={option.disabled || loading}
                                subtitle={option.description}
                                className={`d:-mx-4 first:-mt-2 last:-mb-2 ${classNameDisabled}`}>
                        {option.label}
                    </SimpleCell>
                </Link>
            </If>

            <If is={!isActionSheets}>
                <Link key={option.value} replaceModal={useInModal}>
                    <Radio name="radio"
                           disabled={option.disabled || loading}
                           value={option.value}
                           onClick={() => onSelect(option)}
                           onChange={() => ({})}
                           checked={option.checked}
                           description={option.description}>
                        {option.label}
                    </Radio>
                </Link>
            </If>
        </>
    );
};


export { SingleVariantPart };
