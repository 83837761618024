/**
 * FeedContentCard styled container
 *
 * @author: exode <hello@exode.ru>
 */

import styled from 'styled-components';


export const Container = styled.div`
  margin-bottom: 9px;

  :first-child {
    margin-top: 9px;
  }

  .MuiCard-root {
    border-radius: 0;
    background-color: var(--background_content);
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
  }
`;
