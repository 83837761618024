/**
 * Filter useTaskBaseFilter
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import { useUrlFilters } from '@/hooks/core';

import { ManageTasksListPageStore } from './store';

import { EducationSubject, TaskAnswerType, TaskComplexity, TaskContentType, TaskVisibility } from '@/codegen/graphql';


export const defaultFilter = {
    search: '',
    manage: true,
    archived: false,
    administrate: false,
    listMode: 'manage' as 'manage' | 'administrate',
    subjectCategoryIds: [] as number[],
    subjects: [] as EducationSubject[],
    answerTypes: [] as TaskAnswerType[],
    complexities: [] as TaskComplexity[],
    visibilities: [] as TaskVisibility[],
    contentTypes: [] as TaskContentType[],
};

export const dtoFilterTransformer = (filter: typeof defaultFilter) => ({
    ..._.omit(filter, [ 'listMode' ]),
    manage: filter.listMode === 'manage',
    administrate: filter.listMode === 'administrate',
    subjectCategoryIds: filter.subjectCategoryIds?.map((e) => +e),
});

export const useTaskBaseFilter = () => useUrlFilters(
    defaultFilter,
    [],
    dtoFilterTransformer,
    {
        store: ManageTasksListPageStore,
    },
);
