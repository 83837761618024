/**
 * PulsePoint component
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';


interface Props {
    className?: string;
}


const PulsePoint = (props: Props) => {
    return (
        <span className={[ 'relative flex h-2 w-2', props.className ].join(' ')}>
            <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-green-400 dark:bg-lime-500 opacity-75"/>
            <span className="relative inline-flex rounded-full h-2 w-2 bg-green-500 dark:bg-lime-600"/>
        </span>
    );
};


export { PulsePoint };
