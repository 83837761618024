/**
 * MembersProgressView
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { TournamentMembersProgressQuery } from '@/codegen/graphql';

import { Page } from '@/pages/Core';
import { Graphql, If } from '@/cutils';

import { tournamentMembersListConfig } from '@/pages/Tournament/Lobby/constants';

import { ErrorFallback } from '@/components/App/ErrorFallback';
import { TournamentMembersSkeleton } from '@/components/Atoms/Skeleton/TournamentMembers';

import { ParticipantsProgressView } from './ParticipantsProgressView';


interface Props {
    tournamentId: number;
    isContext?: boolean;
}


const MembersProgressView = (props: Props) => {

    const { tournamentId, isContext } = props;

    return (
        <TournamentMembersProgressQuery children={(result) => (
            <>
                <Graphql.Loading result={result}>
                    <If is={!!isContext}>
                        <TournamentMembersSkeleton isProcess/>
                    </If>

                    <If is={!isContext}>
                        <Page.Section paddings="pt-2 px-3">
                            <TournamentMembersSkeleton isProcess/>
                        </Page.Section>
                    </If>
                </Graphql.Loading>

                <Graphql.Success result={result}>
                    {({ tournamentResultFindMany: members }) => (
                        <ParticipantsProgressView tournamentId={tournamentId} isContext={isContext} members={members}/>
                    )}
                </Graphql.Success>

                <Graphql.Error result={result}>
                    <ErrorFallback message={result.error?.message} onClick={() => result.refetch()}/>
                </Graphql.Error>
            </>
        )} variables={{ ...tournamentMembersListConfig, tournamentId }}/>
    );
};


export { MembersProgressView };
