/**
 * UseChatCreation
 *
 * @author: exode <hello@exode.ru>
 */

import {
    ChatCreateMutationResult,
    ChatFindManyDocument,
    ChatFindManyQuery,
    CreateChatInput,
    CreateTicketSupportInput,
    useChatCreateMutation,
    useSupportTicketCreateByUserMutation,
} from '@/codegen/graphql';

import { useLocation } from '@/router/index';

import { Url } from '@/utils';
import { useStore } from '@/pages/Core';
import { GqlResult } from '@/types/graphql';
import { apolloClient } from '@/api/graphql';

import { Router } from '@/services/Utils/Router';

import { useChatListFilter } from '@/pages/Chat/Dialog/filter';
import { ChatDialogsPageStore } from '@/pages/Chat/Dialog/store';


interface CreateChatProps {
    chat: CreateChatInput;
    ignoreCache?: boolean;
    onCompleted?: (chat: GqlResult<ChatCreateMutationResult>['chatCreate']) => void;
}


export const useChatCreation = () => {

    const { dtoFilter } = useChatListFilter();
    const { route: { params: { folder } } } = useLocation();
    const { list, filter, sort } = useStore(ChatDialogsPageStore);

    const [ _createChat, {
        loading: createChatLoading,
    } ] = useChatCreateMutation();

    const [ _createSupportTicket, {
        loading: createSupportTicketLoading,
    } ] = useSupportTicketCreateByUserMutation();

    const createChat = (props: CreateChatProps) => _createChat({
        variables: { chat: props.chat },
        onCompleted: ({ chatCreate }) => {
            props.onCompleted?.(chatCreate);
        },
        update: (cache, { data }) => {
            if (props.ignoreCache) {
                return;
            }

            const variables = {
                list: { ...list.chats },
                filter: { ...filter.chats },
                sort: { ...sort.chats },
            };

            const writeNewChat = (_variables: typeof variables) => {
                const cachedChats = cache.readQuery<ChatFindManyQuery>({
                    variables: _variables,
                    query: ChatFindManyDocument,
                });

                if (!cachedChats?.chatFindMany) {
                    return console.warn('[Cache]: cachedChats отсутствуют в кэше');
                }

                const { cursor, items, count } = cachedChats?.chatFindMany;

                cache.writeQuery<ChatFindManyQuery>({
                    variables: _variables,
                    query: ChatFindManyDocument,
                    data: {
                        chatFindMany: {
                            __typename: 'ListChatOutput',
                            count,
                            cursor,
                            items: data?.chatCreate
                                ? [ data?.chatCreate, ...(items || []) ]
                                : (items || []),
                        },
                    },
                });
            };

            if (data?.chatCreate) {
                /** Write to All folder */
                writeNewChat({
                    ...variables,
                    filter: {
                        ...variables.filter,
                        types: [],
                    },
                });

                if (dtoFilter.types?.length && dtoFilter.types.includes(data.chatCreate.type)) {
                    /** Write to the specific folder if chat types match */
                    writeNewChat({
                        ...variables,
                        filter: {
                            ...variables.filter,
                            types: dtoFilter.types,
                        },
                    });
                }
            }
        },
    });

    const createSupportTicket = (ticket: CreateTicketSupportInput) => {
        const { locationIsSame } = Url.compareOnAsync();

        return _createSupportTicket({
            variables: { ticket },
            onCompleted: ({ supportTicketCreateByUser: { chat } }) => {
                if (chat?.id) {
                    apolloClient.cache.evict({
                        fieldName: 'ChatFindMany',
                    });

                    if (locationIsSame()) {
                        Router.replacePage('/chat', { folder, chatId: `${chat.id}` });
                    }
                }
            },
        });
    };

    return {
        createChat,
        createChatLoading,
        createSupportTicket,
        createSupportTicketLoading,
    };
};
