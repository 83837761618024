/**
 * CourseShareModal
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { useStableModalParams } from '@/router/index';

import { Modal } from '@/cutils';
import { useI18n } from '@/hooks/core';
import { APP_URL } from '@/root/src/env';

import { Title } from '@exode.ru/vkui';
import { Icon24Share } from '@vkontakte/icons';

import { CopyToClipboard } from '@/components/Atoms/CopyToClipboard';
import { SocialShareButtons } from '@/components/Atoms/SocialShareButtons';


interface Props {
    id: string;
}


const CourseShareModal = (props: Props) => {

    const { t } = useI18n('modals.Course.Share');

    const [ { page, courseId, lessonId } ] = useStableModalParams();

    const link = lessonId
        ? `${APP_URL}/courses/${page}/${courseId}/study/${lessonId}`
        : `${APP_URL}/courses/${page}/${courseId}`;

    return (
        <Modal.Card id={props.id}
                    icon={<Modal.CardIcon icon={<Icon24Share fill="var(--white)"/>}/>}
                    subheader={(lessonId
                            ? t('youAreSharingPublicLinkToLesson')
                            : t('youAreSharingPublicLinkToCourse')
                    )}
                    header={(
                        <Title className="modal-title" level="2" weight="3">
                            {lessonId ? t('shareWithLessonTitle') : t('shareWithCourseTitle')}
                        </Title>
                    )}>
            <Modal.Body isScrollable>
                <CopyToClipboard link={link} className="mt-3 mb-6"/>
                <SocialShareButtons link={link}/>
            </Modal.Body>
        </Modal.Card>
    );
};


export { CourseShareModal };
