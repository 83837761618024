/**
 * ManageSellerBalanceOperationModal
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { Page } from '@/pages/Core';
import { useI18n } from '@/hooks/core';
import { Graphql, Link, Modal } from '@/cutils';
import { useStableModalParams } from '@/router/index';

import { SellerBalanceOperationFindOneQuery } from '@/codegen/graphql';

import { Button, Title } from '@exode.ru/vkui';
import { Icon20HeadphonesSupportOutline } from '@vkontakte/icons';

import { OperationDetails } from '@/components/Seller/Balance/OperationDetails';


interface Props {
    id: string;
}


const ManageSellerBalanceOperationModal = (props: Props) => {

    const { t } = useI18n('modals.Manage.Seller.SellerBalanceOperation');

    const [ { operationId } ] = useStableModalParams();

    return (
        <Modal.Card id={props.id} header={(
            <Title className="modal-title" level="2" weight="3">
                {t('operationDetails')}
            </Title>
        )} actions={(
            <Link pushPage={{ id: '/support' }}>
                <Button size="l" mode="secondary" before={<Icon20HeadphonesSupportOutline/>}>
                    {t('support')}
                </Button>
            </Link>
        )}>
            <Modal.Body isScrollable fullHeight className="flex flex-col pt-1">
                <SellerBalanceOperationFindOneQuery children={(result) => (
                    <>
                        <Graphql.Loading result={result}>
                            <Page.Spinner/>
                        </Graphql.Loading>

                        <Graphql.Success result={result}>
                            {({ sellerBalanceOperationFindOne: operation }) => (
                                <OperationDetails operation={operation}/>
                            )}
                        </Graphql.Success>

                        <Graphql.Error result={result}/>
                    </>
                )} skip={!operationId} variables={{ operationId: +operationId }}/>
            </Modal.Body>
        </Modal.Card>
    );
};


export { ManageSellerBalanceOperationModal };
