/**
 * PageStore
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import { observable } from 'mobx';
import { observer } from 'mobx-react';

import { RouterStore } from '@/store/core/router';

import { DocumentEvent } from '@/types/window';
import { RouterEventType, RouteType } from '@/types/router';
import { Store, StoreCommonType, StoreOnInputCallback, StoreState } from '@/types/store';

import { useLocation } from '@/router/index';
import { InputType, SelectType } from '@/cutils';
import { Router } from '@/services/Utils/Router';


const useStore = <PageStoreType extends StoreCommonType>(store: PageStoreType) => {
    const action = (action: Function) => action.bind(store);

    const stater = store.state as PageStoreType['state'];
    const input = stater.input as PageStoreType['state']['input'];
    const filter = stater.filter as PageStoreType['state']['filter'];
    const sort = stater.sort as PageStoreType['state']['sort'];
    const list = stater.list as PageStoreType['state']['list'];
    const onInput = action(store.onInput) as PageStoreType['onInput'];

    const { route: { pageId, params } } = useLocation();

    return {
        store,
        stater,
        input,
        filter,
        sort,
        list,
        onInput,
        action,
        pageId,
        params,
    };
};

class PageStore implements Store {

    /**
     * Конструктор (в т.ч. для наследуемых классов)
     */
    constructor() {
        this.init();
    }

    /** Store для state страницы */
    @observable
    state: StoreState = {
        input: {},
        filter: {},
        sort: {},
        list: {},
        loading: {},
        success: {},
    };

    /** Набор callback функций для событий onInput: filter.field, input.field */
    callOnInput: StoreOnInputCallback = {};

    /**
     * Инициализация событий
     * @private
     */
    private init() {
        document.addEventListener(DocumentEvent.RouterAfterUpdate, (e) => {
            this.onRouterChange(e as CustomEvent);
        });
    }

    /**
     * Обработчик события изменения состояния router
     * @protected
     */
    private onRouterChange(e: RouterEventType) {
        const { next, prev } = e.detail;
        const { pageIsSame, paramsIsSame } = Router?.compareRouterState(e) || {};

        !pageIsSame && this.onPageChange(next, prev);
        pageIsSame && !paramsIsSame && this.onParamsChange(next, prev);
    }

    /**
     * Обработчик перехода на другую страницу
     * @param {Route} next
     * @param {Route} prev
     * @protected
     */
    protected onPageChange(next: RouteType, prev?: RouteType) {

    }

    /**
     * Обработчик изменения get параметров
     * @param {Route} next
     * @param {Route} prev
     * @protected
     */
    protected onParamsChange(next: RouteType, prev?: RouteType) {

    }

    /**
     * Обработчик изменения пользовательских данных (this.state.data)
     * @param {InputType} e
     */
    onInput(e: InputType) {
        const { name, value } = e.target;


        const [ space, field ] = name.split('.');
        const { input, filter } = this.state;

        const callbacks = this.callOnInput;

        type InputDataType = keyof typeof input;
        type FilterDataType = keyof typeof filter;

        type EventType = keyof typeof callbacks;

        if (!field) {
            this.state.input[name] = value;
        }

        if (field && space === 'filter') {
            this.state.filter[field as FilterDataType] = value;
        } else if (field && space !== 'filter') {
            this.state.input[field as InputDataType] = value;
        }

        const callback = callbacks[name as EventType];

        _.isFunction(callback) && callback(e);
    }

    /**
     * Обработчик изменения пользовательских данных в checkbox (this.state.data)
     * @param {InputType} e
     */
    onCheckboxChange(e: InputType) {
        const { name, checked } = e.target;
        const { input, filter } = this.state;

        const [ space, field ] = name.split('.');

        space !== 'filter'
            ? (input[space] = checked)
            : (filter[field] = checked);
    }

    /**
     * Обработчик изменения пользовательских данных внутри массива (this.state.data)
     * @param {SelectType} e
     */
    onSelectArray(e: SelectType) {
        const { name, value } = e.target;

        const { filter } = this.state;

        if (_.isArray(filter[name])) {
            filter[name].includes(value)
                ? this.state.filter[name] = filter[name].filter((item: string) => item !== value)
                : this.state.filter[name] = [ ...filter[name], value ];
        }
    }

    /**
     * Установка значения input в state
     * @param {keyof this['state']['input']} name
     * @param value
     */
    setInput<T>(
        name: keyof this['state']['input'],
        value: this['state']['input'][typeof name],
    ) {
        this.state.input[name] = value;
    }

    /**
     * Merge input value
     * @param {Partial<this['state']['input']>} partial
     */
    mergeInput(partial: Partial<this['state']['input']>) {
        Object.assign(this.state.input, partial);
    }

    /**
     * Установка значения filter в state
     * @param {keyof this['state']['input']} name
     * @param value
     */
    setFilter<T>(
        name: keyof this['state']['filter'],
        value: this['state']['filter'][typeof name],
    ) {
        this.state.filter && (this.state.filter[name] = value);
    }

    /**
     * Merge filter value
     * @param {Partial<this['state']['input']>} partial
     */
    mergeFilter(partial: Partial<this['state']['filter']>) {
        Object.assign(this.state.filter, partial);
    }

    /**
     * Установка статуса загрузки
     * @param {keyof this['state']['errors']} name
     * @param status
     */
    setLoading(
        name: keyof this['state']['loading'],
        status: boolean = true,
    ) {
        this.state.loading[name] = status;
    }

    /**
     * Обертка для запросов к сервису с автоматической работой loading статусов
     * @returns {Promise<T>}
     * @param name
     * @param params
     */
    async wrapService<T>(
        name: keyof this['state']['loading'],
        params: { service: Promise<T> },
    ) {
        const service: Promise<T> = params.service;

        this.setLoading(name);

        let result = null;

        try {
            result = await service;
        } finally {
            this.setLoading(name, false);
        }

        return result;
    }

    /**
     * Установка значений в store.input из get параметров
     * @param {keyof this['state']['input']} fields
     */
    setInputByGetParams(...fields: (keyof this['state']['input'])[]) {
        const { params } = RouterStore;

        Object.assign(this.state.input, _.pick(params, fields));
    }

    /**
     * Установка значений в store.filter из get параметров
     * @param {keyof this['state']['input']} fields
     */
    setFilterByGetParams(...fields: (keyof this['state']['filter'])[]) {
        const { params } = RouterStore;

        Object.assign(this.state.filter, _.pick(params, fields));
    }

}


export { observer, PageStore, useStore };
