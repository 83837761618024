/**
 * FooterSocialsPart
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { If, Link } from '@/cutils';
import { useI18n } from '@/hooks/core';

import { observer, PlatformStore } from '@/store/platform';

import { Separator } from '@exode.ru/vkui';
import { Icon16LogoApple } from '@vkontakte/icons';

import { AppButtonPart } from '@/components/Navigation/Block';
import { IconGooglePlay, IconTelegram, IconTikTok, IconVk } from '@/images/icons';


const FooterSocialsPart = observer(() => {

    const { COMMON } = PlatformStore;

    const { t } = useI18n('components.Navigation.Block.Footer.parts');

    return (
        <>
            <div className="flex m:w-full items-start flex-col gap-5 d:pr-11">
                <span className="fs-15 font-vk-sans-display">
                    {t('weInSocialNetwork')}
                </span>

                <Separator className="w-full m:hidden" wide={true}/>

                <div className="flex gap-12">
                    <If is={!!COMMON?.organization.social.vkGroupUrl}>
                        <Link blank toOuter={COMMON?.organization.social.vkGroupUrl}>
                            <IconVk/>
                        </Link>
                    </If>

                    <If is={!!COMMON?.organization.social.tgChannelUrl}>
                        <Link blank toOuter={COMMON?.organization.social.tgChannelUrl}>
                            <IconTelegram/>
                        </Link>
                    </If>

                    <If is={!!COMMON?.organization.social.ttAccountUrl}>
                        <Link blank toOuter={COMMON?.organization.social.ttAccountUrl}>
                            <IconTikTok/>
                        </Link>
                    </If>
                </div>

                <If is={!!COMMON?.organization.info.email}>
                    <a href={`mailto:${COMMON?.organization.info.email}`}
                       className="fs-16 text-secondary m-0 font-vk-sans-display no-underline">
                        {COMMON?.organization.info.email}
                    </a>
                </If>

                <div className="flex gap-4 mt-2 lg:flex-col md:flex-col">
                    <AppButtonPart text="App Store" icon={<Icon16LogoApple width={20} height={20}/>}/>

                    <AppButtonPart text="Google Play" icon={<IconGooglePlay className="mt-[3px] ml-1"/>}/>
                </div>
            </div>
        </>
    );
});


export { FooterSocialsPart };
