/**
 * ChatAttachmentsModal
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { observer } from '@/pages/Core';

import { Modal } from '@/cutils';

import { ChatAttachmentsView } from '@/pages/Chat/Dialog/views/ChatAttachmentsView';


interface Props {
    id: string;
}


const ChatAttachmentsModal = observer((props: Props) => {
    return (
        <Modal.Card id={props.id} padding="0">
            <Modal.Body fullHeight isScrollable className="mt-0">
                <ChatAttachmentsView/>
            </Modal.Body>
        </Modal.Card>
    );
});


export { ChatAttachmentsModal };
