/**
 * CustomScrollbar styled container
 *
 * @author: exode <hello@exode.ru>
 */

import styled from 'styled-components';

import { customScrollbar } from '@/styles/modules/scrollbar';


export const CustomScrollbar = styled.div`
  ${customScrollbar()}
`;
