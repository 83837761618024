/**
 * ContentView
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { observer, UserAuthStore } from '@/store/user/auth';

import { Subjects } from '@/types/subject';
import { EducationSubject } from '@/codegen/graphql';

import { List } from '@exode.ru/vkui';
import { Router } from '@/services/Utils/Router';

import { ProfileSubjectCard } from '@/components/Profile/Subject/Card';


interface Props {
    subjects: Subjects;
    filter?: (s: Subjects[number]) => boolean;
}


const ContentView = observer((props: Props) => {

    const { subjects, filter = () => true } = props;

    const handleCreateBlitz = (subject: EducationSubject) => {
        UserAuthStore.isLoggedIn
            ? Router.pushPage('/tournament/blitz/waiting/:subject([a-z-]+)', { subject })
            : Router.replaceToModalAfterLogin(
                '/tournaments',
                undefined,
                undefined,
                Router.routerParams,
            );
    };

    return (
        <List className="grid grid-cols-2 justify-center gap-2 m:mx-4 m:mt-4">
            {subjects.filter(filter).map((subject, index) => (
                <ProfileSubjectCard size="big"
                                    key={index}
                                    subject={subject}
                                    disabled={!subject.activeForTournament}
                                    onClick={() => handleCreateBlitz(subject.subject)}/>
            ))}
        </List>
    );
});


export { ContentView };
