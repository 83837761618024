/**
 * MyCategoriesContextItem
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { useI18n, usePrompt } from '@/hooks/core';

import { SimpleCell } from '@exode.ru/vkui';
import { Icon28ArticleOutline, Icon28SettingsOutline } from '@vkontakte/icons';

import { SelectProfileCategorySelector } from '@/components/Profile/Content/SelectProfileCategorySelector';


const MyCategoriesContextItem = () => {

    const { t } = useI18n('tabs.Education.items');

    const { openPrompt: openConfirmCategories } = usePrompt({
        onConfirm: () => {},
        title: t('selectPath'),
        subtitle: t('willEffectToRecommendationSystem'),
        children: (
            <div className="px-2 my-4">
                <SelectProfileCategorySelector appearance="primary"/>
            </div>
        ),
        buttons: {
            cancel: { hidden: true },
            confirm: {
                text: t('ready'),
                props: {
                    mode: 'secondary',
                },
            },
        },
    });

    return (
        <SimpleCell hasHover={false}
                    hasActive={false}
                    subtitle={t('educationPaths')}
                    before={<Icon28ArticleOutline/>}
                    after={<Icon28SettingsOutline/>}
                    onClick={() => openConfirmCategories()}
                    className="bg-content thin-border cursor-pointer">
            {t('myPaths')}
        </SimpleCell>
    );
};


export { MyCategoriesContextItem };
