/**
 * TimerText
 *
 * @author: exode <hello@exode.ru>
 */

import moment from 'moment';

import React, { useEffect, useState } from 'react';


interface Props {
    mode: 'fromNow';
    date: Date;
}


const TimerText = (props: Props) => {

    const { mode, date } = props;

    const getTimerText = () => {
        switch (mode) {
            case 'fromNow':
                return moment(date).fromNow();
        }
    };

    const [ timeText, setTimeText ] = useState(getTimerText());

    useEffect(() => {
        const interval = setInterval(() => {
            const newTimeText = getTimerText();

            timeText !== newTimeText && setTimeText(newTimeText);
        }, 1000);

        return () => clearInterval(interval);
    }, [ date ]);

    return (
        <>
            {timeText}
        </>
    );
};


export { TimerText };
