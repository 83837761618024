/**
 * TournamentMiniCardSkeleton component
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { Skeleton } from '@mui/material';


const TournamentMiniCardSkeleton = () => {
    return (
        <div className="first:ml-2 m:mb-[2px] mb-[1px]">
            <div className="flex items-center justify-center w-[110px] h-[110px] mx-1.5 my-1 bg-button-muted rounded-xl">
                <Skeleton width={80} height={80} variant="circular"/>
            </div>

            <div className="mx-1.5 mb-1">
                <Skeleton variant="text" width={100} height={23.5}/>
                <Skeleton className="!-mt-1" variant="text" width={75} height={20}/>
            </div>
        </div>
    );
};


export { TournamentMiniCardSkeleton };
