/**
 * UseSession
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import { Router } from '@/services/Utils/Router';

import {
    MyUserSettingsDocument,
    MyUserSettingsQuery,
    useKillSessionByIdMutation,
    useKillSessionsMutation,
} from '@/codegen/graphql';


export const useSession = () => {

    const [ killOtherSessions, { loading: killOtherSessionsLoading } ] = useKillSessionsMutation({
        refetchQueries: [ { query: MyUserSettingsDocument } ],
        onError: () => {
            Router.replaceModal();
        },
    });

    const [ _killSessionById, { loading: killSessionByIdLoading } ] = useKillSessionByIdMutation({
        update(cache, { data }) {
            const cachedSettings = cache.readQuery<MyUserSettingsQuery>({ query: MyUserSettingsDocument });

            if (data && cachedSettings && !_.isEmpty(cachedSettings)) {
                cache.writeQuery<MyUserSettingsQuery>({
                    query: MyUserSettingsDocument,
                    data: {
                        __typename: cachedSettings.__typename,
                        userGetMyInfo: cachedSettings.userGetMyInfo,
                        passwordFindOneLast: cachedSettings.passwordFindOneLast,
                        sessionGetActiveSessions: cachedSettings.sessionGetActiveSessions
                            .filter(({ id }) => id !== data.sessionKillSessionById?.id),
                    },
                });
            }
        },
        onError(error) {
            console.error(error);
        },
    });

    const killSessionById = (sessionId: number) => {
        return _killSessionById({ variables: { sessionId } });
    };

    return {
        killSessionById,
        killSessionByIdLoading,
        killOtherSessions,
        killOtherSessionsLoading,
    };
};
