/**
 * ContentView
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { useI18n } from '@/hooks/core';
import { observer, Page } from '@/pages/Core';
import { UserAuthStore } from '@/store/user/auth';
import { SubscriptionStore } from '@/store/subscription/subscription';

import {
    TournamentFindOneQueryResult,
    TournamentStartMode,
    TournamentState,
    useWalletGetBalanceQuery,
} from '@/codegen/graphql';

import { Time } from '@/utils';
import { Countdown, If } from '@/cutils';
import { GqlResult } from '@/types/graphql';

import { Icon28CoinsOutline } from '@vkontakte/icons';
import { CellButton, Counter, UsersStack } from '@exode.ru/vkui';

import { UserAvatar } from '@/components/Atoms/UserAvatar';
import { stateText, TournamentStateIcon } from '@/components/Tournament/StateIcon/TournamentStateIcon';

import { SetPartsView, TournamentCardInfoInitialParts } from './SetPartsView';


interface Props {
    setParts: Dispatch<SetStateAction<typeof TournamentCardInfoInitialParts>>;
    tournament: GqlResult<TournamentFindOneQueryResult>['tournamentFindOne'];
}


const ContentView = observer((props: Props) => {

    const { setParts, tournament } = props;
    const { id, bet, lastMembers, countMembers, startAt, isMember, state, startMode } = tournament;

    const { t } = useI18n('modals.Tournament.CardInfo');

    const { data: balance } = useWalletGetBalanceQuery();

    const [ showCountdown, setShowCountdown ] = useState(!!isMember);

    const tournamentStartText = (scheduledText: string) => (
        startMode !== TournamentStartMode.Event
            ? scheduledText
            : (() => {
                switch (state) {
                    case TournamentState.Process:
                        return 'Турнир уже начался';

                    case TournamentState.Cancel:
                        return stateText[state];

                    default:
                        return 'Начнется, как все будут готовы';
                }
            })()
    );

    const isEnoughMoney = _.isUndefined(balance) || balance.walletGetBalance >= bet;

    useEffect(() => {
        SubscriptionStore.subscribeToTournament(id);
    }, [ id ]);

    return (
        <>
            <SetPartsView setShowCountdown={setShowCountdown} setParts={setParts} tournament={tournament}/>

            <If is={!!lastMembers?.length}>
                <UsersStack size="m" visibleCount={3} layout="vertical" photos={(
                    lastMembers?.map(({ id, profile }) => (
                        <UserAvatar size={32}
                                    key={id}
                                    userId={id}
                                    showUserOnlineBadge={false}
                                    name={profile!.firstName!}
                                    src={profile?.avatar?.medium || ''}/>)))}>
                    {lastMembers?.map((user) => user?.profile?.firstName).slice(0, 3).join(', ')}
                    <br/>
                    На турнир {t('tournamentEnroll', { count: countMembers })}
                    {' '}
                    {t('members', { count: countMembers })}
                </UsersStack>
            </If>

            <Page.Row mobileModalNoMx className="mt-4 mb-3">
                <CellButton subtitle="Время начала турнира"
                            onClick={() => setShowCountdown(!showCountdown)}
                            before={<TournamentStateIcon state={state} inModal/>}>
                    <If is={!showCountdown}>
                        <span>
                            {tournamentStartText(Time.parseRelative(startAt))}
                        </span>
                    </If>

                    <If is={showCountdown}>
                        <Countdown date={startAt} renderer={({ formatted: { hours, minutes, seconds }, completed }) =>
                            <span>
                                {tournamentStartText(
                                    completed
                                        ? 'Турнир уже начался'
                                        : `Осталось ${[ hours, minutes, seconds ].join(':')}`,
                                )}
                            </span>
                        }/>
                    </If>
                </CellButton>

                <div className="next-release">
                    <CellButton hasHover={false}
                                hasActive={false}
                                subtitle="Ставка турнира"
                                className="cursor-default"
                                before={<Icon28CoinsOutline/>}
                                after={UserAuthStore.isLoggedIn && !isEnoughMoney && (
                                    <Counter className="px-1 py-0.5 " mode="prominent" size="s">
                                        Недостаточно средств
                                    </Counter>
                                )}>
                        {bet} ex.
                    </CellButton>
                </div>
            </Page.Row>
        </>
    );
});


export { ContentView };
