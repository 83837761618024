/**
 * PriceTagPart
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { If } from '@/cutils';

import { Text } from '@exode.ru/vkui';
import { Icon20AddCircleFillGray, Icon20CheckCircleFillGreen } from '@vkontakte/icons';


interface Props {
    tag: string;
}


const PriceTagPart = (props: Props) => {

    const { tag } = props;

    const isNegativeTag = tag.startsWith('-');

    return (
        <div className="flex item">
            <div className="min-w-[16px] mt-0.5">
                <If is={!isNegativeTag}>
                    <Icon20CheckCircleFillGreen/>
                </If>

                <If is={isNegativeTag}>
                    <Icon20AddCircleFillGray style={{ transform: 'rotate(45deg)' }}/>
                </If>
            </div>

            <Text weight="regular" className={[
                'pointer-events-none px-2.5 py-0 rounded-lg min-h-[24px]',
                'text-primary whitespace-pre-line first-letter:capitalize word-break',
            ].join(' ')}>
                {(isNegativeTag ? tag.replace(/^-/, '') : tag)?.trim()}
            </Text>
        </div>
    );
};


export { PriceTagPart };
