/**
 * CommonMenuPart
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { useI18n, usePlatformDigitalIsOnSale } from '@/hooks/core';

import { useLocation } from '@/router/index';
import { RoutePathType } from '@/router/paths';
import { Auth, If, Link, Unauth } from '@/cutils';

import { Separator, SimpleCell } from '@exode.ru/vkui';
import { Icon24EducationOutline, Icon24SquareStackUpOutline } from '@vkontakte/icons';

import { SigningPart } from './SigningPart';
import { ProfilePart } from './ProfilePart';
import { MessagesMenuPart } from './MessagesMenuPart';


const CommonMenuPart = () => {

    const { t } = useI18n('components.Desktop.Navigation.Menu');

    const { route: { pageId } } = useLocation();

    const { digitalIsOnSale } = usePlatformDigitalIsOnSale();

    //TODO:school Move to page spaces
    const isCurrentPathClassName = (paths: RoutePathType[]) => (
        paths.includes(pageId as RoutePathType) ? 'bg-highlighted' : ''
    );

    return (
        <>
            <Auth>
                <ProfilePart/>
            </Auth>

            <Unauth>
                <SigningPart/>
            </Unauth>

            <Separator className="w-full my-3"/>

            <If is={digitalIsOnSale}>
                <Link pushPage={{ id: '/education' }}>
                    <SimpleCell expandable before={<Icon24EducationOutline/>} className={isCurrentPathClassName([
                        '/education',
                        '/courses/enrolled',
                        '/courses/:page([0-9]+)/:courseId([0-9]+)/study/:lessonId([0-9]+)',
                    ])}>
                        {t('education')}
                    </SimpleCell>
                </Link>
            </If>

            <Auth>
                <MessagesMenuPart isCurrentPathClassName={isCurrentPathClassName}/>
            </Auth>

            <If is={digitalIsOnSale}>
                <Link pushPage={{ id: '/catalog' }}>
                    <SimpleCell expandable before={<Icon24SquareStackUpOutline/>} className={isCurrentPathClassName([
                        '/catalog',
                        '/catalog/:page([0-9]+)',
                        '/courses/:page([0-9]+)/:courseId([0-9]+)',
                    ])}>
                        {t('catalog')}
                    </SimpleCell>
                </Link>
            </If>
        </>
    );
};


export { CommonMenuPart };
