/**
 * CreatePayoutFormSkeleton
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { Skeleton } from '@mui/material';


const CreatePayoutFormSkeleton = () => {
    return (
        <div className="flex flex-col gap-4 w-full max-w-[376px]">
            <Skeleton height={70} variant="rectangular" className="w-full rounded-[10px]"/>

            <div className="flex flex-col items-center gap-[40px] w-full h-[152px]">
                <div className="w-full">
                    <Skeleton width={100} height={16} variant="text" className="!mt-[12px] !mb-[3px]"/>

                    <Skeleton height={42} variant="rectangular" className="w-full rounded-[10px]"/>
                </div>

                <Skeleton width={153} height={24} variant="text" className="text-center"/>
            </div>

            <Skeleton height={66} variant="rectangular" className="w-full !opacity-0"/>

            <Skeleton height={42} variant="rectangular" className="w-full rounded-[10px] mb-1"/>
        </div>
    );
};


export { CreatePayoutFormSkeleton };
