/**
 * Use page leave
 *
 * @author: exode <hello@exode.ru>
 */

import { useEffect } from 'react';

import { DocumentEvent } from '@/types/window';


const usePageLeave = (cb: () => void, type: 'before' | 'after' = 'after') => {

    useEffect(() => {
        const callback = () => cb();

        const eventName = DocumentEvent[type === 'after'
            ? 'RouterBeforeUpdate'
            : 'RouterAfterUpdate'];

        document.addEventListener(eventName, callback);

        return () => {
            document.removeEventListener(eventName, callback);
        };
    }, []);
};


export { usePageLeave };
