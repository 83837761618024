/**
 * Input util
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';


/**
 * Тип события onInput
 */
export type InputType = React.ChangeEvent<HTMLInputElement>;

/**
 * Тип события onSelect
 */
export type SelectType = React.ChangeEvent<HTMLSelectElement>;


export const numberOrEmpty = (value: string) => {
    return !value ? '' : +value;
};

export const numberOrNull = (value: string) => {
    return (!value ? '' : +value) || null;
};

export const pointedNumber = (value: string | number) => {
    return +`${value}`.replace(/,/, '.');
};
