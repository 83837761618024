/**
 * SvgComponent util
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import React, { ReactElement } from 'react';


interface Props<P = Record<any, any>> {
    element: ReactElement<P>,
    svgProps?: P;
}


const SvgComponent = <P, >(props: Props<P>) => {

    const { element, svgProps } = props;

    return React.cloneElement(
        element,
        _.omitBy(
            { ...svgProps },
            _.isNull,
        ) as Partial<P>,
    );
};


export { SvgComponent };
