/**
 * UsersListView
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import React from 'react';

import { DebounceInput } from 'react-debounce-input';

import { ManageSchoolUsersPageStore } from '../store';

import { Page } from '@/pages/Core';
import { useLocation } from '@/router/index';
import { Graphql, If, Portal } from '@/cutils';
import { DebouncedInput } from '@/types/html';
import { Router } from '@/services/Utils/Router';
import { useI18n, usePagination } from '@/hooks/core';
import { SchoolUserFindManyQuery } from '@/codegen/graphql';

import { Icon24Users3Outline } from '@vkontakte/icons';
import { Counter, Header, List, Search } from '@exode.ru/vkui';

import { Pagination } from '@/components/Atoms/Pagination';
import { Placeholder } from '@/components/Atoms/Placeholder';
import { UserListSkeleton } from '@/components/Atoms/Skeleton/UserList';

import { UserRowItem } from '../items/UserRowItem';

import { useSchoolManageUsersFilter } from '../filter';


const UsersListView = () => {

    const { t } = useI18n('pages.Manage.School.Users.views');

    const { route: { params } } = useLocation();

    const { page, list, sort, onPageChange } = usePagination({
        store: ManageSchoolUsersPageStore,
        path: '/manage/school/users/:page([0-9]+)',
    });

    const { dtoFilter, urlFilter, setUrlFilter } = useSchoolManageUsersFilter(
        ManageSchoolUsersPageStore,
        {},
    );

    return (
        <>
            <Page.Row>
                <DebounceInput minLength={1}
                               maxLength={15}
                               className="mt-2"
                               debounceTimeout={500}
                               value={urlFilter.search}
                               element={Search as DebouncedInput}
                               data-test="users-list.search-input"
                               onChange={({ target: { value } }) => {
                                   setUrlFilter({ search: value, profile: { search: value } });
                                   params.page !== '1' && Router.updateParams({ page: '1' });
                               }}/>

                <SchoolUserFindManyQuery children={(result) => (
                    <>
                        <Graphql.Loading result={result}>
                            <UserListSkeleton/>
                        </Graphql.Loading>

                        <Graphql.Success result={result}>
                            {({ schoolUserFindMany: { items, pages, count } }) => (
                                <>
                                    <Header mode="secondary" aside={(
                                        <Counter size="m" mode="primary">
                                            {count}
                                        </Counter>
                                    )}>
                                        {t('allUsers')}
                                    </Header>

                                    <List className="flex flex-col gap-1.5 py-3">
                                        {items?.map((user) => (
                                            <UserRowItem key={user.id} user={user}/>
                                        ))}

                                        <If is={_.isEmpty(items)}>
                                            <Placeholder iconSize={96}
                                                         header={t('usersNotFound')}
                                                         className="min-h-[calc(100vh_-_416px)]"
                                                         icon={(
                                                             <Icon24Users3Outline className="text-accent opacity-[0.85]"/>
                                                         )}
                                                         content={(
                                                             <span className="m:w-full w-9/10 flex mx-auto">
                                                                 {t('tryToChangeSearchFilter')}
                                                             </span>
                                                         )}/>
                                        </If>
                                    </List>

                                    <Portal id="page:pagination">
                                        <Pagination sticky
                                                    boundaryCount={1}
                                                    currentPage={page}
                                                    totalPages={pages}
                                                    onChange={value => onPageChange(value, result.refetch)}/>
                                    </Portal>
                                </>
                            )}
                        </Graphql.Success>

                        <Graphql.Error result={result}/>
                    </>
                )} variables={{
                    list: { ...list },
                    filter: { ...dtoFilter },
                    sort: { ...sort },
                }}/>
            </Page.Row>

            <Portal renderer id="page:pagination"/>
        </>
    );
};


export { UsersListView };
