/**
 * Page utils
 *
 * @author: exode <hello@exode.ru>
 */

import React, { ReactElement, useLayoutEffect } from 'react';

import { PageStore } from '@/store/core/page';
import { UiService } from '@/services/Ui/Ui';


interface PageStickyProps {
    children: ReactElement;
    className?: string;
    onlyMobile?: boolean;
    onlyDesktop?: boolean;
}

interface PageCenteredProps {
    children: ReactElement;
}

interface PageRemoveBottomSafeSpaceProps {
    entireScreen?: boolean;
}


const PageSticky = (props: PageStickyProps) => {
    const { className, onlyMobile = false, onlyDesktop = false } = props;

    const mediaClass = onlyMobile
        ? 'm:sticky'
        : (onlyDesktop ? 'd:sticky' : 'sticky');

    return (
        <div className={[
            className,
            mediaClass,
            'z-10 top-header',
        ].join(' ')}>
            {props.children}
        </div>
    );
};

const PageCentered = (props: PageCenteredProps) => {
    return (
        <div className="flex flex-1 flex-col items-center justify-center">
            {props.children}
        </div>
    );
};

const PageRemoveBottomSafeSpace = (props: PageRemoveBottomSafeSpaceProps) => {

    const { entireScreen = false } = props;

    useLayoutEffect(() => {
        PageStore.merge({
            entireScreen,
            bottomSafeSpace: false,
        });

        if (entireScreen) {
            UiService.setAppHeightProperty('100dvh');
        }

        return () => {
            if (entireScreen) {
                UiService.setAppHeightProperty();
            }

            PageStore.merge({
                entireScreen: false,
                bottomSafeSpace: true,
            });
        };
    }, []);

    return (<></>);
};


const PageUtil = {
    Sticky: PageSticky,
    Centered: PageCentered,
    RemoveBottomSafeSpace: PageRemoveBottomSafeSpace,
};


export { PageUtil };
