/**
 * ManageUserCreateModal
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { Modal } from '@/cutils';
import { useI18n } from '@/hooks/core';

import { Title } from '@exode.ru/vkui';

import { CreateUserFormView } from '@/pages/Manage/School/Users/views/CreateUserFormView';


interface Props {
    id: string;
}


const ManageUserCreateModal = (props: Props) => {

    const { t } = useI18n('pages.Manage.School.SchoolUser');

    return (
        <Modal.Card id={props.id} className="modal:w-550" subheader={t('addUsersSubhead')} header={(
            <Title className="modal-title" level="2" weight="3">
                {t('addUsers')}
            </Title>
        )}>
            <Modal.Body isScrollable fullHeight className="p-3 pb-0">
                <CreateUserFormView/>
            </Modal.Body>
        </Modal.Card>
    );
};


export { ManageUserCreateModal };
