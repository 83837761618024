/**
 * PromotionBannerMobileApp
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { If, Link } from '@/cutils';
import { useI18n } from '@/hooks/core';
import { APP_URL, IS_NATIVE } from '@/root/src/env';

import { Button } from '@exode.ru/vkui';
import { Icon28SmartphoneStarsOutline } from '@vkontakte/icons';

import { InnerHtml } from '@/components/Atoms/InnerHtml';
import { GradientBanner, GradientBannerImage } from '@/components/Atoms/GradientBanner';


const PromotionBannerMobileApp = () => {

    const { t } = useI18n('components.Promotion.ContextBanners');

    return (
        <If is={!IS_NATIVE}>
            <GradientBanner imageTheme="light"
                            gradient="bg-content"
                            header={t('mobileApp')}
                            subheader={<InnerHtml content={t('downloadTheAppForIos&Android')}/>}
                            image={(
                                <GradientBannerImage className="top-0 right-0 mt-10 -ml-12 banner__image">
                                    <Icon28SmartphoneStarsOutline width={140}
                                                                  height={140}
                                                                  style={{ marginRight: -20 }}/>
                                </GradientBannerImage>
                            )}
                            actions={(
                                <Link blank toOuter={`${APP_URL}/app`}>
                                    <Button mode="primary" size="m">{t('download')}</Button>
                                </Link>
                            )}/>
        </If>
    );
};


export { PromotionBannerMobileApp };
