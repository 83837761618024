/**
 * FeedContentWrapper styled container
 *
 * @author: exode <hello@exode.ru>
 */

import styled from 'styled-components';


export const Container = styled.div`
  background-color: var(--background_page);
  min-height: 100vh;
`;
