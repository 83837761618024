/**
 * UseLesson
 *
 * @author: exode <hello@exode.ru>
 */

import { GqlResult } from '@/types/graphql';

import {
    CourseLessonMetaUpsertMutationResult,
    CreateLessonMetaCourseInput,
    useCourseLessonMetaUpsertMutation,
} from '@/codegen/graphql';


export const useLesson = () => {

    const [ _upsertMeta, { loading: upsertMetaLoading } ] = useCourseLessonMetaUpsertMutation({
        onError: error => console.error(error),
    });

    const upsertLessonMeta = (
        lessonId: number,
        meta: CreateLessonMetaCourseInput,
        onCompleted?: (meta: GqlResult<CourseLessonMetaUpsertMutationResult>['courseLessonMetaUpsert']) => void,
    ) => {
        return _upsertMeta({
            variables: { lessonId, meta },
            onCompleted: (data) => {
                onCompleted?.(data.courseLessonMetaUpsert);
            },
        });
    };

    return {
        upsertLessonMeta,
        upsertMetaLoading,
    };
};
