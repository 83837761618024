/**
 * EgeSubjectDatesSkeleton component
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { Skeleton } from '@mui/material';
import { List, SimpleCell } from '@exode.ru/vkui';


const EgeSubjectDatesSkeleton = () => {
    return (
        <List className="mt-[22px]">
            {Array(3).fill(0).map((_, index) => (
                <SimpleCell key={index}
                            disabled
                            before={<Skeleton className="!mr-4" variant="rectangular" width={30} height={30}/>}
                            description={<Skeleton className="" variant="text" width={140} height={18}/>}>
                    <Skeleton variant="text" width={100} height={18}/>
                </SimpleCell>
            ))}
        </List>
    );
};


export { EgeSubjectDatesSkeleton };
