/**
 * RangeRoutes
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { PageSpace } from '@/shared/types';

import { RouterItemsType } from '@/types/router';
import { lazyWithRetry } from '@/helpers/react';


const RangeListPage = lazyWithRetry(() => import('@/pages/Range/List'));


const RangeRoutes: RouterItemsType = {
    '/range': {
        view: <RangeListPage/>,
        auth: [],
        spaces: [
            PageSpace.MarketplacePlatform,
        ],
    },
};


export { RangeRoutes };
