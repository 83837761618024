/**
 * ManageProductDiscountsPage store
 *
 * @author: exode <hello@exode.ru>
 */

import { makeObservable } from 'mobx';

import { observer, PageStore } from '@/pages/Core';

import { SortDirection } from '@/codegen/graphql';

import { defaultDiscountsFilter, dtoFilterDiscountsTransformer } from './filter';


class ManageProductDiscountsPage extends PageStore {

    constructor() {
        super();
        makeObservable(this);
    }

    state = {
        list: { skip: 0, take: 25 },
        filter: dtoFilterDiscountsTransformer(defaultDiscountsFilter),
        sort: { id: SortDirection.Desc },
    };

}

const ManageProductDiscountsPageStore = new ManageProductDiscountsPage();


export { observer, ManageProductDiscountsPageStore };
