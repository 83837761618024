/**
 * UseLessonManage
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import { MutableRefObject } from 'react';

import { ApolloError, useApolloClient } from '@apollo/client';

import { ScrollHelper } from '@/helpers/ui';

import {
    CourseFindManyLessonsDocument,
    CourseFindManyLessonsQuery,
    CourseLessonAccessType,
    CourseLessonManageCreateMutationResult,
    CourseLessonManageUpdateMutationResult,
    CreateLessonCourseInput,
    UpdateLessonCourseInput,
    useCourseLessonManageCloneMutation,
    useCourseLessonManageCreateMutation,
    useCourseLessonManageDeleteMutation,
    useCourseLessonManageUpdateMutation,
    useCourseUpdateLessonOrderMutation,
} from '@/codegen/graphql';

import { DraggingIndexes } from '@/types/common';
import { CourseLessonItem, CourseLessonList, CourseLessonTreeItem } from '@/types/course';

import { useI18n } from '@/hooks/core';
import { useStore } from '@/pages/Core';
import { GqlResult } from '@/types/graphql';
import { ManageCourseContentPageStore } from '@/pages/Manage/Courses/Content/store';

import { Notify } from '@/cutils';
import { ArrayUtil } from '@/utils';
import { useCourseManage } from '@/hooks/apollo';
import { Router } from '@/services/Utils/Router';

import { Button } from '@exode.ru/vkui';


export const useLessonManage = (
    courseId: number,
) => {

    const { t } = useI18n('hooks.apollo.course');

    const { list, filter, sort } = useStore(ManageCourseContentPageStore);

    const apolloClient = useApolloClient();

    const { triggerUpdatePreviewEvent } = useCourseManage();

    const getVariables = () => ({
        courseId,
        list: { ...list },
        filter: { ...filter },
        sort: { ...sort },
    });

    const getCachedLessons = () => {
        const variables = getVariables();

        const cachedLessons = apolloClient.cache.readQuery<CourseFindManyLessonsQuery>({
            query: CourseFindManyLessonsDocument,
            variables,
        });

        return {
            variables,
            cachedLessons: cachedLessons?.courseLessonFindMany,
        };
    };

    const orderLessons = (lessons: CourseLessonItem[]) => {
        const treeList = ArrayUtil.groupByParent(
            lessons || [],
            'id',
            'parent.id',
        );

        return _.map(
            _.flatten(
                _.map(treeList, (e) => [ e, ...(e?.items || [])?.map((e) => e) ]),
            ),
            (e, i) => ({ ...e, order: i + 1 }),
        );
    };

    const initialLessonCreateData: CreateLessonCourseInput = {
        withPractice: false,
        scheduledPublishAt: undefined,
        name: t('thingOfANameToLesson'),
        description: t('thisLessonExplains'),
        accessType: CourseLessonAccessType.Participant,
    };

    const [ _createLesson, {
        loading: createLessonLoading,
        error: createLessonError,
    } ] = useCourseLessonManageCreateMutation({
        onError(error) {
            console.log(error);

            Notify.vkui({
                appearance: 'error',
                message: t('errorSomethingWentWrong'),
            });
        },
        update(cache, { data }) {
            triggerUpdatePreviewEvent('course', courseId);

            const { variables, cachedLessons } = getCachedLessons();

            if (!cachedLessons) {
                return console.warn('[Cache]: cachedLessons отсутствуют в кэше');
            }

            if (data) {
                cache.writeQuery<CourseFindManyLessonsQuery>({
                    query: CourseFindManyLessonsDocument,
                    variables,
                    data: {
                        courseLessonFindMany: {
                            __typename: 'ListLessonCourseOutput',
                            pages: cachedLessons?.pages ?? 1,
                            items: orderLessons([
                                ...(cachedLessons?.items ?? []),
                                data.courseLessonManageCreate,
                            ]),
                        },
                    },
                });
            }

            /** Refetch lessons count in course card */
            cache.evict({
                id: `CourseEntity:${courseId}`,
                fieldName: 'countLessons',
            });
        },
    });

    const createLesson = (
        courseId: number,
        lesson?: Partial<CreateLessonCourseInput> | null,
        onCompleted?: (
            lesson: GqlResult<CourseLessonManageCreateMutationResult>['courseLessonManageCreate'],
        ) => void,
    ) => {
        return _createLesson({
            variables: {
                courseId,
                lesson: {
                    ...initialLessonCreateData,
                    ...lesson,
                },
            },
            onCompleted: ({ courseLessonManageCreate: lesson }) => {
                onCompleted?.(lesson);
            },
        });
    };

    const [ _updateLesson, {
        loading: updateLessonLoading,
        error: updateLessonError,
    } ] = useCourseLessonManageUpdateMutation();

    const updateLesson = (
        courseId: number,
        lessonId: number,
        lesson: UpdateLessonCourseInput,
        onCompleted?: (lesson: GqlResult<CourseLessonManageUpdateMutationResult>['courseLessonManageUpdate']) => void,
        onError?: (error: ApolloError) => void,
    ) => {
        return _updateLesson({
            variables: {
                lessonId,
                lesson,
            },
            onCompleted: ({ courseLessonManageUpdate }) => {
                triggerUpdatePreviewEvent('course', courseId);

                const { variables, cachedLessons } = getCachedLessons();

                onCompleted?.(courseLessonManageUpdate);

                if (cachedLessons?.items) {
                    apolloClient?.cache.writeQuery<CourseFindManyLessonsQuery>({
                        variables,
                        query: CourseFindManyLessonsDocument,
                        data: {
                            courseLessonFindMany: {
                                ...cachedLessons,
                                items: orderLessons(cachedLessons.items),
                            },
                        },
                    });
                }
            },
            onError: (error) => {
                Notify.vkui({
                    appearance: 'error',
                    message: t('errorSomethingWentWrong'),
                });

                onError?.(error);
            },
        });
    };

    const [ deleteLesson, {
        loading: deleteLessonLoading,
        error: deleteLessonError,
    } ] = useCourseLessonManageDeleteMutation({
        onCompleted(data, options) {
            const lessonId = options?.variables?.lessonId as number;

            const { variables, cachedLessons } = getCachedLessons();

            if (data.courseLessonManageDelete && cachedLessons) {
                apolloClient.cache.writeQuery<CourseFindManyLessonsQuery>({
                    query: CourseFindManyLessonsDocument,
                    variables,
                    data: {
                        courseLessonFindMany: {
                            pages: cachedLessons.pages ?? 1,
                            items: orderLessons(
                                (cachedLessons.items ?? [])
                                    .filter((item) => item.id !== lessonId)
                                    .filter((item) => item.parent?.id !== lessonId),
                            ),
                        },
                    },
                });
            }

            if (!data.courseLessonManageDelete) {
                Notify.vkui({
                    appearance: 'error',
                    message: t('notAbleToDeleteLesson'),
                });
            }
        },
        update: (cache) => {
            /** Refetch lessons count in course card */
            cache.evict({
                id: `CourseEntity:${courseId}`,
                fieldName: 'countLessons',
            });
        },
        onError(error) {
            console.log(error);
        },
    });

    const changeLessonAccessType = (
        item: NonNullable<CourseLessonList>[number],
        accessType: CourseLessonAccessType,
        onCompleted?: () => void,
    ) => {
        return updateLesson(
            courseId,
            item.id,
            { accessType },
            onCompleted,
        );
    };

    const changeLessonWithContent = (
        lessonId: number,
        withContent: boolean | undefined,
    ) => {
        return updateLesson(
            courseId,
            lessonId,
            { withContent },
        );
    };

    const changeLessonWithPractice = (
        lessonId: number,
        withPractice: boolean | undefined,
    ) => {
        return updateLesson(
            courseId,
            lessonId,
            { withPractice },
        );
    };

    const [ cloneLesson, {
        loading: cloneLessonLoading,
        error: cloneLessonError,
    } ] = useCourseLessonManageCloneMutation({
        update: (cache, { data }) => {
            const { variables, cachedLessons } = getCachedLessons();

            if (data && cachedLessons) {
                cache.writeQuery<CourseFindManyLessonsQuery>({
                    query: CourseFindManyLessonsDocument,
                    variables,
                    data: {
                        courseLessonFindMany: {
                            pages: cachedLessons.pages ?? 1,
                            items: orderLessons([
                                ...(cachedLessons.items || []),
                                data.courseLessonManageClone,
                            ]),
                        },
                    },
                });
            }

            /** Refetch lessons count in course card */
            cache.evict({
                id: `CourseEntity:${courseId}`,
                fieldName: 'countLessons',
            });
        },
        onCompleted: ({ courseLessonManageClone }) => {
            Notify.vkui({
                message: t('lessonCopyIsCreated'),
                appearance: 'success',
                vkuiOptions: {
                    layout: 'horizontal',
                    action: (
                        <Button mode="secondary" className="!bg-white !text-black" data-test="copied-lesson.move-to">
                            {t('moveTo')}
                        </Button>
                    ),
                    onActionClick: () => Router.pushPage(
                        '/manage/course/:courseId([0-9]+)/content/:page([0-9]+)/:lessonId([0-9]+)/settings',
                        {
                            courseId: courseLessonManageClone.course.id.toString(),
                            lessonId: courseLessonManageClone.id.toString(),
                        },
                    ),
                },
            });
        },
        onError: error => {
            console.error(error);

            Notify.vkui({
                appearance: 'error',
                message: t('errorSomethingWentWrong'),
            });
        },
    });

    const [ reorder, {
        loading: reorderListLoading,
        error: reorderListError,
    } ] = useCourseUpdateLessonOrderMutation({
        onError(error) {
            console.log(error);

            Notify.vkui({
                appearance: 'error',
                message: t('errorSomethingWentWrong'),
            });
        },
    });

    const reorderList = async (
        lesson: CourseLessonItem,
        indexes: DraggingIndexes,
        list: CourseLessonList,
        treeList: CourseLessonTreeItem[],
        moduleRefs?: MutableRefObject<HTMLElement[]>,
    ) => {
        const { parent } = lesson;
        const { from, to } = indexes;

        if (list && !_.isEmpty(list)) {
            const parentOrder = parent ? _.findIndex(list, { id: parent.id }) + 1 : 0;

            const newIndexes = {
                from: from + parentOrder,
                to: to + parentOrder,
            };

            const items = orderLessons(
                _.flatten(
                    _.map(
                        ArrayUtil.reorderList<CourseLessonTreeItem[]>(
                            newIndexes,
                            parent ? list : treeList,
                        ),
                        (e) => [ e, ...(e?.items || [])?.map((e) => e) ],
                    ),
                ),
            );

            const { variables, cachedLessons } = getCachedLessons();

            if (cachedLessons) {
                apolloClient?.cache.writeQuery<CourseFindManyLessonsQuery>({
                    variables,
                    query: CourseFindManyLessonsDocument,
                    data: {
                        courseLessonFindMany: {
                            items,
                            pages: cachedLessons.pages || 1,
                        },
                    },
                });
            }

            if (!parent) {
                setTimeout(() => {
                    ScrollHelper.to(
                        (moduleRefs?.current.at(newIndexes.to)?.offsetTop || 0) + 2,
                        true,
                    );
                }, 100);
            }

            await reorder({
                variables: {
                    courseId,
                    lessonIds: items.map(({ id }) => id),
                },
            });
        }
    };

    return {
        createLesson,
        createLessonError,
        createLessonLoading,
        updateLesson,
        updateLessonError,
        updateLessonLoading,
        deleteLesson,
        deleteLessonError,
        deleteLessonLoading,
        cloneLesson,
        cloneLessonError,
        cloneLessonLoading,
        reorderList,
        reorderListError,
        reorderListLoading,
        changeLessonAccessType,
        changeLessonWithContent,
        changeLessonWithPractice,
    };
};
