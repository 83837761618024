/**
 * SigningPart
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { Link } from '@/cutils';
import { useI18n } from '@/hooks/core';

import { SimpleCell } from '@exode.ru/vkui';
import { Icon24DoorArrowLeftOutline, Icon28UserCircleOutline } from '@vkontakte/icons';


const SigningPart = () => {

    const { t } = useI18n('components.Desktop.Navigation.Menu.parts');

    return (
        <Link pushPage={{ id: '/login' }}>
            <SimpleCell className=""
                        subtitle={t('loginFailed')}
                        before={<Icon28UserCircleOutline/>}
                        after={<Icon24DoorArrowLeftOutline/>}>
                {t('loginInToAccount')}
            </SimpleCell>
        </Link>
    );
};


export { SigningPart };
