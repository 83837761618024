/**
 * Filter useTournamentFilter
 *
 * @author: exode <hello@exode.ru>
 */

import { TournamentStartMode, TournamentState, TournamentType } from '@/codegen/graphql';


export const defaultFilter = {
    bet: 0,
    onlyMySelectedSubjects: true,
    types: [ TournamentType.Public ],
    startModes: [ TournamentStartMode.Scheduled ],
    states: [
        TournamentState.Waiting,
        TournamentState.StartLobby,
    ],
};

export const dtoFilterTransformer = (filter: typeof defaultFilter) => filter;
