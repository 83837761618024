/**
 * IconSocial component
 *
 * @author: exode <hello@exode.ru>
 */

import React, { SVGProps } from 'react';


const IconSocial = (props: SVGProps<SVGSVGElement>) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" height="644pt" viewBox="0 0 644 644.216" width="644pt" {...props}>
            <path d="M322.11 146.363c32.331 0 58.542 26.211 58.542 58.547v29.274c0 32.332-26.21 58.543-58.543 58.543-32.336 0-58.546-26.211-58.546-58.543V204.91c0-32.336 26.21-58.547 58.546-58.547Zm0 0"
                  style={{
                      stroke: 'none',
                      fillRule: 'nonzero',
                      fill: 'gold',
                      fillOpacity: 1,
                  }}/>
            <path d="M190.379 29.273c8.086 0 14.637 6.551 14.637 14.637l58.547 322c0 8.082-6.555 14.637-14.637 14.637s-14.637-6.555-14.637-14.637l-58.543-322c0-8.086 6.55-14.637 14.633-14.637Zm0 0"
                  style={{
                      stroke: 'none',
                      fillRule: 'nonzero',
                      fill: '#ffea21',
                      fillOpacity: 1,
                  }}/>
            <path d="M29.379 351.273h292.73c16.164 0 29.27 13.106 29.27 29.274 0 97-78.633 175.633-175.637 175.633-97 0-175.633-78.633-175.633-175.633 0-16.168 13.106-29.274 29.27-29.274Zm0 0"
                  style={{
                      stroke: 'none',
                      fillRule: 'nonzero',
                      fill: '#ffbe00',
                      fillOpacity: 1,
                  }}/>
            <path d="M44.016 351.273h117.093c8.082 0 14.637 6.551 14.637 14.637 0 8.082-6.555 14.637-14.637 14.637H44.016c-8.082 0-14.633-6.555-14.633-14.637 0-8.086 6.55-14.637 14.633-14.637Zm0 0"
                  style={{
                      stroke: 'none',
                      fillRule: 'nonzero',
                      fill: '#ffea21',
                      fillOpacity: 1,
                  }}/>
            <path d="M205.02 409.816H87.926c-16.168 0-29.274 13.106-29.274 29.274s13.106 29.273 29.274 29.273h58.547c16.164.008 29.265 13.117 29.257 29.285-.003 16.16-13.101 29.254-29.257 29.262a29.002 29.002 0 0 0-27.356 19.766 173.17 173.17 0 0 0 115.172-.703V439.09c0-16.168-13.105-29.274-29.27-29.274Zm0 0"
                  style={{
                      stroke: 'none',
                      fillRule: 'nonzero',
                      fill: '#ffa300',
                      fillOpacity: 1,
                  }}/>
            <path d="M117.2 29.273h146.363c16.167 0 29.273 13.106 29.273 29.274 0 16.164-13.106 29.27-29.274 29.27H117.2c-16.168 0-29.273-13.106-29.273-29.27 0-16.168 13.105-29.274 29.273-29.274Zm0 0"
                  style={{
                      stroke: 'none',
                      fillRule: 'nonzero',
                      fill: '#ffbe00',
                      fillOpacity: 1,
                  }}/>
            <path d="M131.836 58.547h58.543c8.086 0 14.637 6.55 14.637 14.633 0 8.086-6.551 14.636-14.637 14.636h-58.543c-8.086 0-14.637-6.55-14.637-14.636 0-8.082 6.551-14.633 14.637-14.633Zm0 0"
                  style={{
                      stroke: 'none',
                      fillRule: 'nonzero',
                      fill: '#ffa300',
                      fillOpacity: 1,
                  }}/>
            <path d="M205.016 29.273h29.273c16.168 0 29.274 13.106 29.274 29.274 0 16.164-13.106 29.27-29.274 29.27h-29.273c-16.164 0-29.27-13.106-29.27-29.27 0-16.168 13.106-29.274 29.27-29.274Zm0 0"
                  style={{
                      stroke: 'none',
                      fillRule: 'nonzero',
                      fill: '#ffa300',
                      fillOpacity: 1,
                  }}/>
            <path d="m474.48 577.7-24.52-8.177c-23.905-7.968-40.034-30.34-40.034-55.539v-99.199c0-47.55-35.606-90.176-83.094-92.66-48.434-2.61-89.809 34.543-92.414 82.977a86.337 86.337 0 0 0-.129 4.714V513.98c0 25.204-16.125 47.575-40.031 55.543l-24.524 8.172c-17.824 5.946-27.46 25.211-21.515 43.04C152.848 634.628 165.852 644 180.496 644H463.72c18.793 0 34.023-15.234 34.023-34.023 0-14.645-9.37-27.649-23.262-32.278Zm0 0"
                  style={{
                      stroke: 'none',
                      fillRule: 'nonzero',
                      fill: 'gold',
                      fillOpacity: 1,
                  }}/>
            <path d="m474.48 577.7-24.523-8.177c-23.906-7.968-40.031-30.34-40.031-55.539V439.09c0-16.164-13.106-29.27-29.274-29.27-16.164 0-29.27 13.106-29.27 29.27v74.894c0 25.2-16.124 47.57-40.034 55.543l-18.512 6.168 6.008 2.004a34.025 34.025 0 0 1 23.265 32.278c0 17.105-12.832 30.66-29.273 33.066 1.559.437 3.144.758 4.75.957h166.137c18.789 0 34.023-15.23 34.023-34.023-.004-14.645-9.375-27.649-23.266-32.278Zm0 0"
                  style={{
                      stroke: 'none',
                      fillRule: 'nonzero',
                      fill: '#ffbe00',
                      fillOpacity: 1,
                  }}/>
            <path d="M161.11 29.273c-8.087 0-14.637 6.551-14.637 14.637l-58.547 322c0 8.082 6.55 14.637 14.636 14.637 8.083 0 14.637-6.555 14.637-14.637l58.543-322c.004-8.086-6.55-14.637-14.633-14.637Zm0 0"
                  style={{
                      stroke: 'none',
                      fillRule: 'nonzero',
                      fill: '#fffc74',
                      fillOpacity: 1,
                  }}/>
            <path d="M322.11 0c32.331 0 58.542 26.21 58.542 58.547V87.82c0 32.332-26.21 58.543-58.543 58.543-32.336 0-58.546-26.21-58.546-58.543V58.547C263.563 26.21 289.772 0 322.108 0Zm0 0"
                  style={{
                      stroke: 'none',
                      fillRule: 'nonzero',
                      fill: 'gold',
                      fillOpacity: 1,
                  }}/>
            <path d="M351.379 29.273c16.168 0 29.273 13.106 29.273 29.274V87.82c0 16.164-13.105 29.27-29.27 29.27-16.167 0-29.273-13.106-29.273-29.27V58.547c0-16.168 13.106-29.274 29.27-29.274Zm0 0"
                  style={{
                      stroke: 'none',
                      fillRule: 'nonzero',
                      fill: '#ffbe00',
                      fillOpacity: 1,
                  }}/>
            <path d="M292.836 263.453h58.543v87.82h-58.543ZM263.563 117.09h117.09c16.167 0 29.273 13.105 29.273 29.273s-13.106 29.274-29.274 29.274h-117.09c-16.167 0-29.273-13.106-29.273-29.274s13.106-29.273 29.274-29.273Zm0 0"
                  style={{
                      stroke: 'none',
                      fillRule: 'nonzero',
                      fill: 'gold',
                      fillOpacity: 1,
                  }}/>
            <path d="M483.11 29.273c8.081 0 14.632 6.551 14.632 14.637l58.547 322c0 8.082-6.55 14.637-14.637 14.637-8.082 0-14.636-6.555-14.636-14.637l-58.543-322c0-8.086 6.55-14.637 14.636-14.637Zm0 0"
                  style={{
                      stroke: 'none',
                      fillRule: 'nonzero',
                      fill: '#ffea21',
                      fillOpacity: 1,
                  }}/>
            <path d="M380.652 29.273h146.364c16.168 0 29.273 13.106 29.273 29.274 0 16.164-13.105 29.27-29.273 29.27H380.652c-16.164 0-29.273-13.106-29.273-29.27 0-16.168 13.11-29.274 29.273-29.274Zm0 0"
                  style={{
                      stroke: 'none',
                      fillRule: 'nonzero',
                      fill: '#ffea21',
                      fillOpacity: 1,
                  }}/>
            <path d="M395.29 58.547h87.82c8.081 0 14.636 6.55 14.636 14.633 0 8.086-6.555 14.636-14.637 14.636h-87.82c-8.082 0-14.637-6.55-14.637-14.636 0-8.082 6.555-14.633 14.637-14.633Zm0 0"
                  style={{
                      stroke: 'none',
                      fillRule: 'nonzero',
                      fill: '#ffbe00',
                      fillOpacity: 1,
                  }}/>
            <path d="M322.11 351.273h292.726c16.164 0 29.27 13.106 29.27 29.274 0 97-78.633 175.633-175.637 175.633-97 0-175.633-78.633-175.633-175.633 0-16.168 13.105-29.274 29.273-29.274Zm0 0"
                  style={{
                      stroke: 'none',
                      fillRule: 'nonzero',
                      fill: '#ffea21',
                      fillOpacity: 1,
                  }}/>
            <path d="M336.746 351.273H600.2c8.082 0 14.637 6.551 14.637 14.637 0 8.082-6.555 14.637-14.637 14.637H336.746c-8.086 0-14.637-6.555-14.637-14.637 0-8.086 6.551-14.637 14.637-14.637Zm0 0"
                  style={{
                      stroke: 'none',
                      fillRule: 'nonzero',
                      fill: '#fffc74',
                      fillOpacity: 1,
                  }}/>
            <path d="M453.836 29.273c-8.082 0-14.637 6.551-14.637 14.637l-58.547 322c0 8.082 6.555 14.637 14.637 14.637 8.086 0 14.637-6.555 14.637-14.637l58.547-322c0-8.086-6.555-14.637-14.637-14.637Zm0 0"
                  style={{
                      stroke: 'none',
                      fillRule: 'nonzero',
                      fill: '#ffff9d',
                      fillOpacity: 1,
                  }}/>
            <path d="M556.29 58.547c0 16.164-13.106 29.273-29.274 29.273-16.164 0-29.274-13.11-29.274-29.273 0-16.168 13.11-29.274 29.274-29.274 16.168 0 29.273 13.106 29.273 29.274ZM497.742 526.91c-16.164 0-29.27 13.106-29.273 29.27v.004c80.562-.051 150.75-54.926 170.23-133.094a28.757 28.757 0 0 0-23.863-13.274H468.473c-16.168 0-29.274 13.106-29.274 29.274s13.106 29.273 29.274 29.273h29.273c16.164.008 29.266 13.117 29.262 29.285-.008 16.157-13.106 29.254-29.266 29.262ZM351.379 175.637c16.168 0 29.273 13.105 29.273 29.273v29.274c0 16.164-13.105 29.27-29.27 29.27-16.167 0-29.273-13.106-29.273-29.27V204.91c0-16.168 13.106-29.273 29.27-29.273ZM292.836 117.09c16.164.008 29.266 13.117 29.262 29.285-.008 16.156-13.106 29.254-29.262 29.262h87.816c16.168 0 29.274-13.106 29.274-29.274 0-16.164-13.106-29.27-29.274-29.27ZM351.379 307.363c0 8.082-6.55 14.637-14.633 14.637-8.086 0-14.637-6.555-14.637-14.637s6.551-14.636 14.637-14.636c8.082 0 14.633 6.554 14.633 14.636Zm0 0"
                  style={{
                      stroke: 'none',
                      fillRule: 'nonzero',
                      fill: '#ffbe00',
                      fillOpacity: 1,
                  }}/>
        </svg>
    );
};

export { IconSocial };
