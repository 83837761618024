/**
 * SellerSelectPart
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { SellerStore } from '@/store/user/seller';

import { observer } from '@/pages/Core';
import { useI18n } from '@/hooks/core';

import { Graphql, Notify } from '@/cutils';
import { useSeller } from '@/hooks/apollo';
import { Router } from '@/services/Utils/Router';
import { ApolloError } from '@apollo/client/errors';

import { SellersOfCurrentUser } from '@/types/seller';

import { GetUserSellersQuery, SellerCurrentUserSellerQuery } from '@/codegen/graphql';

import { Skeleton } from '@mui/material';
import { Avatar, List, RichCell, Separator, Title } from '@exode.ru/vkui';
import { Icon24ChevronDown, Icon24ChevronUp, Icon56NftHeptagonDashedOutline } from '@vkontakte/icons';

import { Accordion } from '@/components/Atoms/Accordion';

import { SellerItem } from '../parts/SellerSelectItem';


interface Props {
    onError: (e: ApolloError) => void;
}


const SellerSelectPart = observer((props: Props) => {

    const { onError } = props;

    const { t } = useI18n('components.Desktop.Manage.ManageServicesList.parts');

    const { handleSelectSeller } = useSeller();

    const handleSellerItemClick = async (s: SellersOfCurrentUser[number]) => {
        await handleSelectSeller(
            s.id,
            s.currentManager.permissions,
            (state) => {
                const id = 'toggle-current-seller';

                if (state === 'set-ls') {
                    // TODO: Change to /manage/dashboard by push (if not on the same page)
                    Router.replacePage('/education');

                    SellerStore.toggleDrawer(false);

                    Notify.toast.loading(
                        t('switchTo', { orgName: s.organization.name }),
                        { id },
                    );
                }

                if (state === 'store-reset') {
                    Notify.toast.success(
                        t('youHaveSwitchedTo', { orgName: s.organization.name }),
                        { id },
                    );
                }
            },
        );
    };

    const placeholder = (
        <RichCell subhead={<Skeleton variant="text" width={150} height={15}/>} caption={(
            <Skeleton variant="text" width={150} height={15}/>
        )} before={(
            <Avatar mode="app" size={54}>
                <Icon56NftHeptagonDashedOutline width={32} height={32}/>
            </Avatar>
        )}>
            <Skeleton variant="text" width={180} height={20}/>
        </RichCell>
    );

    return (
        <SellerCurrentUserSellerQuery onError={onError} children={(result) => (
            <>
                <Graphql.Loading result={result}>
                    {placeholder}
                </Graphql.Loading>

                <Graphql.Success result={result}>
                    {({ sellerCurrentUserSeller: seller }) => (
                        <Accordion rawCell
                                   isExpanded
                                   headerSpacing="0 16px 0 0"
                                   iconOpen={<Icon24ChevronDown/>}
                                   iconClose={<Icon24ChevronUp/>}
                                   header={<SellerItem seller={seller}/>}>
                            <GetUserSellersQuery children={(result) => (
                                <>
                                    <Graphql.Success result={result}>
                                        {({ sellerFindManyByUser: { items } }) => (
                                            <List className="-mx-4">
                                                <Separator wide className="pt-3 pb-2"/>

                                                <Title level="2"
                                                       weight="regular"
                                                       className="px-4 text-secondary uppercase">
                                                    <span className="fs-12">
                                                        {t('availableOrganizations')}
                                                    </span>
                                                </Title>

                                                <List className="flex flex-col gap-2">
                                                    {items?.map((s) => (
                                                        <div key={s.id} onClick={() => handleSellerItemClick(s)}>
                                                            <SellerItem seller={s} active={seller?.id === s.id}/>
                                                        </div>
                                                    ))}
                                                </List>
                                            </List>
                                        )}
                                    </Graphql.Success>

                                    <Graphql.Error result={result}/>
                                </>
                            )}/>
                        </Accordion>
                    )}
                </Graphql.Success>

                <Graphql.Error result={result}>
                    {placeholder}
                </Graphql.Error>
            </>
        )}/>
    );
});


export { SellerSelectPart };
