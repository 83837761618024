/**
 * FiltersFormView
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import React, { useEffect } from 'react';

import { ConfigStore } from '@/store/core/config';
import { observer, useStore } from '@/pages/Core';
import { TournamentTabStore } from '@/pages/Tournament/Tournament/store';

import { Form, If } from '@/cutils';
import { ObjectUtil } from '@/utils';
import { TournamentType } from '@/codegen/graphql';

import { defaultFilter } from '@/pages/Tournament/Tournament/filter';

import { Router } from '@/services/Utils/Router';
import { TournamentService } from '@/services/Tournament/Tournament';

import { Icon24CancelOutline, Icon24Filter } from '@vkontakte/icons';
import { Button, ButtonGroup, FormItem, SegmentedControl, Slider, Text } from '@exode.ru/vkui';


const FiltersFormView = observer(() => {

    const { store, params, action, filter } = useStore(TournamentTabStore);

    const { temporaryFilter } = store.state;

    const isQueryEqualFilter = _.isEqual(temporaryFilter, defaultFilter);

    const handleSubmit = () => {
        const { types, bet, startModes } = temporaryFilter;

        const filterParams = {
            types: types.toString(),
            startModes: startModes.toString(),
            bet: bet.toString(),
        };

        if (!isQueryEqualFilter) {
            Router.pushPage(
                '/tournaments/:page([0-9]+)',
                { page: '1', ...filterParams },
            );

            store.mergeFilter({ ...temporaryFilter });
        }

        Router.replaceModal();
    };

    const resetFilters = () => {
        if (!isQueryEqualFilter) {
            Router.pushPage('/tournaments/:page([0-9]+)');
            store.mergeTemporaryFilter(defaultFilter);
            store.mergeFilter(defaultFilter);
        }

        Router.replaceModal();
    };

    useEffect(() => {
        TournamentService.getAndSetFilterByParams(store);
    }, [ params ]);

    useEffect(() => {
        ConfigStore.isDesktop && store.mergeTemporaryFilter(filter);
    }, [ filter ]);

    return (
        <Form onSubmit={handleSubmit}>
            <FormItem top="Тип турнира" className="pt-1">
                <SegmentedControl className="thin-border"
                                  onChange={action(store.setTournamentFilterTypes)}
                                  value={temporaryFilter.types[0]}
                                  options={[
                                      {
                                          label: 'Открытый',
                                          value: TournamentType.Public,
                                      },
                                      {
                                          label: 'Официальный',
                                          value: TournamentType.Official,
                                      },
                                  ]}/>
            </FormItem>

            <FormItem top="Размер ставки">
                <Slider value={temporaryFilter.bet}
                        onChange={value => store.handleTemporaryFilterChange('bet', value)}
                        step={100}
                        min={0}
                        max={1000}/>
                <div className="flex justify-between">
                    <Text className="text-accent" weight="regular">
                        {temporaryFilter.bet === 0 ? 'Любая' : temporaryFilter.bet}
                    </Text>

                    <Text className="text-secondary" weight="regular">
                        1000
                    </Text>
                </div>
            </FormItem>

            <FormItem>
                <ButtonGroup stretched mode="vertical">
                    <Button stretched mode="primary" size="l" type="submit" before={<Icon24Filter/>}>
                        Применить
                    </Button>

                    <If is={!ObjectUtil.isEqual(defaultFilter, filter)}>
                        <Button stretched
                                size="l"
                                mode="outline"
                                onClick={resetFilters}
                                before={<Icon24CancelOutline/>}>
                            Сбросить
                        </Button>
                    </If>
                </ButtonGroup>
            </FormItem>
        </Form>
    );
});


export { FiltersFormView };
