/**
 * Filter useChatListFilter
 *
 * @author: exode <hello@exode.ru>
 */

import { useUrlFilters } from '@/hooks/core';

import { ChatDialogsPageStore, defaultChatListFilter, dtoFilterChatListTransformer } from '@/pages/Chat/Dialog/store';


export const useChatListFilter = () => useUrlFilters(
    defaultChatListFilter,
    [],
    dtoFilterChatListTransformer,
    {
        store: ChatDialogsPageStore,
        filterField: 'chats',
    },
    {
        routerMethod: 'replacePage',
    },
);
