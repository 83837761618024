/**
 * ManageSchoolLegalPage store
 *
 * @author: exode <hello@exode.ru>
 */

import { makeObservable } from 'mobx';

import { observer, PageStore } from '@/pages/Core';

import { defaultFilter, dtoFilterTransformer } from './filter';


class ManageSchoolLegalPage extends PageStore {

    constructor() {
        super();
        makeObservable(this);
    }

    state = {
        list: {
            skip: 0,
            take: 15,
        },
        filter: dtoFilterTransformer(defaultFilter),
        sort: {},
    };

}

const ManageSchoolLegalPageStore = new ManageSchoolLegalPage();


export { observer, ManageSchoolLegalPageStore };