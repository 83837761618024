/**
 * CartFooterItem component
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { CartService } from '@/services/User/Cart';

import { useI18n } from '@/hooks/core';

import { Button } from '@exode.ru/vkui';
import { Icon16Cancel } from '@vkontakte/icons';

import { ConfirmButton } from '@/components/Atoms/ConfirmButton';

import { CourseCardProps } from '../CourseCard';
import { CommonFooterItem } from './CommonFooterItem';


interface Props {
    course: CourseCardProps['course'];
}


const CartFooterItem = (props: Props) => {

    const { course: { product } } = props;

    const { t } = useI18n('components.Course.CourseCard');

    return (
        <>
            <CommonFooterItem mode="cart" course={props.course}/>

            <ConfirmButton noWrapper
                           stopPropagation
                           replaceDelay={3000}
                           className="!shadow-none bg-hover"
                           callback={() => CartService.removeFromCart(product!.id)}
                           confirmProps={{
                               appearance: 'neutral',
                               children: t('confirm'),
                               before: <Icon16Cancel fill="var(--text-primary)"/>,
                           }}>
                <Button size="s"
                        mode="outline"
                        appearance="neutral"
                        data-test="course.remove-from-cart"
                        before={<Icon16Cancel fill="var(--text-primary)"/>}>
                    <span className="text-primary">
                        {t('delete')}
                    </span>
                </Button>
            </ConfirmButton>
        </>
    );
};


export { CartFooterItem };
