/**
 * Helper
 *
 * @author: exode <hello@exode.ru>
 */

export const courseCartColors = {
    light: [
        'bg-[#D0FA9B]',
        'bg-[#77CBFA]',
        'bg-[#D6D4F0]',
        'bg-[#B3D9EA]',
        'bg-[#F5F3C6]',
        'bg-[#D2FCB5]',

    ],
    dark: [
        'bg-gradient-to-r from-[#00a4ff2e] to-[#4989adb5]',
        'bg-gradient-to-r from-[#4136c533] to-[#9b93ff45]',
        'bg-gradient-to-r from-[#075a8038] to-[#3e5d698f]',
    ],
};

export const getCourseCartColors = (
    id: number,
    isDark = false,
) => {
    const colors = courseCartColors[isDark ? 'dark' : 'light'];

    return colors[id % Object.keys(colors).length];
};

