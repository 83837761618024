/**
 * BurgerMenuPart
 *
 * @author: exode <hello@exode.ru>
 */

import React, { MouseEventHandler } from 'react';

import { IconButton } from '@exode.ru/vkui';
import { Icon24MenuOutline } from '@vkontakte/icons';


interface Props {
    onClick?: MouseEventHandler<HTMLDivElement>;
}


const BurgerMenuPart = (props: Props) => {
    return (
        <IconButton onClick={props.onClick}>
            <Icon24MenuOutline className="text-accent"/>
        </IconButton>
    );
};


export { BurgerMenuPart };
