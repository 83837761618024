/**
 * CourseCardHeaderItem
 *
 * @author: exode <hello@exode.ru>
 */

import moment from 'moment';

import React from 'react';

import { useI18n } from '@/hooks/core';

import { Caption } from '@exode.ru/vkui';
import { Icon12UserTag } from '@vkontakte/icons';

import { ProductAccessListViewProps } from '../ProductAccessListView';


interface Props {
    access: NonNullable<ProductAccessListViewProps['accesses']>[number];
}


const CourseCardHeaderItem = (props: Props) => {

    const { access } = props;

    const { t } = useI18n('pages.Manage.School.Users.views.EditUserFormView');

    return (
        <div className="gap-2 tags-wrapper m-5 m:m-4 w-[calc(100%_-_128px)]">
            <Icon12UserTag/>

            <Caption level="2" className="overflow-hidden text-ellipsis whitespace-nowrap">
                {[
                    access.price?.title,
                    access.active
                        ? access.expireAt
                            ? access.isActive
                                ? t('accessUntil', {
                                    expireAt: moment(access.expireAt).format('D MMM YYYY'),
                                })
                                : t('accessExpired', {
                                    expireAt: moment(access.expireAt).format('D MMM YYYY'),
                                })
                            : t('withoutDateOfExpiration')
                        : t('accessInactivated'),
                ].filter((e) => e).join(' • ')}
            </Caption>
        </div>
    );
};


export { CourseCardHeaderItem };
