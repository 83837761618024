/**
 * BuyView
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import React, { MutableRefObject } from 'react';

import { APP_URL } from '@/root/src/env';
import { observer, Page } from '@/pages/Core';

import { HorizontalScroll } from '@exode.ru/vkui';
import { Icon28BlockCircleFillGray } from '@vkontakte/icons';

import { If, Link, Responsive } from '@/cutils';
import { useI18n, useI18nStatic, usePlatformDigitalIsOnSale } from '@/hooks/core';

import { Placeholder } from '@/components/Atoms/Placeholder';
import { CoursePriceCard } from '@/components/Product/PriceCard';
import { UnderlinePageRowHeader } from '@/components/Panel/Card';

import { CourseViewProps } from './HeadingView';


interface Props extends CourseViewProps {
    noHeader?: boolean;
    flexColReverse?: boolean;
    pricesRef?: MutableRefObject<HTMLDivElement | null>;
}


const PricesView = observer((props: Props) => {

    const {
        course,
        pricesRef,
        noHeader = false,
        flexColReverse = false,
    } = props;

    const { t } = useI18n('pages.Course.Information');
    const { t: tStatic } = useI18nStatic('static.dictionary.declension.course');

    const { product } = course || {};

    const { digitalIsOnSale } = usePlatformDigitalIsOnSale();

    return (
        <div className="relative">
            <div ref={pricesRef} className="absolute top-[-60px]"/>

            <If is={!!product?.isOnSale && digitalIsOnSale}>
                <If is={!noHeader}>
                    <UnderlinePageRowHeader textClassName="fs-20"
                                            title={t('availableTariffs')}
                                            headerClassName="d:!mt-[-4px] mb-2 d:px-0"
                                            count={`${product?.prices?.length || 0}`}/>
                </If>


                <If is={!_.isEmpty(product?.prices)}>
                    <Responsive.Desktop>
                        <If is={(product?.prices?.length ?? 0) > 2}>
                            <HorizontalScroll className="vk-rounded">
                                <div className="flex gap-4">
                                    {product?.prices?.map(price => (
                                        <CoursePriceCard key={price.id}
                                                         width="280px"
                                                         price={price}
                                                         course={course}
                                                         className="max-w-[50%]"
                                                         productId={product?.id}
                                                         currency={product?.currency}
                                                         flexColReverse={flexColReverse}/>
                                    ))}
                                </div>
                            </HorizontalScroll>
                        </If>

                        <If is={(product!.prices?.length ?? 0) <= 2}>
                            <div className={`grid grid-cols-${product?.prices?.length === 2 ? '2' : '1'} gap-4`}>
                                {product?.prices?.map(price => (
                                    <CoursePriceCard price={price}
                                                     key={price.id}
                                                     course={course}
                                                     productId={product?.id}
                                                     currency={product?.currency}
                                                     flexColReverse={flexColReverse}/>
                                ))}
                            </div>
                        </If>

                        <div className="h-4"/>
                    </Responsive.Desktop>

                    <Responsive.Mobile>
                        <div className="grid grid-cols-1 m:mb-3 gap-4">
                            {product?.prices?.map(price => (
                                <CoursePriceCard price={price}
                                                 key={price.id}
                                                 course={course}
                                                 productId={product?.id}
                                                 currency={product?.currency}
                                                 flexColReverse={flexColReverse}/>
                            ))}
                        </div>
                    </Responsive.Mobile>
                </If>
            </If>

            <If is={!product?.isOnSale || !digitalIsOnSale}>
                <Page.Row fullHeight>
                    <Placeholder iconSize={96}
                                 icon={<Icon28BlockCircleFillGray/>}
                                 header={`${tStatic(`single.nominated.${course.type}`)} ${t('notForSale')}`}
                                 content={(
                                     <p className="px-6 mt-3">
                                         {!digitalIsOnSale
                                             ? (
                                                 <>
                                                     {t('youCanOnlyEnrollOnOfficialWebsite')} {APP_URL?.split('://')?.[1]}
                                                 </>
                                             )
                                             : (
                                                 <>
                                                     {t('butYouCanAlwaysFindCourses')}
                                                     <Link pushPage={{ id: '/education' }}>
                                                         <span className="text-accent">{' '}{t('onSales')}</span>
                                                     </Link>
                                                 </>
                                             )
                                         }
                                     </p>
                                 )}/>
                </Page.Row>
            </If>
        </div>
    );
});


export { PricesView };
