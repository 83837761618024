/**
 * SessionInfo component
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { Time } from '@/utils';
import { useI18n } from '@/hooks/core';
import { SvgComponent } from '@/cutils';
import { sessionIconsAndName } from '@/types/session';

import { SessionLauncher } from '@/codegen/graphql';

import { Icon28HistoryBackwardOutline } from '@vkontakte/icons';


interface SessionAppNameProps {
    launcher: SessionLauncher;
    createdAt?: any;
}

interface SessionIconProps extends SessionAppNameProps {
    fill?: string;
    isAlive?: boolean;
}


const SessionIcon = (props: SessionIconProps) => {

    const sessionIconsAndNames = sessionIconsAndName();

    if (!props.isAlive) {
        return <Icon28HistoryBackwardOutline fill="var(--icon_secondary)"/>;
    }

    return (
        <SvgComponent element={sessionIconsAndNames[props.launcher].icon} svgProps={{ fill: props.fill }}/>
    );
};

const SessionAppName = (props: SessionAppNameProps) => {

    const { createdAt, launcher } = props;

    const sessionIconsAndNames = sessionIconsAndName();

    const { t } = useI18n('components.User.Settings.SessionInfo');

    return (
        <>
            {[
                sessionIconsAndNames[launcher].name,
                ' • ',
                t('from'),
                Time.parseDate(createdAt, 'D MMMM HH:mm'),
            ].join(' ')}
        </>
    );
};


export const SessionInfo = {
    Icon: SessionIcon,
    Name: SessionAppName,
};
