/**
 * App helper
 *
 * @author: exode <hello@exode.ru>
 */

import { SessionLauncher } from '@/codegen/graphql';
import { IS_NATIVE, IS_VK, OS_PLATFORM } from '@/root/src/env';


export const getSessionLauncher = () => {
    if (IS_NATIVE) {
        return OS_PLATFORM === 'android'
            ? SessionLauncher.Android
            : SessionLauncher.Ios;
    }

    if (IS_VK) {
        return SessionLauncher.Vk;
    }

    return SessionLauncher.Web;
};
