/**
 * UseProductParticipant
 *
 * @author: exode <hello@exode.ru>
 */

import { apolloClient } from '@/api/graphql';

import {
    CourseFindManyDocument,
    CourseProgressLessonStatus,
    ProductParticipantStatus,
    useProductParticipantUpdateMutation,
} from '@/codegen/graphql';


export const useProductParticipant = (productId: number) => {

    const [
        _updateProductParticipant,
        { loading: updateProductParticipantLoading },
    ] = useProductParticipantUpdateMutation();

    const finishCourse = (
        lessonId: number,
        myProgressId?: number,
    ) => {
        return _updateProductParticipant({
            variables: {
                productId,
                participant: {
                    completeFinalLesson: true,
                    status: ProductParticipantStatus.Completed,
                },
            },
            update: (cache, { data }) => {
                const participant = data?.productParticipantUpdate;

                if (!participant) {
                    return;
                }

                cache.modify({
                    id: `ProductParticipantEntity:${participant.id}`,
                    fields: {
                        status: () => ProductParticipantStatus.Completed,
                    },
                });

                for (const entity of [ 'CourseLessonPreview', 'CourseLessonEntity' ]) {
                    cache.modify({
                        id: `${entity}:${lessonId}`,
                        fields: {
                            myProgressStatus: () => CourseProgressLessonStatus.Completed,
                        },
                    });
                }

                if (myProgressId) {
                    cache.modify({
                        id: `CourseProgressEntity:${myProgressId}`,
                        fields: {
                            status: () => CourseProgressLessonStatus.Completed,
                        },
                    });
                }

                apolloClient.refetchQueries({
                    include: [
                        CourseFindManyDocument,
                    ],
                });
            },
        });
    };

    return {
        finishCourse,
        updateProductParticipantLoading,
    };
};
