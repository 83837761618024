/**
 * CalculateBeforePayoutItem
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { observer, PlatformStore } from '@/store/platform';

import { useI18n } from '@/hooks/core';

import { GqlResult } from '@/types/graphql';
import { PayoutOperationCalculateBeforePayoutQueryResult } from '@/codegen/graphql';

import { InfoRow } from '@exode.ru/vkui';

import { AmountText } from '@/components/Atoms/AmountText';
import { InfoRowWrapper } from '@/components/Atoms/InfoRowWrapper';


interface Props {
    calculation?: GqlResult<PayoutOperationCalculateBeforePayoutQueryResult>['payoutOperationCalculateBeforePayout'];
    isLoading?: boolean;
}


const CalculateBeforePayoutItem = observer((props: Props) => {

    const { calculation, isLoading } = props;

    const { COMMON } = PlatformStore;

    const { t } = useI18n('pages.Manage.Payout.items');

    return (
        <InfoRowWrapper cells={[
            <InfoRow header={t('creditedToAccount')}>
                <AmountText isLoading={isLoading}
                            className="text-primary"
                            skeletonProps={{ height: 20 }}
                            amount={calculation?.transferAmount || 0}
                            currency={COMMON?.organization.settings.currency}/>
            </InfoRow>,

            <InfoRow header={t('bankCommission')}>
                <AmountText isLoading={isLoading}
                            className="text-primary"
                            skeletonProps={{ height: 20 }}
                            amount={calculation?.transactionAmount || 0}
                            currency={COMMON?.organization.settings.currency}/>
            </InfoRow>,
        ]}/>
    );
});


export { CalculateBeforePayoutItem };
