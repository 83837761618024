/**
 * Use kinescope api
 *
 * @param {Props} props
 */

import _ from 'lodash';

import { useEffect, useRef } from 'react';

import toast from 'react-hot-toast';
import { useHotkeys } from 'react-hotkeys-hook';

import Player from '@kinescope/react-kinescope-player';

import { Time } from '@/utils';
import { ScrollHelper } from '@/helpers/ui';
import { DocumentEvent } from '@/types/window';

import { Icon24DeleteClockOutline } from '@vkontakte/icons';


export interface KinescopeApiProps {
    currentTime?: number;
}


export const useKinescopeApi = (props: KinescopeApiProps = {}) => {

    const { currentTime } = props;

    const kinescopeVideoRef = useRef<Player | null>(null);

    const getPlayerApi = () => kinescopeVideoRef.current;

    /** Fixer to start video on timecode click */
    const forceStartPlay = async () => {
        const player = getPlayerApi();

        if (!player) {
            return;
        }

        await Time.timer(100);

        await player.unmute();
        await player.play();
    };

    /** Handle timecode click */
    const onTimeCodeClick = async (e: any) => {
        const player = getPlayerApi();

        if (player && _.isFinite(e?.detail?.time)) {
            const time = e?.detail?.time;

            try {
                const duration = await player.getDuration();

                if (duration < time) {
                    return toast('Тайм код превышает время видео', {
                        icon: <Icon24DeleteClockOutline fill="var(--accent)"/>,
                    });
                }

                ScrollHelper.to(0, true);

                forceStartPlay();

                player.seekTo(time);
            } catch (e) {}
        }
    };

    /** Event handlers */
    const onPlay = () => {};

    const onPause = () => {};

    const onReady = async () => {
        const player = getPlayerApi();

        if (player && currentTime) {
            await player.seekTo(currentTime);
        }
    };

    useHotkeys('space', (e) => {
        e.preventDefault();

        kinescopeVideoRef.current?.isPaused()
            .then((isPaused) => {
                isPaused
                    ? kinescopeVideoRef.current?.play()
                    : kinescopeVideoRef.current?.pause();
            });
    }, [ kinescopeVideoRef ]);

    /** Handle timecodes */
    useEffect(() => {
        document.addEventListener(DocumentEvent.VideoTimecodeClick, onTimeCodeClick);

        return () => {
            document.removeEventListener(DocumentEvent.VideoTimecodeClick, onTimeCodeClick);
        };
    }, [ kinescopeVideoRef.current ]);

    return {
        onPlay,
        onReady,
        onPause,
        kinescopeVideoRef,
    };
};
