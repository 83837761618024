/**
 * RbacForbiddenPage
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { observer, UserAuthStore } from '@/store/user/auth';

import { Page } from '@/pages/Core';
import { useI18n } from '@/hooks/core';

import { Permission } from '@/codegen/graphql';

import { If } from '@/components/Utils';

import { ContextView } from './views/ContextView';
import { ForbiddenContentView } from './views/ForbiddenContentView';


interface Props {
    required: Permission[];
}


const RbacForbiddenPage = observer((props: Props) => {

    const { t } = useI18n('pages.Rbac.Forbidden');

    const permissionsIsLoaded = !!UserAuthStore.user?.permissions;

    return (
        <>
            <If is={permissionsIsLoaded}>
                <Page.Wrapper mode="fillable">
                    <Page.Head>
                        <Page.Header title={t('accessDenied')}/>
                    </Page.Head>

                    <Page.Content>
                        <Page.Row fullHeight>
                            <ForbiddenContentView required={props.required}/>
                        </Page.Row>
                    </Page.Content>

                    <Page.Context>
                        <ContextView/>
                    </Page.Context>
                </Page.Wrapper>
            </If>

            <If is={!permissionsIsLoaded}>
                <Page.Spinner/>
            </If>
        </>
    );
});


export { RbacForbiddenPage };
