/**
 * ChatMembersModal
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { Modal } from '@/cutils';
import { useI18n } from '@/hooks/core';

import { Title } from '@exode.ru/vkui';
import { ChatMembersView } from '@/pages/Chat/Dialog/views/ChatMembersView';


interface Props {
    id: string;
}


const ChatMembersModal = (props: Props) => {

    const { t } = useI18n('modals.Chat');

    return (
        <Modal.Card id={props.id} header={(
            <Title className="modal-title" level="2" weight="3">
                {t('chatParticipants')}
            </Title>
        )}>
            <Modal.Body isScrollable fullHeight>
                <ChatMembersView/>
            </Modal.Body>
        </Modal.Card>
    );
};


export { ChatMembersModal };
