/**
 * SelectView
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { SelectMimicryOptions } from '@/pages/Utils/SelectMimicry/store';

import { RoutePathType } from '@/router/paths';

import { If, Link } from '@/cutils';

import { Cell, List } from '@exode.ru/vkui';
import { Icon24Done } from '@vkontakte/icons';


interface Props {
    options: SelectMimicryOptions[];
    from: RoutePathType;
    selected: string | string[] | number[];
    merge: Function;
}


const SelectView = (props: Props) => {

    const { options, from, selected, merge } = props;

    return (
        <If is={!!options}>
            <Link backOrTo={from}>
                <List>
                    {options && options.map((option, i) => (
                        <Cell key={i}
                              onClick={() => merge({ selected: option.value })}
                              after={selected === option.value &&
                                  <Icon24Done fill="var(--accent)"/>}>
                            {option.name}
                        </Cell>
                    ))}
                </List>
            </Link>
        </If>
    );
};


export { SelectView };
