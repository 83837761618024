/**
 * Icon images
 *
 * @author: exode <hello@exode.ru>
 */

import { Language } from '@/shared/types';

import { IconRussia } from './IconRussia';
import { IconUzbekistan } from './IconUzbekistan';


const IconByLang = {
    [Language.Ru]: IconRussia,
    [Language.Uz]: IconUzbekistan,
};


export {
    IconRussia,
    IconUzbekistan,
    IconByLang,
};
