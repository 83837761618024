/**
 * UseStories
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import { ManageStoriesPageStore } from '@/pages/Manage/Stories/store';

import { useStore } from '@/pages/Core';
import { GqlResult } from '@/types/graphql';

import {
    CreateStoriesInput,
    StoriesEntity,
    StoriesFindManyDocument,
    StoriesFindManyQuery,
    StoriesFindOneQueryResult,
    StoriesStatus,
    StoriesType,
    UpdateStoriesInput,
    useStoriesCreateMutation,
    useStoriesUpdateOneMutation,
} from '@/codegen/graphql';


interface Props {
    stories?: GqlResult<StoriesFindOneQueryResult>['storiesFindOne'];
}


export const useStories = (props: Props) => {

    const { stories } = props;

    const { list, filter, sort } = useStore(ManageStoriesPageStore);

    const initialValues: CreateStoriesInput | StoriesEntity = stories
        ? _.pick(stories, [
            'url',
            'title',
            'type',
            'status',
            'duration',
            'previewImage',
        ]) as StoriesEntity
        : {
            url: '',
            title: '',
            duration: 3,
            previewImage: null,
            type: StoriesType.Image,
            status: StoriesStatus.Draft,
        };

    const [ _createStory, {
        loading: createLoading,
    } ] = useStoriesCreateMutation({
        onError: error => console.error(error),
        update: (cache, { data }) => {
            const variables = {
                list: { ...list },
                filter: { ...filter },
                sort: { ...sort },
            };

            const cachedStories = cache.readQuery<StoriesFindManyQuery>({
                variables,
                query: StoriesFindManyDocument,
            });

            if (!cachedStories) {
                return console.warn('[Cache]: cachedStories отсутствуют в кэше');
            }

            const { items, count } = cachedStories.storiesFindMany;

            data && cache.writeQuery<StoriesFindManyQuery>({
                variables,
                query: StoriesFindManyDocument,
                data: {
                    storiesFindMany: {
                        count: count || 1,
                        items: [ data.storiesManageCreate, ...(items ?? []) ],
                    },
                },
            });
        },
    });

    const createStory = (
        stories: CreateStoriesInput,
        onCompleted?: () => void,
    ) => {
        return _createStory({
            variables: { stories },
            onCompleted: () => {
                onCompleted?.();
            },
        });
    };

    const [ _updateStory, {
        loading: updateLoading,
    } ] = useStoriesUpdateOneMutation({
        onError: error => console.error(error),

    });

    const updateStory = (
        storiesId: number,
        stories: UpdateStoriesInput,
        onCompleted?: () => void,
    ) => {
        return _updateStory({
                variables: {
                    storiesId,
                    stories,
                },
                onCompleted: () => {
                    onCompleted?.();
                },
            },
        );
    };

    return {
        createStory,
        createLoading,
        updateStory,
        updateLoading,
        initialValues,
    };
};
