/**
 * ContentElementSubscription component
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { useContentElementListenUpdateSubscription } from '@/codegen/graphql';


interface Props {
    uuid: string;
}


const ContentElementSubscription = (props: Props) => {

    const { uuid } = props;

    useContentElementListenUpdateSubscription({
        variables: { contentElementUuid: uuid },
    });

    return <></>;
};


export { ContentElementSubscription };
