/**
 * UseTournamentSetPlayer
 *
 * @author: exode <hello@exode.ru>
 */

import { useSetIsPlayerMutation } from '@/codegen/graphql';


export const useTournamentSetPlayer = (tournamentId: number) => {

    const [
        _setIsPlayer,
        { loading: setIsPlayerLoading, error: setIsPlayerError },
    ] = useSetIsPlayerMutation();

    const setIsPlayer = async () => {
        await _setIsPlayer({
            variables: { tournamentId },
            update: (cache, { data }) => {
                data && cache.modify({
                    id: `TournamentMemberEntity:${data.tournamentLobbyMakeMemberPlayer?.id}`,
                    fields: {
                        isPlayer: value => data?.tournamentLobbyMakeMemberPlayer?.isPlayer ?? value,
                    },
                });
            },
            onError(error) {
                console.error(error);
            },
        });
    };

    return { setIsPlayer, setIsPlayerLoading, setIsPlayerError };
};
