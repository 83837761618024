/**
 * Api cookie
 *
 * @author: exode <hello@exode.ru>
 */

import { getCookie, removeCookie, setCookie } from 'react-use-cookie';

import { APP_DOMAIN } from '@/root/src/env';

import { ObjectUtil } from '@/utils';

import { CookieStorageKeyType } from '@/types/config';


class Cookie {

    /**
     * Установка cookie
     * @param {CookieStorageKeyType} key
     * @param value
     */
    static set(
        key: CookieStorageKeyType,
        value: any,
    ) {
        const { packValue } = ObjectUtil.packTools();

        setCookie(key, packValue(value), {
            Secure: true,
            domain: `.${APP_DOMAIN}`,
        });
    }

    /**
     * Получение cookie
     * @param {CookieStorageKeyType} key
     */
    static get(key: CookieStorageKeyType) {
        const { repackValue } = ObjectUtil.packTools();

        return repackValue(getCookie(key));
    }

    /**
     * Удаление cookie
     * @param {CookieStorageKeyType} key
     */
    static destroy(key: CookieStorageKeyType) {
        this.set(key, '');

        return removeCookie(key);
    }

}


export { Cookie };
