/**
 * MarketplaceHeaderView
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { useI18n } from '@/hooks/core';

import { Icon16Flash } from '@vkontakte/icons';

import { StartHeader } from '@/components/Landing/StartHeader';
import { TagInlineText } from '@/components/Atoms/TagInlineText';
import { CategoryTagSelector } from '@/components/Content/CategoryTagSelector';

import { useCatalogCourseFilter } from '../filter';


const MarketplaceHeaderView = () => {

    const { t } = useI18n('tabs.Catalog.views');

    const { urlFilter, setUrlFilter } = useCatalogCourseFilter();

    return (
        <StartHeader image={<div/>}
                     appearance="light-content"
                     titleProps={{ weight: '1' }}
                     className="d:min-h-[450px]"
                     caption={(
                         <TagInlineText gradient="green"
                                        isPointer={false}
                                        icon={<Icon16Flash/>}
                                        content={t('newPaths')}
                                        className="fs-14 !p-2 !pr-4 d:mt-5"/>
                     )}
                     title={(
                         <div style={{ lineHeight: '120%' }}
                              className="flex flex-col w-full d:fs-50 m:fs-30 m:text-center">
                             <span>{t('studyOrTeach')}</span>

                             <span className="bg-content-15--blur vk-rounded px-2 w-fit -ml-2">
                                 {t('everythingWillWorkOutHere')}
                             </span>
                         </div>
                     )}
                     actions={(
                         <div className="my-10 max-w-[750px] min-h-[120px]">
                             <CategoryTagSelector selected={urlFilter.categoryIds}
                                                  onChange={(categoryIds) => setUrlFilter({ categoryIds })}
                                                  itemProps={{
                                                      appearance: 'secondary',
                                                      selectedClassName: '!bg-black text-white',
                                                      unSelectedClassName: '!bg-white text-black',
                                                  }}/>
                         </div>
                     )}/>
    );
};


export { MarketplaceHeaderView };
