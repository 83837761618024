/**
 * TournamentLink component
 *
 * @author: exode <hello@exode.ru>
 */

import React, { ReactElement } from 'react';

import { TournamentState } from '@/codegen/graphql';

import { TournamentService } from '@/services/Tournament/Tournament';


interface Props {
    id: string | number;
    isMember: boolean;
    state: TournamentState;
    children: ReactElement;
}


const TournamentLink = (props: Props) => {

    const { children, id, state, isMember } = props;

    const onClick = () => {
        TournamentService.openAppropriatePageByState({
            id,
            state,
            isMember,
        }, 'pushPage');
    };

    const cloneProps = {
        onClick,
        'data-link': true,
    };

    return React.cloneElement(children, cloneProps);
};


export { TournamentLink };
