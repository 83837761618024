/**
 * PreviewVideoPart component
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { Url } from '@/utils';

import { Plyr } from '@/components/Atoms/Plyr';
import { Kinescope } from '@/components/Atoms/Kinescope';
import { FileUploadProps } from '@/components/Atoms/FileUpload';

import { useParseToPlyrSources, usePlyrApi } from '@/hooks/core';


interface Props {
    linkInput: string;
    previewVideo?: string;
    onFileChange?: FileUploadProps['onFileUpload'];
}


const PreviewVideoPart = (props: Props) => {

    const { linkInput, previewVideo, onFileChange } = props;

    const { provider, id: videoId } = Url.parseVideoUrl(linkInput);

    const { plyrVideoRef, getPlyrApi } = usePlyrApi({
        onReady: () => {
            /** Process only for YouTube link */
            if (!linkInput) {
                return;
            }

            const { duration = 0 } = getPlyrApi() || {};

            if (duration) {
                onFileChange?.([ {
                    id: 0,
                    location: linkInput,
                    meta: { size: 0, duration },
                } ]);
            }
        },
    });

    switch (provider) {
        case 'kinescope':
            return <Kinescope videoId={videoId}/>;

        default:
            return (
                <Plyr.Wrapper styles={{ roundedOnMobile: true }}>
                    <Plyr.Video ref={plyrVideoRef}
                                deps={[ previewVideo, linkInput ]}
                                source={useParseToPlyrSources(linkInput || previewVideo)}/>
                </Plyr.Wrapper>
            );
    }
};


export { PreviewVideoPart };
