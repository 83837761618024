/**
 * SpaceBlock
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';


interface Props {
    className?: string;
    count?: number;
}


const SpaceBlock = (props: Props) => {

    const { className, count = 1 } = props;

    return (
        <>
            {Array(count).fill(null).map((__, index) => (
                <div key={index} className={[ 'h-6 m:h-3', className ].join(' ')}/>
            ))}
        </>
    );
};


export { SpaceBlock };
