/**
 * BaseService
 *
 * @author: exode <hello@exode.ru>
 */

import { ApiResponseContract } from '@/shared/types';


class BaseService {

    /**
     * Парсинг результатов выполнения api метода
     * @param result
     * @returns {{exception: false, payload: Output, success: boolean, unsent: boolean}}
     * @protected
     */
    protected static parse<Output>(result: any) {
        return {
            success: !!result.success && !result.unsent && !result.exception,
            payload: (result.payload || {}) as Output,
            unsent: !!result.unsent,
            exception: !!result.exception && result.exception as ApiResponseContract,
        } as const;
    }

}


export { BaseService };
