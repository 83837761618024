/**
 * VkMiniAppService
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import toast from 'react-hot-toast';

import bridge, { AnyRequestMethodName, VKBridgeEvent } from '@vkontakte/vk-bridge';

import { PreferenceStore } from '@/store/preference/preference';

import { IS_VK } from '@/root/src/env';
import { Storage } from '@/api/storage';
import { DocumentEvent } from '@/types/window';
import { UserAuthStore } from '@/store/user/auth';

import { BaseService } from '@/services/Core/Base';
import { UserAuthService } from '@/services/User/Auth';

import { VkAuthMode, VkIdLoginMode } from '@/codegen/graphql';


class VkMiniAppService extends BaseService {

    /**
     * Конфигурация сервиса
     * @type {{storage: {authTokenKey: string}}}
     * @private
     */
    private static config = {
        storage: {
            authTokenKey: 'userAuthToken',
        },
    };

    /**
     * Регистрация сервиса
     */
    static register() {
        document.addEventListener(DocumentEvent.AuthStateChanged, async () => {
            if (UserAuthStore.isLoggedIn) {
                this.saveAuthToken(Storage.get('user:auth-token'));
            }
        });
    }

    /**
     * Работа с bridge
     * @param {AnyRequestMethodName} method
     * @param {Record<any, any>} options
     * @param {(data: any) => void} onSuccess
     * @param {(error: any) => void} onError
     */
    static sendBridge(
        method: AnyRequestMethodName,
        options: Record<any, any>,
        onSuccess?: (data: any) => void,
        onError?: (error: any) => void,
    ) {
        bridge.send(method, options).then((data) => {
            onSuccess?.(data);
        }).catch((error) => {
            onError?.(error);
            console.log(`[VK mini catch error, method: "${method}"]`, error);
        });
    }

    /**
     * Инициализация сервиса
     */
    static init() {
        if (!IS_VK) {
            return;
        }

        this.sendBridge('VKWebAppInit', {}, async (data) => {
            if (!data.result) {
                return;
            }

            await this.hydrateAppearanceTheme();

            const success = await this.hydrateAuthToken();

            if (!success) {
                this.sendBridge(
                    'VKWebAppGetSilentToken' as AnyRequestMethodName,
                    { app_id: +process.env.REACT_APP_VK_MINI_APP_ID! },
                    async (data) => {
                        const { success, token } = await UserAuthService.vkTokenLogin(VkAuthMode.VkMiniApp, {
                            mode: VkIdLoginMode.MiniApp,
                            payload: JSON.stringify(data.tokens?.[0] || {}),
                        });

                        if (success && token) {
                            await UserAuthStore.authByToken(token);
                        }

                        if (!success) {
                            toast.error('Что-то пошло не так :(');
                        }
                    },
                );
            }

            const subscriber = async (e: VKBridgeEvent<any>) => {
                if (e.detail.type === 'VKWebAppUpdateConfig') {
                    await this.hydrateAppearanceTheme();
                }
            };

            bridge.unsubscribe(subscriber);
            bridge.subscribe(subscriber);
        });
    }

    /**
     * Получение vk app конфига
     * @returns {Promise<void>}
     */
    static async getVkAppConfig(): Promise<Record<any, any>> {
        return new Promise((resolve) => {
            this.sendBridge('VKWebAppGetConfig', {}, (config) => resolve(config));
        });
    }

    /**
     * Сохранение user:auth-token внутрь хранилища ВК
     * @param {string} token
     * @returns {Promise<void>}
     */
    static saveAuthToken(token: string) {
        this.sendBridge('VKWebAppStorageSet', {
            key: this.config.storage.authTokenKey,
            value: token,
        });
    }

    /**
     * Восстановление темы приложения
     * @returns {Promise<void>}
     */
    static async hydrateAppearanceTheme() {
        const config = await this.getVkAppConfig();

        config && await PreferenceStore.setScheme(
            config.appearance === 'light'
                ? 'bright_light'
                : 'space_gray',
        );
    }

    /**
     * Восстановление авторизации через сохраненный токен в ВК
     * @returns {Promise<boolean>}
     */
    static hydrateAuthToken(): Promise<boolean> {
        return new Promise((resolve) => {
            this.sendBridge('VKWebAppStorageGet', {
                keys: [ this.config.storage.authTokenKey ],
            }, async (data) => {
                if (!data.keys) {
                    return resolve(false);
                }

                const tokenKey = _.find(data.keys, { key: this.config.storage.authTokenKey });

                const success = !!tokenKey?.value && (
                    await UserAuthStore.authByToken(tokenKey.value)
                );

                if (!success) {
                    this.saveAuthToken('');
                }

                resolve(success);
            });
        });
    }

}


export { VkMiniAppService };
