/**
 * UseTournamentCreate
 *
 * @author: exode <hello@exode.ru>
 */

import { CreateTournamentInput, TournamentCreateMutation, useTournamentCreateMutation } from '@/codegen/graphql';


const useTournamentCreate = () => {

    const [ createTournamentMutation, {
        loading: createTournamentLoading,
        error: createTournamentError,
    } ] = useTournamentCreateMutation();

    const createTournament = async (
        tournament: CreateTournamentInput,
        onCompleted: (data: TournamentCreateMutation) => void,
    ) => {
        await createTournamentMutation({
            variables: { tournament },
            onCompleted: (data) => onCompleted(data),
            onError: error => console.log(error.message),
        });
    };

    return {
        createTournament,
        createTournamentError,
        createTournamentLoading,
    };
};


export { useTournamentCreate };
