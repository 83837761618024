/**
 * AppRouteLink
 *
 * @author: exode <hello@exode.ru>
 */

import { Field, ObjectType } from '@nestjs/graphql';

import { FrontendRoutePathType } from '@/shared/types';


@ObjectType()
export class AppRouteLink {

    constructor(partial: Partial<AppRouteLink>) {
        Object.assign(this, partial);
    }

    @Field()
    path: FrontendRoutePathType;

    @Field(() => String)
    params: string;

}
