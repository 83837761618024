/**
 * UseManager
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import { useState } from 'react';

import toast from 'react-hot-toast';

import { FormikValues } from 'formik';

import { IS_SCHOOL } from '@/root/src/env';

import { SaveStoreDeepKeys, SaveStoreKeys, Saving, SavingStore } from '@/store/core/saving';

import { Notify } from '@/cutils';
import { useConfirm, useI18n } from '@/hooks/core';

import {
    CreateManagerSellerInput,
    CreateProductManagerSellerInput,
    SellerFindManyManagersDocument,
    SellerFindManyManagersQuery,
    SellerProductManagerFindManyDocument,
    SellerProductManagerFindManyQuery,
    UpdateManagerSellerInput,
    useSellerManagerCreateMutation,
    useSellerManagerDeleteMutation,
    useSellerManagerFindOneByUserQuery,
    useSellerManagerUpdateMutation,
    useSellerProductManagerCreateMutation,
    useSellerProductManagerFindOneByUserAndProductQuery,
    useSellerProductManagerUpdateMutation,
} from '@/codegen/graphql';

import { Router } from '@/services/Utils/Router';

import { ManageSellerManagersPageStore } from '@/pages/Manage/Seller/Managers/store';


export const useManager = () => {

    /** Seller manager */

    const { t } = useI18n('hooks.apollo.seller');

    const setManagerEditing = (editing = true) => {
        SavingStore.setEditing(
            SaveStoreKeys.School,
            SaveStoreDeepKeys.ManagerEdit,
            editing,
        );
    };

    const [ _createManager, {
        loading: createManagerLoading,
        error: createManagerError,
    } ] = useSellerManagerCreateMutation({
        onError: (error) => console.error(error),
        onCompleted: ({ sellerManagerCreate: { user: { profile } } }) => {
            toast.success(`${profile?.fullName || t('user')} ${t('isEmployeeNow')}`);

            Router.pushPage(
                IS_SCHOOL
                    ? '/manage/school/managers/:page([0-9]+)'
                    : '/manage/seller/managers/:page([0-9]+)',
            );
        },
        update: (cache, { data }) => {
            const options = {
                query: SellerFindManyManagersDocument,
                variables: {
                    list: ManageSellerManagersPageStore.state.list.managers,
                    filter: ManageSellerManagersPageStore.state.filter.managers,
                },
            };

            const cachedManagers = cache.readQuery<SellerFindManyManagersQuery>(options);

            if (!cachedManagers) {
                return console.warn('[Cache]: cachedManagers отсутствуют в кэше');
            }

            const { pages, count, items } = cachedManagers?.sellerManagerFindMany;

            if (data?.sellerManagerCreate) {
                cache.writeQuery<SellerFindManyManagersQuery>({
                    ...options,
                    data: {
                        sellerManagerFindMany: {
                            pages,
                            count: count + 1,
                            items: [ data.sellerManagerCreate, ...(items || []) ],
                        },
                    },
                });
            }
        },
    });

    const [ _updateManager, {
        loading: updateManagerLoading,
        error: updateManagerError,
    } ] = useSellerManagerUpdateMutation({
        onError: (error) => console.error(error),
    });

    const [ _deleteManager, {
        loading: deleteManagerLoading,
        error: deleteManagerError,
    } ] = useSellerManagerDeleteMutation({
        onError: (error) => console.error(error),
        onCompleted: () => {
            Notify.toast.success(t('employeeSuccessfullyDeleted'));
        },
        update: (cache, __, { variables }) => {
            const managerId = variables?.managerId;

            const optionsMangers = {
                query: SellerFindManyManagersDocument,
                variables: {
                    list: ManageSellerManagersPageStore.state.list.managers,
                    filter: ManageSellerManagersPageStore.state.filter.managers,
                },
            };

            const cachedManagers = cache.readQuery<SellerFindManyManagersQuery>(optionsMangers);

            if (!cachedManagers) {
                return console.warn('[Cache]: cachedManagers отсутствуют в кэше');
            }

            const { pages, count, items } = cachedManagers?.sellerManagerFindMany;

            cache.writeQuery<SellerFindManyManagersQuery>({
                ...optionsMangers,
                data: {
                    sellerManagerFindMany: {
                        pages,
                        count: count - 1,
                        items: [ ...(items?.filter(elem => elem.id !== managerId) || []) ],
                    },
                },
            });
        },
    });

    const createManager = (
        manager: CreateManagerSellerInput,
    ) => {
        return _createManager({
            variables: { manager },
        });
    };

    const updateManager = (
        managerId: number,
        manager: UpdateManagerSellerInput,
    ) => {
        return _updateManager({
            variables: {
                managerId,
                manager,
            },
            onCompleted: () => {
                setManagerEditing(false);
            },
        });
    };

    const deleteManager = (
        managerId: number,
    ) => {
        return _deleteManager({
            variables: {
                managerId,
            },
        });
    };

    /** Product manager */

    const [ _createProductManager, {
        loading: createProductManagerLoading,
        error: createProductManagerError,
    } ] = useSellerProductManagerCreateMutation({
        onError: (error) => console.error(error),
        onCompleted: ({ sellerProductManagerCreate: { manager: { user: { profile } } } }) => {
            toast.success(`${profile?.fullName || t('user')} ${t('isCollaboratorNow')}`);

            Router.pushPage(
                IS_SCHOOL
                    ? '/manage/school/product-managers/:page([0-9]+)'
                    : '/manage/seller/product-managers/:page([0-9]+)',
            );
        },
        update: (cache, { data }) => {
            const options = {
                query: SellerProductManagerFindManyDocument,
                variables: {
                    list: ManageSellerManagersPageStore.state.list.productManagers,
                    filter: ManageSellerManagersPageStore.state.filter.productManagers,
                },
            };

            const cachedProductManagers = cache.readQuery<SellerProductManagerFindManyQuery>(options);

            if (!cachedProductManagers) {
                return console.warn('[Cache]: cachedProductManagers отсутствуют в кэше');
            }

            const { pages, count, items } = cachedProductManagers?.sellerProductManagerFindMany;

            data?.sellerProductManagerCreate && cache.writeQuery<SellerProductManagerFindManyQuery>({
                ...options,
                data: {
                    sellerProductManagerFindMany: {
                        pages,
                        count: count + 1,
                        items: [ data.sellerProductManagerCreate, ...(items || []) ],
                    },
                },
            });
        },
    });

    const [ _updateProductManager, {
        loading: updateProductManagerLoading,
        error: updateProductManagerError,
    } ] = useSellerProductManagerUpdateMutation({
        onError: (error) => console.error(error),
    });

    const createProductManager = (productManager: CreateProductManagerSellerInput) => {
        return _createProductManager({
            variables: { productManager },
        });
    };

    const updateProductManager = (
        productManagerId: number,
        productManager: UpdateManagerSellerInput,
    ) => {
        return _updateProductManager({
            variables: { productManagerId, productManager },
            onCompleted: () => {
                setManagerEditing(false);
            },
        });
    };

    const useConfirmDeleteManager = (
        managerId: number,
        callback: () => void,
    ) => {
        const { openConfirm: openConfirmDeleteManager } = useConfirm({
            title: t('deletingEmployee'),
            subtitle: t('areYouReadyThatYouWantToDeleteEmployee'),
            buttons: {
                confirm: { text: t('delete') },
            },
            onConfirm: async () => {
                await deleteManager(managerId);

                callback();
            },
            isLoading: deleteManagerLoading,
        });

        return { openConfirmDeleteManager, deleteManagerLoading };
    };

    Saving.listenSetSaving(
        SaveStoreKeys.Seller,
        SaveStoreDeepKeys.ManagerEdit,
        [
            createManagerLoading,
            updateManagerLoading,
            deleteManagerLoading,
            createProductManagerLoading,
            updateProductManagerLoading,
        ],
        [
            createManagerError,
            updateManagerError,
            deleteManagerError,
            createProductManagerError,
            updateProductManagerError,
        ],
    );

    return {
        setManagerEditing,
        createManager,
        createManagerLoading,
        updateManager,
        updateManagerLoading,
        deleteManager,
        deleteManagerLoading,
        useConfirmDeleteManager,
        createProductManager,
        createProductManagerLoading,
        updateProductManager,
        updateProductManagerLoading,
    };
};


export const useFindExistManager = (props: {
    isProductManager: boolean;
    createManagerLoading?: boolean;
    createProductManagerLoading?: boolean;
}) => {
    const {
        isProductManager,
        createManagerLoading,
        createProductManagerLoading,
    } = props;

    const [ managerState, setManagerState ] = useState<{
        userId: null | number;
        productId: null | number;
    }>({ userId: null, productId: null });

    const {
        data: checkManagerData,
        loading: checkManagerLoading,
    } = useSellerManagerFindOneByUserQuery({
        fetchPolicy: 'network-only',
        skip: isProductManager || !managerState.userId,
        variables: {
            userId: managerState.userId ?? 0,
        },
    });

    const { sellerManagerFindOneByUser: foundManager } = checkManagerData || {};

    const {
        data: checkProductManagerData,
        loading: checkProductManagerLoading,
    } = useSellerProductManagerFindOneByUserAndProductQuery({
        fetchPolicy: 'network-only',
        skip: !isProductManager || !managerState.userId || !managerState.productId,
        variables: {
            userId: managerState.userId ?? 0,
            productId: managerState.productId ?? 0,
        },
    });

    const {
        sellerProductManagerFindOneByUserAndProduct: foundProductManager,
    } = checkProductManagerData || {};

    const checkIsDisabled = ({ user, product, permissions }: FormikValues) => {
        const conditions = [
            _.isEmpty(user),
            _.isEmpty(permissions),
            createManagerLoading,
            createProductManagerLoading,
            checkManagerLoading,
            checkProductManagerLoading,
            !!foundManager,
            !!foundProductManager,
            !!foundManager,
        ].filter(e => e);

        return !!conditions?.length || (isProductManager && _.isEmpty(product));
    };

    return {
        foundManager,
        foundProductManager,
        checkIsDisabled,
        managerState,
        setManagerState,
        checkProductManagerLoading,
    };
};
