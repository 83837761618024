/**
 * NotificationsSkeleton component
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { Page } from '@/pages/Core';

import { Skeleton } from '@mui/material';


interface Props {
    count?: number;
}


const NotificationsSkeleton = (props: Props) => {

    const { count = 10 } = props;

    return (
        <Page.Row fullSize>
            <div className="flex justify-between items-center mt-5 mb-[0.425rem] m:mb-1 px-6">
                <Skeleton className="rounded-sm bg-button-muted" variant="rectangular" width={180} height={16}/>
                <Skeleton className="rounded-sm bg-button-muted" variant="rectangular" width={100} height={16}/>
            </div>

            {Array(count).fill(0).map((_, index) => (
                <div key={index} className={[
                    'flex justify-start',
                    'p-3 pl-3 d:mx-3 mb-3 vk-rounded d:bg-button-muted',
                ].join(' ')}>
                    <Skeleton className="mr-3" variant="circular" width={48} height={48}/>

                    <div>
                        <Skeleton className="rounded-sm mt-1" variant="rectangular" height={10} width={150}/>
                        <Skeleton className="rounded-sm mt-1" variant="rectangular" height={15} width={230}/>
                        <Skeleton className="rounded-sm mt-1" variant="rectangular" height={8} width={115}/>
                    </div>

                    <Skeleton className="d:mr-3 absolute mt-2 right-3" variant="circular" width={21} height={21}/>
                </div>
            ))}
        </Page.Row>
    );
};


export { NotificationsSkeleton };
