/**
 * Meta get info
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import { PageParams } from 'router.tsx';

import { Url } from '@/utils';
import { APP_URL } from '@/root/src/env';

import { MetaContent, MetaKeysType, MetaPropsType } from '@/types/page';

import { RoutePathType } from '@/router/paths';
import { MetaRequestOptions } from '@/root/src/meta';


class MetaPageService {

    /**
     * Добавление default значений
     * @param {MetaKeysType} id
     * @param fetchedMeta - fetched from api and modified by middleware
     * @returns {any}
     */
    static mergeMeta(
        id: MetaKeysType,
        fetchedMeta: Partial<MetaPropsType> = {},
    ) {
        const { keywords = '' } = fetchedMeta;

        fetchedMeta.keywords = keywords + MetaContent.common.keywords;

        return {
            // ...MetaContent.pages[id],
            ...fetchedMeta,
        };
    }

    /**
     * Мета теги для страницы
     * @param {RoutePathType} id - page id of route
     * @param {string} path - pathname of url
     * @param qs - query string of url
     * @param {PageParams} params - query params + page path params
     * @param {MetaRequestOptions} options
     * @returns {Promise<PageMetaType>}
     */
    static async getMeta(
        id: RoutePathType,
        path: string,
        qs: string,
        params: PageParams,
        options?: MetaRequestOptions,
    ) {
        const {
            request,
            middleware,
            canonicalParams,
        } = MetaContent.pages[id];

        params = _.cloneDeep(params);

        const tags = request
            ? await request(id, params, options).catch((e) => e).then((r) => r || {})
            : {};

        const urlQuery = qs ? `?${qs}` : qs;

        /** Изменяем мета теги через middleware (в т.ч. полученные с api) */
        const computed = _.isFunction(middleware)
            ? await middleware({ id, params, tags })
            : {};

        const canonicalQuery = canonicalParams
            ? Url.objectToQuery(_.pick(params, canonicalParams), '&', true)
            : '';

        const defaults = {
            //...MetaContent.pages['/'],
            url: APP_URL + path + urlQuery,
            canonical: APP_URL + path + canonicalQuery,
        };

        const merged = this.mergeMeta(id, { ...tags, ...computed });

        return { ...(defaults as MetaPropsType), ...merged };
    }

}


export { MetaPageService };
