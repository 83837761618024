/**
 * PermanentPageContentTop
 *
 * Компонента находится над EpicRoot (на одном уровне)
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import ConfettiExplosion from 'react-confetti-explosion';

import { observer, PreferenceStore } from '@/store/preference/preference';

import { If } from '@/cutils';


const PermanentPageContentTop = observer(() => {
    return (
        <div className="flex justify-center w-full">
            <If is={PreferenceStore.confetti.show}>
                <ConfettiExplosion width={1200}
                                   zIndex={9999999}
                                   particleCount={50}
                                   style={{ transform: 'translate3d(0,0,0)' }}
                                   duration={PreferenceStore.confetti.duration}/>
            </If>
        </div>
    );
});


export { PermanentPageContentTop };
