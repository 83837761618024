/**
 * EnableNotificationsBanner
 *
 * @author: exode <hello@exode.ru>
 */

import React, { useContext } from 'react';

import { useI18n } from '@/hooks/core';

import { Button } from '@exode.ru/vkui';
import { Icon24NotificationOutline } from '@vkontakte/icons';

import { InnerHtml } from '@/components/Atoms/InnerHtml';
import { SessionMetaContext } from '@/components/App/SessionMetaProvider';
import { GradientBanner, GradientBannerImage } from '@/components/Atoms/GradientBanner';


const EnableNotificationsBanner = () => {

    const { t } = useI18n('components.Promotion.ContextBanners');

    const { getWebFcmToken, granted } = useContext(SessionMetaContext);

    if (granted) {
        return <></>;
    }

    return (
        <GradientBanner imageTheme="light"
                        gradient="bg-content"
                        header={t('turnOnNotification')}
                        subheader={<InnerHtml content={t('thisWayYouWillNotMissImportantEvents')}/>}
                        actions={(
                            <Button mode="primary" size="m" onClick={getWebFcmToken}>
                                {t('turnOn')}
                            </Button>
                        )}
                        image={(
                            <GradientBannerImage className="top-0 right-0 mt-5 banner__image">
                                <Icon24NotificationOutline width={130}
                                                           height={130}
                                                           style={{ transform: 'rotate(20deg)', marginRight: -38 }}/>
                            </GradientBannerImage>
                        )}/>
    );
};


export { EnableNotificationsBanner };
