/**
 * TournamentBlitzSelectModal
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { Graphql, Modal } from '@/cutils';

import { ContentCategorySlug } from '@/types/content';
import { ListProfileSubjectsQuery } from '@/codegen/graphql';

import { TournamentBlitzSelectSkeleton } from '@/components/Atoms/Skeleton/TournamentBlitzSelect';

import { Title } from '@exode.ru/vkui';

import { ContentView } from './views/ContentView';


interface Props {
    id: string;
}


const TournamentBlitzSelectModal = (props: Props) => {
    return (
        <Modal.Card id={props.id}
                    header={<Title className="modal-title" level="2" weight="3">Выбери предмет</Title>}
                    subheader={(
                        <span className="flex justify-center">
                            Будь готов — сразу перейдем к поиску соперника

                        </span>
                    )}>
            <Modal.Body fullHeight isScrollable>
                <ListProfileSubjectsQuery children={(result) => (
                    <>
                        <Graphql.Loading result={result}>
                            <TournamentBlitzSelectSkeleton/>
                        </Graphql.Loading>

                        <Graphql.Success result={result}>
                            {({ subjectFindMany: subjects }) => (
                                <ContentView subjects={subjects.items || []}
                                             filter={({ activeForTournament }) => activeForTournament}/>
                            )}
                        </Graphql.Success>

                        <Graphql.Error result={result}/>
                    </>
                )} variables={{
                    list: {},
                    filter: {
                        contentCategory: { slugs: [ ContentCategorySlug.Ege ] },
                    },
                }}/>
            </Modal.Body>
        </Modal.Card>
    );
};


export { TournamentBlitzSelectModal };
