/**
 * App school subscriptions
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { observer } from 'mobx-react';

import { IS_SCHOOL } from '@/root/src/env';

import { UserAuthStore } from '@/store/user/auth';

import { If } from '@/cutils';

import { SchoolSubscription } from './SchoolSubscription';
import { SchoolManageSubscription } from './SchoolManageSubscription';


const AppSchoolSubscriptions = observer(() => {
    return (
        <If is={IS_SCHOOL}>
            <If is={!UserAuthStore.isManager}>
                <SchoolSubscription/>
            </If>

            <If is={UserAuthStore.isManager}>
                <SchoolManageSubscription/>
            </If>
        </If>
    );
});


export { AppSchoolSubscriptions };
