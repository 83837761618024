/**
 * DialogModal
 *
 * @author: exode <hello@exode.ru>
 */

import React, { useEffect, useLayoutEffect } from 'react';

import { observer, useStore } from '@/pages/Core';
import { DialogModalStore } from './store';

import { useDialog } from '@/hooks/core';
import { useLocation } from '@/router/index';
import { Router } from '@/services/Utils/Router';

import { If, Modal } from '@/cutils';
import { Dialog, Slide } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';

import { DialogProvider } from '@/modals/Utils/Dialog/DialogContext';


interface Props {
    id: string;
}


const Transition = React.forwardRef((
    props: TransitionProps & { children: React.ReactElement },
    ref: React.Ref<unknown>,
) => (
    <Slide direction="up" ref={ref} {...props}>
        {props.children}
    </Slide>
));


const DialogModal = observer((props: Props) => {

    const { store } = useStore(DialogModalStore);
    const { route: { params } } = useLocation();

    const { handleModalClose } = useDialog(store.dialogModalProps);

    const {
        children,
        mode = 'vk',
        vkUiModalProps,
        muiDialogProps,
    } = store.dialogModalProps || {};

    useLayoutEffect(() => {
        if (!children) {
            handleModalClose();

            Router.replaceModalWithTimeout().then();
        }

        return () => {
            store.resetDialog();
        };
    }, []);

    useEffect(() => {
        if (mode === 'mui' && params.muiDialog !== 'true') {
            handleModalClose();
        }
    }, [ params.muiDialog ]);

    return (
        <DialogProvider value={{}}>
            <If is={mode === 'mui'}>
                <Dialog fullScreen
                        sx={{ zIndex: 10000 }}
                        open={store.muiIsOpened}
                        TransitionComponent={Transition}
                        onClose={() => handleModalClose()}
                        {...muiDialogProps}>
                    {children}
                </Dialog>
            </If>

            <If is={mode === 'vk'}>
                <Modal.Card id={props.id} onClose={handleModalClose} {...vkUiModalProps}>
                    <>{children}</>
                </Modal.Card>
            </If>
        </DialogProvider>
    );
});


export { DialogModal };
