/**
 * DesktopNavigationMenu component
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { Responsive, Sticky } from '@/cutils';

import { DesktopNavigationScroll } from '@/components/Desktop/Navigation';

import { FooterPart } from './parts/FooterPart';
import { MenuItemsPart } from './parts/MenuItemsPart';


const DesktopNavigationMenu = () => {
    return (
        <Responsive.DesktopOnly>
            <div className="relative">
                <Responsive.Width min={1275}>
                    <DesktopNavigationScroll/>
                </Responsive.Width>

                <Sticky container="#viewport-wrapper" top={56} bottom={0} width={230 + 32}>
                    <MenuItemsPart/>
                </Sticky>

                <FooterPart/>
            </div>
        </Responsive.DesktopOnly>
    );
};


export { DesktopNavigationMenu };
