/**
 * Storage types
 *
 * @author: exode <hello@exode.ru>
 */

import { FileTypeByMime } from '@/utils';

import { StorageFileType } from '@/codegen/graphql';


export const fileTypeMapping = {
    [FileTypeByMime.File]: StorageFileType.File,
    [FileTypeByMime.Image]: StorageFileType.Image,
    [FileTypeByMime.Video]: StorageFileType.Video,
};
