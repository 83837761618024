/**
 * UseSetFriendshipStatus
 *
 * @author: exode <hello@exode.ru>
 */

import { useConfirm, useI18n } from '@/hooks/core';

import { FriendshipStatus, useSetFriendshipStatusMutation } from '@/codegen/graphql';


export const useSetFriendshipStatus = () => {

    const [ setFriendshipStatus, { data: mutationData, loading } ] = useSetFriendshipStatusMutation();

    const setStatus = async (id: number, status: FriendshipStatus) => await setFriendshipStatus({
        variables: { relUserId: id, status },
        update: (cache, { data }) => {
            cache.modify({
                id: cache.identify(data!.friendshipSetFriendshipStatus),
                fields: {
                    friendshipGetFriendshipStatus: (value, { toReference }) => {
                        const from = toReference(cache.identify(data!.friendshipSetFriendshipStatus.from)!);
                        const to = toReference(cache.identify(data!.friendshipSetFriendshipStatus.to)!);
                        const status = data?.friendshipSetFriendshipStatus.status;

                        return { ...value, from, to, status };
                    },
                },
            });
        },
        onError(error) {
            console.error(error);
        },
    });

    return { setStatus, loading, mutationData };
};

export const useConfirmDeleteFriend = (userId: number) => {

    const { t } = useI18n('hooks.apollo.user');

    const { setStatus } = useSetFriendshipStatus();

    const { openConfirm: openConfirmDeleteFriend } = useConfirm({
        title: t('deleteFromFriends'),
        subtitle: t('areYouSureThatYouWantToDeleteUserFromFriends'),
        onConfirm: () => setStatus(userId, FriendshipStatus.Canceled),
        buttons: {
            confirm: { text: t('delete') },
        },
    });

    return { openConfirmDeleteFriend };
};
