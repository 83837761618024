/**
 * ModalCardIcon component
 *
 * @author: exode <hello@exode.ru>
 */

import React, { ReactElement } from 'react';

import { Avatar } from '@exode.ru/vkui';

import { SvgComponent } from '@/cutils';
import { PlaceholderProps } from '@/components/Atoms/Placeholder';

import { gradient as stylesGradient } from '@/assets/styles/modules/gradient';


interface Props {
    icon: ReactElement;
    size?: number;
    gradient?: string;
    className?: string;
    iconSize?: PlaceholderProps['iconSize'];
}


const ModalCardIcon = (props: Props) => {

    const {
        className,
        size = 110,
        iconSize = 72,
        gradient = stylesGradient.blue400ToBlue700Gradient,
    } = props;

    const icon = props.icon
        ? <SvgComponent element={props.icon} svgProps={{ width: iconSize, height: iconSize }}/>
        : undefined;

    return (
        <Avatar size={size} className={[
            gradient,
            className,
            'bg-gradient-to-br m:mt-6 d:mt-3',
        ].join(' ')}>
            {icon}
        </Avatar>
    );
};


export { ModalCardIcon };
