/**
 * AdaptiveSelect styled container
 *
 * @author: exode <hello@exode.ru>
 */

import styled from 'styled-components';

import { customScrollbar } from '@/styles/modules/scrollbar';


export const AdaptiveSelectScroller = styled.div`
  ${customScrollbar()}
  .vkuiGroup__inner {
    padding: 0;
  }
`;
