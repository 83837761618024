/**
 * BgIconPart
 *
 * @author: exode <hello@exode.ru>
 */

import React, { ReactElement } from 'react';

import { GradientBannerImage } from '@/components/Atoms/GradientBanner';


interface Props {
    icon: ReactElement;
}


const BgIconPart = (props: Props) => {

    const { icon } = props;

    return (
        <GradientBannerImage className="banner__image absolute top-[0px] right-[5px]">
            {icon}
        </GradientBannerImage>
    );
};


export { BgIconPart };
