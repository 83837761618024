/**
 * NotificationSnackbar component
 *
 * @author: exode <hello@exode.ru>
 */

import React, { forwardRef } from 'react';

import { SnackbarContent } from 'notistack';

import { ConfigStore } from '@/store/core/config';

import { Notify } from '@/cutils';
import { ModalDismissButton, Snackbar as VkUiSnackbar } from '@exode.ru/vkui';

import { ProfileNotificationCard, ProfileNotificationCardProps } from '@/components/Profile/Notifications/Card';

import { BaseSnackbarProps } from '../interfaces';

import { Container } from './NotificationSnackbar.styled';


export interface NotificationSnackbarProps extends BaseSnackbarProps {
    notification: ProfileNotificationCardProps['notification'];
}


const NotificationSnackbar = forwardRef<HTMLDivElement, NotificationSnackbarProps>((props: NotificationSnackbarProps, ref) => {

    const { options, notification, ...rest } = props;

    return (
        <SnackbarContent ref={ref} role="alert" {...rest}>
            <Container appearance="muted">
                <VkUiSnackbar layout="vertical"
                              duration={Notify.calcDuration(options)}
                              after={ConfigStore.isDesktop && <ModalDismissButton/>}
                              onClose={() => Notify.closeSnackbar(options?.key || props.id)}
                              onClick={() => Notify.closeSnackbar(options?.key || props.id)}
                              {...rest}>
                    <ProfileNotificationCard isInSnackbar notification={notification}/>
                </VkUiSnackbar>
            </Container>
        </SnackbarContent>
    );
});


export { NotificationSnackbar };
