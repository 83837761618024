/**
 * PopoutRoot util
 *
 * @author: exode <hello@exode.ru>
 */

import React, { ReactElement } from 'react';

import { observer, RouterStore } from '@/store/core/router';

import { PopoutWrapper } from '@exode.ru/vkui';


interface Props {
    children: ReactElement | ReactElement[];
}


const PopoutRoot = observer((props: Props) => {

    let popoutElement = null;

    const activePopoutId = RouterStore.activePopup;
    const popouts = React.Children.toArray(props.children) as ReactElement[];

    popouts.map((popout) => {
        if (popout.props.id === activePopoutId) {
            popoutElement = popout.props.withDarkness !== false
                ? <PopoutWrapper alignY="center" alignX="center">{popout}</PopoutWrapper>
                : popout;

            return;
        }
    });

    if (popoutElement || popoutElement === null) {
        RouterStore.setPopout(popoutElement);
    }

    return <></>;
});


export { PopoutRoot };
