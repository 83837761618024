/**
 * ProfileStatusEmojiPickerItem
 *
 * @author: exode <hello@exode.ru>
 */

import React, { useState } from 'react';

import { EmojiClickData } from 'emoji-picker-react';

import { If } from '@/cutils';

import { Icon24SmileAddOutline } from '@vkontakte/icons';
import { Dropdown } from '@exode.ru/vkui/dist/unstable';

import { EmojiPicker } from '@/components/Atoms/EmojiPicker';


interface Props {
    emoji?: string | null;
    onEmojiClick?: (emoji: string) => void;
}


const ProfileStatusEmojiPickerItem = (props: Props) => {

    const { emoji, onEmojiClick } = props;

    const [ pickerShown, setPickerShown ] = useState(false);

    return (
        <Dropdown action="click" placement="left" shown={pickerShown} onShownChange={setPickerShown} content={(
            <div className="absolute d:left-9 m:left-[-4px] d:bottom-[-270px] m:top-7 pb-4 z-20">
                <EmojiPicker className="d:mr-4 d:mb-2" onEmojiClick={(emojiObject: EmojiClickData) => {
                    setPickerShown(false);
                    onEmojiClick?.(emojiObject.emoji);
                }}/>
            </div>
        )}>
            <div className="cursor-pointer">
                <If is={!!emoji}>
                    <span className="fs-22">
                        {emoji}
                    </span>
                </If>

                <If is={!emoji}>
                    <Icon24SmileAddOutline/>
                </If>
            </div>
        </Dropdown>
    );
};


export { ProfileStatusEmojiPickerItem };
