/**
 * Use pagination
 *
 * @author: exode <hello@exode.ru>
 */

import { useLayoutEffect } from 'react';

import { useLocation } from '@/router/index';

import { ObservableQuery } from '@apollo/client/core/ObservableQuery';

import { RoutePathType } from '@/router/paths';
import { StoreCommonType } from '@/types/store';
import { Router } from '@/services/Utils/Router';

import { useStore } from '@/pages/Core';


interface Options<PageStoreType extends StoreCommonType> {
    store: PageStoreType;
    listField?: keyof PageStoreType['state']['list'] | null;
    filterField?: keyof PageStoreType['state']['filter'] | null;
    sortField?: keyof PageStoreType['state']['sort'] | null;
    mode?: 'page' | 'modal';
    path?: RoutePathType;
}


const usePagination = <PageStoreType extends StoreCommonType>(options: Options<PageStoreType>) => {
    const { store, listField, filterField, sortField, path, mode = 'page' } = options;

    const { route: { params } } = useLocation();
    const { filter, sort, pageId } = useStore(store);

    const { take } = listField ? store.state.list[listField] : store.state.list;

    const page = Number(params.page || 1);
    const list = { skip: (page - 1) * take, take };

    const onPageChange = async (page: number, refetch: ObservableQuery['refetch']) => {
        mode === 'page'
            ? Router.pushPage(path || pageId, { ...params, page: `${page}` })
            : Router.updateParams({ ...params, page: `${page}` });

        await refetch({
            list: { skip: (page - 1) * take, take },
            filter: filterField ? { ...filter[filterField] } : { ...filter },
            sort: sortField ? { ...sort[sortField] } : { ...sort },
        });
    };

    const updateStoreSkipTake = () => {
        Object.assign(!listField ? store.state.list : store.state.list[listField], list);
    };

    useLayoutEffect(() => {
        updateStoreSkipTake();
    });

    return { page, list, filter, sort, onPageChange };
};


export { usePagination };
