/**
 * Apollo cache
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import { Notify } from '@/cutils';
import { apolloClient } from '@/api/graphql';

import { useI18n } from '@/hooks/core/useI18n';
import { RefetchQueriesInclude } from '@apollo/client';
import { Modifier } from '@apollo/client/cache/core/types/common';


const useBatchUpdateCacheFields = <Entity, >(fields: Record<any, any>) => {
    return _.entries(fields).map(([ f, v ]) => ({ [f]: () => v })) as unknown as Modifier<Entity>;
};

const useRefreshDocs = () => {

    const { t } = useI18n('pages.Core');

    const refetchGqlDocs = async (
        key: string,
        refetchDocs: RefetchQueriesInclude,
    ) => {
        const id = `refetch-docs:${key}`;

        Notify.toast.loading(t('updating'), { id });

        try {
            await apolloClient.refetchQueries({
                include: refetchDocs,
            });
        } catch (e) {
            return Notify.toast.dismiss(id);
        }

        Notify.toast.success(t('successfullyUpdated'), { id, duration: 1100 });
    };

    return { refetchGqlDocs };
};


export {
    useRefreshDocs,
    useBatchUpdateCacheFields,
};
