/**
 * IconChemistry component
 *
 * @author: exode <hello@exode.ru>
 */

import React, { SVGProps } from 'react';


const IconChemistry = (props: SVGProps<SVGSVGElement>) => {
    return (
        <svg width={644} height={644} fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 644 644"
             {...props}>
            <g clipPath="url(#a)">
                <path d="M619.518 502.691 473.217 356.39a117.053 117.053 0 0 1-34.284-82.762V.242H204.849v273.386a117.046 117.046 0 0 1-34.28 82.762L24.264 502.691c-24.69 24.749-31.246 62.301-16.4 93.945 13.71 29.833 45.393 47.338 78.224 47.338h474.909c45.709 0 82.762-37.052 82.762-82.762a82.764 82.764 0 0 0-24.241-58.521Z"
                      fill="#EFF2FF"/>
                <path d="M409.932 273.629V.242H234.111V273.63c0 31.043 30.11 60.371 64.389 82.762 71.479 46.689 111.432-6.979 111.432-82.762Z"
                      fill="#CCD4FF"/>
                <path d="M234.111 58.763c0 16.158 13.097 29.258 29.259 29.258h29.262c32.32 0 58.521 26.201 58.521 58.521v29.263c0 16.158 13.101 29.258 29.259 29.258 16.162 0 29.259-13.1 29.263-29.258V88.021c0-32.32-26.202-58.521-58.522-58.521H263.37c-16.162 0-29.259 13.1-29.259 29.263Z"
                      fill="#B8C2FF"/>
                <path d="M86.092 614.718c-22.917 0-43.175-11.886-51.664-30.305-9.739-20.524-5.53-44.952 10.515-61.036l146.328-146.328a146.236 146.236 0 0 0 42.84-103.414v-39.306c0-16.158 13.096-29.259 29.258-29.259h117.043c16.161 0 29.262 13.101 29.262 29.259v39.266a146.335 146.335 0 0 0 42.863 103.481l146.301 146.301a53.41 53.41 0 0 1 15.221 44.8c-3.444 27.341-29.111 46.541-56.663 46.541H86.092Z"
                      fill="#FFEA21"/>
                <path d="M414.692 502.693a82.762 82.762 0 0 1 24.241 58.521 82.03 82.03 0 0 1-20.098 53.504h138.562c27.556 0 53.218-19.2 56.662-46.541a53.41 53.41 0 0 0-15.221-44.8L452.537 377.076a146.338 146.338 0 0 1-42.862-103.481v-39.266c0-16.162-13.101-29.263-29.259-29.263H263.37c-16.162 0-29.259 13.101-29.263 29.263v39.302a117.06 117.06 0 0 0 34.281 82.762l146.304 146.3Z"
                      fill="gold"/>
                <path d="M204.848.242h234.085c16.162 0 29.262 13.1 29.262 29.259 0 16.162-13.1 29.262-29.262 29.262H204.848c-16.158 0-29.258-13.1-29.258-29.262 0-16.158 13.097-29.259 29.258-29.259Z"
                      fill="#F8F9FF"/>
                <path d="M438.933.242H234.111c16.158 0 29.259 13.1 29.259 29.259 0 16.162-13.101 29.262-29.259 29.262h204.822c16.162 0 29.263-13.1 29.263-29.262S455.095.242 438.933.242ZM292.632 117.285c16.158 0 29.258 13.101 29.258 29.259v87.783c0 16.162-13.1 29.259-29.258 29.259-16.162 0-29.263-13.097-29.263-29.259v-87.783c0-16.158 13.101-29.259 29.263-29.259ZM292.688 322.109a29.462 29.462 0 0 0-26.873 17.342c-.219.495-.441.991-.668 1.483-6.61 14.76-.086 32.094 14.62 38.826 14.584 6.689 31.828.289 38.517-14.3.035-.074.07-.152.105-.23l.926-2.054c6.579-14.698 0-31.945-14.698-38.525a29.138 29.138 0 0 0-11.929-2.542Zm-40.922 57.37a29.154 29.154 0 0 0-20.688 8.571s-.972.968-2.667 2.667l-7.032 7.032-9.942 9.942-8.895 8.895-33.534 33.534c-11.43 11.429-11.43 29.954 0 41.383 11.425 11.425 29.953 11.425 41.379 0l62.07-62.074c11.426-11.426 11.426-29.954 0-41.379a29.243 29.243 0 0 0-20.691-8.571Z"
                      fill="#EFF2FF"/>
                <path d="M307.26.242h117.042c8.083 0 14.632 6.549 14.632 14.631 0 8.08-6.549 14.628-14.632 14.628H307.26c-8.079 0-14.627-6.549-14.627-14.628 0-8.082 6.548-14.63 14.627-14.63Z"
                      fill="#F8F9FF"/>
                <path d="M468.196 556.195h87.784c16.158 0 29.258 13.101 29.258 29.259 0 16.162-13.1 29.262-29.258 29.262h-87.784c-16.162 0-29.262-13.1-29.262-29.262 0-16.158 13.1-29.259 29.262-29.259Z"
                      fill="#FFBE00"/>
                <path d="M87.807 556.195h292.606c16.162 0 29.262 13.101 29.262 29.259 0 16.162-13.1 29.262-29.262 29.262H87.807c-16.158 0-29.258-13.1-29.258-29.262-.004-16.158 13.096-29.259 29.258-29.259Z"
                      fill="gold"/>
            </g>
            <defs>
                <clipPath id="a">
                    <path fill="#fff" d="M0 0h644v644H0z"/>
                </clipPath>
            </defs>
        </svg>
    );
};


export { IconChemistry };
