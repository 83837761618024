/**
 * PromotionBannerCourse
 *
 * @author: exode <hello@exode.ru>
 */

import React, { ReactElement } from 'react';

import { If, Link } from '@/cutils';
import { IS_VK } from '@/root/src/env';

import { usePlatformDigitalIsOnSale } from '@/hooks/core';

import { Button } from '@exode.ru/vkui';
import { Icon28BrainOutline } from '@vkontakte/icons';

import { GradientBanner, GradientBannerImage } from '@/components/Atoms/GradientBanner';


interface Props {
    header?: string;
    subheader?: ReactElement;
}

const PromotionBannerCourse = (props: Props) => {

    const { header = 'Курс на вуз', subheader } = props;

    const { digitalIsOnSale } = usePlatformDigitalIsOnSale();

    return (
        <GradientBanner imageTheme="light"
                        gradient="bg-content"
                        header={header}
                        subheader={subheader || <span>Записывайся на курсы,<br/>прокачивай мозги</span>}
                        image={(
                            <GradientBannerImage className="top-0 right-0 mt-6 banner__image">
                                <Icon28BrainOutline width={140}
                                                    height={140}
                                                    style={{ transform: 'rotate(20deg)', marginRight: -30 }}/>
                            </GradientBannerImage>
                        )}
                        actions={(
                            <If is={!IS_VK && digitalIsOnSale}>
                                <Link pushPage={{ id: '/education' }}>
                                    <Button mode="primary" size="m">Перейти</Button>
                                </Link>
                            </If>
                        )}/>
    );
};


export { PromotionBannerCourse };
