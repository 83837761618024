/**
 * Manage global
 *
 * @author: exode <hello@exode.ru>
 */

import { IS_SCHOOL } from '@/root/src/env';

import { useI18n } from '@/hooks/core';

import { Permission } from '@/codegen/graphql';
import { SellerManagerPermissions } from '@/types/manage';


export const getGlobalManagerPermissions: () => SellerManagerPermissions = () => {

    const { t } = useI18n('types.manage.permissions.manager.global');

    return [
        ...(IS_SCHOOL ? [
            {
                value: Permission.SchoolManageSettings,
                title: t('schoolSettingsManagement'),
                description: t('userCanChangeSchoolSettings'),
            },
            {
                value: Permission.SchoolManageUsers,
                title: t('schoolManageUsers'),
                description: t('userCanManageSchoolUsers'),
            },
            // {
            //     value: Permission.SchoolCustomizeSite,
            //     title: t('schoolDesignManagement'),
            //     description: t('userCanEditSchoolDesign'),
            // },
        ] : []),
        {
            value: Permission.SellerManage,
            title: IS_SCHOOL ? t('manageSellerSchool') : t('manageSeller'),
            description: IS_SCHOOL
                ? t('userCanViewAndUpdateSellerInfoSchool')
                : t('userCanViewAndUpdateSellerInfo'),
        },
        {
            value: Permission.SellerSales,
            title: IS_SCHOOL ? t('sellerSalesSchool') : t('sellerSales'),
            description: IS_SCHOOL
                ? t('userCanViewPaymentHistoryAndReportsSchool')
                : t('userCanViewPaymentHistoryAndReports'),
        },
        ...(IS_SCHOOL
            ? []
            : [
                {
                    value: Permission.SellerFinances,
                    title: t('sellerFinances'),
                    description: t('userCanViewBalanceAndPayments'),
                },
            ]),
        {
            value: Permission.SellerRefunds,
            title: IS_SCHOOL ? t('sellerReturnsSchool') : t('sellerReturns'),
            description: IS_SCHOOL
                ? t('userCanManageReturnsSchool')
                : t('userCanManageReturns'),
        },
        {
            value: Permission.SellerManageManagers,
            title: IS_SCHOOL ? t('manageSellerEmployeesSchool') : t('manageSellerEmployees'),
            description: IS_SCHOOL
                ? t('userCanManageOtherEmployeesSchool')
                : t('userCanManageOtherEmployees'),
        },
        {
            value: Permission.CourseManage,
            title: t('courseManagement'),
            description: t('userCanManageCourses'),
        },
        {
            value: Permission.CourseView,
            title: t('viewCourses'),
            description: t('userCanViewCoursesList'),
        },
        {
            value: Permission.CourseCurator,
            title: t('courseCurator'),
            description: t('userCanCheckHomework'),
        },
        {
            value: Permission.CourseReportingView,
            title: t('viewCourseReports'),
            description: t('userCanViewAllCourseReports'),
        },
        {
            value: Permission.CourseCommentsManage,
            title: t('manageCourseComments'),
            description: t('userCanManageCourseComments'),
        },
        {
            value: Permission.CourseStudentManage,
            title: t('manageCourseStudents'),
            description: t('userCanViewStudentInfo'),
        },
        {
            value: Permission.ProductManage,
            title: t('manageProducts'),
            description: t('userCanManageProducts'),
        },
        {
            value: Permission.TaskView,
            title: t('viewTaskDatabase'),
            description: t('userCanViewTasksFromDatabase'),
        },
        {
            value: Permission.TaskManage,
            title: t('manageTaskDatabase'),
            description: t('userCanCreateAndUpdateTasks'),
        },
        // {
        //     value: Permission.TournamentCreateOfficial,
        //     title: t('createOfficialTournament'),
        //     description: t('userCanCreateOfficialTournaments'),
        // },
    ] as const;
};
