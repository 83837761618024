/**
 * Responsive mixin functions
 *
 * @author: exode <hello@exode.ru>
 */

const breakpoints = {
    mobile: 'max-width: 480px',
    'gt-mobile': 'min-width: 481px',
    'lt-tablet': 'max-width: 767px',
    tablet: 'max-width: 768px',
    'gte-tablet': 'min-width: 768px',
    'gt-tablet': 'min-width: 769px',
    laptop: 'max-width: 1024px',
    'gt-laptop': 'min-width: 1025px',
    'desktop-sm': 'max-width: 1200px',
    'gt-desktop-sm': 'min-width: 1201px',
    desktop: 'max-width: 1201px',
    'gt-desktop': 'min-width: 1202px',
};

type ResponsiveMode = keyof typeof breakpoints;

const responsive = (modes: ResponsiveMode[]) => {
    const sizes: string[] = [];

    modes.forEach((mode) => {
        sizes.push(`(${breakpoints[mode]})`);
    });

    return '@media ' + sizes.join(', ');
};


export { responsive };
