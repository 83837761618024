/**
 * UiService
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import { FileImageDimensions } from '@/utils';
import { ConfigStore } from '@/store/core/config';
import { BaseService } from '@/services/Core/Base';

import twColors from 'tailwindcss/colors';
import { DocumentEvent } from '@/types/window';


class UiService extends BaseService {

    static interactionEvents = [
        'click',
        'touchstart',
        'keydown',
        'mousemove',
    ];

    /**
     * Регистрация сервиса
     */
    static register() {
        this.addEventListeners();
    }

    /**
     * Инициализация событий window
     */
    static addEventListeners() {
        this.onResize();

        window.addEventListener('resize', this.onResize);

        this.interactionEvents.map((eventName) => {
            document.addEventListener(eventName, _.throttle(() => {
                ConfigStore.setInteraction({
                    [eventName !== 'mousemove' ? 'lastClickAt' : 'lastMouseMoveAt']: new Date(),
                });

                const customEvent = new CustomEvent(DocumentEvent.SystemWindowInteracted);

                document.dispatchEvent(customEvent);
            }, 1000));
        });
    }

    /**
     * Check system theme is dark
     * @returns {boolean}
     */
    static get systemIsDark() {
        return window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
    }

    /**
     * Get safe area in JS
     * @returns {{top: number, left: number, bottom: number, right: number}}
     */
    static get getSafeAreaInsetValue() {
        const styles = getComputedStyle(document.documentElement);

        const get = (key: string) => {
            const size = parseFloat(styles.getPropertyValue(`--safe-area-inset-${key}`).trim());

            return !_.isNaN(size) ? size : 0;
        };

        return {
            top: get('top'),
            right: get('right'),
            bottom: get('bottom'),
            left: get('left'),
        };
    }

    /**
     * Обработка события resize window
     */
    static onResize() {
        ConfigStore.setWidth(window.innerWidth);
    }

    /**
     * Установка variable --app-height
     * @param {string} height
     */
    static setAppHeightProperty(
        height = '100dvh - env(safe-area-inset-top) - env(safe-area-inset-bottom)',
    ) {
        const html = document.documentElement;
        const root = document.getElementById('root');

        html.style.setProperty('--app-height', `${height}`);
        root?.style.setProperty('--app-height', `${height}`);
    }

    /**
     * Вызов события для мануального сохранения scroll
     */
    static saveScroll() {
        const event = new CustomEvent(DocumentEvent.RouterBeforeUpdate);

        document.dispatchEvent(event);
    }

    /**
     * Получение hex цвета по названию
     * @param {string} twColor
     * @param offset
     * @returns {any}
     */
    static parseTwColor(twColor: any, offset: number = 0) {
        const [ , color, opacity ] = twColor.split('-');

        // @ts-ignore @aslan
        return twColors[color][Math.min(+opacity + offset, 900)];
    }

    /**
     * Calculate aspect ratio on resize
     * @param {FileImageDimensions} dimensions
     * @param {'width' | 'height'} dimension
     * @param {number} maxSize
     * @returns {{width: number, height: number}}
     */
    static calcAspectRatio(
        dimensions: FileImageDimensions,
        dimension: 'width' | 'height',
        maxSize: number,
    ) {
        const result = { width: 0, height: 0 };

        result.width = dimension === 'height'
            ? (maxSize * dimensions.width) / dimensions.height
            : maxSize;

        result.height = dimension === 'width'
            ? (maxSize * dimensions.height) / dimensions.width
            : maxSize;

        return result;
    }

    /**
     * Adjust proportion
     * @param {number} maxWidth
     * @param {number} maxHeight
     * @param {number} aspectRatio
     * @returns {{width: number, height: number}}
     */
    static adjustProportion(
        maxWidth: number,
        maxHeight: number,
        aspectRatio: number,
    ) {
        /** First, we calculate the width based on the maximum height */
        let width = maxHeight * aspectRatio;
        let height = maxHeight;

        /** If the width is greater than the maximum, then we recalculate the height */
        if (width > maxWidth) {
            width = maxWidth;
            height = maxWidth / aspectRatio;
        }

        return { width: Math.round(width), height: Math.round(height) };
    }

}


export { UiService };
