/**
 * App chat subscriptions
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { observer, SubscriptionStore } from '@/store/subscription/subscription';

import { Auth } from '@/cutils';

import { ChatSubscription } from './ChatSubscription';
import { ChatSyncAfterConnect } from './ChatSyncAfterConnect';
import { ChatHandleDisconnect } from './ChatHandleDisconnect';
import { ChatTypingSubscription } from './ChatTypingSubscription';
import { ChatMessageSubscription } from './ChatMessagesSubscription';
import { ChatFolderUnreadCountTotalSubscription } from './ChatFolderUnreadCountTotalSubscription';


const AppChatSubscriptions = observer(() => {
    return (
        <>
            <Auth>
                <ChatSubscription/>
                <ChatHandleDisconnect/>
                <ChatSyncAfterConnect/>
            </Auth>

            {[ ...SubscriptionStore.chatFolderUnreadCount ].map((folder) => (
                <ChatFolderUnreadCountTotalSubscription key={folder} folder={folder}/>
            ))}

            {[ ...SubscriptionStore.chatIds ].map((chatId) => (
                <ChatMessageSubscription key={chatId} chatId={chatId}/>
            ))}

            {[ ...SubscriptionStore.chatIdsForTyping ].map((chatId) => (
                <ChatTypingSubscription key={chatId} chatId={chatId}/>
            ))}
        </>
    );
});


export { AppChatSubscriptions };
