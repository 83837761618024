/**
 * EditorJsOutput component
 *
 * @author: exode <hello@exode.ru>
 */

import React, { memo } from 'react';

import Output from 'editorjs-react-renderer';

import { ChecklistPart } from './parts/ChecklistPart';
import { CodePart } from './parts/CodePart';
import { DelimiterPart } from './parts/DelimiterPart';
import { ImagePart } from './parts/ImagePart';
import { LinkPart } from './parts/LinkPart';
import { ListPart } from './parts/ListPart';
import { MathRendererPart } from './parts/MathRendererPart';
import { ParagraphPart } from './parts/ParagraphPart';
import { QuotePart } from './parts/QuotePart';
import { TablePart } from './parts/TablePart';

import { Container } from './EditorJsOutput.styled';
import { classNames, style } from './EditorJsOutput.style';


const EditorJsOutput = memo((props: any) => {
    return (
        <Container {...props} data-editor-output>
            <Output {...props} style={style} classNames={classNames} renderers={{
                checklist: ChecklistPart,
                raw: CodePart,
                delimiter: DelimiterPart,
                image: ImagePart,
                linktool: LinkPart,
                list: ListPart,
                math: MathRendererPart,
                paragraph: ParagraphPart,
                quote: QuotePart,
                table: TablePart,
            }}/>
        </Container>
    );
});


EditorJsOutput.displayName = 'EditorJsOutput';


export { EditorJsOutput };
