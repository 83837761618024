/**
 * DesktopManageServicesList styled container
 *
 * @author: exode <hello@exode.ru>
 */

import styled from 'styled-components';


export const Container = styled.div`
    top: 0;
    right: 0;
    position: fixed;
    overflow: hidden;
    z-index: 9999;

    --text_secondary: #dddddd;

    .wrapper {
        height: 100vh;
        width: 90px;
        overflow: hidden;
        overflow-y: auto;
    }

    .menu-icon {
        color: rgba(255, 255, 255, 0.7);
    }

    body[scheme=bright_light] & {
        --input_border: #252525;
        background-image: linear-gradient(to top, #313131, #252525, #25253b);
    }

    body[scheme=space_gray] & {
        background-image: linear-gradient(to top, var(--background_content), #212123, var(--background_content));
    }

    .SimpleCell {
        border-radius: 0;
    }
`;

export const MenuItem = styled.div`
    .SimpleCell {
        height: 68px;
        padding-left: 4px;
        padding-right: 4px;
    }
`;
