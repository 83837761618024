/**
 * QuestionRoutes
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { PageSpace } from '@/shared/types';

import { RouterItemsType } from '@/types/router';
import { lazyWithRetry } from '@/helpers/react';


const QuestionListMinePage = lazyWithRetry(() => import('@/pages/Question/ListMine'));


const QuestionRoutes: RouterItemsType = {
    '/my-questions': {
        view: <QuestionListMinePage/>,
        auth: [],
        spaces: [
            PageSpace.SchoolPlatform,
            PageSpace.MarketplacePlatform,
        ],
    },
};


export { QuestionRoutes };
