/**
 * Time
 *
 * @author: exode <hello@exode.ru>
 */

const moment = require('moment');


class Time {

    /**
     * Using setTimeout in async functions
     * @param {number} ms
     * @returns {Promise<boolean>}
     */
    static async timer(ms: number) {
        return new Promise<boolean>(resolve => {
            setTimeout(() => resolve(true), ms);
        });
    }

    /**
     * Parse date by format
     * @param {Date | string} date
     * @param {string} format
     * @returns {string}
     */
    static parseDate(date: Date | string, format: string = 'D MMM YYYY HH:mm'): string {
        return moment(date, moment.ISO_8601).format(format);
    }

    /**
     * Parse date relatively
     * @param {Date | string} date
     * @param {string} dateFormat
     * @returns {string}
     */
    static parseRelative(
        date: Date | string,
        dateFormat = 'D MMMM в HH:mm',
    ) {
        const dates = {
            lastWeek: dateFormat,
            lastDay: dateFormat
                .replace(/\D MMMM/g, 'Вчера')
                .replace(/\D\D MMMM/g, 'Вчера')
                .replace(/\D MMM YYYY/g, 'Вчера'),
            sameDay: dateFormat
                .replace(/\D MMMM/g, 'Сегодня')
                .replace(/\D\D MMMM/g, 'Сегодня')
                .replace(/\D MMM YYYY/g, 'Сегодня'),
            nextDay: dateFormat
                .replace(/\D MMMM/g, 'Завтра')
                .replace(/\D\D MMMM/g, 'Завтра')
                .replace(/\D MMM YYYY/g, 'Завтра'),
            nextWeek: dateFormat,
            sameElse: dateFormat,
        };

        if (moment(date).isSame(moment().toISOString(), 'day')) {
            return moment(date).format(dates.sameDay);
        }

        return moment(date, moment.ISO_8601)
            .calendar(null, dates);
    }

    /**
     * Get left dates with dates
     * @param {Date | string} to
     * @param {Date | string} from
     * @returns {number}
     */
    static getDaysLeft(to: Date | string, from?: Date | string): number {
        const source = moment(from || new Date());
        const target = moment(to);

        return target.diff(source, 'days');
    }

    /**
     * Get hours and minutes or hours + minutes from specific date
     * @param {Date | string} to
     * @param {Date | string} from
     * @returns {hours: number, minutes: number, hoursWithMinutes: number}
     */
    static getHoursAndMinutes(to: Date | string, from?: Date | string) {
        const source = moment(from || new Date());
        const target = moment(to);

        const { hours, minutes, timeInHours } = this.minutesToHoursAndMinutes(target.diff(source, 'minutes'));

        return { hours, minutes, timeInHours };
    }

    /**
     * Get in minutes time to hours and minutes
     * @param {number} timeInMinutes
     * @returns {{hours: number, minutes: number, timeInHours: number}}
     */
    static minutesToHoursAndMinutes(timeInMinutes: number) {
        const timeInHours = Math.abs(timeInMinutes / 60);

        const hours = Math.floor(timeInHours);
        const minutes = Math.round((timeInHours - hours) * 60);

        return { hours, minutes, timeInHours };
    }

    /**
     * Get age
     * @param {Date | string} to
     * @param {Date | string} from
     * @returns {string | number}
     */
    static getAge(
        from: Date | string,
        to?: Date | string,
    ): string | number {
        const source = moment(from);
        const target = moment(to || new Date());

        const getAgeNaming = (number: number) => {
            const cases = [ 2, 0, 1, 1, 1, 2 ];
            const plurals = [ 'год', 'года', 'лет' ];

            return plurals[(number % 100 > 4 && number % 100 < 20) ? 2 : cases[(number % 10 < 5) ? number % 10 : 5]];
        };

        return target.diff(source, 'years')
            ? target.diff(source, 'years') + ' ' + getAgeNaming(target.diff(source, 'years'))
            : '';
    }

    /**
     * Parse date to VKUI datepicker
     * @param {Date | string} date
     * @returns {{month: number, year: number, day: number}}
     */
    static parseToDatePicker(date: Date | string) {
        const parsed = moment(date).toObject();

        return {
            day: parsed.date,
            month: parsed.months + 1,
            year: parsed.years,
        };
    }

    /**
     * Parse fate from VKUI datepicker
     * @param {Record<string, number>} date
     * @returns {string}
     */
    static parseFromDatePicker(date: Record<string, number>) {
        const { day, month, year } = date;

        return moment({ day, month: month - 1, year })
            .utc()
            .format();
    }

    /**
     * Format to full date
     * @param {string | number} year
     * @param {string | number} month
     * @param {string | number} date
     * @returns {string}
     */
    static toFullDate(
        year: string | number,
        month: string | number,
        date: string | number,
    ) {
        return `${year}-${('0' + month).slice(-2)}-${('0' + date).slice(-2)}`;
    }

    /**
     * Get list options to calendar
     * @returns {}
     */
    static getCalendarOptions(
        fromYear = new Date().getFullYear() - 70,
        toYear = new Date().getFullYear(),
        defaultYear = new Date().getFullYear(),
    ) {
        const monthNames: string[] = [
            'Января',
            'Февраля',
            'Марта',
            'Апреля',
            'Мая',
            'Июня',
            'Июля',
            'Августа',
            'Сентября',
            'Октября',
            'Ноября',
            'Декабря',
        ];

        const getMonthMaxDay = (month: number, year?: number) => {
            return new Date(year || defaultYear, month, 0).getDate();
        };

        const range = (start: number, end: number) => {
            const result = [];
            const swap = start > end;

            for (let i = Math.min(start, end); i <= Math.max(start, end); i++) {
                result.push(i);
            }

            return swap ? result.reverse() : result;
        };

        const dayOptions = (month?: number, year?: number) => (
            range(1, getMonthMaxDay(month || 1, year || toYear))
                .map((value) => ({
                    label: String(value),
                    value: String(value),
                }))
        );

        const monthOptions = (monthNames).map((name, index) => ({
            label: name,
            value: String(index + 1),
        }));

        const yearOptions = range(toYear, fromYear).map((value) => ({
            label: String(value),
            value: String(value),
        }));

        return { dayOptions, monthOptions, yearOptions };
    }

    /**
     * Border of day (start or finish)
     * @param {'start' | 'finish'} mode
     * @param {Date} date
     * @returns {string}
     */
    static borderOfDay(
        mode: 'start' | 'finish',
        date?: Date,
    ) {
        return moment(date)[mode === 'start' ? 'startOf' : 'endOf']('day').toISOString();
    }

}


export { Time };
