/**
 * ChatAttachmentsView
 *
 * @author: exode <hello@exode.ru>
 */

import React, { useEffect, useRef, useState } from 'react';

import { observer, Page, useStore } from '@/pages/Core';
import { ChatDialogsPageStore } from '@/pages/Chat/Dialog/store';

import { ChatAttachmentsFindManyQuery, StorageFileType } from '@/codegen/graphql';

import { useI18n } from '@/hooks/core';
import { Graphql, If, Link } from '@/cutils';
import { Tabs, TabsItem } from '@exode.ru/vkui';

import { FileRow } from '@/components/Atoms/FileRow';
import { Placeholder } from '@/components/Atoms/Placeholder';

import { ScrollContainer } from '../items/styled';


const ChatAttachmentsView = observer(() => {

    const { t } = useI18n('pages.Chat.Dialog');

    const [ isEmpty, setIsEmpty ] = useState(false);
    const [ mode, setMode ] = useState<'media' | 'files'>('media');

    const { store, list, filter, sort, params } = useStore(ChatDialogsPageStore);

    const parentRef = useRef<HTMLDivElement | null>(null);

    const className = mode === 'files'
        ? 'flex flex-col gap-1'
        : `${isEmpty ? 'grid grid-cols-3 gap-[1px]' : 'flex flex-col'}`;

    useEffect(() => {
        switch (mode) {
            case 'media':
                store.mergeFilter({
                    attachments: {
                        storageFileTypes: [
                            StorageFileType.Image,
                            StorageFileType.Video,
                        ],
                    },
                });

                break;

            case 'files':
                store.mergeFilter({
                    attachments: {
                        storageFileTypes: [ StorageFileType.File ],
                    },
                });

                break;
        }
    }, [ mode ]);

    return (
        <>
            <div className={[
                `flex justify-between m:rounded-none items-center`,
                `sticky top-0 rounded-t-[14px] bg-highlighted overflow-hidden z-[1]`,
            ].join(' ')}>
                <Tabs className="w-full" mode="default">
                    <TabsItem id="media"
                              className="!rounded-none"
                              selected={mode === 'media'}
                              onClick={() => setMode('media')}
                              aria-controls="tab-content-media">
                        {t('mediaHeader')}
                    </TabsItem>

                    <TabsItem id="files"
                              className="!rounded-none"
                              selected={mode === 'files'}
                              onClick={() => setMode('files')}
                              aria-controls="tab-content-files">
                        {t('filesHeader')}
                    </TabsItem>
                </Tabs>
            </div>

            <ScrollContainer className="overflow-y-auto h-[calc(100%_-_44px)] relative vk-rounded rounded-t-none p-1">
                <div ref={parentRef} className="h-full overflow-x-hidden">
                    <ChatAttachmentsFindManyQuery fetchPolicy="network-only" children={(result) => (
                        <>
                            <Graphql.Loading result={result}>
                                <Page.Spinner className="h-full"/>
                            </Graphql.Loading>

                            <Graphql.Success result={result}>
                                {({ chatMessageFindMany: { items } }) => {

                                    setIsEmpty(!!items?.length);

                                    return (
                                        <div className={[
                                            className,
                                            'bg-content overflow-hidden',
                                            !items?.length ? 'h-full' : '',
                                        ].join(' ')}>
                                            {items?.map(({ medias }) => medias).flat().map(({ id, storage }) => (
                                                <React.Fragment key={id}>
                                                    <If is={mode === 'media' && storage?.fileType === StorageFileType.Image}>
                                                        <Link blank toOuter={storage?.location || ''}>
                                                            <img src={storage?.location || ''}
                                                                 className="h-[130px] w-full fit-cover"
                                                                 alt={storage?.meta.originalName || `image-${id}`}/>
                                                        </Link>
                                                    </If>

                                                    <If is={mode === 'files' || storage?.fileType !== StorageFileType.Image}>
                                                        <FileRow separator
                                                                 createdAt={storage?.createdAt}
                                                                 size={storage?.meta?.size || 0}
                                                                 location={storage?.location || ''}
                                                                 mimeType={storage?.meta?.mimeType || ''}
                                                                 name={storage?.meta?.originalName || ''}/>
                                                    </If>
                                                </React.Fragment>
                                            ))}

                                            <If is={!items?.length}>
                                                <Placeholder className="my-auto"
                                                             content={t('wasNotSentToChatPlaceholder')}
                                                             header={(
                                                                 mode === 'media'
                                                                     ? t('mediaWasNotFoundPlaceholder')
                                                                     : t('fileWasNotFoundPlaceholder')
                                                             )}/>
                                            </If>
                                        </div>
                                    );
                                }}
                            </Graphql.Success>

                            <Graphql.Error result={result}/>
                        </>
                    )} skip={!params.chatId} variables={{
                        chatId: +params.chatId,
                        list: { ...list.attachments },
                        filter: { ...filter.attachments },
                        sort: { ...sort.attachments },
                    }}/>
                </div>
            </ScrollContainer>
        </>
    );
});


export { ChatAttachmentsView };
