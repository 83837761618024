/**
 * LanguageSwitcher
 *
 * @author: exode <hello@exode.ru>
 */

import React, { ComponentType } from 'react';

import { observer } from 'mobx-react';

import { ConfigStore } from '@/store/core/config';

import { PanelHeaderButton } from '@exode.ru/vkui';
import { Icon28GlobeOutline } from '@vkontakte/icons';

import { HeaderItem } from '@/components/Desktop/Panel';

import { Container } from './LanguageSwitcher.styled';

import { LanguageAvatarPart } from './parts/LanguageAvatarPart';


interface Props {
    mode?: 'component' | 'panel-header';
    component?: ComponentType;
    componentProps?: Record<any, any>;
}


const LanguageSwitcher = observer((props: Props) => {

    const {
        mode = 'component',
        componentProps = {},
        component: Component = HeaderItem,
        ...rest
    } = props;

    switch (mode) {
        case 'component':
            return (
                <Container>
                    <Component children={<LanguageAvatarPart/>} className={[
                        'bg-transparent',
                        !ConfigStore.isDesktop && 'vk-rounded',
                    ].join(' ')}  {...componentProps} {...rest}/>
                </Container>
            );

        case 'panel-header':
            return (
                <PanelHeaderButton label={(
                    <span className="mr-3 uppercase font-medium-">
                        {ConfigStore.language}
                    </span>
                )} {...componentProps} {...rest}>
                    <Icon28GlobeOutline/>
                </PanelHeaderButton>
            );
    }

});


export { LanguageSwitcher };
