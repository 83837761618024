/**
 * Chat types
 *
 * @author: exode <hello@exode.ru>
 */

import React, { ReactElement } from 'react';

import { GqlResult } from '@/types/graphql';
import { useI18n } from '@/hooks/core/useI18n';

import {
    ChatListenNewMessageSubscriptionResult,
    ChatListenNewTypingStateSubscriptionResult,
    ChatMessageFindManyQueryResult,
    ChatMessageStatus,
    ChatType,
} from '@/codegen/graphql';

import { Icon16CheckOutline, Icon16ClockOutline } from '@vkontakte/icons';


/**
 * GraphQL/UI types
 */
export type ChatFolderType = ChatType | 'All';
export type ChatListViewMode = 'list' | 'creation' | 'search';
export type ChatMessageItem = NonNullable<ChatMessageItemNullable>[number];
export type ChatTypings = GqlResult<ChatListenNewTypingStateSubscriptionResult>['chatListenNewTypingState'];
export type ChatMessageItemNullable = GqlResult<ChatMessageFindManyQueryResult>['chatMessageFindMany']['items'];
export type UpdatedChatMessage = GqlResult<ChatListenNewMessageSubscriptionResult>['chatListenNewMessage']['messages'][number];


/**
 * Chat message status icon
 */
export const chatMessageStatusIcon: { [key in ChatMessageStatus]: ReactElement } = {
    [ChatMessageStatus.Scheduled]: <Icon16CheckOutline/>,
    [ChatMessageStatus.Sent]: <Icon16CheckOutline/>,
    [ChatMessageStatus.Sending]: <Icon16ClockOutline/>,
};

/**
 * Chat folder name
 */
export const chatFolderName = () => {

    const { t } = useI18n('types.chat');

    return {
        All: t('all'),
        [ChatType.Personal]: t('personal'),
        [ChatType.Support]: t('support'),
        [ChatType.Practice]: t('practice'),
        [ChatType.PrivateGroup]: t('personalGroups'),
        [ChatType.PublicGroup]: t('publicGroups'),
        [ChatType.Qa]: t('qa'),
        [ChatType.Channel]: t('channel'),
        [ChatType.ProductReview]: t('moderation'),
        [ChatType.ProductAccess]: t('productAccess'),
    } as const;
};

