/**
 * ChatMessageSubscription component
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { observer, useStore } from '@/pages/Core';
import { ChatDialogsPageStore } from '@/pages/Chat/Dialog/store';

import { ProfileStore } from '@/store/user/profile';

import { useChatListenNewTypingStateSubscription } from '@/codegen/graphql';


interface Props {
    chatId: number;
}


const ChatTypingSubscription = observer(({ chatId }: Props) => {

    const { store } = useStore(ChatDialogsPageStore);

    useChatListenNewTypingStateSubscription({
        variables: { chatId },
        onData: ({ data: { data } }) => {
            const { chatListenNewTypingState } = data || {};

            if (!chatListenNewTypingState) {
                return;
            }

            const { profiles } = chatListenNewTypingState;

            /** Игнорирование собственной печати */
            chatListenNewTypingState.profiles = profiles?.filter(({ id }) => (
                ProfileStore.id !== id
            ));

            store.setChatsTypings(chatListenNewTypingState);
        },
    });

    return (<></>);
});


export { ChatTypingSubscription };
