/**
 * Form util
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';


interface Props {
    onSubmit: (e: React.FormEvent<HTMLFormElement>) => any;
    children: any;
    className?: string;
}


const Form = (props: Props) => {

    const { onSubmit, children, ...rest } = props;

    const onSubmitCallback = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        onSubmit(e);
    };

    return (
        <form {...rest} onSubmit={onSubmitCallback}>
            <>
                {props.children}
            </>
        </form>
    );
};


export { Form };
