/**
 * PayRoutes
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { PageSpace } from '@/shared/types';

import { RouterItemsType } from '@/types/router';
import { lazyWithRetry } from '@/helpers/react';


const PayCartPage = lazyWithRetry(() => import('@/pages/Pay/Cart'));
const PayProductPage = lazyWithRetry(() => import('@/pages/Pay/Product'));
const InvoicePaymentPage = lazyWithRetry(() => import ('@/pages/Pay/InvoicePayment'));


const PayRoutes: RouterItemsType = {
    '/pay/:productId([0-9]+)/:priceId([0-9]+)': {
        view: <PayProductPage/>,
        type: 'iframe',
        spaces: [],
    },
    '/pay/:customName([0-9_A-Za-z_-]+)/:productId([0-9]+)/:priceId([0-9]+)': {
        view: <PayProductPage/>,
        type: 'iframe',
        spaces: [],
    },
    '/cart': {
        view: <PayCartPage/>,
        auth: [],
        spaces: [
            PageSpace.SchoolPlatform,
            PageSpace.MarketplacePlatform,
        ],
    },
    '/cart/:cartUuid([0-9_A-Za-z_-]+)': {
        view: <PayCartPage/>,
        spaces: [
            PageSpace.SchoolPlatform,
            PageSpace.MarketplacePlatform,
        ],
    },
    '/payment/:invoiceUuid([0-9_A-Za-z_-]+)': {
        view: <InvoicePaymentPage/>,
        type: 'iframe',
        spaces: [
            PageSpace.SchoolPlatform,
            PageSpace.MarketplacePlatform,
        ],
    },
};


export { PayRoutes };
