/**
 * LanguageAvatarPart
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { ConfigStore, observer } from '@/store/core/config';

import { gradient } from '@/styles/modules/gradient';

import { Icon28GlobeOutline } from '@vkontakte/icons';
import { Avatar, AvatarProps } from '@exode.ru/vkui';


interface Props {
    avatarProps?: AvatarProps;
}


const LanguageAvatarPart = observer((props: Props) => {

    const { avatarProps } = props;

    return (
        <Avatar size={35} shadow={false} badge={(
            <div className={[
                'flex items-center justify-center fs-10 uppercase font-semibold',
                'w-[20px] h-[20px] rounded-full text-white vk-shadow bg-gradient-to-r',
                gradient.green500ToLime600,
            ].join(' ')}>
                {ConfigStore.language}
            </div>
        )} {...avatarProps}>
            <Icon28GlobeOutline fill="var(--accent)"/>
        </Avatar>
    );
});


export { LanguageAvatarPart };
