/**
 * Subscription store
 *
 * @author: exode <hello@exode.ru>
 */

import { ChatFolderType } from '@/types/chat';

import { observer } from 'mobx-react';
import { makeAutoObservable } from 'mobx';


class Subscription {

    constructor() {
        makeAutoObservable(this);
    }

    /** Профили на которые подписался пользователь */
    profileIds: Set<number> = new Set;

    /** Турниры на которые подписался пользователь */
    tournamentIds: Set<number> = new Set;

    /** Турниры на участников которых подписался пользователь */
    tournamentIdsForMembers: Set<number> = new Set;

    /** Прогресс участников турнира на которые подписался пользователь */
    tournamentIdsForMembersProgress: Set<number> = new Set;

    /** Подписка на сообщения чата в лобби турнира  */
    tournamentIdsForChatMessages: Set<number> = new Set;

    /** Подписка на сообщения чата */
    chatIds: Set<number> = new Set;

    /** Подписка на typing чата */
    chatIdsForTyping: Set<number> = new Set;

    /** Подписка на кол-во непрочитанных сообщений папки чатов */
    chatFolderUnreadCount: Set<ChatFolderType> = new Set;

    /** Подписка на контент элемент */
    contentElementUuid: Set<string> = new Set;

    /**
     * Добавление с проверкой
     * @param {string | number | undefined} key
     * @param {Set<any>} set
     * @private
     */
    private addWithCheck(
        key: string | number | undefined,
        set: Set<any>,
    ) {
        if (!key || set.has(key)) {
            return;
        }

        set.add(key);
    }

    /**
     * Добавление подписки на статус профиля
     * @param {number} profileId
     * @returns {void}
     */
    subscribeToProfileStatus(profileId: number | undefined) {
        this.addWithCheck(profileId, this.profileIds);
    }

    /**
     * Добавление подписки на турнир
     * @param {number} tournamentId
     * @returns {void}
     */
    subscribeToTournament(tournamentId: number | undefined) {
        this.addWithCheck(tournamentId, this.tournamentIds);
    }

    /**
     * Добавление подписки на участников турнира
     * @param {number} tournamentId
     * @returns {void}
     */
    subscribeToTournamentMembers(tournamentId: number | undefined) {
        this.addWithCheck(tournamentId, this.tournamentIdsForMembers);
    }

    /**
     * Добавление подписки на прогресс участников в турнире
     * @param {number} tournamentId
     * @returns {void}
     */
    subscribeToTournamentMembersProgress(tournamentId: number | undefined) {
        this.addWithCheck(tournamentId, this.tournamentIdsForMembersProgress);
    }

    /**
     * Добавление подписки на сообщения чата в лобби турнира
     * @param {number} tournamentId
     * @returns {void}
     */
    subscribeToTournamentChatMessages(tournamentId: number | undefined) {
        this.addWithCheck(tournamentId, this.tournamentIdsForChatMessages);
    }

    /**
     * Добавление подписки на сообщения чата
     * @param {number} chatId
     * @returns {void}
     */
    subscribeToChatIds(chatId: number | undefined) {
        this.addWithCheck(chatId, this.chatIds);
    }

    /**
     * Добавление подписки на typing чата
     * @param {number} chatId
     * @returns {void}
     */
    subscribeToChatTypingIds(chatId: number | undefined) {
        this.addWithCheck(chatId, this.chatIdsForTyping);
    }

    /**
     * Добавление подписки на кол-во непрочитанных сообщений папки
     * @param {ChatFolderType | 'All'} type
     */
    subscribeToChatTypeUnreadCount(type: ChatFolderType) {
        this.addWithCheck(type, this.chatFolderUnreadCount);
    }

    /**
     * Добавление подписки на контент элемент
     * @param {number | undefined} contentElementUuid
     */
    subscribeToContentElementUuid(contentElementUuid: string | undefined) {
        this.addWithCheck(contentElementUuid, this.contentElementUuid);
    }

}

const SubscriptionStore = new Subscription();

export { observer, SubscriptionStore };
