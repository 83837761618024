/**
 * CategoryTagSkeleton component
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { Skeleton } from '@mui/material';


const CategoryTagSkeleton = () => {
    return (
        <>
            {[ 90, 60, 100, 70, 100, 90, 60, 90 ].map((e) => (
                <Skeleton width={e}
                          height={32}
                          variant="rectangular"
                          className="rounded-[16px]"
                          style={{ backgroundColor: 'white' }}/>
            ))}
        </>
    );
};


export { CategoryTagSkeleton };
