/**
 * DocsBanner
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { Link } from '@/cutils';

import { useI18n } from '@/hooks/core';

import { Button } from '@exode.ru/vkui';
import { Icon28DocumentOutline } from '@vkontakte/icons';

import { InnerHtml } from '@/components/Atoms/InnerHtml';
import { GradientBanner, GradientBannerImage } from '@/components/Atoms/GradientBanner';


export const DocsBanner = () => {

    const { t } = useI18n('components.Promotion.ContextBanners');

    return (
        <GradientBanner imageTheme="light"
                        gradient="bg-content"
                        header={t('contracts')}
                        subheader={<InnerHtml content={t('signedDocuments&CompletedContactForms')}/>}
                        image={(
                            <GradientBannerImage className="mt-14 -ml-12 banner__image">
                                <Icon28DocumentOutline width={125}
                                                       height={125}
                                                       style={{ transform: 'rotate(20deg)', marginRight: -25 }}/>
                            </GradientBannerImage>
                        )}
                        actions={(
                            <Link pushPage={{ id: '/manage/seller/documents' }}>
                                <Button mode="primary" size="m">{t('open')}</Button>
                            </Link>
                        )}/>
    );
};
