/**
 * Constants
 *
 * @author: exode <hello@exode.ru>
 */

import { useI18n } from '@/hooks/core';

import { ChatType, FriendshipStatus } from '@/codegen/graphql';


export const friendsHasNotPersonalChatListConfig = { skip: 0, take: 100 };

export const friendsHasNotPersonalChatFilterConfig = {
    status: FriendshipStatus.Confirmed,
    hasPersonalChat: false,
};

export const supportChatParams = () => {
    const { t } = useI18n('pages.Chat.Dialog');

    return {
        folder: ChatType.Support,
        isTicketCreating: 'true',
        blankTitle: t('howCanWeHelpYou'),
        blankSubtitle: t('support'),
        blankPlaceholder: t('sendYourQuestions'),
        blankNoMessageSubtitle: t('youAreCreatingNewTicket'),
    };
};
