/**
 * PageService
 *
 * @author: exode <hello@exode.ru>
 */

import { load } from 'recaptcha-v3';

import { PageStore } from '@/store/core/page';
import { BaseService } from '@/services/Core/Base';

import { MetaPropsType } from '@/types/page';
import { DocumentEvent } from '@/types/window';


class PageService extends BaseService {

    /**
     * Таймер мигания вкладки
     * @private
     */
    private static toggleTabTimer: NodeJS.Timeout;

    /**
     * Теги перед включением мигания
     * @type {Partial<MetaPropsType>}
     * @private
     */
    private static initialTags: Partial<MetaPropsType> = {};

    /**
     * При смене страницы перезаписываем initialTags
     * @param {Partial<MetaPropsType>} tags
     */
    static onRouteTabChange(tags: Partial<MetaPropsType>) {
        this.initialTags = tags;
    }

    /**
     * Мигание вкладки с текстом при отсутствии активности пользователя
     */
    static startToggleTab(
        tags: Partial<MetaPropsType>,
        stopOnInteraction = true,
    ) {
        this.stopToggleTab();

        this.initialTags = PageStore.tags;
        PageStore.setTags(tags);

        this.toggleTabTimer = setInterval(() => {
            const isEven = new Date().getSeconds() % 2 === 0;

            PageStore.setTags(isEven ? this.initialTags : tags);
        }, 1000);

        if (stopOnInteraction) {
            document.addEventListener(
                DocumentEvent.SystemWindowInteracted,
                () => PageService.stopToggleTab(),
                { once: true },
            );
        }
    }

    /**
     * Отключение мигания вкладки
     */
    static stopToggleTab() {
        clearInterval(this.toggleTabTimer);

        PageStore.setTags(this.initialTags);
    }

    /**
     * Обновление токена recaptcha
     */
    static async refreshRecaptchaToken() {
        const clientToken = process.env.REACT_APP_CLIENT_RECAPTCHA_TOKEN || '';

        const recaptcha = await load(clientToken, {
            autoHideBadge: true,
        });

        const recaptchaToken = await recaptcha.execute('submit');

        PageStore.merge({ recaptchaToken });

        return !!recaptchaToken;
    }

}


export { PageService };
