/**
 * DelimiterPart
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';


const DelimiterPart = () => {
    return (
        <div className="ce-block">
            <div className="ce-block__content">
                <div className="ce-delimiter cdx-block"></div>
            </div>
        </div>
    );
};


export { DelimiterPart };
