/**
 * Payout types
 *
 * @author: exode <hello@exode.ru>
 */

import React, { ReactElement } from 'react';

import { useI18n } from '@/hooks/core/useI18n';

import {
    InvoiceProductPayoutStatus,
    PayoutException,
    PayoutOperationFindManyQueryResult,
    PayoutOperationInitiationType,
    PayoutOperationStatus,
} from '@/codegen/graphql';

import { GqlResult } from '@/types/graphql';

import {
    Icon28AddOutline,
    Icon28BankOutline,
    Icon28CancelOutline,
    Icon28CheckCircleOn,
    Icon28ClockOutline,
    Icon28SearchStarsOutline,
} from '@vkontakte/icons';

import { gradient as stylesGradient } from '@/styles/modules/gradient';


export type PayoutOperationItem = NonNullable<GqlResult<PayoutOperationFindManyQueryResult>['payoutOperationFindMany']['items']>[number]

export const invoiceProductPayoutStatusName = () => {

    const { t } = useI18n('types.payout');

    return {
        [InvoiceProductPayoutStatus.Created]: t('created'),
        [InvoiceProductPayoutStatus.Refunded]: t('returned'),
        [InvoiceProductPayoutStatus.Released]: t('transferredToRub'),
        [InvoiceProductPayoutStatus.Waiting]: t('scheduled'),
    } as const;
};

export const invoiceProductPayoutStatusGradient: { [type in InvoiceProductPayoutStatus]: string } = {
    [InvoiceProductPayoutStatus.Created]: stylesGradient.gray500ToGray400,
    [InvoiceProductPayoutStatus.Refunded]: stylesGradient.orange600ToRed500,
    [InvoiceProductPayoutStatus.Released]: stylesGradient.green500ToLime600,
    [InvoiceProductPayoutStatus.Waiting]: stylesGradient.gray500ToGray400,
};

export const payoutOperationInitiationTypeText = () => {

    const { t } = useI18n('types.payout');

    return {
        [PayoutOperationInitiationType.Automation]: t('automatic'),
        [PayoutOperationInitiationType.Manually]: t('byUser'),
    } as const;
};

export const payoutOperationStatus = () => {

    const { t } = useI18n('types.payout');

    return {
        [PayoutOperationStatus.Created]: t('calculated'),
        [PayoutOperationStatus.Canceled]: t('canceled'),
        [PayoutOperationStatus.Calculating]: t('calculated'),
        [PayoutOperationStatus.Completed]: t('finished'),
        [PayoutOperationStatus.WaitBankProcessing]: t('inProcessing'),
        [PayoutOperationStatus.OnBankProcessing]: t('WaitingForBank'),
        [PayoutOperationStatus.Verifying]: t('inAccounting'),
    } as const;
};

export const payoutOperationStatusGradient: { [type in PayoutOperationStatus]: string } = {
    [PayoutOperationStatus.Created]: stylesGradient.gray500ToGray400,
    [PayoutOperationStatus.Canceled]: stylesGradient.orange600ToRed500,
    [PayoutOperationStatus.Calculating]: stylesGradient.gray500ToGray400,
    [PayoutOperationStatus.Completed]: stylesGradient.green500ToLime600,
    [PayoutOperationStatus.WaitBankProcessing]: stylesGradient.gray500ToGray400,
    [PayoutOperationStatus.OnBankProcessing]: stylesGradient.gray500ToGray400,
    [PayoutOperationStatus.Verifying]: stylesGradient.blue400ToBlue700Gradient,
};

export const payoutOperationStatusIcon: { [type in PayoutOperationStatus]: ReactElement } = {
    [PayoutOperationStatus.Created]: <Icon28AddOutline/>,
    [PayoutOperationStatus.Canceled]: <Icon28CancelOutline/>,
    [PayoutOperationStatus.Calculating]: <></>,
    [PayoutOperationStatus.Completed]: <Icon28CheckCircleOn/>,
    [PayoutOperationStatus.WaitBankProcessing]: <Icon28ClockOutline/>,
    [PayoutOperationStatus.OnBankProcessing]: <Icon28BankOutline/>,
    [PayoutOperationStatus.Verifying]: <Icon28SearchStarsOutline/>,
};

export const payoutOperationExceptionText = () => {

    const { t } = useI18n('types.payout');

    return {
        [PayoutException.ErrorInBankProcessing]: t('errorInBankTransaction'),
        [PayoutException.BalanceIsLessMinToPayout]: t('amountBelowMinimumPayout'),
        [PayoutException.BalanceNotEnoughToPayout]: t('notEnoughBalanceToWithdraw'),
        [PayoutException.SellerIsNotVerifiedToPayout]: t('sellerNotVerified'),
        [PayoutException.SellerRequisiteIsNotActiveToPayout]: t('noActiveRequisites'),
        [PayoutException.SellerIsNotVerifiedToSetRequisite]: t('sellerNotVerified'),
    } as const;
};
