/**
 * Filter useSchoolManageUsersFilter
 *
 * @author: exode <hello@exode.ru>
 */

import { useUrlFilters } from '@/hooks/core';

import { FilterProfileInput, Permission } from '@/codegen/graphql';

import { StoreCommonType } from '@/types/store';


export const defaultFilter = {
    userIds: [] as number[],
    active: true,
    search: '',
    verified: false,
    banned: false,
    permissions: [] as Permission[],
    profile: {} as FilterProfileInput,
};

export const dtoFilterTransformer = (filter: typeof defaultFilter) => filter;

export const useSchoolManageUsersFilter = (
    store: StoreCommonType,
    filter: Partial<typeof defaultFilter>,
) => useUrlFilters(
    { ...defaultFilter, ...filter },
    [],
    dtoFilterTransformer,
    { store },
);
