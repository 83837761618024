/**
 * DateInput component
 *
 * @author: exode <hello@exode.ru>
 */

import moment, { unitOfTime } from 'moment';

import React from 'react';

import { DateInput as VkUiDateInput, DateInputProps as VkUiDateInputProps } from '@exode.ru/vkui';


export interface DateInputProps extends VkUiDateInputProps {
    minDate?: Date;
    maxDate?: Date;
    minMaxInclusivity?: '()' | '[)' | '(]' | '[]';
    minMaxGranularity?: unitOfTime.StartOf;
}


const DateInput = (props: DateInputProps) => {

    const {
        minDate,
        maxDate,
        onChange,
        minMaxInclusivity = '()',
        minMaxGranularity = 'day',
    } = props;

    const shouldDisableDate = (date: Date) => {
        if (minDate && maxDate) {
            return !moment(date).isBetween(minDate, maxDate, minMaxGranularity, minMaxInclusivity);
        }

        if (minDate) {
            const method = minMaxInclusivity?.startsWith('(') ? 'isBefore' : 'isSameOrBefore';

            return moment(date)[method](minDate, minMaxGranularity);
        }

        if (maxDate) {
            const method = minMaxInclusivity?.endsWith(')') ? 'isAfter' : 'isSameOrAfter';

            return moment(date)[method](maxDate, minMaxGranularity);
        }

        return false;
    };

    const handleChange = (value: Date, byCalendar: boolean) => {
        const defaultTime = moment(props.defaultTime);

        if (props.enableTime && value) {
            value = moment(value).startOf('minute').toDate();
        }

        onChange?.(
            props.defaultTime && byCalendar
                ? moment(value)
                    .hour(defaultTime.hour())
                    .minute(defaultTime.minute())
                    .second(defaultTime.second())
                    .toDate()
                : value,
        );
    };

    return (
        <VkUiDateInput shouldDisableDate={shouldDisableDate} {...props} onChange={handleChange}/>
    );
};


export { DateInput };
