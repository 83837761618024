/**
 * LegalDocLink
 *
 * @author: exode <hello@exode.ru>
 */

import React, { ReactElement } from 'react';

import { IS_SCHOOL } from '@/root/src/env';

import { UserAuthStore } from '@/store/user/auth';
import { observer, PlatformStore } from '@/store/platform';

import { CommonPlatformConfig } from '@/shared/types';

import { Router } from '@/services/Utils/Router';
import { getTranslated, useI18n } from '@/hooks/core';
import { If, Link, Notify, useRedirect } from '@/cutils';

import { Button } from '@exode.ru/vkui';
import { Icon24LinkCircleFilled } from '@vkontakte/icons';


interface Props {
    type: keyof CommonPlatformConfig['organization']['legal']['docs']['typical'];
    name?: string;
    after?: string;
    before?: string;
    nameClassName?: string;
    children?: ReactElement;
    hideIfEmpty?: boolean;
}


const LegalDocLink = observer((props: Props) => {

    const {
        type,
        name,
        after,
        before,
        children,
        hideIfEmpty,
        nameClassName,
    } = props;

    const { redirect } = useRedirect();

    const { t } = useI18n('components.Company.Legal');

    const { legalDocs } = PlatformStore;

    const link = getTranslated(legalDocs?.typical[type]);

    const onClick = () => {
        if (link) {
            redirect(link, true);
        }

        if (!link) {
            const uniqKey = 'legal-doc-link:set';

            if (IS_SCHOOL && UserAuthStore.isManager) {
                Notify.vkui({
                    uniqKey,
                    closeOnClick: false,
                    appearance: 'primary',
                    message: t('linkNotAdded'),
                    icon: <Icon24LinkCircleFilled/>,
                    vkuiOptions: {
                        layout: 'horizontal',
                        onActionClick: () => Router.pushPage('/manage/school/legal'),
                        action: (
                            <Button mode="secondary" className="!bg-white !text-black">
                                {t('setLinks')}
                            </Button>
                        ),
                    },
                });
            } else {
                Notify.toast.error(t('linkNotAdded'), { id: uniqKey });
            }
        }
    };

    return (
        <If is={!!link || !link && !hideIfEmpty}>
            <>
                {before}
                {before ? ' ' : ''}
            </>

            <Link onClick={onClick}>
                <span>
                    {children}

                    <If is={!children}>
                        <span className={nameClassName}>
                            {name}
                        </span>
                    </If>
                </span>
            </Link>

            <>
                {after && after !== '.' ? ' ' : ''}
                {after}
            </>
        </If>
    );
});


export { LegalDocLink };
