/**
 * MainContentView
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { HeaderView } from './HeaderView';
import { UsersListView } from './UsersListView';


const MainContentView = () => {
    return (
        <>
            <HeaderView/>

            <UsersListView/>
        </>
    );
};


export { MainContentView };
