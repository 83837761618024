/**
 * HeaderView
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { Page } from '@/pages/Core';

import { ReadyAndUpcomingTournamentsDocument, ScheduledTournamentsDocument } from '@/codegen/graphql';


const HeaderView = () => {
    return (
        <Page.Header title="Турниры" refetchDocs={[
            ReadyAndUpcomingTournamentsDocument,
            ScheduledTournamentsDocument,
        ]}/>
    );
};


export { HeaderView };
