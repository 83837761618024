/**
 * Head util
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { MetaPropsType } from '@/types/page';


class Head extends React.Component {

    static get permanent() {
        return (
            <>
                <meta name="mobile-web-app-capable" content="yes"/>
                <meta name="apple-mobile-web-app-capable" content="yes"/>
                <meta name="application-name" content="exode"/>
                <meta name="apple-mobile-web-app-title" content="exode"/>
                <meta name="apple-mobile-web-app-status-bar-style" content="white-translucent"/>
                <meta name="msapplication-starturl" content="/"/>
                <meta name="format-detection" content="telephone=no"/>
                <meta name="msapplication-tap-highlight" content="no"/>

                <meta property="twitter:card" content="summary_large_image"/>
                <meta property="og:site_name" content="exode.ru"/>
            </>
        );
    }

    static Meta(meta: MetaPropsType) {
        return (
            <>
                {Head.permanent}

                <meta name="theme-color" content={meta.color}/>
                <meta name="msapplication-navbutton-color" content={meta.color}/>
                <meta name="apple-mobile-web-app-status-bar-style" content={meta.color}/>

                <title>{meta.title}</title>
                <meta name="title" property="title" content={meta.title}/>
                <meta property="og:title" content={meta.title}/>


                {meta.image && (
                    <>
                        <link rel="image_src" href={meta.image}/>
                        <meta name="image" property="image" content={meta.image}/>
                        <meta property="og:image" content={meta.image}/>
                        <meta property="vk:image" content={meta.image}/>
                        <meta property="twitter:image" content={meta.image}/>
                        <meta property="og:image:width" content="250"/>
                        <meta property="og:image:height" content="250"/>
                    </>
                )}

                <link rel="canonical" href={meta.canonical}/>

                <meta name="description" property="description" content={meta.description}/>
                <meta property="og:description" content={meta.description}/>

                <meta property="content" content={meta.content}/>
                <meta property="og:content" content={meta.content}/>

                <meta name="keywords" property="keywords" content={meta.keywords}/>
                <meta property="og:keywords" content={meta.keywords}/>

                <meta name="url" property="url" content={meta.url}/>
                <meta name="og:url" property="og:url" content={meta.url}/>

                <meta name="type" property="type" content="website"/>
                <meta property="og:type" content="website"/>
            </>
        );
    }

}


export { Head };
