/**
 * HeaderItemPart
 *
 * @author: exode <hello@exode.ru>
 */

import React, { ReactNode } from 'react';

import { Button, ButtonProps } from '@exode.ru/vkui';


interface Props extends Omit<ButtonProps, 'title'> {
    title?: ReactNode;
    before?: ReactNode;
    active?: boolean;
    textClassName?: string;
}


const HeaderItemPart = (props: Props) => {

    const { title, before, active, className, textClassName, ...rest } = props;

    return (
        <Button disabled={active} mode="tertiary" size="l" before={before} className={[
            className,
            'rounded-none',
        ].join(' ')} children={(
            title
                ? (
                    <span className={[
                        'text-primary',
                        textClassName,
                        active ? 'text-underline-marker h-after-4' : '',
                    ].join(' ')}>
                        {title}
                    </span>
                )
                : undefined
        )} {...rest}/>
    );
};


export { HeaderItemPart };
