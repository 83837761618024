/**
 * CellTagSelect component
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import React, { useCallback, useEffect, useState } from 'react';

import { Formik } from 'formik';

import { CellTagItemPart, CellTagItemPartProps } from './parts/CellTagItemPart';

import { SimpleCellWrapper } from './CellTagSelect.styled';


interface Option {
    label: string;
    value: string | number;
    meta?: Record<any, any>;
}

export interface CellTagSelectProps {
    options: Option[] | undefined;
    selected: (string | number)[];
    appearance?: 'primary' | 'secondary' | 'onDark';
    className?: string;
    autoSaveFormDelay?: number;
    enableReinitialize?: boolean;
    itemProps?: Omit<CellTagItemPartProps, 'value' | 'label'>;
    dependsOnSelectedState?: any[];
    onChange?: (
        selected: any[],
        metas?: Option['meta'][],
    ) => void;
}


const CellTagSelect = (props: CellTagSelectProps) => {

    const {
        options,
        selected,
        onChange,
        dependsOnSelectedState,
        className = '',
        appearance = 'primary',
        autoSaveFormDelay = 250,
        enableReinitialize = !dependsOnSelectedState,
    } = props;

    const [ selectedState, setSelectedState ] = useState(props.selected);

    const debouncedCallback = useCallback(
        _.debounce((callback) => callback(), autoSaveFormDelay),
        [],
    );

    useEffect(() => {
        setSelectedState(props.selected);
    }, [ ...(dependsOnSelectedState || []) ]);

    return (
        <Formik initialValues={{ selected }}
                enableReinitialize={enableReinitialize}
                onSubmit={({ selected }) => debouncedCallback(() => onChange?.(
                    selected,
                    selected.map((value) => _.find(options, { value })?.meta),
                ))}>
            {({ setFieldValue, handleSubmit, values }) => (
                <SimpleCellWrapper className="simple-cell-wrapper">
                    {options?.map(({ value, label }, index) => {
                        const isSelected = values.selected?.includes(value);

                        return (
                            <CellTagItemPart key={index}
                                             {...props.itemProps}
                                             value={value}
                                             label={label}
                                             className={className}
                                             appearance={appearance}
                                             isSelected={isSelected || selectedState.includes(value)}
                                             onClick={() => {
                                                 if (!onChange) {
                                                     return;
                                                 }

                                                 const selected = _.uniq(isSelected
                                                     ? values.selected.filter((e) => e !== value)
                                                     : [ ...values.selected, value ],
                                                 );

                                                 setFieldValue('selected', selected);

                                                 setSelectedState(selected);

                                                 handleSubmit();
                                             }}/>
                        );
                    })}
                </SimpleCellWrapper>
            )}
        </Formik>
    );
};


export { CellTagSelect };
