/**
 * CategoryPart
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { Graphql } from '@/cutils';
import { useI18n } from '@/hooks/core';
import { observer } from '@/pages/Core';

import { SubjectCategoryFindManyQuery } from '@/codegen/graphql';

import { AdaptiveSelect } from '@/components/Atoms/AdaptiveSelect';
import { SubjectCategorySelectorProps } from '@/components/Subject/CategorySelector';


interface Props extends SubjectCategorySelectorProps {

}


const CategoryPart = observer((props: Props) => {

    const { filter, onCategorySelect, selectedSubjectCategoryIds } = props;

    const { t } = useI18n('components.Subject.CategorySelector.parts');

    return (
        <SubjectCategoryFindManyQuery children={(result) => (
            <>
                <Graphql.Success result={result}>
                    {({ subjectCategoryFindMany: { items: categories } }) => (
                        <AdaptiveSelect isTab
                                        useInModal
                                        isActionSheets
                                        multiple={{}}
                                        buttonText={t('selectCategories')}
                                        value={selectedSubjectCategoryIds}
                                        stickyButtonClassName="px-0 m:px-2"
                                        callback={(options) => {
                                            onCategorySelect(options.map(({ value }) => +value));
                                        }}
                                        options={(
                                            categories?.map(({ name, id }) => ({
                                                label: name,
                                                value: id,
                                                disabled: false,
                                            })) || []
                                        )}/>
                    )}
                </Graphql.Success>
            </>
        )} variables={{ list: {}, filter }}/>
    );
});


export { CategoryPart };
