/**
 * UserContentCategoryToggle
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import { ReactElement } from 'react';

import { UserStateKey } from '@/codegen/graphql';

import { If } from '@/cutils';
import { useGetUserState } from '@/hooks/apollo';
import { ContentCategorySlug } from '@/types/content';


interface Props {
    slugs: ContentCategorySlug[];
    children: ReactElement | ReactElement[];
    showByDefault?: boolean;
}


const UserContentCategoryToggle = (props: Props) => {

    const { slugs, children, showByDefault } = props;

    const [ userCategories ] = useGetUserState(UserStateKey.ContentCategoryIds);

    const show = !_.isNil(userCategories)
        ? userCategories?.categorySlugs?.some((s: ContentCategorySlug) => slugs.includes(s))
        : !!showByDefault;

    return (
        <If is={show}>
            {children}
        </If>
    );
};


export { UserContentCategoryToggle };
