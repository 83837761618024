/**
 * UserConnectTgModal
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { useI18n } from '@/hooks/core';
import { Modal, Notify } from '@/cutils';
import { Router } from '@/services/Utils/Router';

import { Title } from '@exode.ru/vkui';

import { ConnectTelegramView } from '@/pages/User/Settings/views/ConnectTelegramView';


interface Props {
    id: string;
}


const UserConnectTgModal = (props: Props) => {

    const { t } = useI18n('modals.User.ConnectTelegram');

    return (
        <Modal.Card id={props.id} subheader={t('connectTgSubtitle')} header={(
            <Title className="modal-title" level="2" weight="3">
                {t('connectTelegram')}
            </Title>
        )}>
            <Modal.Body>
                <div className="flex items-center justify-center h-[150px]">
                    <ConnectTelegramView onConnected={() => {
                        Router.replaceModal();
                        Notify.toast.success(t('tgConnectedToProfile'));
                    }}/>
                </div>
            </Modal.Body>
        </Modal.Card>
    );
};


export { UserConnectTgModal };
