/**
 * TournamentSubjectSelectModal
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { Page } from '@/pages/Core';
import { Graphql, Modal } from '@/cutils';
import { ContentCategorySlug } from '@/types/content';

import { SubjectFindManyQuery } from '@/codegen/graphql';

import { SubjectsSelectView } from '@/pages/Tournament/SubjectsSelect/views/SubjectsSelectView';


interface Props {
    id: string;
}


const TournamentSubjectSelectModal = (props: Props) => {
    return (
        <Modal.Card id={props.id}>
            <Modal.Body isScrollable>
                <SubjectFindManyQuery children={(result) => (
                    <>
                        <Graphql.Loading result={result}>
                            <div className="my-10">
                                <Page.Spinner/>
                            </div>
                        </Graphql.Loading>

                        <Graphql.Success result={result}>
                            {({ subjectFindMany: subjects }) => (
                                <SubjectsSelectView subjects={subjects.items || []}/>
                            )}
                        </Graphql.Success>

                        <Graphql.Error result={result}/>
                    </>
                )} variables={{
                    list: {},
                    filter: {
                        contentCategory: { slugs: [ ContentCategorySlug.Ege ] },
                    },
                }}/>
            </Modal.Body>
        </Modal.Card>
    );
};


export { TournamentSubjectSelectModal };
