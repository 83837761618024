/**
 * DateInputInterval component
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import moment from 'moment';

import React, { ReactNode } from 'react';

import { FormikErrors } from 'formik';

import { CompareDateOptions, useDateRangeInput } from '@/hooks/core';

import { FormItem, FormLayoutGroup } from '@exode.ru/vkui';

import { DateInput, DateInputProps } from '@/components/Atoms/DateInput';


interface DateItem {
    name: string;
    label: string;
    value?: Date;
    dataTest?: string;
    tooltip?: ReactNode;
    dateInputProps?: DateInputProps;
    error?: string | string[] | FormikErrors<any> | FormikErrors<any>[];
    compare?: CompareDateOptions | null;
    onValidate?: (isValid: boolean, messages: string[]) => void;
}

interface Props {
    from: DateItem;
    to: DateItem;
    handleChange: (field: string, value: Date | undefined) => void;
    className?: string;
}


const DateInputInterval = (props: Props) => {

    const {
        from,
        to,
        handleChange,
        className = 'pb-0 px-0',
    } = props;

    const {
        setFromOrTo: setFrom,
        errorFrom,
        from: fromRange,
    } = useDateRangeInput(
        from.value,
        to.value,
        from.compare,
        from.onValidate,
    );

    const {
        setFromOrTo: setTo,
        errorTo,
        to: toRange,
    } = useDateRangeInput(
        from.value,
        to.value,
        to.compare,
        to.onValidate,
    );

    const onChange = (
        range: 'from' | 'to',
        field: string,
        value: Date | undefined,
    ) => {
        range === 'from'
            ? setFrom(range, value)
            : setTo(range, value);

        handleChange(field, value);
    };

    const isErrorFrom = (
        errorFrom
        || !!(_.isString(from.error) ? from.error.trim() : from.error)
    );

    const isErrorTo = (
        errorTo
        || !!(_.isString(to.error) ? to.error.trim() : to.error)
    );

    return (
        <FormLayoutGroup className={className} mode="horizontal">
            <FormItem bottom={errorFrom || from.error || ' '}
                      status={isErrorFrom ? 'error' : 'default'}
                      className={[ 'my-1 p-0', isErrorFrom ? 'shake-animation' : '' ].join(' ')}
                      top={(
                          <div className="flex gap-1">
                              {from.label}
                              {from.tooltip}
                          </div>
                      )}>
                <DateInput name={from.name}
                           value={fromRange}
                           data-test={from.dataTest}
                           onChange={(value) => onChange('from', from.name, value)} {...from.dateInputProps}/>
            </FormItem>

            <FormItem bottom={errorTo || to.error || ' '}
                      status={isErrorTo ? 'error' : 'default'}
                      className={[ 'my-1 p-0', isErrorTo ? 'shake-animation' : '' ].join(' ')}
                      top={(
                          <div className="flex gap-1">
                              {to.label}
                              {to.tooltip}
                          </div>
                      )}>
                <DateInput disablePast
                           name={to.name}
                           value={toRange}
                           data-test={to.dataTest}
                           onChange={(value) => onChange(
                               'to',
                               to.name,
                               value && moment(value).startOf('minute').toDate(),
                           )}
                           {...to.dateInputProps}/>
            </FormItem>
        </FormLayoutGroup>
    );
};


export { DateInputInterval };
