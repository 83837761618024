/**
 * ExamRoutes
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { PageSpace } from '@/shared/types';

import { RouterItemsType } from '@/types/router';
import { lazyWithRetry } from '@/helpers/react';


const EgeSubjectPage = lazyWithRetry(() => import('@/pages/Ege/Subject'));
const EgeTimeoutPage = lazyWithRetry(() => import('@/pages/Ege/Timeout'));


const ExamRoutes: RouterItemsType = {
    '/exams-subject/:subject([a-z-]+)': {
        view: <EgeSubjectPage/>,
        spaces: [
            PageSpace.MarketplacePlatform,
        ],
    },
    '/exams-timeout': {
        view: <EgeTimeoutPage/>,
        tab: 'education',
        spaces: [
            PageSpace.MarketplacePlatform,
        ],
    },
};


export { ExamRoutes };
