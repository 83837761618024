/**
 * IconDoubleSwords component
 *
 * @author: exode <hello@exode.ru>
 */

import React, { SVGProps } from 'react';


const IconDoubleSwords = (props: SVGProps<SVGSVGElement>) => {
    return (
        <svg width={30} height={30} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M19.9997 24.1666L24.1663 19.9999L4.99967 0.833252H0.833008V4.99992L19.9997 24.1666Z"
                  fill="#CCD6DD"/>
            <path d="M0.833008 0.833252V4.99992L19.9997 24.1666L22.083 22.0833L0.833008 0.833252Z" fill="#9AAAB4"/>
            <path d="M27.8537 27.3399C28.0904 27.1032 28.2354 26.8182 28.2963 26.5332L23.9279 21.3707L23.3437 20.7857C22.8737 20.3157 22.0354 20.3916 21.4704 20.9566L20.9587 21.4666C20.3946 22.0307 20.3187 22.8691 20.7887 23.3391L21.4062 23.9566L26.5312 28.2941C26.8187 28.2341 27.1046 28.0882 27.3429 27.8507L27.8537 27.3399Z"
                  fill="#D99E82"/>
            <path d="M27.8529 27.34C28.0895 27.1033 28.2345 26.8183 28.2954 26.5333L27.177 25.2116L26.5629 28.2816C26.8387 28.2175 27.1137 28.0791 27.342 27.85L27.8529 27.34ZM25.0945 22.7516L24.3545 26.4525L25.4045 27.3408L26.0879 23.9241L25.0945 22.7516ZM23.927 21.3708L23.3429 20.7858C23.2633 20.709 23.1715 20.6459 23.0712 20.5991L22.2562 24.6758L23.3062 25.5641L24.1037 21.5783L23.927 21.3708ZM21.9262 20.6308C21.7568 20.7133 21.6024 20.8238 21.4695 20.9575L20.9579 21.4666C20.7569 21.6651 20.6103 21.9119 20.532 22.1833C20.4137 22.6083 20.4937 23.0458 20.7879 23.3391L21.2845 23.8358L21.9262 20.6308C21.9262 20.63 21.9262 20.63 21.9262 20.6308Z"
                  fill="#BF6952"/>
            <path d="M27.7087 30.0001C28.9743 30.0001 30.0003 28.9741 30.0003 27.7084C30.0003 26.4428 28.9743 25.4167 27.7087 25.4167C26.443 25.4167 25.417 26.4428 25.417 27.7084C25.417 28.9741 26.443 30.0001 27.7087 30.0001Z"
                  fill="#8A4633"/>
            <path d="M24.6879 18.6034C24.768 18.6834 24.8316 18.7783 24.8749 18.8829C24.9182 18.9874 24.9405 19.0994 24.9405 19.2126C24.9405 19.3258 24.9182 19.4378 24.8749 19.5423C24.8316 19.6469 24.768 19.7418 24.6879 19.8218L19.6113 24.8984C19.5313 24.9784 19.4363 25.0419 19.3318 25.0852C19.2273 25.1285 19.1152 25.1508 19.0021 25.1508C18.889 25.1508 18.777 25.1285 18.6724 25.0852C18.5679 25.0419 18.4729 24.9784 18.3929 24.8984C18.313 24.8184 18.2495 24.7235 18.2062 24.6189C18.1629 24.5144 18.1406 24.4024 18.1406 24.2893C18.1406 24.1761 18.1629 24.0641 18.2062 23.9596C18.2495 23.8551 18.313 23.7601 18.3929 23.6801L23.4696 18.6034C23.5496 18.5234 23.6445 18.4598 23.7491 18.4165C23.8536 18.3731 23.9656 18.3508 24.0788 18.3508C24.1919 18.3508 24.304 18.3731 24.4085 18.4165C24.513 18.4598 24.608 18.5234 24.6879 18.6034Z"
                  fill="#FFAC33"/>
            <path d="M18.3929 26.3559C19.1983 26.3559 19.8512 25.703 19.8512 24.8975C19.8512 24.0921 19.1983 23.4392 18.3929 23.4392C17.5875 23.4392 16.9346 24.0921 16.9346 24.8975C16.9346 25.703 17.5875 26.3559 18.3929 26.3559Z"
                  fill="#FFAC33"/>
            <path d="M24.6888 20.061C25.4942 20.061 26.1471 19.408 26.1471 18.6026C26.1471 17.7972 25.4942 17.1443 24.6888 17.1443C23.8834 17.1443 23.2305 17.7972 23.2305 18.6026C23.2305 19.408 23.8834 20.061 24.6888 20.061Z"
                  fill="#FFAC33"/>
            <path d="M18.3929 25.7309C18.8531 25.7309 19.2262 25.3578 19.2262 24.8975C19.2262 24.4373 18.8531 24.0642 18.3929 24.0642C17.9327 24.0642 17.5596 24.4373 17.5596 24.8975C17.5596 25.3578 17.9327 25.7309 18.3929 25.7309Z"
                  fill="#FFCC4D"/>
            <path d="M24.6888 19.436C25.149 19.436 25.5221 19.0629 25.5221 18.6026C25.5221 18.1424 25.149 17.7693 24.6888 17.7693C24.2286 17.7693 23.8555 18.1424 23.8555 18.6026C23.8555 19.0629 24.2286 19.436 24.6888 19.436Z"
                  fill="#FFCC4D"/>
            <path d="M28.2525 24.4518C28.3115 24.5107 28.3584 24.5808 28.3903 24.6578C28.4222 24.7349 28.4387 24.8175 28.4387 24.9009C28.4387 24.9844 28.4222 25.067 28.3903 25.144C28.3584 25.2211 28.3115 25.2911 28.2525 25.3501L25.3559 28.2459C25.2969 28.3049 25.2269 28.3517 25.1498 28.3836C25.0727 28.4156 24.9901 28.432 24.9067 28.432C24.8233 28.432 24.7407 28.4156 24.6636 28.3836C24.5865 28.3517 24.5165 28.3049 24.4575 28.2459C24.3985 28.1869 24.3518 28.1169 24.3198 28.0399C24.2879 27.9628 24.2715 27.8802 24.2715 27.7968C24.2715 27.7133 24.2879 27.6307 24.3198 27.5537C24.3518 27.4766 24.3986 27.4066 24.4575 27.3476L27.3542 24.4518C27.4132 24.3928 27.4832 24.3459 27.5603 24.314C27.6373 24.2821 27.7199 24.2656 27.8034 24.2656C27.8868 24.2656 27.9694 24.2821 28.0465 24.314C28.1235 24.3459 28.1936 24.3928 28.2525 24.4518Z"
                  fill="#FFAC33"/>
            <path d="M9.99967 24.1666L5.83301 19.9999L24.9997 0.833252H29.1663V4.99992L9.99967 24.1666Z"
                  fill="#CCD6DD"/>
            <path d="M29.167 0.833252V4.99992L10.0003 24.1666L7.91699 22.0833L29.167 0.833252Z" fill="#9AAAB4"/>
            <path d="M2.1466 27.3399C1.92432 27.1194 1.77063 26.8392 1.7041 26.5332L6.07243 21.3707L6.6566 20.7857C7.1266 20.3157 7.96493 20.3916 8.52993 20.9566L9.04077 21.4666C9.60493 22.0307 9.68077 22.8691 9.21077 23.3391L8.59327 23.9566L3.46827 28.2941C3.16042 28.2282 2.87839 28.0742 2.6566 27.8507L2.1466 27.3399Z"
                  fill="#D99E82"/>
            <path d="M2.1466 27.34C1.92432 27.1194 1.77063 26.8393 1.7041 26.5333L2.82243 25.2116L3.4366 28.2816C3.14145 28.2139 2.87139 28.0643 2.65743 27.85L2.1466 27.34ZM4.90494 22.7516L5.64494 26.4525L4.59493 27.3408L3.9116 23.9241L4.90494 22.7516ZM6.07244 21.3708L6.6566 20.7858C6.73621 20.709 6.82801 20.6459 6.92827 20.5991L7.74327 24.6758L6.69327 25.5641L5.89577 21.5783L6.07244 21.3708ZM8.07327 20.6308C8.23577 20.71 8.3916 20.8183 8.52994 20.9575L9.04077 21.4675C9.25244 21.6791 9.39494 21.9291 9.4666 22.1841C9.58493 22.6091 9.50494 23.0466 9.21077 23.34L8.7141 23.8366L8.07327 20.6308C8.07327 20.63 8.07327 20.63 8.07327 20.6308Z"
                  fill="#BF6952"/>
            <path d="M2.29167 30.0001C3.55732 30.0001 4.58333 28.9741 4.58333 27.7084C4.58333 26.4428 3.55732 25.4167 2.29167 25.4167C1.02601 25.4167 0 26.4428 0 27.7084C0 28.9741 1.02601 30.0001 2.29167 30.0001Z"
                  fill="#8A4633"/>
            <path d="M5.31119 18.6034C5.23112 18.6834 5.16759 18.7783 5.12425 18.8829C5.0809 18.9874 5.05859 19.0994 5.05859 19.2126C5.05859 19.3258 5.0809 19.4378 5.12425 19.5423C5.16759 19.6469 5.23112 19.7418 5.31119 19.8218L10.3879 24.8984C10.4679 24.9784 10.5628 25.0419 10.6673 25.0852C10.7719 25.1285 10.8839 25.1508 10.997 25.1508C11.1102 25.1508 11.2222 25.1285 11.3267 25.0852C11.4312 25.0419 11.5262 24.9784 11.6062 24.8984C11.6862 24.8184 11.7496 24.7235 11.7929 24.6189C11.8362 24.5144 11.8585 24.4024 11.8585 24.2893C11.8585 24.1761 11.8362 24.0641 11.7929 23.9596C11.7496 23.8551 11.6862 23.7601 11.6062 23.6801L6.52953 18.6034C6.44957 18.5234 6.35462 18.4598 6.25009 18.4165C6.14556 18.3731 6.03352 18.3508 5.92036 18.3508C5.8072 18.3508 5.69515 18.3731 5.59063 18.4165C5.4861 18.4598 5.39114 18.5234 5.31119 18.6034Z"
                  fill="#FFAC33"/>
            <path d="M11.6068 26.3559C12.4122 26.3559 13.0651 25.703 13.0651 24.8975C13.0651 24.0921 12.4122 23.4392 11.6068 23.4392C10.8014 23.4392 10.1484 24.0921 10.1484 24.8975C10.1484 25.703 10.8014 26.3559 11.6068 26.3559Z"
                  fill="#FFAC33"/>
            <path d="M5.31185 20.061C6.11726 20.061 6.77018 19.408 6.77018 18.6026C6.77018 17.7972 6.11726 17.1443 5.31185 17.1443C4.50643 17.1443 3.85352 17.7972 3.85352 18.6026C3.85352 19.408 4.50643 20.061 5.31185 20.061Z"
                  fill="#FFAC33"/>
            <path d="M11.6068 25.7309C12.067 25.7309 12.4401 25.3578 12.4401 24.8975C12.4401 24.4373 12.067 24.0642 11.6068 24.0642C11.1465 24.0642 10.7734 24.4373 10.7734 24.8975C10.7734 25.3578 11.1465 25.7309 11.6068 25.7309Z"
                  fill="#FFCC4D"/>
            <path d="M5.31185 19.436C5.77209 19.436 6.14518 19.0629 6.14518 18.6026C6.14518 18.1424 5.77209 17.7693 5.31185 17.7693C4.85161 17.7693 4.47852 18.1424 4.47852 18.6026C4.47852 19.0629 4.85161 19.436 5.31185 19.436Z"
                  fill="#FFCC4D"/>
            <path d="M1.74766 24.4518C1.68865 24.5107 1.64184 24.5808 1.6099 24.6578C1.57796 24.7349 1.56152 24.8175 1.56152 24.9009C1.56152 24.9844 1.57796 25.067 1.6099 25.144C1.64184 25.2211 1.68865 25.2911 1.74766 25.3501L4.64433 28.2459C4.70332 28.3049 4.77334 28.3517 4.85041 28.3836C4.92748 28.4156 5.01008 28.432 5.0935 28.432C5.17692 28.432 5.25952 28.4156 5.33659 28.3836C5.41365 28.3517 5.48368 28.3049 5.54266 28.2459C5.60165 28.1869 5.64844 28.1169 5.68036 28.0399C5.71229 27.9628 5.72872 27.8802 5.72872 27.7968C5.72872 27.7133 5.71229 27.6307 5.68036 27.5537C5.64844 27.4766 5.60165 27.4066 5.54266 27.3476L2.646 24.4518C2.58703 24.3928 2.51701 24.3459 2.43994 24.314C2.36287 24.2821 2.28026 24.2656 2.19683 24.2656C2.11341 24.2656 2.0308 24.2821 1.95373 24.314C1.87666 24.3459 1.80664 24.3928 1.74766 24.4518Z"
                  fill="#FFAC33"/>
        </svg>
    );
};


export { IconDoubleSwords };
