/**
 * ModalCard styled container
 *
 * @author: exode <hello@exode.ru>
 */

import styled from 'styled-components';

import { ModalCardProps } from './ModalCard';


export const Container = styled.div<ModalCardProps>`
  .ModalCardBase__container {
    min-height: ${({ minHeight }) => minHeight || 'initial'};
    justify-content: ${({ justifyContent }) => justifyContent || 'initial'};
    padding: ${({ padding }) => padding};
  }
`;
