/**
 * PromotionBannerTournaments
 *
 * @author: exode <hello@exode.ru>
 */

import React, { ReactElement } from 'react';

import { Link } from '@/cutils';
import { useI18n } from '@/hooks/core';

import { Button } from '@exode.ru/vkui';
import { Icon28GameOutline } from '@vkontakte/icons';

import { InnerHtml } from '@/components/Atoms/InnerHtml';
import { GradientBanner, GradientBannerImage } from '@/components/Atoms/GradientBanner';


interface Props {
    header?: string;
    subheader?: ReactElement;
}


const PromotionTournamentsBanner = (props: Props) => {

    const { t } = useI18n('components.Promotion.ContextBanners');

    const { header = t('tournamentsHeader'), subheader } = props;

    return (
        <GradientBanner imageTheme="light"
                        gradient="bg-content"
                        header={header}
                        subheader={subheader || <InnerHtml content={t('competeInGameFormatWithFriends')}/>}
                        image={(
                            <GradientBannerImage className="mt-6 -ml-12 banner__image">
                                <Icon28GameOutline width={140}
                                                   height={140}
                                                   style={{ transform: 'rotate(20deg)', marginRight: -25 }}/>
                            </GradientBannerImage>
                        )}
                        actions={(
                            <Link pushPage={{ id: '/tournaments/:page([0-9]+)' }}>
                                <Button mode="primary" size="m">
                                    {t('moveTo')}
                                </Button>
                            </Link>
                        )}/>
    );
};


export { PromotionTournamentsBanner };
