/**
 * QrFormView
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import React, { useEffect, useLayoutEffect } from 'react';

import { Form, Formik } from 'formik';

import { createValidator } from 'class-validator-formik';

import { useApolloClient } from '@apollo/client';

import { observer, useStore } from '@/pages/Core';
import { UserAuthStore } from '@/store/user/auth';
import { UserSettingsPageStore } from '@/pages/User/Settings/store';

import { Button, FormItem, Text } from '@exode.ru/vkui';
import { Icon28PincodeLockOutline } from '@vkontakte/icons';

import { useI18n } from '@/hooks/core';
import { Field, If, Link, Responsive } from '@/cutils';
import { SetOtpAuthInput } from '@/libs/class-validator';
import { QrCode } from '@/components/Atoms/QrCode';
import { ContentCard } from '@/components/Atoms/ContentCard';
import { CopyToClipboard } from '@/components/Atoms/CopyToClipboard';
import { OtpInputWrapper } from '@/components/Atoms/OtpInputWrapper';

import { mStickyBottom2PbSafe } from '@/styles/modules/button';


const QrFormView = observer(() => {

    const { t } = useI18n('pages.User.Settings');

    const { input, store } = useStore(UserSettingsPageStore);

    const { cache } = useApolloClient();

    const updateHasOtpInCache = (hasOtp: boolean) => (
        cache.modify({
            id: `UserEntity:${UserAuthStore.user?.id}`,
            fields: {
                meta: value => ({ ...value, hasOtp }),
            },
        })
    );

    useLayoutEffect(() => {
        store.generateSecret();
    }, []);

    useEffect(() => {
        updateHasOtpInCache(input.hasOtp);
    }, [ input.hasOtp ]);

    return (
        <Formik enableReinitialize
                validateOnBlur
                validateOnChange
                validateOnMount
                validate={createValidator(SetOtpAuthInput)}
                onSubmit={(__, formik) => store.setOtpAuth(formik)}
                initialValues={_.pick(input, [ 'otp', 'code', 'hasOtp' ])}>
            {({ handleSubmit, values, errors, isValid, touched, isSubmitting, setFieldValue }) => (
                <Form onSubmit={handleSubmit} className="flex flex-col flex-1 gap-1">
                    <If is={!values.hasOtp}>
                        <ContentCard hideOverflow
                                     mode="outline"
                                     className="px-2"
                                     activeAfter={false}
                                     header={t('howToEnable2FA')}
                                     after={(
                                         <Icon28PincodeLockOutline className="opacity-50 pr-3" width={50} height={50}/>
                                     )}
                                     caption={(
                                         <Text className="pr-10">
                                             {t('downloadAndInstallApp')}{' '}
                                             <Link toOuter="https://vk.cc/c743Cl">
                                                 <span className="text-accent">iOS</span>
                                             </Link>
                                             {` ${t('or')} `}
                                             <Link toOuter="https://vk.cc/2unule">
                                                 <span className="text-accent">Android</span>
                                             </Link>
                                             {'. '}
                                             {t('describedInDetails')}{' '}
                                             <Link toOuter="https://vk.cc/csLSUe">
                                                 <span className="text-accent">
                                                     {t('inStatement')}
                                                 </span>
                                             </Link>.
                                         </Text>
                                     )}/>
                    </If>

                    <If is={!!values.hasOtp}>
                        <ContentCard hideOverflow
                                     mode="outline"
                                     className="px-2"
                                     activeAfter={false}
                                     header={t('doYouWantToEnable2FA')}
                                     after={(
                                         <Icon28PincodeLockOutline className="opacity-50 pr-3" width={50} height={50}/>
                                     )}
                                     caption={(
                                         <Text className="pr-10">
                                             {t('2FADescription')}
                                         </Text>
                                     )}/>
                    </If>

                    <Responsive.Desktop>
                        <FormItem className={!values.hasOtp ? 'self-center my-4' : 'hidden'}>
                            <QrCode value={values.code} size={180}/>
                        </FormItem>
                    </Responsive.Desktop>

                    <Responsive.Mobile>
                        <If is={!values.hasOtp}>
                            <FormItem top={t('clickToCopyCode')}>
                                <CopyToClipboard link={input.secret} message={t('Copied')} vkComponent={(
                                    <Button size="l" stretched className="flex mx-auto my-4" mode="outline">
                                        {t('copyCode')}
                                    </Button>
                                )}/>
                            </FormItem>
                        </If>
                    </Responsive.Mobile>

                    <FormItem status={Field.status(errors, touched, 'otp')}
                              bottom={Field.message(errors, touched, 'otp')}
                              className={values.hasOtp ? '' : 'py-0'}
                              top={!values.hasOtp ? t('qrCodeInfoFromApp') : t('codeFromApp')}>
                        <OtpInputWrapper value={values.otp} onChange={value => {
                            store.setInput('otp', `${value}`);
                            setFieldValue('otp', `${value}`);
                        }}/>
                    </FormItem>

                    <FormItem className={`${mStickyBottom2PbSafe} d:mb-1`}>
                        <Button type="submit"
                                size="l"
                                stretched
                                className="content-shadow"
                                disabled={!isValid || isSubmitting}
                                loading={isSubmitting}>
                            {t('send')}
                        </Button>
                    </FormItem>
                </Form>
            )}
        </Formik>
    );
});


export { QrFormView };
