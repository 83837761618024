/**
 * StartHeader component
 *
 * @author: exode <hello@exode.ru>
 */

import React, { CSSProperties, ReactNode } from 'react';

import { If } from '@/cutils';

import { Caption, CaptionProps, Text, TextProps, Title, TitleProps } from '@exode.ru/vkui';

import { Container } from './StartHeader.styled';


interface Props {
    title: ReactNode;
    titleProps?: TitleProps;
    subtitle?: ReactNode;
    subtitleProps?: TextProps;
    caption?: ReactNode;
    captionProps?: CaptionProps;
    actions?: ReactNode;
    image?: ReactNode;
    alignment?: 'center';
    appearance?: 'dark-content' | 'light-content';
    className?: string;
    gradientClassName?: string;
    iframeScreen?: boolean;
    style?: CSSProperties;
}


const StartHeader = (props: Props) => {

    const {
        title,
        titleProps,
        subtitle,
        subtitleProps,
        caption,
        captionProps,
        actions,
        image,
        alignment,
        appearance,
        style,
        className,
        iframeScreen,
        gradientClassName,
    } = props;

    return (
        <Container style={style} className={[
            className,
            gradientClassName,
            iframeScreen ? 'max-w-[calc(100%_-_80px)]' : '',
            appearance === 'light-content' ? 'text-white' : 'text-primary',
            'flex flex-col items-center justify-center w-full box-border rounded-2xl d:rounded-[24px]',
        ].join(' ')}>
            <div className="flex flex-1 m:flex-col items-center justify-between viewport__container w-full">
                <div className={[
                    alignment === 'center' ? 'items-center' : 'items-start',
                    'flex flex-col gap-4 justify-center m:items-center w-full',
                ].join(' ')}>
                    <If is={!!caption}>
                        <Caption {...captionProps}>
                            {caption}
                        </Caption>
                    </If>

                    <Title level="1" weight="3" {...titleProps}>
                        {title}
                    </Title>

                    <If is={!!subtitle}>
                        <Text {...subtitleProps}>
                            {subtitle}
                        </Text>
                    </If>

                    <If is={!!actions}>
                        <>
                            {actions}
                        </>
                    </If>
                </div>

                <If is={!!image}>
                    <div>
                        {image}
                    </div>
                </If>
            </div>
        </Container>
    );
};


export { StartHeader };
