/**
 * FeatureBanner
 *
 * @author: exode <hello@exode.ru>
 */

import React, { ReactNode } from 'react';

import { gradient } from '@/styles/modules/gradient';
import { StartHeader } from '@/components/Landing/StartHeader';

import { Container } from './FeatureBanner.styled';


interface Props {
    title: string;
    subtitle: string;
    backgroundImage: string;
    caption?: ReactNode;
    actions?: ReactNode;
    startHeaderClassName?: string;
}


const FeatureBanner = (props: Props) => {

    const { title, subtitle, backgroundImage, caption, actions, startHeaderClassName } = props;

    return (
        <div className="flex items-start m:mx-3">
            <Container className="viewport__container mx-auto w-full">
                <StartHeader title={title}
                             subtitle={subtitle}
                             caption={caption}
                             actions={actions}
                             alignment="center"
                             appearance="light-content"
                             style={{ backgroundImage: `url(${backgroundImage})` }}
                             titleProps={{ weight: '1', className: 'fs-38 m:fs-25 mt-6' }}
                             gradientClassName={`bg-gradient-to-br ${gradient.purple500ToBlue500}`}
                             className={[
                                 startHeaderClassName,
                                 'py-12 m:!py-8 px-6 bg-cover bg-center m:bg-left-top bg-no-repeat text-center',
                             ].join(' ')}/>
            </Container>
        </div>
    );
};


export { FeatureBanner };
