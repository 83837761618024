/**
 * Landing images
 *
 * @author: exode <hello@exode.ru>
 */

const ElementBackground = (process.env.PUBLIC_URL || '') + '/static/images/landing/element-background.svg';
const GraffitiBackground = (process.env.PUBLIC_URL || '') + '/static/images/landing/graffiti-background.webp';
const GraffitiLineBackground = (process.env.PUBLIC_URL || '') + '/static/images/landing/graffiti-line-background.jpg';
const LinesBackground = (process.env.PUBLIC_URL || '') + '/static/images/landing/lines-background.svg';

export {
    ElementBackground,
    GraffitiBackground,
    GraffitiLineBackground,
    LinesBackground,
};
