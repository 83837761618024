/**
 * UseProductManage
 *
 * @author: exode <hello@exode.ru>
 */

import { UpdateProductInput, useProductUpdateMutation } from '@/codegen/graphql';


export const useProductManage = () => {

    const [ _updateProduct, {
        loading: updateProductLoading,
        error: updateProductError,
    } ] = useProductUpdateMutation();

    const updateProduct = (
        productId: number,
        product: UpdateProductInput,
    ) => {
        return _updateProduct({
            variables: {
                product,
                productId,
            },
        });
    };

    return {
        updateProduct,
        updateProductLoading,
        updateProductError,
    };
};
