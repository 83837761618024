/**
 * ModalCard component
 *
 * @author: exode <hello@exode.ru>
 */

import React, { useEffect } from 'react';

import { PageStore } from '@/store/core/page';
import { ModalCard as VkUiModalCard, ModalCardProps as VkUiModalCardProps } from '@exode.ru/vkui';

import styles from './ModalCard.module.scss';
import { Container } from './ModalCard.styled';


export interface ModalCardProps extends VkUiModalCardProps {
    minHeight?: string;
    justifyContent?: string;
    padding?: string;
    disableManualClose?: boolean;
    backdropFilter?: number;
}


const ModalCard = (props: ModalCardProps) => {

    const defaultBackdropFilter = 3;

    const {
        className,
        disableManualClose,
        backdropFilter = defaultBackdropFilter,
    } = props;

    const setBlurVariable = (backdropFilter: number) => {
        (document.querySelector('.vkui__root') as any)?.style.setProperty(
            '--modal_backdrop',
            `${backdropFilter}px`,
        );
    };

    useEffect(() => {
        if (disableManualClose) {
            PageStore.setIgnoreCloseModalCard(true);
        }

        return () => {
            if (disableManualClose) {
                PageStore.setIgnoreCloseModalCard(false);
            }
        };
    }, [ disableManualClose ]);

    useEffect(() => {
        setBlurVariable(backdropFilter);

        return () => {
            setBlurVariable(defaultBackdropFilter);
        };
    }, [ backdropFilter ]);

    return (
        <Container {...props} className={[
            className,
            disableManualClose ? styles.disableManualClose : '',
        ].join(' ')}>
            <VkUiModalCard {...props}/>
        </Container>
    );
};


export { ModalCard };
