/**
 * UseSellerOrganization
 *
 * @author: exode <hello@exode.ru>
 */

import { useI18n } from '@/hooks/core';

import { SaveStoreDeepKeys, SaveStoreKeys, Saving, SavingStore } from '@/store/core/saving';

import { Notify } from '@/cutils';

import {
    UpdateOrganizationSchoolInput,
    useSchoolOrganizationUpdateMutation,
    useSellerOrganizationUpdateMutation,
} from '@/codegen/graphql';


interface Props {
    withSavingStore?: boolean;
}


export const useSellerOrganization = (props: Props = {}) => {

    const { t } = useI18n('hooks.apollo.seller');

    /**
     * Marketplace organization mutation
     */
    const [ updateLogo, {
        loading: updateLogoLoading,
        error: updateLogoError,
    } ] = useSellerOrganizationUpdateMutation({
        onError: error => console.error(error),
        update: (cache, { data }) => {
            cache.modify({
                id: `SellerOrganizationEntity:${data?.sellerOrganizationUpdate.id}`,
                fields: {
                    logo: () => data?.sellerOrganizationUpdate.logo || null,
                },
            });

            Notify.toast.success(t('logoSuccessfullySaved'));
        },
    });

    /**
     * School organization mutation
     */
    const [ _schoolOrganizationUpdate, {
        loading: schoolOrganizationUpdateLoading,
        error: schoolOrganizationUpdateError,
    } ] = useSchoolOrganizationUpdateMutation();

    const schoolOrganizationUpdate = (
        sellerOrganizationId: number,
        organization: UpdateOrganizationSchoolInput,
    ) => {
        return _schoolOrganizationUpdate({
            variables: { sellerOrganizationId, organization },
        });
    };

    const setSellerOrganizationEditing = (editing = true) => {
        SavingStore.setEditing(
            SaveStoreKeys.School,
            SaveStoreDeepKeys.SellerOrganization,
            editing,
        );
    };

    Saving.listenSetSaving(
        SaveStoreKeys.School,
        SaveStoreDeepKeys.SellerOrganization,
        [
            updateLogoLoading,
            schoolOrganizationUpdateLoading,
        ],
        [
            updateLogoError,
            schoolOrganizationUpdateError,
        ],
        { disabled: !props?.withSavingStore },
    );

    return {
        updateLogo,
        updateLogoLoading,
        schoolOrganizationUpdate,
        setSellerOrganizationEditing,
        schoolOrganizationUpdateLoading,
    };
};
