/**
 * UseProductPublication
 *
 * @author: exode <hello@exode.ru>
 */

import { Notify } from '@/cutils';
import { useConfirm, useI18n } from '@/hooks/core';

import { ProductApprove, ProductLaunchStatus, useProductPublicationReviewLaunchMutation } from '@/codegen/graphql';


interface Props {
    launchId: number;
}


export const useProductPublication = (props: Props) => {

    const { launchId } = props;

    const { t } = useI18n('hooks.apollo.product');

    const [ reviewLaunch, { loading: reviewLaunchLoading } ] = useProductPublicationReviewLaunchMutation({
        onError: (error) => console.log(error),
        onCompleted: (data) => {
            const { productPublicationReviewLaunch: { status } } = data;

            if (status === ProductLaunchStatus.Published) {
                Notify.toast.success(t('published&MessageWasSent'));
            }

            if (status === ProductLaunchStatus.Declined) {
                Notify.toast.error(t('declined&MessageWasSent'));
            }
        },
    });

    const publishLaunch = async () => {
        return reviewLaunch({
                variables: {
                    launchId,
                    review: {
                        status: ProductLaunchStatus.Published,
                        approves: [ ProductApprove.Certified ],
                    },
                },
            },
        );
    };

    const { openConfirm: declineLaunchPublication } = useConfirm({
        title: t('confirmTheRejectionOfThePublication'),
        subtitle: t('writeTheReasonForTheRejection'),
        isLoading: reviewLaunchLoading,
        buttons: {
            confirm: { text: t('reject') },
        },
        prompt: {
            required: true,
            mode: 'input',
            minLength: 5,
            placeholder: t('reason'),
        },
        onConfirm: ({ prompt: message }) => reviewLaunch({
            variables: {
                launchId,
                review: {
                    message,
                    approves: [],
                    status: ProductLaunchStatus.Declined,
                },
            },
        }),
    });

    return {
        publishLaunch,
        declineLaunchPublication,
        reviewLaunchLoading,
    };
};
