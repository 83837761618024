/**
 * Use scroll to ref
 *
 * @author: exode <hello@exode.ru>
 */

import { RefObject, useEffect } from 'react';

import { useLocation } from '@/router/index';

import { ScrollHelper } from '@/helpers/ui';
import { Router } from '@/services/Utils/Router';


const useScrollToRef = (
    elementRef: RefObject<any>,
    paramKey: string,
    offset = -50,
) => {

    const { route: { params } } = useLocation();

    useEffect(() => {
        if (params[paramKey] === 'true' && elementRef.current) {
            ScrollHelper.to(
                ScrollHelper.calcTopToElement(elementRef.current, offset),
                false,
            );

            Router.updateParams({ [paramKey]: 'false' });
        }
    }, [ params[paramKey], elementRef.current ]);
};


export { useScrollToRef };
