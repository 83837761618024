/**
 * ModalResponsive component
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { ConfigStore, observer } from '@/store/core/config';

import { ModalCardProps as VkUiModalCardProps, ModalPageProps as VkUiModalPageProps } from '@exode.ru/vkui';

import { If, Modal } from '@/cutils';


export interface ModalResponsiveProps extends VkUiModalCardProps {
    cardProps?: VkUiModalCardProps;
    pageProps?: VkUiModalPageProps;
}


const ModalResponsive = observer((props: ModalResponsiveProps) => {

    const { cardProps, pageProps, ...rest } = props;

    return (
        <>
            <If is={ConfigStore.isDesktop}>
                <Modal.Card {...rest} {...cardProps}/>
            </If>

            <If is={!ConfigStore.isDesktop}>
                <Modal.Page {...rest} {...pageProps}/>
            </If>
        </>
    );
});


export { ModalResponsive };
