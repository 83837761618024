/**
 * Notistack util
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import { toJS } from 'mobx';
import toast from 'react-hot-toast';
import { closeSnackbar, enqueueSnackbar, SnackbarKey } from 'notistack';

import { Storage } from '@/api/storage';
import { ConfigStore } from '@/store/core/config';

import {
    BaseSnackbarProps,
    ChatSnackbarProps,
    DefaultSnackbarProps,
    NotificationSnackbarProps,
} from '@/components/Atoms/Notistack';


class Notify {

    private static get config() {
        return {
            TransitionProps: {
                direction: 'right',
            },
            anchorOrigin: ConfigStore.isDesktop
                ? {
                    vertical: 'bottom',
                    horizontal: 'left',
                }
                : {
                    vertical: 'top',
                    horizontal: 'center',
                },
        };
    }

    static toast = toast;

    static vkui(props: DefaultSnackbarProps) {
        const { message, uniqKey } = props;

        const options = this.transformOptions(props.options);

        if (uniqKey) {
            this.closeAllByUniqKey(uniqKey, false);
        }

        const key = enqueueSnackbar(message, {
            variant: 'default',
            anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
            },
            className: [
                'pt-safe pb-safe',
                options?.className,
            ].join(' '),
            ...options,
            ...props,
        });

        if (uniqKey) {
            this.addUniqKey(uniqKey, key);
        }
    }

    static notification(props: NotificationSnackbarProps) {
        const { uniqKey } = props;

        const options = this.transformOptions(props.options);

        if (this.keyIsExist(uniqKey, props.key as string)) {
            return;
        }

        if (uniqKey) {
            this.closeAllByUniqKey(uniqKey);
        }

        const key = enqueueSnackbar({
            ...this.config,
            ...props,
            ...options,
            variant: 'notification',
        });

        if (uniqKey) {
            this.addUniqKey(uniqKey, key);
        }
    }

    static chat(props: ChatSnackbarProps) {
        const { uniqKey } = props;

        const options = this.transformOptions(props.options);

        if (this.keyIsExist(uniqKey, props.key as string)) {
            return;
        }

        if (uniqKey) {
            this.closeAllByUniqKey(uniqKey);
        }

        const key = enqueueSnackbar({
            ...this.config,
            ...props,
            ...options,
            variant: 'chat',
        });

        if (uniqKey) {
            this.addUniqKey(uniqKey, key);
        }
    }

    static closeAllByUniqKey(
        uniqKey: string,
        throttle = true,
    ) {
        ConfigStore.getSnackbarKeys.get(uniqKey)?.map((key) => {
            throttle
                ? _.throttle(() => closeSnackbar(key), 100)
                : closeSnackbar(key);
        });
    }

    static addUniqKey(
        uniqKey: string,
        key: SnackbarKey,
    ) {
        ConfigStore.getSnackbarKeys.set(
            uniqKey,
            [
                ...(ConfigStore.getSnackbarKeys.get(uniqKey) ?? []),
                key,
            ],
        );
    }

    /**
     * Check key exists
     * @param {string} uniqKey
     * @param {string} messageKey
     */
    static keyIsExist(
        uniqKey: string | undefined,
        messageKey: string | undefined,
    ) {
        if (uniqKey && messageKey) {
            return toJS(ConfigStore.getSnackbarKeys.get(uniqKey))?.includes(messageKey);
        }
    }

    static closeSnackbar(
        key?: string | number,
        triggerEventOnly = false,
    ) {
        if (!key) {
            return;
        }

        if (!triggerEventOnly) {
            closeSnackbar(key);
        }

        Storage.set('system:event-stream', {
            type: 'notistack',
            payload: { key, event: 'closed' },
        });
    }

    static transformOptions(options: BaseSnackbarProps['options']) {
        if (options) {
            options.autoHideDuration = this.calcDuration(options, null);
        }

        return options;
    }

    static calcDuration<T>(
        options: BaseSnackbarProps['options'],
        byDefault: undefined | null = undefined,
    ) {
        return (
            options?.neverToHide
                ? Number.MAX_SAFE_INTEGER / 5
                : (options?.autoHideDuration || byDefault as T)
        );
    }

    static handleStorageNotistackEvent(
        newValue?: { key: string, event: 'closed' },
    ) {
        try {
            const { key, event } = newValue || {};

            if (key && event === 'closed') {
                closeSnackbar(key);
            }
        } catch (e) {
            console.log(e);
        }
    }
}


export { Notify };
