/**
 * IsOnlyDate validator
 *
 * @author: exode <hello@exode.ru>
 */

import * as _ from 'lodash';

import { unitOfTime } from 'moment';

import { registerDecorator, ValidationArguments, ValidationOptions } from 'class-validator';


const moment = require('moment');


export type StartFinishFieldName = 'from' | 'to';

interface DateCompareOptions {
    isOptional?: ((o: Record<any, any>, v: any) => boolean) | boolean,
    granularity?: unitOfTime.StartOf,
}


/**
 * Validate is YYYY-MM-DD
 * @param {ValidationOptions} validationOptions
 * @returns {(object: Object, propertyName: string) => void}
 * @constructor
 */
export function IsOnlyDate(validationOptions?: ValidationOptions & { optional?: boolean }) {
    return function (object: Object, propertyName: string) {
        registerDecorator({
            name: 'IsOnlyDate',
            target: object.constructor,
            propertyName: propertyName,
            constraints: [],
            options: {
                message: 'Please provide only date like YYYY-MM-DD',
                ...validationOptions,
            },
            validator: {
                validate(value: any) {
                    if (!value) {
                        return !!validationOptions?.optional;
                    }

                    value = new Date(value).toISOString()?.split('T')[0];

                    const regex = /([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/;

                    return value && typeof value === 'string' && regex.test(value);
                },
            },
        });
    };
}

/**
 * Compare two dates with provided moment method name
 * @param {'isBefore' | 'isAfter' | 'isSameOrBefore' | 'isSameOrAfter'} method
 * @param {string} property
 * @param {DateCompareOptions} options
 * @param {ValidationOptions} validationOptions
 * @returns {(object: Object, propertyName: string) => void}
 * @constructor
 */
export function DateCompare(
    method: 'isBefore' | 'isAfter' | 'isSameOrBefore' | 'isSameOrAfter',
    property: string,
    options?: DateCompareOptions,
    validationOptions?: ValidationOptions,
) {
    return function (object: Object, propertyName: string) {
        registerDecorator({
            name: 'DateCompare',
            target: object.constructor,
            propertyName: propertyName,
            constraints: [ property ],
            options: validationOptions,
            validator: {
                validate(value: any, args: ValidationArguments) {
                    const relatedValue = (args.object as any)[args.constraints[0]];

                    const isOptional = _.isFunction(options?.isOptional)
                        ? options?.isOptional(args.object, value)
                        : options?.isOptional;

                    if (isOptional && !value || (moment(value).isValid() && !relatedValue)) {
                        return true;
                    }

                    return moment(value).isValid() && (
                        moment(value)[method](relatedValue, options?.granularity)
                    );
                },
            },
        });
    };
}

/**
 * Validate date border (start and finished not crossed)
 * @param {Date | undefined} value
 * @param {StartFinishFieldName} name
 * @param {Date} from
 * @param {Date} to
 * @returns {boolean}
 */
export const validateDateBorder = (
    value: Date | undefined,
    name: StartFinishFieldName,
    from?: Date,
    to?: Date,
) => {
    if (moment(value).isSame(name === 'to' ? from : to)) {
        return true;
    }

    return (!value || !to) || (
        name === 'from'
            ? moment(value).isSameOrBefore(to)
            : moment(value).isSameOrAfter(from)
    );
};


/**
 * Validate date range (value, from and to interval)
 * @param {Date | undefined} value
 * @param {Date} from
 * @param {Date} to
 * @returns {}
 */
export const validateDateRange = (
    value: Date | undefined,
    from?: Date,
    to?: Date,
) => {
    if (!value) {
        return {
            valid: true,
        };
    }

    if (from && to) {
        return {
            valid: moment(value).isBetween(from, to, undefined, '[]'),
            message: [
                'Дата должна быть в пределах',
                moment(from).format('DD.MM.YY HH:mm'),
                ' - ',
                moment(to).format('DD.MM.YY HH:mm'),
            ].join(' '),
        };
    }

    if (from && !to) {
        return {
            valid: moment(value).isSameOrAfter(from),
            message: [
                'Дата должна быть позднее',
                moment(from).format('DD.MM.YY HH:mm'),
            ].join(' '),
        };
    }

    if (!from && to) {
        return {
            valid: moment(value).isSameOrBefore(to),
            message: [
                'Дата должна быть ранее',
                moment(to).format('DD.MM.YY HH:mm'),
            ].join(' '),
        };
    }

    return { valid: false };
};
