/**
 * TournamentsPlaceholderView
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { Button } from '@exode.ru/vkui';
import { Icon24AddSquareOutline, Icon24GameOutline } from '@vkontakte/icons';

import { Link } from '@/cutils';

import { Placeholder } from '@/components/Atoms/Placeholder';


const TournamentsPlaceholderView = () => {
    return (
        <Placeholder iconSize={96}
                     innerPadding="2em 0"
                     header="Турниры не найдены"
                     icon={<Icon24GameOutline className="pt-0 text-accent opacity-[0.85]"/>}
                     content={(
                         <span className="m:w-full w-9/10 flex mx-auto">
                             Всегда можно начать с чистого листа или турнира

                         </span>
                     )}
                     action={(
                         <Link pushModal={{ id: 'tournament-create' }} pushPageMobile={{ id: '/tournaments/create' }}>
                             <Button size="l" before={<Icon24AddSquareOutline/>}>
                                 Создать турнир
                             </Button>
                         </Link>
                     )}/>
    );
};


export { TournamentsPlaceholderView };
