/**
 * BootstrapService - точка входа в сервисы приложения
 *
 * @author: exode <hello@exode.ru>
 */

import { IS_SCHOOL } from '@/root/src/env';

import { BaseService } from '@/services/Core/Base';

import { AnalyticsService } from '@/services/Analytics/Analytics';
import { CourseService } from '@/services/Course/Course';
import { NativeService } from '@/services/Native/Native';
import { SchoolService } from '@/services/School/School';
import { UiService } from '@/services/Ui/Ui';
import { UserService } from '@/services/User/User';
import { FcmMessagingService } from '@/services/Utils/FcmMessaging';
import { LoggerService } from '@/services/Utils/Logger';
import { VkService } from '@/services/Vk/Vk';


class BootstrapService extends BaseService {

    /**
     * Регистрация сервисов (add listeners)
     * Есть возможность добавлять события для window / document
     * (вызывается перед React.render / native deviceready)
     */
    static register() {
        NativeService.register();
        UiService.register();
        UserService.register();
        VkService.register();
        CourseService.register();

        if (IS_SCHOOL) {
            SchoolService.register();
        }
    }

    /**
     * Инициализация сервисов (вызов init методов для сервисов)
     * Уже доступны объекты native, DOM-дерево и т.д.
     * (вызывается как callback React.render)
     */
    static ready() {
        AnalyticsService.init();
        NativeService.init();
        CourseService.init();
        UserService.init();
        FcmMessagingService.init();
        LoggerService.init();
        VkService.init();

        if (IS_SCHOOL) {
            SchoolService.init();
        }
    }

}


export { BootstrapService };
