/**
 * NotificationSubscription component
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import React, { useRef } from 'react';

import { useI18n } from '@/hooks/core';

import { PageService } from '@/services/Utils/Page';
import { ConfigStore, observer } from '@/store/core/config';

import {
    NotificationCountByStatusDocument,
    NotificationCountByStatusQuery,
    NotificationsDocument,
    NotificationsQuery,
    NotificationStatus,
    useNotificationListenNewUnreadCountSubscription,
    useNotificationListenNewUnreadSubscription,
} from '@/codegen/graphql';

import { Notify } from '@/cutils';

import { notificationListAndSortConfig } from '@/pages/Profile/Notifications/views/UnreadNotificationsView';


const NotificationSubscription = observer(() => {

    const { t } = useI18n('components.Subscriptions.Notification');

    const newNotificationsSet = useRef<Set<number>>(new Set());

    useNotificationListenNewUnreadSubscription({
        onData: ({ data: { data }, client: { cache } }) => {
            if (!data) {
                return;
            }

            const { notificationListenNewNotificationItemOrUpdate: notificationData } = data;

            const cachedUnread = cache.readQuery<NotificationsQuery>({
                query: NotificationsDocument,
                variables: { ...notificationListAndSortConfig, filter: { statuses: [ NotificationStatus.Unread ] } },
            });

            const notification = { ...notificationData, createdAt: new Date() };

            const isNewNotification = () => {
                if (notificationData.status !== NotificationStatus.Unread) {
                    return false;
                }

                if (cachedUnread?.notificationFindMany.items?.map(({ id }) => id).includes(notificationData.id)) {
                    return false;
                }

                return !newNotificationsSet.current.has(notificationData.id);
            };

            if (!notification.isGhost && isNewNotification() && cachedUnread?.notificationFindMany.items) {
                cache.writeQuery<NotificationsQuery>({
                    query: NotificationsDocument,
                    variables: {
                        ...notificationListAndSortConfig,
                        filter: { statuses: [ NotificationStatus.Unread ] },
                    },
                    data: {
                        notificationFindMany: {
                            __typename: 'ListNotificationOutput',
                            count: (cachedUnread?.notificationFindMany.count || 0) + 1,
                            items: _.isEmpty(cachedUnread?.notificationFindMany.items)
                                ? [ notification ]
                                : [ notification, ...cachedUnread!.notificationFindMany.items! ],
                        },
                    },
                });
            }

            if (isNewNotification()) {
                const { isMainTab, isInInteractive } = ConfigStore;

                if (isMainTab && !isInInteractive) {
                    PageService.startToggleTab({ title: t('newNotification') });
                }

                Notify.notification({
                    notification,
                    key: `notification:${notification.id}`,
                    options: {
                        autoHideDuration: 60000,
                        neverToHide: !document.hasFocus(),
                    },
                });

                newNotificationsSet.current.add(notificationData.id);
            }
        },
    });

    useNotificationListenNewUnreadCountSubscription({
        onData: ({ data: { data }, client, client: { cache } }) => {
            const variables = { notificationStatus: NotificationStatus.Unread };

            const cachedUnreadCount = cache.readQuery<NotificationCountByStatusQuery>({
                variables,
                query: NotificationCountByStatusDocument,
            });

            const { notificationListenNewUnreadNotificationCount: unreadCount } = data || {};

            const { notificationCountByStatus: countByStatus } = cachedUnreadCount || {};

            if (data && countByStatus !== unreadCount) {
                cache.writeQuery<NotificationCountByStatusQuery>({
                    variables,
                    query: NotificationCountByStatusDocument,
                    data: {
                        __typename: 'Query',
                        notificationCountByStatus: unreadCount || 0,
                    },
                });
            }

            client.refetchQueries({
                include: [
                    NotificationsDocument,
                ],
            });
        },
    });

    return <></>;
});


export { NotificationSubscription };
