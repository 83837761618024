/**
 * IconMathBasic component
 *
 * @author: exode <hello@exode.ru>
 */

import React, { SVGProps } from 'react';


const IconMathBasic = (props: SVGProps<SVGSVGElement>) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg"
             height={858.953}
             viewBox="0 0 644.215 644.215"
             width={858.953} {...props}>
            <path d="M585.453 0H58.547C26.21 0 0 26.21 0 58.543v526.91C0 617.79 26.21 644 58.547 644h526.906C617.79 644 644 617.79 644 585.453V58.547C644 26.21 617.79 0 585.457 0h-.004ZM556.18 556.18H87.816V87.816H556.18Zm0 0"
                  style={{
                      stroke: 'none',
                      fillRule: 'nonzero',
                      fill: 'gold',
                      fillOpacity: 1,
                  }}/>
            <path d="M585.453 58.547H58.547v526.906h526.906ZM556.18 556.18H87.816V87.816H556.18Zm0 0" style={{
                stroke: 'none',
                fillRule: 'nonzero',
                fill: '#ffbe00',
                fillOpacity: 1,
            }}/>
            <path d="M102.453 556.18H570.82c8.082 0 14.633 6.554 14.633 14.636 0 8.086-6.55 14.637-14.633 14.637H102.453c-8.082 0-14.633-6.55-14.633-14.637-.004-8.082 6.551-14.636 14.633-14.636Zm0 0"
                  style={{
                      stroke: 'none',
                      fillRule: 'nonzero',
                      fill: '#ffa300',
                      fillOpacity: 1,
                  }}/>
            <path d="M585.453 102.453V570.82c0 8.082-6.55 14.633-14.633 14.633-8.086 0-14.636-6.55-14.636-14.633V102.453c0-8.082 6.55-14.633 14.636-14.633 8.082-.004 14.633 6.551 14.633 14.633Zm0 0"
                  style={{
                      stroke: 'none',
                      fillRule: 'nonzero',
                      fill: '#ffa300',
                      fillOpacity: 1,
                  }}/>
            <path d="m585.453 556.18 23.211 23.21c8.086 8.087 8.086 21.192 0 29.274-8.082 8.082-21.187 8.082-29.273 0l-23.207-23.21Zm0 0"
                  style={{
                      stroke: 'none',
                      fillRule: 'nonzero',
                      fill: '#ffa300',
                      fillOpacity: 1,
                  }}/>
            <path d="m556.18 58.547 23.21-23.211c8.087-8.086 21.192-8.086 29.274 0 8.082 8.082 8.082 21.187 0 29.273l-23.21 23.207ZM58.547 556.18l-23.211 23.21c-8.086 8.087-8.086 21.192 0 29.274 8.082 8.082 21.187 8.082 29.273 0l23.207-23.21ZM87.816 58.547 64.61 35.336c-8.086-8.086-21.191-8.086-29.273 0-8.082 8.082-8.082 21.187 0 29.273l23.21 23.207Zm0 0"
                  style={{
                      stroke: 'none',
                      fillRule: 'nonzero',
                      fill: '#ffbe00',
                      fillOpacity: 1,
                  }}/>
            <path d="M87.816 146.363H556.18c16.168 0 29.273 13.106 29.273 29.274 0 16.164-13.105 29.27-29.273 29.27H87.816c-16.164 0-29.27-13.106-29.27-29.27 0-16.168 13.106-29.274 29.27-29.274Zm0 0"
                  style={{
                      stroke: 'none',
                      fillRule: 'nonzero',
                      fill: '#dfe5ff',
                      fillOpacity: 1,
                  }}/>
            <path d="M102.453 175.637h439.094c8.082 0 14.637 6.55 14.637 14.636 0 8.082-6.555 14.637-14.637 14.637H102.453c-8.082 0-14.633-6.555-14.633-14.637-.004-8.085 6.551-14.636 14.633-14.636Zm0 0"
                  style={{
                      stroke: 'none',
                      fillRule: 'nonzero',
                      fill: '#b8c2ff',
                      fillOpacity: 1,
                  }}/>
            <path d="M87.816 292.727H556.18c16.168 0 29.273 13.105 29.273 29.273s-13.105 29.273-29.273 29.273H87.816c-16.164 0-29.27-13.105-29.27-29.273s13.106-29.273 29.27-29.273Zm0 0"
                  style={{
                      stroke: 'none',
                      fillRule: 'nonzero',
                      fill: '#dfe5ff',
                      fillOpacity: 1,
                  }}/>
            <path d="M102.453 322h439.094c8.082 0 14.637 6.55 14.637 14.637 0 8.082-6.555 14.636-14.637 14.636H102.453c-8.082 0-14.633-6.554-14.633-14.636-.004-8.082 6.551-14.637 14.633-14.637Zm0 0"
                  style={{
                      stroke: 'none',
                      fillRule: 'nonzero',
                      fill: '#b8c2ff',
                      fillOpacity: 1,
                  }}/>
            <path d="M87.816 439.09H556.18c16.168 0 29.273 13.105 29.273 29.273 0 16.164-13.105 29.274-29.273 29.274H87.816c-16.164 0-29.27-13.11-29.27-29.274 0-16.168 13.106-29.273 29.27-29.273Zm0 0"
                  style={{
                      stroke: 'none',
                      fillRule: 'nonzero',
                      fill: '#dfe5ff',
                      fillOpacity: 1,
                  }}/>
            <path d="M102.453 468.363h439.094c8.082 0 14.637 6.555 14.637 14.637s-6.555 14.637-14.637 14.637H102.453c-8.082 0-14.633-6.555-14.633-14.637-.004-8.082 6.551-14.637 14.633-14.637Zm0 0"
                  style={{
                      stroke: 'none',
                      fillRule: 'nonzero',
                      fill: '#b8c2ff',
                      fillOpacity: 1,
                  }}/>
            <path d="M131.727 117.09c24.25 0 43.91 19.66 43.91 43.91v29.273c0 24.25-19.66 43.91-43.91 43.91-24.25 0-43.91-19.66-43.91-43.91V161c0-24.25 19.66-43.91 43.91-43.91ZM248.816 117.09c24.25 0 43.91 19.66 43.91 43.91v29.273c0 24.25-19.656 43.91-43.91 43.91-24.25 0-43.906-19.66-43.906-43.91V161c0-24.25 19.656-43.91 43.906-43.91ZM365.91 117.09c24.25 0 43.906 19.66 43.906 43.91v29.273c0 24.25-19.656 43.91-43.906 43.91S322 214.524 322 190.274V161c0-24.25 19.66-43.91 43.91-43.91Zm0 0"
                  style={{
                      stroke: 'none',
                      fillRule: 'nonzero',
                      fill: '#005eee',
                      fillOpacity: 1,
                  }}/>
            <path d="M483 117.09c24.25 0 43.91 19.66 43.91 43.91v29.273c0 24.25-19.66 43.91-43.91 43.91-24.25 0-43.91-19.66-43.91-43.91V161c0-24.25 19.66-43.91 43.91-43.91Zm0 0"
                  style={{
                      stroke: 'none',
                      fillRule: 'nonzero',
                      fill: '#bc5cf3',
                      fillOpacity: 1,
                  }}/>
            <path d="M131.727 263.453c24.25 0 43.91 19.66 43.91 43.91v29.274c0 24.25-19.66 43.91-43.91 43.91-24.25 0-43.91-19.66-43.91-43.91v-29.274c0-24.25 19.66-43.91 43.91-43.91Zm0 0"
                  style={{
                      stroke: 'none',
                      fillRule: 'nonzero',
                      fill: '#005eee',
                      fillOpacity: 1,
                  }}/>
            <path d="M219.547 263.453c24.25 0 43.906 19.66 43.906 43.91v29.274c0 24.25-19.656 43.91-43.906 43.91s-43.91-19.66-43.91-43.91v-29.274c0-24.25 19.656-43.91 43.91-43.91ZM365.91 263.453c24.25 0 43.906 19.66 43.906 43.91v29.274c0 24.25-19.656 43.91-43.906 43.91S322 360.887 322 336.637v-29.274c0-24.25 19.66-43.91 43.91-43.91Zm0 0"
                  style={{
                      stroke: 'none',
                      fillRule: 'nonzero',
                      fill: '#005eee',
                      fillOpacity: 1,
                  }}/>
            <path d="M512.273 263.453c24.25 0 43.91 19.66 43.91 43.91v29.274c0 24.25-19.66 43.91-43.91 43.91-24.25 0-43.91-19.66-43.91-43.91v-29.274c0-24.25 19.66-43.91 43.91-43.91Zm0 0"
                  style={{
                      stroke: 'none',
                      fillRule: 'nonzero',
                      fill: '#bc5cf3',
                      fillOpacity: 1,
                  }}/>
            <path d="M161 409.816c24.25 0 43.91 19.66 43.91 43.91V483c0 24.25-19.66 43.91-43.91 43.91-24.25 0-43.91-19.66-43.91-43.91v-29.273c0-24.25 19.66-43.91 43.91-43.91ZM278.09 409.816c24.25 0 43.91 19.66 43.91 43.91V483c0 24.25-19.656 43.91-43.91 43.91-24.25 0-43.906-19.66-43.906-43.91v-29.273c0-24.25 19.656-43.91 43.906-43.91ZM365.91 409.816c24.25 0 43.906 19.66 43.906 43.91V483c0 24.25-19.656 43.91-43.906 43.91S322 507.25 322 483v-29.273c0-24.25 19.66-43.91 43.91-43.91Zm0 0"
                  style={{
                      stroke: 'none',
                      fillRule: 'nonzero',
                      fill: '#005eee',
                      fillOpacity: 1,
                  }}/>
            <path d="M483 409.816c24.25 0 43.91 19.66 43.91 43.91V483c0 24.25-19.66 43.91-43.91 43.91-24.25 0-43.91-19.66-43.91-43.91v-29.273c0-24.25 19.66-43.91 43.91-43.91Zm0 0"
                  style={{
                      stroke: 'none',
                      fillRule: 'nonzero',
                      fill: '#bc5cf3',
                      fillOpacity: 1,
                  }}/>
            <path d="M146.363 146.363c0 16.168-13.105 29.274-29.273 29.274-16.164 0-29.27-13.106-29.27-29.274s13.106-29.273 29.27-29.273c16.168 0 29.273 13.105 29.273 29.273Zm0 0"
                  style={{
                      stroke: 'none',
                      fillRule: 'nonzero',
                      fill: '#079cff',
                      fillOpacity: 1,
                  }}/>
            <path d="M117.09 131.727c0 8.082-6.55 14.636-14.637 14.636-8.082 0-14.633-6.554-14.633-14.636 0-8.082 6.551-14.637 14.633-14.637 8.086 0 14.637 6.555 14.637 14.637Zm0 0"
                  style={{
                      stroke: 'none',
                      fillRule: 'nonzero',
                      fill: '#bce4ff',
                      fillOpacity: 1,
                  }}/>
            <path d="M263.453 146.363c0 16.168-13.105 29.274-29.27 29.274-16.167 0-29.273-13.106-29.273-29.274s13.106-29.273 29.274-29.273c16.164 0 29.27 13.105 29.27 29.273Zm0 0"
                  style={{
                      stroke: 'none',
                      fillRule: 'nonzero',
                      fill: '#079cff',
                      fillOpacity: 1,
                  }}/>
            <path d="M234.184 131.727c0 8.082-6.555 14.636-14.637 14.636-8.086 0-14.637-6.554-14.637-14.636 0-8.082 6.55-14.637 14.637-14.637 8.082 0 14.637 6.555 14.637 14.637Zm0 0"
                  style={{
                      stroke: 'none',
                      fillRule: 'nonzero',
                      fill: '#bce4ff',
                      fillOpacity: 1,
                  }}/>
            <path d="M380.547 146.363c0 16.168-13.106 29.274-29.274 29.274S322 162.53 322 146.363s13.105-29.273 29.273-29.273 29.274 13.105 29.274 29.273Zm0 0"
                  style={{
                      stroke: 'none',
                      fillRule: 'nonzero',
                      fill: '#079cff',
                      fillOpacity: 1,
                  }}/>
            <path d="M351.273 131.727c0 8.082-6.554 14.636-14.636 14.636-8.082 0-14.637-6.554-14.637-14.636 0-8.082 6.555-14.637 14.637-14.637s14.636 6.555 14.636 14.637Zm0 0"
                  style={{
                      stroke: 'none',
                      fillRule: 'nonzero',
                      fill: '#bce4ff',
                      fillOpacity: 1,
                  }}/>
            <path d="M497.637 146.363c0 16.168-13.106 29.274-29.274 29.274s-29.273-13.106-29.273-29.274 13.105-29.273 29.273-29.273 29.274 13.105 29.274 29.273Zm0 0"
                  style={{
                      stroke: 'none',
                      fillRule: 'nonzero',
                      fill: '#c175ff',
                      fillOpacity: 1,
                  }}/>
            <path d="M468.363 131.727c0 8.082-6.55 14.636-14.636 14.636-8.082 0-14.637-6.554-14.637-14.636 0-8.082 6.555-14.637 14.637-14.637 8.086 0 14.636 6.555 14.636 14.637Zm0 0"
                  style={{
                      stroke: 'none',
                      fillRule: 'nonzero',
                      fill: '#d7a5ff',
                      fillOpacity: 1,
                  }}/>
            <path d="M146.363 292.727c0 16.168-13.105 29.273-29.273 29.273-16.164 0-29.27-13.105-29.27-29.273 0-16.164 13.106-29.274 29.27-29.274 16.168 0 29.273 13.11 29.273 29.274Zm0 0"
                  style={{
                      stroke: 'none',
                      fillRule: 'nonzero',
                      fill: '#079cff',
                      fillOpacity: 1,
                  }}/>
            <path d="M117.09 278.09c0 8.086-6.55 14.637-14.637 14.637-8.082 0-14.633-6.551-14.633-14.637 0-8.082 6.551-14.637 14.633-14.637 8.086 0 14.637 6.555 14.637 14.637Zm0 0"
                  style={{
                      stroke: 'none',
                      fillRule: 'nonzero',
                      fill: '#bce4ff',
                      fillOpacity: 1,
                  }}/>
            <path d="M234.184 292.727c0 16.168-13.106 29.273-29.274 29.273s-29.273-13.105-29.273-29.273c0-16.164 13.105-29.274 29.273-29.274s29.274 13.11 29.274 29.274Zm0 0"
                  style={{
                      stroke: 'none',
                      fillRule: 'nonzero',
                      fill: '#079cff',
                      fillOpacity: 1,
                  }}/>
            <path d="M204.91 278.09c0 8.086-6.555 14.637-14.637 14.637-8.085 0-14.636-6.551-14.636-14.637 0-8.082 6.55-14.637 14.636-14.637 8.082 0 14.637 6.555 14.637 14.637Zm0 0"
                  style={{
                      stroke: 'none',
                      fillRule: 'nonzero',
                      fill: '#bce4ff',
                      fillOpacity: 1,
                  }}/>
            <path d="M380.547 292.727c0 16.168-13.106 29.273-29.274 29.273S322 308.895 322 292.727s13.105-29.274 29.273-29.274 29.274 13.106 29.274 29.274Zm0 0"
                  style={{
                      stroke: 'none',
                      fillRule: 'nonzero',
                      fill: '#079cff',
                      fillOpacity: 1,
                  }}/>
            <path d="M351.273 278.09c0 8.086-6.554 14.637-14.636 14.637-8.082 0-14.637-6.551-14.637-14.637 0-8.082 6.555-14.637 14.637-14.637s14.636 6.555 14.636 14.637Zm0 0"
                  style={{
                      stroke: 'none',
                      fillRule: 'nonzero',
                      fill: '#bce4ff',
                      fillOpacity: 1,
                  }}/>
            <path d="M526.91 292.727c0 16.168-13.105 29.273-29.273 29.273s-29.274-13.105-29.274-29.273 13.106-29.274 29.274-29.274 29.273 13.106 29.273 29.274Zm0 0"
                  style={{
                      stroke: 'none',
                      fillRule: 'nonzero',
                      fill: '#c175ff',
                      fillOpacity: 1,
                  }}/>
            <path d="M497.637 278.09c0 8.086-6.555 14.637-14.637 14.637s-14.637-6.551-14.637-14.637c0-8.082 6.555-14.637 14.637-14.637s14.637 6.555 14.637 14.637Zm0 0"
                  style={{
                      stroke: 'none',
                      fillRule: 'nonzero',
                      fill: '#d7a5ff',
                      fillOpacity: 1,
                  }}/>
            <path d="M175.637 439.09c0 16.168-13.106 29.273-29.274 29.273s-29.273-13.105-29.273-29.273 13.105-29.274 29.273-29.274 29.274 13.106 29.274 29.274Zm0 0"
                  style={{
                      stroke: 'none',
                      fillRule: 'nonzero',
                      fill: '#079cff',
                      fillOpacity: 1,
                  }}/>
            <path d="M146.363 424.453c0 8.086-6.554 14.637-14.636 14.637-8.082 0-14.637-6.55-14.637-14.637 0-8.082 6.555-14.637 14.637-14.637s14.636 6.555 14.636 14.637Zm0 0"
                  style={{
                      stroke: 'none',
                      fillRule: 'nonzero',
                      fill: '#bce4ff',
                      fillOpacity: 1,
                  }}/>
            <path d="M292.727 439.09c0 16.168-13.106 29.273-29.274 29.273s-29.273-13.105-29.273-29.273 13.105-29.274 29.273-29.274 29.274 13.106 29.274 29.274Zm0 0"
                  style={{
                      stroke: 'none',
                      fillRule: 'nonzero',
                      fill: '#079cff',
                      fillOpacity: 1,
                  }}/>
            <path d="M263.453 424.453c0 8.086-6.55 14.637-14.637 14.637-8.082 0-14.632-6.55-14.632-14.637 0-8.082 6.55-14.637 14.632-14.637 8.086 0 14.637 6.555 14.637 14.637Zm0 0"
                  style={{
                      stroke: 'none',
                      fillRule: 'nonzero',
                      fill: '#bce4ff',
                      fillOpacity: 1,
                  }}/>
            <path d="M380.547 439.09c0 16.168-13.106 29.273-29.274 29.273S322 455.258 322 439.09s13.105-29.274 29.273-29.274 29.274 13.106 29.274 29.274Zm0 0"
                  style={{
                      stroke: 'none',
                      fillRule: 'nonzero',
                      fill: '#079cff',
                      fillOpacity: 1,
                  }}/>
            <path d="M351.273 424.453c0 8.086-6.554 14.637-14.636 14.637-8.082 0-14.637-6.55-14.637-14.637 0-8.082 6.555-14.637 14.637-14.637s14.636 6.555 14.636 14.637Zm0 0"
                  style={{
                      stroke: 'none',
                      fillRule: 'nonzero',
                      fill: '#bce4ff',
                      fillOpacity: 1,
                  }}/>
            <path d="M497.637 439.09c0 16.168-13.106 29.273-29.274 29.273s-29.273-13.105-29.273-29.273 13.105-29.274 29.273-29.274 29.274 13.106 29.274 29.274Zm0 0"
                  style={{
                      stroke: 'none',
                      fillRule: 'nonzero',
                      fill: '#c175ff',
                      fillOpacity: 1,
                  }}/>
            <path d="M468.363 424.453c0 8.086-6.55 14.637-14.636 14.637-8.082 0-14.637-6.55-14.637-14.637 0-8.082 6.555-14.637 14.637-14.637 8.086 0 14.636 6.555 14.636 14.637Zm0 0"
                  style={{
                      stroke: 'none',
                      fillRule: 'nonzero',
                      fill: '#d7a5ff',
                      fillOpacity: 1,
                  }}/>
            <path d="M131.727 29.273h117.09c8.085 0 14.636 6.551 14.636 14.637 0 8.082-6.55 14.637-14.637 14.637h-117.09c-8.081 0-14.636-6.555-14.636-14.637 0-8.086 6.555-14.637 14.637-14.637ZM307.363 29.273h29.274c8.082 0 14.636 6.551 14.636 14.637 0 8.082-6.554 14.637-14.636 14.637h-29.274c-8.082 0-14.636-6.555-14.636-14.637 0-8.086 6.554-14.637 14.636-14.637Zm0 0"
                  style={{
                      stroke: 'none',
                      fillRule: 'nonzero',
                      fill: '#ffff9d',
                      fillOpacity: 1,
                  }}/>
        </svg>
    );
};

export { IconMathBasic };
