/**
 * PhotoViewer styled item
 *
 * @author: exode <hello@exode.ru>
 */

import styled from 'styled-components';


export const OverlayRenderWrapper = styled.div`
  --text_primary: #fff;
  --text_secondary: #fefefe;

  color: #fff;
  justify-content: space-around;
  transition: opacity .2s ease-out;
  background-color: rgba(0, 0, 0, .5);
`;
