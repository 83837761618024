/**
 * FriendsRequestedView
 *
 * @author: exode <hello@exode.ru>
 */

import React, { useState } from 'react';

import { Page } from '@/pages/Core';

import { FriendshipStatus, ListFriendsByStatusQueryResult } from '@/codegen/graphql';

import { useI18n } from '@/hooks/core';
import { GqlResult } from '@/types/graphql';
import { useSetFriendship } from '@/hooks/apollo';

import { Button, ButtonGroup, Header } from '@exode.ru/vkui';

import { User } from '@/components/Atoms/User';


const FriendsRequestedView = (props: GqlResult<ListFriendsByStatusQueryResult>['friendshipFindMany']) => {

    const { items } = props;

    const { t } = useI18n('pages.Profile.Friends');

    const [ clickFriendId, setClickFriendId ] = useState<number | null>();
    const [ buttonId, setButtonId ] = useState<FriendshipStatus | null>();

    const { setFriendship, setFriendshipLoading } = useSetFriendship();

    return (
        <Page.Row>
            <Header mode="secondary">
                {t('friendRequests')}
            </Header>

            {items?.map(({ id, profile, lastOnlineAt }) => (
                <User.Cell disabled
                           key={id}
                           mode="rich"
                           userId={id}
                           avatarSize={72}
                           profile={profile}
                           lastOnlineAt={lastOnlineAt}
                           avatarProps={{ hasLink: true }}
                           actions={(
                               <ButtonGroup>
                                   <Button disabled={setFriendshipLoading}
                                           data-test={FriendshipStatus.Confirmed}
                                           loading={(
                                               clickFriendId === id
                                               && buttonId === FriendshipStatus.Confirmed
                                               && setFriendshipLoading
                                           )}
                                           onClick={async () => {
                                               setClickFriendId(id);
                                               setButtonId(FriendshipStatus.Confirmed);

                                               await setFriendship(id, FriendshipStatus.Confirmed);
                                           }}>
                                       {t('addToFriend')}
                                   </Button>

                                   <Button mode="secondary"
                                           disabled={setFriendshipLoading}
                                           data-test={FriendshipStatus.Rejected}
                                           loading={(
                                               clickFriendId === id
                                               && buttonId === FriendshipStatus.Rejected
                                               && setFriendshipLoading
                                           )}
                                           onClick={async () => {
                                               setClickFriendId(id);
                                               setButtonId(FriendshipStatus.Rejected);

                                               await setFriendship(id, FriendshipStatus.Rejected);
                                           }}>
                                       {t('hide')}
                                   </Button>
                               </ButtonGroup>
                           )}/>
            ))}
        </Page.Row>
    );
};


export { FriendsRequestedView };
