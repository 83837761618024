/**
 * TournamentMembersSkeleton component
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { Skeleton } from '@mui/material';
import { Header, SimpleCell } from '@exode.ru/vkui';
import { Icon20ChevronRightOutline } from '@vkontakte/icons';

import { If } from '@/cutils';


interface Props {
    isProcess?: boolean;
}


const TournamentMembersSkeleton = (props: Props) => {

    const { isProcess } = props;

    return (
        <div className="bg-content thin-border vk-rounded">
            <Header mode="secondary" aside={<Skeleton className="-mb-1" variant="circular" width={24} height={24}/>}>
                <Skeleton className="rounded-sm" variant="rectangular" width={100} height={15}/>
            </Header>

            {Array(8).fill('').map((__, index) => (
                <SimpleCell key={index}
                            disabled
                            before={<Skeleton className="mr-3" variant="circular" width={36} height={36}/>}
                            after={isProcess && <Icon20ChevronRightOutline fill="var(--icon_tertiary_alpha)"/>}
                            className={[
                                isProcess && 'mt-3.5',
                                'last:mb-2 rounded-none last:rounded-b-lg',
                            ].join(' ')}
                            subtitle={(
                                <>
                                    <Skeleton className="rounded-sm mt-0.5"
                                              variant="rectangular"
                                              width={50}
                                              height={13}/>
                                    <If is={!!isProcess}>
                                        <Skeleton className="rounded-sm mt-1 w-full" variant="rectangular" height={6}/>
                                    </If>
                                </>
                            )}>
                    <Skeleton className="rounded-sm" variant="rectangular" width={87} height={15}/>
                </SimpleCell>
            ))}

        </div>
    );
};


export { TournamentMembersSkeleton };
