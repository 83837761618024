/**
 * Redirect util
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { PageParams, useRouter } from 'router.tsx';

import { IS_NATIVE } from '@/root/src/env';
import { RoutePathType } from '@/router/paths';
import { NativeBrowserService } from '@/services/Native/Browser';


interface Props {
    to?: RoutePathType;
    toOuter?: string;
    blank?: boolean;
    replace?: boolean;
    focus?: boolean;
    refresh?: boolean;
    params?: PageParams;
}


const useRedirect = () => {
    return {
        redirect: (
            location: string | undefined,
            blank: boolean = true,
            focus?: boolean,
            systemBrowser?: boolean,
        ) => {
            if (!location) {
                return <></>;
            }

            if (IS_NATIVE) {
                NativeBrowserService.openUrl(location, systemBrowser);

                return <></>;
            }

            const page = blank
                ? window.open(location, '_blank')
                : window.location.assign(location);

            blank && focus && page?.focus();

            return <></>;
        },
        handleInnerHtmlLinks: (
            element: HTMLElement,
            onClick: (href: string) => void,
            stopPropagation = false,
        ) => {
            const links = element?.querySelectorAll('a');

            for (const link of links || []) {
                const handleClick = (e: MouseEvent) => {
                    const href = link.getAttribute('href') || '';

                    e.preventDefault();

                    if (stopPropagation) {
                        e.stopPropagation();
                    }

                    onClick(href);
                };

                const clone = link.cloneNode(true);

                clone.addEventListener(
                    'click',
                    handleClick as unknown as EventListenerOrEventListenerObject | null,
                );

                link?.parentNode?.replaceChild(clone, link);
            }
        },
    };
};

const Redirect = (props: Props) => {

    const { to, toOuter, blank, replace, focus, refresh, params } = props;

    const router = useRouter(false);

    const { redirect } = useRedirect();

    if (blank === true || !to) {
        return redirect(to || toOuter || '', blank, focus);
    }

    router[replace ? 'replacePage' : 'pushPage'](to, params);

    refresh && window.location.reload();

    return <></>;
};


export { useRedirect, Redirect };
