/**
 * UseTournamentStateListener
 *
 * @author: exode <hello@exode.ru>
 */

import { useEffect } from 'react';

import { DocumentEvent } from '@/types/window';

import { useNative } from '@/hooks/core';


interface Props {
    isTriggerHaptic?: boolean;
}


export const useTournamentStateListener = (props: Props = {}) => {

    const { isTriggerHaptic } = props;

    const { triggerHaptic } = useNative();

    const onStateChange = () => {
        if (isTriggerHaptic) {
            triggerHaptic();
        }
    };

    useEffect(() => {
        document.addEventListener(DocumentEvent.TournamentOnStateChange, onStateChange);

        return () => {
            document.removeEventListener(DocumentEvent.TournamentOnStateChange, onStateChange);
        };
    }, []);
};
