/**
 * TournamentCard component
 *
 * @author: exode <hello@exode.ru>
 */

import React, { useEffect } from 'react';

import { observer, SubscriptionStore } from '@/store/subscription/subscription';

import { Time } from '@/utils';
import { If, SvgComponent } from '@/cutils';
import { GqlResult } from '@/types/graphql';
import { TournamentService } from '@/services/Tournament/Tournament';

import {
    ScheduledTournamentsQueryResult,
    TournamentStartMode,
    TournamentState,
    TournamentType,
} from '@/codegen/graphql';

import { educationSubjectColors, educationSubjectImages, educationSubjects } from '@/types/subject';

import { Card, Text, Title, UsersStack } from '@exode.ru/vkui';
import { Icon20ArrowshapeRightOutline } from '@vkontakte/icons';
import { TextTooltip } from '@exode.ru/vkui/unstable';

import { IconDoubleSwords } from '@/images/icons';
import { UserAvatar } from '@/components/Atoms/UserAvatar';
import { TournamentLink } from '@/components/Tournament/Link';
import { BlurImageWrapper } from '@/components/Atoms/BlurImageWrapper';
import { stateText, TournamentStateIcon } from '@/components/Tournament/StateIcon';
import { SubjectCategoriesTooltip } from '@/components/Subject/CategoriesTooltip';

import { Container } from './TournamentCard.styled';


type Props = NonNullable<GqlResult<ScheduledTournamentsQueryResult>['tournamentFindMany']['items']>[number]


const TournamentCard = observer((props: Props) => {

    const {
        id,
        subject,
        startAt,
        lastMembers,
        countMembers,
        isMember,
        type,
        bet,
        state,
        startMode,
        categories,
    } = props;

    useEffect(() => {
        SubscriptionStore.subscribeToTournament(id);
    }, [ id ]);

    const isLobbyOpen = state === 'StartLobby';

    const startTime = state !== (TournamentState.StartLobby || TournamentState.Waiting)
        ? Time.parseRelative(startAt)
        : startMode === TournamentStartMode.Scheduled
            ? Time.parseRelative(startAt)
            : 'Начнется, как все будут готовы';

    return (
        <TournamentLink id={id} state={state} isMember={!!isMember}>
            <Container>
                <Card className={`p-3 bg-gradient-to-r ${educationSubjectColors[subject]}`}>
                    <div className="flex flex-1">
                        <BlurImageWrapper image={(
                            <SvgComponent element={educationSubjectImages[subject]}
                                          svgProps={{ width: 45, height: 45 }}/>
                        )}/>

                        <div className="flex justify-between items-center flex-1 pb-1.5 ml-4">
                            <div className="flex flex-col justify-between items-start">
                                <div className="flex items-center gap-2">
                                    <Title className="text-lg text-white" level="1" weight="3">
                                        {educationSubjects[subject]}
                                    </Title>

                                    <TextTooltip placement="right"
                                                 offsetSkidding={0.5}
                                                 text={stateText[state]}
                                                 className="tooltip-no-arrow">
                                        <TournamentStateIcon state={state}/>
                                    </TextTooltip>
                                </div>

                                <Text weight="regular" className="text-xs text-white text-opacity-70 mb-1.5">
                                    {startTime}
                                </Text>

                                <div className="flex justify-start items-center gap-2 mt-0.5">
                                    <SubjectCategoriesTooltip categories={categories}>
                                        <div className="fs-10 m:max-w-[160px] font-medium vk-rounded text-white py-1 px-2 bg-white bg-opacity-25">
                                            {TournamentService.getCategoryLength(categories?.length)}
                                        </div>
                                    </SubjectCategoriesTooltip>

                                    <If is={type === TournamentType.Blitz}>
                                        <BlurImageWrapper padding="0.30rem"
                                                          image={<IconDoubleSwords height={10} width={10}/>}/>
                                    </If>
                                </div>
                            </div>

                            <div className="flex h-full flex-col justify-between items-end px-1">
                                <Title level="1" weight="3" className="mt-1 text-sm text-white">
                                    {bet} ex.
                                </Title>

                                <If is={isLobbyOpen && !!isMember}>
                                    <Icon20ArrowshapeRightOutline className="-mr-0.5 -mt-0.5" fill="var(--white)"/>
                                </If>

                                <UsersStack className="mb-0.5" size="xs" photos={lastMembers?.map(({ id, profile }) => (
                                    <UserAvatar key={id}
                                                size={16}
                                                userId={id}
                                                showUserOnlineBadge={false}
                                                name={profile?.firstName || ''}
                                                src={profile?.avatar?.medium || ''}/>))}>
                                    <Text weight="regular" className="text-xs text-white text-opacity-60">
                                        {countMembers}
                                    </Text>
                                </UsersStack>
                            </div>
                        </div>
                    </div>
                </Card>
            </Container>
        </TournamentLink>
    );
});


export { TournamentCard };
