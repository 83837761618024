/**
 * Subjects images
 *
 * @author: exode <hello@exode.ru>
 */

import { IconBiology } from '../subjects/IconBiology';
import { IconChemistry } from '../subjects/IconChemistry';
import { IconEnglish } from '../subjects/IconEnglish';
import { IconFrench } from '../subjects/IconFrench';
import { IconGeography } from '../subjects/IconGeography';
import { IconGerman } from '../subjects/IconGerman';
import { IconRussian } from '../subjects/IconRussian';
import { IconMath } from '../subjects/IconMath';
import { IconMathBasic } from '../subjects/IconMathBasic';
import { IconSocial } from '../subjects/IconSocial';
import { IconSpanish } from '../subjects/IconSpanish';
import { IconHistory } from '../subjects/IconHistory';
import { IconPhysics } from '../subjects/IconPhysics';
import { IconInformatics } from '../subjects/IconInformatics';
import { IconLiterature } from '../subjects/IconLiterature';


const Biology = (process.env.PUBLIC_URL || '') + '/static/images/subjects/biology.png';
const Chemistry = (process.env.PUBLIC_URL || '') + '/static/images/subjects/chemistry.png';
const English = (process.env.PUBLIC_URL || '') + '/static/images/subjects/english.png';
const French = (process.env.PUBLIC_URL || '') + '/static/images/subjects/french.png';
const Geography = (process.env.PUBLIC_URL || '') + '/static/images/subjects/geography.png';
const German = (process.env.PUBLIC_URL || '') + '/static/images/subjects/german.png';
const History = (process.env.PUBLIC_URL || '') + '/static/images/subjects/history.png';
const Informatics = (process.env.PUBLIC_URL || '') + '/static/images/subjects/informatics.png';
const Literature = (process.env.PUBLIC_URL || '') + '/static/images/subjects/literature.png';
const Math = (process.env.PUBLIC_URL || '') + '/static/images/subjects/math.png';
const MathBasic = (process.env.PUBLIC_URL || '') + '/static/images/subjects/math-basic.png';
const Physics = (process.env.PUBLIC_URL || '') + '/static/images/subjects/physics.png';
const Russian = (process.env.PUBLIC_URL || '') + '/static/images/subjects/russian.png';
const Social = (process.env.PUBLIC_URL || '') + '/static/images/subjects/social.png';
const Spanish = (process.env.PUBLIC_URL || '') + '/static/images/subjects/spanish.png';

export {
    Biology,
    Chemistry,
    English,
    French,
    Geography,
    German,
    History,
    Informatics,
    Literature,
    Math,
    MathBasic,
    Physics,
    Russian,
    Social,
    Spanish,
    IconBiology,
    IconChemistry,
    IconEnglish,
    IconFrench,
    IconGeography,
    IconGerman,
    IconMath,
    IconMathBasic,
    IconInformatics,
    IconRussian,
    IconSocial,
    IconSpanish,
    IconHistory,
    IconPhysics,
    IconLiterature,
};
