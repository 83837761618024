/**
 * Modals
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import React from 'react';

import { useRouter } from 'router.tsx';

import { observer, RouterStore } from '@/store/core/router';

import { ModalRoot } from '@exode.ru/vkui';

import { ModalCards } from '@/modals/index';
import { PageStore } from '@/store/core/page';


const Modals = () => {
    return (
        <ModalRootWrapper>
            {
                ModalCards.map((Modal) =>
                    <Modal.modal id={Modal.id} key={Modal.id}/>,
                )
            }
        </ModalRootWrapper>
    );
};


const ModalRootWrapper = observer(({ children }: any) => {

    const router = useRouter();

    const currentModal = _.find(ModalCards, { id: RouterStore.activeModal }) || null;
    const modalComponent = currentModal && RouterStore.activeModal;

    const onClose = () => {
        return PageStore.ignoreCloseModalCard
            ? () => {}
            : router.popPage();
    };

    return (
        <ModalRoot activeModal={modalComponent} onClose={onClose}>
            {children}
        </ModalRoot>
    );
});


export { Modals };
