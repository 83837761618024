/**
 * UseProductDiscount
 *
 * @author: exode <hello@exode.ru>
 */

import { ApolloError } from '@apollo/client';

import { PlatformStore } from '@/store/platform';

import { useStore } from '@/pages/Core';
import { DiscountItem } from '@/types/product';

import { ManageCourseDiscountsPageStore } from '@/pages/Manage/Courses/Discounts/store';

import {
    CreateDiscountInput,
    DiscountManageFindManyDocument,
    DiscountManageFindManyQuery,
    DiscountType,
    useDiscountManageCreateMutation,
    useDiscountManageUpdateMutation,
} from '@/codegen/graphql';

import { useDiscountsFilter } from '@/pages/Manage/Products/Discounts';


interface Props {
    onCompleted?: () => void;
    onError?: (error: ApolloError) => void;
    filter: ReturnType<typeof useDiscountsFilter>;
}


export const useProductDiscount = (props: Props) => {

    const { COMMON } = PlatformStore;

    const {
        onError,
        onCompleted,
        filter: { dtoFilter },
    } = props;

    const { list } = useStore(ManageCourseDiscountsPageStore);

    const generateRandomCode = () => {
        return Math.random().toString(36).slice(2).toUpperCase();
    };

    const getVariables = () => {
        return ({
            list: { ...list },
            filter: dtoFilter,
        });
    };

    const getInitialValues = (
        discount?: DiscountItem,
    ) => {
        return {
            type: discount?.type || DiscountType.Amount,
            code: discount?.code || '',
            value: discount?.value || 0,
            productIds: [] as number[],
            active: discount ? !!discount?.active : true,
            maxApplies: discount?.maxApplies || null,
            activeFrom: discount?.activeFrom,
            activeTo: discount?.activeTo,
            note: discount?.note || '',
        };
    };

    /** Create discount */
    const [ _createDiscount, {
        loading: createDiscountLoading,
    } ] = useDiscountManageCreateMutation({
        onCompleted: () => onCompleted?.(),
        onError: (error) => onError?.(error),
        update: (cache, { data }) => {
            const variables = getVariables();

            const cachedDiscounts = cache.readQuery<DiscountManageFindManyQuery>({
                variables,
                query: DiscountManageFindManyDocument,
            });

            if (!cachedDiscounts) {
                return console.warn('[Cache]: cachedDiscounts отсутствуют в кэше');
            }

            const { items, pages, count } = cachedDiscounts.discountManageFindMany;

            data && cache.writeQuery<DiscountManageFindManyQuery>({
                variables,
                query: DiscountManageFindManyDocument,
                data: {
                    discountManageFindMany: {
                        pages: pages || 1,
                        count: count || 1,
                        items: [ data.discountManageCreate, ...(items ?? []) ],
                    },
                },
            });
        },
    });

    const createDiscount = (
        discount: CreateDiscountInput,
    ) => {
        return _createDiscount({
            variables: {
                discount: {
                    ...discount,
                    currency: COMMON?.organization.settings.currency,
                },
            },
        });
    };

    /** Update discount */
    const [ _updateDiscount,
        { loading: updateDiscountLoading },
    ] = useDiscountManageUpdateMutation({
        onCompleted: () => onCompleted?.(),
        onError: (error) => onError?.(error),
    });

    const updateDiscount = (
        discountId: number,
        discount: CreateDiscountInput,
    ) => {
        return _updateDiscount({
            variables: { discountId, discount },
        });
    };

    return {
        createDiscount,
        createDiscountLoading,
        updateDiscount,
        updateDiscountLoading,
        getInitialValues,
        generateRandomCode,
    };
};
