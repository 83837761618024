/**
 * Background mixin functions
 *
 * @author: exode <hello@exode.ru>
 */

const bgContent = (opacity: number) => (
    `
    body[scheme=bright_light] & {
        background-color: rgba(255, 255, 255, ${opacity});
    }
    
    body[scheme=space_gray] & {
        background-color: rgba(25, 25, 26, ${opacity});
    }
 `
);


export { bgContent };
