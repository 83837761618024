/**
 * PreferenceService
 *
 * @author: exode <hello@exode.ru>
 */

import { BaseService } from '@/services/Core/Base';

import { Storage } from '@/api/storage';
import { AppColorSchemeType } from '@/shared/types';


class PreferenceService extends BaseService {

    /**
     * Сохранение темы после ее переключения
     * @param {AppColorSchemeType} scheme
     * @returns {Promise<void>}
     */
    static async saveScheme(scheme: AppColorSchemeType) {
        Storage.set('preference:scheme', scheme);
    }

}


export { PreferenceService };
