/**
 * CustomRadioWithInput styled container
 *
 * @author: exode <hello@exode.ru>
 */

import styled, { css } from 'styled-components';


const centeredSized = css`
    display: flex !important;
    justify-content: center;
    align-items: center;
    padding: 0 !important;
    width: 36px !important;
    height: 36px !important;
    margin: 0 !important;
    margin-right: 0.5rem !important;
    border-radius: 100% !important;
`;

const RadioWithInput = styled.div`
    svg {
        margin: auto;
    }

    .Radio {
        ${centeredSized};
    }
`;

const CheckboxWithInput = styled.div`
    .Checkbox {
        ${centeredSized};
    }
`;

const MappingColumns = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 0.1fr;
`;


export { RadioWithInput, CheckboxWithInput, MappingColumns };
