/**
 * UseSeller
 *
 * @author: exode <hello@exode.ru>
 */

import { useEffect, useState } from 'react';

import { ApolloError } from '@apollo/client';

import { SellerStore } from '@/store/user/seller';

import { Time } from '@/utils';
import { Storage } from '@/api/storage';
import { apolloClient, getGqlCause } from '@/api/graphql';

import {
    AuthException,
    GetUserSellersDocument,
    Permission,
    SellerBecomeRequestFindOneByCurrentUserDocument,
    SellerCurrentUserSellerDocument,
    useGetUserSellersLazyQuery,
    useSellerBecomeRequestFindOneByCurrentUserLazyQuery,
    useSellerCurrentUserSellerLazyQuery,
} from '@/codegen/graphql';


export const useSeller = () => {

    const [ getSellerInfoError, setSellerInfoError ] = useState(false);

    const onSellerGetInfoError = (e: ApolloError) => {
        if (getGqlCause(e) === AuthException.Forbidden) {
            Storage.destroy('seller:id');

            setSellerInfoError(true);
        }
    };

    const refetchSelectSellerQueries = async (waitTimer = 0) => {
        try {
            if (waitTimer) {
                await Time.timer(waitTimer);
            }

            await apolloClient.refetchQueries({
                include: [
                    GetUserSellersDocument,
                    SellerCurrentUserSellerDocument,
                    SellerBecomeRequestFindOneByCurrentUserDocument,
                ],
            });

            setSellerInfoError(false);
        } catch (e) {
            setSellerInfoError(true);
        }
    };

    const handleSelectSeller = async (
        sellerId: number,
        currentSellerManager: Permission[],
        onToggleChangeState?: (state: 'set-ls' | 'store-reset') => void,
        forceReset = false,
    ) => {
        if (Storage.get('seller:id') === sellerId && !forceReset) {
            return;
        }

        Storage.set('seller:id', sellerId);

        SellerStore.merge({
            managerPermissions: currentSellerManager,
        });

        onToggleChangeState?.('set-ls');

        try {
            await apolloClient?.resetStore();
        } catch (e) {}

        onToggleChangeState?.('store-reset');
    };

    const [
        getUserSellersLazy,
        { data: userSellersLazy },
    ] = useGetUserSellersLazyQuery({ onError: onSellerGetInfoError });

    const [
        getCurrentSellerLazy,
        {
            data: currentSellerLazy,
            refetch: getCurrentSellerLazyRefetch,
        },
    ] = useSellerCurrentUserSellerLazyQuery({ onError: onSellerGetInfoError });

    const [
        getCurrentSellerBecomeRequestLazy,
        {
            data: currentSellerBecomeRequestLazy,
            refetch: getCurrentSellerBecomeRequestLazyRefetch,
        },
    ] = useSellerBecomeRequestFindOneByCurrentUserLazyQuery({
        onError: (e) => console.log(e),
    });

    useEffect(() => {
        getSellerInfoError && refetchSelectSellerQueries(2500);
    }, [ getSellerInfoError ]);

    return {
        handleSelectSeller,
        getUserSellersLazy,
        userSellersLazy,
        getCurrentSellerLazy,
        getCurrentSellerLazyRefetch,
        currentSellerLazy,
        getCurrentSellerBecomeRequestLazy,
        getCurrentSellerBecomeRequestLazyRefetch,
        currentSellerBecomeRequestLazy,
        refetchSelectSellerQueries,
    };
};
