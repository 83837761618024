/**
 * FindFriendsBanner
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { Link } from '@/cutils';
import { useI18n } from '@/hooks/core';

import { InnerHtml } from '@/components/Atoms/InnerHtml';
import { GradientBanner, GradientBannerImage } from '@/components/Atoms/GradientBanner';

import { Button } from '@exode.ru/vkui';
import { Icon36Users } from '@vkontakte/icons';


const FindFriendsBanner = () => {

    const { t } = useI18n('components.Promotion.ContextBanners');

    return (
        <GradientBanner imageTheme="light"
                        gradient="bg-content"
                        header={t('commonGoals')}
                        subheader={<InnerHtml content={t('findFriendsWithCommonGoals')}/>}
                        image={(
                            <GradientBannerImage className="top-0 right-0 mt-7 banner__image">
                                <Icon36Users width={140} height={140}/>
                            </GradientBannerImage>
                        )}
                        actions={(
                            <Link pushPage={{ id: '/friends' }}>
                                <Button mode="primary" size="m">
                                    {t('search')}
                                </Button>
                            </Link>
                        )}/>
    );
};


export { FindFriendsBanner };
