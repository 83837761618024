/**
 * Icon images
 *
 * @author: exode <hello@exode.ru>
 */

import { IconAtom } from './IconAtom';
import { IconDoubleSwords } from './IconDoubleSwords';
import { IconExodeLogo } from './IconExodeLogo';
import { IconExodeIcon } from './IconExodeIcon';
import { IconGooglePlay } from './IconGooglePlay';
import { IconMedal } from './IconMedal';
import { IconTelegram } from './IconTelegram';
import { IconTikTok } from './IconTikTok';
import { IconVk } from './IconVk';
import { IconWallet } from './IconWallet';


const Icon = (process.env.PUBLIC_URL || '') + '/static/images/brand/icon.svg';
const IconPart = (process.env.PUBLIC_URL || '') + '/static/images/brand/icon-part.svg';
const IconSquare = (process.env.PUBLIC_URL || '') + '/static/images/brand/icon-square.svg';
const IconWhite = (process.env.PUBLIC_URL || '') + '/static/images/brand/icon-white.svg';
const IconBiz = (process.env.PUBLIC_URL || '') + '/static/images/brand/icon-biz.svg';
const IconBizWhite = (process.env.PUBLIC_URL || '') + '/static/images/brand/icon-biz-white.svg';
const Logo = (process.env.PUBLIC_URL || '') + '/static/images/brand/logo.svg';


export {
    Icon,
    IconPart,
    IconSquare,
    IconWhite,
    IconBiz,
    IconBizWhite,
    Logo,
    IconAtom,
    IconDoubleSwords,
    IconExodeLogo,
    IconExodeIcon,
    IconGooglePlay,
    IconMedal,
    IconTelegram,
    IconVk,
    IconTikTok,
    IconWallet,
};
