/**
 * PasswordChangeModal
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { useI18n } from '@/hooks/core';
import { observer } from '@/pages/Core';

import { Modal } from '@/cutils';
import { Title } from '@exode.ru/vkui';
import { PasswordFormView } from '@/pages/User/Password/views/PasswordFormView';


interface Props {
    id: string;
}


const PasswordChangeModal = observer((props: Props) => {

    const { t } = useI18n('modals.User.PasswordChange');

    return (
        <Modal.Card id={props.id} header={(
            <Title className="modal-title" level="2" weight="3">
                {t('changingPassword')}
            </Title>
        )}>
            <Modal.Body>
                <PasswordFormView mode="modal"/>
            </Modal.Body>
        </Modal.Card>
    );
});


export { PasswordChangeModal };
