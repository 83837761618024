/**
 * TournamentParticipantsProgressModal
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { useStableModalParams } from '@/router/index';

import { Modal } from '@/cutils';
import { Title } from '@exode.ru/vkui';

import { MembersProgressView } from '@/pages/Tournament/Process/views/MembersProgressView';


interface Props {
    id: string;
}


const TournamentParticipantsProgressModal = (props: Props) => {

    const [ { tournamentId } ] = useStableModalParams();

    return (
        <Modal.Card id={props.id}
                    header={<Title className="modal-title" level="2" weight="3">Прогресс участников</Title>}>
            <Modal.Body isScrollable>
                <MembersProgressView tournamentId={+tournamentId}/>
            </Modal.Body>
        </Modal.Card>
    );
};


export { TournamentParticipantsProgressModal };
