/**
 * TournamentBlitzSelectSkeleton component
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { Skeleton } from '@mui/material';


const TournamentBlitzSelectSkeleton = () => {
    return (
        <div className="grid grid-cols-2 gap-2 m:mx-4 m:mt-4">
            {Array(10).fill(0).map((_, i) =>
                <Skeleton key={i} className="vk-rounded w-full !h-[135px]" variant="rectangular"/>)}
        </div>
    );
};


export { TournamentBlitzSelectSkeleton };
