/**
 * SystemRoutes
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { PageSpace } from '@/shared/types';

import { RouterItemsType } from '@/types/router';
import { lazyWithRetry } from '@/helpers/react';


const SystemAppDownloadPage = lazyWithRetry(() => import('@/pages/System/AppDownload'));
const SystemRouteNotFoundPage = lazyWithRetry(() => import('@/pages/System/RouteNotFound'));


const SystemRoutes: RouterItemsType = {
    '/app': {
        view: <SystemAppDownloadPage/>,
        spaces: [
            PageSpace.SchoolPlatform,
            PageSpace.MarketplacePlatform,
        ],
    },
    '/404': {
        view: <SystemRouteNotFoundPage/>,
        type: 'iframe',
        spaces: [],
    },
};


export { SystemRoutes };
