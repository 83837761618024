/**
 * UserTwoFactorSetPage
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { ConfigStore } from '@/store/core/config';

import { useI18n } from '@/hooks/core';
import { observer, Page, useStore } from '@/pages/Core';
import { UserSettingsPageStore } from '@/pages/User/Settings/store';

import { Header } from '@exode.ru/vkui';

import { QrFormView } from '@/pages/User/Settings/views/QrFormView';


export const twoFactorHeader = (
    hasOtp: boolean,
    isMobile = false,
) => {

    const { t } = useI18n('pages.User.TwoFactor');

    const title = hasOtp
        ? isMobile ? t('turnOff2FA') : t('turnOff2FactorAuth')
        : isMobile ? t('turnOn2FA') : t('turnOn2FactorAuth');

    const subheader = hasOtp
        ? t('enterCodeFromAppToTurnOff')
        : t('onLoginWeWillAskPasswordAndCodeFromApp');

    return { title, subheader };
};


const UserTwoFactorSetPage = observer(() => {

    const { input } = useStore(UserSettingsPageStore);

    const { title, subheader } = twoFactorHeader(input.hasOtp, !ConfigStore.isDesktop);

    return (
        <Page.Wrapper>
            <Page.Head>
                <Page.Header title={title}/>
            </Page.Head>

            <Page.Content>
                <Page.Row fullHeight>
                    <Header multiline mode="secondary" className="mb-3">
                        {subheader}
                    </Header>

                    <QrFormView/>
                </Page.Row>
            </Page.Content>
        </Page.Wrapper>
    );
});


export { UserTwoFactorSetPage };
