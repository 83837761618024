/**
 * BlurImageWrapper styled container
 *
 * @author: exode <hello@exode.ru>
 */

import styled from 'styled-components';

import { BlurImageWrapperProps } from '@/components/Atoms/BlurImageWrapper/BlurImageWrapper';


export const Container = styled.div<BlurImageWrapperProps>`
  margin: ${props => props.margin || 'auto'};

  .Banner__in:before {
    border: none;
    box-shadow: inset 0 0 0 var(--thin-border) var(--input_border);
  }
`;

export const ImageWrapper = styled.div<Pick<BlurImageWrapperProps, 'padding' | 'className'>>`
  padding: ${props => props.padding};
  background-color: rgba(255, 255, 255, 0.3);
`;
