/**
 * GridColumnView
 *
 * @author: exode <hello@exode.ru>
 */

import React, { ReactElement } from 'react';

import { List, Text } from '@exode.ru/vkui';


interface Props {
    icon: ReactElement;
    title: string;
    list: ReactElement;
}


const GridColumnView = (props: Props) => {

    const { icon, title, list } = props;

    return (
        <>
            <div className="flex flex-col my-3 ml-3.5">
                <div className="flex items-center justify-start mb-3">
                    {icon}
                    <Text weight="regular">
                        {title}
                    </Text>
                </div>

                <List className="flex flex-col justify-center items-start gap-1.5 text-sm">
                    {list}
                </List>
            </div>
        </>
    );
};


export { GridColumnView };
