/**
 * CardRequisiteView
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { WatchQueryFetchPolicy } from '@apollo/client';
import { PayoutRequisiteFindOneActiveQuery } from '@/codegen/graphql';

import { Page } from '@/pages/Core';
import { Graphql, If, Link } from '@/cutils';

import { CardRequisiteRow } from '@/components/Seller/Balance/CardRequisiteRow';
import { CardRequisiteRowSkeleton } from '@/components/Atoms/Skeleton/ManagePayoutPage/CardRequisiteRowSkeleton';

import { CardRequisitePlaceholderItem } from '../items/CardRequisitePlaceholderItem';


interface Props {
    placement: 'page' | 'modal';
    fetchPolicy?: WatchQueryFetchPolicy;
}


const CardRequisiteView = (props: Props) => {

    const { placement, fetchPolicy } = props;

    return (
        <PayoutRequisiteFindOneActiveQuery fetchPolicy={fetchPolicy} children={(result) => (
            <>
                <Graphql.Loading result={result}>
                    <Page.Row>
                        <CardRequisiteRowSkeleton/>
                    </Page.Row>
                </Graphql.Loading>

                <Graphql.Success result={result}>
                    {({ payoutRequisiteFindOneActive: requisite }) => (
                        <Page.Row>
                            <If is={!!requisite}>
                                <Link disabled={placement === 'modal'} pushPage={{
                                    id: '/manage/seller',
                                    params: { scrollToSetRequisites: 'true' },
                                }}>
                                    <div>
                                        <CardRequisiteRow requisite={requisite}
                                                          wholeIsClickable={placement === 'page'}
                                                          afterWrapper={(after) => (
                                                              <Link stopPropagation={placement === 'page'} pushPage={{
                                                                  id: '/manage/seller',
                                                                  params: { scrollToSetRequisites: 'true' },
                                                              }}>
                                                                  <div>
                                                                      {after}
                                                                  </div>
                                                              </Link>
                                                          )}/>
                                    </div>
                                </Link>
                            </If>

                            <If is={!requisite}>
                                <CardRequisitePlaceholderItem/>
                            </If>
                        </Page.Row>
                    )}
                </Graphql.Success>

                <Graphql.Error result={result}/>
            </>
        )}/>
    );
};


export { CardRequisiteView };
