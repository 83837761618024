/**
 * CatalogTabContentSkeleton component
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { CourseGrid } from '@/components/Course/CourseCard';
import { CourseCardSkeleton } from '@/components/Atoms/Skeleton/CourseCard';


interface Props {
    count?: number;
}


const CatalogTabContentSkeleton = (props: Props) => {

    const { count = 5 } = props;

    return (
        <CourseGrid>
            {Array(count).fill(0).map((__, i) => (
                <CourseCardSkeleton key={i}
                                    className="bg-gradient-to-br from-gray-200 dark:from-transparent to-transparent dark:!bg-[var(--placeholder\_icon\_background)]"/>
            ))}
        </CourseGrid>
    );
};


export { CatalogTabContentSkeleton };
