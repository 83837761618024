/**
 * Plyr component
 *
 * @author: exode <hello@exode.ru>
 */

import React, { ForwardedRef, forwardRef, HTMLAttributes, ReactElement, useMemo } from 'react';

import { Options } from 'plyr';
import PlyrLib, { APITypes, PlyrProps } from 'plyr-react';

import { useI18n, usePlatform } from '@/hooks/core';
import { RndVideoOrientation } from '@/components/Atoms/RndVideo';

import { Container } from './Plyr.styled';


export interface PlyrWrapperProps extends HTMLAttributes<HTMLDivElement> {
    children: ReactElement;

    styles?: {
        width?: string;
        height?: string;
        boxShadow?: string;
        roundedOnMobile?: boolean;
        orientation?: RndVideoOrientation;
    };
}

interface PlyrVideoProps extends Omit<PlyrProps, 'ref'> {
    deps: Array<any>;
}


const PlyrWrapper = ({ children, ...rest }: PlyrWrapperProps) => {
    return (
        <Container {...rest}>
            {children}
        </Container>
    );
};

const PlyrVideo = forwardRef((props: PlyrVideoProps, ref: ForwardedRef<APITypes>) => {

    const { source, deps, options, ...rest } = props;

    const { t } = useI18n('components.Atoms.Plyr');

    const plyrSource = useMemo(() => source, [ ...deps, ref ]);

    const plyrOptions: Options = useMemo(() => ({
        clickToPlay: true,
        disableContextMenu: true,
        invertTime: false,
        fullscreen: {
            iosNative: true,
        },
        keyboard: { focused: true, global: true },
        speed: {
            selected: 1,
            options: [ 0.5, 1, 1.25, 1.5 ],
        },
        i18n: {
            speed: t('speed'),
            normal: t('simple'),
        },
        controls: [
            'play-large',
            'play',
            'progress',
            'current-time',
            'captions',
            'settings',
            'pip',
            'airplay',
            'duration',
            'fullscreen',
            usePlatform() === 'android' ? 'volume' : '',
        ].filter(e => e),
        storage: {
            enabled: true,
            key: 'plyr',
        },
        ...options,
    }), [ ...deps, options ]);

    return (<PlyrLib ref={ref} source={plyrSource} options={plyrOptions} {...rest} />);
});


export const Plyr = {
    Wrapper: PlyrWrapper,
    Video: PlyrVideo,
};
