/**
 * UseUserOnline
 *
 * @author: exode <hello@exode.ru>
 */

import { UserOnlineStore } from '@/store/user/online';


/**
 * Хук для определения пользователя в сети
 * Оптимизировано через store, так как частый рендер = много запросов на сервер
 * @returns {{chatUnreadCount: any}}
 */
export const useUserOnline = (userId: number) => {
    UserOnlineStore.addListener(userId);

    const isOnline = UserOnlineStore.onlineList[userId];

    return { isOnline };
};
