/**
 * ChecklistPart
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { If } from '@/cutils';

import { InnerHtml } from '@/components/Atoms/InnerHtml';


const ChecklistPart = ({ data }: any) => (
    <div className="cdx-block cdx-checklist mb-4">
        {data?.items?.map(({ checked, text }: any, index: number) => (
            <div key={index} className={`cdx-checklist__item ${checked && 'cdx-checklist__item--checked'}`}>
                <div className="cdx-checklist__item-checkbox">
                    <span className="cdx-checklist__item-checkbox-check" style={{ cursor: 'default' }}>
                        <If is={checked}>
                            <svg xmlns="http://www.w3.org/2000/svg"
                                 width="24"
                                 height="24"
                                 fill="none"
                                 viewBox="0 0 24 24">
                                <path stroke="currentColor"
                                      strokeLinecap="round"
                                      strokeWidth="2"
                                      d="M7 12L10.4884 15.8372C10.5677 15.9245 10.705 15.9245 10.7844 15.8372L17 9"/>
                            </svg>
                        </If>
                    </span>
                </div>

                <InnerHtml content={text} include={[ 'timeCodes' ]} className="cdx-checklist__item-text"/>
            </div>
        ))}
    </div>
);


export { ChecklistPart };
