/**
 * PageRowRoundedButton component
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { Button } from '@exode.ru/vkui';


export interface Props {
    children: any;
}


const PageRowRoundedButton = (props: Props) => {

    const { children } = props;

    return (
        <Button {...props} stretched mode="secondary" className="vk-rounded m:hidden mt-2 mb-0.5" size="l">
            {children}
        </Button>
    );
};


export { PageRowRoundedButton };
