/**
 * Window util
 *
 * @author: exode <hello@exode.ru>
 */

import React, { ReactElement } from 'react';

import { Url } from '@/utils';

import { NativeBrowserService } from '@/services/Native/Browser';


interface WindowPopupProps {
    url: string;
    name: string;
    callback: Function;
    children: ReactElement;
    width?: number;
    height?: number;
    top?: number;
    left?: number;
}

interface WindowInAppBrowserProps {
    url: string;
    children: ReactElement;
    systemBrowser?: boolean;
}


const WindowPopup = (props: WindowPopupProps) => {

    const features = () => {
        const { width = 500, height = 500, top = 100, left = 100 } = props;

        const features = { toolbar: 'no', menubar: 'no', width, height, top, left };

        return Url.objectToQuery(features, ',');
    };

    const onMessage = (event: MessageEvent, callback: Function) => {
        callback && callback(event.data);
    };

    const open = () => {
        const { url, name, callback } = props;
        const popup = window.open(url, name, features());

        window.onmessage = (e: MessageEvent) => onMessage(e, callback);

        popup?.focus();
    };

    const onClick = () => open();

    return React.cloneElement(props.children, { onClick });
};


const WindowInAppBrowser = (props: WindowInAppBrowserProps) => {

    const open = () => {
        const { url, systemBrowser } = props;

        NativeBrowserService.openUrl(url, systemBrowser);
    };

    const onClick = () => open();

    return React.cloneElement(props.children, { onClick });
};


const Window = {
    Popup: WindowPopup,
    InAppBrowser: WindowInAppBrowser,
};


export { Window };
