/**
 * ChatRoutes
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { PageSpace } from '@/shared/types';

import { RouterItemsType } from '@/types/router';
import { lazyWithRetry } from '@/helpers/react';


const ChatDialogsPage = lazyWithRetry(() => import('@/pages/Chat/Dialog'));
const ChatMembersView = lazyWithRetry(() => import('@/pages/Chat/Dialog/views/ChatMembersView'));


const ChatRoutes: RouterItemsType = {
    '/chat/attachments': {
        view: <ChatDialogsPage/>,
        type: 'tab',
        tab: 'chat',
        auth: [],
        spaces: [
            PageSpace.SchoolPlatform,
            PageSpace.MarketplacePlatform,
        ],
    },
    '/chat/members/:page([0-9]+)': {
        view: <ChatMembersView/>,
        type: 'tab',
        tab: 'chat',
        auth: [],
        spaces: [
            PageSpace.SchoolPlatform,
            PageSpace.MarketplacePlatform,
        ],
    },
};


export { ChatRoutes };
