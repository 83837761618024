/**
 * Kinescope player component
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import KinescopePlayer from '@kinescope/react-kinescope-player';

import { UserAuthStore } from '@/store/user/auth';
import { ConfigStore, observer } from '@/store/core/config';

import { KinescopeApiProps, useKinescopeApi } from '@/hooks/core';

import { Container } from './Kinescope.styled';


interface Props {
    videoId: string;
    options?: KinescopeApiProps;
}


const Kinescope = observer((props: Props) => {

    const { videoId, options } = props;

    const {
        onPlay,
        onReady,
        onPause,
        kinescopeVideoRef,
    } = useKinescopeApi(options);

    return (
        <Container>
            <KinescopePlayer width="100%"
                             height="100%"
                             preload={true}
                             onPlay={onPlay}
                             autoPlay={false}
                             onReady={onReady}
                             onPause={onPause}
                             videoId={videoId}
                             ref={kinescopeVideoRef}
                             watermark={`@${UserAuthStore.user?.id}`}
                             language={ConfigStore.language?.toLowerCase() as 'ru'}
                             className="thin-border w-full h-full flex flex-1 vk-rounded overflow-hidden bg-hover"/>
        </Container>
    );
});


export { Kinescope };
