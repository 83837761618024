/**
 * ContextMenuItems styled container
 *
 * @author: exode <hello@exode.ru>
 */

import styled from 'styled-components';

import { contentCardAfterIconButtonStyle } from '@/components/Atoms/Styled';


export const ContextMenuItemsContainer = styled.div<{
    withHeader?: boolean;
}>`
    .context-menu-header {
        .Group__inner {
            padding: 0.5rem !important;
        }
    }

    .Group__inner {
        padding-left: 0 !important;
        padding-right: 0 !important;
        padding-bottom: 0.65rem !important;
        padding-top: ${({ withHeader }) => `${withHeader ? '0px' : '0.65rem'} !important`};

        .SimpleCell {
            border-radius: 0;
        }

        & .active {
            border-left: 3px solid var(--accent);
            background: var(--background_hover);
            padding-left: 13px;
        }
    }

    ${contentCardAfterIconButtonStyle};

    .right-button .IconButton {
        border-radius: 0 var(--vkui--size_border_radius_paper--regular) var(--vkui--size_border_radius_paper--regular) 0;
    }

`;

export const AutoSaveFormContainer = styled.div`
    .vkuiSimpleCell .vkuiSimpleCell__content {
        padding-right: 3rem;
    }
`;
