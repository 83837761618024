/**
 * Http util
 *
 * @author: exode <hello@exode.ru>
 */

import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';


class Http {

    /**
     * Send post-request
     * @param url
     * @param data
     * @param config
     * @returns {Promise<any>}
     */
    static async post<R>(
        url: string,
        data?: any,
        config?: AxiosRequestConfig | undefined,
    ) {
        try {
            const result = await axios.post<R>(url, data, config);

            return result as AxiosResponse<R>;
        } catch (e) {
            return { data: false } as AxiosResponse<R>;
        }
    }

    /**
     * Send get request
     * @param url
     * @param data
     * @returns {Promise<any>}
     */
    static async get<R>(
        url: string,
        data?: AxiosRequestConfig,
    ) {
        try {
            const result = await axios.get(url, data);

            return result as AxiosResponse<R>;
        } catch (e) {
            return { data: false } as { data: false };
        }
    }

    /**
     * Append form data
     * @param {FormData} formData
     * @param {string} key
     * @param data
     */
    static appendFormData(
        formData: FormData,
        key: string,
        data: any,
    ) {
        if (data === Object(data) || Array.isArray(data)) {
            for (const i in data) {
                this.appendFormData(formData, key + '[' + i + ']', data[i]);
            }
        } else {
            formData.append(key, data);
        }
    }

}


export { Http };
