/**
 * SubjectCellItem
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { useI18n } from '@/hooks/core';
import { useSchoolDiscipline } from '@/hooks/apollo';
import { ContentCategoryItem } from '@/types/content';

import { Cell } from '@exode.ru/vkui';

import { ShowOnHover } from '@/components/Atoms/Styled';
import { EditableTextArea } from '@/components/Atoms/EditableTextArea';

import { ChangeVisibilityItem } from './ChangeVisibilityItem';


interface Props {
    subject: ContentCategoryItem['subjects'][number];
    index: number;
}


const SubjectCellItem = (props: Props) => {

    const { subject, index } = props;

    const { t } = useI18n('pages.Manage.School.Disciplines');

    const { subjectUpdate } = useSchoolDiscipline();

    const {
        changeSubjectVisibility,
        subjectUpdateLoading: subjectVisibilityUpdateLoading,
    } = useSchoolDiscipline();

    return (
        <ShowOnHover>
            <Cell activeMode="background" className="vk-rounded" before={(
                <span className="text-secondary mr-4">
                    # {index + 1}
                </span>
            )} after={(
                <div className="flex items-center gap-2">
                    <ChangeVisibilityItem visible={subject.visible}
                                          data-tes="descipline.hide-subject"
                                          loading={subjectVisibilityUpdateLoading}
                                          onChange={(visible) => changeSubjectVisibility(
                                              subject.id,
                                              visible,
                                          )}/>
                </div>
            )}>
                <EditableTextArea value={subject.name}
                                  className="w-[300px]"
                                  dataTest="discipline.subject"
                                  placeholder={t('enterSubjectName')}
                                  data-test="descipline.edit-subject"
                                  onSave={(name) => subjectUpdate(subject.id, { name })}/>
            </Cell>
        </ShowOnHover>
    );
};


export { SubjectCellItem };
