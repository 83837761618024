/**
 * UserRbacService
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import { Permission } from '@/codegen/graphql';
import { Permissions } from '@/types/rbac';

import { BaseService } from '@/services/Core/Base';


class UserRbacService extends BaseService {

    /**
     * Проверка принудительно всех прав доступа пользователя
     * @param {Permission[]} granted
     * @param {Permission} requested
     * @returns {boolean}
     */
    static canEvery(granted: Permission[], requested: Permission[]) {
        if (_.isEmpty(requested) || _.isEmpty(granted)) {
            return true;
        }

        return requested.every((permission) => granted.includes(permission));
    }

    /**
     * Проверка прав доступа пользователя
     * @param {Permission[]} granted
     * @param {Permission} required
     * @returns {boolean}
     */
    static canAny(
        granted: Permissions,
        ...required: Permissions
    ) {
        if (!required?.length) {
            return true;
        }

        return required.some((permission) => (
            _.isArray(permission)
                ? this.canEvery(granted as Permission[], permission)
                : (granted as Permission[]).includes(permission)
        ));
    }

}


export { UserRbacService };
