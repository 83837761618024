/**
 * ChatFolderUnreadCountTotalSubscription component
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { ChatFolderType } from '@/types/chat';

import {
    ChatGetUnreadCountDocument,
    ChatGetUnreadCountQuery,
    useChatListenNewTotalUnreadCountSubscription,
} from '@/codegen/graphql';


interface Props {
    folder: ChatFolderType;
}


const ChatFolderUnreadCountTotalSubscription = (props: Props) => {

    const { folder } = props;

    useChatListenNewTotalUnreadCountSubscription({
        variables: folder === 'All' ? undefined : { type: folder },
        onError: (error) => console.error(error),
        onData: ({ data: { data }, client: { cache } }) => {
            if (!data) {
                return;
            }

            const { chatListenNewTotalUnreadCount: newUnreadCount = 0 } = data;

            cache.writeQuery<ChatGetUnreadCountQuery>({
                query: ChatGetUnreadCountDocument,
                variables: folder === 'All' ? undefined : { types: [ folder ] },
                data: {
                    chatGetUnreadCount: newUnreadCount,
                },
            });
        },
    });

    return (<></>);
};


export { ChatFolderUnreadCountTotalSubscription };
