/**
 * ActiveBadgeStatus component
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { If } from '@/cutils';
import { Icon20Pause, Icon20Play } from '@vkontakte/icons';

import { gradient } from '@/styles/modules/gradient';


interface Props {
    isActive: boolean;
    className?: string;
    size?: number;
}


const ActiveBadgeStatus = (props: Props) => {

    const { isActive, className = '', size = 14 } = props;

    return (
        <>
            <If is={!isActive}>
                <div className={[
                    className,
                    gradient.blue400ToBlue700Gradient,
                    'bg-gradient-to-br p-0.5 rounded-full',
                ].join(' ')}>
                    <Icon20Pause width={size} height={size} fill="var(--white)"/>
                </div>
            </If>

            <If is={isActive}>
                <div className={[
                    className,
                    gradient.green500ToLime600,
                    'bg-gradient-to-br p-0.5 rounded-full',
                ].join(' ')}>
                    <Icon20Play width={size} height={size} fill="var(--white)"/>
                </div>
            </If>
        </>
    );
};


export { ActiveBadgeStatus };
