/**
 * Permissions index file
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import { userManage } from '@/types/manage/permission/administrate-user';
import { getGlobalManagerPermissions } from '@/types/manage/permission/manager-global';
import { getProductManagerPermissions } from '@/types/manage/permission/manager-product';


export {
    userManage,
    getGlobalManagerPermissions,
    getProductManagerPermissions,
};

export const getSellerManagerPermissions = () => [
    ...getGlobalManagerPermissions(),
    ...getProductManagerPermissions(),
];

export const groupByModule = (list: ReturnType<typeof getGlobalManagerPermissions>) => {
    return _.groupBy(
        list,
        (e) => e.value.match(/^[A-Za-z]+?(?=[A-Z])/)?.[0],
    );
};
