/**
 * UseCompanyGetOrganizationName
 *
 * @author: exode <hello@exode.ru>
 */

import { useState } from 'react';

import { useI18n } from '@/hooks/core';

import { useCompanyGetOrganizationInfoByInnLazyQuery } from '@/codegen/graphql';


export const useCompanyGetOrganizationName = () => {

    const { t } = useI18n('hooks.apollo.seller');

    const [ organizationSearch, setOrganizationSearch ] = useState({ name: '', error: '' });

    const [ getOrganizationName, {
        loading: getOrganizationNameLoading,
        called: getOrganizationNameCalled,
    } ] = useCompanyGetOrganizationInfoByInnLazyQuery({
        notifyOnNetworkStatusChange: true,
        onError: () => {
            setOrganizationSearch({
                name: '',
                error: t('organizationNotFoundOrOccurredError'),
            });
        },
        onCompleted: ({ companyGetOrganizationInfoByInn: info }) => {
            setOrganizationSearch({
                name: info?.name || '',
                error: !info ? t('organizationNotFound') : '',
            });
        },
    });

    return {
        organizationSearch,
        setOrganizationSearch,
        getOrganizationName,
        getOrganizationNameLoading,
        getOrganizationNameCalled,
    };
};
