/**
 * Promotion types
 *
 * @author: exode <hello@exode.ru>
 */

import { GqlResult } from '@/types/graphql';

import { ApplyInvitationBonusMutationResult } from '@/codegen/graphql';


export type PromotionApplyInvitationResult = GqlResult<ApplyInvitationBonusMutationResult>['promotionApplyInvitationBonus'];

export enum PromoBannerLsKey {
    StartCoursesSectionSoonBanner = 'StartCoursesSectionSoonBanner',
    DownloadAppReminderInWeb = 'DownloadAppReminderInWeb',
}
