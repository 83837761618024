/**
 * SignupAuthInput
 *
 * @author: exode <hello@exode.ru>
 */

import { IsIn, IsNotEmpty, IsOptional, IsString, MinLength, ValidateIf } from 'class-validator';

import { IsPhoneNumber } from '@/shared/validators';

import { isNotEmpty } from '../constants';


export class SignupAuthInput {

    @IsIn([
        'phone',
        'login',
    ])
    readonly mode?: 'phone' | 'login';

    @IsNotEmpty()
    readonly login: string;

    @ValidateIf((o) => o.mode === 'login')
    @IsNotEmpty({ message: isNotEmpty('Логин') })
    readonly emailOrLogin?: string;

    @ValidateIf((o) => o.mode === 'phone')
    @IsNotEmpty({ message: isNotEmpty('Телефон') })
    @IsPhoneNumber({ message: 'Некорректный телефон' })
    readonly phone?: string;

    @IsOptional()
    @IsString()
    @IsNotEmpty({ message: isNotEmpty('Код', 'пустой') })
    @MinLength(6, { message: ' ' })
    readonly code?: string;

}
