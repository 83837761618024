/**
 * SellerAvatar component
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { UserAvatar } from '@/components/Atoms/UserAvatar';


interface Props {
    sellerId: number;
    sellerOrganizationLogo?: string | null;
    sellerName?: string | null;
    size?: number;
    className?: string;
}


const SellerAvatar = (props: Props) => {

    const { sellerId, sellerOrganizationLogo, sellerName = sellerId, className, size = 54 } = props;

    return (
        <UserAvatar mode="app"
                    size={size}
                    entityId={sellerId}
                    className={className}
                    name={`${sellerName}`}
                    src={sellerOrganizationLogo || ''}/>
    );
};


export { SellerAvatar };
