/**
 * QrCode styled container
 *
 * @author: exode <hello@exode.ru>
 */

import styled from 'styled-components';


export const Container = styled.div`
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.35) 0 5px 15px;

  body[scheme=space_gray] & {
    background-color: var(--white);
  }
`;
