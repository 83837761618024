/**
 * Platform util
 *
 * @author: exode <hello@exode.ru>
 */

import React, { ReactElement } from 'react';

import { isWindows } from 'react-device-detect';

import { CURRENT_PLATFORM, IS_NATIVE, IS_VK, OS_PLATFORM } from '@/root/src/env';

import { If } from '@/cutils/Condition';
import { usePlatform } from '@/hooks/core';
import { PageSpace } from '@/shared/types';

import { Platform as PlatformEnum } from '@exode.ru/vkui';


interface PlatformAppleOnlyProps {
    children: ReactElement | ReactElement[];
    forNativeOnly?: boolean;
}

const PlatformDetector = () => {
    const platform = usePlatform();

    document.body.setAttribute('platform', platform);
    document.body.setAttribute('is-vk', `${IS_VK}`);
    document.body.setAttribute('target-os-platform', OS_PLATFORM);
    document.body.setAttribute('is-native', `${IS_NATIVE}`);
    document.body.setAttribute('data-is-windows', String(isWindows));

    return <></>;
};

const PlatformAppleOnly = (props: PlatformAppleOnlyProps) => {

    if (OS_PLATFORM !== PlatformEnum.IOS && props.forNativeOnly && IS_NATIVE) {
        return <></>;
    }

    return (<>{props.children}</>);
};

const PlatformIf: React.FC<{
    platforms: (
        PageSpace.SchoolPlatform
        | PageSpace.MarketplacePlatform
        | PageSpace.BizWelcomePlatform
        )[]
}> = (props) => {
    return (
        <If is={props.platforms.includes(CURRENT_PLATFORM)}>
            <>{props.children}</>
        </If>
    );
};

const PlatformSchool: React.FC = (props) => {
    return (
        <If is={CURRENT_PLATFORM === PageSpace.SchoolPlatform}>
            <>{props.children}</>
        </If>
    );
};

const PlatformMarket: React.FC = (props) => {
    return (
        <If is={CURRENT_PLATFORM === PageSpace.MarketplacePlatform}>
            <>{props.children}</>
        </If>
    );
};

const PlatformBizWelcome: React.FC = (props) => {
    return (
        <If is={CURRENT_PLATFORM === PageSpace.BizWelcomePlatform}>
            <>{props.children}</>
        </If>
    );
};


export const Platform = {
    /** OS / Device */
    Detector: PlatformDetector,
    AppleOnly: PlatformAppleOnly,

    /** School / marketplace / welcome */
    If: PlatformIf,
    School: PlatformSchool,
    Market: PlatformMarket,
    BizWelcome: PlatformBizWelcome,
};
