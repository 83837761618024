/**
 * LanguageSetItem
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { ConfigStore, observer } from '@/store/core/config';

import { If } from '@/cutils';
import { useI18n } from '@/hooks/core';
import { languageNames } from '@/shared/types';

import { Icon28HieroglyphCharacterOutline } from '@vkontakte/icons';

import { IconByLang } from '@/images/countries';

import { LanguageDropdown, LanguageDropdownProps } from '@/components/Atoms/LanguageDropdown';


interface Props {
    languageDropdownProps?: LanguageDropdownProps;
    withLanguageName?: boolean;
}


const LanguageSetItem = observer((props: Props) => {

    const {
        languageDropdownProps,
        withLanguageName = false,
    } = props;

    const { t } = useI18n('pages.User.Settings');

    const LanguageIcon = IconByLang[ConfigStore.language];

    return (
        <LanguageDropdown componentProps={{
            children: t('language'),
            className: 'm:!rounded-none',
            'data-test': 'settings.language',
            before: <Icon28HieroglyphCharacterOutline/>,
            indicator: (
                <div className="flex items-center gap-2">
                    <If is={withLanguageName}>
                        <span>{languageNames[ConfigStore.language]}</span>
                    </If>

                    <LanguageIcon width={24} height={24} className="block my-auto"/>
                </div>
            ),
            ...languageDropdownProps?.componentProps,
        }} dropdownProps={{
            offsetDistance: -220,
            placement: 'right-start',
            offsetSkidding: ConfigStore.isDesktop ? 45 : 10,
            ...languageDropdownProps?.dropdownProps,
        }}/>
    );
});


export { LanguageSetItem };
