/**
 * FileInfoPart component
 *
 * @author: exode <hello@exode.ru>
 */

import React, { Fragment } from 'react';

import { FileInfo } from '@/hooks/core';
import { Chip } from '@exode.ru/vkui/tokenized';


interface Props {
    files: FileList | File[] | null;
    filesInfo: FileInfo[] | null;
}


const FileInfoPart = (props: Props) => {

    const { files, filesInfo } = props;

    return (
        <>
            {files?.length === 1 && filesInfo?.map(({ size, name }) => (
                <Fragment key={name + size}>
                    <Chip className="absolute bottom-[5px] left-[5px]" removable={false} value={size}>
                        {size}
                    </Chip>

                    <Chip className="absolute top-[5px] left-[5px]" removable={false} value={size}>
                        {name}
                    </Chip>
                </Fragment>
            ))}
        </>
    );
};


export { FileInfoPart };

