/**
 * LoggerService
 *
 * @author: exode <hello@exode.ru>
 */

import { BaseService } from '@/services/Core/Base';


class LoggerService extends BaseService {

    static init() {
        this.teamLogger();
    }

    static get styles() {
        const exodeStyles = [
            'color: #ffffff',
            'background: linear-gradient(70deg, #ED1C24, #FF9A44)',
            'font-size: 20px',
            'border-radius: 20px',
            'padding: 10px',
        ].join(';');

        const importantStyles = [
            'color: #000000',
            'background: linear-gradient(-70deg, #FFFFFF, #EEEEEE)',
            'font-size: 14px',
            'border-radius: 20px',
            'padding: 10px',
        ].join(';');

        return { exodeStyles, importantStyles };
    }

    static teamLogger() {
        const { exodeStyles, importantStyles } = this.styles;

        console.log('%cХочешь изменить мир образования с нашей командой?', exodeStyles);
        console.log('%cПиши сюда t.me/exode_support', exodeStyles);

        console.log('%cСтрого не рекомендуем что-то копировать отсюда и передавать третьим лицам!', importantStyles);
    }

}


export { LoggerService };
