/**
 * UseUpdateUserSubjects
 *
 * @author: exode <hello@exode.ru>
 */

import { apolloClient } from '@/api/graphql';

import {
    EducationSubject,
    EgeSubjectAuthQueryResult,
    EgeTimeoutAuthDocument,
    EgeTimeoutAuthQuery,
    ListProfileSubjectsQueryResult,
    ReadyAndUpcomingTournamentsDocument,
    ScheduledTournamentsDocument,
    useEgeSetReminderStateMutation,
    useEgeUpdateUserSubjectsMutation,
} from '@/codegen/graphql';

import { GqlResult } from '@/types/graphql';
import { SubjectService } from '@/services/Subject/Subject';


interface Props {
    userSubjects: GqlResult<ListProfileSubjectsQueryResult | EgeSubjectAuthQueryResult>['educationPreferenceGetUserSubjects'];
    subject: EducationSubject;
}


export const useUpdateUserSubjects = ({ subject, userSubjects }: Props) => {

    const [ _updateSubjects, { loading, error } ] = useEgeUpdateUserSubjectsMutation();

    const {
        isSelected,
        mutated,
        filtered,
        selected,
        chosen,
    } = SubjectService.onSelectUserSubjects(subject, userSubjects);

    const updateSubjects = async () => {
        await _updateSubjects({
            variables: { subjects: mutated },
            update: (cache, { data }) => {
                apolloClient.refetchQueries({
                    include: [
                        ReadyAndUpcomingTournamentsDocument,
                        ScheduledTournamentsDocument,
                    ],
                });

                data && cache.modify({
                    fields: {
                        educationPreferenceGetUserSubjects: () => data.educationPreferenceSetUserSubjects,
                        subjectFindManyExaminees: (value: Record<any, any>) => {
                            const count = value.count + (isSelected ? -1 : 1);

                            return { ...value, count };
                        },
                    },
                });
            },
            onError(error) {
                console.error(error);
            },
        });
    };

    return { updateSubjects, loading, error, mutated, isSelected, selected, filtered, chosen };
};

export const useUpdateEducationPreference = () => {

    const [
        _setEducationPreferenceReminder,
        { loading: educationPreferenceReminderLoading },
    ] = useEgeSetReminderStateMutation();

    const setPreferenceReminder = async (subject: EducationSubject) => {
        await _setEducationPreferenceReminder({
            variables: { subject },
            update: (cache, { data }) => {
                const cached = cache.readQuery<EgeTimeoutAuthQuery>({
                    query: EgeTimeoutAuthDocument,
                });

                data && cached && cache.writeQuery<EgeTimeoutAuthQuery>({
                    query: EgeTimeoutAuthDocument,
                    data: {
                        subjectFindMany: cached.subjectFindMany,
                        educationPreferenceGetExamsReminderState: data.educationPreferenceSetExamsReminderState,
                    },
                });
            },
            onError(error) {
                console.error(error);
            },
        });
    };

    return { setPreferenceReminder, educationPreferenceReminderLoading };
};
