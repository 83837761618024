/**
 * DisciplinesView
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { ManageSchoolDisciplinesPageStore } from '../store';

import { Graphql } from '@/cutils';
import { useI18n } from '@/hooks/core';
import { useSchoolDiscipline } from '@/hooks/apollo';
import { observer, Page, useStore } from '@/pages/Core';

import { Button } from '@exode.ru/vkui';
import { Icon24AddSquareOutline } from '@vkontakte/icons';

import { ContentCategoryFindManyQuery, ContentCategorySpace } from '@/codegen/graphql';

import { SimpleCellTree } from '@/components/Atoms/SimpleCellTree';

import { SubjectCellItem } from '../items/SubjectCellItem';
import { CategoryCellItem } from '../items/CategoryCellItem';


const DisciplinesView = observer(() => {

    const { t } = useI18n('pages.Manage.School.Disciplines');

    const { list, filter } = useStore(ManageSchoolDisciplinesPageStore);

    const {
        categoryCreate,
        categoryCreateLoading,
    } = useSchoolDiscipline();

    return (
        <ContentCategoryFindManyQuery children={(result) => (
            <>
                <Graphql.Loading result={result}>
                    <Page.Spinner/>
                </Graphql.Loading>

                <Graphql.Success result={result}>
                    {({ contentCategoryFindMany: { items } }) => (
                        <>
                            <SimpleCellTree list={items?.map((category) => ({
                                parent: {
                                    id: category.id,
                                    element: <CategoryCellItem category={category}/>,
                                },
                                items: category.subjects?.map((subject, index) => (
                                    <SubjectCellItem index={index} key={subject.id} subject={subject}/>
                                )),
                            }))}/>

                            <Button stretched
                                    size="l"
                                    type="button"
                                    mode="secondary"
                                    loading={categoryCreateLoading}
                                    disabled={categoryCreateLoading}
                                    className="sticky bottom-0 mt-5"
                                    before={<Icon24AddSquareOutline/>}
                                    data-test="disciplines.create-category"
                                    onClick={() => categoryCreate({
                                        slug: null,
                                        visible: true,
                                        name: t('categoryName'),
                                        space: ContentCategorySpace.Course,
                                    })}>
                                {t('addCategory')}
                            </Button>
                        </>
                    )}
                </Graphql.Success>

                <Graphql.Error result={result}/>
            </>
        )} variables={{
            list: { ...list },
            filter: { ...filter },
        }}/>
    );
});


export { DisciplinesView };
