/**
 * HandleDisconnect component
 *
 * @author: exode <hello@exode.ru>
 */

import React, { useEffect } from 'react';

import { Time } from '@/utils';
import { DocumentEvent } from '@/types/window';
import { useApolloClient } from '@apollo/client';

import { ChatDialogsPageStore } from '@/pages/Chat/Dialog/store';

import { chatRefetchDocsOnConnect } from '@/components/Subscriptions/Chat/constants';


const ChatHandleDisconnect = () => {

    const apolloClient = useApolloClient();

    useEffect(() => {
        const onConnectedAfterDisconnect = async () => {
            await Time.timer(500);

            apolloClient?.refetchQueries({
                include: chatRefetchDocsOnConnect,
            });
        };

        const onDisconnected = () => {
            ChatDialogsPageStore.clearChatsTypings();
        };

        document.addEventListener(
            DocumentEvent.WsConnectedAfterDisconnect,
            onConnectedAfterDisconnect,
        );

        document.addEventListener(
            DocumentEvent.WsDisconnected,
            onDisconnected,
        );

        return () => {
            document.removeEventListener(
                DocumentEvent.WsConnectedAfterDisconnect,
                onConnectedAfterDisconnect,
            );

            document.removeEventListener(
                DocumentEvent.WsDisconnected,
                onDisconnected,
            );
        };
    }, []);

    return (<></>);
};


export { ChatHandleDisconnect };
