/**
 * UserListSkeleton component
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { Skeleton } from '@mui/material';
import { ButtonGroup, Header, List, RichCell, SimpleCell } from '@exode.ru/vkui';

import { If } from '@/cutils';
import { Page } from '@/pages/Core';


interface Props {
    mode?: 'friends' | 'chat';
    wrapRow?: boolean;
    isContext?: boolean;

    friends?: {
        withRequested?: boolean;
        withPossible?: boolean;
    };
}


const UserListSkeleton = (props: Props) => {

    const { mode, isContext, wrapRow, friends = {} } = props;

    const isContextSize = isContext ? 48 : 72;

    const content = (
        <>
            <If is={mode === 'friends'}>
                <Header>
                    <Skeleton className="rounded-sm"
                              variant="rectangular"
                              width={friends.withRequested ? 125 : 100}
                              height={friends.withRequested ? 16 : friends.withPossible ? 16 : 19}/>
                </Header>
            </If>

            <If is={mode === 'chat'}>
                <div className="pt-1.5"/>
            </If>

            <If is={!!friends.withRequested}>
                <RichCell disabled
                          before={<Skeleton className="mt-2" variant="circular" width={72} height={72}/>}
                          caption={(
                              <Skeleton height={13} width={100} variant="rectangular" className="rounded-sm mb-1"/>
                          )}
                          actions={(
                              <ButtonGroup>
                                  <Skeleton className="vk-rounded" variant="rectangular" height={28} width={94}/>
                                  <Skeleton height={28}
                                            width={80}
                                            variant="rectangular"
                                            className="vk-rounded  bg-button-muted"/>
                              </ButtonGroup>
                          )}>
                    <Skeleton className="rounded-sm mb-1" variant="rectangular" height={15} width={120}/>
                </RichCell>
            </If>

            <If is={!friends.withRequested && !friends.withPossible}>
                <List className="-mt-2">
                    {Array(25).fill(0).map((_, index) => (
                            <SimpleCell disabled
                                        key={index}
                                        className={[ mode === 'chat' ? 'px-2.5' : '', 'my-2' ].join(' ')}
                                        subtitle={(
                                            <Skeleton height={13}
                                                      width={100}
                                                      variant="rectangular"
                                                      className="rounded-sm mb-1"/>
                                        )}
                                        before={(
                                            <Skeleton className="mr-3"
                                                      variant="circular"
                                                      width={mode === 'chat' ? 50 : 48}
                                                      height={mode === 'chat' ? 50 : 48}/>
                                        )}>
                                <Skeleton className="rounded-sm mb-1" variant="rectangular" height={15} width={120}/>
                            </SimpleCell>
                        ),
                    )}
                </List>
            </If>

            <If is={!!friends.withPossible}>
                <List>
                    {Array(3).fill(0).map((_, index) => (
                        <RichCell disabled key={index} before={(
                            <Skeleton className="mt-2" variant="circular" width={isContextSize} height={isContextSize}/>
                        )} caption={(
                            <Skeleton height={13} width={100} variant="rectangular" className="rounded-sm mb-1"/>
                        )} actions={(
                            <Skeleton className="vk-rounded" variant="rectangular" height={28} width={94}/>
                        )}>
                            <Skeleton className="rounded-sm mb-1" variant="rectangular" height={15} width={140}/>
                        </RichCell>
                    ))}
                </List>
            </If>
        </>
    );

    return wrapRow ? <Page.Row>{content}</Page.Row> : content;
};


export { UserListSkeleton };
