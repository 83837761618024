/**
 * Preference types
 *
 * @author: exode <hello@exode.ru>
 */


/**
 * Frontend color schemes
 */
export type AppColorSchemeType = 'bright_light' | 'space_gray';

export enum AppColorScheme {
    BrightLight = 'BrightLight',
    SpaceGray = 'SpaceGray',
}

export const appColorSchemeMapping = {
    [AppColorScheme.BrightLight]: 'bright_light',
    [AppColorScheme.SpaceGray]: 'space_gray',
} as const;
