/**
 * Responsive util
 *
 * @author: exode <hello@exode.ru>
 */

import React, { ReactElement } from 'react';

import { ConfigStore, observer } from '@/store/core/config';

import { If } from '@/cutils';
import { Page } from '@/pages/Core';


interface WidthProps {
    children: ReactElement | ReactElement[];
    max?: number;
    min?: number;
    forHiddenContext?: boolean;
    forShownContext?: boolean;
}

interface DesktopProps {
    children: ReactElement | ReactElement[];
    force?: boolean;
}

interface MobileProps {
    children: ReactElement | ReactElement[];
    force?: boolean;
}

interface TabletProps {
    children: ReactElement | ReactElement[];
    force?: boolean;
}

interface DesktopContextProps {
    children: ReactElement | ReactElement[];
}

interface ClassProps {
    d?: string;
    m?: string;
    common?: string;
    children: ReactElement;
}

const Width = observer((props: WidthProps) => {

    const { width } = ConfigStore;
    const { children, forShownContext, forHiddenContext } = props;

    const min = forShownContext ? 950 : (props.min || 0);
    const max = forHiddenContext ? 951 : props.max;

    const cond = max ? width > min && width < max : width > min;

    return (
        <If is={cond}>
            {children}
        </If>
    );
});

const Desktop = observer((props: DesktopProps) => {
    return (
        <If is={ConfigStore.isDesktop || !!props.force}>
            {props.children}
        </If>
    );
});

const DesktopOnly = observer((props: DesktopProps) => {
    return (
        <If is={ConfigStore.isDesktopOnly || !!props.force}>
            {props.children}
        </If>
    );
});

const Tablet = observer((props: TabletProps) => {
    return (
        <If is={ConfigStore.isTablet || !!props.force}>
            {props.children}
        </If>
    );
});

const TabletOnly = observer((props: TabletProps) => {
    return (
        <If is={ConfigStore.isTabletOnly || !!props.force}>
            {props.children}
        </If>
    );
});

const Mobile = observer((props: MobileProps) => {
    return (
        <If is={!ConfigStore.isDesktop || !!props.force}>
            {props.children}
        </If>
    );
});

const MobileOnly = observer((props: MobileProps) => {
    return (
        <If is={ConfigStore.isMobileOnly || !!props.force}>
            {props.children}
        </If>
    );
});


const DesktopContext = (props: DesktopContextProps) => {
    return (
        <>
            <Mobile>{props.children}</Mobile>
            <Desktop>
                <Page.Context>
                    {props.children}
                </Page.Context>
            </Desktop>
        </>
    );
};


const Classier = observer((props: ClassProps) => {
    const childrenClassName = props.children.props.className;

    const className = props[ConfigStore.isDesktop ? 'd' : 'm']
        + ` ${childrenClassName} ${props.common || ''}`;

    return React.cloneElement(props.children, { className });
});


export const Responsive = {
    Width,
    Desktop,
    DesktopOnly,
    Tablet,
    TabletOnly,
    Mobile,
    MobileOnly,
    Classier,
    DesktopContext,
};
