/**
 * SubjectCategorySelectionCard
 *
 * @author: exode <hello@exode.ru>
 */

import React, { ReactNode } from 'react';

import { useI18n } from '@/hooks/core';

import { Icon28CheckCircleOff, Icon28CheckCircleOn } from '@vkontakte/icons';

import { ContentCard } from '@/components/Atoms/ContentCard';

import { Wrapper } from './SubjectCategorySelectionCard.styled';


export interface SubjectCategorySelectionCardProps {
    header: ReactNode;
    onClick: () => void;
    categoriesLength: number;
    active?: boolean;
}


const SubjectCategorySelectionCard = (props: SubjectCategorySelectionCardProps) => {

    const { header, onClick, active, categoriesLength } = props;

    const { t } = useI18n('components.Subject');

    return (
        <Wrapper {...props}>
            <ContentCard hideOverflow
                         slimOnMobile
                         wholeCardActive
                         mode="outline"
                         header={header}
                         onClick={onClick}
                         rightButtonHasHover={false}
                         after={!active ? <Icon28CheckCircleOff/> : <Icon28CheckCircleOn/>}
                         caption={(
                             <span className="line-clamp-2">
                                 {t('categories', { count: categoriesLength })}
                             </span>
                         )}/>
        </Wrapper>
    );
};


export { SubjectCategorySelectionCard };
