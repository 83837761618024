/**
 * ForbiddenContentView
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { Permission } from '@/codegen/graphql';

import { useI18n } from '@/hooks/core';

import { Chip } from '@mui/material';
import { Button, Title } from '@exode.ru/vkui';
import { Icon48LockCircleFillGreen } from '@vkontakte/icons';

import { Placeholder } from '@/components/Atoms/Placeholder';


interface Props {
    required: Permission[];
}


const ForbiddenContentView = (props: Props) => {

    const { t } = useI18n('pages.Rbac.Forbidden');

    return (
        <div className="flex flex-1 flex-col justify-between items-center my-10">
            <Icon48LockCircleFillGreen height={180} width={180} className="my-auto rounded-full shadow-xl"/>

            <Placeholder header={(
                <Title level="1" weight="3" className="mb-7">
                    {t('insufficientPermissions')}
                </Title>
            )} content={(
                <>
                    <span className="m:w-full w-9/10 flex mx-auto">
                        {t('conditionsToGainPermissions')}
                    </span>

                    <span className="flex mt-7 items-center justify-center flex-wrap">
                        <>
                            {props.required.map(r => (
                                <Chip key={r} className="text-primary bg-button-muted" label={r}/>
                            ))}
                        </>
                    </span>
                </>
            )} action={(
                <div className="flex justify-center gap-4 mb-auto">
                    <Button size="l" onClick={() => window.location.reload()}>
                        {t('updatePage')}
                    </Button>
                </div>
            )}/>
        </div>
    );
};


export { ForbiddenContentView };
