/**
 * UseLessonBlockManage
 *
 * @author: exode <hello@exode.ru>
 */

import { apolloClient } from '@/api/graphql';

import {
    CreateElementContentInput,
    GetLessonDocument,
    GetLessonQuery,
    UpdateElementContentInput,
    UpdateLessonBlockOrderCourseInput,
    useCourseLessonBlockManageCreateMutation,
    useCourseLessonBlockManageDeleteMutation,
    useCourseLessonBlockManageRecoverMutation,
    useCourseLessonBlockManageUpdateMutation,
    useCourseLessonBlockManageUpdateOrderMutation,
} from '@/codegen/graphql';

import { useBlockManage, useCourseManage } from '@/hooks/apollo';

import { ContentElementItem } from '@/components/ContentElement';


interface Props {
    courseId: number,
    lessonId: number,
}


export const useLessonBlockManage = (props: Props) => {

    const { courseId, lessonId } = props;

    const { triggerUpdatePreviewEvent } = useCourseManage();

    const onLessonBlocksChange = () => {
        triggerUpdatePreviewEvent('course', courseId);
    };

    const {
        onCreateCacheUpdate,
        onDeleteCacheUpdate,
        onRecoverBlockWrapper,
        updateBlockOrderInCache,
    } = useBlockManage({
        cacheEntityId: `CourseLessonEntity:${lessonId}`,
        getExistBlocks: (): ContentElementItem[] | undefined => {
            const { cache } = apolloClient;

            const { courseLessonFindOne } = cache.readQuery<GetLessonQuery>({
                variables: {
                    courseId,
                    lessonId,
                },
                query: GetLessonDocument,
            }) || {};

            return courseLessonFindOne?.blocks;
        },
    });

    const [ _createBlock, {
        loading: createBlockLoading,
        error: createBlockError,
    } ] = useCourseLessonBlockManageCreateMutation({
        onError: error => console.error(error),
        update: (__, { data }) => onCreateCacheUpdate(data?.courseLessonBlockManageCreate),
    });

    const createBlock = (
        contentElement: CreateElementContentInput,
    ) => {
        return _createBlock({
            variables: {
                lessonId,
                contentElement,
            },
        });
    };

    const [ _updateBlock, {
        loading: updateBlockLoading,
        error: updateBlockError,
    } ] = useCourseLessonBlockManageUpdateMutation({
        onError: error => console.error(error),
    });

    const updateBlock = (
        contentElementId: number,
        contentElement: UpdateElementContentInput,
    ) => {
        return _updateBlock({
            variables: {
                lessonId,
                contentElementId,
                contentElement,
            },
        });
    };

    const [ _deleteBlock, {
        loading: deleteBlockLoading,
        error: deleteBlockError,
    } ] = useCourseLessonBlockManageDeleteMutation({
        onError: error => console.error(error),
        onCompleted: () => {
            onLessonBlocksChange();
        },
    });

    const deleteBlock = (
        contentElementId: number,
    ) => {
        return _deleteBlock({
            variables: {
                lessonId,
                contentElementId,
            },
            update: () => onDeleteCacheUpdate(contentElementId),
        });
    };

    const [ _recoverBlock, {
        loading: recoverBlockLoading,
        error: recoverBlockError,
    } ] = useCourseLessonBlockManageRecoverMutation({
        onError: error => console.error(error),
        onCompleted: () => {
            onLessonBlocksChange();
        },
    });

    const recoverBlock = (
        contentElementId: number,
        deletedBlock?: ContentElementItem,
    ) => {
        return onRecoverBlockWrapper(
            ({ recoverToCache }) => _recoverBlock({
                variables: {
                    lessonId,
                    contentElementId,
                },
                update: (__, { data }) => {
                    if (data && !deletedBlock) {
                        recoverToCache(data.courseLessonBlockManageRecover);
                    }
                },
            }),
            deletedBlock,
        );
    };

    const [ _updateBlockOrder, {
        loading: updateBlockOrderLoading,
        error: updateBlockOrderError,
    } ] = useCourseLessonBlockManageUpdateOrderMutation({
        onError: error => console.error(error),
        onCompleted: () => {
            onLessonBlocksChange();
        },
    });

    const updateBlockOrder = (
        blockOrder: UpdateLessonBlockOrderCourseInput,
    ) => {
        return _updateBlockOrder({
            variables: {
                lessonId,
                blockOrder,
            },
        });
    };

    return {
        createBlock,
        createBlockError,
        createBlockLoading,
        updateBlock,
        updateBlockError,
        updateBlockLoading,
        deleteBlock,
        deleteBlockError,
        deleteBlockLoading,
        recoverBlock,
        recoverBlockError,
        recoverBlockLoading,
        updateBlockOrder,
        updateBlockOrderError,
        updateBlockOrderLoading,
        updateBlockOrderInCache,
    };
};
