/**
 * Pagination component
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import React, { useLayoutEffect } from 'react';

import { ScrollHelper } from '@/helpers/ui';
import { ConfigStore } from '@/store/core/config';

import { StickyObserver } from '@/cutils';
import { Pagination as VkUiPagination, PaginationProps } from '@exode.ru/vkui';

import styles from './Pagination.module.scss';


interface Props extends PaginationProps {
    totalPages: number;
    sticky?: boolean;
}


const Pagination = (props: Props) => {

    const { totalPages, currentPage, sticky = false } = props;

    const { stickyContainer, centeredPagination, centeredPaginationRounded } = styles;

    const containerClass = sticky ? stickyContainer : '';

    const paginationClass = sticky
        ? centeredPagination
        : centeredPaginationRounded;

    const siblingCount = ConfigStore.isDesktop ? 2 : 1;

    if (totalPages <= 1) {
        return <></>;
    }

    useLayoutEffect(() => {
        ScrollHelper.to();
    }, [ currentPage ]);

    return (
        <StickyObserver stackedProps={{ 'data-stacked-bottom': 'true' }}>
            <div className={[ containerClass ].join(' ')}>
                <VkUiPagination siblingCount={siblingCount} className={[
                    paginationClass,
                    'd-thin-border m-thin-border-top',
                    props.className,
                ].join(' ')} {..._.omit(props, 'className')} />
            </div>
        </StickyObserver>
    );
};


export { Pagination };
