/**
 * ManageDrawerPart
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { Page } from '@/pages/Core';

import { ApolloError } from '@apollo/client/errors';

import { Box } from '@mui/material';
import { Separator } from '@exode.ru/vkui';
import { DrawerProps } from '@mui/material/Drawer/Drawer';

import { If, Portal } from '@/cutils';
import { DrawerContainer } from '@/components/Desktop/Navigation';
import { DesktopManageServicesListProps } from '@/components/Desktop/Manage/ManageServicesList';

import { SellerSelectPart } from './SellerSelect';


interface Props extends Pick<DesktopManageServicesListProps, 'shown'> {
    mode: 'admin' | 'seller' | 'admin-seller';
    handleClose: DrawerProps['onClose'];
    onError: (e: ApolloError) => void;
}


const ManageDrawerPart = (props: Props) => {

    const { shown, handleClose, mode, onError } = props;

    return (
        <DrawerContainer open={shown} anchor="right" onClose={handleClose} className="manage-drawer vkui__root">
            <Box sx={{ width: 365 }} role="presentation" className="thin-border-right h-full px-2">
                <Page.Row mode="plain">
                    <If is={mode === 'seller'}>
                        <SellerSelectPart onError={onError}/>

                        <Separator wide className="my-3"/>
                    </If>

                    <Portal renderer id="menu:context-inner"/>
                </Page.Row>
            </Box>
        </DrawerContainer>
    );
};


export { ManageDrawerPart };
