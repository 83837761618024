/**
 * TournamentStateIcon component
 *
 * @author: exode <hello@exode.ru>
 */

import React, { HTMLAttributes, ReactElement } from 'react';

import { TournamentState } from '@/codegen/graphql';

import {
    Icon24BlockOutline,
    Icon24BombOutline,
    Icon24CupOutline,
    Icon24LiveClockBadgeOutline,
    Icon24LiveOutline,
} from '@vkontakte/icons';


interface Props extends HTMLAttributes<HTMLDivElement> {
    state: TournamentState;
    inModal?: boolean;
}


export const stateText: { [key in TournamentState]: string } = {
    [TournamentState.Waiting]: 'Ожидание лобби',
    [TournamentState.StartLobby]: 'Лобби турнира открыто',
    [TournamentState.Process]: 'Турнир уже идет',
    [TournamentState.Result]: 'Турнир закончился',
    [TournamentState.Cancel]: 'Турнир был отменен',
};

export const icons: { [keys in TournamentState]: ReactElement } = {
    [TournamentState.Waiting]: <Icon24LiveClockBadgeOutline/>,
    [TournamentState.StartLobby]: <Icon24LiveOutline/>,
    [TournamentState.Process]: <Icon24BombOutline/>,
    [TournamentState.Result]: <Icon24CupOutline/>,
    [TournamentState.Cancel]: <Icon24BlockOutline/>,
};


const TournamentStateIcon = (props: Props) => {
    const { state, inModal } = props;

    const svgProps = {
        width: inModal ? 28 : 20,
        height: inModal ? 28 : 20,
        fill: inModal ? 'var(--accent)' : 'var(--white)',
    };

    return React.cloneElement(icons[state], { ...svgProps, ...props });
};


export { TournamentStateIcon };
