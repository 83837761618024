/**
 * TournamentRoutes
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { PageSpace } from '@/shared/types';
import { RouterItemsType } from '@/types/router';
import { TournamentTab } from '@/pages/Tournament/Tournament';
import { lazyWithRetry } from '@/helpers/react';

import { TournamentsTabHeaderPart } from '@/components/Panel/Header';


const TournamentBlitzSelectPage = lazyWithRetry(() => import('@/pages/Tournament/BlitzSelect'));
const TournamentBlitzWaitingPage = lazyWithRetry(() => import('@/pages/Tournament/BlitzWaiting'));
const TournamentCreationPage = lazyWithRetry(() => import('@/pages/Tournament/Creation'));
const TournamentLobbyPage = lazyWithRetry(() => import('@/pages/Tournament/Lobby'));
const TournamentMembersPage = lazyWithRetry(() => import('@/pages/Tournament/Members'));
const TournamentProcessPage = lazyWithRetry(() => import('@/pages/Tournament/Process'));
const TournamentResultPage = lazyWithRetry(() => import('@/pages/Tournament/Result'));
const TournamentSubjectsSelectPage = lazyWithRetry(() => import('@/pages/Tournament/SubjectsSelect'));
const TournamentInvitationRedirectPage = lazyWithRetry(() => import('@/pages/Tournament/LinkRedirect'));
const TournamentHistoryExpandedPage = lazyWithRetry(() => import('@/pages/Tournament/HistoryExpanded'));
const TournamentHistoryPage = lazyWithRetry(() => import('@/pages/Tournament/History'));


const TournamentRoutes: RouterItemsType = {
    '/tournaments': {
        view: <TournamentTab/>,
        header: <TournamentsTabHeaderPart/>,
        type: 'tab',
        tab: 'menu',
        spaces: [
            PageSpace.SchoolPlatform,
            PageSpace.MarketplacePlatform,
        ],
    },
    '/tournaments/:page([0-9]+)': {
        view: <TournamentTab/>,
        header: <TournamentsTabHeaderPart/>,
        type: 'tab',
        tab: 'menu',
        auth: [],
        spaces: [
            PageSpace.SchoolPlatform,
            PageSpace.MarketplacePlatform,
        ],
    },
    '/tournaments/history': {
        view: <TournamentHistoryPage/>,
        type: 'tab',
        tab: 'menu',
        auth: [],
        spaces: [
            PageSpace.SchoolPlatform,
            PageSpace.MarketplacePlatform,
            PageSpace.PromotionRightMenu,
        ],
    },
    '/tournaments/history/ongoing/:page([0-9]+)': {
        view: <TournamentHistoryExpandedPage/>,
        type: 'tab',
        tab: 'menu',
        auth: [],
        spaces: [
            PageSpace.SchoolPlatform,
            PageSpace.MarketplacePlatform,
        ],
    },
    '/tournaments/history/waiting/:page([0-9]+)': {
        view: <TournamentHistoryExpandedPage/>,
        type: 'tab',
        tab: 'menu',
        auth: [],
        spaces: [
            PageSpace.SchoolPlatform,
            PageSpace.MarketplacePlatform,
        ],
    },
    '/tournaments/history/finished/:page([0-9]+)': {
        view: <TournamentHistoryExpandedPage/>,
        type: 'tab',
        tab: 'menu',
        auth: [],
        spaces: [
            PageSpace.SchoolPlatform,
            PageSpace.MarketplacePlatform,
        ],
    },
    '/tournaments/create': {
        view: <TournamentCreationPage/>,
        type: 'tab',
        tab: 'menu',
        auth: [],
        spaces: [
            PageSpace.SchoolPlatform,
            PageSpace.MarketplacePlatform,
        ],
    },
    '/tournaments/subjects-select': {
        view: <TournamentSubjectsSelectPage/>,
        type: 'tab',
        tab: 'menu',
        spaces: [
            PageSpace.SchoolPlatform,
            PageSpace.MarketplacePlatform,
        ],
    },
    '/tournaments/blitz-select': {
        view: <TournamentBlitzSelectPage/>,
        type: 'tab',
        tab: 'menu',
        spaces: [],
    },
    '/tournament/blitz/waiting/:subject([a-z-]+)': {
        view: <TournamentBlitzWaitingPage/>,
        tab: 'menu',
        auth: [],
        spaces: [
            PageSpace.SchoolPlatform,
            PageSpace.MarketplacePlatform,
        ],
    },
    '/tournament/invite/:tournamentId([0-9]+)': {
        view: <TournamentInvitationRedirectPage/>,
        tab: 'menu',
        auth: [],
        spaces: [
            PageSpace.SchoolPlatform,
            PageSpace.MarketplacePlatform,
        ],
    },
    '/tournament/lobby/:tournamentId([0-9]+)': {
        view: <TournamentLobbyPage/>,
        tab: 'menu',
        auth: [],
        spaces: [
            PageSpace.SchoolPlatform,
            PageSpace.MarketplacePlatform,
        ],
    },
    '/tournament/members/:tournamentId([0-9]+)': {
        view: <TournamentMembersPage/>,
        type: 'tab',
        tab: 'menu',
        auth: [],
        spaces: [
            PageSpace.SchoolPlatform,
            PageSpace.MarketplacePlatform,
        ],
    },
    '/tournament/friends/:tournamentId([0-9]+)': {
        view: <TournamentMembersPage/>,
        type: 'tab',
        tab: 'menu',
        auth: [],
        spaces: [
            PageSpace.SchoolPlatform,
            PageSpace.MarketplacePlatform,
        ],
    },
    '/tournament/process/:tournamentId([0-9]+)': {
        view: <TournamentProcessPage/>,
        type: 'tab',
        tab: 'menu',
        auth: [],
        spaces: [
            PageSpace.SchoolPlatform,
            PageSpace.MarketplacePlatform,
        ],
    },
    '/tournament/process/:tournamentId([0-9]+)/progress': {
        view: <TournamentProcessPage/>,
        type: 'tab',
        tab: 'menu',
        auth: [],
        spaces: [
            PageSpace.SchoolPlatform,
            PageSpace.MarketplacePlatform,
        ],
    },
    '/tournament/result/:tournamentId([0-9]+)/:page([0-9]+)': {
        view: <TournamentResultPage/>,
        type: 'tab',
        tab: 'menu',
        auth: [],
        spaces: [
            PageSpace.SchoolPlatform,
            PageSpace.MarketplacePlatform,
        ],
    },
};


export { TournamentRoutes };
