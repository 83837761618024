/**
 * TournamentsTabHeaderPart
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { Link } from '@/cutils';
import { Page } from '@/pages/Core';
import { useI18n } from '@/hooks/core';

import { PanelHeader, PanelHeaderButton, PanelHeaderContent } from '@exode.ru/vkui';
import { Icon28ChevronDownOutline, Icon28HistoryForwardOutline, Icon28SlidersOutline } from '@vkontakte/icons';


const TournamentsTabHeaderPart = () => {

    const { t } = useI18n('components.Panel.Header.Tab.parts');

    return (
        <PanelHeader separator={false} className="tab-title" before={(
            <PanelHeaderButton>
                <Link pushPage={{ id: '/tournaments/history' }}>
                    <Icon28HistoryForwardOutline/>
                </Link>
            </PanelHeaderButton>
        )} after={(
            <PanelHeaderButton>
                <Link pushModal={{ id: 'tournament-filters' }}>
                    <Icon28SlidersOutline/>
                </Link>
            </PanelHeaderButton>
        )}>
            <Link pushPage={{ id: '/my-subjects' }}>
                <PanelHeaderContent aside={<Icon28ChevronDownOutline className="ml-0.5 mt-0.5"/>}>
                    <Page.HeaderContentWrapper>
                        <>{t('allSubjects')}</>
                    </Page.HeaderContentWrapper>
                </PanelHeaderContent>
            </Link>
        </PanelHeader>
    );
};


export { TournamentsTabHeaderPart };
