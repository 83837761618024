/**
 * LastOnlineText
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import moment from 'moment';

import React, { useEffect, useState } from 'react';

import { observer } from '@/pages/Core';

import { If } from '@/cutils';
import { Time } from '@/utils';
import { useI18n } from '@/hooks/core';
import { useUserOnline } from '@/hooks/user';
import { UserSexType } from '@/codegen/graphql';


interface Props {
    userId: number;
    lastOnlineAt: Date;
    hidePrefix?: boolean;
    sex?: UserSexType;
}


const LastOnlineText = observer((props: Props) => {

    const { userId, lastOnlineAt, hidePrefix } = props;

    const { t } = useI18n('components.Profile.Page');

    const [ parsedTime, setParsedTime ] = useState(Time.getHoursAndMinutes(lastOnlineAt));

    const { isOnline } = useUserOnline(userId);

    const { hours, minutes } = parsedTime;

    const hoursToDisplay = hours ? t('hours', { count: hours }) : '';

    const yearPrecision = (() => {
        const year = moment(lastOnlineAt).toDate().getFullYear();
        const currentYear = new Date().getFullYear();

        return year < currentYear ? t('year', { year }) : '';
    })();

    useEffect(() => {
        const onTick = () => {
            const newParsedTime = Time.getHoursAndMinutes(lastOnlineAt);

            !_.isEqual(parsedTime, newParsedTime) && setParsedTime(newParsedTime);
        };

        onTick();

        const interval = setInterval(onTick, 1000);

        return () => clearInterval(interval);
    }, [ lastOnlineAt ]);

    const dateFormat = [
        'D MMMM',
        yearPrecision,
        'в HH:mm',
    ].filter(e => e).join(' ');

    const wasOnline = (hours > 6
        ? [
            !hidePrefix && t('wasActive'),
            Time.parseRelative(lastOnlineAt, dateFormat).toLowerCase(),
        ]
        : [
            !hidePrefix && t('wasActive'),
            minutes === 0
                ? t('justNow') :
                `${hoursToDisplay} ${t('minutes', { count: minutes })} ${t('back')}`,
        ]).filter(e => e).join(' ');

    return (
        <span className="first-letter:capitalize">
            <If is={!lastOnlineAt}>
                <>{t('loading')}</>
            </If>

            <If is={!!lastOnlineAt}>
                <>{isOnline ? t('activeNow') : wasOnline}</>
            </If>
        </span>
    );
});


export { LastOnlineText };
