/**
 * App tournament subscriptions
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { observer, SubscriptionStore } from '@/store/subscription/subscription';

import { TournamentSubscription } from './TournamentSubscription';
import { TournamentHandleDisconnect } from './TournamentHandleDisconnect';
import { TournamentMembersSubscription } from './TournamentMembersSubscription';
import { TournamentChatMessagesSubscription } from './TournamentChatMessagesSubscription';
import { TournamentMembersProgressSubscription } from './TournamentMembersProgressSubscription';


const AppTournamentSubscription = observer(() => {
    return (
        <>
            <TournamentHandleDisconnect/>

            {[ ...SubscriptionStore.tournamentIds ].map((tournamentId) => (
                <TournamentSubscription key={tournamentId} tournamentId={tournamentId}/>
            ))}

            {[ ...SubscriptionStore.tournamentIdsForMembers ].map((tournamentId) => (
                <TournamentMembersSubscription key={tournamentId} tournamentId={tournamentId}/>
            ))}

            {[ ...SubscriptionStore.tournamentIdsForMembersProgress ].map((tournamentId) => (
                <TournamentMembersProgressSubscription key={tournamentId} tournamentId={tournamentId}/>
            ))}

            {[ ...SubscriptionStore.tournamentIdsForChatMessages ].map((tournamentId) => (
                <TournamentChatMessagesSubscription key={tournamentId} tournamentId={tournamentId}/>
            ))}
        </>
    );
});


export { AppTournamentSubscription };
