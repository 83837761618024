/**
 * App session subscriptions
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { Auth } from '@/cutils';

import { SessionSubscription } from './SessionSubscription';


const AppSessionSubscriptions = () => {
    return (
        <>
            <Auth>
                <SessionSubscription/>
            </Auth>
        </>
    );
};


export { AppSessionSubscriptions };
