/**
 * ChatForwardMessageModal
 *
 * @author: exode <hello@exode.ru>
 */

import React, { useLayoutEffect, useRef, useState } from 'react';

import { DebounceInput } from 'react-debounce-input';

import { Router } from '@/services/Utils/Router';
import { useChatSendMessage } from '@/hooks/apollo';
import { observer, Page, useStore } from '@/pages/Core';
import { ChatDialogsPageStore } from '@/pages/Chat/Dialog/store';

import { ChatFindManyQuery, ChatMessageEntity, SortDirection } from '@/codegen/graphql';

import { Search } from '@exode.ru/vkui';
import { Graphql, Modal } from '@/cutils';
import { DebouncedInput } from '@/types/html';

import { ScrollContainer } from '@/pages/Chat/Dialog/items/styled';
import { DialogsListView } from '@/pages/Chat/Dialog/views/DialogsListView';


interface Props {
    id: string;
}


const ChatForwardMessageModal = observer((props: Props) => {

    const [ search, setSearch ] = useState('');
    const parentRef = useRef<HTMLDivElement | null>(null);

    const variables = {
        list: { skip: 0, take: 15 },
        filter: { search },
        sort: { lastAction: SortDirection.Desc },
    };

    const {
        params: { forwardChatId, chatId },
        stater: { selectedMessages },
    } = useStore(ChatDialogsPageStore);

    const forwardingMessages = selectedMessages[+forwardChatId] || [];

    const { forwardChatMessage } = useChatSendMessage();

    const forwardMessage = (chatId: number) => {
        return forwardChatMessage(
            chatId,
            forwardingMessages as ChatMessageEntity[],
        );
    };

    useLayoutEffect(() => {
        if (!forwardingMessages.length) {
            (async () => {
                await Router.afterUpdate();

                Router.replacePage('/chat', { chatId });
            })();
        }
    }, [ forwardChatId ]);

    return (
        <Modal.Card id={props.id}>
            <Modal.Body fullHeight isScrollable>
                <div className={[
                    `flex justify-between m:rounded-none items-center`,
                    `p-0 h-[36px] pb-4 sticky top-0 d:rounded-tl-[14px]`,
                ].join(' ')}>
                    <DebounceInput minLength={1}
                                   maxLength={15}
                                   value={search}
                                   className="p-0"
                                   debounceTimeout={500}
                                   data-test="chat.forward.search"
                                   element={Search as DebouncedInput}
                                   onChange={({ target: { value } }) => setSearch(value)}/>
                </div>

                <ScrollContainer className="overflow-y-auto h-[calc(100%_-_52px)] relative">
                    <div ref={parentRef} className="h-full overflow-x-hidden">
                        <ChatFindManyQuery fetchPolicy="network-only" children={(result) => (
                            <>
                                <Graphql.Loading result={result}>
                                    <Page.Spinner/>
                                </Graphql.Loading>

                                <Graphql.Success result={result}>
                                    {({ chatFindMany: { items, cursor } }) => (
                                        <DialogsListView items={items}
                                                         cursor={cursor}
                                                         activeChatId={0}
                                                         isChatCellRounded
                                                         parentRef={parentRef}
                                                         variables={variables}
                                                         fetchMore={result.fetchMore}
                                                         onChatCellClick={forwardMessage}
                                                         previousVariables={result.variables}/>
                                    )}
                                </Graphql.Success>

                                <Graphql.Error result={result}/>
                            </>
                        )} variables={variables} nextFetchPolicy="network-only"/>
                    </div>
                </ScrollContainer>
            </Modal.Body>
        </Modal.Card>
    );
});


export { ChatForwardMessageModal };
