/**
 * GradientBanner component
 *
 * @author: exode <hello@exode.ru>
 *
 * @gradients: https://hypercolor.dev/
 */

import React, { ReactElement, ReactNode } from 'react';

import { ConfigStore, observer } from '@/store/core/config';

import { If } from '@/cutils';

import { Banner, BannerProps } from '@exode.ru/vkui';

import { Container } from './GradientBanner.styled';


export interface GradientBannerProps extends BannerProps {
    gradient?: string;
    image?: ReactElement;
    bgImage?: string;
    withShadow?: boolean;
    textColor?: string;
    transparent?: boolean;
    after?: ReactElement;
    adaptiveActions?: ReactNode;
    hideBeforeOnMobile?: boolean;
}

interface GradientBannerImageProps {
    children: ReactElement;
    className?: string;
}


const GradientBannerImage = (props: GradientBannerImageProps) => {

    const { children, className = '' } = props;

    return (
        <div className={[ 'Banner__image banner__image', className ].join(' ')}>
            {children}
        </div>
    );
};

const GradientBanner = observer((props: GradientBannerProps) => {

    const { isDesktop } = ConfigStore;
    const { adaptiveActions } = props;

    const {
        image,
        before,
        bgImage,
        withShadow,
        className = '',
        gradient = '',
        hideBeforeOnMobile = false,
        after = isDesktop && adaptiveActions ? adaptiveActions : undefined,
        actions = !isDesktop && adaptiveActions ? adaptiveActions : undefined,
        ...rest
    } = props;

    return (
        <Container data-component="GradientBanner" bgImage={bgImage} withShadow={withShadow} {...rest}>
            <If is={!!after}>
                <div className="z-10 absolute right-0 top-0 bottom-0">
                    {after}
                </div>
            </If>

            <Banner mode="image"
                    actions={actions}
                    className={className}
                    background={<div className={`banner-bg ${gradient}`}/>}
                    before={(!hideBeforeOnMobile || isDesktop) ? before : undefined}
                    {...rest}/>
            {image}
        </Container>
    );
});


export { GradientBanner, GradientBannerImage };
