/**
 * UseArticleBlockManage
 *
 * @author: exode <hello@exode.ru>
 */

import { apolloClient } from '@/api/graphql';

import { useBlockManage } from '@/hooks/apollo';

import {
    ArticleFindOneDocument,
    ArticleFindOneQuery,
    CreateElementContentInput,
    UpdateBlockOrderArticleInput,
    UpdateElementContentInput,
    useArticleBlockManageCreateMutation,
    useArticleBlockManageDeleteMutation,
    useArticleBlockManageRecoverMutation,
    useArticleBlockManageUpdateMutation,
    useArticleBlockManageUpdateOrderMutation,
} from '@/codegen/graphql';

import { ContentElementItem } from '@/components/ContentElement';


interface Props {
    articleId: number,
}


export const useArticleBlockManage = (props: Props) => {

    const { articleId } = props;

    const {
        onCreateCacheUpdate,
        onDeleteCacheUpdate,
        onRecoverBlockWrapper,
        updateBlockOrderInCache,
    } = useBlockManage({
        cacheEntityId: `ArticleEntity:${articleId}`,
        getExistBlocks: () => {
            const { cache } = apolloClient;

            const { articleFindOne } = cache.readQuery<ArticleFindOneQuery>({
                variables: { articleId },
                query: ArticleFindOneDocument,
            }) || {};

            return articleFindOne?.blocks;
        },
    });

    const [ _createBlock, {
        loading: createBlockLoading,
        error: createBlockError,
    } ] = useArticleBlockManageCreateMutation({
        onError: error => console.error(error),
        update: (__, { data }) => onCreateCacheUpdate(data?.articleBlockManageCreate),
    });

    const createBlock = (
        contentElement: CreateElementContentInput,
    ) => {
        return _createBlock({
            variables: {
                articleId,
                contentElement,
            },
        });
    };

    const [ _updateBlock, {
        loading: updateBlockLoading,
        error: updateBlockError,
    } ] = useArticleBlockManageUpdateMutation({
        onError: error => console.error(error),
    });

    const updateBlock = (
        contentElementId: number,
        contentElement: UpdateElementContentInput,
    ) => {
        return _updateBlock({
            variables: {
                articleId,
                contentElementId,
                contentElement,
            },
        });
    };

    const [ _deleteBlock, {
        loading: deleteBlockLoading,
        error: deleteBlockError,
    } ] = useArticleBlockManageDeleteMutation({
        onError: error => console.error(error),
    });

    const deleteBlock = (
        contentElementId: number,
    ) => {
        return _deleteBlock({
            variables: {
                articleId,
                contentElementId,
            },
            update: () => onDeleteCacheUpdate(contentElementId),
        });
    };

    const [ _recoverBlock, {
        loading: recoverBlockLoading,
        error: recoverBlockError,
    } ] = useArticleBlockManageRecoverMutation({
        onError: error => console.error(error),
    });

    const recoverBlock = (
        contentElementId: number,
        deletedBlock?: ContentElementItem,
    ) => {
        return onRecoverBlockWrapper(
            ({ recoverToCache }) => _recoverBlock({
                variables: {
                    articleId,
                    contentElementId,
                },
                update: (__, { data }) => {
                    if (data && !deletedBlock) {
                        recoverToCache(data?.articleBlockManageRecover);
                    }
                },
            }),
            deletedBlock,
        );
    };

    const [ _updateBlockOrder, {
        loading: updateBlockOrderLoading,
        error: updateBlockOrderError,
    } ] = useArticleBlockManageUpdateOrderMutation({
        onError: error => console.error(error),
    });

    const updateBlockOrder = (
        blockOrder: UpdateBlockOrderArticleInput,
    ) => {
        return _updateBlockOrder({
            variables: {
                articleId,
                blockOrder,
            },
        });
    };

    return {
        createBlock,
        createBlockError,
        createBlockLoading,
        updateBlock,
        updateBlockError,
        updateBlockLoading,
        deleteBlock,
        deleteBlockError,
        deleteBlockLoading,
        recoverBlock,
        recoverBlockError,
        recoverBlockLoading,
        updateBlockOrder,
        updateBlockOrderError,
        updateBlockOrderLoading,
        updateBlockOrderInCache,
    };
};
