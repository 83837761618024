/**
 * ModalPageHeader component
 *
 * @author: exode <hello@exode.ru>
 */

import React, { ReactElement } from 'react';

import { usePlatform } from '@/hooks/core';
import { Router } from '@/services/Utils/Router';

import { Icon24Dismiss } from '@vkontakte/icons';

import {
    ModalPageHeader as VkUiModalPageHeader,
    ModalPageHeaderProps as VkUiModalPageHeaderProps,
    PanelHeaderButton,
    PanelHeaderClose,
} from '@exode.ru/vkui';


interface Props extends VkUiModalPageHeaderProps {
    children: ReactElement | ReactElement[];
}


const ModalPageHeader = (props: Props) => {

    const platform = usePlatform();

    return (
        <VkUiModalPageHeader before={(
            platform === 'android'
                ? <PanelHeaderClose onClick={() => Router.replaceModal()}/>
                : undefined
        )} after={(
            platform === 'ios'
                ? (
                    <PanelHeaderButton onClick={() => Router.replaceModal()}>
                        <Icon24Dismiss/>
                    </PanelHeaderButton>
                )
                : undefined
        )} {...props}/>
    );
};


export { ModalPageHeader };
