/**
 * UtilsRoutes
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { RouterItemsType } from '@/types/router';
import { lazyWithRetry } from '@/helpers/react';


const UtilsSelectMimicryPage = lazyWithRetry(() => import ('@/pages/Utils/SelectMimicry'));


const UtilsRoutes: RouterItemsType = {
    '/utils/page': {
        view: <UtilsSelectMimicryPage/>,
        type: 'tab',
        spaces: [],
    },
};


export { UtilsRoutes };
