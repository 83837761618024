/**
 * DesktopNavigationMenu styled container
 *
 * @author: exode <hello@exode.ru>
 */

import styled from 'styled-components';

import { customScrollbar } from '@/styles/modules/scrollbar';


export const Container = styled.div`
  ${customScrollbar('--background_page')};

  max-height: calc(100vh - 185px);
  overflow-y: auto;
  overflow-x: hidden;

  .MuiPaper-root & {
    ${customScrollbar('--background_content')};
  }
`;
