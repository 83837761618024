/**
 * ChatService
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import moment from 'moment';

import { GqlResult } from '@/types/graphql';
import { ChatMessageItem } from '@/types/chat';

import {
    ChatActionPermission,
    ChatFindManyQueryResult,
    ChatFragmentFragment,
    ChatMessageType,
    ChatType,
} from '@/codegen/graphql';

import { BaseService } from '@/services/Core/Base';


class ChatService extends BaseService {

    /**
     * Check can send a new message
     * @param {} chat
     */
    static canSendMessage(
        chat: Pick<ChatFragmentFragment,
            'sendMessagePermission' |
            'isAdmin' |
            'myMember'>,
    ) {
        return chat.sendMessagePermission === ChatActionPermission.Admin
            ? !!chat.isAdmin
            : !chat.myMember?.blocked;
    }

    /**
     * Check gap by time interval
     * @param {number} messageId
     * @param {ChatMessageItem[]} chatMessages
     * @returns {boolean}
     */
    static hasGapByInterval(
        messageId: number,
        chatMessages: ChatMessageItem[],
    ) {
        const messages = chatMessages.filter((m) => m?.type === ChatMessageType.User);

        const index = _.findIndex(messages, { id: messageId });

        const interval = 5;
        const prevIndex = index - 1;

        if (!messages || !messages.length || index === 0) {
            return false;
        }

        /** Длинное сообщение, не будет видно отправителя */
        if ((messages[index]?.text?.length || 0) > 1000) {
            return false;
        }

        const prevTime = messages[prevIndex]?.createdAt;
        const currentTime = messages[index]?.createdAt;

        const diff = moment(currentTime).diff(prevTime, 'minutes');

        if (messages[index].user.id === messages[prevIndex].user.id) {
            return Math.abs(diff) <= interval;
        }

        return false;
    }

    /**
     * Parse chat date
     * @param {Date | string} date
     * @returns {string}
     */
    static parseChatDate(date: Date | string) {
        const dates = {
            sameDay: 'HH:mm',
            sameElse: 'DD.MM.YY',
            lastDay: 'ddd. в HH:mm',
            lastWeek: 'ddd. в HH:mm',
        };

        return moment(date, moment.ISO_8601).calendar(null, dates);
    }

    /**
     * Parse last message text or emoji (attachment/poll)
     * @param {NonNullable<GqlResult<>['chatFindMany']['items']>[number]['lastMessage']} message
     * @returns {string}
     */
    static lastMessageText(
        message: NonNullable<GqlResult<ChatFindManyQueryResult>['chatFindMany']['items']>[number]['lastMessage'],
    ) {
        const byContent = [
            message?.medias?.length && '📎',
            message?.text,
        ].filter(e => e).join(' ');

        return byContent || '📎';
    }

    /**
     * Check is a channel but not admin
     * @param {Pick<, 'type' | 'isAdmin'>} topBarData
     * @returns {boolean}
     */
    static isChannelButNotAdmin(
        topBarData?: Pick<ChatFragmentFragment, 'type' | 'isAdmin'>,
    ) {
        return topBarData?.type === ChatType.Channel && !topBarData?.isAdmin;
    }

}


export { ChatService };
