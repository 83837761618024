/**
 * ChatMessageSubscription component
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import React from 'react';

import { ConfigStore } from '@/store/core/config';

import { DocumentEvent } from '@/types/window';
import { UpdatedChatMessage } from '@/types/chat';
import { observer, useStore } from '@/pages/Core';
import { ChatDialogsPageStore } from '@/pages/Chat/Dialog/store';

import {
    ChatMessageEventAction,
    ChatMessageFindManyDocument,
    ChatMessageFindManyQuery,
    CursorOutput,
    useChatListenNewMessageSubscription,
} from '@/codegen/graphql';


interface Props {
    chatId: number;
}


const ChatMessageSubscription = observer(({ chatId }: Props) => {

    const { list, filter, sort } = useStore(ChatDialogsPageStore);

    useChatListenNewMessageSubscription({
        variables: { chatId },
        onError: error => console.error(error),
        onData: ({ data: { data }, client: { cache } }) => {
            if (!data) {
                return console.warn('[Cache]: useChatListenNewMessageSubscription отсутствует data');
            }

            const { messages, action } = data.chatListenNewMessage;

            const variables = {
                chatId,
                list: { ...list.messages },
                filter: { ...filter.messages },
                sort: { ...sort.messages },
            };

            if (!messages || !messages.length) {
                return;
            }

            const cachedQueryResult = cache.readQuery<ChatMessageFindManyQuery>({
                query: ChatMessageFindManyDocument,
                variables,
            });

            const {
                cursor: cachedCursor,
                items: cachedMessages = [],
            } = cachedQueryResult?.chatMessageFindMany || {};

            if (!cachedQueryResult) {
                return console.warn('[Cache]: cachedQueryResult отсутствует в кэше');
            }

            /** Handle edit and read message */
            const mergeCachedWithUpdatedMessages = () => {
                const updatedMessages: { [key: number]: UpdatedChatMessage } = {};

                messages.forEach(item => updatedMessages[item.id] = item);

                return cachedMessages?.map((item) => {
                    return updatedMessages[item.id] ? updatedMessages[item.id] : item;
                });
            };

            const writeToQueryCache = (
                items: ChatMessageFindManyQuery['chatMessageFindMany']['items'],
                cursor?: ChatMessageFindManyQuery['chatMessageFindMany']['cursor'],
            ) => {
                cache.writeQuery<ChatMessageFindManyQuery>({
                    query: ChatMessageFindManyDocument,
                    variables,
                    data: {
                        __typename: 'Query',
                        chatMessageFindMany: {
                            items,
                            __typename: 'ListMessageChatOutput',
                            cursor: cursor || cachedCursor as CursorOutput,
                        },
                    },
                });
            };

            switch (action) {
                case ChatMessageEventAction.NewMessage:
                    const message = messages[0];

                    if (message.isMine && message.userTabUuid === ConfigStore.tabUuid) {
                        break;
                    }

                    document.dispatchEvent(
                        new CustomEvent(
                            DocumentEvent.ChatBeforeNewMessageAddInCache,
                            { detail: { chatId } },
                        ),
                    );

                    writeToQueryCache([ ...(cachedMessages || []), message ]);

                    document.dispatchEvent(
                        new CustomEvent(
                            DocumentEvent.ChatAfterNewMessageAddInCache,
                            { detail: { chatId } },
                        ),
                    );

                    break;

                case ChatMessageEventAction.EditMessage:
                case ChatMessageEventAction.ReadMessages:
                    writeToQueryCache(mergeCachedWithUpdatedMessages());

                    break;

                case ChatMessageEventAction.DeleteMessages:
                    writeToQueryCache(_.differenceBy(cachedMessages, messages, 'id'));

                    break;
            }
        },
    });

    return (<></>);
});


export { ChatMessageSubscription };
