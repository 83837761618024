/**
 * School store
 *
 * @author: exode <hello@exode.ru>
 */

import { observer } from 'mobx-react';
import { makeAutoObservable, observable } from 'mobx';

import { getSchoolWithDefaults } from '@/hooks/apollo';
import { SchoolFragmentFragment } from '@/codegen/graphql';


class School {

    constructor() {
        makeAutoObservable(this);
    }

    /**
     * Id текущей школы
     * @type {number}
     */
    schoolId: number = window.exode.school?.id as number;

    /** Информация о текущей школе (синхронизируется при обновлении/socket) */
    @observable
    school: SchoolFragmentFragment | null = window.exode.school;

    /** Получение настроек блоков */
    @observable
    get blocks() {
        const school = getSchoolWithDefaults(this.school);

        return school?.blockSettings as SchoolFragmentFragment['blockSettings'];
    }

    /** Получение настроек преференций */
    @observable
    get preferences() {
        return this.school?.preferenceSettings as SchoolFragmentFragment['preferenceSettings'];
    }

    /** Установка блоков */
    setBlocks(
        blockSettings: Partial<SchoolFragmentFragment['blockSettings']>,
    ) {
        if (this.school?.blockSettings) {
            this.school.blockSettings = {
                ...this.school.blockSettings,
                ...blockSettings,
            };
        }
    }

    /**
     * Установка значений в поля store
     * @param partial
     */
    merge(partial: Partial<School>) {
        Object.assign(this, partial);
    }

}

const SchoolStore = new School();


export { observer, SchoolStore };
