/**
 * LessonCardInfoWrapper component
 *
 * @author: exode <hello@exode.ru>
 */

import React, { ReactElement } from 'react';


interface Props {
    children: ReactElement | ReactElement[];
    className?: string;
    fullWidthOnDesktop?: boolean;
}


const LessonCardInfoWrapper = (props: Props) => {

    const { children, className, fullWidthOnDesktop } = props;

    return (
        <div className={[
            className,
            'max-w-[500px] thin-border bg-hover',
            'my-4 d:mx-24 p-4 text-left m:mx-4 rounded-2xl',
            fullWidthOnDesktop
                ? 'd:!max-w-[calc(100%_-_2rem)] d:!my-0 d:w-full d:!mx-0 m:bg-hover d:bg-content'
                : '',
        ].join(' ')}>
            {children}
        </div>
    );
};


export { LessonCardInfoWrapper };
