/**
 * RndVideo component
 *
 * @author: exode <hello@exode.ru>
 */

import React, { useEffect, useState } from 'react';

import { observer } from 'mobx-react';
import { Position, Rnd } from 'react-rnd';

import { PortalStore } from '@/store/core/portal';
import { ConfigStore } from '@/store/core/config';

import { If } from '@/cutils';
import { usePlyrApi } from '@/hooks/core';

import { RndVideoOrientation } from '@/components/Atoms/RndVideo';

import { VideoPart } from './parts/VideoPart';
import { DragHandlerPart } from './parts/DragHandlerPart';
import { ControlPanelPart } from './parts/ControlPanelPart';
import { ResizeHandlerPart } from './parts/ResizeHandlerPart';

import { Wrapper } from './RndVideo.styled';


export interface RndVideoProps {
    videoLink?: string;
    withHandler?: boolean;
    position?: Position;
    size?: { width: number | string, height: number | string };
}


const RndVideo = observer((props: RndVideoProps) => {

    const {
        size,
        position,
        withHandler,
        videoLink = PortalStore.rndVideoLink,
    } = props;

    const [ muted, setMuted ] = useState(true);
    const [ playing, setPlaying ] = useState(false);

    const [ orientation, setOrientation ] = useState<RndVideoOrientation>('vertical');

    const width = 320;
    const height = 'auto';

    const x = ConfigStore.isDesktop
        ? window.innerWidth - 100 - 320
        : window.innerWidth - 350;

    const y = ConfigStore.isDesktop
        ? window.innerHeight - 100 - 180 / 2
        : window.innerHeight - 320;

    const [ defaultPosition, setDefaultPosition ] = useState({ x, y, width, height });

    const { plyrVideoRef, getPlyrApi, forceStartPlay } = usePlyrApi({
        videoLink,
        onPlay: () => setPlaying(true),
        onPause: () => setPlaying(false),
        onReady: () => ConfigStore.interaction.lastClickAt && forceStartPlay(),
        onVolumeChange: (plyr) => plyr && setMuted(plyr.muted),
    });

    useEffect(() => {
        setDefaultPosition(prevState => ({ ...prevState, x, y }));
    }, [ ConfigStore.isDesktop ]);

    return (
        <If is={!!videoLink}>
            <Wrapper className="fixed top-0 left-0 right-0 bottom-0 pointer-events-none h-[100vh] z-[10000]">
                <Rnd size={size}
                     minWidth={320}
                     minHeight={180}
                     bounds="window"
                     position={position}
                     default={defaultPosition}
                     style={{ pointerEvents: 'auto' }}
                     resizeHandleComponent={{ topLeft: <ResizeHandlerPart/> }}
                     lockAspectRatio={orientation === 'horizontal' ? 9 / 16 : 16 / 9}>
                    <If is={!!withHandler}>
                        <DragHandlerPart/>
                    </If>

                    <div className="absolute z-[100] top-0 left-0 w-full h-full"/>

                    <ControlPanelPart playing={playing}
                                      muted={muted}
                                      getPlyrApi={getPlyrApi}
                                      orientation={orientation}
                                      setOrientation={setOrientation}/>

                    <VideoPart videoLink={videoLink!} orientation={orientation} plyrVideoRef={plyrVideoRef}/>
                </Rnd>
            </Wrapper>
        </If>
    );
});


export { RndVideo };
