/**
 * DesktopNavigationMenuDrawer component
 *
 * @author: exode <hello@exode.ru>
 */

import React, { KeyboardEvent, MouseEvent, useState } from 'react';

import { Responsive } from '@/cutils';

import { Separator } from '@exode.ru/vkui';

import { DrawerContainer } from '@/components/Desktop/Navigation';
import { HeaderLogoPart } from '@/components/Desktop/Panel/Header';
import { MenuItemsPart } from '@/components/Desktop/Navigation/Menu';

import { BurgerMenuPart } from './parts/BurgerMenu';


const DesktopNavigationMenuDrawer = () => {

    const [ open, setOpen ] = useState(false);

    const toggleDrawer = (isOpen: boolean) => ((event: KeyboardEvent | MouseEvent) => {
        const { key } = event as KeyboardEvent;

        if (event.type === 'keydown' && [ 'Tab', 'Shift' ].includes(key)) {
            return;
        }

        setOpen(isOpen);
    });

    return (
        <div className="flex items-center font-vk-sans-display vkui__root">
            <Responsive.TabletOnly>
                <BurgerMenuPart onClick={toggleDrawer(true)}/>
            </Responsive.TabletOnly>

            <DrawerContainer open={open}
                             onClick={toggleDrawer(false)}
                             onClose={toggleDrawer(false)}
                             onKeyDown={toggleDrawer(false)}
                             className="vkui__root left-menu-drawer">
                <div className="flex items-center ml-3.5 h-panel-header text-primary">
                    <HeaderLogoPart/>
                </div>

                <Separator className="mx-4 mt-1 mb-2.5"/>

                <MenuItemsPart wrapped/>
            </DrawerContainer>
        </div>
    );
};


export { DesktopNavigationMenuDrawer };
