/**
 * Api constants
 *
 * @author: exode <hello@exode.ru>
 */

import { API_DOMAIN, API_VERSION, HTTP_PROTOCOL, WS_PROTOCOL } from '@/root/src/env';


/**
 * Абсолютная ссылка rest api
 *
 * @type {string}
 */
export const restApiUrl = process.env.REACT_APP_REST_API_URL ||
    `${HTTP_PROTOCOL}://${API_DOMAIN}/api/v${API_VERSION}/`;

/**
 * Абсолютная ссылка GraphQL
 *
 * @type {string}
 */
export const graphQlApiUrl = process.env.REACT_APP_GRAPH_QL_URL ||
    `${HTTP_PROTOCOL}://${API_DOMAIN}/graphql`;

/**
 * Абсолютная ссылка socket.io
 *
 * @type {string}
 */
export const wsUrl = process.env.REACT_APP_WS_API_URL ||
    `${HTTP_PROTOCOL}://${API_DOMAIN}/socket/v${API_VERSION}`;

/**
 * Абсолютная ссылка WS GraphQL
 *
 * @type {string}
 */
export const wsGraphqlUrl = `${WS_PROTOCOL}://${graphQlApiUrl.split('://')[1]}`;

