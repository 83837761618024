/**
 * TabRoutes
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { PageSpace } from '@/shared/types';

import { lazyWithRetry } from '@/helpers/react';
import { RouterItemsType } from '@/types/router';

import {
    CatalogTabHeaderPart,
    ChatTabHeaderPart,
    EducationTabHeaderPart,
    MenuTabHeaderPart,
} from '@/components/Panel/Header';


const MainTab = lazyWithRetry(() => import('@/tabs/Main'));
const EducationTab = lazyWithRetry(() => import('@/tabs/Education'));
const CourseCatalogPage = lazyWithRetry(() => import('@/tabs/Catalog'));
const ChatDialogsPage = lazyWithRetry(() => import('@/pages/Chat/Dialog'));
const MenuTab = lazyWithRetry(() => import('@/tabs/Menu'));


const TabRoutes: RouterItemsType = {
    '/': {
        view: <MainTab/>,
        header: <CatalogTabHeaderPart/>,
        type: 'iframe',
    },
    '/education': {
        view: <EducationTab/>,
        header: <EducationTabHeaderPart/>,
        type: 'tab',
        tab: 'education',
        auth: [],
        spaces: [
            PageSpace.SchoolPlatform,
            PageSpace.MarketplacePlatform,
        ],
    },
    '/catalog': {
        view: <CourseCatalogPage/>,
        header: <CatalogTabHeaderPart/>,
        type: 'iframe',
        subType: 'tab',
        tab: 'catalog',
        spaces: [
            PageSpace.SchoolPlatform,
            PageSpace.MarketplacePlatform,
        ],
    },
    '/catalog/:page([0-9]+)': {
        view: <CourseCatalogPage/>,
        header: <CatalogTabHeaderPart/>,
        type: 'iframe',
        subType: 'tab',
        tab: 'catalog',
        spaces: [
            PageSpace.SchoolPlatform,
            PageSpace.MarketplacePlatform,
        ],
    },
    '/chat': {
        view: <ChatDialogsPage/>,
        header: <ChatTabHeaderPart/>,
        type: 'tab',
        tab: 'chat',
        auth: [],
        spaces: [
            PageSpace.SchoolPlatform,
            PageSpace.MarketplacePlatform,
        ],
    },
    '/menu': {
        view: <MenuTab/>,
        header: <MenuTabHeaderPart/>,
        type: 'tab',
        tab: 'menu',
        auth: [],
        spaces: [
            PageSpace.SchoolPlatform,
            PageSpace.MarketplacePlatform,
        ],
    },
};


export { TabRoutes };
