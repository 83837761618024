/**
 * LeftPart
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { PageSpace } from '@/shared/types';
import { Link, Platform, Responsive } from '@/cutils';

import { DesktopNavigationMenuDrawer } from '@/components/Desktop/Navigation';

import { HeaderLogoPart } from './HeaderLogoPart';


const LeftPart = () => {
    return (
        <div className="viewport__left-menu vkuiSplitCol--spaced h-full">
            <div className="flex items-center h-full">
                <Platform.If platforms={[
                    PageSpace.SchoolPlatform,
                    PageSpace.MarketplacePlatform,
                ]}>
                    <DesktopNavigationMenuDrawer/>
                </Platform.If>

                <Responsive.DesktopOnly>
                    <Link pushPage={{ id: '/' }}>
                        <HeaderLogoPart/>
                    </Link>
                </Responsive.DesktopOnly>
            </div>
        </div>
    );
};


export { LeftPart };
