/**
 * Manage global
 *
 * @author: exode <hello@exode.ru>
 */

import { useI18n } from '@/hooks/core';

import { Permission } from '@/codegen/graphql';

import { SellerManagerPermissions } from '@/types/manage';


export const getProductManagerPermissions: () => SellerManagerPermissions = () => {

    const { t } = useI18n('types.manage.permissions.manager.product');

    return [
        {
            value: Permission.CourseManage,
            title: t('manageCourse'),
            description: t('userCanManageCourseContent'),
        },
        {
            value: Permission.CourseView,
            title: t('viewCourse'),
            description: t('userCanViewCourseInList'),
        },
        {
            value: Permission.CourseCurator,
            title: t('courseCurator'),
            description: t('userCanCheckStudentHomework'),
        },
        {
            value: Permission.CourseReportingView,
            title: t('viewCourseReports'),
            description: t('userCanViewCurrentCourseReports'),
        },
        {
            value: Permission.CourseCommentsManage,
            title: t('manageCourseComments'),
            description: t('userCanManageCourseCommentsLeftByStudents'),
        },
        {
            value: Permission.ProductManage,
            title: t('manageProductPart'),
            description: t('userCanManageLaunchesPricesCouponsPublication'),
        },
    ];
};
