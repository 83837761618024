/**
 * HeaderItem
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { Counter, Header, Text } from '@exode.ru/vkui';

import { If } from '@/cutils';
import { useI18n } from '@/hooks/core';
import { useNotificationRead } from '@/hooks/apollo';

import styles from '../ProfileNotificationsPage.module.scss';


interface Props {
    count: number;
    isUnread?: boolean;
}


const HeaderItem = (props: Props) => {

    const { count, isUnread = false } = props;

    const { t } = useI18n('pages.Profile.Notifications.items');

    const { readAllNotifications, readAllNotificationLoading } = useNotificationRead();

    const readAll = async () => {
        if (isUnread && !readAllNotificationLoading) {
            await readAllNotifications();
        }
    };

    return (
        <Header mode="secondary" className={styles.newNotificationsCount} aside={(
            <If is={isUnread}>
                <Text onClick={readAll} className="fs-14 font-semibold text-accent cursor-pointer">
                    {!readAllNotificationLoading ? t('readAll') : t('oneMinute')}
                </Text>
            </If>
        )}>
            {isUnread ? t('newNotifications') : t('lastRead')}

            <If is={!!count}>
                <Counter size="s" mode={isUnread ? 'prominent' : 'primary'}>
                    {count}
                </Counter>
            </If>
        </Header>
    );
};


export { HeaderItem };
