/**
 * NotificationSignal styled container
 *
 * @author: exode <hello@exode.ru>
 */

import styled from 'styled-components';


export const Container = styled.div`
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: 0.5px var(--accent) solid;

    .vkuiIcon {
        min-width: 16px;
    }

    .dot {
        width: 8px;
        height: 8px;
        border-radius: 100%;
        background-color: var(--accent);
    }
`;
