/**
 * EducationTabHeaderPart
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { Page } from '@/pages/Core';
import { useI18n } from '@/hooks/core';
import { Auth, Graphql, Link, Unauth } from '@/cutils';

import { CountByTypeMode, CourseCountByTypeQuery, CourseParticipationType } from '@/codegen/graphql';

import { Icon28ArticleOutline, Icon28VideoCircleOutline } from '@vkontakte/icons';
import { Counter, PanelHeader, PanelHeaderButton, PanelHeaderContent } from '@exode.ru/vkui';


const EducationTabHeaderPart = () => {

    const { t } = useI18n('components.Panel.Header.Tab.parts');

    const placeholder = (
        <Link pushPage={{ id: '/courses/enrolled' }}>
            <PanelHeaderButton>
                <Icon28VideoCircleOutline/>
            </PanelHeaderButton>
        </Link>
    );

    const before = (
        <>
            <Auth>
                <CourseCountByTypeQuery children={(result) => (
                    <>
                        <Graphql.Loading result={result}>
                            {placeholder}
                        </Graphql.Loading>

                        <Graphql.Success result={result}>
                            {({ courseCountByType: counter }) => (
                                <Link pushPage={{ id: '/courses/enrolled' }}>
                                    <PanelHeaderButton label={(
                                        <Counter size="s" mode="primary" className="absolute !top-[23px]">
                                            {counter?.totalCount ?? 0}
                                        </Counter>
                                    )}>
                                        <Icon28VideoCircleOutline/>
                                    </PanelHeaderButton>
                                </Link>
                            )}
                        </Graphql.Success>

                        <Graphql.Error result={result}>
                            {placeholder}
                        </Graphql.Error>
                    </>
                )} variables={{
                    mode: CountByTypeMode.Enrolled,
                    filter: {
                        manage: false,
                        participation: CourseParticipationType.All,
                    },
                }}/>
            </Auth>

            <Unauth>
                <Link pushPage={{ id: '/courses/enrolled' }}>
                    <PanelHeaderButton>
                        <Icon28VideoCircleOutline/>
                    </PanelHeaderButton>
                </Link>
            </Unauth>
        </>
    );

    return (
        <PanelHeader before={before} separator={false} className="tab-title" after={(
            <Link pushPage={{ id: '/my-subjects' }}>
                <PanelHeaderButton>
                    <Icon28ArticleOutline/>
                </PanelHeaderButton>
            </Link>
        )}>
            <PanelHeaderContent>
                <Page.HeaderContentWrapper>
                    <>{t('education')}</>
                </Page.HeaderContentWrapper>
            </PanelHeaderContent>
        </PanelHeader>
    );
};


export { EducationTabHeaderPart };
