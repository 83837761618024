/**
 * Auth util
 *
 * @author: exode <hello@exode.ru>
 */

import React, { ReactElement } from 'react';

import { observer, UserAuthStore } from '@/store/user/auth';


interface Props {
    children: ReactElement | ReactElement[];
}


const Auth = observer((props: Props) => {
    return UserAuthStore.isLoggedIn ? <>{props.children}</> : <></>;
});


const Unauth = observer((props: Props) => {
    return !UserAuthStore.isLoggedIn ? <>{props.children}</> : <></>;
});


export { Auth, Unauth };
