/**
 * Event helper
 *
 * @author: exode <hello@exode.ru>
 */

import { DocumentEvent } from '@/types/window';


export const waitDocumentEvent = (event: DocumentEvent) => {
    return new Promise((resolve) => {
        document.addEventListener(event, (e) => resolve(e), { once: true });
    });
};
