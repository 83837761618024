/**
 * ManageSchoolUsersPage store
 *
 * @author: exode <hello@exode.ru>
 */

import { makeObservable, observable } from 'mobx';

import { Router } from '@/services/Utils/Router';

import { observer, PageStore } from '@/pages/Core';

import { UserEditTab } from './interfaces';
import { defaultFilter, dtoFilterTransformer } from './filter';


class ManageSchoolUsersPage extends PageStore {

    constructor() {
        super();
        makeObservable(this);

        this.userEditActiveTab = UserEditTab.Study;
    }

    /** Текущая вкладка manage-user-edit modal */
    @observable
    userEditActiveTab: UserEditTab;

    setUserEditActiveTab = (nextTab: UserEditTab) => {
        Router.updateParams({ tab: nextTab });

        this.userEditActiveTab = nextTab;
    };

    state = {
        list: {
            skip: 0,
            take: 25,
        },
        filter: dtoFilterTransformer(defaultFilter),
        sort: {},
    };

}

const ManageSchoolUsersPageStore = new ManageSchoolUsersPage();


export { observer, ManageSchoolUsersPageStore };
