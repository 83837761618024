/**
 * CreatePayoutFormView
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';
import * as Yup from 'yup';

import { observer, PlatformStore } from '@/store/platform';

import { NumberUtil, regex } from '@/utils';
import { Router } from '@/services/Utils/Router';
import { AutoSaveForm, Field, Graphql, If, Notify, pointedNumber } from '@/cutils';

import React, { useRef, useState } from 'react';

import { useI18n } from '@/hooks/core';

import { Formik, FormikProps } from 'formik';

import { usePayout } from '@/hooks/apollo';
import { getGqlCause } from '@/api/graphql';
import { productCurrency } from '@/types/product';
import { payoutOperationExceptionText } from '@/types/payout';

import { CreateOperationPayoutInput, PayoutException, PayoutRequisiteFindOneActiveQuery } from '@/codegen/graphql';

import { Icon24MoneyRequestOutline } from '@vkontakte/icons';
import { Button, FormItem, InfoRow, Spinner } from '@exode.ru/vkui';

import { AmountText } from '@/components/Atoms/AmountText';
import { FilterInput } from '@/components/Atoms/FilterInput';
import { InputSideInfo } from '@/components/Atoms/InputSideInfo';
import { CreatePayoutFormSkeleton } from '@/components/Atoms/Skeleton/CreatePayoutFormSkeleton';

import { CalculateBeforePayoutItem } from '../items/CalculateBeforePayoutItem';


const CreatePayoutFormView = observer(() => {

    const { COMMON } = PlatformStore;

    const payoutOperationExceptionTexts = payoutOperationExceptionText();

    const { t } = useI18n('pages.Manage.Payout.views');

    const formikRef = useRef<FormikProps<any>>(null);

    const [ isChangedAfterCalculation, setIsChangedAfterCalculation ] = useState(false);

    const {
        createPayoutOperation,
        createPayoutOperationLoading,
        refetchOnPayoutCreated,
        getCalculateBeforePayoutLazy,
        calculateBeforePayoutLazy,
        calculateBeforePayoutLazy: { minAmount },
        getCalculateBeforePayoutLazyLoading,
    } = usePayout();

    /** Handle form submit */
    const handleSubmit = (values: CreateOperationPayoutInput) => {
        createPayoutOperation(
            { ...values, amount: pointedNumber(values.amount) },
            async () => {
                Router.replaceModal();

                Notify.toast.success(t('withdrawOperationIsCreated'));

                await refetchOnPayoutCreated();
            },
            (e) => {
                Router.replaceModal();

                const message = payoutOperationExceptionTexts[getGqlCause(e) as PayoutException];

                if (message) {
                    Notify.toast.error(message);
                }
            },
        );
    };

    /** Set full payout balance to payout */
    const setFullPayoutBalanceToAmount = async (
        props: Parameters<typeof triggerCalculationOnChanges>[0],
        payoutBalance: number,
    ) => {
        const { setFieldValue } = props;

        setFieldValue('amount', payoutBalance);

        setImmediate(() => {
            triggerCalculationOnChanges(
                props,
                { amount: payoutBalance },
            );
        });
    };

    /**
     * Trigger calculation on changes
     * @param {} props
     * @param {Partial<>} newValues
     */
    const triggerCalculationOnChanges = async (
        props: Pick<
            FormikProps<CreateOperationPayoutInput>,
            'validateForm' | 'values' | 'setFieldValue' | 'setFieldTouched'
        >,
        newValues: Partial<CreateOperationPayoutInput>,
    ) => {
        const { validateForm, setFieldTouched, values } = props;

        const validation = await validateForm();

        setFieldTouched('amount', true);

        if (_.isEmpty(validation)) {
            setIsChangedAfterCalculation(true);

            await getCalculateBeforePayoutLazy({ ...values, ...newValues });

            setIsChangedAfterCalculation(false);
        }
    };

    return (
        <PayoutRequisiteFindOneActiveQuery fetchPolicy="network-only" children={(result) => (
            <>
                <Graphql.Loading result={result}>
                    <FormItem>
                        <CreatePayoutFormSkeleton/>
                    </FormItem>
                </Graphql.Loading>

                <Graphql.Success result={result}>
                    {({
                          sellerBalanceCurrent: { payoutBalance },
                          payoutRequisiteFindOneActive: requisite,
                      }) => (
                        <If is={!!requisite}>
                            <Formik validateOnBlur
                                    validateOnMount
                                    validateOnChange
                                    innerRef={formikRef}
                                    isInitialValid={false}
                                    onSubmit={handleSubmit}
                                    initialValues={{
                                        amount: 0,
                                        requisiteId: requisite?.id || 0,
                                    }}
                                    validationSchema={(Yup.object().shape({
                                        amount: Yup.number()
                                            .required(t('enterAmountToWithdraw'))
                                            .min(minAmount || 0, t('min', { min: NumberUtil.splitByDecimal(minAmount || 0) }))
                                            .max(
                                                payoutBalance,
                                                payoutBalance > (minAmount || 0)
                                                    ? t('max', { max: NumberUtil.splitByDecimal(payoutBalance) })
                                                    : t('noEnoughFoundsOnBalance'),
                                            ),
                                    }))}>
                                {({
                                      values,
                                      isValid,
                                      errors,
                                      touched,
                                      handleBlur,
                                      handleSubmit,
                                      validateForm,
                                      setFieldValue,
                                      setFieldTouched,
                                  }) => (
                                    <AutoSaveForm delay={250}
                                                  onSubmit={handleSubmit}
                                                  onInstantChange={() => setIsChangedAfterCalculation(true)}
                                                  onChange={async (e: any) => {
                                                      if (e.target.name === 'amount' && e.target.value) {
                                                          await triggerCalculationOnChanges({
                                                              values,
                                                              validateForm,
                                                              setFieldValue,
                                                              setFieldTouched,
                                                          }, { amount: +e.target.value });
                                                      }
                                                  }}>
                                        <FormItem>
                                            <InfoRow header={t('availableForPayout')}
                                                     className="vk-rounded p-3 bg-input thin-border">
                                                <div className="mt-2">
                                                    <AmountText semibold
                                                                amount={payoutBalance || 0}
                                                                className="pt-2 fs-22 font-vk-sans-display"
                                                                currency={COMMON?.organization.settings.currency}/>
                                                </div>
                                            </InfoRow>
                                        </FormItem>

                                        <FormItem top={t('payoutAmount')}
                                                  status={Field.status(errors, touched, 'amount')}
                                                  bottom={(
                                                      <>
                                                          <span>
                                                              {Field.message(errors, touched, 'amount')}
                                                          </span>

                                                          <If is={payoutBalance !== values.amount}>
                                                              <div className="flex items-center justify-center mt-3">
                                                                  <Button size="s"
                                                                          mode="tertiary"
                                                                          onClick={async () => {
                                                                              await setFullPayoutBalanceToAmount({
                                                                                  values,
                                                                                  validateForm,
                                                                                  setFieldValue,
                                                                                  setFieldTouched,
                                                                              }, payoutBalance);
                                                                          }}>
                                                                      {t('withdrawEntireAmount')}
                                                                  </Button>
                                                              </div>
                                                          </If>
                                                      </>
                                                  )}>
                                            <FilterInput name="amount"
                                                         autoComplete="off"
                                                         onBlur={handleBlur}
                                                         max={payoutBalance}
                                                         min={minAmount || 0}
                                                         value={values.amount || ''}
                                                         regExp={regex.floatNumberOnInput}
                                                         placeholder={t('from', {
                                                             minAmount: minAmount,
                                                             currency: COMMON && productCurrency[COMMON.organization.settings.currency],
                                                         })}
                                                         after={(
                                                             getCalculateBeforePayoutLazyLoading
                                                                 ? <Spinner/>
                                                                 : (
                                                                     <InputSideInfo side="after">
                                                                         {COMMON && productCurrency[COMMON.organization.settings.currency]}
                                                                     </InputSideInfo>
                                                                 )
                                                         )}
                                                         callback={(e) => setFieldValue(
                                                             'amount',
                                                             e.target.value,
                                                         )}/>
                                        </FormItem>

                                        <FormItem className={[
                                            'mb-1 transition-all',
                                            !isValid ? 'opacity-0' : '',
                                        ].join(' ')}>
                                            <CalculateBeforePayoutItem calculation={calculateBeforePayoutLazy}
                                                                       isLoading={(
                                                                           isChangedAfterCalculation
                                                                           || getCalculateBeforePayoutLazyLoading
                                                                       )}/>
                                        </FormItem>

                                        <FormItem className="mb-1">
                                            <Button stretched
                                                    size="l"
                                                    type="submit"
                                                    appearance="positive"
                                                    before={<Icon24MoneyRequestOutline/>}
                                                    loading={createPayoutOperationLoading}
                                                    disabled={(
                                                        createPayoutOperationLoading
                                                        || getCalculateBeforePayoutLazyLoading
                                                        || !isValid
                                                    )}>
                                                <>{t('withdraw')}</>
                                                {' '}
                                                <AmountText semibold
                                                            amount={values.amount || 0}
                                                            currency={COMMON?.organization.settings.currency}/>
                                            </Button>
                                        </FormItem>
                                    </AutoSaveForm>
                                )}
                            </Formik>
                        </If>
                    )}
                </Graphql.Success>

                <Graphql.Error result={result}/>
            </>
        )} onCompleted={async ({ payoutRequisiteFindOneActive }) => {
            if (payoutRequisiteFindOneActive) {
                await getCalculateBeforePayoutLazy({
                    amount: 1,
                    requisiteId: payoutRequisiteFindOneActive.id,
                });

                formikRef.current?.validateForm();
            }
        }}/>
    );
});


export { CreatePayoutFormView };
