/**
 * PingPongSubscription component
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { useEmit, useSubscription } from '@/hooks/core';
import { ClientEmit, ClientEvent } from '@/shared/types';


const PingPongSubscription = () => {

    const [ pongEmit ] = useEmit(ClientEmit.userOnlinePong, true);

    useSubscription(ClientEvent.userOnlinePing, () => {
        pongEmit();
    });

    return <></>;
};


export { PingPongSubscription };
