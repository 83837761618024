/**
 * VerticalSeparator
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';


interface Props {
    height: number;
}


const VerticalSeparator = (props: Props) => {

    const { height } = props;

    return (
        <div className="thin-border-right" style={{ height }}/>
    );
};


export { VerticalSeparator };
