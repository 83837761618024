/**
 * ConnectTelegramView
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { observer, PlatformStore } from '@/store/platform';
import { useUserConnectTelegramMutation } from '@/codegen/graphql';

import { TelegramConnectButton } from '@/components/Atoms/TelegramConnectButton';


interface Props {
    onConnected?: () => void;
}


const ConnectTelegramView = observer((props: Props) => {

    const { onConnected } = props;

    const { COMMON } = PlatformStore;

    const [
        userConnectTelegramMutation,
        { loading },
    ] = useUserConnectTelegramMutation();

    const handleAuth = async (
        user: Parameters<typeof window['onTgAuth']>[0],
    ) => {
        if (!user) {
            return;
        }

        try {
            await userConnectTelegramMutation({
                variables: { callbackConnectInput: user },
                onError: (error) => {
                    console.error(error);
                },
                onCompleted() {
                    onConnected?.();
                },
            });
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <TelegramConnectButton onAuth={handleAuth}
                               isLoading={loading}
                               botName={`${COMMON?.user.auth.telegramLoginBotName}`}/>
    );
});


export { ConnectTelegramView };
