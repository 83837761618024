/**
 * FileUploadVideoPart component
 *
 * @author: exode <hello@exode.ru>
 */

import React, { useEffect, useState } from 'react';

import { Url } from '@/utils';
import { Field, If } from '@/cutils';
import { useI18n } from '@/hooks/core';

import { FormItem, IconButton, Input } from '@exode.ru/vkui';
import { Icon16Clear, Icon24Linked } from '@vkontakte/icons';

import { FileUploadProps } from '@/components/Atoms/FileUpload';
import { ConfirmButton } from '@/components/Atoms/ConfirmButton';

import { FileUploadVideoMode } from '../interfaces';


interface Props extends FileUploadProps {
    videoLinkInput: string;
    uploadProgress: number;
    clearAndCancelUploading: () => void;
    setVideoLinkInput: (link: string) => void;
}


const FileUploadVideoPart = (props: Props) => {

    const {
        previewVideo,
        videoLinkInput,
        clearAndCancelUploading,
        setVideoLinkInput,
        uploadProgress,
        onFileUpload,
        videoModes = [
            FileUploadVideoMode.Local,
            FileUploadVideoMode.Youtube,
            FileUploadVideoMode.Vimeo,
        ],
    } = props;

    const { t } = useI18n('components.Atoms.FileUpload.parts');

    const [ linkError, setLinkError ] = useState('');

    /** Inner state for validating (call prop setLinkInput only if valid) */
    const [ _videoLinkInput, _setVideoLinkInput ] = useState(videoLinkInput);

    const clearVideoCallback = () => {
        linkError && setLinkError('');
        clearAndCancelUploading();
        _setVideoLinkInput('');
    };

    useEffect(() => {
        _setVideoLinkInput(videoLinkInput);
    }, [ videoLinkInput ]);

    return (
        <>
            <If is={!!previewVideo && !Url.videoUrlIsThirdParty(previewVideo)}>
                <div className="z-[1] absolute right-0 m-2 rounded-full bg-content-15 top-0">
                    <ConfirmButton iconFill="var(--white)"
                                   callback={clearVideoCallback}
                                   text={t('reallyWantToDeleteVideo')}/>
                </div>
            </If>

            <If is={(!uploadProgress
                && !!videoModes?.includes(FileUploadVideoMode.Youtube)
                && (!!_videoLinkInput || !previewVideo)
            )}>
                <FormItem bottom={linkError && (
                    <div className="bg-content-75 thin-border py-0.5 px-2 vk-rounded">
                        {linkError}
                    </div>
                )} status={linkError ? 'error' : 'default'} className="absolute w-[calc(100%_-_1em)] p-2 top-0 z-[1]">
                    <Input value={_videoLinkInput}
                           data-test="file-upload.video"
                           placeholder={t('orYoutubeLink')}
                           className="flex flex-1 bg-opacity-50"
                           before={<Icon24Linked/>}
                           after={(
                               <>
                                   <If is={!linkError && !!videoLinkInput}>
                                       <ConfirmButton callback={clearVideoCallback}
                                                      text={t('doYouReallyWantToDeleteVideo')}
                                                      confirmProps={{ className: 'bg-destructive' }}
                                                      confirmRegularButton={(
                                                          <IconButton>
                                                              <Icon16Clear fill="var(--white)"/>
                                                          </IconButton>
                                                      )}>
                                           <IconButton>
                                               <Icon16Clear/>
                                           </IconButton>
                                       </ConfirmButton>
                                   </If>

                                   <If is={!!linkError || !videoLinkInput}>
                                       <IconButton onClick={clearVideoCallback}>
                                           <Icon16Clear/>
                                       </IconButton>
                                   </If>
                               </>
                           )}
                           onChange={Field.transform.ignoreSpaceOnChange((event: React.ChangeEvent<HTMLInputElement>) => {
                               const location = event.target.value;

                               if (uploadProgress) {
                                   event.preventDefault();
                                   event.stopPropagation();
                               }

                               setLinkError('');
                               _setVideoLinkInput(location);

                               if (!location || Url.parseVideoUrl(location).id) {
                                   setVideoLinkInput(location);
                                   onFileUpload?.([ { location, id: 0, meta: { size: 0, duration: 0 } } ]);
                               } else {
                                   setLinkError(t('putProperYouTubeLink'));
                               }
                           })}/>
                </FormItem>
            </If>
        </>
    );
};


export { FileUploadVideoPart };
