/**
 * NotistackProvider component
 *
 * @author: exode <hello@exode.ru>
 */

import React, { ReactElement } from 'react';

import { SnackbarProvider } from 'notistack';


import { ChatSnackbar, DefaultSnackbar, NotificationSnackbar } from '@/components/Atoms/Notistack';


declare module 'notistack' {
    interface VariantOverrides {
        chat: true;
        notification: true;
    }
}


interface Props {
    children: ReactElement | ReactElement[];
}


const NotistackProvider = (props: Props) => {
    return (
        <SnackbarProvider maxSnack={3} Components={{
            chat: ChatSnackbar,
            default: DefaultSnackbar,
            notification: NotificationSnackbar,
        }}>
            {props.children}
        </SnackbarProvider>
    );
};


export { NotistackProvider };
