/**
 * Socket hooks
 *
 * @author: exode <hello@exode.ru>
 */

import { useContext, useEffect, useState } from 'react';

import { ObjectUtil } from '@/utils';
import { socket } from '@/api/socket';

import { ISocketContext, SocketContext } from '@/components/App/SocketProvider';

import {
    EventNames,
    EventParams,
    EventsMap,
    ReservedOrUserEventNames,
    ReservedOrUserListener,
} from '@socket.io/component-emitter';


type Args = EventParams<EventsMap, EventNames<EventsMap>>
type EventHandler = ReservedOrUserListener<EventsMap, EventsMap, ReservedOrUserEventNames<EventsMap, EventsMap>>

type UseEmitReturn<Input, Output> = [
    (data?: Input, ...args: any) => void,
    [ data: Output, setData: Function ]
];


/**
 * Socket subscription hook
 * @param {string} eventName
 * @param {EventHandler} eventHandler
 * @returns {}
 * @constructor
 */
export function useSubscription(eventName: string, eventHandler?: EventHandler) {
    const [ data, setData ] = useState();
    const { socket } = useContext<ISocketContext>(SocketContext);

    useEffect(() => {
        socket?.on(eventName, (data) => {
            setData(data);
            eventHandler && eventHandler(data);
        });

        return () => {
            socket?.off(eventName, eventHandler);
        };
    }, [ eventHandler ]);

    return [ data, setData ];
}

/**
 * Socket emit hook
 * @param {string} eventName
 * @param sync
 * @param state
 * @returns {UseEmitReturn<Input, Output>}
 */
export function useEmit<Output = any, Input = any>(eventName: string, sync = false, state?: any) {
    const [ data, setData ] = useState(state);

    const { socket } = useContext<ISocketContext>(SocketContext);

    const emitter = (...args: Args) => {
        const emit = () => socket?.emit(eventName, ...args);

        const addOnConnect = () => socket?.once('connect', emit);

        if (!socket?.connected) {
            return addOnConnect();
        }

        sync && socket.once('disconnect', addOnConnect);

        emit();
    };

    const setDataCb = (payload: any) => {
        if (!ObjectUtil.isEqual(data, payload)) {
            setData(payload);
        }
    };

    return [ emitter, [ data, setDataCb ] ] as unknown as UseEmitReturn<Input, Output>;
}

/**
 * Emit store wrapper
 * @param subscribedList
 * @param key
 * @param {() => void} callback
 */
export const emitStoreWrapper = (
    subscribedList: Set<any>,
    key: any,
    callback: () => void,
) => {
    if (!socket.connected) {
        socket.once('connect', () => emitStoreWrapper(subscribedList, key, callback));
    }

    if (subscribedList.has(key)) {
        return;
    }

    callback();
};
