/**
 * ProfileTab
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';
import moment from 'moment';

import React from 'react';

import { Formik } from 'formik';

import { Page } from '@/pages/Core';
import { Graphql } from '@/cutils';
import { useI18n } from '@/hooks/core';
import { languageNames } from '@/shared/types';

import { SchoolUserFindOneQuery } from '@/codegen/graphql';

import { Icon24MailOutline, Icon24PhoneOutline } from '@vkontakte/icons';
import { FormItem, FormLayoutGroup, InfoRow, Input } from '@exode.ru/vkui';

import { InfoRowWrapper } from '@/components/Atoms/InfoRowWrapper';


interface Props {
    userId: number;
}


const ProfileTab = (props: Props) => {

    const { userId } = props;

    const { t } = useI18n('pages.Manage.School.Users.views.EditUserFormView');

    return (
        <SchoolUserFindOneQuery children={(result) => (
            <>
                <Graphql.Loading result={result}>
                    <Page.Spinner/>
                </Graphql.Loading>

                <Graphql.Success result={result}>
                    {({ schoolUserFindOne: user }) => (
                        <Formik initialValues={user.profile} onSubmit={() => {}}>
                            {({ values }) => (
                                <>
                                    <FormLayoutGroup className="px-0 pt-0" mode="horizontal">
                                        <FormItem top={t('firstName')}>
                                            <Input readOnly
                                                   name="firstName"
                                                   placeholder={t('firstName')}
                                                   value={values.firstName || t('withoutName')}/>
                                        </FormItem>

                                        <FormItem top={t('lastName')}>
                                            <Input readOnly
                                                   name="lastName"
                                                   placeholder={t('lastName')}
                                                   value={values.lastName || t('withoutLastName')}/>
                                        </FormItem>
                                    </FormLayoutGroup>

                                    <FormLayoutGroup className="px-0 pt-0" mode="horizontal">
                                        <FormItem top={t('phoneNumber')}>
                                            <Input readOnly
                                                   name="phone"
                                                   value={user.phone || '—'}
                                                   before={<Icon24PhoneOutline/>}/>
                                        </FormItem>

                                        <FormItem top={t('email')}>
                                            <Input readOnly
                                                   name="email"
                                                   value={user.email || '—'}
                                                   before={<Icon24MailOutline/>}/>
                                        </FormItem>
                                    </FormLayoutGroup>

                                    <InfoRowWrapper className="mt-4" cells={[
                                        <InfoRow header={t('birthDate')}>
                                            <>{user.profile?.bdate || '—'}</>
                                        </InfoRow>,

                                        <InfoRow header={t('userLanguage')}>
                                            <>{user.language ? languageNames[user.language] : '—'}</>
                                        </InfoRow>,
                                    ]}/>

                                    <InfoRowWrapper className="mt-4" cells={[
                                        <InfoRow header={t('timezone')}>
                                            <>
                                                {_.isNil(user.timezone)
                                                    ? '—'
                                                    : [
                                                        'GMT ',
                                                        user.timezone > 0 ? '+' : '',
                                                        user.timezone,
                                                    ].join('')
                                                }
                                            </>
                                        </InfoRow>,

                                        <InfoRow header={t('dateOfSignUp')}>
                                            <>{moment(user.createdAt).format('D MMM YYYY HH:MM')}</>
                                        </InfoRow>,
                                    ]}/>

                                    <InfoRowWrapper className="mt-4" cells={[
                                        <InfoRow header={t('personalDomain')}>
                                            <>{user.domain}</>
                                        </InfoRow>,

                                        <InfoRow header={t('tgId')}>
                                            <>{user.tgId || '—'}</>
                                        </InfoRow>,
                                    ]}/>

                                    <InfoRowWrapper className="mt-4" cells={[
                                        <InfoRow header={t('createdBy')}>
                                            <>{user.createdBy?.profile?.fullName || t('bySelf')}</>
                                        </InfoRow>,
                                    ]}/>
                                </>
                            )}
                        </Formik>
                    )}
                </Graphql.Success>
            </>
        )} variables={{ userId }}/>
    );
};


export { ProfileTab };
