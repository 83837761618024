/**
 * Rbac util
 *
 * @author: exode <hello@exode.ru>
 */

import React, { ReactElement } from 'react';

import { observer, UserAuthStore } from '@/store/user/auth';

import { Auth, Unauth } from '@/cutils';
import { Permissions } from '@/types/rbac';

import { ComponentHelper } from '@/helpers/react';
import { UserRbacService } from '@/services/User/Rbac';


interface WrapperProps {
    permissions: Permissions;
    children: ReactElement | ReactElement[];
}

interface PermissionProps {
    children: ReactElement | ReactElement[];
    permissions?: Permissions;
    guest?: boolean;
    user?: boolean;
    invert?: boolean;
}


const RbacWrapper = observer((props: WrapperProps) => {

    const allowed = (() => {
        /** Request permissions */
        const requested = props.permissions;

        /** Granted combined permissions (user + seller) */
        const granted = UserAuthStore.combinedPermissions;

        return !requested.length || UserRbacService.canAny(granted, ...requested);
    })();

    const childrenList = React.Children.toArray(props.children);

    const namedChildren = ComponentHelper.getNamedChildren(props.children);

    const { RbacAllowed, RbacForbidden } = namedChildren;

    if (!RbacForbidden && childrenList.length === 1) {
        return allowed ? <>{props.children}</> : <></>;
    }

    return allowed ? RbacAllowed : RbacForbidden;
});


const RbacPermission = observer((props: PermissionProps) => {

    const { permissions = [], guest, user, invert } = props;

    /** Granted combined permissions (user + seller) */
    const granted = UserAuthStore.combinedPermissions;

    if (guest) {
        props.children = <Unauth>{props.children}</Unauth>;
    } else if (user) {
        props.children = <Auth>{props.children}</Auth>;
    }

    const allowed = UserRbacService.canAny(granted, ...permissions);

    return allowed && !invert ? <>{props.children}</> : <></>;
});


/** Базовая компонента переключения по разрешению доступа */
class RbacRender extends React.Component {

    render() {
        return this.props.children || <></>;
    }

}


/** Компонента переключения при наличии доступа */
class RbacAllowed extends RbacRender {

    static cname = 'RbacAllowed';

}


/** Компонента переключения при отсутствии доступа */
class RbacForbidden extends RbacRender {

    static cname = 'RbacForbidden';

}


const Rbac = {
    Wrapper: RbacWrapper,
    Allowed: RbacAllowed,
    Forbidden: RbacForbidden,
    Permission: RbacPermission,
};


export { Rbac };
