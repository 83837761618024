/**
 * MySubjectsBanner
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { Link } from '@/cutils';

import { useI18n } from '@/hooks/core';

import { Button } from '@exode.ru/vkui';

import { InnerHtml } from '@/components/Atoms/InnerHtml';
import { GradientBanner } from '@/components/Atoms/GradientBanner';


const MySubjectsBanner = () => {

    const { t } = useI18n('components.Promotion.ContextBanners');

    return (
        <GradientBanner imageTheme="light"
                        gradient="bg-content"
                        header={t('mySubjects')}
                        subheader={<InnerHtml content={t('getRecommendationsOnlyBySubjectSelection')}/>}
                        actions={(
                            <Link pushPage={{ id: '/my-subjects' }}>
                                <Button mode="primary" size="m">{t('change')}</Button>
                            </Link>
                        )}/>
    );
};


export { MySubjectsBanner };
