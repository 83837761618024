/**
 * ChatTabHeaderPart
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { If, Link } from '@/cutils';
import { Page } from '@/pages/Core';
import { useI18n } from '@/hooks/core';

import { useLocation } from '@/router/index';

import { Icon28ArrowLeftOutline } from '@vkontakte/icons';
import { PanelHeader, PanelHeaderButton, PanelHeaderContent } from '@exode.ru/vkui';


const ChatTabHeaderPart = () => {

    const { t } = useI18n('components.Panel.Header.Tab.parts');

    const { route: { params } } = useLocation();

    return (
        <PanelHeader separator={false} className="tab-title" before={(
            <If is={!!params.chatId}>
                <Link pushPage={{ id: '/chat' }}>
                    <PanelHeaderButton>
                        <Icon28ArrowLeftOutline/>
                    </PanelHeaderButton>
                </Link>
            </If>
        )}>
            <PanelHeaderContent>
                <Page.HeaderContentWrapper>
                    <>{t('messenger')}</>
                </Page.HeaderContentWrapper>
            </PanelHeaderContent>
        </PanelHeader>
    );
};


export { ChatTabHeaderPart };
