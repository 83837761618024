/**
 * CancelUploadPart component
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { useI18n } from '@/hooks/core';

import { Icon24CancelCircleFillRed } from '@vkontakte/icons';

import { FileUploadProps } from '@/components/Atoms/FileUpload';
import { ConfirmButton } from '@/components/Atoms/ConfirmButton';


interface Props {
    cancelUploadIconSize: number;
    clearAndCancelUploading: () => void;
    uploadLoadingIconSize: FileUploadProps['uploadLoadingIconSize'];
}


const CancelUploadPart = (props: Props) => {

    const {
        cancelUploadIconSize,
        uploadLoadingIconSize,
        clearAndCancelUploading,
    } = props;

    const { t } = useI18n('components.Atoms.FileUpload.parts');

    return (
        <div className="z-[100] absolute right-0 m-2 rounded-full bg-content-50--blur thin-border top-0">
            <ConfirmButton text={t('cancelLoad?')}
                           iconFill="var(--dynamic_red)"
                           callback={clearAndCancelUploading}
                           confirmProps={{
                               size: uploadLoadingIconSize,
                               width: cancelUploadIconSize,
                               height: cancelUploadIconSize,
                           }}
                           confirmIcon={(
                               <Icon24CancelCircleFillRed width={cancelUploadIconSize} height={cancelUploadIconSize}/>
                           )}/>
        </div>
    );
};


export { CancelUploadPart };
