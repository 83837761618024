/**
 * PasswordInput component
 *
 * @author: exode <hello@exode.ru>
 */

import React, { useState } from 'react';

import { Input, InputProps } from '@exode.ru/vkui';
import { Icon24HideOutline, Icon24ViewOutline } from '@vkontakte/icons';


interface Props extends InputProps {
    showChars?: boolean;
}


const PasswordInput = (props: Props) => {

    const { showChars = false, ...rest } = props;

    const [ isShown, setShown ] = useState(showChars);

    const handleShow = () => setShown(!isShown);

    const type = isShown ? 'text' : 'password';

    const after = isShown
        ? <Icon24HideOutline className="pr-2 cursor-pointer" onClick={handleShow} fill="var(--accent)"/>
        : <Icon24ViewOutline className="pr-2 cursor-pointer" onClick={handleShow} fill="var(--accent)"/>;

    return (
        <Input type={type} after={after} autoComplete="off" {...rest} />
    );
};


export { PasswordInput };
