/**
 * UtilsSelectMimicryPage store
 *
 * @author: exode <hello@exode.ru>
 */

import { ReactElement } from 'react';

import { action, makeObservable, observable } from 'mobx';

import { observer, PageStore } from '@/pages/Core';

import { RoutePathType } from '@/router/paths';

import { ModalCardProps } from '@/components/Atoms/ModalCard';


export interface SelectMimicryOptions {
    name: string;
    value: string;
}


class UtilSelectMimicryPage extends PageStore {

    constructor() {
        super();
        makeObservable(this);
    }

    @observable
    title: string = 'Выбор';

    @observable
    from: RoutePathType = '/';

    @observable
    selected: string | string[] | number[];

    @observable
    options: SelectMimicryOptions[] = [];

    @observable
    component: ReactElement | null;

    @observable
    rawModalCardProps?: ModalCardProps;

    @action
    merge(partial: Partial<this>) {
        Object.assign(this, partial);
    }

    @action
    reset() {
        this.title = '';
        this.from = '/';
        this.selected = '';
        this.options = [];
        this.component = null;
    }
}

const UtilSelectMimicryPageStore = new UtilSelectMimicryPage();


export { observer, UtilSelectMimicryPageStore };
