/**
 * UseSetFriendship
 *
 * @author: exode <hello@exode.ru>
 */

import {
    FriendshipStatus,
    ListFriendsByStatusDocument,
    ListFriendsByStatusQuery,
    PossibleFriendsListDocument,
    PossibleFriendsListQuery,
    useSetFriendshipStatusMutation,
} from '@/codegen/graphql';

import { friendsByStatusListConfig } from '@/pages/Profile/Friends/views/FriendsContentView';
import { possibleFriendsListConfig } from '@/pages/Profile/Friends/views/FriendsContextView';


export const useSetFriendship = () => {

    const [ setFriendshipMutation, { loading: setFriendshipLoading } ] = useSetFriendshipStatusMutation();

    const setFriendship = async (userId: number, status: FriendshipStatus) => {
        await setFriendshipMutation({
            variables: { relUserId: userId, status },
            update: (cache, { data }) => {
                const getVariables = (status: FriendshipStatus) => {
                    return {
                        ...friendsByStatusListConfig,
                        filter: { status },
                    };
                };

                const { friendshipFindMany: cachedRequested } = cache.readQuery<ListFriendsByStatusQuery>({
                    query: ListFriendsByStatusDocument,
                    variables: getVariables(FriendshipStatus.Requested),
                }) || {};

                const { friendshipFindMany: cachedConfirmed } = cache.readQuery<ListFriendsByStatusQuery>({
                    query: ListFriendsByStatusDocument,
                    variables: getVariables(FriendshipStatus.Confirmed),
                }) || {};

                const { friendshipFindManyPossible: cachedPossible } = cache.readQuery<PossibleFriendsListQuery>({
                    query: PossibleFriendsListDocument,
                    variables: possibleFriendsListConfig,
                }) || {};

                if ([
                    FriendshipStatus.Rejected,
                    FriendshipStatus.Canceled,
                    FriendshipStatus.Confirmed,
                ].includes(status)) {
                    cachedRequested && cache.writeQuery<ListFriendsByStatusQuery>({
                        query: ListFriendsByStatusDocument,
                        variables: getVariables(FriendshipStatus.Requested),
                        data: {
                            friendshipFindMany: {
                                count: (cachedRequested.count ?? 1) - 1,
                                items: cachedRequested.items?.filter((item) => item.id !== userId),
                            },
                        },
                    });
                }

                if ([ FriendshipStatus.Requested, FriendshipStatus.Canceled ].includes(status)) {
                    cachedPossible && cache.writeQuery<PossibleFriendsListQuery>({
                        query: PossibleFriendsListDocument,
                        variables: possibleFriendsListConfig,
                        data: {
                            friendshipFindManyPossible: {
                                count: cachedPossible.count ?? 0,
                                items: cachedPossible.items?.map((item) => (
                                    item.id === userId
                                        ? { ...item, friendshipStatus: data?.friendshipSetFriendshipStatus.status }
                                        : item
                                )),
                            },
                        },
                    });
                }

                if (status === FriendshipStatus.Confirmed) {
                    const newFriendUser = cachedRequested?.items?.find(item => item.id === userId);

                    if (newFriendUser) {
                        cachedConfirmed && cache.writeQuery<ListFriendsByStatusQuery>({
                            query: ListFriendsByStatusDocument,
                            variables: getVariables(FriendshipStatus.Confirmed),
                            data: {
                                friendshipFindMany: {
                                    count: (cachedRequested?.count ?? 0) + 1,
                                    items: [ newFriendUser, ...(cachedConfirmed.items ?? []) ],
                                },
                            },
                        });
                    }
                }

                cache.modify({
                    fields: {
                        friendshipGetFriendshipStatus: (value) => ({
                            ...value,
                            status: data?.friendshipSetFriendshipStatus.status,
                        }),
                    },
                });
            },
            onError(error) {
                console.error(error);
            },
        });
    };

    return { setFriendship, setFriendshipLoading };
};
