/**
 * Helper
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import { IS_BIZ_WELCOME } from '@/root/src/env';

import { UserAuthStore } from '@/store/user/auth';

import { useSellerContext } from '@/components/App/SellerProvider';


/**
 * Should manage navigation be shown by permissions
 */
export const shouldManageNavigationBeShown = () => {
    const { seller } = useSellerContext();

    if (IS_BIZ_WELCOME) {
        return false;
    }

    return UserAuthStore.isLoggedIn
        && !_.isEmpty(UserAuthStore.combinedPermissions)
        || !!seller;
};
