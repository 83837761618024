/**
 * Filter useProfileSearchFilter
 *
 * @author: exode <hello@exode.ru>
 */

import { useUrlFilters } from '@/hooks/core';


export const useProfileSearchFilter = () => useUrlFilters(
    {
        profileSearch: '',
    },
    [],
    (filter) => filter,
    undefined,
    { routerMethod: 'replacePage' },
);
