/**
 * NativeShareButton component
 *
 * @author: exode <hello@exode.ru>
 */

import React, { ReactElement } from 'react';

import { NativeShareOpen, useNative } from '@/hooks/core';


interface Props {
    children: ReactElement;
    options: NativeShareOpen;
}


const NativeShareButton = (props: Props) => {

    const { shareOpen } = useNative();

    return React.cloneElement(props.children, {
        onClick: () => {
            shareOpen(props.options);
        },
    });
};


export { NativeShareButton };
