/**
 * UserRowItem
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { If, Link } from '@/cutils';
import { GqlResult } from '@/types/graphql';
import { SchoolUserFindManyQueryResult } from '@/codegen/graphql';

import { User } from '@/components/Atoms/User';
import { Icon24MessageOutline } from '@vkontakte/icons';
import { Button, ButtonGroup, Cell, IconButton } from '@exode.ru/vkui';

import { UserAvatar } from '@/components/Atoms/UserAvatar';
import { LastOnlineText } from '@/components/Profile/Page';

import { UserEditTab } from '@/pages/Manage/School/Users';


interface Props {
    user: NonNullable<GqlResult<SchoolUserFindManyQueryResult>['schoolUserFindMany']['items']>[number];
}


const UserRowItem = (props: Props) => {

    const { user } = props;

    return (
        <Link pushModal={{
            id: 'manage-user-edit',
            params: { userId: `${user.id}` },
        }}>
            <Cell className="vk-rounded"
                  subtitle={<LastOnlineText userId={user.id} lastOnlineAt={user.lastOnlineAt}/>}
                  before={(
                      <UserAvatar size={48}
                                  userId={user.id}
                                  className="cursor-pointer mr-4"
                                  name={user.profile?.fullName || ''}
                                  src={user.profile?.avatar.medium || ''}>
                      </UserAvatar>
                  )}
                  after={(
                      <div className="flex items-center gap-1">
                          <ButtonGroup gap="none">
                              <If is={!!(user.email || user.phone)}>
                                  <Link onClick={(e) => e.stopPropagation()} pushModal={{
                                      id: 'manage-user-edit',
                                      params: {
                                          userId: `${user.id}`,
                                          tab: UserEditTab.Profile,
                                      },
                                  }}>
                                      <Button size="s"
                                              mode="secondary"
                                              appearance="overlay"
                                              data-test="user-row.phone"
                                              className="text-secondary">
                                          <span className="text-secondary">
                                              {user.phone || user.email}
                                          </span>
                                      </Button>
                                  </Link>
                              </If>
                          </ButtonGroup>

                          <Link onClick={(e) => e.stopPropagation()} pushPage={{
                              id: '/chat',
                              params: { personalUserId: `${user.id}` },
                          }}>
                              <IconButton className="mr-2">
                                  <Icon24MessageOutline/>
                              </IconButton>
                          </Link>
                      </div>
                  )}>
                <User.Name profile={user.profile}/>
            </Cell>
        </Link>
    );
};


export { UserRowItem };
