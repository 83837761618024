/**
 * BlitzCard styled container
 *
 * @author: exode <hello@exode.ru>
 */

import styled from 'styled-components';

import { tournamentCardRounded } from '@/components/Tournament/Card/TournamentCard.styled';


export const Container = styled.div`
  z-index: 1;

  .Card {
    border-radius: ${tournamentCardRounded};
  }
`;

export const CardShadow = styled.div<any>`
  height: 20px;
  width: calc(100% - 20px);
  border-top-left-radius: ${tournamentCardRounded};
  border-top-right-radius: ${tournamentCardRounded};
  margin: auto auto -15px;
  z-index: 0;
`;