/**
 * Constants
 *
 * @author: exode <hello@exode.ru>
 */

import {
    ChatFindManyDocument,
    ChatFindOneTopBarDocument,
    ChatGetUnreadCountDocument,
    ChatMessageFindManyDocument,
} from '@/codegen/graphql';


export const chatRefetchDocsOnConnect = [
    ChatFindManyDocument,
    ChatFindOneTopBarDocument,
    ChatMessageFindManyDocument,
    ChatGetUnreadCountDocument,
];
