/**
 * Administrate user
 *
 * @author: exode <hello@exode.ru>
 */

import { useI18n } from '@/hooks/core';

import { Permission } from '@/codegen/graphql';
import { UserPermissions } from '@/shared/types';


interface PermissionText {
    title: string;
    description: string;
}

interface UserPermissionsManage {
    verified: PermissionText;
    banned: PermissionText;
    permissions: Array<PermissionText & { value: UserPermissions }>;
}


/**
 * Управление пользователем и его правами доступа
 */
export const userManage: () => UserPermissionsManage = () => {

    const { t } = useI18n('types.manage.permissions.user');

    return {
        verified: {
            title: t('userIsConfirmed'),
            description: t('checkmarkInAllProfileDisplays'),
        },
        banned: {
            title: t('userIsBlocked'),
            description: t('logoutFromAllSessions'),
        },
        permissions: [
            {
                value: Permission.CourseManage,
                title: t('courseManagement'),
                description: t('userCanManageCourses'),
            },
            {
                value: Permission.CourseView,
                title: t('viewingCourses'),
                description: t('userCanSeeAllCoursesList'),
            },
            {
                value: Permission.CourseCurator,
                title: t('courseSupervisor'),
                description: t('userCanCheckHomework'),
            },
            {
                value: Permission.CourseReportingView,
                title: t('viewingCourseReports'),
                description: t('userCanViewAllCourseReports'),
            },
            {
                value: Permission.CourseCommentsManage,
                title: t('managingCourseComments'),
                description: t('userCanManageComments'),
            },
            {
                value: Permission.CourseStudentManage,
                title: t('courseStudentManagement'),
                description: t('userCanViewStudentInformation'),
            },
            {
                value: Permission.ProductManage,
                title: t('productManagement'),
                description: t('userCanCreateAndManageLaunches'),
            },
            {
                value: Permission.SchoolManageSettings,
                title: t('managingSchoolSettings'),
                description: t('userCanChangeSchoolSettings'),
            },
            {
                value: Permission.SchoolCustomizeSite,
                title: t('schoolDesignManagement'),
                description: t('userCanEditSchoolDesign'),
            },
            {
                value: Permission.SellerManage,
                title: t('sellerManagement'),
                description: t('userCanViewAndUpdateSellerInfo'),
            },
            {
                value: Permission.SellerSales,
                title: t('sellerSales'),
                description: t('userCanViewPaymentHistoryAndReports'),
            },
            {
                value: Permission.SellerFinances,
                title: t('sellerFinances'),
                description: t('userCanViewBalanceAndPaymentsSchool'),
            },
            {
                value: Permission.SellerRefunds,
                title: t('sellerReturns'),
                description: t('userCanManageReturns'),
            },
            {
                value: Permission.SellerManageManagers,
                title: t('sellerEmployeeManagement'),
                description: t('userCanManageOtherEmployees'),
            },
            {
                value: Permission.AdminSellerVerify,
                title: t('partnerVerification'),
                description: t('userCanVerifyPartnerRequests'),
            },
            {
                value: Permission.AdminCoursePublish,
                title: t('coursePublicationByAdmin'),
                description: t('userCanPublishRequestedLaunches'),
            },
            {
                value: Permission.AdminCompanyTeamMember,
                title: t('companyTeamMember'),
                description: t('userCanHaveThisRoleAndBeProud'),
            },
            {
                value: Permission.AdminCompanyItDeveloper,
                title: t('developerInITCompany'),
                description: t('userCanSimplyHaveThisRoleAndBeProud'),
            },
            {
                value: Permission.TaskView,
                title: t('viewTaskDatabase'),
                description: t('userCanViewTasksFromDatabase'),
            },
            {
                value: Permission.TaskManage,
                title: t('taskDatabaseManagement'),
                description: t('userCanCreateAndUpdateTasks'),
            },
            {
                value: Permission.TournamentCreateOfficial,
                title: t('officialTournamentCreation'),
                description: t('userCanCreateOfficialTournaments'),
            },
            {
                value: Permission.UserView,
                title: t('viewUserData'),
                description: t('userCanViewAllUserData'),
            },
            {
                value: Permission.UserHavePartnerAccount,
                title: t('partnerAccount'),
                description: t('userCanHavePartnerAccount'),
            },
            {
                value: Permission.UserIsAdmin,
                title: t('admin'),
                description: t('userCanHaveSuperAdminAccount'),
            },
            {
                value: Permission.UserIsBot,
                title: t('bot'),
                description: t('userCanHaveBotAccount'),
            },
            {
                value: Permission.UserIsAdministrationAccount,
                title: t('adminAccount'),
                description: t('userCanHaveAdministrativeAccount'),
            },
        ],
    };
};
