/**
 * DiscountRowItem
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { observer, PlatformStore } from '@/store/platform';

import { If } from '@/cutils';
import { useCopy, useI18n } from '@/hooks/core';

import { DiscountType } from '@/codegen/graphql';
import { DiscountFindMany, productCurrency } from '@/types/product';

import { Avatar, RichCell } from '@exode.ru/vkui';
import { TextTooltip } from '@exode.ru/vkui/unstable';
import { Icon16CopyOutline, Icon16New, Icon28DiscountOutline, Icon28MoneyWadOutline } from '@vkontakte/icons';

import { amountText } from '@/components/Atoms/AmountText';
import { TagInlineText } from '@/components/Atoms/TagInlineText';
import { CheckListTooltip } from '@/components/Atoms/CheckListTooltip';
import { DateTimeTextInterval } from '@/components/Atoms/DateTimeTextInterval';


interface Props {
    discount: NonNullable<DiscountFindMany['items']>[number];
}


const DiscountRowItem = observer((props: Props) => {

    const {
        discount: {
            type,
            code,
            value,
            active,
            isActive,
            maxApplies,
            isScheduled,
            countApplies,
            activeFrom,
            activeTo,
            isActiveByDate,
            isActiveByCountApplies,
        },
    } = props;

    const { COMMON } = PlatformStore;

    const { t } = useI18n('pages.Manage.Product.Discount');

    const { copy } = useCopy(t('promoCodeCopied'));

    const handleCopy = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
        e.stopPropagation();

        return copy(code);
    };

    return (
        <TextTooltip placement="right" offsetSkidding={-10} offsetDistance={-14} text={(
            <CheckListTooltip header={isActive ? t('promoCodeIsActive') : t('promoCodeIsNotActive')} list={[
                { text: t('flagActivity'), mode: active ? 'success' : 'error' },
                { text: t('usageLimit'), mode: isActiveByCountApplies ? 'success' : 'error' },
                {
                    text: t('promoCodeValidityDates'),
                    mode: isActiveByDate ? 'success' : (isScheduled ? 'warning' : 'error'),
                },
            ]}/>
        )}>
            <div>
                <RichCell className="flex-1" afterCaption={(
                    <DateTimeTextInterval from={activeFrom} to={activeTo} bothIsNullText={t('datesAreNotLimited')}/>
                )} before={(
                    <Avatar shadow={false} className="bg-transparent">
                        <If is={type === DiscountType.Percent}>
                            <Icon28DiscountOutline fill={(
                                isActive
                                    ? 'var(--dynamic_green)'
                                    : 'var(--icon_secondary)'
                            )}/>
                        </If>

                        <If is={type === DiscountType.Amount}>
                            <Icon28MoneyWadOutline fill={(
                                isActive
                                    ? 'var(--dynamic_green)'
                                    : 'var(--icon_secondary)'
                            )}/>
                        </If>
                    </Avatar>
                )} caption={(
                    <div className="flex gap-2">
                        <TagInlineText size="m"
                                       gradient={isActive ? 'green' : 'secondary'}
                                       content={`${t('usage')} ${countApplies} / ${maxApplies || '∞'}`}/>
                    </div>
                )} after={(
                    <div className="flex items-center justify-between gap-2">
                        {(type === DiscountType.Amount
                                ? `${amountText(value)} ${COMMON && productCurrency[COMMON.organization.settings.currency]}`
                                : `${value}%`
                        )}

                        <Icon16New width={24} height={24} fill={(
                            isActive
                                ? 'var(--dynamic_green)'
                                : isScheduled
                                    ? 'var(--dynamic_orange)'
                                    : 'var(--tabbar_inactive_icon)'
                        )}/>
                    </div>
                )}>
                    <div className="flex items-center gap-1 mb-1 w-fit" onClick={handleCopy}>
                        <span>{code}</span>

                        <div>
                            <Icon16CopyOutline/>
                        </div>
                    </div>
                </RichCell>
            </div>
        </TextTooltip>
    );
});


export { DiscountRowItem };
