/**
 * ChatSubscription component
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import React from 'react';

import { useI18n } from '@/hooks/core';
import { useStore } from '@/pages/Core';
import { ConfigStore, observer } from '@/store/core/config';
import { ChatDialogsPageStore, dtoFilterChatListTransformer } from '@/pages/Chat/Dialog/store';

import {
    ChatFindManyDocument,
    ChatFindManyQuery,
    ChatFragmentFragment,
    ChatListEventAction,
    useChatListenNewListEventSubscription,
} from '@/codegen/graphql';

import { Notify } from '@/cutils';

import { useLocation } from '@/router/index';
import { PageService } from '@/services/Utils/Page';


const ChatSubscription = observer(() => {

    const { t } = useI18n('components.Subscriptions.Chat');

    const { route: { pageId } } = useLocation();

    const { list, sort } = useStore(ChatDialogsPageStore);

    useChatListenNewListEventSubscription({
        onData: ({ data: { data }, client: { cache } }) => {
            const { chat, action } = data?.chatListenNewListEvent || {};

            const writeToQueryCache = (
                chat: ChatFragmentFragment,
                filter: ReturnType<typeof dtoFilterChatListTransformer>,
            ) => {
                const variables = {
                    filter,
                    list: { ...list.chats },
                    sort: { ...sort.chats },
                };

                const cachedChats = cache.readQuery<ChatFindManyQuery>({
                    variables,
                    query: ChatFindManyDocument,
                });

                if (cachedChats?.chatFindMany) {
                    const { cursor, count } = cachedChats?.chatFindMany;

                    const items = _.orderBy(
                        [
                            chat,
                            ...(cachedChats?.chatFindMany?.items?.filter(({ id }) => id !== chat.id) ?? []),
                        ],
                        'lastAction',
                        'desc',
                    );

                    cache.writeQuery<ChatFindManyQuery>({
                        variables,
                        query: ChatFindManyDocument,
                        data: {
                            chatFindMany: {
                                __typename: 'ListChatOutput',
                                count,
                                items,
                                cursor,
                            },
                        },
                    });
                }
            };

            switch (action) {
                case ChatListEventAction.NewLastAction:
                case ChatListEventAction.NewLastMessage:
                case ChatListEventAction.NewMessage:
                    if (chat) {
                        /** Добавляем в папку "Все" */
                        writeToQueryCache(chat, dtoFilterChatListTransformer({
                            folder: 'All',
                        }));

                        /** Добавляем в папку по типу самого чата */
                        writeToQueryCache(chat, dtoFilterChatListTransformer({
                            folder: chat.type,
                        }));
                    }

                    break;
            }

            if (action === ChatListEventAction.NewMessage) {
                if (!chat?.myMember?.muted && !chat?.lastMessage?.isMine) {

                    if (chat) {
                        const { isMainTab, isInInteractive } = ConfigStore;

                        if (isMainTab && !isInInteractive) {
                            PageService.startToggleTab({ title: t('newMessage') });
                        }

                        if (![ '/chat' ].includes(pageId)) {
                            Notify.chat({
                                chat,
                                key: `chat:${chat.id}:${chat.lastMessage?.id}`,
                                uniqKey: `chat:${chat?.id}`,
                                options: {
                                    variant: 'chat',
                                    autoHideDuration: 60000,
                                    neverToHide: !document.hasFocus(),
                                },
                            });
                        }
                    }
                }
            }
        },
    });

    return (<></>);
});

export { ChatSubscription };
