/**
 * PriceAvatarPart component
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { Icon12Circle } from '@vkontakte/icons';
import { Avatar, AvatarProps } from '@exode.ru/vkui';

import { If, SvgComponent } from '@/cutils';

import { gradient } from '@/styles/modules/gradient';
import { DynamicVkIcon } from '@/components/Atoms/DynamicVkIcon';


interface Props {
    inCart?: boolean;
    icon?: string | null;
    badge?: AvatarProps['badge'];
    mode?: AvatarProps['mode'];
    withCorner?: boolean;
}


const PriceAvatarPart = (props: Props) => {

    const { inCart, icon, mode, badge, withCorner = true } = props;

    return (
        <Avatar mode={mode} badge={badge} className={[
            'relative',
            withCorner ? '!mt-4 !rounded-tl-[0]' : '',
            inCart
                ? 'bg-gradient-to-tl from-lime-600 to-green-600'
                : `bg-gradient-to-tl ${gradient.blue400ToBlue700Gradient}`,
        ].join(' ')} width={52} height={52}>
            <If is={withCorner}>
                <Icon12Circle className="absolute left-[3px] top-[3px] rounded-full"
                              fill="var(--modal_card_background)"/>
            </If>

            <SvgComponent element={<DynamicVkIcon vkIconName={icon}/>}
                          svgProps={{ width: 28, height: 28, className: 'text-white' }}/>
        </Avatar>
    );
};


export { PriceAvatarPart };
