/**
 * LinkPart
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { If, Link } from '@/cutils';
import { Avatar, Caption, Text, Title } from '@exode.ru/vkui';

import { Icon12ArrowUpRightOutSquareOutline } from '@vkontakte/icons';


const LinkPart = ({ data }: any) => {

    const { title, description, url } = data?.meta || {};

    const image = data?.meta?.icon || data?.meta?.image;

    return (
        <Link disabled={!url} blank toOuter={`${url}`}>
            <div className="flex flex-col thin-border vk-rounded p-3 my-4">
                <div className="flex justify-between gap-2">
                    <div className={!image ? 'w-full' : 'w-[calc(100%_-_56px)]'}>
                        <Title className="font-bold line-clamp-1">
                            {title || '—'}
                        </Title>

                        <If is={!!description}>
                            <Text className="line-clamp-2 mt-1 fs-14">
                                {description}
                            </Text>
                        </If>
                    </div>

                    <If is={!!image}>
                        <Avatar size={48} mode="app" src={image} className="vk-rounded"/>
                    </If>
                </div>

                <div className="flex gap-2 items-center mt-2">
                    <div className="min-h-[12px]">
                        <Icon12ArrowUpRightOutSquareOutline fill="var(--icon_secondary)"/>
                    </div>

                    <Caption className="line-clamp-1 text-secondary">
                        {url}
                    </Caption>
                </div>
            </div>
        </Link>
    );
};


export { LinkPart };
