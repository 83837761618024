/**
 * UseProductManager
 *
 * @author: exode <hello@exode.ru>
 */

import { ApolloCache } from '@apollo/client';

import { GqlResult } from '@/types/graphql';

import {
    CreateProductManagerSellerInput,
    Permission,
    SellerProductManagerFindManyDocument,
    SellerProductManagerFindManyQuery,
    SellerProductManagerFindManyQueryResult,
    useSellerProductManagerCreateMutation,
    useSellerProductManagerFindOneByUserAndProductLazyQuery,
    useSellerProductManagerUpdateMutation,
} from '@/codegen/graphql';


type ProductManager = NonNullable<GqlResult<SellerProductManagerFindManyQueryResult>['sellerProductManagerFindMany']['items']>[number]


export const useProductManager = (productId: number) => {
    const findManyCuratorsVariables = {
        list: {
            skip: 0,
        },
        filter: {
            permissions: [ Permission.CourseCurator ],
            productIds: [ productId ],
        },
    };

    const [ checkForProductManager ] = useSellerProductManagerFindOneByUserAndProductLazyQuery();

    const [
        createProductManager,
        { loading: productManagerCreateLoading },
    ] = useSellerProductManagerCreateMutation({
        onError: error => console.error(error),
    });

    const [
        updateSellerProductManager,
        { loading: productManagerUpdateLoading },
    ] = useSellerProductManagerUpdateMutation({
        onError: error => console.error(error),
    });

    const onSelect = async (userId: number, items?: number[]) => {
        if (items?.includes(userId)) {
            return;
        }

        const { data, loading } = await checkForProductManager({
            variables: {
                userId,
                productId,
            },
        });

        if (loading || !data) {
            return;
        }

        const productManager = data?.sellerProductManagerFindOneByUserAndProduct;

        const writeToFindManyProductManagersCache = (
            cache: ApolloCache<any>,
            newProductManager: ProductManager,
            mode: 'create' | 'update',
        ) => {
            const cachedCourseProductManagers = cache.readQuery<SellerProductManagerFindManyQuery>({
                query: SellerProductManagerFindManyDocument,
                variables: findManyCuratorsVariables,
            });

            if (!cachedCourseProductManagers) {
                return console.warn('[Cache]: cachedProductManagers отсутствуют в кэше');
            }

            const { pages, count, items } = cachedCourseProductManagers.sellerProductManagerFindMany;

            cache.writeQuery<SellerProductManagerFindManyQuery>({
                query: SellerProductManagerFindManyDocument,
                variables: findManyCuratorsVariables,
                data: {
                    sellerProductManagerFindMany: {
                        __typename: 'ListProductManagerSellerOutput',
                        pages,
                        count: count + (mode === 'create' ? 1 : 0),
                        items: [ ...(items ?? []), newProductManager ],
                    },
                },
            });
        };

        /** Manager exists - update */
        if (productManager) {
            return updateSellerProductManager({
                variables: {
                    productManagerId: productManager.id,
                    productManager: {
                        productId,
                        userId: productManager.manager.user.id,
                        permissions: [
                            ...productManager.permissions,
                            Permission.CourseCurator,
                        ],
                    },
                },
                update: (cache, { data }) => (
                    data && writeToFindManyProductManagersCache(
                        cache,
                        data.sellerProductManagerUpdate,
                        'update',
                    )
                ),
            });
        }

        /** Manager doesn't exist - create new */
        return createProductManager({
            variables: {
                productManager: {
                    userId,
                    productId,
                    permissions: [ Permission.CourseCurator ],
                },
            },
            update: (cache, { data }) => (
                data && writeToFindManyProductManagersCache(
                    cache,
                    data.sellerProductManagerCreate,
                    'create',
                )
            ),
        });
    };

    const onDelete = async (
        productManagerId: number,
        productManager: CreateProductManagerSellerInput,
    ) => {
        await updateSellerProductManager({
            variables: { productManagerId, productManager },
            update: (cache, { data }) => {
                const cachedCourseProductManagers = cache.readQuery<SellerProductManagerFindManyQuery>({
                    query: SellerProductManagerFindManyDocument,
                    variables: findManyCuratorsVariables,
                });

                if (!cachedCourseProductManagers) {
                    return console.warn('[Cache]: cachedProductManagers отсутствуют в кэше');
                }

                const { pages, count, items } = cachedCourseProductManagers?.sellerProductManagerFindMany;

                data && cache.writeQuery<SellerProductManagerFindManyQuery>({
                    query: SellerProductManagerFindManyDocument,
                    variables: findManyCuratorsVariables,
                    data: {
                        sellerProductManagerFindMany: {
                            __typename: 'ListProductManagerSellerOutput',
                            pages,
                            count: count - 1,
                            items: items?.filter(({ id }) => id !== productManagerId),
                        },
                    },
                });
            },
        });
    };

    return {
        onSelect,
        onDelete,
        findManyCuratorsVariables,
        isLoading: productManagerCreateLoading || productManagerUpdateLoading,
    };
};
