/**
 * UserService
 *
 * @author: exode <hello@exode.ru>
 */

import { Permission } from '@/codegen/graphql';
import { BaseService } from '@/services/Core/Base';
import { UserAuthService } from '@/services/User/Auth';

import { UserAuthStore } from '@/store/user/auth';
import { CartService } from '@/services/User/Cart';


class UserService extends BaseService {

    /**
     * Регистрация сервиса
     */
    static register() {
        CartService.register();
    }

    /**
     * Инициализация сервиса
     */
    static init() {
        UserAuthService.init();
        CartService.init().finally();
    }

    /**
     * Проверка на права админа по user.permissions
     * @returns {boolean}
     */
    static get isAdmin() {
        const { permissions } = UserAuthStore.user || {};

        return !!permissions?.includes(Permission.UserIsAdmin);
    }

    /**
     * Проверка на права продавца по user.permissions
     * @returns {boolean}
     */
    static get isSeller() {
        const { permissions } = UserAuthStore.user || {};

        return !!permissions?.includes(Permission.UserHavePartnerAccount);
    }

}


export { UserService };
