/**
 * SessionSubscription component
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { UserAuthStore } from '@/store/user/auth';

import { useSessionListenKillSubscription } from '@/codegen/graphql';


const SessionSubscription = () => {

    useSessionListenKillSubscription({
        onData: async ({ data: { data } }) => {
            if (data?.sessionListenExit) {
                await UserAuthStore.logout();
            }
        },
    });

    return <></>;
};


export { SessionSubscription };
