/**
 * UseSchoolUser
 *
 * @author: exode <hello@exode.ru>
 */

import { GqlResult } from '@/types/graphql';

import { usePagination } from '@/hooks/core';

import { ManageSchoolUsersPageStore } from '@/pages/Manage/School/Users/store';
import { useSchoolManageUsersFilter } from '@/pages/Manage/School/Users/filter';

import {
    CreateAccessManyProductInput,
    CreateManyUserInput,
    SchoolUserFindManyDocument,
    SchoolUserFindManyQuery,
    SchoolUserManageCreateManyMutationResult,
    UpdateUserInput,
    useSchoolUserManageCreateManyMutation,
    useSchoolUserManageUpdateMutation,
} from '@/codegen/graphql';


export const useSchoolUser = () => {

    const { list, sort } = usePagination({
        store: ManageSchoolUsersPageStore,
        path: '/manage/school/users/:page([0-9]+)',
    });

    const { dtoFilter } = useSchoolManageUsersFilter(
        ManageSchoolUsersPageStore,
        {},
    );

    const initialValuesForCreate = {
        users: {
            accounts: [
                {
                    email: '',
                    phone: '',
                    type: 'email' as 'email' | 'phone',
                },
            ],
        },
        enrollments: {
            accesses: [] as {
                productId: number;
                launchId: number;
                priceId: number;
            }[],
        },
    };

    const [ _schoolUserManageCreate, {
        data: schoolUserManageCreateData,
        loading: schoolUserManageCreateLoading,
    } ] = useSchoolUserManageCreateManyMutation();

    const schoolUserManageCreate = (
        users: CreateManyUserInput,
        enrollments: CreateAccessManyProductInput,
        onCompleted?: (
            data: GqlResult<SchoolUserManageCreateManyMutationResult>['schoolUserManageCreateMany'],
        ) => void,
    ) => {
        return _schoolUserManageCreate({
            variables: { users, enrollments },
            onCompleted: (data) => {
                onCompleted?.(data.schoolUserManageCreateMany);
            },
            update(cache, { data }) {
                const variables = {
                    list: { ...list },
                    filter: { ...dtoFilter },
                    sort: { ...sort },
                };

                const cachedUsers = cache.readQuery<SchoolUserFindManyQuery>({
                    variables,
                    query: SchoolUserFindManyDocument,
                });

                if (!cachedUsers) {
                    return console.warn('[Cache]: cachedMembers отсутствуют в кэше');
                }

                if (cachedUsers && data?.schoolUserManageCreateMany) {
                    const { count, items, ...rest } = cachedUsers.schoolUserFindMany;

                    cache.writeQuery<SchoolUserFindManyQuery>({
                        variables,
                        query: SchoolUserFindManyDocument,
                        data: {
                            schoolUserFindMany: {
                                ...rest,
                                count: count + 1,
                                items: [
                                    ...data?.schoolUserManageCreateMany?.created,
                                    ...items || [],
                                ],
                            },
                        },
                    });
                }
            },
        });
    };

    const [ _schoolUserManageUpdate, {
        loading: schoolUserManageUpdateLoading,
    } ] = useSchoolUserManageUpdateMutation();

    const schoolUserManageUpdate = (
        userId: number,
        userPartial: UpdateUserInput,
    ) => {
        return _schoolUserManageUpdate({
            variables: { userId, userPartial },
        });
    };

    return {
        initialValuesForCreate,
        schoolUserManageCreate,
        schoolUserManageCreateData,
        schoolUserManageCreateLoading,
        schoolUserManageUpdate,
        schoolUserManageUpdateLoading,
    };
};
