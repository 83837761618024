/**
 * LessonMeta
 *
 * @author: exode <hello@exode.ru>
 */

import { PageParams } from 'router.tsx';

import { gql } from '@apollo/client';

import { MetaPropsType } from '@/types/page';
import { MetaRequestOptions } from '@/root/src/meta';

import { serverApolloClient } from '@/api/ssr-api';

import { RoutePathType } from '@/router/paths';
import { CourseLessonEntity } from '@/codegen/graphql';


export const lessonMetaRequest = async (
    id: RoutePathType,
    params: PageParams,
    options?: MetaRequestOptions,
): Promise<Partial<MetaPropsType>> => {
    const query = gql`
        query CourseLessonFindOnePreview($lessonId: Int!) {
            courseLessonFindOnePreview(lessonId:$lessonId) {
                id
                name
                description
                wrapper {
                    small
                    maximum
                }
            }
        }
    `;

    const { data: { courseLessonFindOnePreview } } = await serverApolloClient.query({
        query,
        variables: { lessonId: +params.lessonId },
        /** Added no-cache due to course preview logic */
        fetchPolicy: options?.fetchPolicy || 'no-cache',
    });

    const { name, description, wrapper } = courseLessonFindOnePreview as CourseLessonEntity;

    return {
        title: name,
        description,
        image: wrapper?.medium || '',
        keywords: [ name, description ].join(', '),
    };
};
