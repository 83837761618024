/**
 * Shop store
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import { observer } from 'mobx-react';
import { makeAutoObservable } from 'mobx';

import { Storage } from '@/api/storage';
import { CartProduct } from '@/codegen/graphql';


class Shop {

    constructor() {
        makeAutoObservable(this);
    }

    /** Корзина пользователя */
    cart: CartProduct[] = [];

    /** Форма оплаты */
    paymentForm = {
        mode: 'phone' as 'phone' | 'login',
        email: '',
        phone: '',
    };

    /** DTO форма оплаты */
    get paymentFormDto() {
        const { mode, phone, email } = ShopStore.paymentForm;

        return {
            login: mode === 'phone' ? phone : email,
        };
    }

    /** Промокоды корзины пользователя */
    promoCodes: string[] = [];

    /**
     * Длина корзины
     * @returns {number}
     */
    get cartLength() {
        return this.cart.length;
    }

    /**
     * Установка значений корзины
     * @param {CartProduct[]} cart
     */
    setCart(cart: CartProduct[]) {
        Storage.set('products:cart', cart);

        this.merge({
            cart: cart.map((p) => _.omit(p, '__typename')),
        });
    }

    /**
     * Проверка товара в корзине
     * @param {number} productId
     * @param {number} priceId
     * @param {'product' | 'price'} compareMode
     * @returns {boolean}
     */
    checkInCart(
        productId: number,
        priceId: number,
        compareMode: 'product' | 'price' = 'price',
    ) {
        return ShopStore.cart.some(({ productId: cartProductId, priceId: cartPriceId }) => {
            return cartProductId === productId
                && (
                    compareMode === 'product'
                        ? cartPriceId !== priceId
                        : cartPriceId === priceId
                );
        });
    }

    /**
     * Merge and persist
     * @param {Partial<Shop>} shop
     * @param {boolean} persist
     */
    merge(
        shop: Partial<Shop>,
        persist = true,
    ) {
        Object.assign(this, shop);

        if (persist) {
            Storage.persistStore(ShopStore, 'store:shop');
        }
    }

}

const ShopStore = new Shop();

Storage.recoverStore(ShopStore, 'store:shop');


export { observer, ShopStore };
