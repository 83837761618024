/**
 * PanelHeaderPage
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import React, { ReactElement } from 'react';

import { Link, Portal, Responsive } from '@/cutils';

import { PanelHeaderButton, PanelHeaderContent } from '@exode.ru/vkui';
import { Icon28ArrowLeftOutline } from '@vkontakte/icons';


interface Props {
    children: ReactElement;
}

interface PanelHeaderPageContentProps {
    children: ReactElement;
    wrap: boolean;
    aside?: ReactElement | ReactElement[];
}


const PanelHeaderPageLeft = (props: Props) => {

    const children = !_.isUndefined(props.children)
        ? props.children
        : (
            <Link popPage>
                <PanelHeaderButton>
                    <Icon28ArrowLeftOutline/>
                </PanelHeaderButton>
            </Link>
        );

    return (
        <>
            <Responsive.Mobile>
                <Portal id="panel-header-page:left">
                    {children}
                </Portal>
            </Responsive.Mobile>
            <Responsive.Desktop>
                <Portal id="panel-header-page:d:right">
                    {children}
                </Portal>
            </Responsive.Desktop>
        </>
    );
};

const PanelHeaderPageContent = (props: PanelHeaderPageContentProps) => {

    const { wrap, children, aside } = props;

    return (
        <Portal id="panel-header-page:content">
            {wrap ? <PanelHeaderContent aside={aside} children={children}/> : children}
        </Portal>
    );
};

const PanelHeaderPageRight = (props: Props) => {

    const { children } = props;

    return (
        <>
            <Responsive.Mobile>
                <Portal id="panel-header-page:right">
                    {children}
                </Portal>
            </Responsive.Mobile>

            <Responsive.Desktop>
                <Portal id="panel-header-page:d:right">
                    {children}
                </Portal>
            </Responsive.Desktop>
        </>
    );
};


export {
    PanelHeaderPageLeft,
    PanelHeaderPageContent,
    PanelHeaderPageRight,
};
