/**
 * FriendsListView
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { Page } from '@/pages/Core';
import { useI18n } from '@/hooks/core';

import { FriendshipStatus, ListFriendsByStatusQueryResult } from '@/codegen/graphql';

import { useParams } from 'router.tsx';

import { Button, Counter, Header, List } from '@exode.ru/vkui';
import { Icon16ChevronOutline, Icon20UsersOutline } from '@vkontakte/icons';

import { If, Link } from '@/cutils';
import { GqlResult } from '@/types/graphql';
import { useSetFriendshipStatus } from '@/hooks/apollo';

import { User } from '@/components/Atoms/User';
import { Placeholder } from '@/components/Atoms/Placeholder';
import { PageRowRoundedButton } from '@/components/Atoms/PageRowRoundedButton';


interface Props {
    items?: GqlResult<ListFriendsByStatusQueryResult>['friendshipFindMany']['items'];
    count: GqlResult<ListFriendsByStatusQueryResult>['friendshipFindMany']['count'];
    isContext?: boolean;
    isOwner?: boolean;
}


const FriendsListView = (props: Props) => {

    const { count, items, isContext = false, isOwner } = props;

    const { t } = useI18n('pages.Profile.Profile.views');

    const { userId } = useParams();
    const { setStatus, loading } = useSetFriendshipStatus();

    return (
        <>
            <If is={!!count}>
                <Page.Row>
                    <Header aside={<Counter size="m" mode="primary">{count}</Counter>} mode="secondary">
                        {t('friends')}
                    </Header>

                    <List>
                        {items?.map(({ id, profile, lastOnlineAt }) => (
                            <Link key={id} pushPage={{ id: '/@:userId([0-9_A-Za-z]+)', params: { userId: `${id}` } }}>
                                <User.Cell mode="simple"
                                           userId={id}
                                           profile={profile}
                                           lastOnlineAt={lastOnlineAt}
                                           after={<Icon16ChevronOutline width={20} height={20}/>}/>
                            </Link>
                        ))}

                        <If is={isContext}>
                            <Link pushModal={{ id: 'profile-friends' }}>
                                <PageRowRoundedButton>
                                    {count > 5 ? t('showMore') : t('openList')}
                                </PageRowRoundedButton>
                            </Link>
                        </If>
                    </List>
                </Page.Row>
            </If>

            <If is={!count && !isContext}>
                <Page.Row>
                    <Placeholder iconSize={72}
                                 header={t('emptyHere')}
                                 icon={<Icon20UsersOutline className="text-accent opacity-[0.85]"/>}
                                 content={(
                                     <span className="m:w-full w-9/10 flex mx-auto">
                                         {isOwner
                                             ? t('hereWillBeDisplayedPeopleWhoYouAddToFriends')
                                             : t('beFirstAddPersonToFriends')
                                         }
                                     </span>
                                 )}
                                 action={(
                                     <If is={!isOwner}>
                                         <Button stretched
                                                 size="m"
                                                 loading={loading}
                                                 disabled={loading}
                                                 onClick={() => setStatus(+userId, FriendshipStatus.Requested)}>
                                             {t('addToFriends')}
                                         </Button>
                                     </If>
                                 )}/>
                </Page.Row>
            </If>
        </>
    );
};


export { FriendsListView };
