/**
 * CheckListTooltip
 *
 * @author: exode <hello@exode.ru>
 */

import React, { ReactNode } from 'react';

import { If } from '@/cutils';

import { Icon12CancelCircleFillRed, Icon12CheckCircle, Icon12ErrorCircleFillYellow } from '@vkontakte/icons';


interface Props {
    list: {
        text: string;
        mode: 'success' | 'warning' | 'error';
        icon?: ReactNode;
    }[];

    header?: ReactNode;
}


const CheckListTooltip = (props: Props) => {

    const { header, list } = props;

    return (
        <div className="flex flex-col gap-0.5">
            <If is={!!header}>
                <span className="font-medium mb-1">
                    {header}
                </span>
            </If>

            {list.map(({ text, mode, icon }) => (
                <div className="flex items-center gap-2">
                    <If is={!icon}>
                        <If is={mode === 'success'}>
                            <Icon12CheckCircle className="bg-white vk-rounded" fill="var(--dynamic_green)"/>
                        </If>

                        <If is={mode === 'warning'}>
                            <Icon12ErrorCircleFillYellow/>
                        </If>

                        <If is={mode === 'error'}>
                            <Icon12CancelCircleFillRed fill="var(--dynamic_red)"/>
                        </If>
                    </If>

                    <>{icon}</>

                    <span>
                        {text}
                    </span>
                </div>
            ))}
        </div>
    );
};


export { CheckListTooltip };
