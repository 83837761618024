/**
 * TelegramConnectButton component index file
 *
 * @author: exode <hello@exode.ru>
 */

import React, { useEffect, useRef, useState } from 'react';

import { Time } from '@/utils';
import { If } from '@/cutils';

import { Spinner } from '@exode.ru/vkui';
import { Skeleton } from '@mui/material';


interface Props {
    botName: string;
    onAuth: (data: any) => void;
    isLoading?: boolean;
}


const TelegramConnectButton = (props: Props) => {

    const { botName, onAuth, isLoading } = props;

    const [ scriptIsLoaded, setScriptIsLoaded ] = useState(false);
    const [ iframeIsLoaded, setIframeIsLoaded ] = useState(false);

    const telegramWrapperRef = useRef<HTMLDivElement>(null);
    const iframe = telegramWrapperRef.current?.getElementsByTagName('iframe')[0];

    window.onTgAuth = onAuth;

    useEffect(() => {
        const scriptElement = document.createElement('script');

        scriptElement.setAttribute('data-size', 'large');
        scriptElement.setAttribute('data-radius', '14');
        scriptElement.setAttribute('data-telegram-login', botName);
        scriptElement.setAttribute('data-onauth', 'onTgAuth(user)');
        scriptElement.setAttribute('data-request-access', 'write');

        scriptElement.async = true;

        scriptElement.onload = async () => {
            setScriptIsLoaded(true);
        };

        scriptElement.src = 'https://telegram.org/js/telegram-widget.js?22';

        telegramWrapperRef.current?.appendChild(scriptElement);
    }, []);

    useEffect(() => {
        if (iframe) {
            Time.timer(450).then(() => setIframeIsLoaded(true));
        }
    }, [ scriptIsLoaded, iframe ]);

    return (
        <div className="flex items-center justify-center h-[40px] relative">
            <div ref={telegramWrapperRef} className={[
                (!iframeIsLoaded || isLoading) ? 'opacity-0' : '',
                'h-[40px] transition-all duration-300 vk-rounded overflow-hidden z-[2]',
            ].join(' ')}/>

            <If is={!iframeIsLoaded}>
                <Spinner size="small" className="absolute top-0 z-[1]"/>
            </If>

            <If is={!iframeIsLoaded || !!isLoading}>
                <Skeleton height={39}
                          width={218}
                          variant="rectangular"
                          className="absolute top-0 vk-rounded bg-button-secondary"/>
            </If>

            <If is={!!isLoading}>
                <Spinner size="small" className="absolute top-0 text-accent"/>
            </If>
        </div>
    );
};


export { TelegramConnectButton };
