/**
 * ParticipantsProgressView
 *
 * @author: exode <hello@exode.ru>
 */

import { APP_URL } from '@/root/src/env';

import React, { useEffect } from 'react';

import { Page } from '@/pages/Core';
import { ConfigStore, observer } from '@/store/core/config';
import { SubscriptionStore } from '@/store/subscription/subscription';

import { TournamentMembersProgressQueryResult } from '@/codegen/graphql';

import { Icon20ChevronRightOutline } from '@vkontakte/icons';
import { Counter, Header, List, Progress, SimpleCell } from '@exode.ru/vkui';

import { If, Link } from '@/cutils';
import { GqlResult } from '@/types/graphql';
import { RoutePathType } from '@/router/paths';
import { UserAvatar } from '@/components/Atoms/UserAvatar';
import { PageRowRoundedButton } from '@/components/Atoms/PageRowRoundedButton';


interface Props {
    tournamentId: number;
    members: GqlResult<TournamentMembersProgressQueryResult>['tournamentResultFindMany'];
    isContext?: boolean;
}


const ParticipantsProgressView = observer((props: Props) => {

    const { tournamentId, isContext = false, members: { items, count } } = props;

    const members = isContext ? items?.slice(0, 5) : items;

    const linkProps = (userId: number) => ConfigStore.isDesktop
        ? {
            blank: true,
            toOuter: `${APP_URL}/@${userId}`,
        }
        : {
            pushPage: {
                id: '/@:userId([0-9_A-Za-z]+)' as RoutePathType,
                params: { userId: String(userId) },
            },
        };

    useEffect(() => {
        SubscriptionStore.subscribeToTournamentMembersProgress(tournamentId);
    }, [ tournamentId ]);

    return (
        <Page.Row>
            <Header className="mb-2" mode="secondary" aside={<Counter size="m" mode="primary">{count}</Counter>}>
                Прогресс участников
            </Header>

            <List className="d:max-h-[400px] d:overflow-auto">
                {members?.map(({ user, solvedCount, finished, tournament: { tasks } }) => (
                    <Link key={user?.id} {...linkProps(user?.id)}>
                        <SimpleCell after={<Icon20ChevronRightOutline/>} subtitle={(
                            <>
                                <span>
                                    {finished ? 'Завершил турнир' : `Выполнено ${solvedCount} из ${tasks.length}`}
                                </span>

                                <Progress className="my-1 h-[5px] w-full flex rounded-full"
                                          value={solvedCount / tasks.length * 100}/>
                            </>
                        )} before={(
                            <UserAvatar size={36}
                                        className="mr-3"
                                        userId={user!.id}
                                        name={user!.profile!.fullName}
                                        src={user?.profile?.avatar?.medium || ''}/>
                        )}>
                            {user?.profile?.fullName}
                        </SimpleCell>
                    </Link>
                ))}

                <If is={isContext && count > 5}>
                    <Link pushModal={{ id: 'tournament-participants-progress' }}>
                        <PageRowRoundedButton>
                            Показать еще
                        </PageRowRoundedButton>
                    </Link>
                </If>
            </List>
        </Page.Row>
    );
});


export { ParticipantsProgressView };
