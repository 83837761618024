/**
 * TournamentSubscription component
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { DocumentEvent } from '@/types/window';

import { useTournamentListenNewMember, useTournamentSetState } from '@/hooks/apollo';


interface Props {
    tournamentId: number;
}


const TournamentSubscription = (props: Props) => {

    const { tournamentId } = props;

    useTournamentSetState(tournamentId, {
        onStateChange: (next) => {
            document.dispatchEvent(
                new CustomEvent(
                    DocumentEvent.TournamentOnStateChange,
                    { detail: { next } },
                ),
            );
        },
    });

    useTournamentListenNewMember(tournamentId);

    return (<></>);
};


export { TournamentSubscription };
