/**
 * MembersListView
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { useStableModalParams } from '@/router/index';

import { User } from '@/components/Atoms/User';

import { GqlResult } from '@/types/graphql';
import { Graphql, Link, Responsive } from '@/cutils';
import { TournamentService } from '@/services/Tournament/Tournament';
import { TournamentFindOneQuery, TournamentMembersQueryResult } from '@/codegen/graphql';

import { Counter, Header, List } from '@exode.ru/vkui';
import { Icon20ChevronRightOutline } from '@vkontakte/icons';

import { CopyToClipboard } from '@/components/Atoms/CopyToClipboard';
import { SocialShareButtons } from '@/components/Atoms/SocialShareButtons';


const MembersListView = (props: GqlResult<TournamentMembersQueryResult>['tournamentLobbyFindManyMembers']) => {

    const { count, items } = props;

    const [ { tournamentId, inviteKey } ] = useStableModalParams();

    return (
        <TournamentFindOneQuery children={(result) => (
            <>
                <Graphql.Success result={result}>
                    {({ tournamentFindOne: { uuid, accessByInviteKey } }) => {
                        const link = TournamentService.getInviteLink(
                            tournamentId,
                            uuid || inviteKey,
                            accessByInviteKey,
                        );

                        return (
                            <div>
                                <Responsive.Mobile>
                                    <Header className="my-2" mode="secondary">
                                        Поделиться ссылкой на турнир
                                    </Header>

                                    <CopyToClipboard link={link} className="mt-3 mb-6"/>
                                    <SocialShareButtons showVkPublish link={link} className="mb-3"/>
                                </Responsive.Mobile>

                                <Header className="mb-2"
                                        mode="secondary"
                                        aside={<Counter size="m" mode="primary">{count}</Counter>}>
                                    Всего зарегистрировано
                                </Header>

                                <div>
                                    <List className="d:max-h-[400px] d:overflow-auto">
                                        {items?.map(({ user }) => (
                                            <Link key={user?.id} pushPage={{
                                                id: '/@:userId([0-9_A-Za-z]+)',
                                                params: { userId: user.id.toString() },
                                            }}>
                                                <User.Cell mode="simple"
                                                           userId={user.id}
                                                           profile={user.profile}
                                                           lastOnlineAt={user.lastOnlineAt}
                                                           after={<Icon20ChevronRightOutline/>}/>
                                            </Link>
                                        ))}
                                    </List>
                                </div>
                            </div>
                        );
                    }}
                </Graphql.Success>
            </>
        )} variables={{ tournamentId: +tournamentId }}/>
    );
};


export { MembersListView };
