/**
 * EgeSubjectDatesModal
 *
 * @author: exode <hello@exode.ru>
 */

import React, { ReactElement, useState } from 'react';

import { useStableModalParams } from '@/router/index';
import { EducationSubject, EgeSubjectDatesQuery } from '@/codegen/graphql';

import { Page } from '@/pages/Core';

import { Title } from '@exode.ru/vkui';
import { Skeleton } from '@mui/material';

import { Graphql, Modal } from '@/cutils';
import { EgeSubjectDatesSkeleton } from '@/components/Atoms/Skeleton/EgeSubjectDates';

import { DatesPart } from '../SubjectDates/parts/Dates';


interface Props {
    id: string;
}


const EgeSubjectDatesModal = (props: Props) => {

    const [ parts, setParts ] = useState<{ actions: ReactElement[] }>();

    const [ { subject, create, subjectCategoryId } ] = useStableModalParams();

    return (
        <Modal.Card id={props.id}
                    actionsLayout="vertical"
                    header={<Title className="modal-title" level="2" weight="3">Даты экзамена</Title>}
                    actions={(
                        parts?.actions || [
                            <Skeleton className="mx-1.5 vk-rounded"
                                      key="courses"
                                      variant="rectangular"
                                      width="100%"
                                      height={44}/>,
                            <Skeleton className="mx-1.5 vk-rounded"
                                      key="tournament"
                                      variant="rectangular"
                                      width="100%"
                                      height={44}/>,
                        ]
                    )}>
            <Modal.Body>
                <EgeSubjectDatesQuery children={(result) => (
                    <Page.Row mobileModalNoMx>
                        <Graphql.Loading result={result}>
                            <EgeSubjectDatesSkeleton/>
                        </Graphql.Loading>

                        <Graphql.Success result={result}>
                            {({ subjectFindOne: subject }) => (
                                <DatesPart subjectCategoryId={subjectCategoryId} setParts={setParts} subject={subject}/>
                            )}
                        </Graphql.Success>

                        <Graphql.Error result={result}/>
                    </Page.Row>
                )} variables={{ subject: (subject || create) as EducationSubject }}/>
            </Modal.Body>
        </Modal.Card>
    );
};


export { EgeSubjectDatesModal };
