/**
 * UserMenuModal
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { UserAuthStore } from '@/store/user/auth';

import { Page } from '@/pages/Core';
import { useI18n } from '@/hooks/core';

import { Auth, Link, Modal } from '@/cutils';

import { Header, IconButton, Link as VkUiLink, Search, SimpleCell } from '@exode.ru/vkui';

import {
    Icon16Chevron,
    Icon20Cancel,
    Icon24EducationOutline,
    Icon24GearOutline,
    Icon24HistoryBackwardOutline,
    Icon24LiveOutline,
    Icon24UserSquareOutline,
} from '@vkontakte/icons';

import { GridColumnView } from './views/GridColumnView';


interface Props {
    id: string;
}


const UserMenuModal = (props: Props) => {

    const { t } = useI18n('modals.User.Menu');

    return (
        <Modal.Page id={props.id} className="modal:bg-modal-content modal:w-450">
            <Page.Content className="px-2">
                <Page.Row className="sticky vk-rounded pt-3 top-0 bg-modal-content z-10">
                    <Search className="bg-modal-content" after={null}/>
                </Page.Row>

                <Page.Row>
                    <Header mode="secondary" aside={(
                        <Link>
                            <span className="text-accent fs-13 mr-0.5">{t('all')}</span>
                        </Link>
                    )}>
                        {t('history')}
                    </Header>

                    {Array(2).fill(1, 2).map((i) => (
                        <SimpleCell key={i} before={<Icon24HistoryBackwardOutline/>} after={(
                            <>
                                <IconButton className="p-0 m-0 h-fit">
                                    <Icon20Cancel className="p-1.5"/>
                                </IconButton>
                            </>
                        )}>
                            {t('searchPhraseFromTheUsersSearchHistory')}
                        </SimpleCell>
                    ))}
                </Page.Row>

                <Page.Row>
                    <Header mode="secondary" aside={(
                        <Link pushPage={{ id: '/menu' }}>
                            <VkUiLink>
                                <span className="text-accent fs-13 mr-0.5">{t('more')}</span>
                            </VkUiLink>
                        </Link>
                    )}>
                        {t('sections')}
                    </Header>
                    <div className="grid grid-cols-2">
                        <GridColumnView icon={<Icon24UserSquareOutline className="text-accent mr-2"/>}
                                        title={t('profile')}
                                        list={(
                                            <>
                                                <Link pushPage={{ id: '/friends' }}>
                                                    <VkUiLink>{t('friends')} <Icon16Chevron/></VkUiLink>
                                                </Link>

                                                <Link pushPage={{ id: '/wallet' }}>
                                                    <VkUiLink>{t('wallet')} <Icon16Chevron/></VkUiLink>
                                                </Link>

                                                <Link pushPage={{ id: '/my-subjects' }}>
                                                    <VkUiLink>{t('subjects')} <Icon16Chevron/></VkUiLink>
                                                </Link>

                                                <Link pushPage={{ id: '/achievements' }}>
                                                    <VkUiLink>{t('achievements')} <Icon16Chevron/></VkUiLink>
                                                </Link>
                                            </>
                                        )}/>

                        <GridColumnView icon={<Icon24EducationOutline className="text-accent mr-2"/>}
                                        title={t('education')}
                                        list={(
                                            <>
                                                <Link pushPage={{ id: '/courses/enrolled' }}>
                                                    <VkUiLink>{t('courses')} <Icon16Chevron/></VkUiLink>
                                                </Link>

                                                <Link pushPage={{ id: '/courses/enrolled' }}>
                                                    <VkUiLink>{t('webinars')} <Icon16Chevron/></VkUiLink>
                                                </Link>

                                                <Link pushPage={{ id: '/my-questions' }}>
                                                    <VkUiLink>{t('questions')} <Icon16Chevron/></VkUiLink>
                                                </Link>

                                                <Link pushPage={{ id: '/bookmarks' }}>
                                                    <VkUiLink>{t('notes')} <Icon16Chevron/></VkUiLink>
                                                </Link>
                                            </>
                                        )}/>

                        <GridColumnView icon={<Icon24LiveOutline className="text-accent mr-2"/>}
                                        title={t('other')}
                                        list={(
                                            <>
                                                <Link pushPage={{ id: '/promo/bonus' }}>
                                                    <VkUiLink>{t('bonuses')} <Icon16Chevron/></VkUiLink>
                                                </Link>

                                                <Link pushPage={{ id: '/wiki' }}>
                                                    <VkUiLink>{t('taskBase')} <Icon16Chevron/></VkUiLink>
                                                </Link>

                                                <Link pushPage={{ id: '/exams-timeout' }}>
                                                    <VkUiLink>{t('tillEgeLeft')} <Icon16Chevron/></VkUiLink>
                                                </Link>

                                                <Link pushPage={{ id: '/about' }}>
                                                    <VkUiLink>{t('aboutApp')} <Icon16Chevron/></VkUiLink>
                                                </Link>
                                            </>
                                        )}/>


                        <GridColumnView icon={<Icon24GearOutline className="text-accent mr-2"/>}
                                        title={t('settings')}
                                        list={(
                                            <>
                                                <Link pushPage={{ id: '/settings' }}>
                                                    <VkUiLink>
                                                        {t('seances')} <Icon16Chevron/>
                                                    </VkUiLink>
                                                </Link>

                                                <Link pushPage={{ id: '/settings' }}>
                                                    <VkUiLink>
                                                        {t('main')} <Icon16Chevron/>
                                                    </VkUiLink>
                                                </Link>

                                                <Link pushPage={{ id: '/settings' }}>
                                                    <VkUiLink>
                                                        {t('security')} <Icon16Chevron/>
                                                    </VkUiLink>
                                                </Link>

                                                <Auth>
                                                    <Link replaceModal pushPage={{ id: '/' }}>
                                                        <VkUiLink className="text-red-500"
                                                                  onClick={() => UserAuthStore.logout()}>
                                                            {t('quit')} <Icon16Chevron/>
                                                        </VkUiLink>
                                                    </Link>
                                                </Auth>
                                            </>
                                        )}/>
                    </div>
                </Page.Row>
            </Page.Content>
        </Modal.Page>
    );
};


export { UserMenuModal };
