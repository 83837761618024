/**
 * PhoneInput
 *
 * @author: exode <hello@exode.ru>
 */

import React, { useState } from 'react';

import { Spinner } from '@exode.ru/vkui';
import { defaultCountries, PhoneInput as Phone, PhoneInputProps } from '@exode-team/react-phone';

import { useGetAvailableCountryCodesQuery } from '@/codegen/graphql';

import { Container } from './PhoneInput.styled';


interface Props extends PhoneInputProps {

}


const PhoneInput = (props: Props) => {

    const { onFocus, onBlur } = props;

    const [ hasFocus, setHasFocus ] = useState(false);

    const { data, loading } = useGetAvailableCountryCodesQuery();

    const availableCountries = !data
        ? [ 'uz' ]
        : data.smsProviderGetAvailableCountryCodes?.map(e => e.toLowerCase());

    const hideDropdown = availableCountries?.length < 2;

    return (
        <Container hasFocus={hasFocus}
                   height={props.style?.height}
                   className="thin-border vk-rounded"
                   disabledSelector={loading || hideDropdown}>
            <Phone key={availableCountries[0]} {...props}
                   hideDropdown={hideDropdown}
                   defaultCountry={availableCountries[0]}
                   countries={defaultCountries.filter(([ __, code ]) => availableCountries.includes(code))}
                   onFocus={(e) => {
                       onFocus?.(e);
                       setHasFocus(true);
                   }}
                   onBlur={(e) => {
                       onBlur?.(e);
                       setHasFocus(false);
                   }}
                   countrySelectorProps={{
                       buttonContent: loading
                           ? <Spinner size="regular" className="pointer-events-none text-primary ml-2 pr-0.5"/>
                           : undefined,
                   }}/>
        </Container>
    );
};


export { PhoneInput };
