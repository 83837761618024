/**
 * CollaborationRoutes
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { PageSpace } from '@/shared/types';

import { RouterItemsType } from '@/types/router';
import { lazyWithRetry } from '@/helpers/react';


const BecomePartnerShortPage = lazyWithRetry(() => import('@/pages/Manage/Seller/BecomePartnerShort'));
const BecomePartnerFullPage = lazyWithRetry(() => import('@/pages/Manage/Seller/BecomePartnerFull'));


const CollaborationRoutes: RouterItemsType = {
    '/become/partner': {
        view: <BecomePartnerShortPage/>,
        auth: [],
        spaces: [
            PageSpace.MarketplacePlatform,
        ],
    },
    '/become/partner/:requestId([0-9]+)': {
        view: <BecomePartnerFullPage/>,
        auth: [],
        spaces: [
            PageSpace.MarketplacePlatform,
        ],
    },
};


export { CollaborationRoutes };
