/**
 * ManageSellerInfoModal
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { Page } from '@/pages/Core';
import { useI18n } from '@/hooks/core';

import { SellerOrganizationDocumentType, SellerOrganizationFindManyDocumentsQuery } from '@/codegen/graphql';

import { Title } from '@exode.ru/vkui';

import { Graphql, Modal } from '@/cutils';

import { SellerOrganizationDocsView } from '@/pages/Manage/Seller/Seller/views/SellerOrganizationDocsView';


interface Props {
    id: string;
}


const ManageSellerInfoModal = (props: Props) => {

    const { t } = useI18n('modals.Manage.Seller.SellerInfo');

    return (
        <Modal.Card id={props.id} header={(
            <Title className="modal-title" level="2" weight="3">
                {t('editing')}
            </Title>
        )}>
            <Modal.Body isScrollable>
                <SellerOrganizationFindManyDocumentsQuery children={(result) => (
                    <>
                        <Graphql.Loading result={result}>
                            <Page.Spinner/>
                        </Graphql.Loading>

                        <Graphql.Success result={result}>
                            {({ sellerOrganizationDocumentFindMany: { items } }) => (
                                <SellerOrganizationDocsView items={items}/>
                            )}
                        </Graphql.Success>

                        <Graphql.Error result={result}/>
                    </>
                )} variables={{
                    list: { skip: 0 },
                    filter: { types: [ SellerOrganizationDocumentType.AgreementSigned ] },
                }}/>
            </Modal.Body>
        </Modal.Card>
    );
};


export { ManageSellerInfoModal };
