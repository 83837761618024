/**
 * UserInviteView
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { APP_URL, IS_MARKETPLACE } from '@/root/src/env';

import { observer, UserAuthStore } from '@/store/user/auth';

import { useI18n } from '@/hooks/core';

import { FormItem } from '@exode.ru/vkui';

import { CopyToClipboard } from '@/components/Atoms/CopyToClipboard';
import { SocialShareButtons } from '@/components/Atoms/SocialShareButtons';


const UserInviteView = observer(() => {

    const { t } = useI18n('pages.Profile.Share');

    const appUrl = !IS_MARKETPLACE ? 'https://welcome.exode.biz' : APP_URL;

    const referralLink = `${appUrl}/referral/${UserAuthStore.user?.uuid}`;

    return (
        <>
            <FormItem top={t('yourPersonalLink')}>
                <CopyToClipboard link={referralLink} className="my-6"/>
            </FormItem>

            <SocialShareButtons link={referralLink}/>
        </>
    );
});


export { UserInviteView };
