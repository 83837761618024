/**
 * IsGreaterThan validator
 *
 * @author: exode <hello@exode.ru>
 */

import { registerDecorator, ValidationArguments, ValidationOptions } from 'class-validator';


const compareObjectProps = (
    name: 'IsSmallerThan' | 'IsGreaterThan',
    property: string,
    validationOptions?: ValidationOptions,
    direction: 'gt' | 'st' = 'gt',
    isOptional?: boolean,
) => {
    return function (object: Object, propertyName: string) {
        registerDecorator({
            name,
            target: object.constructor,
            propertyName: propertyName,
            constraints: [ property ],
            options: validationOptions,
            validator: {
                validate(value: any, args: ValidationArguments) {
                    const relatedValue = +(args.object as any)[args.constraints[0]];

                    if (isOptional && (!value || !relatedValue)) {
                        return true;
                    }

                    return typeof value === 'number' && direction === 'gt'
                        ? value > relatedValue
                        : value < relatedValue;
                },
            },
        });
    };
};

/**
 * Validate is greater
 * @param {string} property
 * @param {boolean} isOptional
 * @param {ValidationOptions} validationOptions
 * @returns {(object: Object, propertyName: string) => void}
 * @constructor
 */
export function IsGreaterThan(
    property: string,
    isOptional = false,
    validationOptions?: ValidationOptions,
) {
    return compareObjectProps('IsGreaterThan', property, validationOptions, 'gt', isOptional);
}

/**
 * Validate is smaller
 * @param {string} property
 * @param {boolean} isOptional
 * @param {ValidationOptions} validationOptions
 * @returns {(object: Object, propertyName: string) => void}
 * @constructor
 */
export function IsSmallerThan(
    property: string,
    isOptional = false,
    validationOptions?: ValidationOptions,
) {
    return compareObjectProps('IsSmallerThan', property, validationOptions, 'st', isOptional);
}

/**
 * Validate is > 0
 * @param {ValidationOptions} validationOptions
 * @param {boolean} isOptional
 * @returns {(object: Object, propertyName: string) => void}
 * @constructor
 */
export function IsBiggerThanZero(
    validationOptions?: ValidationOptions,
    isOptional?: boolean,
) {
    return function (object: Object, propertyName: string) {
        registerDecorator({
            name: 'IsBiggerThanZero',
            target: object.constructor,
            propertyName: propertyName,
            constraints: [ isOptional ],
            options: validationOptions,
            validator: {
                validate(value: any, args: ValidationArguments) {
                    const [ isOptional ] = args.constraints;

                    if (isOptional && !value) {
                        return true;
                    }

                    return +value > 0 && +(`${value}`.charAt(0)) !== 0;
                },
            },
        });
    };
}

/**
 * Validate min max if
 * @param {'min' | 'max'} mode
 * @param {(object: any, value: any) => boolean} condition
 * @param {number} maxValue
 * @param {'strict' | 'soft'} compare
 * @param {ValidationOptions} validationOptions
 * @param {boolean} isOptional
 * @returns {(object: Object, propertyName: string) => void}
 * @constructor
 */
export function MinMaxIf(
    mode: 'min' | 'max',
    condition: (object: any, value: any) => boolean,
    maxValue: number,
    compare: 'strict' | 'soft' = 'soft',
    validationOptions?: ValidationOptions,
    isOptional?: boolean,
) {
    return function (object: Object, propertyName: string) {
        registerDecorator({
            name: 'MinMaxIf',
            target: object.constructor,
            propertyName: propertyName,
            constraints: [ isOptional ],
            options: validationOptions,
            validator: {
                validate(value: any, args: ValidationArguments) {
                    if (!condition(args.object, value)) {
                        return true;
                    }

                    const [ isOptional ] = args.constraints;

                    if (isOptional && !value) {
                        return true;
                    }

                    return mode === 'min'
                        ? (compare === 'soft' ? value >= maxValue : value > maxValue)
                        : (compare === 'soft' ? value <= maxValue : value < maxValue);
                },
                defaultMessage() {
                    return `${validationOptions?.message}`;
                },
            },
        });
    };
}
