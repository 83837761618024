/**
 * ContentCard component
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { If } from '@/cutils';

import { Container } from './ContentCard.styled';

import { ContentCard as VkUiContentCard, ContentCardProps as VkUiContentProps, IconButton } from '@exode.ru/vkui';


export interface ContentCardProps extends VkUiContentProps {
    after?: React.ReactNode;
    activeAfter?: boolean;
    separator?: boolean;
    compact?: boolean;
    hideOverflow?: boolean;
    width?: string;
    wholeCardActive?: boolean;
    rightButtonHasHover?: boolean;
    transparent?: boolean;
    slimOnMobile?: boolean;
    equalPaddings?: boolean;
    innerCardClassName?: string;
}


const ContentCard = (props: ContentCardProps) => {

    const {
        after,
        separator,
        className,
        hideOverflow,
        width,
        compact,
        transparent,
        innerCardClassName,
        activeAfter = true,
        slimOnMobile = false,
        equalPaddings = false,
        wholeCardActive = false,
        rightButtonHasHover = true,
        ...rest
    } = props;

    return (
        <Container width={width}
                   compact={compact}
                   className={className}
                   activeAfter={activeAfter}
                   transparent={transparent}
                   hideOverflow={hideOverflow}
                   equalPaddings={equalPaddings}
                   wholeCardActive={wholeCardActive}>
            <VkUiContentCard {...rest} className={innerCardClassName}/>

            {separator && <div className="vertical-separator"/>}

            <If is={!!after}>
                <div className={[ 'right-button', slimOnMobile && 'slim-on-mobile' ].join(' ')}>
                    <IconButton hasActive={false} hasHover={rightButtonHasHover} className="cursor-pointer">
                        {after}
                    </IconButton>
                </div>
            </If>
        </Container>
    );
};


export { ContentCard };
