/**
 * ModalProvider
 *
 * @author: exode <hello@exode.ru>
 */

import React, { createContext, ReactElement, useContext, useState } from 'react';


interface Props {
    children: ReactElement;
}

interface ModalProviderProps {
    modalState: Record<any, any>,
    setModalState: (value: Record<any, any>) => void;
    clearModalState: () => void;
}


const ModalContext = createContext<ModalProviderProps>({
    modalState: {},
    setModalState: () => {},
    clearModalState: () => {},
});


const ModalProvider = (props: Props) => {

    const [ modalState, setModalState ] = useState({});

    const clearModalState = () => {
        setModalState({});
    };

    return (
        <ModalContext.Provider value={{ modalState, setModalState, clearModalState }}>
            {props.children}
        </ModalContext.Provider>
    );
};

/**
 * UseModalContext hook
 * @returns {SellerContext}
 */
const useModalContext = (): ModalProviderProps => useContext(ModalContext);


export { ModalProvider, useModalContext };
