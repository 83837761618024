/**
 * DialogModalStore
 *
 * @author: exode <hello@exode.ru>
 */

import { ReactElement } from 'react';

import { observer } from 'mobx-react';
import { makeObservable, observable } from 'mobx';
import { ModalStore } from '@/modals/Core/store';

import { DialogProps } from '@mui/material';
import { ModalCardProps } from '@exode.ru/vkui';


export interface DialogModalProps {
    mode?: 'mui' | 'vk';
    children?: ReactElement;
    vkUiModalProps?: ModalCardProps;
    muiDialogProps?: DialogProps;
}


class Dialog extends ModalStore {

    constructor() {
        super();
        makeObservable(this);
    }

    @observable
    muiIsOpened = true;

    @observable
    dialogModalProps: DialogModalProps = {};

    open(props: DialogModalProps) {
        this.dialogModalProps = { ...props };
    }

    close() {
        this.resetDialog();
    }

    resetDialog() {
        this.dialogModalProps = {};
    }

    merge(partial: Partial<Dialog>) {
        Object.assign(this, partial);
    }

}

const DialogModalStore = new Dialog();


export { observer, DialogModalStore };
