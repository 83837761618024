/**
 * CompanyAboutTextModal
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { useI18n } from '@/hooks/core';

import { observer } from '@/store/user/auth';

import { Modal } from '@/cutils';

import { Text, Title } from '@exode.ru/vkui';
import { Icon56DiamondOutline } from '@vkontakte/icons';


interface Props {
    id: string;
}


const CompanyAboutTextModal = observer((props: Props) => {

    const { t } = useI18n('modals.Company.AboutText');

    return (
        <Modal.Card id={props.id}
                    icon={<Icon56DiamondOutline/>}
                    header={<Title className="modal-title" level="2" weight="3">{t('aboutProject')}</Title>}>
            <Modal.Body>
                <Text weight="regular" className="text-center mt-3 mb-2 px-1 text-muted">
                    {t('aboutCompanyText')}
                </Text>
            </Modal.Body>
        </Modal.Card>
    );
});


export { CompanyAboutTextModal };
