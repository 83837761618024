/**
 * ContextView
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { Link } from '@/cutils';
import { Page } from '@/pages/Core';

import {
    Icon20AddSquareOutline,
    Icon28ChevronRightCircle,
    Icon28HistoryBackwardOutline,
    Icon36Users,
} from '@vkontakte/icons';

import { Button, Separator, SimpleCell } from '@exode.ru/vkui';

import { EnableNotificationsBanner } from '@/components/Promotion/ContextBanners';
import { GradientBanner, GradientBannerImage } from '@/components/Atoms/GradientBanner';

import { FiltersFormView } from '@/modals/Tornament/Filters/views/FilterFormView';
import { MyCategoriesContextItem } from '@/tabs/Education/items/MyCategoriesContextItem';


const ContextView = () => {
    return (
        <Page.ContextWrapper>
            <EnableNotificationsBanner/>

            <MyCategoriesContextItem/>

            <Link pushPage={{ id: '/tournaments/history' }}>
                <SimpleCell hasHover={false}
                            hasActive={false}
                            subtitle="Расписание и результаты"
                            className="bg-content thin-border"
                            after={<Icon28ChevronRightCircle/>}
                            before={<Icon28HistoryBackwardOutline/>}>
                    История турниров
                </SimpleCell>
            </Link>

            <GradientBanner imageTheme="light"
                            gradient="bg-content"
                            header="Создай свой турнир"
                            subheader={<span>Выбери предмет и режим,<br/>пригласи друзей и играй</span>}
                            image={(
                                <GradientBannerImage className="top-0 -right-2 mt-7 banner__image">
                                    <Icon36Users width={140} height={140} className="-mr-2"/>
                                </GradientBannerImage>
                            )}
                            actions={(
                                <Link pushModal={{ id: 'tournament-create' }}>
                                    <Button size="m" mode="commerce" before={<Icon20AddSquareOutline/>}>
                                        Создать турнир
                                    </Button>
                                </Link>
                            )}/>

            <Separator wide className="mt-3"/>

            <div className="-mx-4 relative">
                <FiltersFormView/>
            </div>
        </Page.ContextWrapper>
    );
};


export { ContextView };
