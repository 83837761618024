/**
 * StartHeader styled container
 *
 * @author: exode <hello@exode.ru>
 */

import styled from 'styled-components';

import { responsive } from '@/styles/modules/responsive';


export const Container = styled.div`
    ${responsive([ 'tablet' ])} {
        padding-top: 20px;
    }

    .simple-cell-wrapper {
        ${responsive([ 'mobile', 'lt-tablet' ])} {
            justify-content: center;
        }
    }
`;
