/**
 * Api storage
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import { ObjectUtil } from '@/utils';

import { localStorageKeys, LocalStorageKeyType } from '@/types/config';


class Storage {

    /**
     * Получение localStorage
     * @returns {boolean}
     * @private
     */
    private static get ls() {
        return typeof localStorage !== 'undefined'
            ? localStorage
            : {
                setItem: (): any => ({}),
                getItem: (): any => ({}),
                removeItem: (): any => ({}),
            };
    }

    /**
     * Обновление значения TTL
     * @param {string} name
     * @param {number} ttl - in seconds
     * @private
     */
    private static setTtl(
        name: LocalStorageKeyType,
        ttl: number,
    ) {
        const alive = this.get('storage:ttl', {});

        if (ttl) {
            alive[name] = new Date().getTime() + ttl * 1000;
        } else {
            delete alive[name];
        }

        this.set('storage:ttl', alive);
    }

    /**
     * Проверка активности значения в storage (по ttl)
     * @param {LocalStorageKeyType} name
     * @returns {boolean}
     * @private
     */
    private static checkIsAlive(name: LocalStorageKeyType) {
        if (name === 'storage:ttl') {
            return true;
        }

        const alive = this.get('storage:ttl', {});

        return !alive[name] || alive[name] > new Date().getTime();
    }

    /**
     * Получение значения из local storage
     * @param name
     * @param byDefault
     */
    static get(
        name: LocalStorageKeyType,
        byDefault?: any,
    ) {
        const { repackValue } = ObjectUtil.packTools();

        const alive = this.checkIsAlive(name);

        if (!alive) {
            this.destroy(name);

            return byDefault || null;
        }

        const value = this.ls.getItem(localStorageKeys[name]);

        return repackValue(value) || byDefault || null;
    }

    /**
     * Изменение значения в local storage
     * @param name
     * @param value
     * @param ttl in seconds
     */
    static set(
        name: LocalStorageKeyType,
        value: any,
        ttl: number = 0,
    ) {
        const { packValue } = ObjectUtil.packTools();

        this.ls.setItem(localStorageKeys[name], packValue(value));

        name !== 'storage:ttl' && this.setTtl(name, ttl);
    }

    /**
     * Удаление значения в local storage
     * @param name
     */
    static destroy(name: LocalStorageKeyType) {
        this.ls.removeItem(localStorageKeys[name]);
    }


    /**
     * Save store fields to local storage
     * @param store
     * @param {LocalStorageKeyType} key
     */
    static persistStore = (
        store: any,
        key: LocalStorageKeyType,
    ) => {
        const value = _.cloneDeep(store);

        return this.set(key, value);
    };

    /**
     * Get store values from local storage
     * @param store
     * @param {LocalStorageKeyType} key
     * @param ignore
     */
    static recoverStore = (
        store: any,
        key: LocalStorageKeyType,
        ignore: string[] = [],
    ) => {
        const value = this.get(key, {});

        return store.merge(_.omit(value, ignore));
    };

}


export { Storage };
