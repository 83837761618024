/**
 * DialogRoot
 *
 * @author: exode <hello@exode.ru>
 */

import { observer } from 'mobx-react';

import { If } from '@/cutils';

import { DialogModal, DialogModalStore } from '@/modals/Utils/Dialog';


const DialogRoot = observer(() => {
    return (
        <If is={DialogModalStore.dialogModalProps.mode === 'mui'}>
            <DialogModal id="dialog-modal-mui"/>
        </If>
    );
});


export { DialogRoot };
