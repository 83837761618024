/**
 * NewNotificationsView
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import React from 'react';

import { Page } from '@/pages/Core';
import { useI18n } from '@/hooks/core';

import { NotificationsQueryResult } from '@/codegen/graphql';

import { If } from '@/cutils';
import { GqlResult } from '@/types/graphql';

import { Placeholder } from '@/components/Atoms/Placeholder';
import { ProfileNotificationCard } from '@/components/Profile/Notifications/Card';

import { Icon28NotificationAddOutline } from '@vkontakte/icons';

import { HeaderItem } from '../items/HeaderItem';


interface Props {
    notifications: GqlResult<NotificationsQueryResult>['notificationFindMany'];
    isUnread?: boolean;
}


const NotificationsView = (props: Props) => {

    const { isUnread, notifications: { items, count } } = props;

    const { t } = useI18n('pages.Profile.Notifications.items');

    return (
        <>
            <If is={!!count}>
                <Page.Row isFirst={isUnread}>
                    <HeaderItem count={count} isUnread={isUnread}/>

                    {items?.map((notification) => (
                        <ProfileNotificationCard key={notification.id} notification={notification}/>
                    ))}
                </Page.Row>
            </If>

            <If is={_.isEmpty(items) && !!isUnread}>
                <Page.Row isFirst>
                    <Placeholder iconSize={72}
                                 header={t('noNotificationsYet')}
                                 icon={<Icon28NotificationAddOutline className="pt-0 text-accent opacity-[0.85]"/>}
                                 content={(
                                     <span className="m:w-full w-9/10 flex mx-auto">
                                         {t('youAreTheBest')}
                                     </span>
                                 )}/>
                </Page.Row>
            </If>
        </>
    );
};


export { NotificationsView };
