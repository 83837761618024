/**
 * Profile store
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import { makeAutoObservable } from 'mobx';
import { observer } from 'mobx-react';

import { Storage } from '@/api/storage';

import {
    ProfileAvatar,
    ProfileEntity,
    ProfileRole,
    ProfileSchool,
    ProfileTitleState,
    UserEntity,
    UserSexType,
} from '@/codegen/graphql';


class Profile {

    constructor() {
        makeAutoObservable(this);
    }

    /** User профиля */
    user: UserEntity;

    /** Id профиля */
    id: number;

    /** Официальный аккаунт */
    official: boolean;

    /** Имя */
    firstName: string | null;

    /** Фамилия */
    lastName: string | null;

    /** Аватары */
    avatar: ProfileAvatar;

    /** День рождения */
    bdate?: string | null;

    /** Титул */
    title: string | null;

    /** Титул эмодзи */
    emojiTitle: string | null;

    /** Состояние титула */
    titleState: ProfileTitleState = {};

    /** Пол */
    sex: UserSexType;

    /** Роль */
    role: ProfileRole;

    /** Страна */
    country: string | null;

    /** Фамилия */
    city: string | null;

    /** Школа пользователя */
    school: ProfileSchool;

    /**
     * Полное имя пользователя
     * @returns {string}
     */
    get fullName() {
        return [ this.firstName, this.lastName ].filter(e => e).join(' ');
    }

    static get ignore(): (keyof Profile)[] {
        return [ 'fullName' ];
    }

    /**
     * Merge and persist
     * @param {Partial<this>} fields
     * @returns {}
     */
    merge(fields: Partial<this>) {
        Object.assign(this, _.omit(fields, Profile.ignore));

        Storage.persistStore(ProfileStore, 'store:user-profile');

        return this as unknown as ProfileEntity;
    }

}

const ProfileStore = new Profile();

Storage.recoverStore(ProfileStore, 'store:user-profile', Profile.ignore);


export { observer, ProfileStore };
