/**
 * Storage types
 *
 * @author: exode <hello@exode.ru>
 */

export enum FileExtension {
    All = 'image/*,'
        + 'video/*,'
        + 'audio/*,'
        + 'text/plain,text/html,text/css,text/javascript,'
        + 'application/json,'
        + 'application/pdf,'
        + 'application/zip,'
        + 'application/msword,'
        + 'application/x-xmind,'
        + 'application/vnd.ms-excel,'
        + 'application/x-7z-compressed,'
        + 'application/vnd.ms-powerpoint,'
        + 'application/x-rar-compressed,'
        + 'application/x-shockwave-flash,'
        + 'application/xml,text/markdown,'
        + 'application/vnd.ms-excel.sheet.binary.macroenabled.12,'
        + 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,'
        + 'application/vnd.openxmlformats-officedocument.wordprocessingml.document,'
        + 'application/vnd.openxmlformats-officedocument.presentationml.presentation,',
    Video = 'video/*',
    Image = 'image/*',
    Audio = 'audio/*',
    ImageAndVideo = 'video/*,image/*',
    Docs = 'text/plain,text/html,text/css,text/javascript,'
        + 'application/json,'
        + 'application/pdf,'
        + 'application/zip,'
        + 'application/msword,'
        + 'application/x-xmind,'
        + 'application/vnd.ms-excel,'
        + 'application/x-7z-compressed,'
        + 'application/vnd.ms-powerpoint,'
        + 'application/x-rar-compressed,'
        + 'application/x-shockwave-flash,'
        + 'application/xml,text/markdown,'
        + 'application/vnd.ms-excel.sheet.binary.macroenabled.12,'
        + 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,'
        + 'application/vnd.openxmlformats-officedocument.wordprocessingml.document,'
        + 'application/vnd.openxmlformats-officedocument.presentationml.presentation,',
}
