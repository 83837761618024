/**
 * ChatHeaderItemPart
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { TabbarItem } from '@exode.ru/vkui';
import { Icon28MessageOutline } from '@vkontakte/icons';

import { HeaderItem } from '@/components/Desktop/Panel';
import { ChatIndicatorPart } from '@/components/Navigation/Tab';


interface Props {

}


const ChatHeaderItemPart = (props: Props) => {
    return (
        <HeaderItem {...props}>
            <TabbarItem indicator={<ChatIndicatorPart/>}>
                <Icon28MessageOutline fill="var(--accent)"/>
            </TabbarItem>
        </HeaderItem>
    );
};


export { ChatHeaderItemPart };
