/**
 * Constants
 *
 * @author: exode <hello@exode.ru>
 */

import { SortDirection } from '@/codegen/graphql';


export const tournamentMembersListConfig = {
    list: { skip: 0, take: 20 },
    filter: {},
    sort: { isPlayer: SortDirection.Desc },
};
