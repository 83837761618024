/**
 * UseArticleManage
 *
 * @author: exode <hello@exode.ru>
 */

import { Router } from '@/services/Utils/Router';

import { useI18n } from '@/hooks/core';

import { SaveStoreDeepKeys, SaveStoreKeys, SavingStore } from '@/store/core/saving';

import {
    ArticleFindManyDocument,
    ArticleFindManyQuery,
    SortDirection,
    UpdateArticleInput,
    useArticleCreateMutation,
    useArticleUpdateMutation,
} from '@/codegen/graphql';


interface Props {
    articleId?: number,
}


export const useArticleManage = (props: Props = {}) => {

    const { articleId } = props;

    const setArticleBlockEditing = (editing = true) => {
        SavingStore.setEditing(
            SaveStoreKeys.Article,
            SaveStoreDeepKeys.ArticleBlocksEdit,
            editing,
        );
    };

    const { t } = useI18n('hooks.apollo.admin');

    /** Create article */
    const [ _createArticle, { loading: createArticleLoading } ] = useArticleCreateMutation({
        update: (cache, { data }) => {
            const variables = {
                list: { skip: 0, take: 15 },
                filter: {},
                sort: { createdAt: SortDirection.Desc },
            };

            const cachedArticles = cache.readQuery<ArticleFindManyQuery>({
                variables,
                query: ArticleFindManyDocument,
            });

            if (!cachedArticles) {
                return console.warn('[Cache]: cachedArticles отсутствуют в кэше');
            }

            const { pages, count, items } = cachedArticles.articleFindMany;

            data && cache.writeQuery<ArticleFindManyQuery>({
                variables,
                query: ArticleFindManyDocument,
                data: {
                    articleFindMany: {
                        pages: pages || 1,
                        count: count || 1,
                        items: [ data.articleCreate, ...(items ?? []) ],
                    },
                },
            });
        },
        onCompleted: ({ articleCreate: { id } }) => {
            Router.pushPage(
                '/admin/article/:articleId([0-9]+)',
                { articleId: `${id}` },
            );
        },
    });

    const createArticle = () => {
        return _createArticle({
            variables: {
                article: {
                    slug: null,
                    title: t('newStatement'),
                },
            },
        });
    };

    /** Update article */
    const [ _updateArticle, {
        loading: updateArticleLoading,
        error: updateArticleError,
    } ] = useArticleUpdateMutation();

    const updateArticle = (
        article: UpdateArticleInput,
    ) => {
        if (articleId) {
            return _updateArticle({
                variables: {
                    articleId,
                    article,
                },
            });
        }
    };

    return {
        createArticle,
        createArticleLoading,
        updateArticle,
        updateArticleLoading,
        updateArticleError,
        setArticleBlockEditing,
    };
};
