/**
 * StickyButton component
 *
 * @author: exode <hello@exode.ru>
 */

import React, { ReactElement } from 'react';

import styles from './StickyButton.module.scss';


interface Props {
    children: ReactElement;
    isTab?: boolean;
    className?: string;
    disabled?: boolean;
}


const StickyButton = (props: Props) => {

    const {
        children,
        isTab = false,
        disabled = false,
    } = props;

    return (
        <div className={[
            props.className,
            ...(!disabled
                    ? [
                        'mt-6 -ml-[0.04em] -mr-[0.04em]',
                        isTab ? styles.stickyButtonAtTab : styles.stickyButton,
                    ]
                    : []
            ),
        ].join(' ')}>
            {children}
        </div>
    );
};


export { StickyButton };
