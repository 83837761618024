/**
 * PhotoEditorModal
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import React, { useLayoutEffect, useRef } from 'react';

import { Cropper, CropperRef } from 'react-mobile-cropper';

import { PhotoEditorModalStore } from './store';
import { ConfigStore } from '@/store/core/config';
import { observer, useStore } from '@/pages/Core';

import { Modal } from '@/cutils';
import { FileUtil } from '@/utils';
import { Router } from '@/services/Utils/Router';

import { Container } from './PhotoEditor.styled';
import { ButtonPart } from './parts/ButtonPart';


interface Props {
    id: string;
}


const PhotoEditorModal = observer((props: Props) => {

    const { store } = useStore(PhotoEditorModalStore);

    const { cropper, onSubmit } = store.photoEditorProps || {};

    const cropperRef = useRef<CropperRef>();

    const handleSubmit = () => {
        const cropper = cropperRef.current;
        const canvas = cropper?.getCanvas();

        if (canvas) {
            const base64Data = canvas.toDataURL();

            const file = FileUtil.getFileByBase64(base64Data);

            onSubmit?.(file);

            Router.replaceModal();
        }
    };

    useLayoutEffect(() => {
        if (!_.isFunction(onSubmit)) {
            Router.replaceModalWithTimeout().then();
        }

        return () => {
            if (cropper.src) {
                URL.revokeObjectURL(cropper.src);
            }

            store.resetPhotoEditor();
        };
    }, []);

    return (
        <Container>
            <Modal.Card disableManualClose id={props.id} className="modal:w-850">
                <Modal.Body fullHeight={ConfigStore.isDesktop} className="p-0 relative">
                    <Cropper {...cropper} ref={cropperRef} className="cropper"/>

                    <ButtonPart handleSubmit={handleSubmit}/>
                </Modal.Body>
            </Modal.Card>
        </Container>
    );
});


export { PhotoEditorModal };
