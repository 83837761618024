/**
 * StackedBlocks styled container
 *
 * @author: exode <hello@exode.ru>
 */

import styled from 'styled-components';


const StackedBlocks = styled.div`
    [data-component="StatusBanner"] {
        .Banner__in {
            &:before {
                box-shadow: none !important;
                border: 1px solid var(--image_border, var(--vkui--color_image_border_alpha)) !important;
            }
        }

        &:not(:first-child) {
            .Banner__in,
            [data-component="GradientBanner"] {
                border-top-left-radius: 0;
                border-top-right-radius: 0;
            }
        }

        &:not(:last-child) {
            .Banner__in,
            [data-component="GradientBanner"] {
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
            }

            .Banner__in {
                &:before {
                    border-bottom: none !important;
                }
            }
        }
    }
`;


export { StackedBlocks };
