/**
 * SchoolHeaderView
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { observer, SchoolStore } from '@/store/platform';

import { If } from '@/cutils';
import { Parse } from '@/utils';
import { ComponentHelper } from '@/helpers/react';

import { StartHeader } from '@/components/Landing/StartHeader';


const SchoolHeaderView = observer(() => {
    return (
        <StartHeader alignment="center"
                     appearance="light-content"
                     titleProps={{ weight: '1' }}
                     className="d:min-h-[384px] text-center"
                     subtitle={(
                         <span style={{ lineHeight: '120%' }}
                               className="block d:fs-24 m:fs-20 d:mt-5 mt-3 m:mb-7"
                               dangerouslySetInnerHTML={{
                                   __html: ComponentHelper.sanitizeHtml(
                                       Parse.nlToBr(SchoolStore.blocks?.catalog?.subtitle || ''),
                                   ),
                               }}/>
                     )}
                     title={(
                         <If is={!!SchoolStore.blocks?.catalog?.title}>
                             <div style={{ lineHeight: '120%' }}
                                  className="flex flex-col w-full d:fs-50 m:fs-30 m:text-center">
                                 <span className="bg-content-15--blur vk-rounded p-2 px-3">
                                     {SchoolStore.blocks?.catalog?.title}
                                 </span>
                             </div>
                         </If>
                     )}/>
    );
});


export { SchoolHeaderView };
