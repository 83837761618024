/**
 * ManageSchoolAcquiringConnectModal
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { Title } from '@exode.ru/vkui';

import { Page } from '@/pages/Core';
import { useI18n } from '@/hooks/core';
import { Graphql, If, Modal } from '@/cutils';
import { useStableModalParams } from '@/router/index';

import { SchoolAcquiringFindOneQuery } from '@/codegen/graphql';

import { AcquiringFormView } from '@/pages/Manage/School/Acquiring/views/AcquiringFormView';


interface Props {
    id: string;
}


const ManageSchoolAcquiringConnectModal = (props: Props) => {

    const { t } = useI18n('modals.Manage.School.SchoolAcquiringConnect');

    const [ { acquiringId } ] = useStableModalParams();

    return (
        <Modal.Card id={props.id}
                    className="modal:w-550"
                    subheader={acquiringId ? t('editPaymentProviderForm') : t('fillPaymentProviderForm')}
                    header={(
                        <Title className="modal-title" level="2" weight="3">
                            {acquiringId ? t('editingAcquiring') : t('connectingAcquiring')}
                        </Title>
                    )}>
            <Modal.Body isScrollable fullHeight className="min-h-[450px]">
                <If is={!acquiringId}>
                    <AcquiringFormView/>
                </If>

                <If is={!!acquiringId}>
                    <SchoolAcquiringFindOneQuery children={(result) => (
                        <>
                            <Graphql.Loading result={result}>
                                <Page.Spinner/>
                            </Graphql.Loading>

                            <Graphql.Success result={result}>
                                {({ schoolAcquiringFindOne: acquiring }) => (
                                    <AcquiringFormView acquiring={acquiring}/>
                                )}
                            </Graphql.Success>

                            <Graphql.Error result={result}/>
                        </>
                    )} variables={{ acquiringId: +acquiringId }}/>
                </If>
            </Modal.Body>
        </Modal.Card>
    );
};


export { ManageSchoolAcquiringConnectModal };
