/**
 * FooterLegalPart component
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { IS_MARKETPLACE } from '@/root/src/env';

import { observer } from '@/store/core/config';
import { PlatformStore } from '@/store/platform';

import { Link } from '@/cutils';
import { useI18n } from '@/hooks/core';

import { Caption } from '@exode.ru/vkui';


const FooterLegalPart = observer(() => {

    const { COMMON, legalDocs } = PlatformStore;

    const { t } = useI18n('components.Navigation.Block.Footer.parts');

    return (
        <div className="flex m:flex-col flex-wrap gap-5 mb-3 mt-1 d:pr-11 pb-safe-1">
            <Link disabled={!IS_MARKETPLACE} pushPage={{ id: '/about' }}>
                <Caption className="text-secondary flex items-center">
                    <span className="mr-1">©</span>

                    <span>
                        {[
                            COMMON?.organization.info.name,
                            new Date().getFullYear(),

                            COMMON?.organization.info.inn && ([
                                '•',
                                t('inn'),
                                COMMON?.organization.info.inn,
                            ].join(' ')),
                        ].filter((e) => e).join(' ')}
                    </span>
                </Caption>
            </Link>

            {legalDocs?.list?.map(({ name, location }, index) => (
                <Link key={index} blank toOuter={location}>
                    <Caption className="text-secondary">
                        {name}
                    </Caption>
                </Link>
            ))}
        </div>
    );
});


export { FooterLegalPart };
