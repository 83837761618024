/**
 * SubjectCategorySelectionCard styled container
 *
 * @author: exode <hello@exode.ru>
 */

import styled from 'styled-components';

import { SubjectCategorySelectionCardProps } from './SubjectCategorySelectionCard';


export const Wrapper = styled.div<SubjectCategorySelectionCardProps>`
  .ContentCard__body {
    border-radius: 8px;
    box-shadow: ${props => props.active ? 'inset 0 0 0 2px var(--accent)' : ''}
  }
`;
