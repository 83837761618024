/**
 * UseChatMember
 *
 * @author: exode <hello@exode.ru>
 */

import { useApolloClient } from '@apollo/client';

import { ChatDialogsPageStore } from '@/pages/Chat/Dialog/store';

import {
    ChatFindManyDocument,
    ChatFindManyQuery,
    ChatMemberEntity,
    UpdateMemberChatInput,
    useChatMemberDeleteMutation,
    useChatMemberToggleBlockPersonalMutation,
    useChatMemberUpdateMutation,
} from '@/codegen/graphql';

import { useStore } from '@/pages/Core';
import { Router } from '@/services/Utils/Router';
import { useBatchUpdateCacheFields } from '@/hooks/core';


export const useChatMember = () => {

    const { cache } = useApolloClient();

    const { list, filter, sort } = useStore(ChatDialogsPageStore);

    const [ _updateChatMember, { loading: updateChatMemberLoading } ] = useChatMemberUpdateMutation({
        onError: error => console.error(error),
    });

    const updateChatMember = async (
        chatId: number,
        member: UpdateMemberChatInput,
    ) => {
        await _updateChatMember({
            variables: { chatId, member },
            update(cache, { data }) {
                data && cache.modify({
                    id: `ChatMemberEntity:${data.chatMemberUpdate.id}`,
                    fields: useBatchUpdateCacheFields<ChatMemberEntity>(data.chatMemberUpdate),
                });

                if (data?.chatMemberUpdate.deletedAt) {
                    filterDeletedChat(chatId);

                    Router.replacePage('/chat');
                }
            },
        });
    };

    const [ _deleteChatMember, {
        loading: deleteChatMemberLoading,
    } ] = useChatMemberDeleteMutation({
        onError: error => console.error(error),
    });

    const deleteChatMember = async (chatId: number) => {
        return _deleteChatMember({
            variables: { chatId },
            update(__, { data }) {
                if (data?.chatMemberDelete) {
                    filterDeletedChat(chatId);

                    Router.replacePage('/chat');
                }
            },
        });
    };

    const filterDeletedChat = (chatId: number) => {
        const variables = {
            list: { ...list.chats },
            filter: { ...filter.chats },
            sort: { ...sort.chats },
        };

        const { chatFindMany } = cache.readQuery<ChatFindManyQuery>({
            query: ChatFindManyDocument,
            variables,
        }) || {};

        if (!chatFindMany) {
            return console.warn('cached Chat not found');
        }

        cache.writeQuery<ChatFindManyQuery>({
            query: ChatFindManyDocument,
            variables,
            data: {
                chatFindMany: {
                    __typename: 'ListChatOutput',
                    count: chatFindMany?.count,
                    cursor: chatFindMany?.cursor,
                    items: chatFindMany?.items?.filter(({ id }) => id !== chatId),
                },
            },
        });
    };

    const [
        _blockChatMemberPersonal,
        { loading: blockChatMemberPersonalLoading },
    ] = useChatMemberToggleBlockPersonalMutation({
        onError: error => console.error(error),
    });

    const blockChatMemberPersonal = async (
        chatId: number,
        companionUserId: number,
        blocked: boolean,
    ) => {
        await _blockChatMemberPersonal({
            variables: {
                chatId,
                companionUserId,
                blocked,
            },
            update(cache, { data }) {
                if (data) {
                    cache.modify({
                        id: cache.identify({
                            __typename: `ChatMemberEntity`,
                            id: data?.chatMemberToggleBlockPersonal.id,
                        }),
                        fields: useBatchUpdateCacheFields<ChatMemberEntity>(data.chatMemberToggleBlockPersonal),
                    });
                }
            },
        });
    };

    return {
        updateChatMember,
        updateChatMemberLoading,
        deleteChatMember,
        deleteChatMemberLoading,
        blockChatMemberPersonal,
        blockChatMemberPersonalLoading,
    };
};
