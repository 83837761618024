/**
 * ImageLoader component
 *
 * @author: exode <hello@exode.ru>
 */

import React, { ReactElement, useEffect, useRef, useState } from 'react';

import { Time } from '@/utils';
import { If } from '@/cutils';


interface Props {
    src: string;
    loaded: ReactElement;
    placeholder: ReactElement;
    onLoad?: () => void;
    wrapperClassName?: string;
}


const loadedCache: string[] = [];


const ImageLoader = (props: Props) => {

    const {
        src,
        loaded,
        onLoad,
        placeholder,
        wrapperClassName,
    } = props;

    const alreadyLoaded = loadedCache.includes(src);
    const [ isLoaded, setIsLoaded ] = useState(alreadyLoaded);

    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const img = new Image();

        img.onload = async () => {
            onLoad?.();

            if (isLoaded) {
                return;
            }

            ref.current && (ref.current.className += ' opacity-0');

            loadedCache.push(src);

            await Time.timer(500);

            setIsLoaded(true);
        };

        img.onerror = () => setIsLoaded(false);
        img.src = src;

        return () => {
            img.onload = null;
            img.onerror = null;
        };
    }, [ src ]);

    return (
        <div className={[ 'flex relative', wrapperClassName ].join(' ')}>
            {loaded}

            <If is={!alreadyLoaded && !isLoaded}>
                <div ref={ref}
                     className={[ 'absolute z-0 transition-opacity duration-500', wrapperClassName ].join(' ')}>
                    {placeholder}
                </div>
            </If>
        </div>
    );
};


export { ImageLoader };
