/**
 * MathRendererPart
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

// @ts-ignore @aslan
import { InlineMath } from 'react-katex';


const MathRendererPart = ({ data }: { data: { text: string } }) => {
    return (
        <span style={{ fontSize: 'var(--vkui--font_text--font_size--regular)' }}>
            <InlineMath math={data.text}/>
        </span>
    );
};


export { MathRendererPart };
