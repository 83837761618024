/**
 * Gradient constants
 *
 * @author: exode <hello@exode.ru>
 */

export const gradient = {
    lime600ToGreen400: 'from-lime-600 to-green-400',
    orange600ToRed500: 'from-orange-600 to-red-600',
    purple500ToBlue500: 'from-purple-500 to-blue-500',
    blue400ToBlue700Gradient: 'from-blue-400 to-blue-700',
    blue600ToPurple500Gradient: 'from-blue-600 to-purple-500 dark:from-blue-400 dark:to-purple-500',
    gray500ToGray400: 'from-gray-500 dark:from-gray-600 to-gray-400 dark:to-gray-500',
    green500ToLime600: 'from-green-500 dark:from-green-600 to-lime-600 dark:to-lime-600',
};
