/**
 * ManageSellerSelectPlaceholder
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { If } from '@/cutils';
import { IconSquare } from '@/images/icons';
import { Avatar, Spinner } from '@exode.ru/vkui';


interface Props {
    isAdmin: boolean;
    isSeller: boolean;
    hasSelectedSeller: boolean;
    loading?: boolean;
}


const ManageSellerLogoPlaceholder = (props: Props) => {

    const { isAdmin, isSeller, hasSelectedSeller, loading } = props;

    return (
        <>
            <If is={!!loading}>
                <Avatar shadow
                        size={32}
                        mode="app"
                        alt="IconSquare"
                        className="flex mt-2 mb-2 mx-auto rounded-full bg-[#ffffff25]">
                    <Spinner/>
                </Avatar>
            </If>

            <If is={!loading}>
                <Avatar shadow mode="app" size={32} alt="IconSquare" src={IconSquare} className={[
                    'flex mt-2 mb-2 mx-auto rounded-full',
                    (isAdmin || isSeller || hasSelectedSeller)
                        ? 'cursor-pointer'
                        : 'cursor-default',
                ].join(' ')}/>
            </If>
        </>
    );
};


export { ManageSellerLogoPlaceholder };
