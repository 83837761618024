/**
 * SubjectsSelectView
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { observer, useStore } from '@/pages/Core';

import { Subjects } from '@/types/subject';

import { Router } from '@/services/Utils/Router';

import { TournamentSubjectSelectStore } from '@/modals/Tornament/SubjectSelect/store';

import { AdaptiveSelect, Option } from '@/components/Atoms/AdaptiveSelect';


interface Props {
    subjects: Subjects;
}


const SubjectsSelectView = observer((props: Props) => {

    const { input } = useStore(TournamentSubjectSelectStore);

    const { subjects } = props;

    const adaptiveSelectProps = {
        isTab: true,
        value: input.subjects,
        multiple: {},
        isActionSheets: true,
        buttonText: 'Применить',
        useInModal: false,
        options: subjects.map(({ subject, name }) => ({
            value: subject,
            label: name,
            disabled: false,
        })),
        callback: (selected: Option[]) => {
            const subjects = selected.map(item => item.value);

            TournamentSubjectSelectStore.mergeInput({ subjects });

            Router.pushPage(
                '/tournaments/:page([0-9]+)',
                { subjects: subjects.join() },
            );
        },
    };

    return (<AdaptiveSelect {...adaptiveSelectProps} />);
});


export { SubjectsSelectView };
