/**
 * UseTournamentListenNewMember
 *
 * @author: exode <hello@exode.ru>
 */

import { useEffect } from 'react';

import { GqlResult } from '@/types/graphql';
import { useApolloClient } from '@apollo/client';

import {
    TournamentListenNewMembersSubscriptionResult,
    TournamentMemberEntity,
    useTournamentListenNewMembersSubscription,
} from '@/codegen/graphql';


interface IncomingData {
    tournamentListenNewMember: GqlResult<TournamentListenNewMembersSubscriptionResult>['tournamentListenNewMember'];
}


export const useTournamentListenNewMember = (tournamentId: number) => {

    const { cache } = useApolloClient();

    const { data } = useTournamentListenNewMembersSubscription({
        variables: { tournamentId },
        onError: error => console.error(error),
    });

    const updateTournamentMembers = (data?: IncomingData) => {
        const newMember = data?.tournamentListenNewMember.user;

        newMember && cache.modify({
            id: `TournamentEntity:${tournamentId}`,
            fields: {
                countMembers: () => data?.tournamentListenNewMember.tournament.countMembers,
                lastMembers: (value: TournamentMemberEntity[]) => {
                    const cachedMembers = value.map((item) => item?.id);

                    if (!cachedMembers.includes(newMember.id)) {
                        return [ ...value, newMember ];
                    }

                    return value as any;
                },
            },
        });
    };

    useEffect(() => {
        updateTournamentMembers(data);
    }, [ data ]);
};
