/**
 * SetOtpAuthInput
 *
 * @author: exode <hello@exode.ru>
 */

import { IsNotEmpty, IsString, MaxLength, MinLength } from 'class-validator';

import { isMinMax, isNotEmpty } from '../constants';


export class SetOtpAuthInput {

    @IsString()
    @MinLength(6, { message: isMinMax(6, 'Код', 'equal') })
    @MaxLength(6, { message: isMinMax(6, 'Код', 'equal') })
    @IsNotEmpty({ message: isNotEmpty('Код') })
    readonly otp: string;
}
