/**
 * FriendsConfirmedView
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { Page } from '@/pages/Core';

import { If, Link } from '@/cutils';
import { useI18n } from '@/hooks/core';
import { GqlResult } from '@/types/graphql';
import { ListFriendsByStatusQueryResult } from '@/codegen/graphql';

import { Header } from '@exode.ru/vkui';
import { Icon20UsersOutline } from '@vkontakte/icons';

import { RoutePathType } from '@/router/paths';

import { User } from '@/components/Atoms/User';
import { Placeholder } from '@/components/Atoms/Placeholder';


interface Props {
    friends: GqlResult<ListFriendsByStatusQueryResult>['friendshipFindMany'];
    pushPageId?: RoutePathType;
}


const FriendsConfirmedView = (props: Props) => {

    const {
        friends: { items },
        pushPageId = '/@:userId([0-9_A-Za-z]+)',
    } = props;

    const { t } = useI18n('pages.Profile.Friends');

    return (
        <Page.Row>
            <Header mode="secondary">
                {t('myFriends')}
            </Header>

            {items?.map(({ id, profile, lastOnlineAt }) => (
                <Link key={id} pushPage={{ id: pushPageId, params: { userId: `${id}` } }}>
                    <User.Cell mode="simple"
                               userId={id}
                               avatarSize={48}
                               className="my-2"
                               profile={profile}
                               lastOnlineAt={lastOnlineAt}/>
                </Link>
            ))}

            <If is={!items?.length}>
                <Placeholder iconSize={120}
                             innerPadding="1em"
                             header={t('friendsAreNotFound')}
                             icon={<Icon20UsersOutline className="text-accent opacity-[0.85]"/>}
                             content={(
                                 <span className="m:w-full w-9/10 flex mx-auto">
                                     {t('friendsWillBeDisplayedHere')}
                                 </span>
                             )}/>
            </If>
        </Page.Row>
    );
};


export { FriendsConfirmedView };
