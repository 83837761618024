/**
 * AmountText component
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import React from 'react';

import { observer, PlatformStore } from '@/store/platform';

import { NumberUtil } from '@/utils';
import { If, pointedNumber } from '@/cutils';

import { Skeleton, SkeletonProps } from '@mui/material';

import { productCurrency } from '@/types/product';
import { ProductCurrency, ProductPriceExchangeFragmentFragment } from '@/codegen/graphql';


interface Props {
    amount: number;
    currency?: ProductCurrency | null;
    dataTest?: string;
    className?: string;
    toFixed?: 0 | 2;
    roundPrecision?: 0 | 2;
    unsigned?: boolean;
    semibold?: boolean;
    isGreen?: boolean;
    isRed?: boolean;
    colorByAmount?: boolean;
    isLoading?: boolean;
    toFixedDynamic?: boolean;
    skeletonProps?: SkeletonProps;
    exchange?: NonNullable<ProductPriceExchangeFragmentFragment['amountExchangeRates']>['amount'] | null;
}

const amountText = (
    amount: Props['amount'],
    roundPrecision: Props['roundPrecision'] = 2,
    toFixed: Props['toFixed'] = 0,
) => {
    return NumberUtil.splitByDecimal(_.round(amount, roundPrecision), toFixed);
};


const AmountText = observer((props: Props) => {

    const { COMMON } = PlatformStore;

    /** If defined exchange and marketplace payment currency: */
    const currency = props.exchange && COMMON?.organization.settings.currency || props.currency;
    const amountByExchange = props.exchange?.[currency as keyof typeof props.exchange];

    const amount = pointedNumber(amountByExchange || props.amount);

    const {
        dataTest,
        className,
        skeletonProps,
        isRed = false,
        isGreen = false,
        unsigned = true,
        semibold = true,
        isLoading = false,
        roundPrecision = 2,
        colorByAmount = false,
        toFixedDynamic = true,
        toFixed = toFixedDynamic ? (_.isInteger(+amount) ? 0 : 2) : 0,
    } = props;

    const colorIsGreen = isGreen || colorByAmount && amount > 0;
    const colorIsRed = isRed || colorByAmount && amount < 0;

    return (
        <span data-test={dataTest} data-value={amount} className={[
            className,
            semibold ? 'font-semibold' : '',
            colorIsGreen ? 'text-positive' : '',
            colorIsRed ? 'text-destructive' : '',
        ].join(' ')}>
            <If is={!isLoading}>
                <If is={!unsigned}>
                    <>{amount > 0 ? '+' : ''}</>
                </If>

                <>{amountText(amount, roundPrecision, toFixed)}</>

                <>{currency && ` ${productCurrency[currency]}`}</>
            </If>

            <If is={isLoading}>
                <Skeleton variant="rectangular" className="rounded-[6px]" {...skeletonProps}/>
            </If>
        </span>
    );
});


export { AmountText, amountText };
