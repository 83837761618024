/**
 * StudyTab
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import React from 'react';

import { Page } from '@/pages/Core';
import { useI18n } from '@/hooks/core';
import { Graphql, If } from '@/cutils';
import { ProductAccessFindManyQuery } from '@/codegen/graphql';

import { Icon28EducationOutline } from '@vkontakte/icons';

import { Placeholder } from '@/components/Atoms/Placeholder';

import { ProductAccessListView } from '../ProductAccessListView';


interface Props {
    userId: number;
}


const StudyTab = (props: Props) => {

    const { userId } = props;

    const { t } = useI18n('pages.Manage.School.Users.views.EditUserFormView');

    return (
        <ProductAccessFindManyQuery children={(result) => (
            <>
                <Graphql.Loading result={result}>
                    <Page.Spinner/>
                </Graphql.Loading>

                <Graphql.Success result={result}>
                    {({ productAccessFindMany: { items: accesses } }) => (
                        <>
                            <ProductAccessListView accesses={accesses || []}/>

                            <If is={_.isEmpty(accesses)}>
                                <Placeholder iconSize={72} header={t('accessesNotFound')} icon={(
                                    <Icon28EducationOutline className="pt-0 text-accent opacity-[0.85]"/>
                                )} content={(
                                    <span className="m:w-full w-9/10 flex mx-auto">
                                        {t('accessesWillAppearHere')}
                                    </span>
                                )}/>
                            </If>
                        </>
                    )}
                </Graphql.Success>
            </>
        )} variables={{ list: {}, filter: { userIds: [ userId ] } }}/>
    );
};


export { StudyTab };
