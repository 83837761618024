/**
 * IconPart
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { SvgComponent } from '@/cutils';

import { sellerBalanceOperationCategory, SellerBalanceOperationItem } from '@/types/seller';

import { Avatar } from '@exode.ru/vkui';


interface Props {
    operation: SellerBalanceOperationItem;
    size?: number;
    iconSize?: number;
}


const IconPart = (props: Props) => {

    const sellerBalanceOperationCategories = sellerBalanceOperationCategory();

    const { operation: { source }, size = 48, iconSize = 28 } = props;

    return (
        <Avatar size={size} badge={(
            <div className={[
                'bg-gradient-to-br p-0.5 rounded-full',
                sellerBalanceOperationCategories[source].gradient,
            ].join(' ')}>
                <SvgComponent element={sellerBalanceOperationCategories[source].badge}
                              svgProps={{ width: 16, height: 16, className: 'text-white' }}/>
            </div>
        )} className={[
            'bg-gradient-to-r',
            sellerBalanceOperationCategories[source].gradient,
        ].join(' ')}>
            <SvgComponent element={sellerBalanceOperationCategories[source].icon}
                          svgProps={{ width: iconSize, height: iconSize, fill: 'var(--white)' }}/>
        </Avatar>
    );
};


export { IconPart };
