/**
 * WhiteScheme styled container
 *
 * @author: exode <hello@exode.ru>
 */

import { ReactElement } from 'react';

import styled from 'styled-components';


interface Props {
    children: ReactElement | ReactElement[];
    className?: string;
}


const Wrapper = styled.div`
  body[scheme=space_gray] & {
    --background_content: #f2f3f5;
    --modal_card_background: #f2f3f5;
  }
`;

const WhiteScheme = (props: Props) => {

    const { children, className } = props;

    return (
        // @ts-ignore @aslan
        <div scheme="bright_light" className={className}>
            <Wrapper className={className}>
                {children}
            </Wrapper>
        </div>
    );
};


export { WhiteScheme };
