/**
 * QuotePart
 *
 * @author: exode <hello@exode.ru>
 */

import { If } from '@/cutils';

import { Icon24QuoteClosing } from '@vkontakte/icons';

import { InnerHtml } from '@/components/Atoms/InnerHtml';


const QuotePart = ({ data }: any) => {

    const { text, caption } = data;

    return (
        <If is={!!text}>
            <div className="flex flex-col my-4">
                <div className="flex justify-center gap-2.5">
                    <Icon24QuoteClosing fill="var(--accent)"
                                        className="shrink-0"
                                        style={{ transform: 'rotate(180deg)' }}/>

                    <InnerHtml content={text} className="min-w-[1px]"/>
                </div>

                <If is={!!caption}>
                    <div className="flex justify-end">
                        <InnerHtml content={caption} className="min-w-[1px] font-medium italic"/>
                    </div>
                </If>
            </div>
        </If>
    );
};


export { QuotePart };
