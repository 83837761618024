/**
 * TournamentCardInfoModal
 *
 * @author: exode <hello@exode.ru>
 */

import React, { useState } from 'react';

import { useStableModalParams } from '@/router/index';

import { TournamentFindOneQuery } from '@/codegen/graphql';

import { Graphql, Modal } from '@/cutils';

import { Title } from '@exode.ru/vkui';
import { TournamentCardInfoSkeleton } from '@/components/Atoms/Skeleton/TournamentCardInfo';

import { ContentView } from './views/ContentView';
import { TournamentCardInfoInitialParts } from './views/SetPartsView';


interface Props {
    id: string;
}


const TournamentCardInfoModal = (props: Props) => {

    const [ { tournamentId } ] = useStableModalParams();

    const [ parts, setParts ] = useState(TournamentCardInfoInitialParts);

    return (
        <Modal.Card id={props.id}
                    icon={parts.icon}
                    actionsLayout="vertical"
                    actions={parts.actions}
                    subheader={parts.subheader}
                    header={parts.header && (
                        <Title className="modal-title" level="2" weight="3">
                            {parts.header}
                        </Title>
                    )}>
            <Modal.Body>
                <TournamentFindOneQuery children={(result) => (
                    <>
                        <Graphql.Loading result={result}>
                            <TournamentCardInfoSkeleton/>
                        </Graphql.Loading>

                        <Graphql.Success result={result}>
                            {({ tournamentFindOne: tournament }) => (
                                <ContentView setParts={setParts} tournament={tournament}/>
                            )}
                        </Graphql.Success>

                        <Graphql.Error result={result}/>
                    </>
                )} variables={{ tournamentId: +tournamentId }} skip={!tournamentId}/>
            </Modal.Body>
        </Modal.Card>
    );
};


export { TournamentCardInfoModal };
