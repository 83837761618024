/**
 * InnerHtml
 *
 * @author: exode <hello@exode.ru>
 */

import React, { HTMLAttributes } from 'react';

import { useRedirect } from '@/cutils';
import { ComponentHelper } from '@/helpers/react';


export interface InnerHtmlProps extends HTMLAttributes<HTMLElement> {
    content?: string;
    include?: ('nlToBr' | 'timeCodes')[];
    exclude?: ('fillLinks')[];
}


const InnerHtml = (props: InnerHtmlProps) => {

    const { content, include, exclude, ...rest } = props;

    const { redirect, handleInnerHtmlLinks } = useRedirect();

    return (
        <span {...rest} ref={(ref) => ref && handleInnerHtmlLinks(ref, (href) => redirect(href), true)}
              dangerouslySetInnerHTML={{
                  __html: ComponentHelper.commonParseContent(content, include, exclude),
              }}/>
    );
};


export { InnerHtml };
