/**
 * AppPageFallback component
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { useI18n } from '@/hooks/core';
import { observer, Page } from '@/pages/Core';

import { RouterStore } from '@/store/core/router';


const AppPageFallback = observer(() => {

    const { t } = useI18n('components.App.PageFallback');

    return (
        <>
            <Page.Head>
                <Page.Header title={RouterStore.type === 'iframe' ? '' : t('loading')}/>
            </Page.Head>

            <Page.Content>
                <Page.Spinner/>
            </Page.Content>

            <Page.Context>
                <Page.Spinner mode="skeleton" wrapperClassName="mb-4 !p-0 h-[60px]"/>

                <Page.Spinner mode="skeleton" wrapperClassName="mb-4 !p-0 h-[260px]"/>
            </Page.Context>
        </>
    );
});


export { AppPageFallback };
