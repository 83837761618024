/**
 * App notification subscriptions
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { Auth } from '@/cutils';

import { NotificationSubscription } from './NotificationSubscription';


const AppNotificationSubscriptions = () => {
    return (
        <>
            <Auth>
                <NotificationSubscription/>
            </Auth>
        </>
    );
};


export { AppNotificationSubscriptions };
