/**
 * TournamentCardInfoSkeleton component
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { Responsive } from '@/cutils';

import { Skeleton } from '@mui/material';


const TournamentCardInfoSkeleton = () => {
    return (
        <>
            <Responsive.Desktop>
                <div className="flex flex-col items-center pt-4">
                    <Skeleton className="mt-[-25px] mb-3" variant="circular" width={120} height={120}/>
                    <Skeleton className="mb-2" variant="text" width={140} height={35}/>
                    <Skeleton className="!mb-4" variant="text" width={120} height={25}/>

                    <div className="flex mb-2 gap-2">
                        <Skeleton variant="circular" width={32} height={32}/>
                        <Skeleton variant="circular" width={32} height={32}/>
                        <Skeleton variant="circular" width={32} height={32}/>
                    </div>

                    <Skeleton variant="text" className="text mb-6" width={190} height={70}/>

                    <Skeleton className="self-start ml-3 !mt-1.5" variant="text" width={200} height={72}/>
                    <Skeleton className="self-start ml-3 !mt-[-15px]" variant="text" width={200} height={72}/>

                    <Skeleton className="!mt-auto !mb-[-16px]" variant="text" width={408} height={70}/>
                    <Skeleton className="!mb-[-28px]" variant="text" width={408} height={70}/>
                </div>
            </Responsive.Desktop>

            <Responsive.Mobile>
                <div className="flex flex-col items-center pt-3.5">
                    <Skeleton className="mt-[-20px] mb-3" variant="circular" width={100} height={100}/>
                    <Skeleton className="mb-2" variant="text" width={140} height={35}/>
                    <Skeleton className="!mb-4" variant="text" width={120} height={25}/>

                    <div className="flex mb-6">
                        <Skeleton variant="circular" width={32} height={32}/>
                        <Skeleton variant="circular" width={32} height={32}/>
                        <Skeleton variant="circular" width={32} height={32}/>
                    </div>

                    <Skeleton variant="text" width={190} height={39}/>

                    <Skeleton className="self-start !mt-5" variant="text" width={200} height={72}/>
                    <Skeleton className="self-start !mt-[-15px]" variant="text" width={200} height={72}/>

                    <Skeleton className="!mt-auto !mb-[-16px] w-full" variant="text" height={75}/>
                    <Skeleton className="!mb-[-28px] w-full" variant="text" height={75}/>
                </div>
            </Responsive.Mobile>
        </>
    );
};


export { TournamentCardInfoSkeleton };
