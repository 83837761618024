/**
 * TutorExercisesPageStore store
 *
 * @author: exode <hello@exode.ru>
 */

import { makeObservable } from 'mobx';

import { observer, PageStore } from '@/pages/Core';

import { defaultFilter, dtoFilterTransformer } from './filter';


class ManageTasksListPage extends PageStore {

    constructor() {
        super();
        makeObservable(this);
    }

    state = {
        list: {
            skip: 0,
            take: 15,
        },
        filter: dtoFilterTransformer(defaultFilter),
    };

}

const ManageTasksListPageStore = new ManageTasksListPage();


export { observer, ManageTasksListPageStore };
