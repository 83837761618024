/**
 * DialogModalStore
 *
 * @author: exode <hello@exode.ru>
 */

import { ReactElement } from 'react';

import { observer } from 'mobx-react';
import { makeObservable, observable } from 'mobx';

import { ButtonProps } from '@exode.ru/vkui';

import { ModalStore } from '@/modals/Core/store';

import { ModalBodyProps } from '@/components/Atoms/ModalBody';
import { ModalCardProps } from '@/components/Atoms/ModalCard';


interface ConfirmModalPromptProps {
    placeholder: string;
    mode?: 'textarea' | 'input';
    required?: boolean;
    minLength?: number;
    maxLength?: number;
}

interface ConfirmModalOnConfirmProps {
    prompt?: string;
}

export interface ConfirmModalProps {
    title: string | null;
    subtitle: string | null;
    onConfirm: ((props: ConfirmModalOnConfirmProps) => void) | null;
    isLoading?: boolean;
    onCancel?: () => void;
    prompt?: ConfirmModalPromptProps;
    children?: ReactElement;
    modalCardProps?: Partial<ModalCardProps>;
    modalBodyProps?: Partial<ModalBodyProps>;
    buttons?: {
        cancel?: {
            text?: string;
            hidden?: boolean;
            props?: ButtonProps;
        }
        confirm?: {
            text?: string;
            hidden?: boolean;
            props?: ButtonProps;
        }
    };
}


const defaultConfirmModalProps: ConfirmModalProps = {
    title: '',
    subtitle: '',
    onConfirm: null,
};

class Confirmation extends ModalStore {

    constructor() {
        super();
        makeObservable(this);
    }

    @observable
    confirmModalProps: ConfirmModalProps = defaultConfirmModalProps;

    openConfirm(props: ConfirmModalProps) {
        this.confirmModalProps = props;
    }

    resetConfirm() {
        this.confirmModalProps = defaultConfirmModalProps;
    }
}

const ConfirmationModalStore = new Confirmation();


export { observer, ConfirmationModalStore };
