/**
 * EgeSubjectInfoModal
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { Modal } from '@/cutils';


interface Props {
    id: string;
}


const EgeSubjectInfoModal = (props: Props) => {
    return (
        <Modal.Card id={props.id}>
            <Modal.Body>
                <span>EgeSubjectInfo modal</span>
            </Modal.Body>
        </Modal.Card>
    );
};


export { EgeSubjectInfoModal };
