/**
 * Use native
 *
 * @author: exode <hello@exode.ru>
 */

import { NativeBridgeEvent } from '@/shared/types';


export interface NativeShareOpen {
    url: string,
    title?: string,
    message?: string,
}


export const sendToBridge = (message: NativeBridgeEvent) => {
    window.ReactNativeWebView?.postMessage?.(JSON.stringify(message));
};

export const useNative = () => {

    const shareOpen = (payload: NativeShareOpen) => {
        sendToBridge({
            payload,
            module: 'ShareModule',
            method: 'open',
        });
    };

    const triggerHaptic = (type?: string) => {
        sendToBridge({
            module: 'HapticFeedback',
            method: 'trigger',
            payload: { type },
        });
    };

    return {
        shareOpen,
        sendToBridge,
        triggerHaptic,
    };
};
