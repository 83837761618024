/**
 * TournamentCard styled container
 *
 * @author: exode <hello@exode.ru>
 */

import styled from 'styled-components';


export const tournamentCardRounded = '14px';

export const Container = styled.div`
  .Card {
    border-radius: ${tournamentCardRounded};
  }

  .pulseDot {
    opacity: 0.75;
    width: 8px;
    height: 8px;
    margin-top: 3px;
    background-color: var(--white);
    border-radius: 50%;
  }
`;
