/**
 * ManageSchoolDisciplinesModal
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { useI18n } from '@/hooks/core';

import { Modal } from '@/cutils';

import { Title } from '@exode.ru/vkui';

import { DisciplinesView } from '@/pages/Manage/School/Disciplines/views/DisciplinesView';


interface Props {
    id: string;
}


const ManageSchoolDisciplinesModal = (props: Props) => {

    const { t } = useI18n('modals.Manage.School.SchoolManageDisciplines');

    return (
        <Modal.Card id={props.id} className="modal:w-550" subheader={t('categoryCreateDescription')} header={(
            <Title className="modal-title" level="2" weight="3">
                {t('categoryCreate')}
            </Title>
        )}>
            <Modal.Body fullHeight isScrollable>
                <DisciplinesView/>
            </Modal.Body>
        </Modal.Card>
    );
};


export { ManageSchoolDisciplinesModal };
