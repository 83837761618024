/**
 * DatesPart
 *
 * @author: exode <hello@exode.ru>
 */

import React, { Dispatch, ReactElement, SetStateAction, useEffect } from 'react';

import { PageParams } from 'router.tsx';

import { ConfigStore } from '@/store/core/config';
import { Router } from '@/services/Utils/Router';
import { EgeSubjectDatesQueryResult } from '@/codegen/graphql';

import { Time } from '@/utils';
import { If, Link } from '@/cutils';
import { IS_VK } from '@/root/src/env';
import { useI18n } from '@/hooks/core';
import { GqlResult } from '@/types/graphql';

import { Button, CellButton, List } from '@exode.ru/vkui';
import { Icon28CalendarOutline } from '@vkontakte/icons';


interface Props {
    setParts: Dispatch<SetStateAction<{ actions: ReactElement[] } | undefined>>;
    subject: GqlResult<EgeSubjectDatesQueryResult>['subjectFindOne'];
    subjectCategoryId?: string;
}


const DatesPart = (props: Props) => {

    const {
        setParts,
        subjectCategoryId,
        subject: {
            activeForTournament,
            subject,
            egeSubjectInfo: { dates },
        },
    } = props;

    const { t } = useI18n('modals.Ege.SubjectDates');

    const untilWaves: { [key: string]: string } = {
        early: 'До досрочной волны',
        main: 'До основной волны',
        reserve: 'До резервной волны',
    };

    const getExamLeftText = (
        date: string,
        wave: keyof typeof untilWaves,
    ) => (
        Time.getDaysLeft(date) < 0
            ? 'Экзамен закончился'
            : `${untilWaves[wave]} ${t('days', { count: Time.getDaysLeft(date) })}`
    );

    const handleCreateTournament = async () => {
        const createParams = subjectCategoryId
            ? { create: subject, subjectCategoryId } as PageParams
            : { create: subject };

        if (ConfigStore.isDesktop) {
            Router.replacePage('/tournaments', createParams);

            Router.pushModal('tournament-create');
        } else {
            Router.replacePage('/exams-subject/:subject([a-z-]+)', { subject });

            Router.pushPage('/tournaments/create', createParams);
        }
    };

    useEffect(() => {
        setParts({
            actions: [
                <Button size="l"
                        stretched
                        mode="secondary"
                        key="create-tournament"
                        disabled={!activeForTournament}
                        onClick={handleCreateTournament}>
                    Создать турнир
                </Button>,

                <If is={!IS_VK} key="open-courses">
                    <Link pushPage={{
                        id: '/education',
                        params: {
                            subjects: JSON.stringify([ subject ]),
                        },
                    }}>
                        <Button stretched size="l" mode="primary">
                            Открыть курсы
                        </Button>
                    </Link>
                </If>,
            ],
        });
    }, []);

    return (
        <List className="mt-4">
            {dates?.early.map((date, index) => (
                <CellButton key={date + index}
                            before={<Icon28CalendarOutline/>}
                            subtitle={getExamLeftText(date, 'early')}>
                    {Time.parseRelative(date, 'D MMMM YYYY')}
                </CellButton>
            ))}

            {dates?.main.map((date, index) => (
                <CellButton key={date + index}
                            before={<Icon28CalendarOutline/>}
                            subtitle={getExamLeftText(date, 'main')}>
                    {Time.parseRelative(date, 'D MMMM YYYY')}
                </CellButton>
            ))}

            {dates?.reserve?.map((date, index) => (
                <CellButton key={date + index}
                            before={<Icon28CalendarOutline/>}
                            subtitle={getExamLeftText(date, 'reserve')}>
                    {Time.parseRelative(date, 'D MMMM YYYY')}
                </CellButton>
            ))}
        </List>
    );
};


export { DatesPart };
