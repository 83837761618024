/**
 * PartnerPart
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { ConfigStore } from '@/store/core/config';
import { UserAuthStore } from '@/store/user/auth';
import { ProfileStore } from '@/store/user/profile';
import { observer, SellerStore } from '@/store/user/seller';

import { useI18n } from '@/hooks/core';
import { Router } from '@/services/Utils/Router';
import { Auth, Graphql, If, Unauth } from '@/cutils';

import { useSellerContext } from '@/components/App/SellerProvider';

import {
    ProfileRole,
    SellerBecomeRequestFindOneByCurrentUserQuery,
    SellerBecomeRequestStatus,
} from '@/codegen/graphql';

import { SimpleCell } from '@exode.ru/vkui';

import {
    Icon20AddCircleFillGreen,
    Icon24ArrowRightCircleOutline,
    Icon24ClockOutline,
    Icon24StorefrontOutline,
    Icon28StorefrontOutline,
} from '@vkontakte/icons';


interface PartnerItemPartProps {
    placement: 'desktop' | 'mobile';
}


interface ItemCellProps extends PartnerItemPartProps {
    requestId?: number;
    onClick?: () => void;
    loading?: boolean;
}


const ItemCell = observer((props: ItemCellProps) => {

    const { t } = useI18n('components.Desktop.Navigation.Menu');

    const { requestId, onClick, loading } = props;

    const { isSeller } = useSellerContext();

    const hasRequest = requestId || isSeller;

    const defineIcon = (status: SellerBecomeRequestStatus) => {
        switch (status) {
            case SellerBecomeRequestStatus.AgreementChecking:
            case SellerBecomeRequestStatus.FormDataChecking:
                return <Icon24ClockOutline/>;

            case SellerBecomeRequestStatus.FormDataDeclined:
            case SellerBecomeRequestStatus.OnAgreementSigning:
            case SellerBecomeRequestStatus.AgreementDataDeclined:
                return <Icon24ArrowRightCircleOutline fill="var(--exode_orange)"/>;
        }
    };

    const handleClick = () => {
        requestId
            ? Router.pushPage('/manage/courses/:page([0-9]+)')
            : ConfigStore.isDesktop
                ? Router.pushModal('short-form-become-partner')
                : Router.pushPage('/become/partner');
    };

    return (
        <SimpleCell expandable
                    onClick={onClick || handleClick}
                    data-test="partner.become.button"
                    subtitle={hasRequest ? t('contentAndSales') : t('sellingYourContent')}
                    className={(
                        !hasRequest
                        && !loading
                        && UserAuthStore.isLoggedIn
                        && ProfileStore.role === ProfileRole.Tutor ? 'outline-active' : ''
                    )}
                    before={(
                        hasRequest ?
                            (
                                props.placement === 'mobile'
                                    ? <Icon28StorefrontOutline/>
                                    : <Icon24StorefrontOutline/>
                            )
                            : (
                                props.placement === 'mobile'
                                    ? <Icon20AddCircleFillGreen width={28} height={28}/>
                                    : <Icon20AddCircleFillGreen width={24} height={24}/>
                            )
                    )}
                    after={(
                        <If is={!!SellerStore.requestStatus}>
                            <>{defineIcon(SellerStore.requestStatus as SellerBecomeRequestStatus)}</>
                        </If>
                    )}>
            {hasRequest ? t('controlPanel') : t('createYourCourse')}
        </SimpleCell>
    );
});


const PartnerPart = (props: PartnerItemPartProps) => {
    return (
        <>
            <Auth>
                <SellerBecomeRequestFindOneByCurrentUserQuery children={(result) => (
                    <>
                        <Graphql.Loading result={result}>
                            <ItemCell loading {...props}/>
                        </Graphql.Loading>

                        <Graphql.Success result={result}>
                            {({ sellerBecomeRequestFindOneByCurrentUser: request }) => (
                                <ItemCell requestId={request?.id} {...props}/>
                            )}
                        </Graphql.Success>

                        <Graphql.Error result={result}>
                            <ItemCell {...props}/>
                        </Graphql.Error>
                    </>
                )}/>
            </Auth>

            <Unauth>
                <ItemCell {...props} onClick={() => {
                    Router.replaceToModalAfterLogin(
                        '/education',
                        'short-form-become-partner',
                    );
                }}/>
            </Unauth>
        </>
    );
};


export { PartnerPart };
