/**
 * AutoSaveFormStatus
 *
 * @author: exode <hello@exode.ru>
 */

import React, { ReactNode, useEffect, useRef, useState } from 'react';

import { SimpleCell } from '@exode.ru/vkui';

import { Router } from '@/cutils';
import { useI18n } from '@/hooks/core';
import { RoutePathType } from '@/router/paths';

import { Icon12ArrowUp, Icon12CheckCircle, Icon12Clock } from '@vkontakte/icons';

import { AutoSaveFormContainer } from '@/components/Atoms/ContextMenuItems/ContextMenuItems.styled';


interface Props {
    mode: 'save' | 'preview';
    pages: RoutePathType[];
    isSaving: boolean;
    isEditing?: boolean;
    title?: ReactNode;
    before?: ReactNode;
    after?: ReactNode;
    subtitle?: {
        editing?: ReactNode;
        saving?: ReactNode;
        saved?: ReactNode;
        preview?: ReactNode
    };
}


export const AutoSaveFormStatus = (props: Props) => {

    const {
        mode,
        pages,
        isSaving,
        title,
        before,
        after,
        subtitle,
        isEditing = false,
    } = props;

    const iconRef = useRef<HTMLDivElement>(null);
    const timeoutRef = useRef<NodeJS.Timeout>();

    const { t } = useI18n('components.Desktop.Manage');

    const [ animationInitialized, setAnimationInitialized ] = useState(false);

    const {
        preview,
        editing = (
            <div className="flex gap-1 items-center">
                <div><Icon12Clock fill="var(--dynamic_blue)"/></div>
                <span>{t('collectingChanges')}</span>
            </div>
        ),
        saving = (
            <div className="flex gap-1 items-center">
                <div><Icon12ArrowUp fill="var(--dynamic_orange)"/></div>
                <span>{t('saving')}</span>
            </div>
        ),
        saved = (
            <div className="flex items-center">
                <div ref={iconRef} className="bg-white rounded-full fixed z-[1]">
                    <Icon12CheckCircle fill="var(--dynamic_green)"/>
                </div>

                <span className="pl-[calc(0.25rem_+_12px)]">
                    {t('everythingIsSaved')}
                </span>
            </div>
        ),
    } = subtitle || {};

    useEffect(() => {
        if (animationInitialized && !isSaving) {
            iconRef.current?.classList.add('scale-up-animation');
            clearTimeout(timeoutRef?.current);

            timeoutRef.current = setTimeout(() => {
                iconRef.current?.classList.remove('scale-up-animation');
            }, 1000);
        }

        return () => {
            clearTimeout(timeoutRef?.current);
        };
    }, [ mode, isSaving, isEditing ]);

    useEffect(() => {
        setAnimationInitialized(true);
    }, [ isEditing ]);

    return (
        <Router pages={pages}>
            <AutoSaveFormContainer>
                <SimpleCell before={before}
                            after={after}
                            hasActive={false}
                            hasHover={false}
                            className="bg-content thin-border mb-4 pl-2"
                            subtitle={(
                                mode === 'save'
                                    ? (isSaving ? saving : (isEditing ? editing : saved))
                                    : preview
                            )}>
                    {title}
                </SimpleCell>
            </AutoSaveFormContainer>
        </Router>
    );
};
