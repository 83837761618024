/**
 * UnreadNotificationsView
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { NotificationsQuery, NotificationStatus, SortDirection } from '@/codegen/graphql';

import { Graphql } from '@/cutils';
import { NotificationsSkeleton } from '@/components/Atoms/Skeleton/Notifications';

import { NotificationsView } from './NotificationsView';


export const notificationListAndSortConfig = {
    list: { skip: 0, take: 15 },
    sort: { createdAt: SortDirection.Desc },
};

const UnreadNotificationsView = () => {
    return (
        <NotificationsQuery children={(result) => (
            <>
                <Graphql.Loading result={result}>
                    <NotificationsSkeleton count={3}/>
                </Graphql.Loading>

                <Graphql.Success result={result}>
                    {({ notificationFindMany: notifications }) => (
                        <NotificationsView isUnread notifications={notifications}/>
                    )}
                </Graphql.Success>

                <Graphql.Error result={result}/>
            </>
        )} variables={{
            ...notificationListAndSortConfig,
            filter: { statuses: [ NotificationStatus.Unread ] },
        }}/>
    );
};


export { UnreadNotificationsView };
