/**
 * ProgressLessonLineItem
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { ConfigStore, observer } from '@/store/core/config';

import { Link } from '@/cutils';
import { CourseService } from '@/services/Course/Course';

import { TextTooltip } from '@exode.ru/vkui/unstable';

import { ProgressItemProps } from './ProgressItem';


interface Props {
    course: ProgressItemProps['course'];
    lesson: ProgressItemProps['course']['lessons'][number];
    currentLesson: ProgressItemProps['course']['currentLesson'];
}


const ProgressLessonLineItem = observer((props: Props) => {

    const { course, lesson, currentLesson } = props;

    return (
        <Link key={lesson.id} stopPropagation disabled={!ConfigStore.isDesktop} pushPage={{
            id: '/courses/:page([0-9]+)/:courseId([0-9]+)/study/:lessonId([0-9]+)',
            params: { courseId: `${course.id}`, lessonId: `${lesson.id}` },
        }}>
            <div className="flex flex-1 overflow-hidden first:rounded-l-lg last:rounded-r-lg">
                <TextTooltip placement="bottom" offsetDistance={4} text={lesson.name}>
                    <div key={lesson.id} className={[
                        'flex flex-1 w-auto h-[8px]',
                        (currentLesson?.id !== lesson.id
                                ? (CourseService.lessonIsCompleted(lesson)
                                        ? 'bg-positive'
                                        : 'bg-[#0000002e]'
                                )
                                : (CourseService.lessonIsCompleted(lesson)
                                        ? 'bg-positive'
                                        : 'bg-accent'
                                )
                        ),
                    ].join(' ')}/>
                </TextTooltip>
            </div>
        </Link>
    );
});


export { ProgressLessonLineItem };
