/**
 * ProfileStatusSubscription component
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { ProfileStore } from '@/store/user/profile';
import { useBatchUpdateCacheFields } from '@/hooks/core';

import { ProfileEntity, useProfileListenTitleSubscription } from '@/codegen/graphql';


interface Props {
    profileId: number;
}


const ProfileStatusSubscription = (props: Props) => {

    const { profileId } = props;

    useProfileListenTitleSubscription({
        variables: { profileId },
        onData: ({ data: { data }, client: { cache } }) => {
            if (!data) {
                return;
            }

            cache.modify({
                id: `ProfileEntity:${profileId}`,
                fields: useBatchUpdateCacheFields<ProfileEntity>(data.profileListenNewState),
            });

            if (ProfileStore.id === profileId) {
                ProfileStore.merge({
                    ...data.profileListenNewState,
                });
            }
        },
    });

    return (<></>);
};


export { ProfileStatusSubscription };
