/**
 * UseTournamentProcess
 *
 * @author: exode <hello@exode.ru>
 */

import {
    SaveAnswerTaskInput,
    TournamentTasksDocument,
    TournamentTasksQuery,
    useFinishTournamentMutation,
    useSendTaskAnswerMutation,
} from '@/codegen/graphql';


export const useTournamentProcess = () => {

    const [ _sendTaskAnswer, { loading: sendTaskAnswerLoading } ] = useSendTaskAnswerMutation();
    const [ _finishTournament, { loading: finishTournamentLoading } ] = useFinishTournamentMutation();

    const sendTaskAnswer = async (
        tournamentId: number,
        answer: SaveAnswerTaskInput,
        currentTaskIndex: number,
    ) => {
        await _sendTaskAnswer({
            variables: {
                answer,
                tournamentId,
                currentTaskIndex,
            },
            update(cache, { data }) {
                const cachedResults = cache.readQuery<TournamentTasksQuery>({
                    query: TournamentTasksDocument,
                    variables: { tournamentId },
                });

                cachedResults && data && cache.writeQuery<TournamentTasksQuery>({
                    query: TournamentTasksDocument,
                    variables: { tournamentId },
                    data: {
                        tournamentFindOne: cachedResults.tournamentFindOne,
                        tournamentResultFindOne: data.tournamentProcessSaveTaskAnswer,
                    },
                });
            },
            onError(error) {
                console.error(error);
            },
        });
    };

    const finishTournament = async (tournamentId: number) => {
        await _finishTournament({
            variables: { tournamentId },
            update(cache, { data }) {
                const cachedResults = cache.readQuery<TournamentTasksQuery>({
                    query: TournamentTasksDocument,
                    variables: { tournamentId },
                });

                cachedResults && data && cache.writeQuery<TournamentTasksQuery>({
                    query: TournamentTasksDocument,
                    variables: { tournamentId },
                    data: {
                        ...cachedResults,
                        tournamentResultFindOne: {
                            ...data.tournamentResultFinishMyTournament,
                            answers: cachedResults.tournamentResultFindOne?.answers ?? [],
                        },
                    },
                });
            },
            onError(error) {
                console.error(error);
            },
        });
    };

    return {
        sendTaskAnswer,
        sendTaskAnswerLoading,
        finishTournament,
        finishTournamentLoading,
    };
};
