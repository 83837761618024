/**
 * Tournament types
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { GqlResult } from '@/types/graphql';

import {
    StartLookingForBlitzRivalMutationResult,
    TournamentException,
    TournamentListenStateSubscriptionResult,
    TournamentStartMode,
    TournamentType,
} from '@/codegen/graphql';

import { Icon24DonateOutline, Icon24GlobeOutline, Icon24LockOutline, Icon24Podcast } from '@vkontakte/icons';


/**
 * GraphQL types
 */
export type RivalEntity = NonNullable<StartLookingForBlitzRivalMutationResult['data']>['tournamentBlitzFindRival']['user']

export type TournamentStateChangeEvent = GqlResult<TournamentListenStateSubscriptionResult>['tournamentListenTournamentState']

/**
 * Tournament type name
 */
export const tournamentTypeName = {
    [TournamentType.Blitz]: 'Блиц турнир',
    [TournamentType.Public]: 'Открытый турнир',
    [TournamentType.Private]: 'Закрытый турнир',
    [TournamentType.Official]: 'Официальный турнир',
};
/**
 * Tournament start mode
 */
export const tournamentStartMode = {
    [TournamentStartMode.Event]: 'Как все готовы',
    [TournamentStartMode.Scheduled]: 'Запланированный',
};

/**
 * Tournament type icon
 */
export const tournamentTypeIcon = {
    [TournamentType.Blitz]: <Icon24DonateOutline width={26} height={26} className="cursor-pointer"/>,
    [TournamentType.Public]: <Icon24GlobeOutline className="cursor-pointer"/>,
    [TournamentType.Private]: <Icon24LockOutline className="cursor-pointer"/>,
    [TournamentType.Official]: <Icon24Podcast className="cursor-pointer"/>,
};

/**
 * Tournament exceptions
 */
export const tournamentExceptions: { [key in TournamentException]: string } = {
    [TournamentException.NoMoney]: 'Недостаточно средств',
    [TournamentException.AlreadyStarted]: 'Турнир уже начался',
    [TournamentException.MemberNotPlayer]: 'Участник не игрок',
    [TournamentException.InvalidInviteKey]: 'Неверный ключ приглашения',
    [TournamentException.AlreadyAppointed]: 'Вы уже участвуете',
    [TournamentException.MaxMembersExceeded]: 'Количество участников превышено',
    [TournamentException.NotAllowedCreateOfficial]: 'Не разрешено создавать официальные',
};
