/**
 * SetPartsView
 *
 * @author: exode <hello@exode.ru>
 */

import React, { Dispatch, SetStateAction, useEffect, useLayoutEffect } from 'react';

import { ConfigStore } from '@/store/core/config';
import { observer, UserAuthStore } from '@/store/user/auth';

import { GqlResult } from '@/types/graphql';
import { tournamentTypeIcon } from '@/types/tournament';
import { educationSubjectColors, educationSubjectImages, educationSubjects } from '@/types/subject';

import { If, SvgComponent } from '@/cutils';
import { Router } from '@/services/Utils/Router';
import { useTournamentLobby } from '@/hooks/apollo';
import { useStableModalParams } from '@/router/index';
import { TournamentService } from '@/services/Tournament/Tournament';

import { TournamentFindOneQueryResult, TournamentState } from '@/codegen/graphql';

import { Avatar, Button } from '@exode.ru/vkui';

import {
    Icon24ShareOutline,
    Icon24UserAddedOutline,
    Icon24UserAddOutline,
    Icon28HistoryForwardOutline,
    Icon28TrainOutline,
} from '@vkontakte/icons';

import { TextTooltip } from '@exode.ru/vkui/unstable';
import { AvatarChip } from '@/components/Atoms/AvatarChip';
import { SubjectCategoriesTooltip } from '@/components/Subject/CategoriesTooltip';


interface Props {
    setParts: Dispatch<SetStateAction<typeof TournamentCardInfoInitialParts>>;
    tournament: GqlResult<TournamentFindOneQueryResult>['tournamentFindOne'];
    setShowCountdown: Dispatch<SetStateAction<boolean>>;
}


export const TournamentCardInfoInitialParts = {
    header: '',
    subheader: <></>,
    icon: <></>,
    actions: [ <></>, <></> ],
};


const SetPartsView = observer((props: Props) => {

    const {
        setParts,
        setShowCountdown,
        tournament: {
            uuid,
            subject,
            isMember,
            state,
            type,
            accessByInviteKey,
            categories,
        },
    } = props;

    const canJoin = TournamentService.canJoin(state);

    const [ { tournamentId, inviteKey } ] = useStableModalParams();

    const {
        appointTournament,
        appointTournamentError,
        appointTournamentLoading,
    } = useTournamentLobby();

    const handleAppoint = () => {
        UserAuthStore.isLoggedIn
            ? appointTournament(
                +tournamentId,
                !accessByInviteKey ? String(uuid) : inviteKey,
                ({ isMember }) => setShowCountdown(!!isMember),
            )
            : Router.replaceToModalAfterLogin(
                '/tournaments',
                undefined,
                undefined,
                Router.routerParams,
            );
    };

    const handleInvite = () => {
        if (!UserAuthStore.isLoggedIn) {
            return Router.replaceToModalAfterLogin(
                '/tournaments',
                undefined,
                undefined,
                Router.routerParams,
            );
        }

        ConfigStore.isDesktop
            ? Router.pushModal('tournament-invite')
            : Router.pushPage('/tournament/friends/:tournamentId([0-9]+)', { tournamentId });
    };

    useEffect(() => {
        TournamentService.openAppropriatePageByState({
            state,
            id: tournamentId,
            isMember: !!isMember,
        }, 'replacePage');
    }, [ state, isMember ]);

    useLayoutEffect(() => {
        setParts({
            header: educationSubjects[subject],
            subheader: (
                <SubjectCategoriesTooltip categories={categories}>
                    <div className="w-fit mx-auto">
                        {TournamentService.getCategoryLength(categories.length)}
                    </div>
                </SubjectCategoriesTooltip>
            ),
            icon: (
                <Avatar shadow={true}
                        size={ConfigStore.isDesktop ? 120 : 100}
                        className={`relative mt-3 bg-gradient-to-br shadow-lg ${educationSubjectColors[subject]}`}
                        badge={(
                            <AvatarChip top={-14}
                                        left={-14}
                                        size={ConfigStore.isDesktop ? 24 : 20}
                                        className="bg-modal-content p-0.5 shadow dark:bg-blueGray-700">
                                <SvgComponent element={tournamentTypeIcon[type]} svgProps={{ height: 20, width: 20 }}/>
                            </AvatarChip>
                        )}>

                    <SvgComponent element={educationSubjectImages[subject]} svgProps={(
                        ConfigStore.isDesktop
                            ? { width: 70, height: 70 }
                            : { width: 55, height: 55 }
                    )}/>
                </Avatar>
            ),
            actions: [
                <div className="flex flex-col" key="action-buttons">
                    <If is={!!isMember}>
                        <TextTooltip placement="top" text="Лобби откроется за 20 минут до турнира">
                            <Button size="l"
                                    disabled
                                    className="cursor-default"
                                    before={<Icon28HistoryForwardOutline width={24} height={24}/>}>
                                <div className="flex gap-1">
                                    Огонь, ты участвуешь
                                </div>
                            </Button>
                        </TextTooltip>
                    </If>

                    <If is={!isMember}>
                        <Button size="l"
                                hasHover={!isMember}
                                hasActive={!isMember}
                                onClick={handleAppoint}
                                loading={appointTournamentLoading}
                                disabled={appointTournamentLoading || !canJoin}
                                before={(
                                    !canJoin
                                        ? <Icon28TrainOutline/>
                                        : isMember
                                            ? <Icon24UserAddedOutline/>
                                            : <Icon24UserAddOutline/>
                                )}>
                            {(() => {
                                switch (state) {
                                    case TournamentState.Process:
                                        return 'Турнир уже идёт';

                                    case TournamentState.Cancel:
                                        return 'Турнир был отменен';

                                    default:
                                        return 'Присоединиться';
                                }
                            })()}
                        </Button>
                    </If>

                    <Button size="l"
                            mode="secondary"
                            className="w-full"
                            onClick={handleInvite}
                            before={<Icon24ShareOutline/>}>
                        {canJoin
                            ? 'Пригласить друзей'
                            : 'Поделиться ссылкой'
                        }
                    </Button>
                </div>,
            ],
        });
    }, [ appointTournamentError, isMember, appointTournamentLoading ]);

    return <></>;
});


export { SetPartsView };
