/**
 * Router util
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import React from 'react';

import { PageParams, useParams } from 'router.tsx';

import { RoutePathType } from '@/router/paths';

import { observer, RouterStore } from '@/store/core/router';

import { ObjectUtil } from '@/utils';


interface Props {
    children: any;
    pages?: RoutePathType | RoutePathType[];
    ignore?: RoutePathType | RoutePathType[];
    params?: PageParams;
    strict?: boolean;
}


const Router = observer((props: Props) => {

    const { pages, params, strict, ignore } = props;

    const keys = params && _.keys(params) || [];

    const compareParams = strict ? useParams() : _.pick(useParams(), keys);

    const pageIsEqual = _.isArray(pages)
        ? pages.includes(RouterStore.pageId)
        : pages === RouterStore.pageId;

    const isIgnore = _.isArray(ignore)
        ? ignore.includes(RouterStore.pageId)
        : ignore === RouterStore.pageId;

    const paramsIsEqual = ObjectUtil.isEqual(compareParams, params);

    const condition = (pages ? pageIsEqual : !isIgnore) && (params ? paramsIsEqual : true);

    return condition && props.children || <></>;
});


export { Router };
