/**
 * Snackbar styled container
 *
 * @author: exode <hello@exode.ru>
 */

import styled from 'styled-components';

import { responsive } from '@/styles/modules/responsive';

import { DefaultSnackbarProps } from './DefaultSnackbar';


const snackbarTypes = {
    primary: {
        bg: 'var(--accent)',
        color: 'var(--white)',
        avatarBg: 'var(--white)',
    },
    muted: {
        bg: '',
        color: '',
        avatarBg: 'var(--accent)',
    },
    error: {
        bg: 'var(--destructive)',
        color: 'var(--white)',
        avatarBg: 'var(--white)',
    },
    success: {
        bg: 'var(--vkui--color_background_positive)',
        color: 'var(--white)',
        avatarBg: 'var(--white)',
    },
};


export const Container = styled.div<Pick<DefaultSnackbarProps, 'appearance'>>`
    ${responsive([ 'tablet', 'gt-tablet' ])} {
        min-width: 380px;
        max-width: 380px;
    }

    ${responsive([ 'mobile' ])} {
        min-width: 100%;
    }

    .vkuiSnackbar {
        min-width: 300px;
        display: flex;
        justify-content: center;
    }

    .Snackbar__in {
        width: 100%;

        & .Snackbar__body {
            display: flex;
            background-color: ${props => snackbarTypes[props.appearance as keyof typeof snackbarTypes].bg};

            & .Snackbar__content-text {
                color: ${props => snackbarTypes[props.appearance as keyof typeof snackbarTypes].color};
            }

            & .Snackbar__before .Avatar {
                background-color: ${props => snackbarTypes[props.appearance as keyof typeof snackbarTypes].avatarBg};

                .vkuiIcon {
                    color: ${props => snackbarTypes[props.appearance as keyof typeof snackbarTypes].bg || 'var(--white)'};
                }
            }
        }

        .vkuiSnackbar__action {
            margin-bottom: -4px;
        }

        ${responsive([ 'gt-laptop' ])} {
            & .Snackbar__body {
                padding-left: 20px;
                padding-right: 20px;
            }
        }

        ${responsive([ 'mobile' ])} {
            width: 100%;
            padding: 0.5rem 0;

            .vkuiSnackbar {
                width: 100%;
            }

            & .Snackbar__body {
                width: 100%;
            }
        }
    }
`;
