/**
 * CourseNotParticipantModal
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { useI18n } from '@/hooks/core';
import { useStableModalParams } from '@/router/index';

import { Modal } from '@/cutils';

import { Title } from '@exode.ru/vkui';
import { NotParticipantView } from '@/pages/Course/Study/views/NotParticipantView';

import SizedModal from '@/modals/Sized/Sized.module.scss';


interface Props {
    id: string;
}


const CourseNotParticipantModal = (props: Props) => {

    const { t } = useI18n('modals.Course.NotParticipant');

    const [ { courseId, modalLessonId, productIsFree } ] = useStableModalParams();

    return (
        <Modal.Card id={props.id} className={[ 'modal:w-850', SizedModal.medium ].join(' ')} header={(
            <Title className="modal-title" level="2" weight="3">
                {productIsFree === 'true' ? t('confirmOfEntry') : t('availableTariffs')}
            </Title>
        )}>
            <Modal.Body fullHeight isScrollable>
                <NotParticipantView mode="offer" placement="modal" lessonId={+modalLessonId} courseId={+courseId}/>
            </Modal.Body>
        </Modal.Card>
    );
};


export { CourseNotParticipantModal };
