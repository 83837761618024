/**
 * Routes
 *
 * @author: exode <hello@exode.ru>
 */

import { useState } from 'react';

import { Page, PageParams, RouteList, Router, useLocation as routerUseLocation } from 'router.tsx';

import { RouterStore } from '@/store/core/router';
import { PageStore } from '@/store/core/page';

import { IS_NATIVE, IS_WEB } from '@/root/src/env';

import { Routes } from './routes';
import { RoutePathType } from '@/router/paths';
import { ModalTypes } from '@/modals/index';

import { DocumentEvent } from '@/types/window';
import { RouterEventType, RouterItemType, RouteType, TabBarItemsType, TabsType } from '@/types/router';


const routes: RouteList = {};

/**
 * Use location с типизацией
 * @returns {{route: {pageId: RoutePathType, params: PageParams}}}
 */
const useLocation = function <P extends PageParams>() {
    return routerUseLocation() as unknown as { route: { pageId: RoutePathType, params: P } };
};

/**
 * Use stable modal params
 * @returns {[]}
 */
const useStableModalParams = <P extends PageParams>() => {
    const { route: { params } } = useLocation();
    const [ stableParams ] = useState(params);

    return [ stableParams as P ];
};

/**
 * Current route in Routes
 * @note: need to wrap with observer
 */
export const useCurrentRoute = () => {

    const currentRoute = Routes[RouterStore.pageId];

    return {
        currentRoute,
        isTab: currentRoute?.type === 'tab',
    };
};

/**
 * Группировка вкладок (tabs) по кнопкам нижнего меню
 */
const TabItemViewsGroup: { [key in TabBarItemsType]: TabsType[] } = {
    education: [],
    catalog: [],
    chat: [],
    menu: [],
};

/**
 * Инициализация всех страниц приложения (+ группировка вкладок по кнопкам меню)
 */
for (const key in Routes) {
    if (!Routes.hasOwnProperty(key)) {
        continue;
    }

    const route = Routes[key as RoutePathType] as RouterItemType;

    if (route.tab !== undefined) {
        TabItemViewsGroup[route.tab as TabBarItemsType].push(route.viewId as TabsType);
    }

    routes[key] = new Page();
}

const router = new Router(routes, {
    useHash: IS_NATIVE,
    notFoundRoute: IS_NATIVE ? '/' : '/404',
});

/**
 * Событие изменения состояния роутера меняет состояние приложения
 */
router.on('update', (next, prev) => {
    const pageId = next.getPageId() as RoutePathType;
    const route = Routes[pageId] as RouterItemType;

    const data: RouterEventType = {
        detail: {
            prev: prev as RouteType,
            next: next as RouteType,
        },
    };

    const beforeUpdate = new CustomEvent(DocumentEvent.RouterBeforeUpdate, data);
    const afterUpdate = new CustomEvent(DocumentEvent.RouterAfterUpdate, data);

    document.dispatchEvent(beforeUpdate);

    RouterStore.setRouter({
        type: route.type || 'page',
        subType: route.subType || 'page',
        tab: route.tab,
        pageId: pageId,
        viewId: String(route.viewId) as RoutePathType,
        params: next.getParams(),
        location: next.getLocation(),
        activeModal: next.getParams().modal as ModalTypes,
        activePopup: next.getParams().popup,
        isModal: next.isModal(),
        isPopup: next.isPopup(),
        hasOverlay: next.hasOverlay(),
    });

    IS_WEB && PageStore.updateTags();

    document.dispatchEvent(afterUpdate);
});

/**
 * Инициализация роутинга
 */
router.start();


export {
    router,
    useLocation,
    TabItemViewsGroup,
    useStableModalParams,
};

export type { PageParams } from 'router.tsx';

