/**
 * OperationDetails
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { observer } from 'mobx-react';

import { PlatformStore } from '@/store/platform';

import { If } from '@/cutils';
import { Time } from '@/utils';
import { useI18n } from '@/hooks/core';
import { sellerBalanceOperationCategory, SellerBalanceOperationItem } from '@/types/seller';

import { Text } from '@exode.ru/vkui';
import { Icon16CalendarOutline } from '@vkontakte/icons';

import { AmountText } from '@/components/Atoms/AmountText';
import { InlineInfoCaptionText } from '@/components/Atoms/InlineInfoCaptionText';
import { IconPart } from '@/components/Seller/Balance/OperationRow/parts/IconPart';

import { PayoutOperationPart } from './parts/PayoutOperationPart';
import { InvoiceProductPayoutPart } from './parts/InvoiceProductPayoutPart';


interface Props {
    operation: SellerBalanceOperationItem;
}


const OperationDetails = observer((props: Props) => {

    const {
        operation,
        operation: {
            uuid,
            source,
            amount,
            currency,
            createdAt,
            payoutOperations,
            invoiceProductPayouts,
        },
    } = props;

    const { COMMON } = PlatformStore;

    const sellerBalanceOperationCategories = sellerBalanceOperationCategory();

    const { t } = useI18n('components.Seller.Balance.OperationDetails');

    return (
        <>
            <div className="flex flex-col items-center">
                <InlineInfoCaptionText className="mb-5"
                                       icon={<Icon16CalendarOutline/>}
                                       text={t('doneAt', { at: Time.parseRelative(createdAt).toLowerCase() })}/>

                <IconPart size={72} iconSize={48} operation={operation}/>

                <Text className="mt-5">
                    {sellerBalanceOperationCategories[source].text}
                </Text>

                <InlineInfoCaptionText icon={<></>}
                                       className="mt-2 mb-5"
                                       text={t('operationId', { id: uuid?.split('-')?.at(-1) })}/>

                <AmountText toFixed={2}
                            amount={amount}
                            unsigned={false}
                            isGreen={amount > 0}
                            className="fs-25 mb-2"
                            currency={currency || COMMON?.organization.settings.currency}/>
            </div>

            <If is={invoiceProductPayouts.length > 0}>
                <InvoiceProductPayoutPart source={source}
                                          invoiceProductPayouts={invoiceProductPayouts}
                                          currency={currency || COMMON?.organization.settings.currency}/>
            </If>

            <If is={payoutOperations.length > 0}>
                <PayoutOperationPart payoutOperations={payoutOperations}/>
            </If>
        </>
    );
});


export { OperationDetails };
