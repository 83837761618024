/**
 * ReadyAndUpcomingTournamentsView
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import React, { HTMLAttributes } from 'react';

import { UserAuthStore } from '@/store/user/auth';
import { Page, useStore } from '@/pages/Core';

import { observer, TournamentTabStore } from '../store';

import {
    ReadyAndUpcomingTournamentsQuery,
    ReadyAndUpcomingTournamentsQueryResult,
    TournamentStartMode,
    TournamentState,
    TournamentType,
} from '@/codegen/graphql';

import { useI18n } from '@/hooks/core';
import { GqlResult } from '@/types/graphql';
import { Graphql, If, Link } from '@/cutils';

import { Icon24BoxingGloveOutline } from '@vkontakte/icons';
import { Counter, Header, HorizontalScroll } from '@exode.ru/vkui';

import { Placeholder } from '@/components/Atoms/Placeholder';
import { TournamentMiniCard } from '@/components/Tournament/MiniCard';
import { TournamentMiniCardSkeleton } from '@/components/Atoms/Skeleton/TournamentMiniCard';


interface WrapperProps extends HTMLAttributes<HTMLDivElement> {
    count?: number;
}

interface SuccessProps extends HTMLAttributes<HTMLDivElement> {
    tournaments: GqlResult<ReadyAndUpcomingTournamentsQueryResult>['tournamentFindManyReady'];
}


const Wrapper: React.FC<WrapperProps> = (props) => {

    const { t } = useI18n('pages.Tournament.Tournament');

    return (
        <Page.Row isFirst {...props}>
            <Header mode="primary" aside={(
                <Counter size="s" mode="primary" className={[
                    'px-1 py-0.5 bg-gradient-to-r from-blue-600 to-purple-500',
                    'dark:from-blue-400 dark:to-purple-500 text-white',
                ].join(' ')}>
                    {t('tournaments', { count: props.count || 0 })}
                </Counter>
            )}>
                <span className="text-underline-marker h-after-4">
                    Активные турниры
                </span>
            </Header>

            <HorizontalScroll showArrows
                              className="d:mt-1"
                              getScrollToLeft={(i) => i - 120}
                              getScrollToRight={(i) => i + 120}>
                <div className="flex">
                    {props.children}
                </div>
            </HorizontalScroll>
        </Page.Row>
    );
};

const Loading = () => {
    return (
        <Wrapper>
            {Array(8).fill(0).map((_, i) => <TournamentMiniCardSkeleton key={i}/>)}
        </Wrapper>
    );
};

const Success = (props: SuccessProps) => {

    const { tournaments: { items } } = props;

    return (
        <Wrapper count={items?.length}>
            {items?.map((tournament) => (
                <TournamentMiniCard key={tournament.id} {...tournament} />
            ))}

            <If is={_.isEmpty(items)}>
                <Placeholder iconSize={96}
                             innerPadding="0px"
                             className="mx-auto"
                             header="Турниры не найдены"
                             icon={<Icon24BoxingGloveOutline className="pt-0 text-accent opacity-[0.85]"/>}
                             content={(
                                 <div className="m:w-full w-9/10 mx-auto">
                                     <span>Создай или прими участие в</span>

                                     <Link pushModal={{ id: 'tournament-create' }}
                                           pushPageMobile={{ id: '/tournaments/create' }}>
                                         <span className="text-accent">&nbsp;новом турнире</span>
                                     </Link>
                                 </div>
                             )}/>
            </If>
        </Wrapper>
    );
};


const ReadyAndUpcomingTournamentsView = observer(() => {

    const { filter: storeFilter, sort, list: { upcoming } } = useStore(TournamentTabStore);

    const filter = {
        ..._.pick(storeFilter, 'states'),
        onlyMySelectedSubjects: true,
        startModes: [ TournamentStartMode.Scheduled ],
        types: [ TournamentType.Official, TournamentType.Public ],
    };

    return (
        <ReadyAndUpcomingTournamentsQuery fetchPolicy="network-only" children={(result) => (
            <>
                <Graphql.Loading result={result}>
                    <Loading/>
                </Graphql.Loading>

                <Graphql.Success result={result}>
                    {({ tournamentFindManyReady, tournamentFindManyUpcoming }) => (
                        <Success tournaments={{
                            ...tournamentFindManyReady,
                            ...tournamentFindManyUpcoming,
                            items: [
                                ...(tournamentFindManyReady.items || []),
                                ...(tournamentFindManyUpcoming.items || []),
                            ],
                        }}/>
                    )}
                </Graphql.Success>

                <Graphql.Error result={result}/>
            </>
        )} variables={{
            listReady: { take: 10 },
            sortReady: {
                state: [
                    TournamentState.Process,
                    TournamentState.StartLobby,
                ],
            },
            filterReady: {
                onlyMySelectedSubjects: true,
                states: [
                    TournamentState.Process,
                    TournamentState.StartLobby,
                ],
                memberUserIds: UserAuthStore.user?.id
                    ? [ UserAuthStore.user.id ]
                    : [],
            },
            listUpcoming: { ...upcoming },
            sortUpcoming: { ...sort },
            filterUpcoming: { ...filter },
        }}/>
    );
});


export { ReadyAndUpcomingTournamentsView };
