/**
 * AcquiringFormView
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import React, { useState } from 'react';

import { EXODE_BIZ_SUPPORT_TG_URL } from '@/root/src/env';

import {
    PaymentProviderFindManyQuery,
    PaymentProviderFragmentFragment,
    SchoolAcquiringFragmentFragment,
} from '@/codegen/graphql';

import { Page } from '@/pages/Core';

import { TranslateJson } from '@/shared/types';
import { Router } from '@/services/Utils/Router';
import { useSchoolAcquiring } from '@/hooks/apollo';
import { Graphql, If, Link, Notify } from '@/cutils';
import { getTranslated, useI18n, usePrompt } from '@/hooks/core';

import { Avatar, Button, CustomSelectOption, FormItem, Select } from '@exode.ru/vkui';
import { Icon16ErrorCircleFillYellow, Icon20HeadphonesSupportOutline, Icon28NotificationWaves } from '@vkontakte/icons';

import { useSellerContext } from '@/components/App/SellerProvider';
import { InlineInfoCaptionText } from '@/components/Atoms/InlineInfoCaptionText';
import { DynamicForm, DynamicFormFieldPart } from '@/components/Atoms/DynamicForm';


interface Props {
    acquiring?: SchoolAcquiringFragmentFragment;
}


const AcquiringFormView = (props: Props) => {

    const { acquiring } = props;

    const { t } = useI18n('pages.Manage.School.Acquiring');

    const [ providerId, setProviderId ] = useState<number | null>();

    const { seller } = useSellerContext();

    const {
        createAcquiring,
        createAcquiringLoading,
        updateAcquiring,
        updateAcquiringLoading,
        entityCrossFields,
    } = useSchoolAcquiring();

    const { openPrompt: openCopyCallbackLink } = usePrompt({
        onConfirm: () => {},
        title: t('setCallbackLink'),
        subtitle: t('setCallbackLinkSubtitle'),
        modalCardProps: {
            disableManualClose: true,
            icon: <Icon28NotificationWaves width={72} height={72} className="modal-title"/>,
        },
        buttons: {
            cancel: { hidden: true },
            confirm: {
                text: t('setCallbackDone'),
                props: { mode: 'commerce' },
            },
        },
    });

    const handleSubmit = (values: Record<any, any>) => {
        if (!providerId) {
            return;
        }

        const partial = {
            providerId,
            config: _.omit(values, entityCrossFields),
            ..._.pick(values, entityCrossFields),
        };

        acquiring
            ? updateAcquiring(acquiring.id, { ...partial }, () => {
                Router.replaceModal();

                Notify.toast.success(t('acquiringUpdated'));
            })
            : createAcquiring(partial, async (acquiring) => {
                Notify.toast.success(t('acquiringCreated'));

                await Router.replaceModalWithTimeout();

                const callbackLinkField = _.find(
                    acquiring.provider.dynamicForm?.fields,
                    { name: '_callbackLink' },
                );

                if (callbackLinkField) {
                    openCopyCallbackLink({
                        children: (
                            <FormItem className="px-0 mt-5">
                                <DynamicFormFieldPart index={0}
                                                      config={null}
                                                      payload={{ acquiring }}
                                                      field={callbackLinkField}/>

                                <InlineInfoCaptionText alignment="left"
                                                       className="mt-5 !items-start"
                                                       text={t('infoTextAboutImportant')}
                                                       icon={(
                                                           <Icon16ErrorCircleFillYellow className="min-w-[16px] mt-1"/>
                                                       )}/>

                                <div className="flex flex-1 justify-center">
                                    <Link toOuter={EXODE_BIZ_SUPPORT_TG_URL}>
                                        <Button size="m"
                                                mode="secondary"
                                                className="mt-8 mx-auto"
                                                before={<Icon20HeadphonesSupportOutline/>}>
                                            {t('contactSupport')}
                                        </Button>
                                    </Link>
                                </div>
                            </FormItem>
                        ),
                    });
                }
            });
    };

    return (
        <PaymentProviderFindManyQuery onCompleted={({ paymentProviderFindMany: { items } }) => {
            setProviderId(acquiring?.provider.id || items?.[0]?.id || null);
        }} children={(result) => (
            <>
                <Graphql.Loading result={result}>
                    <Page.Spinner/>
                </Graphql.Loading>

                <Graphql.Success result={result}>
                    {({ paymentProviderFindMany: { items } }) => {

                        const provider = _.find(items, { id: providerId }) as PaymentProviderFragmentFragment;

                        return (
                            <div className="p-3">
                                <If is={!acquiring}>
                                    <FormItem className="p-0 mb-6" top={t('paymentProvider')}>
                                        <Select name="providerId"
                                                value={providerId || undefined}
                                                data-test="payment-provider.select"
                                                onChange={(e) => setProviderId(+e.target.value)}
                                                before={provider && <Avatar size={24} src={`${provider.icon}`}/>}
                                                renderOption={({ option, ...restProps }) => (
                                                    <CustomSelectOption {...restProps} before={(
                                                        <Avatar size={24} src={option.avatar}/>
                                                    )}/>
                                                )}
                                                options={(
                                                    _.map(items, ({ id: value, name: label, icon }) => ({
                                                        value,
                                                        avatar: icon,
                                                        label: getTranslated(label as TranslateJson) || '',
                                                    }))
                                                )}/>
                                    </FormItem>
                                </If>

                                <DynamicForm fields={provider?.dynamicForm.fields}
                                             payload={{ acquiring: acquiring || null }}
                                             onSubmit={(values) => handleSubmit(values)}
                                             validation={provider?.dynamicForm.validation}
                                             loading={createAcquiringLoading || updateAcquiringLoading}
                                             savedValues={{
                                                 ...acquiring?.config,
                                                 ..._.pick(acquiring, entityCrossFields),
                                             }}
                                             button={{
                                                 text: !acquiring
                                                     ? t('createAcquiring')
                                                     : t('saveAcquiring'),
                                                 savedText: acquiring
                                                     ? t('everythingIsSaved')
                                                     : undefined,
                                             }}/>
                            </div>
                        );
                    }}
                </Graphql.Success>

                <Graphql.Error result={result}/>
            </>
        )} variables={{
            list: {},
            filter: {
                active: true,
                currencies: seller?.baseCurrency
                    ? [ seller?.baseCurrency ]
                    : [],
            },
        }}/>
    );
};


export { AcquiringFormView };
