/**
 * CoursePricingPlanModal
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { Page } from '@/pages/Core';
import { useI18n } from '@/hooks/core';

import { ProductLaunchFindOneQuery, ProductPriceFindOneQuery } from '@/codegen/graphql';

import { Graphql, If, Modal } from '@/cutils';
import { useStableModalParams } from '@/router/index';

import { Title } from '@exode.ru/vkui';

import { PriceFormView } from '@/pages/Manage/Courses/Launches/Pricing/views/PriceFormView';


interface Props {
    id: string;
}


const CoursePricingPlanModal = (props: Props) => {

    const { t } = useI18n('modals.Course.PricingPlan');

    const [ { productId, launchId, priceId } ] = useStableModalParams();

    return (
        <Modal.Card className="modal:w-550" id={props.id} header={(
            <Title className="modal-title" level="2" weight="3">
                {priceId ? t('editingTariffTitle') : t('creatingTariffTitle')}
            </Title>
        )}>
            <Modal.Body fullHeight isScrollable>
                <If is={!!priceId}>
                    <ProductPriceFindOneQuery children={(result) => (
                        <>
                            <Graphql.Loading result={result}>
                                <Page.Spinner/>
                            </Graphql.Loading>

                            <Graphql.Success result={result}>
                                {({ productPriceFindOne: productPrice }) => (
                                    <PriceFormView price={productPrice}
                                                   launchId={+launchId}
                                                   productId={+productId}
                                                   launch={productPrice.launch}/>
                                )}
                            </Graphql.Success>

                            <Graphql.Error result={result}/>
                        </>
                    )} variables={{ priceId: +priceId }}/>
                </If>

                <If is={!priceId}>
                    <ProductLaunchFindOneQuery children={(result) => (
                        <>
                            <Graphql.Loading result={result}>
                                <Page.Spinner/>
                            </Graphql.Loading>

                            <Graphql.Success result={result}>
                                {({ productLaunchFindOne: launch }) => (
                                    <PriceFormView productId={+productId} launchId={+launchId} launch={launch}/>
                                )}
                            </Graphql.Success>

                            <Graphql.Error result={result}/>
                        </>
                    )} variables={{ launchId: +launchId }}/>
                </If>
            </Modal.Body>
        </Modal.Card>
    );
};


export { CoursePricingPlanModal };
