/**
 * IconButton component
 *
 * @author: exode <hello@exode.ru>
 */

import React, { HTMLAttributes, ReactElement } from 'react';

import { IconButton as VkUiIconButton } from '@exode.ru/vkui';

import { Container } from './IconButton.styled';


export interface IconButtonProps extends HTMLAttributes<HTMLDivElement> {
    children: ReactElement;
    height?: number;
    width?: number;
    background?: string;
}


const IconButton = (props: IconButtonProps) => {
    return (
        <Container {...props}>
            <VkUiIconButton hasActive={false}>
                {props.children}
            </VkUiIconButton>
        </Container>
    );
};


export { IconButton };
