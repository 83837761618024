/**
 * PageContextModal styled container
 *
 * @author: exode <hello@exode.ru>
 */

import styled from 'styled-components';


export const Container = styled.div`
  .Header {
    padding-left: 18px;
  }

  .vkuiModalCardBase__container {
    padding: 0;
  }
`;
