/**
 * ProfileSubjectCard component
 *
 * @author: exode <hello@exode.ru>
 */

import React, { MouseEventHandler } from 'react';

import { SvgComponent } from '@/cutils';

import { SubjectFragmentFragment } from '@/codegen/graphql';
import { educationSubjectColors, educationSubjectImages } from '@/types/subject';

import { Card, Text } from '@exode.ru/vkui';

import styles from './ProfileSubjectCard.module.scss';


interface Props {
    subject: SubjectFragmentFragment;
    onClick: MouseEventHandler<HTMLDivElement>;
    size?: 'big' | 'default';
    className?: string;
    loading?: boolean;
    disabled?: boolean;
}


const ProfileSubjectCard = (props: Props) => {

    const { subject, onClick, className, size = false, loading = false, disabled = false } = props;

    const { bigSubjectCard, selectedSubjectsCard } = styles;

    return (
        <Card mode="shadow" onClick={!loading && !disabled ? onClick : undefined} className={[
            className,
            educationSubjectColors[subject.subject],
            'cursor-pointer min-w-[115px] bg-gradient-to-br',
            disabled ? 'pointer-events-none opacity-50' : '',
            size === 'big' ? bigSubjectCard : selectedSubjectsCard,
        ].join(' ')}>
            <div className="gap-1 pt-4 pb-2.5 flex flex-col justify-start items-center">
                <SvgComponent element={educationSubjectImages[subject.subject]}/>

                <Text className="text-white" weight="medium">
                    {subject.name}
                </Text>
            </div>
        </Card>
    );
};


export { ProfileSubjectCard };
