/**
 * UserWalletBalanceSubscription component
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { useUserBalanceListenUpdateSubscription, WalletOperationsDocument } from '@/codegen/graphql';


const UserWalletBalanceSubscription = () => {

    useUserBalanceListenUpdateSubscription({
        onData: ({ data: { data }, client, client: { cache } }) => {
            if (data) {
                cache.modify({
                    id: cache.identify({ __typename: 'Query' }),
                    fields: {
                        walletGetBalance: () => data.walletListenNewUserBalance,
                    },
                });

                client.refetchQueries({
                    include: [
                        WalletOperationsDocument,
                    ],
                });
            }
        },
    });

    return (<></>);
};


export { UserWalletBalanceSubscription };
