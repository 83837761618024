/**
 * AvailableInAppModal
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { observer, PlatformStore } from '@/store/platform';

import { useLocation } from '@/router/index';

import { useI18n } from '@/hooks/core';

import { Time } from '@/utils';
import { If, Modal, useRedirect } from '@/cutils';

import { Router } from '@/services/Utils/Router';
import { QrCode } from '@/components/Atoms/QrCode';

import { Icon16LogoApple } from '@vkontakte/icons';
import { Button, Text, Title } from '@exode.ru/vkui';

import { IconGooglePlay } from '@/images/icons';


interface Props {
    id: string;
}


const AvailableInAppModal = observer((props: Props) => {

    const { COMMON } = PlatformStore;

    const { t } = useI18n('modals.Utils.AvailableInApp');

    const { route: { pageId } } = useLocation();

    const { redirect } = useRedirect();

    const onClick = async (to?: string) => {
        to && redirect(to, true);

        Router.replaceModal();

        if (pageId === '/app') {
            await Time.timer(Router.timeout);

            Router.replacePage('/');
        }
    };

    return (
        <Modal.Card id={props.id}
                    header={<Title className="modal-title" level="2" weight="3">{t('officialApp')}</Title>}
                    onClose={() => onClick()}
                    icon={(
                        <QrCode size={150} className="mt-4" value="https://exode.ru/app"/>
                    )}
                    actions={(
                        <div className="flex w-full">
                            <If is={!!COMMON?.organization.native.appStore}>
                                <Button size="l"
                                        mode="secondary"
                                        before={<Icon16LogoApple width={20} height={20}/>}
                                        onClick={() => onClick(COMMON?.organization.native.appStore)}>
                                    App Store
                                </Button>
                            </If>

                            <If is={!!COMMON?.organization.native.googlePlay}>
                                <Button size="l"
                                        mode="secondary"
                                        before={<IconGooglePlay className="mt-1"/>}
                                        onClick={() => onClick(COMMON?.organization.native.googlePlay)}>
                                    Google Play
                                </Button>
                            </If>
                        </div>
                    )}>
            <Modal.Body>
                <Text className="text-center text-muted my-3">
                    {t('yourPersonalPathStartFromHere')}
                </Text>
            </Modal.Body>
        </Modal.Card>
    );
});


export { AvailableInAppModal };
