/**
 * ScrollTo util
 *
 * @author: exode <hello@exode.ru>
 */

import { useEffect } from 'react';

import { ScrollHelper } from '@/helpers/ui';


interface Props {
    element: Window | HTMLDivElement | null;
    top?: number;
    smooth?: boolean;
}


const ScrollTo = (props: Props) => {

    const { top = 0, element = window, smooth = false } = props;

    useEffect(() => {
        if (element) {
            ScrollHelper.to(top, smooth, element);
        }
    }, []);

    return <></>;
};


export { ScrollTo };
