/**
 * ControlPanelPart
 *
 * @author: exode <hello@exode.ru>
 */

import React, { Dispatch, SetStateAction } from 'react';

import { If } from '@/cutils';
import { RndVideoOrientation } from '@/components/Atoms/RndVideo';

import {
    Icon24HorizontalRectangle16x9Outline,
    Icon24VerticalRectangle9x16Outline,
    Icon28MuteOutline,
    Icon28Pause,
    Icon28Play,
    Icon28VolumeOutline,
} from '@vkontakte/icons';

import { ControlPanelWrapper } from '../RndVideo.styled';


interface Props {
    playing: boolean;
    muted: boolean;
    getPlyrApi: () => (Plyr | undefined);
    orientation: RndVideoOrientation;
    setOrientation: Dispatch<SetStateAction<RndVideoOrientation>>;
}


const ControlPanelPart = (props: Props) => {

    const { playing, muted, getPlyrApi, orientation, setOrientation } = props;

    const onMuteToggle = () => {
        const plyr = getPlyrApi();

        plyr && (plyr.muted = !muted);
    };

    return (
        <ControlPanelWrapper className="absolute flex bottom-0 z-[101] p-2 flex-1 gap-2 w-[95%]">
            <div className="flex cursor-pointer" onClick={() => getPlyrApi()?.togglePlay(!playing)}>
                <If is={!playing}>
                    <Icon28Play fill="var(--white)"/>
                </If>

                <If is={playing}>
                    <Icon28Pause fill="var(--white)"/>
                </If>
            </div>

            <div className="flex cursor-pointer" onClick={onMuteToggle}>
                <If is={muted}>
                    <Icon28MuteOutline fill="var(--white)"/>
                </If>

                <If is={!muted}>
                    <Icon28VolumeOutline fill="var(--white)"/>
                </If>
            </div>

            <div className="flex cursor-pointer"
                 onClick={() => setOrientation(orientation === 'horizontal' ? 'vertical' : 'horizontal')}>
                <If is={orientation === 'horizontal'}>
                    <Icon24VerticalRectangle9x16Outline fill="var(--white)"/>
                </If>

                <If is={orientation === 'vertical'}>
                    <Icon24HorizontalRectangle16x9Outline fill="var(--white)"/>
                </If>
            </div>
        </ControlPanelWrapper>
    );
};


export { ControlPanelPart };
