/**
 * App content subscriptions
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { observer } from 'mobx-react';

import { SubscriptionStore } from '@/store/subscription/subscription';

import { ContentElementSubscription } from './ContentElementSubscription';


const AppContentSubscriptions = observer(() => {
    return (
        <>
            {[ ...SubscriptionStore.contentElementUuid ].map((uuid) => (
                <ContentElementSubscription key={uuid} uuid={uuid}/>
            ))}
        </>
    );
});


export { AppContentSubscriptions };
