/**
 * User online store
 *
 * @author: exode <hello@exode.ru>
 */

import moment from 'moment';

import { observer } from 'mobx-react';
import { makeObservable, observable } from 'mobx';

import { socket } from '@/api/socket';
import { apolloClient } from '@/api/graphql';
import { emitStoreWrapper } from '@/hooks/core';
import { UserOnlineSocketOutput } from '@/codegen/graphql';
import { ClientEmit, ClientEvent } from '@/shared/types';


class Online {

    constructor() {
        makeObservable(this, {
            onlineList: observable,
        });
    }

    onlineList: Record<number, boolean> = {};

    subscribedList: Set<number> = new Set();

    private toggleOnline(userId: number, isOnline: boolean) {
        if (!isOnline && this.onlineList[userId]) {
            apolloClient.cache.modify({
                id: `UserEntity:${userId}`,
                fields: {
                    lastOnlineAt: () => moment(new Date()).utc().format(),
                },
            });
        }

        this.onlineList[userId] = isOnline;
        this.addToSubscribeList(userId);
    }

    private addToSubscribeList(key: number) {
        this.subscribedList.add(key);
    }

    resetListeners() {
        this.subscribedList.clear();
    }

    addListener(userId: number) {
        const eventName = ClientEvent.userOnline(userId);

        const onUserOnlineToggleSocket = (output: UserOnlineSocketOutput) => {
            const { online, user: { id } } = output;

            this.toggleOnline(id, online);
        };

        const onDisconnect = () => {
            this.resetListeners();

            socket.off(eventName, onUserOnlineToggleSocket);
            socket.once('connect', () => this.addListener(userId));
        };

        emitStoreWrapper(this.subscribedList, userId, () => {
            this.addToSubscribeList(userId);

            socket.once('disconnect', onDisconnect);
            socket.on(eventName, onUserOnlineToggleSocket);

            socket.emit(ClientEmit.userOnlineStatus, { userId }, (status: boolean) => {
                this.toggleOnline(userId, status);
            });
        });
    }

}

const UserOnlineStore = new Online();


export { observer, UserOnlineStore };
