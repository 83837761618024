/**
 * FooterBlock styled container
 *
 * @author: exode <hello@exode.ru>
 */

import styled from 'styled-components';


export const FooterWrapper = styled.div`
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
`;

export const Wrapper = styled.div`
`;
