/**
 * PhotoViewer component
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { IconButton } from '@mui/material';
import { OverlayRenderProps as PhotoViewOverlayRenderProps } from 'react-photo-view/dist/types';

import {
    Icon28AddOutline,
    Icon28MinusOutline,
    Icon28RotateLeftOutline,
    Icon28RotateRightOutline,
} from '@vkontakte/icons';

import { User } from '@/components/Atoms/User';
import { UserCellProps } from '@/components/Atoms/User/User';

import { OverlayRenderWrapper } from './PhotoViewer.styled';


interface OverlayRenderProps extends Partial<PhotoViewOverlayRenderProps> {
    userCellProps?: UserCellProps;
}


const OverlayRender = (props: OverlayRenderProps) => {

    const { onScale, onRotate, userCellProps, scale = 1, rotate = 0 } = props;

    return (
        <OverlayRenderWrapper className="flex flex-1 absolute pb-safe left-0 bottom-0 z-[20] items-center w-full h-[60px]">
            {userCellProps && (
                <div>
                    <User.Cell {...userCellProps}/>
                </div>
            )}

            <div className="flex gap-2">
                <div className="hidden d:flex">
                    <IconButton onClick={() => onScale?.(scale - 1)}>
                        <Icon28MinusOutline fill="white"/>
                    </IconButton>
                </div>

                <div className="hidden d:flex">
                    <IconButton className="hidden d:flex" onClick={() => onScale?.(scale + 1)}>
                        <Icon28AddOutline fill="white"/>
                    </IconButton>
                </div>

                <IconButton onClick={() => onRotate?.(rotate - 90)}>
                    <Icon28RotateLeftOutline fill="white"/>
                </IconButton>

                <IconButton onClick={() => onRotate?.(rotate + 90)}>
                    <Icon28RotateRightOutline fill="white"/>
                </IconButton>
            </div>
        </OverlayRenderWrapper>
    );
};


export const PhotoViewer = {
    OverlayRender: OverlayRender,
};
