/**
 * Kinescope styled container
 *
 * @author: exode <hello@exode.ru>
 */

import styled from 'styled-components';


export const Container = styled.div`
    > span {
        aspect-ratio: 16/9;
    }
`;
