/**
 * Modal util
 *
 * @author: exode <hello@exode.ru>
 */

import { ModalPage } from '@exode.ru/vkui';

import { ModalCard } from '@/components/Atoms/ModalCard';
import { ModalCardIcon } from '@/components/Atoms/ModalCardIcon';
import { ModalBody } from '@/components/Atoms/ModalBody';
import { ModalResponsive } from '@/components/Atoms/ModalResponsive';
import { ModalPageHeader } from '@/components/Atoms/ModalPageHeader';


export const Modal = {
    Card: ModalCard,
    Page: ModalPage,
    Body: ModalBody,
    CardIcon: ModalCardIcon,
    Responsive: ModalResponsive,
    PageHeader: ModalPageHeader,
};
