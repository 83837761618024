/**
 * Scrollbar custom function
 *
 * @author: exode <hello@exode.ru>
 */

export const customScrollbar = (bg = '--background_content') => {
    return `
    ::-webkit-scrollbar {
      width: 8px;
      height: 8px;
      background-color: var(${bg});
    }

    ::-webkit-scrollbar-track {
      margin-top: 5px;
      border-radius: 10px;
      background-color: var(${bg});
    }
    
    ::-webkit-scrollbar-track-piece {
        border-radius: 16px;
    }

    ::-webkit-scrollbar-thumb {
      background-color: var(--vkui--color_icon_tertiary_alpha);
      border-radius: 16px;
      border: 1px solid var(${bg});
    }

    ::-webkit-scrollbar-button {
      display: none;
    }
    
    .ModalRoot & {
        ::-webkit-scrollbar-track {
            background-color:  ${bg === '--background_content' ? 'var(--modal_card_background)' : `var(${bg})`} 
        }
        
        ::-webkit-scrollbar {
            background-color:  ${bg === '--background_content' ? 'var(--modal_card_background)' : `var(${bg})`} 
        }
    }
`;
};
