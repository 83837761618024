/**
 * CellTagSelect styled container
 *
 * @author: exode <hello@exode.ru>
 */

import styled from 'styled-components';


export const SimpleCellWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;
