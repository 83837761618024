/**
 * TournamentHandleDisconnect component
 *
 * @author: exode <hello@exode.ru>
 */

import React, { useEffect } from 'react';

import { useApolloClient } from '@apollo/client';

import { Time } from '@/utils';
import { DocumentEvent } from '@/types/window';

import {
    TournamentFindOneLobbyDocument,
    TournamentGetStatusDocument,
    TournamentMembersDocument,
    TournamentMembersProgressDocument,
    TournamentMessagesDocument,
    UserFinishedTournamentDocument,
} from '@/codegen/graphql';


const TournamentHandleDisconnect = () => {

    const apolloClient = useApolloClient();

    useEffect(() => {
        const onConnectedAfterDisconnect = async () => {
            await Time.timer(500);

            apolloClient?.refetchQueries({
                include: [
                    TournamentMembersDocument,
                    TournamentMessagesDocument,
                    TournamentGetStatusDocument,
                    UserFinishedTournamentDocument,
                    TournamentFindOneLobbyDocument,
                    TournamentMembersProgressDocument,
                ],
            });
        };

        document.addEventListener(
            DocumentEvent.WsConnectedAfterDisconnect,
            onConnectedAfterDisconnect,
        );

        return () => {
            document.removeEventListener(
                DocumentEvent.WsConnectedAfterDisconnect,
                onConnectedAfterDisconnect,
            );
        };
    }, []);

    return (<></>);
};


export { TournamentHandleDisconnect };
