/**
 * Theme util
 *
 * @author: exode <hello@exode.ru>
 */

import React, { ReactElement } from 'react';

import { observer, PreferenceStore } from '@/store/preference/preference';


interface Props {
    children: ReactElement | ReactElement[];
}


const Light = observer((props: Props) => {
    return !PreferenceStore.isDark ? <>{props.children}</> : <></>;
});


const Dark = observer((props: Props) => {
    return PreferenceStore.isDark ? <>{props.children}</> : <></>;
});


const Theme = { Dark, Light };


export { Theme };
