/**
 * OtpInputWrapper component
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import OtpInput from 'react-otp-input';

import { ConfigStore, observer } from '@/store/core/config';

import { Container } from './OtpInputWrapper.styled';


interface Props {
    onChange: (value: number) => void;
    value: string | undefined;
    inputStyle?: Object;
    containerStyle?: Object;
}


const OtpInputWrapper = observer((props: Props) => {

    const { onChange, value, inputStyle, containerStyle } = props;

    return (
        <Container>
            <OtpInput isInputNum
                      numInputs={6}
                      value={value}
                      onChange={onChange}
                      shouldAutoFocus={ConfigStore.isDesktop}
                      separator={<span className="mx-2 m:mx-1 fs-20">-</span>}
                      containerStyle={{ margin: '0.5rem', justifyContent: 'center', ...(containerStyle ?? {}) }}
                      inputStyle={{
                          width: '1.8rem',
                          height: '2rem',
                          fontSize: '1.25rem',
                          border: '1px solid var(--activity_indicator_tint)',
                          borderRadius: '8px',
                          ...(inputStyle ?? {}),
                      }}/>
        </Container>
    );
});


export { OtpInputWrapper };
