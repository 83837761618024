/**
 * ProfileRoutes
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { PageSpace } from '@/shared/types';

import { RouterItemsType } from '@/types/router';
import { lazyWithRetry } from '@/helpers/react';

import { CatalogTabHeaderPart } from '@/components/Panel/Header';


const ActualTab = lazyWithRetry(() => import('@/tabs/Actual'));
const ProfilePage = lazyWithRetry(() => import( '@/pages/Profile/Profile'));
const ProfileFriendsPage = lazyWithRetry(() => import('@/pages/Profile/Friends'));
const ProfileLikesPage = lazyWithRetry(() => import('@/pages/Profile/Likes'));
const ProfileNotificationsPage = lazyWithRetry(() => import('@/pages/Profile/Notifications'));
const ProfileSubjectsPage = lazyWithRetry(() => import ('@/pages/Profile/Subjects'));
const ProfileWalletPage = lazyWithRetry(() => import('@/pages/Profile/Wallet'));
const ProfileWalletMoneySendPage = lazyWithRetry(() => import('@/pages/Profile/WalletMoneySend'));
const ProfileSharePage = lazyWithRetry(() => import('@/pages/Profile/Share'));


const ProfileRoutes: RouterItemsType = {
    '/friends': {
        view: <ProfileFriendsPage/>,
        type: 'tab',
        tab: 'menu',
        auth: [],
        spaces: [
            PageSpace.MarketplacePlatform,
        ],
    },
    '/likes': {
        view: <ProfileLikesPage/>,
        auth: [],
        spaces: [
            PageSpace.SchoolPlatform,
            PageSpace.MarketplacePlatform,
        ],
    },
    '/actual': {
        view: <ActualTab/>,
        header: <CatalogTabHeaderPart/>,
        type: 'tab',
        tab: 'education',
        spaces: [
            PageSpace.SchoolPlatform,
            PageSpace.MarketplacePlatform,
        ],
    },
    '/notifications': {
        view: <ProfileNotificationsPage/>,
        type: 'tab',
        tab: 'menu',
        auth: [],
        spaces: [
            PageSpace.SchoolPlatform,
            PageSpace.MarketplacePlatform,
        ],
    },
    '/@:userId([0-9_A-Za-z]+)': {
        view: <ProfilePage/>,
        type: 'tab',
        tab: 'menu',
        spaces: [
            PageSpace.SchoolPlatform,
            PageSpace.MarketplacePlatform,
        ],
    },
    '/@:userId([0-9_A-Za-z]+)/friends': {
        view: <ProfilePage/>,
        type: 'tab',
        tab: 'menu',
        spaces: [
            PageSpace.SchoolPlatform,
            PageSpace.MarketplacePlatform,
        ],
    },
    '/share': {
        view: <ProfileSharePage/>,
        type: 'tab',
        auth: [],
        spaces: [
            PageSpace.SchoolPlatform,
            PageSpace.MarketplacePlatform,
        ],
    },
    '/my-subjects': {
        view: <ProfileSubjectsPage/>,
        type: 'tab',
        tab: 'menu',
        auth: [],
        spaces: [
            PageSpace.SchoolPlatform,
            PageSpace.MarketplacePlatform,
        ],
    },
    '/wallet': {
        view: <ProfileWalletPage/>,
        type: 'tab',
        tab: 'menu',
        auth: [],
        spaces: [
            PageSpace.MarketplacePlatform,
        ],
    },
    '/wallet/:page([0-9]+)': {
        view: <ProfileWalletPage/>,
        type: 'tab',
        tab: 'menu',
        auth: [],
        spaces: [
            PageSpace.MarketplacePlatform,
        ],
    },
    '/wallet/send': {
        view: <ProfileWalletMoneySendPage/>,
        type: 'tab',
        tab: 'menu',
        auth: [],
        spaces: [
            PageSpace.MarketplacePlatform,
        ],
    },
    '/wallet/send/:userId([0-9]+)': {
        view: <ProfileWalletMoneySendPage/>,
        type: 'iframe',
        tab: 'menu',
        auth: [],
        spaces: [
            PageSpace.MarketplacePlatform,
        ],
    },
    '/wallet/send/successful': {
        view: <ProfileWalletMoneySendPage/>,
        type: 'iframe',
        tab: 'menu',
        auth: [],
        spaces: [
            PageSpace.MarketplacePlatform,
        ],
    },
};


export { ProfileRoutes };
