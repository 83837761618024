/**
 * TournamentCreationFormView
 *
 * @author: exode <hello@exode.ru>
 */

import React, { useEffect } from 'react';

import {
    CreateTournamentInput,
    SubjectFindManyQuery,
    TaskContentType,
    TournamentCreateMutation,
    TournamentStartMode,
    TournamentType,
} from '@/codegen/graphql';

import { observer, useStore } from '@/pages/Core';
import { ConfigStore } from '@/store/core/config';
import { TournamentCreationPageStore } from '../store';

import { Graphql, If } from '@/cutils';
import { Router } from '@/services/Utils/Router';
import { ContentCategorySlug } from '@/types/content';
import { tournamentStartMode } from '@/types/tournament';
import { TournamentService } from '@/services/Tournament/Tournament';

import { useTournamentCreate } from '@/hooks/apollo';

import { StickyButton } from '@/components/Atoms/StickyButton/StickyButton';
import { SelectMimicrySet } from '@/components/Atoms/SelectMimicrySet';
import { SubjectCategorySelector } from '@/components/Subject/CategorySelector';

import {
    Button,
    FormItem,
    FormLayoutGroup,
    SegmentedControl,
    Select,
    SizeType,
    Slider,
    Spinner,
    Text,
} from '@exode.ru/vkui';

import { SubjectSelectView } from './SubjectSelectView';


const TournamentCreationFormView = observer(() => {

    const { store, pageId, input, action, params } = useStore(TournamentCreationPageStore);

    const {
        createTournament,
        createTournamentError,
        createTournamentLoading,
    } = useTournamentCreate();

    const handleMutation = () => {
        const tournamentInput: CreateTournamentInput = {
            ...input,
            startAt: TournamentService.getTournamentStartAtDate(
                input.startMode === TournamentStartMode.Event
                    ? '0'
                    : input.startAt,
            ),
        };

        const onCompleted = async (data: TournamentCreateMutation) => {
            const { id, state } = data.tournamentCreate;

            await Router.afterUpdate();

            TournamentService.openAppropriatePageByState({
                id,
                state,
                isMember: true,
            }, 'replacePage');
        };

        return Router.authAction(() => createTournament(tournamentInput, onCompleted));
    };

    useEffect(() => {
        const { subjectCategoryId, create } = params;

        create && store.setInput('subject', create);
        subjectCategoryId && store.setInput('subjectCategoryIds', [ +subjectCategoryId ]);
    }, []);

    return (
        <form className="flex flex-col flex-1">
            <FormItem top="Как можно присоединиться?">
                <SegmentedControl className="thin-border"
                                  name="input.type"
                                  value={input.type}
                                  onChange={value => store.setInput('type', value as TournamentType)}
                                  options={[
                                      {
                                          label: 'Открытый',
                                          value: TournamentType.Public,
                                      },
                                      {
                                          label: 'По ссылке',
                                          value: TournamentType.Private,
                                      },
                                  ]}/>
            </FormItem>

            <FormLayoutGroup mode="horizontal">
                <FormItem top="Предмет турнира">
                    <SubjectFindManyQuery children={(result) => (
                        <>
                            <Graphql.Loading result={result}>
                                <Select disabled
                                        options={[]}
                                        placeholder="Предмет"
                                        icon={<Spinner size="small"/>}
                                        sizeY={SizeType.REGULAR}/>
                            </Graphql.Loading>

                            <Graphql.Success result={result}>
                                {({ subjectFindMany: subjects }) => (
                                    <SubjectSelectView subjects={subjects.items || []}/>
                                )}
                            </Graphql.Success>

                            <Graphql.Error result={result}>
                                <Select disabled sizeY={SizeType.REGULAR} options={[]} placeholder="Ошибка"/>
                            </Graphql.Error>
                        </>
                    )} variables={{
                        list: {},
                        filter: {
                            contentCategory: { slugs: [ ContentCategorySlug.Ege ] },
                        },
                    }}/>
                </FormItem>

                <FormItem top="Категории заданий">
                    <SelectMimicrySet pageOnMobile
                                      from={pageId}
                                      mode="modal"
                                      sizeY={SizeType.REGULAR}
                                      placeholder="Все категории"
                                      title={ConfigStore.isDesktop ? undefined : 'Выбор категорий'}
                                      rawModalCardProps={{ minHeight: 'calc(100vh - 150px)' }}
                                      selected={TournamentService.getCategoryLength(input.subjectCategoryIds?.length)}
                                      component={(
                                          <SubjectCategorySelector selectedSubjectCategoryIds={input.subjectCategoryIds}
                                                                   onCategorySelect={(subjectCategoryIds) => store.setInput('subjectCategoryIds', subjectCategoryIds)}
                                                                   filter={{
                                                                       subjects: [ input.subject ],
                                                                       minTaskCount: 1,
                                                                       task: {
                                                                           contentTypes: [
                                                                               TaskContentType.Tournament,
                                                                           ],
                                                                       },
                                                                   }}/>
                                      )}/>
                </FormItem>
            </FormLayoutGroup>

            <FormItem top="Количество пользователей">
                <Slider value={input.membersLimit}
                        onChange={value => store.setInput('membersLimit', value)}
                        step={1}
                        min={0}
                        max={20}/>
                <div className="flex justify-between">
                    <Text className="text-accent" weight="regular">
                        {(input.membersLimit === 0 ? 'Неограниченно' : input.membersLimit)}
                    </Text>

                    <Text className="text-secondary" weight="regular">
                        20
                    </Text>
                </div>
            </FormItem>

            <FormItem top="Количество заданий">
                <Slider value={input.taskCount}
                        onChange={value => store.setInput('taskCount', value)}
                        step={1}
                        min={5}
                        max={20}/>

                <div className="flex justify-between">
                    <Text className="text-accent" weight="regular">
                        {input.taskCount}
                    </Text>

                    <Text className="text-secondary" weight="regular">20</Text>
                </div>
            </FormItem>

            <FormItem top="Режим турнира">
                <SegmentedControl className="thin-border"
                                  name="input.startMode"
                                  onChange={(value) => store.setInput('startMode', value as TournamentStartMode)}
                                  value={input.startMode}
                                  options={[
                                      {
                                          value: TournamentStartMode.Event,
                                          label: tournamentStartMode[TournamentStartMode.Event],
                                      },
                                      {
                                          value: TournamentStartMode.Scheduled,
                                          label: tournamentStartMode[TournamentStartMode.Scheduled],
                                      },

                                  ]}/>
            </FormItem>

            <If is={input.startMode === TournamentStartMode.Scheduled}>
                <FormItem top="Время проведения турнира">
                    <Select name="input.startAt"
                            sizeY={SizeType.REGULAR}
                            value={input.startMode === TournamentStartMode.Scheduled ? input.startAt : '2'}
                            onChange={action(store.onInput)}
                            disabled={input.startMode !== TournamentStartMode.Scheduled}
                            options={[
                                {
                                    value: '2',
                                    label: input.startMode === TournamentStartMode.Scheduled
                                        ? 'Через 2 минуты' :
                                        '—',
                                },
                                {
                                    value: '5', label: 'Через 5 минут',
                                },
                                {
                                    value: '10', label: 'Через 10 минут',
                                },
                                {
                                    value: '15', label: 'Через 15 минут',
                                },
                                {
                                    value: '20', label: 'Через 20 минут',
                                },
                                {
                                    value: '25', label: 'Через 25 минут',
                                },
                                {
                                    value: '30', label: 'Через 30 минут',
                                },
                            ]}/>
                </FormItem>
            </If>

            <FormItem top="Размер ставки">
                <Slider value={input.bet}
                        onChange={value => store.setInput('bet', value)}
                        step={100}
                        min={100}
                        max={1000}/>
                <div className="flex justify-between">
                    <Text className="text-accent" weight="regular">{input.bet}</Text>
                    <Text className="text-secondary" weight="regular">1000</Text>
                </div>
            </FormItem>

            <StickyButton isTab>
                <FormItem className="p-0"
                          status={createTournamentError && 'error'}
                          bottom={createTournamentError && createTournamentError.message}>
                    <Button size="l"
                            stretched
                            mode="primary"
                            onClick={handleMutation}
                            loading={createTournamentLoading}>
                        Создать турнир
                    </Button>
                </FormItem>
            </StickyButton>
        </form>
    );
});


export { TournamentCreationFormView };
