/**
 * Sticky util
 *
 * @author: exode <hello@exode.ru>
 */

import { v4 } from 'uuid';

import React, { ReactElement, useEffect } from 'react';

import styled from 'styled-components';


interface Props {
    children: ReactElement;
    container: string;
    top: number;
    bottom: number;
    width: number;
    className?: string;
}


const StickyContainer = styled.div`
  .sticky__inner {
    left: initial !important;
    transform: translate3d(0, 0, 0);
  }
`;


const Sticky = (props: Props) => {

    const { container, top, bottom, width, className = '' } = props;

    const id = 'id' + v4().replace(/-/g, '');

    useEffect(() => {
        const sticker = document.getElementById(id);

        const sticky = new window.StickySidebar(sticker, {
            containerSelector: container,
            innerWrapperSelector: '.sticky__inner',
            topSpacing: top,
            bottomSpacing: bottom,
            minWidth: width,
        });

        return () => sticky.destroy();
    });

    return (
        <StickyContainer id={id} className={className} style={{ width, minWidth: width }}>
            <div className="sticky__inner">
                {props.children}
            </div>
        </StickyContainer>
    );
};


export { Sticky };
