/**
 * DragHandlerPart
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { Icon16DotsVertical6 } from '@vkontakte/icons';


const DragHandlerPart = () => {
    return (
        <div className="absolute right-[-10px] top-[-10px] p-1 vk-rounded shadow-lg z-[100] flex items-center justify-center bg-content">
            <Icon16DotsVertical6 fill="var(--accent)"/>
        </div>
    );
};


export { DragHandlerPart };
