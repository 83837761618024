/**
 * AnalyticsService
 *
 * @author: exode <hello@exode.ru>
 */

import { Storage } from '@/api/storage';
import { DocumentEvent } from '@/types/window';
import { BaseService } from '@/services/Core/Base';

import { UserAuthStore } from '@/store/user/auth';


class AnalyticsService extends BaseService {

    /**
     * Инициализация сервиса
     */
    static init() {
        document.addEventListener(DocumentEvent.AuthStateChanged, () => {
            this.setYMUserParams({ UserID: UserAuthStore.user?.id || null });
        });
    }

    /**
     * Открытие приложения native
     */
    static onNativeOpen() {
        this.incrementNativeOpen();
    }

    /**
     * Кол-во открытий приложений
     * @returns {number}
     */
    static get getNativeOpenCounter() {
        return +Storage.get('native:on-device-ready-counter', 0);
    }

    /**
     * Увеличение кол-ва открытий приложений
     */
    static incrementNativeOpen() {
        Storage.set('native:on-device-ready-counter', this.getNativeOpenCounter + 1);
    }

    /**
     * Установка параметров Яндекс Метрика
     */
    static setYMUserParams(params: { UserID?: number | null }) {
        window.ym?.(window.YM_ID, 'userParams', params);
    }

}


export { AnalyticsService };
