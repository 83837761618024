/**
 * AchievementRoutes
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { PageSpace } from '@/shared/types';

import { RouterItemsType } from '@/types/router';
import { lazyWithRetry } from '@/helpers/react';


const AchievementListPage = lazyWithRetry(() => import('@/pages/Achievement/List'));


const AchievementRoutes: RouterItemsType = {
    '/achievements': {
        view: <AchievementListPage/>,
        auth: [],
        spaces: [
            PageSpace.MarketplacePlatform,
        ],
    },
};


export { AchievementRoutes };
