/**
 * Template types
 *
 * @author: exode <hello@exode.ru>
 */

export enum TemplateSpace {
    Doc = 'Doc',
    Sms = 'Sms',
    Mail = 'Mail',
    Chat = 'Chat',
}

export enum TemplateType {
    MailFreeform = 'MailFreeform',
    MailUserOnboarding = 'MailUserOnboarding',
    MailUserPasswordRecover = 'MailUserPasswordRecover',
    MailUserLoginVerification = 'MailUserLoginVerification',
    MailUserPaymentReleased = 'MailUserPaymentReleased',

    MailSellerBecomeRequestFormDataChecking = 'MailSellerBecomeRequestFormDataChecking',
    MailSellerBecomeRequestFormDataDeclined = 'MailSellerBecomeRequestFormDataDeclined',
    MailSellerBecomeRequestOnAgreementSigning = 'MailSellerBecomeRequestOnAgreementSigning',
    MailSellerBecomeRequestAgreementChecking = 'MailSellerBecomeRequestAgreementChecking',
    MailSellerBecomeRequestAgreementDataDeclined = 'MailSellerBecomeRequestAgreementDataDeclined',
    MailSellerBecomeRequestVerified = 'MailSellerBecomeRequestVerified',

    ChatTgUserOnboarding = 'ChatTgUserOnboarding',
    ChatTgUserPasswordRecover = 'ChatTgUserPasswordRecover',

    SmsUserCredentials = 'SmsUserCredentials',
    SmsUserLoginVerification = 'SmsUserLoginVerification',

    DocSellerAgreement = 'docs/SellerAgreement',
}
