/**
 * Translate
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { observer } from '@/store/core/config';

import { getTranslated } from '@/hooks/core';
import { TranslateJson } from '@/shared/types';


interface Props {
    data: TranslateJson | undefined | null;
}


const Translate = observer((props: Props) => {
    return (
        <>
            {getTranslated(props.data)}
        </>
    );
});


export { Translate };
