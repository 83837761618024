/**
 * SearchSelect component
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import React, { ChangeEvent, useEffect } from 'react';

import { useI18n } from '@/hooks/core';

import { CustomSelect } from '@exode.ru/vkui';

import { SelectOptionPart } from './parts/SelectOptionPart';


export interface SelectSearchOption {
    value: string | number;
    label: string;
    avatar?: string;
    optional?: {
        [key: string | number]: any
    };
}

export interface SearchSelectStateProps {
    loading?: boolean;
    disabled?: boolean;
}


export interface SearchSelectProps extends SearchSelectStateProps {
    onSelect: (value: SelectSearchOption) => void;
    searchCallback: (value: string) => void;
    options: SelectSearchOption[];
    clearOnSelect?: boolean;
    avatarType?: 'user' | 'icon';
    selectedIds?: string[] | number[];
    emptyText?: string;
    dataTest?: string;
    placeholder?: string;
    avatarMode?: 'default' | 'image' | 'app';
    searchable?: boolean;
    initialSearch?: {
        deps: any[];
        skip: boolean;
        value?: string;
    };
}


const SearchSelect = (props: SearchSelectProps) => {

    const { t } = useI18n('components.Atoms.SearchSelect');

    const {
        options,
        loading,
        onSelect,
        disabled,
        dataTest,
        avatarType,
        selectedIds,
        initialSearch,
        searchCallback,
        searchable = true,
        clearOnSelect = false,
        avatarMode = 'default',
        placeholder = t('enter'),
        emptyText = t('searchDidGiveAnyResults'),
    } = props;

    const debouncedCallback = _.debounce((callback) => callback(), 500);

    const handleChange = (option: SelectSearchOption) => {
        onSelect(option);

        clearOnSelect && searchCallback('');
    };

    const handleInput = (event: ChangeEvent<HTMLSelectElement>) => {
        const { value } = event.target;

        debouncedCallback(() => searchCallback(value));
    };

    useEffect(() => {
        if (initialSearch?.skip === false) {
            searchCallback(initialSearch?.value || '');
        }
    }, initialSearch?.deps);

    return (
        <CustomSelect filterFn={false}
                      fetching={loading}
                      disabled={disabled}
                      data-test={dataTest}
                      onInput={handleInput}
                      emptyText={emptyText}
                      searchable={searchable}
                      options={options || []}
                      value={selectedIds?.[0]}
                      placeholder={placeholder}
                      onClose={() => setTimeout(() => searchCallback(''), 250)}
                      renderOption={(option) => (
                          <SelectOptionPart option={option}
                                            avatarMode={avatarMode}
                                            avatarType={avatarType}
                                            selectedIds={selectedIds}
                                            key={[ option.value, option.lang ].join('.')}/>
                      )}
                      onChange={(event) => {
                          const value = event.target.value;
                          const option = _.find(options, { value }) || _.find(options, { value: +value });

                          option && handleChange(option);
                      }}/>
    );
};


export { SearchSelect };
