/**
 * ManageSchoolUsersPage
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { Page } from '@/pages/Core';
import { useI18n } from '@/hooks/core';

import { useLocation } from 'router.tsx';

import { manageContextMenu } from '@/types/manage';
import { SchoolUserFindManyDocument } from '@/codegen/graphql';

import { ContextMenuItems } from '@/components/Atoms/ContextMenuItems';
import { SchoolContextInfo } from '@/components/School/ContextInfo';

import { MainContentView } from './views/MainContentView';


const ManageSchoolUsersPage = () => {

    const { t } = useI18n('pages.Manage.School.Users');

    const { route: { params } } = useLocation();

    return (
        <Page.Wrapper>
            <Page.Head>
                <Page.Header title={t('users')} refetchDocs={[
                    SchoolUserFindManyDocument,
                ]}/>
            </Page.Head>

            <Page.Content>
                <MainContentView/>
            </Page.Content>

            <Page.Context contextIsScrollable>
                <Page.ContextWrapper>
                    <ContextMenuItems statusComponent={<SchoolContextInfo/>}
                                      tabs={manageContextMenu(params).users.items}/>
                </Page.ContextWrapper>
            </Page.Context>
        </Page.Wrapper>
    );
};


export { ManageSchoolUsersPage };
