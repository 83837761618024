/**
 * FooterTextItemPart
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { Link } from '@/cutils';
import { RoutePathType } from '@/router/paths';
import { Router } from '@/services/Utils/Router';

import { FooterBlockItemBody } from '@/components/Navigation/Block/Footer/parts/FooterBlockItemPart';


interface Props {
    item: FooterBlockItemBody;
}


const FooterTextItemPart = (props: Props) => {

    const { item } = props;

    const { text, link, onClick } = item;

    const match = Router.matchLinkPathAppRoute(link || '');

    return (
        <Link blank={!match}
              onClick={onClick}
              disabled={!!onClick}
              toOuter={!match ? link : undefined}
              pushPage={match && {
                  id: match.path as RoutePathType,
                  params: match.params,
              }}>
            <span className="fs-14 m:fs-15 m-0 !cursor-pointer">
                {text}
            </span>
        </Link>
    );
};


export { FooterTextItemPart };
