/**
 * CreateDiscountInput
 *
 * @author: exode <hello@exode.ru>
 */

import {
    IsBoolean,
    IsEnum,
    IsInt,
    IsNotEmpty,
    IsNumber,
    IsOptional,
    IsString,
    MaxLength,
    Min,
    MinLength,
} from 'class-validator';

import { DiscountType } from '@/codegen/graphql';

import { isMinMax, isNotEmpty } from '@/libs/class-validator/constants';
import { DateCompare, IsOnlyDate, MinMaxIf } from '@/shared/validators';


export class CreateDiscountInput {

    @IsEnum(DiscountType)
    readonly type: DiscountType;

    @IsString()
    @IsNotEmpty({ message: isNotEmpty('Логин') })
    @MinLength(6, { message: isMinMax(6, 'Код', 'min', 'должен') })
    @MaxLength(12, { message: isMinMax(12, 'Код', 'max', 'должен') })
    readonly code: string;

    @IsNumber()
    @MinMaxIf(
        'max',
        (o) => o.type === DiscountType.Percent,
        100,
        'soft',
        { message: 'Значение не должно быть более 100%' },
    )
    @Min(1, { message: 'Значение должно быть больше нуля' })
    readonly value: number;

    @IsOptional()
    @IsBoolean()
    readonly active?: boolean;

    @IsOptional()
    @IsInt()
    readonly maxApplies?: number;

    @IsOptional()
    @DateCompare(
        'isSameOrBefore',
        'activeTo',
        { isOptional: true },
        { message: 'Дата не может быть больше окончания' },
    )
    @IsOnlyDate({
        message: 'Поле не может быть пустым',
    })
    readonly activeFrom?: Date;

    @IsOptional()
    @DateCompare(
        'isSameOrAfter',
        'activeFrom',
        { isOptional: true },
        { message: 'Дата не может быть меньше начала' },
    )
    @IsOnlyDate({
        message: 'Поле не может быть пустым',
    })
    readonly activeTo?: Date;

}
