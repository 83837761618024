/**
 * SubjectSelectView
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { observer, useStore } from '@/pages/Core';
import { TournamentCreationPageStore } from '../store';

import { Subjects } from '@/types/subject';

import { Select, SizeType } from '@exode.ru/vkui';


interface Props {
    subjects: Subjects;
}


const SubjectSelectView = observer((props: Props) => {

    const { subjects } = props;

    const { store, input, action } = useStore(TournamentCreationPageStore);

    return (
        <Select name="input.subject"
                value={input.subject}
                sizeY={SizeType.REGULAR}
                onChange={action(store.onInput)}
                onClose={() => store.setInput('subjectCategoryIds', [])}
                options={subjects.map(({ name, subject }) => ({
                    value: subject,
                    label: name,
                    disabled: false,
                }))}/>
    );
});


export { SubjectSelectView };
