/**
 * Filter usePricingList
 *
 * @author: exode <hello@exode.ru>
 */

import { useUrlFilters } from '@/hooks/core';

import { StoreCommonType } from '@/types/store';


export const defaultPricingListFilter = {
    productId: null as unknown as number,
    launchIds: [] as number[],
};

export const dtoFilterPricingListTransformer = (filter: typeof defaultPricingListFilter) => filter;

export const usePricingListFilter = (
    store: StoreCommonType,
    filter: Partial<typeof defaultPricingListFilter>,
) => useUrlFilters(
    { ...defaultPricingListFilter, ...filter },
    [],
    dtoFilterPricingListTransformer,
    { store },
);
