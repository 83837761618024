/**
 * UseCompanyGetBankName
 *
 * @author: exode <hello@exode.ru>
 */

import { useState } from 'react';

import { useI18n } from '@/hooks/core';

import { useCompanyGetBankInfoByBicLazyQuery } from '@/codegen/graphql';


export const useCompanyGetBankName = () => {

    const { t } = useI18n('hooks.apollo.seller');

    const [ bankSearch, setBankSearch ] = useState({ name: '', error: '' });

    const [ getBankName, { loading: getBankNameLoading } ] = useCompanyGetBankInfoByBicLazyQuery({
        notifyOnNetworkStatusChange: true,
        onError: () => {
            setBankSearch({
                name: '',
                error: t('bankNotFoundOrOccurredError'),
            });
        },
        onCompleted: ({ companyGetBankInfoByBic: info }) => {
            setBankSearch({
                name: info?.name || '',
                error: !info ? t('bankBySuchBicNotFound') : '',
            });
        },
    });

    return {
        getBankName,
        getBankNameLoading,
        bankSearch,
        setBankSearch,
    };
};
