/**
 * PhotoEditor styled container
 *
 * @author: exode <hello@exode.ru>
 */

import styled from 'styled-components';


export const Container = styled.div`
    .ModalCardBase__container {
        background: rgb(0, 0, 0);
    }

    .cropper {
        border-top-left-radius: var(--vkui--size_border_radius_paper--regular);
        border-top-right-radius: var(--vkui--size_border_radius_paper--regular);
        height: calc(var(--app-height) - 300px + 8px);
    }

    .ModalCardBase__container {
        padding: 0;
    }

    .rmc-cropper {
        color: var(--accent);

        .rmc-navigation__button {
            justify-content: center;
        }
    }
`;
