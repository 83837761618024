/**
 * Config store
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import moment from 'moment';

import { makeAutoObservable } from 'mobx';
import { observer } from 'mobx-react';

import { nanoid } from 'nanoid';

import { OS_PLATFORM } from '@/root/src/env';

import { Storage } from '@/api/storage';

import { Language } from '@/codegen/graphql';


interface InteractionState {
    pageLoadedAt: Date;
    lastClickAt: Date | null;
    lastMouseMoveAt: Date | null;
}


class Config {

    constructor() {
        makeAutoObservable(this);
    }

    /** Текущий язык */
    language: Language;

    /** Текущая ширина окна */
    width: number = typeof window !== 'undefined'
        ? window.innerWidth
        : 0;

    /** User in interaction and tab is active */
    interaction: InteractionState = {
        pageLoadedAt: new Date,
        lastClickAt: null as Date | null,
        lastMouseMoveAt: null as Date | null,
    };

    /** Socket connection has been closed */
    disconnected: { gql: boolean, sio: boolean, all: () => boolean } = {
        gql: true,
        sio: true,
        all: () => this.disconnected.sio && this.disconnected.gql,
    };

    /** Уникальный список id активных snackbar */
    snackbarKeys: Map<string, (number | string)[]> = new Map();

    /** Уникальный id вкладки */
    readonly tabUuid: string = `${nanoid()}`;

    /** Vk mini apps или webview */
    readonly isWebView: boolean = OS_PLATFORM != 'vk';

    /** Получение списка id активных snackbar */
    get getSnackbarKeys() {
        return this.snackbarKeys;
    }

    /** Проверка пользователь за устройством или нет */
    get isInInteractive() {
        const { lastClickAt, lastMouseMoveAt } = this.interaction;

        const byLastClick = moment().diff(lastClickAt, 'seconds') < 3;
        const byLastMouseMove = moment().diff(lastMouseMoveAt, 'seconds') < 3;

        return document.hasFocus() && (byLastClick || byLastMouseMove);
    }

    /** Является ли вкладка последней открытой */
    get isMainTab() {
        return this.tabUuid === Storage.get('tab-uuid');
    }

    /** Размер экрана ПК (больше телефона) */
    get isDesktop() {
        return this.width >= 768;
    }

    /** Размер экрана только ПК */
    get isDesktopOnly() {
        return this.width > 1208;
    }

    /** Размер экрана только мобильное устройство */
    get isMobileOnly() {
        return this.width < 480;
    }

    /** Размер экрана планшет или меньше */
    get isTablet() {
        return this.width <= 1208;
    }

    /** Размер экрана только планшет */
    get isTabletOnly() {
        return this.width >= 768 && this.width <= 1208;
    }

    /**
     * Изменение ширины экрана
     * @param {number} width
     */
    setWidth(width: number) {
        this.width = width;
    }

    /**
     * Изменение состояния соединения с сервером
     * @param {keyof typeof ConfigStore.disconnected} field
     * @param {boolean} disconnected
     */
    setDisconnected(
        field: keyof typeof ConfigStore.disconnected,
        disconnected: boolean,
    ) {
        ConfigStore.merge({
            disconnected: {
                ...ConfigStore.disconnected,
                [field]: disconnected,
            },
        });
    }

    /**
     * Merge
     * @param {Partial<this>} partial
     */
    merge(partial: Partial<this>) {
        Object.assign(this, partial);
    }

    /**
     * Сохранение взаимодействия с приложением
     * @param {Partial<{pageLoadedAt: Date, lastMouseAt: Date | null, lastClickAt: Date | null}>} partial
     */
    setInteraction(partial: Partial<InteractionState>) {
        _.assign(this.interaction, partial);
    }

}

const ConfigStore = new Config();


export { observer, ConfigStore };
