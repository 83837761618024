/**
 * Enums
 *
 * @author: exode <hello@exode.ru>
 */

/** @Duplicate of StorageFileType in Gql */
export enum FileTypeByMime {
    File = 'File',
    Image = 'Image',
    Video = 'Video',
}
