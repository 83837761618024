/**
 * UseCoursePublication
 *
 * @author: exode <hello@exode.ru>
 */

import { useProductManageUpsertReviewTicketMutation } from '@/codegen/graphql';

import { Router } from '@/services/Utils/Router';


export const useCoursePublication = () => {

    const [
        _upsertProductReviewTicket,
        { loading: upsertProductReviewTicketLoading },
    ] = useProductManageUpsertReviewTicketMutation({
        onCompleted(data) {
            Router.pushPage('/chat', { chatId: `${data.productManageUpsertReviewTicket.chat?.id}` });
        },
    });

    const upsertProductReviewTicket = (productId: number) => {
        return _upsertProductReviewTicket({
            variables: { productId },
        });
    };

    return {
        upsertProductReviewTicket,
        upsertProductReviewTicketLoading,
    };
};
