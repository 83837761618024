/**
 * Url util
 *
 * @author: exode <hello@exode.ru>
 */

import * as _ from 'lodash';

import * as urlVideoParser from 'js-video-url-parser';
import UrlParser from 'js-video-url-parser/lib/urlParser';

// @ts-ignore @aslan
import { SchoolDomainType } from '@/shared/types';


class Url {

    /**
     * System subdomains
     * @type {string[]}
     */
    static readonly systemSubdomains = [
        'vk',
        'cdn',
        'www',
        'ios',
        'android',
        'staging',
        'welcome',
    ];

    /**
     * Transform link to HTTPS
     * @param {string} link
     * @returns {string}
     */
    static toHttps(link?: string) {
        return link && link.replace('http://', 'https://');
    }

    /**
     * Retrieve get parameters from link/location
     * @param {string} link
     * @returns {SimpleObject}
     */
    static getParams(link?: string) {
        return this.parseQuery(link || window.location.search.slice(1));
    }

    /**
     * Get [name] parameter from url
     * @param name
     * @param byDefault
     * @param {string} link
     * @returns {SimpleObject}
     */
    static getParam(
        name: string,
        byDefault = '',
        link?: string,
    ) {
        const param = this.getParams(link)[name];

        return !_.isUndefined(param) ? param : byDefault;
    }

    /**
     * Transform object to encoded http query string
     * @param object
     * @param glue
     * @param addQuery - add "?" if not empty return string
     * @returns {string}
     */
    static objectToQuery(
        object: {},
        glue: string = '&',
        addQuery = false,
    ) {
        const query = _.map(object, (value: any, key: string) => {
            value = _.isString(value) ? value : JSON.stringify(value);

            return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
        }).join(glue);

        return (addQuery && query ? '?' : '') + query;
    }

    /**
     * Parse query string to object
     * @param queryString
     * @param glue
     * @param delimiter
     * @returns {{}}
     */
    static parseQuery(
        queryString: string,
        delimiter = '=',
        glue = '&',
    ): Record<string | number, any> {
        const query: Record<string | number, any> = {};

        const pairs = (
            queryString[0] === '?'
                ? queryString.substring(1)
                : queryString
        ).split(glue);

        if (!queryString?.trim()) {
            return query;
        }

        for (let i = 0; i < pairs.length; i++) {
            const pair = pairs[i].split(delimiter);
            query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
        }

        return query;
    }

    /**
     * Generate a link for native app or post-message page in a browser window
     * @param {object} params
     * @param post
     * @param origin
     * @returns {string}
     */
    static openApp(
        params: Record<any, any>,
        post = false,
        origin: string,
    ) {
        const query = Url.objectToQuery(params);
        const file = post ? 'post-message' : 'openapp';

        return [
            origin || '',
            (origin || '').endsWith('/') ? '' : '/',
            'static/utils/' + file + '.html?',
            query,
        ].join('');
    }

    /**
     * Parse video url
     * @param {string} videoLink
     * @returns {any}
     */
    static parseVideoUrl(videoLink: string) {
        const { parse } = (urlVideoParser as unknown as UrlParser);

        const result = parse(videoLink) || { id: '', provider: null };

        if (videoLink.includes('kinescope.io')) {
            result.provider = 'kinescope';
            result.id = videoLink.split('/').pop()?.split('?')[0] || '';
        }

        return result as {
            id: string;
            provider: 'youtube' | 'vimeo' | 'kinescope' | null
        };
    }

    /**
     * Safety parse url
     * @param {string} location
     * @returns {module:url.URL | URL | null}
     */
    static safetyParseUrl(location: string) {
        try {
            return new URL(location);
        } catch (e) {
            return null;
        }
    }

    /**
     * Check is video url is by third party provider (youtube, vimeo, etc. )
     * @param {string} videoLink
     * @returns {boolean}
     */
    static videoUrlIsThirdParty(videoLink?: string) {
        const { provider } = this.parseVideoUrl(videoLink || '');

        return !!provider;
    }

    /**
     * Get video url wrapper
     * @param {string} videoUrl
     * @returns {{default: string, maximum: string, medium: string}}
     */
    static getVideoWrapperUrl(videoUrl: string) {
        const { id, provider } = this.parseVideoUrl(videoUrl);

        switch (provider) {
            case 'youtube':
                return {
                    default: `https://img.youtube.com/vi/${id}/default.jpg`,
                    medium: `https://img.youtube.com/vi/${id}/mqdefault.jpg`,
                    maximum: `https://img.youtube.com/vi/${id}/maxresdefault.jpg`,
                };

            default:
                return {
                    default: null,
                    medium: null,
                    maximum: null,
                };
        }
    }

    /**
     * Get domain and subdomain info
     * @param {string} host
     * @returns {}
     */
    static getDomainAndSubdomainInfo(host?: string) {
        const parts = `${host}`.split('.');

        const domain = parts.slice(-2).join('.');
        const subdomain = parts.length > 2 ? parts.slice(0, 1).join('.') : null;

        const domainIsSchool = !domain?.startsWith('exode.');
        const subdomainIsSchool = !!subdomain && !this.systemSubdomains.includes(subdomain);

        return {
            domain,
            subdomain,
            domainIsSchool,
            subdomainIsSchool,
            /** Top level domain */
            tld: domain?.split('.').at(-1) || '',
            /** Second level domain */
            sld: domain?.split('.').at(0) || '',
        };
    }

    /**
     * Get school domain url
     * @param {'url' | 'text'} type
     * @param {{domainType: , baseDomain?: string | null, customDomain?: string | null}} partial
     * @param {any} appDomain
     * @returns {string}
     */
    static getSchoolDomainUrl(
        type: 'url' | 'text',
        appDomain: string,
        partial: {
            domainType: SchoolDomainType;
            baseDomain?: string | null;
            customDomain?: string | null;
        },
    ) {
        const domain = partial.domainType === SchoolDomainType.Base
            ? `${partial.baseDomain}.${appDomain}`
            : (partial.customDomain || '');

        switch (type) {
            case 'text':
                return domain;

            case 'url':
                return new URL(`https://${domain}`).href;
        }
    }

    /**
     * Compare on async
     * @param {string} location
     * @returns {{isSame: () => boolean}}
     */
    static compareOnAsync(location?: string) {
        const _location = location || window.location.href;

        return { locationIsSame: () => _location === window.location.href };
    }

}


export { Url };
