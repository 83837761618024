/**
 * AppGlobalSubscriptions component
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { AppChatSubscriptions } from '@/components/Subscriptions/Chat';
import { AppUserSubscriptions } from '@/components/Subscriptions/User';
import { AppSchoolSubscriptions } from '@/components/Subscriptions/School';
import { AppSocketSubscriptions } from '@/components/Subscriptions/Socket';
import { AppContentSubscriptions } from '@/components/Subscriptions/Content';
import { AppProfileSubscriptions } from '@/components/Subscriptions/Profile';
import { AppSessionSubscriptions } from '@/components/Subscriptions/Sessions';
import { AppTournamentSubscription } from '@/components/Subscriptions/Tournaments';
import { AppNotificationSubscriptions } from '@/components/Subscriptions/Notification';
import { AppLocalStorageSubscriptions } from '@/components/Subscriptions/LocalStorage';


export const AppGlobalSubscriptions = () => (
    <>
        <AppChatSubscriptions/>
        <AppContentSubscriptions/>
        <AppLocalStorageSubscriptions/>
        <AppNotificationSubscriptions/>
        <AppProfileSubscriptions/>
        <AppSchoolSubscriptions/>
        <AppSessionSubscriptions/>
        <AppSocketSubscriptions/>
        <AppTournamentSubscription/>
        <AppUserSubscriptions/>
    </>
);
