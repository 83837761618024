/**
 * Middleware util
 *
 * @author: exode <hello@exode.ru>
 */

import React, { ReactElement } from 'react';

import { useParams } from 'router.tsx';

import { Url } from '@/utils';
import { Permissions } from '@/types/rbac';
import { Permission } from '@/codegen/graphql';
import { useLocation } from '@/router/index';

import { RoutePathType } from '@/router/paths';
import { Router } from '@/services/Utils/Router';

import { RouterItemType } from '@/types/router';
import { Auth, Rbac, Redirect, Unauth } from '@/cutils';
import { RbacForbiddenPage } from '@/pages/Rbac/Forbidden';


interface MiddlewareAuthProps {
    id: RoutePathType;
    children: ReactElement | ReactElement[];
    permissions?: Permissions;
    onGuardForbidden?: RouterItemType['onGuardForbidden'];
}

interface MiddlewareUnauthProps {
    children: ReactElement | ReactElement[];
    onGuardForbidden?: RouterItemType['onGuardForbidden'];
}


const OnGuardForbidden = (props: Pick<MiddlewareUnauthProps, 'onGuardForbidden'>) => {
    const { onGuardForbidden } = props;

    const params = useParams();

    onGuardForbidden && onGuardForbidden({ params });

    return <></>;
};


const MiddlewareAuth = (props: MiddlewareAuthProps) => {

    const { id, children, permissions, onGuardForbidden } = props;

    return (
        <>
            <Auth>
                {(
                    !permissions?.length
                        ? children
                        : (
                            <Rbac.Wrapper permissions={permissions as Permission[]}>
                                <Rbac.Allowed>
                                    {children}
                                </Rbac.Allowed>

                                <Rbac.Forbidden>
                                    <OnGuardForbidden onGuardForbidden={onGuardForbidden}/>
                                    <RbacForbiddenPage required={permissions as Permission[]}/>
                                </Rbac.Forbidden>
                            </Rbac.Wrapper>
                        )
                )}
            </Auth>

            <Unauth>
                <OnGuardForbidden onGuardForbidden={onGuardForbidden}/>
                <Redirect replace to="/login" params={{ to: id, toParams: Router.urlQueryParams }}/>
            </Unauth>
        </>
    );
};

const MiddlewareUnauth = (props: MiddlewareUnauthProps) => {

    const { onGuardForbidden } = props;

    const { route: { params: { to = '/', toParams = '', toModalParams = '' } } } = useLocation();

    const params = Url.parseQuery([
        toParams,
        /** In the focused tab triggers Router.openAfterLogin method by POST login action */
        !document.hasFocus() && toModalParams,
    ].filter(e => e).join('&'));

    return (
        <>
            <Auth>
                <>
                    <OnGuardForbidden onGuardForbidden={onGuardForbidden}/>
                    <Redirect replace params={params} to={to as RoutePathType}/>
                </>
            </Auth>

            <Unauth>
                {props.children}
            </Unauth>
        </>
    );
};


const Middleware = {
    Auth: MiddlewareAuth,
    Unauth: MiddlewareUnauth,
};


export { Middleware };
