/**
 * ProgressButton styled container
 *
 * @author: exode <hello@exode.ru>
 */

import styled from 'styled-components';


export const Container = styled.div`
    border-radius: var(--vkui--size_border_radius--regular);

    .progress-line {
        top: 4px;
        left: 4px;
        height: calc(100% - 8px);
        backdrop-filter: blur(4px);
        -webkit-backdrop-filter: blur(4px);
        transition: width 0.2s ease;
        border-radius: var(--vkui--size_border_radius--regular);

        body[scheme=bright_light] & {
            color: var(--accent);
            background: var(--background_content);
        }

        body[scheme=space_gray] & {
            background: rgba(25, 25, 26, 80%);
            color: white !important;
        }
    }
`;
