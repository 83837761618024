/**
 * Gesture util
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import React from 'react';

import { Gestures } from 'react-gesture-handler';


interface PanProps {
    panup?: Function;
    panleft?: Function;
    panright?: Function;
    pandown?: Function;
    panstart?: Function;
    panmove?: Function;
    panend?: Function;
    pancancel?: Function;
}

interface PinchProps {
    pinchstart?: Function;
    pinchmove?: Function;
    pinchend?: Function;
    pinchcancel?: Function;
    pinchin?: Function;
    pinchout?: Function;
}

interface PressProps {
    press?: Function;
    pressup?: Function;
}

interface RotateProps {
    rotatestart?: Function;
    rotatemove?: Function;
    rotateend?: Function;
    rotatecancel?: Function;
}

interface SwipeProps {
    swipe?: Function;
    swipeleft?: Function;
    swiperight?: Function;
    swipeup?: Function;
    swipedown?: Function;
}

interface TapProps {
    tap?: Function;
}


class GestureBase<T> extends React.Component<T> {

    name = 'Base';

    createEvents(mapping: {}, handler: Function) {
        const events: { [key: string]: Function } = {};

        for (const event in mapping) {
            events[event] = handler;
        }

        return events;
    }

    render() {
        const handler = (e: HammerInput) => {
            const callback = this.props[e.type as keyof T];

            _.isFunction(callback) && callback();
        };

        const recognizers = { [this.name]: { events: this.createEvents(this.props, handler) } };

        return (
            <Gestures recognizers={recognizers}>{this.props.children}</Gestures>
        );
    }

}


class GesturePan extends GestureBase<PanProps> {

    name = 'Pan';

}


class PinchGesture extends GestureBase<PinchProps> {

    name = 'Pinch';

}


class PressGesture extends GestureBase<PressProps> {

    name = 'Press';

}


class RotateGesture extends GestureBase<RotateProps> {

    name = 'Rotate';

}


class SwipeGesture extends GestureBase<SwipeProps> {

    name = 'Swipe';

}


class TapGesture extends GestureBase<TapProps> {

    name = 'Tap';

}


const Gesture = {
    Pan: GesturePan,
    Pinch: PinchGesture,
    Press: PressGesture,
    Rotate: RotateGesture,
    Swipe: SwipeGesture,
    Tap: TapGesture,
};


export { Gesture };
