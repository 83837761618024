/**
 * SelectionPart
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import React, { useState } from 'react';

import { Graphql } from '@/cutils';
import { useI18n } from '@/hooks/core';
import { observer, Page } from '@/pages/Core';
import { SubjectCategorySelectionFindManyQuery } from '@/codegen/graphql';

import { AdaptiveSelect } from '@/components/Atoms/AdaptiveSelect';
import { SubjectCategorySelectionCard } from '@/components/Subject/CategorySelectionCard';
import { SubjectCategorySelectorProps } from '@/components/Subject/CategorySelector';


interface Props extends SubjectCategorySelectorProps {

}


const SelectionPart = observer((props: Props) => {

    const { filter, onCategorySelect, selectedSubjectCategoryIds } = props;

    const { t } = useI18n('components.Subject.CategorySelector.parts');

    const [ selected, setSelected ] = useState(selectedSubjectCategoryIds);

    return (
        <SubjectCategorySelectionFindManyQuery children={(result) => (
            <>
                <Graphql.Loading result={result}>
                    <Page.Spinner/>
                </Graphql.Loading>

                <Graphql.Success result={result}>
                    {({ subjectCategorySelectionFindMany: { items: selections } }) => (
                        <AdaptiveSelect isTab
                                        useInModal
                                        isActionSheets
                                        options={[]}
                                        multiple={{}}
                                        buttonText={t('select')}
                                        stickyButtonClassName="px-0 m:px-2"
                                        value={selectedSubjectCategoryIds}
                                        forceButtonEnabled={!!selected.length}
                                        callback={() => onCategorySelect(selected)}>
                            <div className="grid grid-cols-2 gap-3 m:px-2">
                                {selections?.map(selection => {
                                    const categoriesLength = selection.categories.length;
                                    const categoryIds = _.map(selection.categories, ({ id }) => id);

                                    return (
                                        <SubjectCategorySelectionCard key={selection.id}
                                                                      onClick={() => setSelected(categoryIds)}
                                                                      active={_.isEqual(selected, categoryIds)}
                                                                      header={selection.name}
                                                                      categoriesLength={categoriesLength}/>
                                    );
                                })}
                            </div>
                        </AdaptiveSelect>
                    )}
                </Graphql.Success>

                <Graphql.Error result={result}/>
            </>
        )} variables={{
            list: {},
            filter: _.pick(filter, [ 'subjects' ]),
        }}/>
    );
});


export { SelectionPart };
