/**
 * MakeTransferWalletInput
 *
 * @author: exode <hello@exode.ru>
 */

import { IsNotEmpty, IsOptional, IsString, Max, Min } from 'class-validator';

import { isNotEmpty } from '../constants';


export class MakeTransferWalletInput {

    @IsNotEmpty({ message: isNotEmpty('Сумма', 'пустой') })
    @Min(10, { message: 'Минимальная сумма перевода 10 ex.' })
    @Max(3000, { message: 'Максимум 3000 ex. за операцию' })
    readonly amount: number;

    @IsString()
    @IsOptional()
    readonly comment: string;

}
