/**
 * Registering all existing routes
 *
 * @author: exode <hello@exode.ru>
 *
 * @router-regexp https://www.npmjs.com/package/path-to-regexp
 * @route-tester http://forbeslindesay.github.io/express-route-tester/
 */

import _ from 'lodash';

import { RouterItemsType, RouterItemType } from '@/types/router';

import { RoutePathType } from '@/router/paths';

import { Router } from '@/services/Utils/Router';

import { SystemRoutes } from '@/router/routes/System';
import { TabRoutes } from '@/router/routes/Tab';
import { LandingRoutes } from '@/router/routes/Landing';

import { AchievementRoutes } from '@/router/routes/Achievement';
import { BookmarkRoutes } from '@/router/routes/Bookmark';
import { ChatRoutes } from '@/router/routes/Chat';
import { CollaborationRoutes } from '@/router/routes/Collaboration';
import { CompanyRoutes } from '@/router/routes/Company';
import { CourseRoutes } from '@/router/routes/Course';
import { ExamRoutes } from '@/router/routes/Exam';
import { ManageRoutes } from '@/router/routes/Manage';
import { PayRoutes } from '@/router/routes/Pay';
import { ProfileRoutes } from '@/router/routes/Profile';
import { PromotionRoutes } from '@/router/routes/Promotion';
import { QuestionRoutes } from '@/router/routes/Question';
import { RangeRoutes } from '@/router/routes/Range';
import { SchoolRoutes } from '@/router/routes/School';
import { TaskRoutes } from '@/router/routes/Task';
import { TournamentRoutes } from '@/router/routes/Tournament';
import { UserRoutes } from '@/router/routes/User';
import { UtilsRoutes } from '@/router/routes/Utils';
import { WikiRoutes } from '@/router/routes/Wiki';


const routes = {
    /** APP LOGIC ROUTES: */
    ...SystemRoutes,
    ...TabRoutes,
    ...LandingRoutes,

    /** FEATURE-BASE ROUTES: (A-Z) */
    ...AchievementRoutes,
    ...BookmarkRoutes,
    ...ChatRoutes,
    ...CollaborationRoutes,
    ...CompanyRoutes,
    ...CourseRoutes,
    ...ExamRoutes,
    ...ManageRoutes,
    ...PayRoutes,
    ...ProfileRoutes,
    ...PromotionRoutes,
    ...QuestionRoutes,
    ...RangeRoutes,
    ...SchoolRoutes,
    ...TaskRoutes,
    ...TournamentRoutes,
    ...UserRoutes,
    ...UtilsRoutes,
    ...WikiRoutes,
};


for (const key in routes) {
    if (!routes.hasOwnProperty(key)) {
        continue;
    }

    const route = routes[key as RoutePathType] as RouterItemType;

    route.type = route.type ? route.type : 'page';
    route.viewId = key as RoutePathType;

    if (!Router.filterBySpaces(route.spaces)) {
        Object.assign(route, _.pick(routes['/404'], [
            'type',
            'viewId',
        ]));
    }

    routes[key as RoutePathType] = Object.assign(routes[key as RoutePathType] as Object, route);
}


/** List of all routes */
export const Routes: RouterItemsType = Object.freeze(routes);
