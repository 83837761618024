/**
 * CodePart
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import toast from 'react-hot-toast';

import { atomOneDark, CopyBlock } from 'react-code-blocks';

import { useI18n } from '@/hooks/core';


const CodePart = ({ data }: any) => {

    const { t } = useI18n('components.Atoms.EditorJsOutput.parts');

    const props = {
        codeBlock: true,
        wrapLines: true,
        copied: false,
        theme: atomOneDark,
        text: data.html,
        language: 'jsx',
        wrapLongLines: false,
        showLineNumbers: false,
        onCopy: () => toast.success(t('copied')),
    };

    return <CopyBlock {...props}/>;
};


export { CodePart };
