/**
 * SchoolRoutes
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { PageSpace } from '@/shared/types';

import { RouterItemsType } from '@/types/router';
import { lazyWithRetry } from '@/helpers/react';


const SchoolCreatePage = lazyWithRetry(() => import('@/pages/School/Create'));


const SchoolRoutes: RouterItemsType = {
    '/school/create': {
        view: <SchoolCreatePage/>,
        type: 'iframe',
        subType: 'tab',
        tab: 'menu',
        auth: [],
        spaces: [ PageSpace.BizWelcomePlatform ],
    },
};


export { SchoolRoutes };
