/**
 * UseSchoolSession
 *
 * @author: exode <hello@exode.ru>
 */

import {
    useSchoolUserManageSessionKillAllMutation,
    useSchoolUserManageSessionKillByIdMutation,
} from '@/codegen/graphql';


export const useSchoolSession = () => {

    const [
        _killAllSessions,
        { loading: killAllSessionsLoading },
    ] = useSchoolUserManageSessionKillAllMutation({
        onError(error) {
            console.error(error);
        },
    });

    const killAllSessions = (userId: number) => {
        return _killAllSessions({ variables: { userId } });
    };

    const [
        _killSessionById,
        { loading: killSessionByIdLoading },
    ] = useSchoolUserManageSessionKillByIdMutation({
        onError(error) {
            console.error(error);
        },
    });

    const killSessionById = (sessionId: number) => {
        return _killSessionById({ variables: { sessionId } });
    };

    return {
        killSessionById,
        killSessionByIdLoading,
        killAllSessions,
        killAllSessionsLoading,
    };
};
