/**
 * CatalogTabHeaderPart
 *
 * @author: exode <hello@exode.ru>
 */

/** Fix first header render */
import '@/cutils';

import React from 'react';

import { Page } from '@/pages/Core';
import { useI18n } from '@/hooks/core';

import { PanelHeader, PanelHeaderContent } from '@exode.ru/vkui';


const CatalogTabHeaderPart = () => {

    const { t } = useI18n('components.Panel.Header.Tab.parts');

    return (
        <PanelHeader shadow separator={false} className="tab-title">
            <PanelHeaderContent>
                <Page.HeaderContentWrapper>
                    <>{t('coursesOfCatalog')}</>
                </Page.HeaderContentWrapper>
            </PanelHeaderContent>
        </PanelHeader>
    );
};


export { CatalogTabHeaderPart };
