/**
 * ContentCard styled container
 *
 * @author: exode <hello@exode.ru>
 */

import styled from 'styled-components';

import { responsive } from '@/styles/modules/responsive';

import { contentCardAfterIconButtonStyle } from '@/components/Atoms/Styled';

import { ContentCardProps } from './ContentCard';


export const Container = styled.div<ContentCardProps>`
    display: flex;
    position: relative;
    min-width: ${({ width }) => width || 'auto'};

    &.thin-border .ContentCard {
        box-shadow: inset 0 0 0 var(--thin-border) var(--vkui--color_separator_primary);
    }

    &.card-clear-before {
        .vkuiCard:before {
            display: none;
        }
    }

    .ContentCard {
        display: flex;
        flex: 1 1;
        width: 100%;

        ${(props) => props.transparent && 'background: transparent;'};

        .vkuiCard__in {
            flex: 1
        }

        &__body {
            cursor: ${({ wholeCardActive }) => wholeCardActive ? 'pointer' : 'initial'};
        }
    }

    .ContentCard__body {
        padding: ${({ compact }) => compact ? '0px' : ''};
        padding: ${({ equalPaddings }) => equalPaddings ? 'var(--vkui--size_base_padding_vertical--regular)' : ''};
    }

    ${contentCardAfterIconButtonStyle};

    .right-button {
        ${responsive([ 'mobile' ])} {
            right: ${({ hideOverflow }) => hideOverflow ? '4px' : 0};
        }

        .IconButton {
            pointer-events: ${({ activeAfter, wholeCardActive }) => {
                return wholeCardActive ? 'all' : (activeAfter ? '' : 'none');
            }};
        }
    }

    .vertical-separator {
        position: absolute;
        height: 100%;
        width: 2px;
        right: 64px;
        background-color: var(--accent);
        opacity: 0.6;
    }
`;
