/**
 * School types
 *
 * @author: exode <hello@exode.ru>
 */

export enum SchoolDomainType {
    Base = 'Base',
    Custom = 'Custom',
}
