/**
 * DesktopNavigationContextMenu component
 *
 * @author: exode <hello@exode.ru>
 */

import React, { ReactElement } from 'react';

import { observer, PageStore } from '@/store/core/page';

import { EpicViewType } from '@/types/router';
import { If, Portal, Responsive, Sticky } from '@/cutils';

import { SplitCol } from '@exode.ru/vkui';

import { PanelHeaderSpace } from '@/components/Panel/Header';
import { DesktopManageServicesList } from '@/components/Desktop/Manage/ManageServicesList';

import { Container } from './DesktopNavigationContextMenu.styled';


interface Props {
    type: EpicViewType;
}


const DesktopNavigationContextMenu = observer((props: Props) => {

    const { type } = props;

    const withStickyByMode = (component: ReactElement) => (
        <>
            <If is={PageStore.contextMode === 'scrollable'}>
                <div className="sticky top-header h-fit">
                    {component}
                </div>
            </If>

            <If is={PageStore.contextMode === 'regular'}>
                <Sticky top={50}
                        bottom={0}
                        width={280 + 32}
                        className="context-sticky !static"
                        container="#viewport-wrapper">
                    {component}
                </Sticky>
            </If>
        </>
    );

    return (
        <>
            <If is={type !== 'iframe' && PageStore.mode === 'regular'}>
                <Responsive.Desktop>
                    {withStickyByMode(
                        <Container className={[
                            PageStore.contextMode === 'scrollable' ? 'is-scrollable-context' : '',
                        ].join(' ')}>
                            <SplitCol spaced className="viewport__right-menu windows:scrollbar-hidden">
                                <PanelHeaderSpace offset={18}/>
                                <Portal renderer id="menu:context"/>

                                <div className={PageStore.footer ? 'd:pb-9' : ''}/>
                            </SplitCol>
                        </Container>,
                    )}
                </Responsive.Desktop>
            </If>

            <DesktopManageServicesList/>
        </>
    );
});


export { DesktopNavigationContextMenu };
