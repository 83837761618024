/**
 * UnderlinePageRowHeader
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import React, { ReactElement } from 'react';

import { Counter, Header } from '@exode.ru/vkui';

import { gradient } from '@/styles/modules/gradient';


interface Props {
    title: string;
    count?: number | string;
    headerClassName?: string;
    textClassName?: string;
    counterClassName?: string;
    aside?: ReactElement;
    asideGradient?: string;
    children?: ReactElement;
    disableUnderline?: boolean;
}


const UnderlinePageRowHeader = (props: Props) => {

    const {
        title,
        count,
        children,
        textClassName,
        headerClassName,
        counterClassName,
        disableUnderline,
        asideGradient = `${gradient.purple500ToBlue500} dark:blue-purple-gradient`,
    } = props;

    const aside = props.aside || (!_.isNil(count) && (
        <Counter size="s" mode="primary" data-test="course.study-progress-count" className={[
            asideGradient,
            counterClassName,
            'p-1 text-white bg-gradient-to-br',
        ].join(' ')}>
            {count}
        </Counter>
    ));

    return (
        <>
            {children}

            <Header mode="primary" aside={aside} className={headerClassName}>
                <span className={[ !disableUnderline && 'text-underline-marker', textClassName ].join(' ')}>
                    {title}
                </span>
            </Header>
        </>
    );
};


export { UnderlinePageRowHeader };
