/**
 * Product types
 *
 * @author: exode <hello@exode.ru>
 */

import React, { ReactElement } from 'react';

import { useI18n } from '@/hooks/core/useI18n';

import { GqlResult } from '@/types/graphql';

import {
    DiscountManageFindManyQueryResult,
    DiscountManageFindOneQueryResult,
    DiscountUnAppliedReason,
    ProductAccessFindManyQueryResult,
    ProductAccessFragmentFragment,
    ProductCurrency,
    ProductLaunchByDateStatus,
    ProductLaunchFindOneQueryResult,
    ProductLaunchStatus,
    ProductPriceFindOneQueryResult,
    ProductStatus,
} from '@/codegen/graphql';

import {
    Icon20Pause,
    Icon20Play,
    Icon20Write,
    Icon24Error,
    Icon24Forward,
    Icon24Privacy,
    Icon24SnowflakeOutline,
    Icon24View,
    Icon28SkipPrevious,
} from '@vkontakte/icons';


export type ProductPriceItem = GqlResult<ProductPriceFindOneQueryResult>['productPriceFindOne'];
export type ProductLaunchItem = GqlResult<ProductLaunchFindOneQueryResult>['productLaunchFindOne'];
export type DiscountItem = GqlResult<DiscountManageFindOneQueryResult>['discountManageFindOne'];
export type DiscountFindMany = GqlResult<DiscountManageFindManyQueryResult>['discountManageFindMany'];
export type ProductAccessListItem = NonNullable<GqlResult<ProductAccessFindManyQueryResult>['productAccessFindMany']['items']>[number]
export type ProductAccessProgressItem = ProductAccessFragmentFragment;

/**
 * Статус продукта
 */
export const productStatus = () => {

    const { t } = useI18n('types.product');

    return {
        [ProductStatus.Declined]: t('rejected'),
        [ProductStatus.Draft]: t('draft'),
        [ProductStatus.OnCheck]: t('underReview'),
        [ProductStatus.Published]: t('published'),
        [ProductStatus.ReadyToPublish]: t('readyToPublish'),
    } as const;
};

/**
 * Статус запуска продукта
 */
export const productLaunchStatus = () => {

    const { t } = useI18n('types.product');

    return {
        [ProductLaunchStatus.Declined]: t('rejected'),
        [ProductLaunchStatus.Draft]: t('draft'),
        [ProductLaunchStatus.OnCheck]: t('underReview'),
        [ProductLaunchStatus.Published]: t('published'),
        [ProductLaunchStatus.Unpublished]: t('notPublished'),
    } as const;
};

/**
 * Виды валюты
 */
export const productCurrency: { [type in ProductCurrency]: string } = {
    [ProductCurrency.Eur]: '€',
    [ProductCurrency.Exes]: 'ex.',
    [ProductCurrency.Free]: '',
    [ProductCurrency.Kzt]: '₸',
    [ProductCurrency.Rub]: '₽',
    [ProductCurrency.Usd]: '$',
    [ProductCurrency.Uzs]: 'сум',
};

export const productLaunchByDateStatusIcon: Record<ProductLaunchByDateStatus, ReactElement> = {
    [ProductLaunchByDateStatus.Active]: <Icon20Play/>,
    [ProductLaunchByDateStatus.Draft]: <Icon20Write/>,
    [ProductLaunchByDateStatus.Past]: <Icon28SkipPrevious/>,
    [ProductLaunchByDateStatus.Future]: <Icon24Forward/>,
    [ProductLaunchByDateStatus.Declined]: <Icon24Error/>,
    [ProductLaunchByDateStatus.Inactive]: <Icon24Privacy/>,
    [ProductLaunchByDateStatus.OnCheck]: <Icon24View/>,
    [ProductLaunchByDateStatus.NoActualPrices]: <Icon24SnowflakeOutline/>,
    [ProductLaunchByDateStatus.ReadyToPublish]: <Icon20Pause/>,
};

export const productLaunchByDateStatusColor: { [type in ProductLaunchByDateStatus]: string } = {
    [ProductLaunchByDateStatus.Draft]: 'var(--icon_secondary)',
    [ProductLaunchByDateStatus.Past]: 'var(--icon_secondary)',
    [ProductLaunchByDateStatus.Future]: 'var(--iris_blue)',
    [ProductLaunchByDateStatus.Active]: 'var(--dynamic_green)',
    [ProductLaunchByDateStatus.Inactive]: 'var(--icon_secondary)',
    [ProductLaunchByDateStatus.Declined]: 'var(--dynamic_red)',
    [ProductLaunchByDateStatus.OnCheck]: 'var(--exode_orange)',
    [ProductLaunchByDateStatus.NoActualPrices]: 'var(--exode_orange)',
    [ProductLaunchByDateStatus.ReadyToPublish]: 'var(--dynamic_green)',
};

export const productLaunchByDateStatus = () => {

    const { t } = useI18n('types.product');

    return {
        [ProductLaunchByDateStatus.Past]: t('finished'),
        [ProductLaunchByDateStatus.Draft]: t('draft'),
        [ProductLaunchByDateStatus.Active]: t('active'),
        [ProductLaunchByDateStatus.Inactive]: t('turnedOff'),
        [ProductLaunchByDateStatus.Declined]: t('rejected'),
        [ProductLaunchByDateStatus.OnCheck]: t('underReview'),
        [ProductLaunchByDateStatus.Future]: t('scheduled'),
        [ProductLaunchByDateStatus.NoActualPrices]: t('noActivePrices'),
        [ProductLaunchByDateStatus.ReadyToPublish]: t('readyToPublish'),
    } as const;
};


export const discountUnAppliedReasonName = () => {

    const { t } = useI18n('types.product');

    return {
        [DiscountUnAppliedReason.NotActive]: t('promoCodeInActive'),
        [DiscountUnAppliedReason.ApplyLimitExceeded]: t('promoCodeUsageLimitHasBeenReached'),
        [DiscountUnAppliedReason.NotApplicableToProduct]: t('thePromotionalCodeIsNotApplicableToItemsInTheCart'),
    } as const;
};

