/**
 * SectionTitle
 *
 * @author: exode <hello@exode.ru>
 */

import React, { ReactNode } from 'react';

import { Text } from '@exode.ru/vkui';


interface Props {
    title: ReactNode;
    className?: string;
}


const SectionTitle = (props: Props) => {

    const { title, className } = props;

    return (
        <Text style={{ lineHeight: '36px' }}
              className={[ 'd:fs-25 w-fit d:mt-4 d:mb-6 m:mb-2 m:mx-3', className ].join(' ')}>
            {title}
        </Text>
    );
};


export { SectionTitle };
