/**
 * Number
 *
 * @author: exode <hello@exode.ru>
 */

import * as _ from 'lodash';


class NumberUtil {

    /**
     * Division of a number by digits
     * @param {number} x
     * @param {number} toFixed
     * @param {string} delimiter
     * @returns {string}
     */
    static splitByDecimal(
        x: number = 0,
        toFixed = 0,
        delimiter: string = ' ',
    ) {
        return x.toFixed(toFixed).replace(/\B(?=(\d{3})+(?!\d))/g, delimiter);
    }

    /**
     * Collapse range with dash
     * @returns {Array<string>}
     * @param array
     */
    static getRanges(array: number[]) {
        const ranges = [];
        let start, last, length;

        for (let i = 0; i < array.length; i++) {
            length = 0;
            start = array[i];
            last = start;

            while (array[i + 1] - array[i] == 1) {
                last = array[i + 1];
                length++;
                i++;
            }

            const alone = start === last;

            if (alone || length <= 1) {
                alone ? ranges.push(start) : ranges.push(start, last);
                continue;
            }

            ranges.push(start + '-' + last);
        }

        return ranges;
    }

    /**
     * Round money
     * @Note: standard for a project money calculation
     * @param {number} amount
     * @returns {number}
     */
    static roundMoney(amount: number) {
        return _.round(amount, 2);
    }

    /**
     * Get percent with min amount
     * @param {number} amount
     * @param {number} percent
     * @param {number} minAmount
     * @returns {number}
     * @Note: standard for a project money calculation
     */
    static getPercentWithMinAmount(
        amount: number,
        percent: number,
        minAmount: number,
    ) {
        return this.roundMoney(Math.max(minAmount, amount * percent / 100));
    }

}


export { NumberUtil };
