/**
 * LanguageSwitcher styled container
 *
 * @author: exode <hello@exode.ru>
 */

import styled from 'styled-components';


export const Container = styled.div`
    .Avatar__badge {
        right: calc(25% - 1px);
        bottom: calc(50% - 5px);
    }

    .vkuiAvatar__badge--shadow:before {
        display: none;
    }
`;
