/**
 * UseChatInput
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import { Dispatch, SetStateAction, useCallback, useEffect } from 'react';

import { useApolloClient } from '@apollo/client';

import { useStore } from '@/pages/Core';
import { ChatDialogsPageStore } from '@/pages/Chat/Dialog/store';

import { MessagesViewContext } from '@/pages/Chat/Dialog/contexts/MessagesViewContext';

import { Parse } from '@/utils';
import { Router } from '@/services/Utils/Router';
import { UploadedFilePreviews } from '@/hooks/core';
import { useChatCreation, useChatSendMessage } from '@/hooks/apollo';

import {
    ChatType,
    FindManyFriendsHasNotPersonalChatDocument,
    FindManyFriendsHasNotPersonalChatQuery,
    useChatMessageEditMutation,
    useChatTypingSetStatusMutation,
} from '@/codegen/graphql';

import {
    friendsHasNotPersonalChatFilterConfig,
    friendsHasNotPersonalChatListConfig,
} from '@/pages/Chat/Dialog/constants';


interface Props {
    chatId?: number;
    personalUserId?: number;
    previews: UploadedFilePreviews[],
    setPreviews: Dispatch<SetStateAction<UploadedFilePreviews[]>>,
    handleClearReply: (chatId: string | number) => void;
    scrollToBottom: MessagesViewContext['scrollToBottom'];
    cancelEdit: () => void;
    setShowPicker: (value: boolean) => void;
    isTicketCreating?: boolean;
    defaultValue?: string;
}


export const useChatInput = (props: Props) => {

    const {
        chatId,
        personalUserId,
        previews,
        setPreviews,
        handleClearReply,
        scrollToBottom,
        cancelEdit,
        setShowPicker,
        isTicketCreating,
        defaultValue,
    } = props;

    const apolloClient = useApolloClient();

    const {
        createChat,
        createChatLoading,
        createSupportTicket,
        createSupportTicketLoading,
    } = useChatCreation();

    const [ setTypingStatus ] = useChatTypingSetStatusMutation();
    const [ editChatMessage, { loading: editChatMessageLoading } ] = useChatMessageEditMutation();

    const {
        sendChatMessage,
        getMediaIsUploading,
        sendChatMessageLoading,
        getMessageMediaPayload,
    } = useChatSendMessage();

    const { input, store, params } = useStore(ChatDialogsPageStore);

    const handleTyping = useCallback(
        _.throttle(() => (
            setTypingStatus({
                variables: {
                    isTyping: true,
                    chatId: chatId ?? +params.chatId,
                },
            })
        ), 3000, { leading: true, trailing: false }),
        [ chatId ],
    );

    const handleSend = async () => {
        const text = Parse.removeGaps(input.message).trimStart() || '';

        if (!text?.trim().length && !previews.length) {
            return;
        }

        store.mergeInput({ message: '' });
        setShowPicker(false);

        /** Создание чата (персонального | тикет) */
        if (!chatId || !_.isFinite(chatId) || isTicketCreating) {
            const _previews = [ ...previews ];
            setPreviews([]);

            /** Upload images before create chat */
            const medias = await getMessageMediaPayload(_previews);

            /** Создание тикета */
            if (isTicketCreating) {
                return createSupportTicket({
                    message: {
                        text,
                        medias,
                    },
                });
            }

            /** Создание персонального чата */
            if (!personalUserId) {
                return;
            }

            return createChat({
                ignoreCache: true,
                chat: {
                    type: ChatType.Personal,
                    message: { text, medias },
                    memberUserIds: [ personalUserId ],
                },
                onCompleted: async ({ id }) => {
                    Router.replacePage('/chat', { chatId: `${id}` });

                    /** Clear friend if exist in "FriendsHasNotPersonalChat" */
                    filterFriendsHasNotPersonalChat(personalUserId);
                },
            });
        }

        /** Редактирование сообщения */
        if (input.isEditing) {
            return editChatMessage({
                variables: {
                    chatId,
                    messageId: input.isEditing,
                    message: {
                        text,
                        replyMessageId: input.replyMessage[+chatId]?.id,
                    },
                },
                onCompleted: () => {
                    cancelEdit();

                    handleClearReply(chatId);
                },
            });
        }

        /** Обычная отправка сообщения */
        await sendChatMessage({
            chatId,
            previews,
            message: {
                text,
                replyMessageId: input.replyMessage[+chatId]?.id,
            },
            onStateChange: (state, payload, messageId) => {
                switch (state) {
                    case 'inserted':
                        handleClearReply(chatId);
                        setPreviews([]);
                        scrollToBottom?.();
                        break;

                    case 'error':
                        messageId && store.addUnsentMessage({ ...payload, messageId });
                        break;
                }
            },
        });

        handleTyping.cancel();

        store.saveDraftMessage('', chatId);
    };

    const filterFriendsHasNotPersonalChat = (personalUserId: number) => {
        const variables = {
            list: friendsHasNotPersonalChatListConfig,
            filter: friendsHasNotPersonalChatFilterConfig,
        };

        const { friendshipFindMany } = apolloClient.cache.readQuery<FindManyFriendsHasNotPersonalChatQuery>({
            query: FindManyFriendsHasNotPersonalChatDocument,
            variables,
        }) || {};

        friendshipFindMany && apolloClient.cache.writeQuery<FindManyFriendsHasNotPersonalChatQuery>({
            query: FindManyFriendsHasNotPersonalChatDocument,
            variables,
            data: {
                friendshipFindMany: {
                    __typename: 'ListUserFriendshipOutput',
                    count: friendshipFindMany.count ?? 0,
                    items: (friendshipFindMany.items ?? []).filter((f) => f.id !== personalUserId),
                },
            },
        });
    };

    useEffect(() => {
        store.setInput(
            'message',
            defaultValue || input.message,
        );
    }, [ defaultValue ]);

    return {
        handleSend,
        handleTyping,
        cancelEdit,
        editChatMessageLoading,
        sendChatMessageLoading,
        createSupportTicketLoading,
        createChatLoading: createChatLoading
            || getMediaIsUploading('create')
            || createSupportTicketLoading,
    };
};
