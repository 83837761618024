/**
 * Placeholder styled container
 *
 * @author: exode <hello@exode.ru>
 */

import styled from 'styled-components';

import { PlaceholderProps } from '@/components/Atoms/Placeholder/Placeholder';


export const Container = styled.div<PlaceholderProps>`
  .Placeholder__in {
    padding: ${({ innerPadding }) => innerPadding};
  }
`;
