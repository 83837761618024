/**
 * PreferenceStatusBarColor component
 *
 * @author: exode <hello@exode.ru>
 */

import React, { useEffect } from 'react';

import { observer, PreferenceStore } from '@/store/preference/preference';


interface Props {
    color?: string;
}


const PreferenceStatusBarColor = observer(({ color }: Props) => {

    useEffect(() => {
        color && PreferenceStore.setTemporaryStatusBarColor(color);

        return () => {
            PreferenceStore.temporaryStatusBarColor && PreferenceStore.setTemporaryStatusBarColor('');
        };
    }, []);

    return (<></>);
});


export { PreferenceStatusBarColor };
