/**
 * App user subscriptions
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { Auth } from '@/cutils';

import { UserOnlineCountSubscription } from './UserOnlineCountSubscription';
import { UserWalletBalanceSubscription } from './UserWalletBalanceSubscription';


const AppUserSubscriptions = () => {
    return (
        <>
            <UserOnlineCountSubscription/>

            <Auth>
                <UserWalletBalanceSubscription/>
            </Auth>
        </>
    );
};


export { AppUserSubscriptions };
