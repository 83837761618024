/**
 * TournamentMiniCard styled container
 *
 * @author: exode <hello@exode.ru>
 */

import styled from 'styled-components';

import { tournamentCardRounded } from '@/components/Tournament/Card/TournamentCard.styled';


interface Props {
    shadowColor: string;
    borderRadius?: string;
}

export const SubjectImageShadow = styled.div<Props>`
  .Avatar,
  .Card {
    border-radius: ${(props) => props.borderRadius ? props.borderRadius : tournamentCardRounded} !important;
    box-shadow: inset 0 0 0 2px ${(props) => props.shadowColor}, inset 0 0 0 4px var(--background_content);
  }
`;

export const Container = styled.div`
  .HorizontalCell {
    &:before,
    &:after {
      display: none;
    }
  }
`;
