/**
 * SelectMimicryModal
 *
 * @author: exode <hello@exode.ru>
 */

import React, { useLayoutEffect } from 'react';

import { observer, Page, useStore } from '@/pages/Core';
import { UtilSelectMimicryPageStore } from '@/pages/Utils/SelectMimicry/store';

import { Modal } from '@/cutils';
import { Router } from '@/services/Utils/Router';

import { Title } from '@exode.ru/vkui';

import { AdaptiveSelectScroller } from '@/components/Atoms/AdaptiveSelect';
import { SelectView } from '@/pages/Utils/SelectMimicry/views/SelectView';
import { ComponentView } from '@/pages/Utils/SelectMimicry/views/ComponentView';


interface Props {
    id: string;
}


const SelectMimicryModal = observer((props: Props) => {

    const { store } = useStore(UtilSelectMimicryPageStore);

    const { component, title, options, from, selected, rawModalCardProps, merge } = store;

    useLayoutEffect(() => {
        !component && (
            Router.replaceModalWithTimeout(Router.timeout, true)
        );
    }, []);

    return (
        <Modal.Card id={props.id} className={!component ? 'invisible' : ''} header={title && (
            <Title className="modal-title" level="2" weight="3">{title}</Title>
        )} {...rawModalCardProps}>
            <Modal.Body isScrollable className={[ 'h-[calc(100vh_-_95px)]', title ? '' : '!mt-0' ].join(' ')}>
                <AdaptiveSelectScroller>
                    <Page.Row mobileModalNoMx={!!title}>
                        <ComponentView component={component}/>

                        <SelectView options={options} from={from} selected={selected} merge={merge}/>
                    </Page.Row>
                </AdaptiveSelectScroller>
            </Modal.Body>
        </Modal.Card>
    );
});


export { SelectMimicryModal };
