/**
 * UseSchoolDiscipline
 *
 * @author: exode <hello@exode.ru>
 */

import { SaveStoreDeepKeys, SaveStoreKeys, Saving } from '@/store/core/saving';
import { ManageSchoolDisciplinesPageStore } from '@/pages/Manage/School/Disciplines/store';

import { useStore } from '@/pages/Core';

import {
    ContentCategoryFindManyDocument,
    ContentCategoryFindManyQuery,
    CreateCategoryContentInput,
    CreateSubjectInput,
    SubjectEntity,
    UpdateCategoryContentInput,
    UpdateSubjectInput,
    useSchoolContentCategoryCreateMutation,
    useSchoolContentCategoryUpdateMutation,
    useSchoolSubjectCreateMutation,
    useSchoolSubjectUpdateMutation,
} from '@/codegen/graphql';


export const useSchoolDiscipline = () => {

    const { list, filter } = useStore(ManageSchoolDisciplinesPageStore);

    /** Categories */

    const [ _categoryCreate, {
        loading: categoryCreateLoading,
        error: categoryCreateError,
    } ] = useSchoolContentCategoryCreateMutation({
        update: (cache, { data }) => {
            const variables = {
                list: { ...list },
                filter: { ...filter },
            };

            const cachedCategories = cache.readQuery<ContentCategoryFindManyQuery>({
                variables,
                query: ContentCategoryFindManyDocument,
            });

            if (!cachedCategories) {
                return console.warn('[Cache]: cachedCategories отсутствуют в кэше');
            }

            const { count, items, page, pages } = cachedCategories.contentCategoryFindMany;

            data && cache.writeQuery<ContentCategoryFindManyQuery>({
                variables,
                query: ContentCategoryFindManyDocument,
                data: {
                    contentCategoryFindMany: {
                        page,
                        pages,
                        count,
                        items: [
                            ...(items || []),
                            data.schoolContentCategoryCreate,
                        ],
                    },
                },
            });
        },
    });

    const categoryCreate = (category: CreateCategoryContentInput) => {
        return _categoryCreate({ variables: { category } });
    };

    const [ _categoryUpdate, {
        loading: categoryUpdateLoading,
        error: categoryUpdateError,
    } ] = useSchoolContentCategoryUpdateMutation();

    const categoryUpdate = (
        categoryId: number,
        category: UpdateCategoryContentInput,
    ) => {
        return _categoryUpdate({
            variables: { categoryId, category },
        });
    };

    const changeCategoryVisibility = (
        categoryId: number,
        visible: boolean,
    ) => {
        return _categoryUpdate({
            variables: {
                categoryId,
                category: {
                    visible,
                },
            },
        });
    };

    /** Subjects */

    const [ _subjectCreate, {
        loading: subjectCreateLoading,
        error: subjectCreateError,
    } ] = useSchoolSubjectCreateMutation({
        update: (cache, { data }) => {
            data && cache.modify({
                id: `ContentCategoryEntity:${data?.schoolSubjectCreate?.contentCategory?.id}`,
                fields: {
                    subjects: (existingSubjects = []) => [
                        ...existingSubjects,
                        data.schoolSubjectCreate,
                    ],
                },
            });
        },
    });

    const subjectCreate = (subject: CreateSubjectInput) => {
        return _subjectCreate({
            variables: { subject },
        });
    };

    const [ _subjectUpdate, {
        loading: subjectUpdateLoading,
        error: subjectUpdateError,
    } ] = useSchoolSubjectUpdateMutation({
        update: (cache, { data }) => {
            data && cache.modify({
                id: `ContentCategoryEntity:${data?.schoolSubjectUpdate?.contentCategory?.id}`,
                fields: {
                    subjects: (existingSubjects = []) => {
                        existingSubjects.map(((item: SubjectEntity) => (
                            item.id === data.schoolSubjectUpdate.id
                                ? data.schoolSubjectUpdate
                                : item
                        )));
                    },
                },
            });
        },
    });

    const subjectUpdate = (
        subjectId: number,
        subject: UpdateSubjectInput,
    ) => {
        return _subjectUpdate({
            variables: { subject, subjectId },
        });
    };

    const changeSubjectVisibility = (
        subjectId: number,
        visible: boolean,
    ) => {
        return _subjectUpdate({
            variables: {
                subjectId,
                subject: { visible },
            },
        });
    };

    Saving.listenSetSaving(
        SaveStoreKeys.School,
        SaveStoreDeepKeys.SchoolInformation,
        [
            categoryCreateLoading,
            categoryUpdateLoading,
            subjectCreateLoading,
            subjectUpdateLoading,
        ],
        [
            categoryCreateError,
            categoryUpdateError,
            subjectCreateError,
            subjectUpdateError,
        ],
    );

    return {
        categoryCreate,
        categoryCreateLoading,
        categoryCreateError,
        categoryUpdate,
        categoryUpdateLoading,
        categoryUpdateError,
        changeCategoryVisibility,
        subjectCreate,
        subjectCreateLoading,
        subjectCreateError,
        subjectUpdate,
        subjectUpdateLoading,
        subjectUpdateError,
        changeSubjectVisibility,
    };
};

