/**
 * FloatingButton styled container
 *
 * @author: exode <hello@exode.ru>
 */

import styled from 'styled-components';

import { FloatingButtonProps } from './FloatingButton';


export const Container = styled.div<FloatingButtonProps>`
  position: fixed;
  z-index: 2;
  left: ${({ isLeftSided }) => isLeftSided ? '22px' : 'unset'};
  right: ${({ forDesktop, isLeftSided }) => isLeftSided ? 'unset' : forDesktop ? '150px' : '22px'};
  bottom: calc(env(safe-area-inset-bottom, 0px) + 70px);
  cursor: pointer;

  .without-tabbar & {
    bottom: calc(env(safe-area-inset-bottom, 0px) + 22px);
  }

  .vkuiCounter {
    position: absolute;
    right: -5px;
    z-index: 2;
    top: 40px;
    background: ${props => props.badgeBg || 'initial'};
    color: ${props => props.badgeColor || 'initial'};
  }

  .Avatar__children {
    color: var(--white);
  }

  .Avatar {
    background: var(--accent);
  }
`;
