/**
 * EmojiPicker styled container
 *
 * @author: exode <hello@exode.ru>
 */

import styled from 'styled-components';

import { responsive } from '@/styles/modules/responsive';
import { customScrollbar } from '@/styles/modules/scrollbar';


export const Container = styled.div`

    .EmojiPickerReact .epr-body {
        ${customScrollbar('epr-highlight-color')};
        overflow-x: hidden;
    }

    ${responsive([ 'mobile' ])} {
        width: calc(100vw - 30px);
    }

    .EmojiPickerReact.epr-main {
        border: none;
        backdrop-filter: blur(0px);
        -webkit-backdrop-filter: blur(0px);

        ${responsive([ 'mobile' ])} {
            width: auto !important;
        }
    }

    .epr-emoji-category-label {
        font-family: 'VK Sans Display', serif !important;
        font-weight: 500 !important;
        text-transform: none;
    }

    .EmojiPickerReact li.epr-emoji-category > .epr-emoji-category-label {
        text-transform: none;
    }

    .epr-category-nav {
        display: none !important;
    }

    .epr-icn-search {
        display: none;
    }

    .EmojiPickerReact .epr-search-container input.epr-search {
        font-size: 15px;
        padding: 8px 12px;
        height: initial;
        color: var(--text_primary, var(--vkui--color_text_primary)) !important;
        font-family: 'VK Sans Display', serif !important;
        background-color: var(--epr-search-input-bg-color) !important;

        &:active, &:focus {
            border-color: var(--action_sheet_separator) !important;
        }
    }
`;
