/**
 * BlurImageWrapper
 *
 * @author: exode <hello@exode.ru>
 */

import React, { ReactElement } from 'react';

import { Container, ImageWrapper } from './BlurImageWrapper.styled';


export interface BlurImageWrapperProps {
    image: ReactElement;
    padding?: string;
    margin?: string;
    className?: string;
}


const BlurImageWrapper = (props: BlurImageWrapperProps) => {

    const { image, padding = '1rem' } = props;

    const className = `${props.className} h-fit w-fit m-auto rounded-full flex items-center justify-center shadow-sm`;

    return (
        <Container {...props} >
            <ImageWrapper padding={padding} className={className}>
                {image}
            </ImageWrapper>
        </Container>
    );
};


export { BlurImageWrapper };
