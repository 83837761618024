/**
 * AdaptiveSelect component
 *
 * @author: exode <hello@exode.ru>
 */

import _ from 'lodash';

import React, { ReactElement, ReactNode, useEffect, useState } from 'react';

import { If, Link } from '@/cutils';
import { useI18n } from '@/hooks/core';

import { Button, Cell, FormLayout, ScreenSpinner } from '@exode.ru/vkui';

import { StickyButton } from '@/components/Atoms/StickyButton';

import { SingleVariantPart } from './parts/SingleVariant';


export interface Option {
    value: string | number;
    label: string;
    disabled?: boolean;
    checked?: boolean;
    description?: string;
    after?: ReactElement;
    onSelect?: (option: Option | { option: Option; options: OptionState }) => void;
}

interface AdaptiveSelectProps {
    options: Option[];
    value: (string | number)[];
    callback: (option: Option[]) => void;
    multiple?: { max?: number, min?: number };
    buttonText?: string;
    isActionSheets?: boolean;
    isTab?: boolean;
    useInModal?: boolean;
    loading?: boolean;
    children?: ReactNode;
    forceButtonEnabled?: boolean;
    stickyButtonClassName?: string;
}

export type OptionState = {
    [key: Option['value']]: Option;
}


const AdaptiveSelect = (props: AdaptiveSelectProps) => {

    const { t } = useI18n('components.Atoms.AdaptiveSelect');

    const [ buttonEnabled, setButtonEnabled ] = useState(true);

    const {
        value,
        callback,
        multiple,
        isActionSheets,
        useInModal,
        children,
        forceButtonEnabled,
        stickyButtonClassName,
        isTab = false,
        loading = false,
        buttonText = t('select'),
    } = props;

    const parsedOptions: { [key: Option['value']]: Option } = {};

    _.each(props.options, (option) => {
        parsedOptions[option.value] = {
            ...option,
            checked: value?.includes(option.value),
        };
    });

    if (!_.isUndefined(multiple)) {
        multiple.min = multiple.min || 1;
        multiple.max = multiple.max || props.options.length;
    }

    const [ options, setOptions ] = useState<OptionState>(parsedOptions);

    const checkedOptions = _.groupBy(options, 'checked')['true'];
    const checkedCount = checkedOptions?.length || 0;

    const onSelect = (option: Option) => {
        option.onSelect && option.onSelect({ option, options });

        if (!multiple) {
            return callback([ option ]);
        }

        setOptions({
            ...options,
            [option.value]: {
                ...option,
                checked: !option.checked || !multiple,
            },
        });
    };

    const optionIsEnabled = (option: Option) => {
        if (option.checked || !multiple) {
            return true;
        }

        const maxIsExceeded = multiple && checkedCount >= Number(multiple.max);

        return !maxIsExceeded;
    };

    const handleSubmit = () => {
        callback(_.map(checkedOptions || []));
    };

    useEffect(() => {
        const minIsExceeded = multiple && checkedCount >= Number(multiple.min);

        _.isBoolean(forceButtonEnabled)
            ? setButtonEnabled(forceButtonEnabled)
            : buttonEnabled !== minIsExceeded && setButtonEnabled(!!minIsExceeded);
    }, [ multiple, options, checkedCount, forceButtonEnabled ]);

    return (
        <FormLayout className="mimicry-select">
            <If is={loading}>
                <ScreenSpinner/>
            </If>

            <If is={!!multiple}>
                <If is={!children}>
                    <div>
                        {_.map(options, (option) => (
                            <Cell key={option.value}
                                  mode="selectable"
                                  after={option.after}
                                  checked={option.checked}
                                  subtitle={option.description}
                                  onChange={() => onSelect(option)}
                                  disabled={option.disabled || !optionIsEnabled(option)}>
                                {option.label}
                            </Cell>
                        ))}
                    </div>
                </If>

                <If is={!!children}>
                    <>{children}</>
                </If>

                <StickyButton isTab={isTab} className={stickyButtonClassName}>
                    <Link replaceModal={useInModal}>
                        <Button stretched mode="primary" size="l" disabled={!buttonEnabled} onClick={handleSubmit}>
                            {buttonText}
                        </Button>
                    </Link>
                </StickyButton>
            </If>

            <If is={!multiple}>
                <If is={!children}>
                    <div className="d:px-4 my-2">
                        {_.map(options, (option) => (
                                <SingleVariantPart key={option.value}
                                                   loading={loading}
                                                   option={option}
                                                   isActionSheets={isActionSheets}
                                                   onSelect={onSelect}
                                                   useInModal={useInModal}/>
                            ),
                        )}
                    </div>
                </If>

                <If is={!!children}>
                    <>{children}</>
                </If>
            </If>
        </FormLayout>
    );
};


export { AdaptiveSelect };
