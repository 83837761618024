/**
 * Subject types
 *
 * @author: exode <hello@exode.ru>
 */

import React, { ReactElement } from 'react';

import { GqlResult } from '@/types/graphql';
import { EducationSubject, ListProfileSubjectsQueryResult, SubjectFindManyQueryResult } from '@/codegen/graphql';

import { UiService } from '@/services/Ui/Ui';

import {
    Biology,
    Chemistry,
    English,
    French,
    Geography,
    German,
    History,
    IconBiology,
    IconChemistry,
    IconEnglish,
    IconFrench,
    IconGeography,
    IconGerman,
    IconHistory,
    IconInformatics,
    IconLiterature,
    IconMath,
    IconMathBasic,
    IconPhysics,
    IconRussian,
    IconSocial,
    IconSpanish,
    Informatics,
    Literature,
    Math,
    MathBasic,
    Physics,
    Russian,
    Social,
    Spanish,
} from '@/assets/images/subjects';


export type EducationSubjectType = keyof typeof EducationSubject;
export type Subjects = NonNullable<GqlResult<SubjectFindManyQueryResult>['subjectFindMany']['items']>;
export type ProfileSubjects = NonNullable<GqlResult<ListProfileSubjectsQueryResult>['subjectFindMany']['items']>;

/**
 * Цвета предметов
 */
export const educationSubjectColors: { [subject in EducationSubject]: string } = {
    [EducationSubject.EgeRussian]: 'from-red-500 to-yellow-500',
    [EducationSubject.EgeMath]: 'from-purple-500 to-blue-500',
    [EducationSubject.EgeMathBasic]: 'from-fuchsia-500 to-purple-500',
    [EducationSubject.EgeSocial]: 'from-indigo-700 to-indigo-500',
    [EducationSubject.EgeHistory]: 'from-amber-600 to-red-400',
    [EducationSubject.EgePhysics]: 'from-blue-600 to-blue-400',
    [EducationSubject.EgeChemistry]: 'from-lime-600 to-green-400',
    [EducationSubject.EgeInformatics]: 'from-sky-500 to-sky-400',
    [EducationSubject.EgeBiology]: 'from-teal-500 to-green-400',
    [EducationSubject.EgeLiterature]: 'from-gray-600 to-yellow-400',
    [EducationSubject.EgeGeography]: 'from-orange-600 to-orange-400',
    [EducationSubject.EgeEnglish]: 'from-indigo-600 to-red-700',
    [EducationSubject.EgeGerman]: 'from-red-900 to-red-600',
    [EducationSubject.EgeFrench]: 'from-blue-600 via-blue-300 to-red-600',
    [EducationSubject.EgeSpanish]: 'from-red-700 via-orange-500 to-orange-700',
    [EducationSubject.EgeOther]: 'from-red-700 via-orange-500 to-orange-700',
    [EducationSubject.BusinessLegal]: 'from-red-700 via-orange-500 to-orange-700',
    [EducationSubject.BusinessManagement]: 'from-red-700 via-orange-500 to-orange-700',
    [EducationSubject.BusinessMedia]: 'from-red-700 via-orange-500 to-orange-700',
    [EducationSubject.BusinessSales]: 'from-red-700 via-orange-500 to-orange-700',
    [EducationSubject.BusinessOther]: 'from-red-700 via-orange-500 to-orange-700',
    [EducationSubject.DevelopmentApp]: 'from-red-700 via-orange-500 to-orange-700',
    [EducationSubject.DevelopmentArchitecture]: 'from-red-700 via-orange-500 to-orange-700',
    [EducationSubject.DevelopmentWeb]: 'from-red-700 via-orange-500 to-orange-700',
    [EducationSubject.DevelopmentOther]: 'from-red-700 via-orange-500 to-orange-700',
    [EducationSubject.DevelopmentDb]: 'from-red-700 via-orange-500 to-orange-700',
    [EducationSubject.DevelopmentTheory]: 'from-red-700 via-orange-500 to-orange-700',
    [EducationSubject.BusinessBrand]: 'from-red-700 via-orange-500 to-orange-700',
    [EducationSubject.BusinessEntrepreneur]: 'from-red-700 via-orange-500 to-orange-700',
    [EducationSubject.DesignGraphic]: 'from-red-700 via-orange-500 to-orange-700',
    [EducationSubject.Design3DAnimation]: 'from-red-700 via-orange-500 to-orange-700',
    [EducationSubject.DesignWeb]: 'from-red-700 via-orange-500 to-orange-700',
    [EducationSubject.DesignUx]: 'from-red-700 via-orange-500 to-orange-700',
    [EducationSubject.DesignOther]: 'from-red-700 via-orange-500 to-orange-700',
    [EducationSubject.FinanceAccounting]: 'from-red-700 via-orange-500 to-orange-700',
    [EducationSubject.FinanceCrypto]: 'from-red-700 via-orange-500 to-orange-700',
    [EducationSubject.FinanceStocks]: 'from-red-700 via-orange-500 to-orange-700',
    [EducationSubject.FinanceOther]: 'from-red-700 via-orange-500 to-orange-700',
    [EducationSubject.LanguageChinese]: 'from-red-700 via-orange-500 to-orange-700',
    [EducationSubject.LanguageEnglish]: 'from-red-700 via-orange-500 to-orange-700',
    [EducationSubject.LanguageFrench]: 'from-red-700 via-orange-500 to-orange-700',
    [EducationSubject.LanguageGerman]: 'from-red-700 via-orange-500 to-orange-700',
    [EducationSubject.LanguageJapanese]: 'from-red-700 via-orange-500 to-orange-700',
    [EducationSubject.LanguageSpanish]: 'from-red-700 via-orange-500 to-orange-700',
    [EducationSubject.LanguageTurkish]: 'from-red-700 via-orange-500 to-orange-700',
    [EducationSubject.LanguageOther]: 'from-red-700 via-orange-500 to-orange-700',
    [EducationSubject.MarketingContent]: 'from-red-700 via-orange-500 to-orange-700',
    [EducationSubject.MarketingInternet]: 'from-red-700 via-orange-500 to-orange-700',
    [EducationSubject.MarketingSeo]: 'from-red-700 via-orange-500 to-orange-700',
    [EducationSubject.MarketingSmm]: 'from-red-700 via-orange-500 to-orange-700',
    [EducationSubject.MarketingTrade]: 'from-red-700 via-orange-500 to-orange-700',
    [EducationSubject.MarketingOther]: 'from-red-700 via-orange-500 to-orange-700',
    [EducationSubject.MusicBasics]: 'from-red-700 via-orange-500 to-orange-700',
    [EducationSubject.MusicInstruments]: 'from-red-700 via-orange-500 to-orange-700',
    [EducationSubject.MusicProduction]: 'from-red-700 via-orange-500 to-orange-700',
    [EducationSubject.MusicSkillUp]: 'from-red-700 via-orange-500 to-orange-700',
    [EducationSubject.MusicSoftware]: 'from-red-700 via-orange-500 to-orange-700',
    [EducationSubject.MusicVocals]: 'from-red-700 via-orange-500 to-orange-700',
    [EducationSubject.MusicOther]: 'from-red-700 via-orange-500 to-orange-700',
    [EducationSubject.OgeBiology]: 'from-red-700 via-orange-500 to-orange-700',
    [EducationSubject.OgeChemistry]: 'from-red-700 via-orange-500 to-orange-700',
    [EducationSubject.OgeGeography]: 'from-red-700 via-orange-500 to-orange-700',
    [EducationSubject.OgeHistory]: 'from-red-700 via-orange-500 to-orange-700',
    [EducationSubject.OgeInformatics]: 'from-red-700 via-orange-500 to-orange-700',
    [EducationSubject.OgeLiterature]: 'from-red-700 via-orange-500 to-orange-700',
    [EducationSubject.OgeMath]: 'from-red-700 via-orange-500 to-orange-700',
    [EducationSubject.OgePhysics]: 'from-red-700 via-orange-500 to-orange-700',
    [EducationSubject.OgeRussian]: 'from-red-700 via-orange-500 to-orange-700',
    [EducationSubject.OgeSocial]: 'from-red-700 via-orange-500 to-orange-700',
    [EducationSubject.OgeOther]: 'from-red-700 via-orange-500 to-orange-700',
    [EducationSubject.PersonalBrand]: 'from-red-700 via-orange-500 to-orange-700',
    [EducationSubject.PersonalCareer]: 'from-red-700 via-orange-500 to-orange-700',
    [EducationSubject.PersonalLeadership]: 'from-red-700 via-orange-500 to-orange-700',
    [EducationSubject.PersonalMotivation]: 'from-red-700 via-orange-500 to-orange-700',
    [EducationSubject.PersonalRelationships]: 'from-red-700 via-orange-500 to-orange-700',
    [EducationSubject.PersonalOther]: 'from-red-700 via-orange-500 to-orange-700',
    [EducationSubject.Photo]: 'from-red-700 via-orange-500 to-orange-700',
    [EducationSubject.PhotoBasics]: 'from-red-700 via-orange-500 to-orange-700',
    [EducationSubject.VideoBasics]: 'from-red-700 via-orange-500 to-orange-700',
    [EducationSubject.VideoEditing]: 'from-red-700 via-orange-500 to-orange-700',
    [EducationSubject.VideoOther]: 'from-red-700 via-orange-500 to-orange-700',
    [EducationSubject.PhotoColorCorrection]: 'from-red-700 via-orange-500 to-orange-700',
    [EducationSubject.PhotoDigital]: 'from-red-700 via-orange-500 to-orange-700',
    [EducationSubject.PhotoPortrait]: 'from-red-700 via-orange-500 to-orange-700',
    [EducationSubject.PhotoOther]: 'from-red-700 via-orange-500 to-orange-700',
    [EducationSubject.PreschoolMentalArithmetic]: 'from-red-700 via-orange-500 to-orange-700',
    [EducationSubject.PreschoolPreparation]: 'from-red-700 via-orange-500 to-orange-700',
    [EducationSubject.PreschoolSpeedReading]: 'from-red-700 via-orange-500 to-orange-700',
    [EducationSubject.PreschoolOther]: 'from-red-700 via-orange-500 to-orange-700',
    [EducationSubject.SportWeightLoss]: 'from-red-700 via-orange-500 to-orange-700',
    [EducationSubject.SportBodyBuilding]: 'from-red-700 via-orange-500 to-orange-700',
    [EducationSubject.SportFitness]: 'from-red-700 via-orange-500 to-orange-700',
    [EducationSubject.SportOther]: 'from-red-700 via-orange-500 to-orange-700',
    [EducationSubject.HealthHealthyEating]: 'from-red-700 via-orange-500 to-orange-700',
    [EducationSubject.HealthBeauty]: 'from-red-700 via-orange-500 to-orange-700',
    [EducationSubject.HealthOther]: 'from-red-700 via-orange-500 to-orange-700',
    [EducationSubject.OtherOther]: 'from-red-700 via-orange-500 to-orange-700',

} as const;

/**
 * Названия предметов
 */
export const educationSubjects: { [subject in EducationSubjectType]: string } = {
    [EducationSubject.EgeRussian]: 'Русский язык',
    [EducationSubject.EgeMath]: 'Математика',
    [EducationSubject.EgeMathBasic]: 'Математика (б)',
    [EducationSubject.EgePhysics]: 'Физика',
    [EducationSubject.EgeChemistry]: 'Химия',
    [EducationSubject.EgeInformatics]: 'Информатика',
    [EducationSubject.EgeBiology]: 'Биология',
    [EducationSubject.EgeHistory]: 'История',
    [EducationSubject.EgeGeography]: 'География',
    [EducationSubject.EgeEnglish]: 'Английский',
    [EducationSubject.EgeGerman]: 'Немецкий',
    [EducationSubject.EgeFrench]: 'Французский',
    [EducationSubject.EgeSocial]: 'Общество',
    [EducationSubject.EgeSpanish]: 'Испанский',
    [EducationSubject.EgeLiterature]: 'Литература',
    [EducationSubject.EgeOther]: 'Другое',
    [EducationSubject.MarketingInternet]: 'Интернет маркетинг',
    [EducationSubject.MarketingSeo]: 'Поисковая оптимизация',
    [EducationSubject.MarketingSmm]: 'Маркетинг социальных сетей',
    [EducationSubject.MarketingContent]: 'Контент-маркетинг',
    [EducationSubject.MarketingTrade]: 'Товарный-маркетинг',
    [EducationSubject.MarketingOther]: 'Другое',
    [EducationSubject.FinanceAccounting]: 'Бухгалтерский учет',
    [EducationSubject.FinanceCrypto]: 'Криптовалюты и блокчейн',
    [EducationSubject.FinanceStocks]: 'Инвестирование и трейдинг',
    [EducationSubject.FinanceOther]: 'Другое',
    [EducationSubject.DevelopmentApp]: 'Разработка приложений',
    [EducationSubject.DevelopmentArchitecture]: 'Архитектура',
    [EducationSubject.DevelopmentWeb]: 'Веб-разработка',
    [EducationSubject.DevelopmentDb]: 'Проектирование баз данных',
    [EducationSubject.DevelopmentTheory]: 'Языки программирования',
    [EducationSubject.DevelopmentOther]: 'Другое',
    [EducationSubject.BusinessLegal]: 'Бизнес право',
    [EducationSubject.BusinessManagement]: 'Менеджмент',
    [EducationSubject.BusinessMedia]: 'Медиа',
    [EducationSubject.BusinessSales]: 'Продажи',
    [EducationSubject.BusinessBrand]: 'Брендирование',
    [EducationSubject.BusinessEntrepreneur]: 'Предпринимательство',
    [EducationSubject.BusinessOther]: 'Другое',
    [EducationSubject.PersonalLeadership]: 'Лидерство',
    [EducationSubject.PersonalCareer]: 'Карьерный рост',
    [EducationSubject.PersonalRelationships]: 'Отношения и воспитание',
    [EducationSubject.PersonalMotivation]: 'Мотивация',
    [EducationSubject.PersonalBrand]: 'Создание личного бренда',
    [EducationSubject.PersonalOther]: 'Другое',
    [EducationSubject.MusicInstruments]: 'Музыкальные инструменты',
    [EducationSubject.MusicProduction]: 'Музыкальное производство',
    [EducationSubject.MusicBasics]: 'Основы музыки',
    [EducationSubject.MusicVocals]: 'Вокал',
    [EducationSubject.MusicSkillUp]: 'Развитие навыков',
    [EducationSubject.MusicSoftware]: 'Музыкальное ПО',
    [EducationSubject.MusicOther]: 'Другое',
    [EducationSubject.DesignWeb]: 'Веб дизайн',
    [EducationSubject.DesignGraphic]: 'Графический дизайн и иллюстрация',
    [EducationSubject.DesignUx]: 'Дизайн пользовательского интерфейса',
    [EducationSubject.Design3DAnimation]: '3Д дизайн и анимация',
    [EducationSubject.DesignOther]: 'Другое',
    [EducationSubject.Photo]: 'Фотография',
    [EducationSubject.PhotoDigital]: 'Цифровая фотография',
    [EducationSubject.PhotoPortrait]: 'Портретная фотография',
    [EducationSubject.PhotoBasics]: 'Основы фотографии',
    [EducationSubject.PhotoColorCorrection]: 'Цветокоррекция ',
    [EducationSubject.PhotoOther]: 'Другое',
    [EducationSubject.VideoBasics]: 'Основы видеосъемки',
    [EducationSubject.VideoEditing]: 'Видеомонтаж',
    [EducationSubject.VideoOther]: 'Другое',
    [EducationSubject.PreschoolMentalArithmetic]: 'Ментальная арифметика',
    [EducationSubject.PreschoolPreparation]: 'Подготовка к школе',
    [EducationSubject.PreschoolSpeedReading]: 'Скорочтение',
    [EducationSubject.PreschoolOther]: 'Другое',
    [EducationSubject.LanguageEnglish]: 'Английский',
    [EducationSubject.LanguageGerman]: 'Немецкий',
    [EducationSubject.LanguageTurkish]: 'Турецкий',
    [EducationSubject.LanguageChinese]: 'Китайский',
    [EducationSubject.LanguageSpanish]: 'Испанский',
    [EducationSubject.LanguageFrench]: 'Французский',
    [EducationSubject.LanguageJapanese]: 'Японский',
    [EducationSubject.LanguageOther]: 'Другое',
    [EducationSubject.SportFitness]: 'Фитнес',
    [EducationSubject.SportWeightLoss]: 'Похудение',
    [EducationSubject.SportBodyBuilding]: 'Бодибилдинг',
    [EducationSubject.SportOther]: 'Другое',
    [EducationSubject.HealthHealthyEating]: 'Здоровое питание',
    [EducationSubject.HealthBeauty]: 'Красота',
    [EducationSubject.HealthOther]: 'Другое',
    [EducationSubject.OgeRussian]: 'Русский язык',
    [EducationSubject.OgeMath]: 'Математика',
    [EducationSubject.OgePhysics]: 'Физика',
    [EducationSubject.OgeChemistry]: 'Химия',
    [EducationSubject.OgeBiology]: 'Биология',
    [EducationSubject.OgeLiterature]: 'Литература',
    [EducationSubject.OgeGeography]: 'География',
    [EducationSubject.OgeHistory]: 'История',
    [EducationSubject.OgeInformatics]: 'Информатика',
    [EducationSubject.OgeSocial]: 'Обществознание',
    [EducationSubject.OgeOther]: 'Другое',
    [EducationSubject.OtherOther]: 'Другое',
} as const;

/**
 * Изображения предметов
 */
export const educationSubjectImages: { [subject in EducationSubjectType]: ReactElement } = {
    [EducationSubject.EgeRussian]: <IconRussian/>,
    [EducationSubject.EgeMath]: <IconMath/>,
    [EducationSubject.EgeMathBasic]: <IconMathBasic/>,
    [EducationSubject.EgeSocial]: <IconSocial/>,
    [EducationSubject.EgeHistory]: <IconHistory/>,
    [EducationSubject.EgePhysics]: <IconPhysics/>,
    [EducationSubject.EgeChemistry]: <IconChemistry/>,
    [EducationSubject.EgeInformatics]: <IconInformatics/>,
    [EducationSubject.EgeBiology]: <IconBiology/>,
    [EducationSubject.EgeLiterature]: <IconLiterature/>,
    [EducationSubject.EgeGeography]: <IconGeography/>,
    [EducationSubject.EgeEnglish]: <IconEnglish/>,
    [EducationSubject.EgeGerman]: <IconGerman/>,
    [EducationSubject.EgeFrench]: <IconFrench/>,
    [EducationSubject.EgeSpanish]: <IconSpanish/>,
    [EducationSubject.EgeOther]: <></>,
    [EducationSubject.BusinessLegal]: <></>,
    [EducationSubject.BusinessManagement]: <></>,
    [EducationSubject.BusinessMedia]: <></>,
    [EducationSubject.BusinessSales]: <></>,
    [EducationSubject.BusinessOther]: <></>,
    [EducationSubject.DevelopmentApp]: <></>,
    [EducationSubject.DevelopmentArchitecture]: <></>,
    [EducationSubject.DevelopmentWeb]: <></>,
    [EducationSubject.DevelopmentOther]: <></>,
    [EducationSubject.DevelopmentDb]: <></>,
    [EducationSubject.DevelopmentTheory]: <></>,
    [EducationSubject.BusinessBrand]: <></>,
    [EducationSubject.BusinessEntrepreneur]: <></>,
    [EducationSubject.DesignGraphic]: <></>,
    [EducationSubject.Design3DAnimation]: <></>,
    [EducationSubject.DesignWeb]: <></>,
    [EducationSubject.DesignUx]: <></>,
    [EducationSubject.DesignOther]: <></>,
    [EducationSubject.FinanceAccounting]: <></>,
    [EducationSubject.FinanceCrypto]: <></>,
    [EducationSubject.FinanceStocks]: <></>,
    [EducationSubject.FinanceOther]: <></>,
    [EducationSubject.LanguageChinese]: <></>,
    [EducationSubject.LanguageEnglish]: <></>,
    [EducationSubject.LanguageFrench]: <></>,
    [EducationSubject.LanguageGerman]: <></>,
    [EducationSubject.LanguageJapanese]: <></>,
    [EducationSubject.LanguageSpanish]: <></>,
    [EducationSubject.LanguageTurkish]: <></>,
    [EducationSubject.LanguageOther]: <></>,
    [EducationSubject.MarketingContent]: <></>,
    [EducationSubject.MarketingInternet]: <></>,
    [EducationSubject.MarketingSeo]: <></>,
    [EducationSubject.MarketingSmm]: <></>,
    [EducationSubject.MarketingTrade]: <></>,
    [EducationSubject.MarketingOther]: <></>,
    [EducationSubject.MusicBasics]: <></>,
    [EducationSubject.MusicInstruments]: <></>,
    [EducationSubject.MusicProduction]: <></>,
    [EducationSubject.MusicSkillUp]: <></>,
    [EducationSubject.MusicSoftware]: <></>,
    [EducationSubject.MusicVocals]: <></>,
    [EducationSubject.MusicOther]: <></>,
    [EducationSubject.OgeBiology]: <></>,
    [EducationSubject.OgeChemistry]: <></>,
    [EducationSubject.OgeGeography]: <></>,
    [EducationSubject.OgeHistory]: <></>,
    [EducationSubject.OgeInformatics]: <></>,
    [EducationSubject.OgeLiterature]: <></>,
    [EducationSubject.OgeMath]: <></>,
    [EducationSubject.OgePhysics]: <></>,
    [EducationSubject.OgeRussian]: <></>,
    [EducationSubject.OgeSocial]: <></>,
    [EducationSubject.OgeOther]: <></>,
    [EducationSubject.PersonalBrand]: <></>,
    [EducationSubject.PersonalCareer]: <></>,
    [EducationSubject.PersonalLeadership]: <></>,
    [EducationSubject.PersonalMotivation]: <></>,
    [EducationSubject.PersonalRelationships]: <></>,
    [EducationSubject.PersonalOther]: <></>,
    [EducationSubject.Photo]: <></>,
    [EducationSubject.PhotoBasics]: <></>,
    [EducationSubject.VideoBasics]: <></>,
    [EducationSubject.VideoEditing]: <></>,
    [EducationSubject.VideoOther]: <></>,
    [EducationSubject.PhotoColorCorrection]: <></>,
    [EducationSubject.PhotoDigital]: <></>,
    [EducationSubject.PhotoPortrait]: <></>,
    [EducationSubject.PhotoOther]: <></>,
    [EducationSubject.PreschoolMentalArithmetic]: <></>,
    [EducationSubject.PreschoolPreparation]: <></>,
    [EducationSubject.PreschoolSpeedReading]: <></>,
    [EducationSubject.PreschoolOther]: <></>,
    [EducationSubject.SportWeightLoss]: <></>,
    [EducationSubject.SportBodyBuilding]: <></>,
    [EducationSubject.SportFitness]: <></>,
    [EducationSubject.SportOther]: <></>,
    [EducationSubject.HealthHealthyEating]: <></>,
    [EducationSubject.HealthBeauty]: <></>,
    [EducationSubject.HealthOther]: <></>,
    [EducationSubject.OtherOther]: <></>,
};

export const subjectPublicImages: { [subject in EducationSubjectType]: string } = {
    [EducationSubject.EgeRussian]: Russian,
    [EducationSubject.EgeMath]: Math,
    [EducationSubject.EgeMathBasic]: MathBasic,
    [EducationSubject.EgeSocial]: Social,
    [EducationSubject.EgeHistory]: History,
    [EducationSubject.EgePhysics]: Physics,
    [EducationSubject.EgeChemistry]: Chemistry,
    [EducationSubject.EgeInformatics]: Informatics,
    [EducationSubject.EgeBiology]: Biology,
    [EducationSubject.EgeLiterature]: Literature,
    [EducationSubject.EgeGeography]: Geography,
    [EducationSubject.EgeEnglish]: English,
    [EducationSubject.EgeGerman]: German,
    [EducationSubject.EgeFrench]: French,
    [EducationSubject.EgeSpanish]: Spanish,
    [EducationSubject.EgeOther]: '',
    [EducationSubject.BusinessLegal]: '',
    [EducationSubject.BusinessManagement]: '',
    [EducationSubject.BusinessMedia]: '',
    [EducationSubject.BusinessSales]: '',
    [EducationSubject.BusinessOther]: '',
    [EducationSubject.DevelopmentApp]: '',
    [EducationSubject.DevelopmentArchitecture]: '',
    [EducationSubject.DevelopmentWeb]: '',
    [EducationSubject.DevelopmentOther]: '',
    [EducationSubject.DevelopmentDb]: '',
    [EducationSubject.DevelopmentTheory]: '',
    [EducationSubject.BusinessBrand]: '',
    [EducationSubject.BusinessEntrepreneur]: '',
    [EducationSubject.DesignGraphic]: '',
    [EducationSubject.Design3DAnimation]: '',
    [EducationSubject.DesignWeb]: '',
    [EducationSubject.DesignUx]: '',
    [EducationSubject.DesignOther]: '',
    [EducationSubject.FinanceAccounting]: '',
    [EducationSubject.FinanceCrypto]: '',
    [EducationSubject.FinanceStocks]: '',
    [EducationSubject.FinanceOther]: '',
    [EducationSubject.LanguageChinese]: '',
    [EducationSubject.LanguageEnglish]: '',
    [EducationSubject.LanguageFrench]: '',
    [EducationSubject.LanguageGerman]: '',
    [EducationSubject.LanguageJapanese]: '',
    [EducationSubject.LanguageSpanish]: '',
    [EducationSubject.LanguageTurkish]: '',
    [EducationSubject.LanguageOther]: '',
    [EducationSubject.MarketingContent]: '',
    [EducationSubject.MarketingInternet]: '',
    [EducationSubject.MarketingSeo]: '',
    [EducationSubject.MarketingSmm]: '',
    [EducationSubject.MarketingTrade]: '',
    [EducationSubject.MarketingOther]: '',
    [EducationSubject.MusicBasics]: '',
    [EducationSubject.MusicInstruments]: '',
    [EducationSubject.MusicProduction]: '',
    [EducationSubject.MusicSkillUp]: '',
    [EducationSubject.MusicSoftware]: '',
    [EducationSubject.MusicVocals]: '',
    [EducationSubject.MusicOther]: '',
    [EducationSubject.OgeBiology]: '',
    [EducationSubject.OgeChemistry]: '',
    [EducationSubject.OgeGeography]: '',
    [EducationSubject.OgeHistory]: '',
    [EducationSubject.OgeInformatics]: '',
    [EducationSubject.OgeLiterature]: '',
    [EducationSubject.OgeMath]: '',
    [EducationSubject.OgePhysics]: '',
    [EducationSubject.OgeRussian]: '',
    [EducationSubject.OgeSocial]: '',
    [EducationSubject.OgeOther]: '',
    [EducationSubject.PersonalBrand]: '',
    [EducationSubject.PersonalCareer]: '',
    [EducationSubject.PersonalLeadership]: '',
    [EducationSubject.PersonalMotivation]: '',
    [EducationSubject.PersonalRelationships]: '',
    [EducationSubject.PersonalOther]: '',
    [EducationSubject.Photo]: '',
    [EducationSubject.PhotoBasics]: '',
    [EducationSubject.VideoBasics]: '',
    [EducationSubject.VideoEditing]: '',
    [EducationSubject.VideoOther]: '',
    [EducationSubject.PhotoColorCorrection]: '',
    [EducationSubject.PhotoDigital]: '',
    [EducationSubject.PhotoPortrait]: '',
    [EducationSubject.PhotoOther]: '',
    [EducationSubject.PreschoolMentalArithmetic]: '',
    [EducationSubject.PreschoolPreparation]: '',
    [EducationSubject.PreschoolSpeedReading]: '',
    [EducationSubject.PreschoolOther]: '',
    [EducationSubject.SportWeightLoss]: '',
    [EducationSubject.SportBodyBuilding]: '',
    [EducationSubject.SportFitness]: '',
    [EducationSubject.SportOther]: '',
    [EducationSubject.HealthHealthyEating]: '',
    [EducationSubject.HealthBeauty]: '',
    [EducationSubject.HealthOther]: '',
    [EducationSubject.OtherOther]: '',
};

export const parseSubjectColor = (subject: EducationSubjectType, offset: number = 0) => {
    return UiService.parseTwColor(educationSubjectColors[subject].split(' ')[0], offset);
};
