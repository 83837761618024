/**
 * CardRequisiteRowSkeleton
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { ConfigStore, observer } from '@/store/core/config';

import { Skeleton } from '@mui/material';


const CardRequisiteRowSkeleton = observer(() => {
    return (
        <div className="flex items-center justify-between pt-2.5 pb-3 pr-4 pl-3">
            <div className="flex gap-3 items-center">
                <Skeleton width={36} height={36} className="before-item rounded" variant="rectangular"/>

                <div>
                    <Skeleton width={150} height={22} variant="text"/>
                    <Skeleton width={90} variant="text" height={ConfigStore.isDesktop ? 18.5 : 20}/>
                </div>
            </div>

            <Skeleton width={36} height={36} variant="circular" className="rounded"/>
        </div>
    );
});


export { CardRequisiteRowSkeleton };
