/**
 * CoursePriceCard styled container
 *
 * @author: exode <hello@exode.ru>
 */

import styled from 'styled-components';

import { responsive } from '@/styles/modules/responsive';


export const Container = styled.div<{ width?: string, height?: string }>`
  min-width: ${({ width }) => width || '200px'};
  min-height: ${({ height }) => height || '150px'};

  background-color: var(--background_content);
  border-radius: var(--vkui--size_border_radius_paper--regular);

  ${responsive([ 'mobile', 'tablet' ])} {
    margin: 0 1rem;

    body[scheme=space_gray] & {
      box-shadow: inset 0 0 0 var(--thin-border) var(--input_border);
    }
  }

  .vkuiSimpleCell--mult .vkuiSimpleCell__children {
    font-weight: 700;
  }

  .chip {
    .vkuiSimpleCell__main {
      padding: 0;

      .vkuiSimpleCell__children {
        font-size: 11px !important;
      }
    }
  }
`;
