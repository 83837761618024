/**
 * FileUpload HOC
 *
 * @author: exode <hello@exode.ru>
 */

import { FileUpload, FileUploadProps } from '@/components/Atoms/FileUpload/FileUpload';


export const useWithFileUpload = (props: FileUploadProps) => (children?: FileUploadProps['children']) => {
    return (
        <FileUpload {...props} children={children}/>
    );
};
