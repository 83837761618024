/**
 * SelectProfileCategorySelector
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { useGetUserState, useSetUserState } from '@/hooks/apollo';

import { UserStateKey } from '@/codegen/graphql';

import { CellTagSelectProps } from '@/components/Atoms/CellTagSelect';
import { CategoryTagSelector } from '@/components/Content/CategoryTagSelector';


interface Props extends Omit<CellTagSelectProps, 'selected' | 'options'> {
    onSaved?: () => void;
}


const SelectProfileCategorySelector = (props: Props) => {

    const { setUserState } = useSetUserState();
    const [ userCategories, userCategoriesLoading ] = useGetUserState(UserStateKey.ContentCategoryIds);

    return (
        <CategoryTagSelector {...props} selected={userCategories?.categoryIds}
                             dependsOnSelectedState={[ userCategoriesLoading ]}
                             loading={{
                                 isTransparent: props.appearance === 'onDark',
                             }}
                             onChange={(
                                 async (categoryIds, options) => {
                                     await setUserState(
                                         UserStateKey.ContentCategoryIds,
                                         {
                                             categoryIds,
                                             categorySlugs: options?.map((m) => m?.slug),
                                         },
                                     );

                                     props.onSaved?.();
                                 }
                             )}/>
    );
};


export { SelectProfileCategorySelector };
