/**
 * App localstorage subscriptions
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { LocalStorageSubscription } from './LocalStorageSubscription';


const AppLocalStorageSubscriptions = () => {
    return (
        <>
            <LocalStorageSubscription/>
        </>
    );
};


export { AppLocalStorageSubscriptions };
