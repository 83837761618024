/**
 * UseUpdateAvatar
 *
 * @author: exode <hello@exode.ru>
 */

import { Canceler } from 'axios';

import { useRef } from 'react';

import { StorageImageSize, StorageSpace, useProfileUpdatePhotoMutation } from '@/codegen/graphql';

import { SaveStoreKeys, SavingStore } from '@/store/core/saving';

import { Http } from '@/utils';
import { Notify } from '@/cutils';
import { setupFilesInfo, useI18n } from '@/hooks/core';
import { UploadService } from '@/services/Upload/Upload';


export const useUpdateAvatar = () => {

    const { t } = useI18n('hooks.apollo.user');

    const [
        updateProfilePhoto,
        { loading: updateAvatarLoading },
    ] = useProfileUpdatePhotoMutation({ onError: error => console.error(error) });

    const cancelFileUploadRef = useRef<Canceler | null>(null);

    const updateAvatar = async (
        profileId: number,
        file: File,
        uploadKey: string,
    ) => {
        const formData = new FormData();

        formData.append('space', StorageSpace.User);

        Http.appendFormData(formData, 'meta', await setupFilesInfo([ file ]));

        formData.append('files', file);

        const { success, exception, payload: [ avatar ] } = await UploadService.uploadFiles(
            uploadKey,
            formData,
            cancelFileUploadRef,
            undefined,
            [ StorageImageSize.Medium, StorageImageSize.Small ],
        );

        SavingStore.setSavingRecord(
            SaveStoreKeys.Uploading,
            uploadKey,
            false,
        );

        if (exception || !success) {
            Notify.vkui({
                appearance: 'error',
                message: t('unsuccessfulFileUpload'),
            });

            return;
        }

        await updateProfilePhoto({
            variables: {
                photo: {
                    avatar: {
                        small: avatar.imageSizes.small,
                        medium: avatar.imageSizes.medium,
                        maximum: avatar.location,
                    },
                },
            },
            update: (cache) => {
                cache.modify({
                    id: `ProfileEntity:${profileId}`,
                    fields: {
                        avatar: (value) => ({ ...value, medium: avatar.imageSizes.medium }),
                    },
                });
            },
        });
    };

    return { updateAvatar, updateAvatarLoading };
};
