/**
 * InviteFriendsView
 *
 * @author: exode <hello@exode.ru>
 */

import React, { useState } from 'react';

import {
    ListFriendsByStatusQueryResult,
    TournamentInvitationsDocument,
    TournamentInvitationsQuery,
    useSendInvitationMutation,
    useTournamentInvitationsQuery,
} from '@/codegen/graphql';

import { Counter, Header, List, Spinner } from '@exode.ru/vkui';
import { Icon24AddOutline, Icon24DoneOutline } from '@vkontakte/icons';

import { If } from '@/cutils';
import { GqlResult } from '@/types/graphql';

import { User } from '@/components/Atoms/User';
import { CustomScrollbar } from '@/components/Atoms/Styled';


interface Props {
    tournamentId: number;
    friends: GqlResult<ListFriendsByStatusQueryResult>['friendshipFindMany'];
    isContext?: boolean;
}


const InviteFriendsView = (props: Props) => {

    const { tournamentId, friends: { items, count }, isContext = false } = props;

    const [ inviteId, setInviteId ] = useState(0);

    const [ sendInvitation, { loading } ] = useSendInvitationMutation({
        onError: error => console.error(error),
    });

    const { data, loading: queryLoading } = useTournamentInvitationsQuery({
        variables: {
            tournamentId,
            list: { skip: 0, take: 20 },
        },
        onError: error => {
            console.error(error);
        },
    });

    const invitedUsers = data?.tournamentLobbyFindManyInvitations?.items?.map(({ to }) => to.id);
    const friends = isContext ? items?.slice(0, 5) : items;

    const invite = async (toUserId: number) => {
        setInviteId(toUserId);

        !invitedUsers?.includes(toUserId) && await sendInvitation({
            variables: { invitation: { tournamentId, toUserId } },
            update(cache, { data }) {
                const invitedUser = data?.tournamentLobbySendInvitation;

                const cachedInvitations = cache.readQuery<TournamentInvitationsQuery>({
                    query: TournamentInvitationsDocument,
                    variables: {
                        tournamentId,
                        list: { skip: 0, take: 20 },
                    },
                });

                if (!cachedInvitations?.tournamentLobbyFindManyInvitations) {
                    return console.warn('[Cache]: cachedInvitations отсутствуют в кэше');
                }

                const { items } = cachedInvitations?.tournamentLobbyFindManyInvitations;

                invitedUser && cache.writeQuery<TournamentInvitationsQuery>({
                    query: TournamentInvitationsDocument,
                    variables: {
                        tournamentId,
                        list: { skip: 0, take: 20 },
                    },
                    data: {
                        tournamentLobbyFindManyInvitations: {
                            items: [ invitedUser, ...(items || []) ],
                        },
                    },
                });
            },
            onError(error) {
                console.error(error);
            },
        });
    };

    const parseInvitationToIcon = (userId: number) => {
        return invitedUsers?.includes(userId)
            ? <Icon24DoneOutline/>
            : <Icon24AddOutline/>;
    };

    return (
        <If is={!!items?.length}>
            <Header mode="secondary" aside={<Counter size="m" mode="primary">{count}</Counter>}>
                Мои друзья
            </Header>

            <CustomScrollbar className="d:max-h-[200px] d:overflow-auto mt-2">
                <List>
                    {friends?.map(({ id, profile, lastOnlineAt }) => (
                        <User.Cell key={id}
                                   mode="simple"
                                   userId={id}
                                   profile={profile}
                                   lastOnlineAt={lastOnlineAt}
                                   onClick={() => invite(id)}
                                   disabled={queryLoading || loading}
                                   avatarProps={{ badgeBg: 'var(--modal_card_background)' }}
                                   after={(queryLoading || loading && id === inviteId)
                                       ? <Spinner size="regular" className="text-accent"/>
                                       : parseInvitationToIcon(id)}/>
                    ))}
                </List>
            </CustomScrollbar>
        </If>
    );
};


export { InviteFriendsView };
