/**
 * EmptyDiscountListView
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { useI18n } from '@/hooks/core';

import { Icon28DiscountOutline } from '@vkontakte/icons';

import { Placeholder } from '@/components/Atoms/Placeholder';


const EmptyDiscountListView = () => {

    const { t } = useI18n('pages.Manage.Product.Discount');

    return (
        <Placeholder iconSize={72}
                     header={t('promoCodesWasNotCreatedYet')}
                     className="my-auto m:pt-0 h-[calc(100%_-_52px)]"
                     icon={<Icon28DiscountOutline fill="var(--accent)"/>}
                     content={(
                         <span className="m:w-full w-9/10 flex mx-auto">
                             {t('createdPromoCodesWillAppearHere')}
                         </span>
                     )}/>
    );
};


export { EmptyDiscountListView };
