/**
 * MenuPart
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { useI18n } from '@/hooks/core';

import { NotificationCountByStatusQuery, NotificationStatus } from '@/codegen/graphql';

import { Auth, Graphql, If, Unauth } from '@/cutils';
import { NavigationTabBarItem } from '@/components/Navigation/Tab';

import { Counter, TabbarItem } from '@exode.ru/vkui';
import { Icon24ArrowRightSquareOutline, Icon24ServicesOutline } from '@vkontakte/icons';

import styles from '../NavigationTabBarItems.module.scss';


const MenuPart = () => {

    const { t } = useI18n('components.Navigation.Tab.BarItems.parts');

    const defaultMenuItem = (
        <NavigationTabBarItem tab="menu" page={{ id: '/menu' }}>
            <TabbarItem text={t('menu')}>
                <Icon24ServicesOutline/>
            </TabbarItem>
        </NavigationTabBarItem>
    );

    return (
        <>
            <Auth>
                <NotificationCountByStatusQuery children={(result) => (
                    <>
                        <Graphql.Loading result={result}>
                            {defaultMenuItem}
                        </Graphql.Loading>

                        <Graphql.Success result={result}>
                            {({ notificationCountByStatus: count }) => (
                                <NavigationTabBarItem tab="menu" page={{ id: '/menu' }}>
                                    <TabbarItem text={t('menu')} indicator={(
                                        <If is={!!count}>
                                            <Counter size="s" mode="prominent" className={styles.menuCounter}>
                                                {count > 9 ? '9+' : count}
                                            </Counter>
                                        </If>
                                    )}>
                                        <Icon24ServicesOutline/>
                                    </TabbarItem>
                                </NavigationTabBarItem>
                            )}
                        </Graphql.Success>

                        <Graphql.Error result={result}>
                            {defaultMenuItem}
                        </Graphql.Error>
                    </>
                )} variables={{ notificationStatus: NotificationStatus.Unread }}/>

            </Auth>
            <Unauth>
                <NavigationTabBarItem tab="menu" page={{ id: '/login' }}>
                    <TabbarItem text={t('login')}>
                        <Icon24ArrowRightSquareOutline/>
                    </TabbarItem>
                </NavigationTabBarItem>
            </Unauth>
        </>
    );
};


export { MenuPart };
