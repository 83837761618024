/**
 * ChatIndicatorPart
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { If } from '@/cutils';

import { Counter, CounterProps } from '@exode.ru/vkui';

import { useChatUnread } from '@/hooks/apollo';


interface Props {
    counterProps?: CounterProps;
}


const ChatIndicatorPart = (props: Props) => {

    const { counterProps } = props;

    const { unreadCountChat } = useChatUnread('All');

    return (
        <If is={!!unreadCountChat}>
            <Counter mode="primary" size="s" {...counterProps}>
                {unreadCountChat}
            </Counter>
        </If>
    );
};


export { ChatIndicatorPart };
