/**
 * NavigationTabBarItem component
 *
 * @author: exode <hello@exode.ru>
 */

import React, { ReactComponentElement } from 'react';

import { observer, RouterStore } from '@/store/core/router';
import { UserAuthStore } from '@/store/user/auth';

import { TabBarItemsType, TabsType } from '@/types/router';

import { UserRbacService } from '@/services/User/Rbac';
import { PageAction } from '@/services/Utils/Router';

import { Routes } from '@/router/routes';
import { TabItemViewsGroup } from '@/router/index';

import { Link } from '@/cutils';


interface Props {
    tab: TabBarItemsType;
    children: ReactComponentElement<any>;
    page: PageAction;
}


const NavigationTabBarItem = observer((props: Props) => {

    let { page } = props;

    const { children, tab } = props;
    const tabViews = TabItemViewsGroup[tab];

    const selected = tabViews.includes(RouterStore.viewId as TabsType);

    if (RouterStore.tabsInfo[tab].activePage && RouterStore.tab !== tab) {
        const tabPage = RouterStore.tabsInfo[tab].activePage as PageAction;

        const { auth, unauth } = Routes[tabPage.id] || {};

        const { isLoggedIn } = UserAuthStore;

        const authAccess = !auth && !unauth || unauth && !isLoggedIn || auth && isLoggedIn;
        const permissionAccess = !auth || UserRbacService.canAny(UserAuthStore.permissions, ...auth);

        if (authAccess && permissionAccess) {
            page = tabPage;
        }

        if (typeof page === 'undefined') {
            return <></>;
        }
    }

    return (
        <Link pushPage={page}>
            {React.cloneElement(children, { selected })}
        </Link>
    );
});


export { NavigationTabBarItem };
