/**
 * Filter useSchoolManageDisciplinesFilter
 *
 * @author: exode <hello@exode.ru>
 */

import { useUrlFilters } from '@/hooks/core';

import { StoreCommonType } from '@/types/store';

import { ContentCategorySpace } from '@/codegen/graphql';


export const defaultFilter = {
    search: '',
    spaces: [] as ContentCategorySpace[],
    slugs: [] as string[],
    visible: null as unknown as boolean,
};

export const dtoFilterTransformer = (filter: typeof defaultFilter) => filter;

export const useSchoolManageDisciplinesFilter = (
    store: StoreCommonType,
    filter: Partial<typeof defaultFilter>,
) => useUrlFilters(
    { ...defaultFilter, ...filter },
    [],
    dtoFilterTransformer,
    { store },
);
