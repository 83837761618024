/**
 * ModalStore
 *
 * @author: exode <hello@exode.ru>
 */

import { makeObservable } from 'mobx';
import { observer } from 'mobx-react';

import { PageStore } from '@/pages/Core';


class ModalStore extends PageStore {

    constructor() {
        super();
        makeObservable(this);
    }

}

export { observer, ModalStore };