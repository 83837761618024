/**
 * CategoryTagSelector
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { useI18n } from '@/hooks/core';

import { Graphql } from '@/cutils';

import { ContentCategoryFindManyQuery, ContentCategorySpace } from '@/codegen/graphql';

import { CategoryTagSkeleton } from '@/components/Atoms/Skeleton/CategoryTag';
import { SimpleCellWrapper } from '@/components/Atoms/CellTagSelect/CellTagSelect.styled';
import { CellTagItemPart, CellTagSelect, CellTagSelectProps } from '@/components/Atoms/CellTagSelect';


interface Props extends Omit<CellTagSelectProps, 'selected' | 'options'> {
    selected: (string | number)[] | undefined | null;
    spaces?: ContentCategorySpace[];
    cellTagItemProps?: CellTagSelectProps['itemProps'];
    loading?: {
        className?: string;
        isTransparent?: boolean;
    };
}


const CategoryTagSelector = (props: Props) => {

    const {
        selected,
        onChange,
        cellTagItemProps,
        loading = {},
        spaces = [ ContentCategorySpace.Course ],
        ...rest
    } = props;

    const { t } = useI18n('components.Content.CategoryTagSelector');

    return (
        <ContentCategoryFindManyQuery children={(result) => (
            <>
                <Graphql.Loading result={result}>
                    <SimpleCellWrapper className="simple-cell-wrapper">
                        <CellTagItemPart value=""
                                         label={t('loading')}
                                         itemProps={{ hasActive: false, ...cellTagItemProps }}/>
                        <CategoryTagSkeleton/>
                    </SimpleCellWrapper>
                </Graphql.Loading>

                <Graphql.Success result={result}>
                    {({ contentCategoryFindMany: { items } }) => (
                        <CellTagSelect onChange={onChange}
                                       selected={selected || []}
                                       itemProps={{ hasActive: false, ...cellTagItemProps }}
                                       options={items?.map(({ id, name, slug }) => ({
                                           value: id,
                                           label: `${name}`,
                                           meta: { id, name, slug },
                                       }))}
                                       {...rest}/>
                    )}
                </Graphql.Success>

                <Graphql.Error result={result}/>
            </>
        )} variables={{ list: {}, filter: { spaces } }}/>
    );
};


export { CategoryTagSelector };
