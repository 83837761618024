/**
 * ButtonPart
 *
 * @author: exode <hello@exode.ru>
 */

import React, { useState } from 'react';

import { useHotkeys } from 'react-hotkeys-hook';

import { useI18n } from '@/hooks/core';

import { useStore } from '@/pages/Core';
import { PhotoEditorModalStore } from '../store';
import { ConfigStore, observer } from '@/store/core/config';

import { Router } from '@/services/Utils/Router';

import { Button, ButtonGroup } from '@exode.ru/vkui';


interface Props {
    handleSubmit: () => void;
}


const ButtonPart = observer((props: Props) => {

    const { store } = useStore(PhotoEditorModalStore);

    const { handleSubmit } = props;

    const { t } = useI18n('modals.Utils.PhotoEditor.part');

    const { onCancel } = store.photoEditorProps || {};

    const [ isLoading, setIsLoading ] = useState(false);
    const [ isDisabled, setIsDisabled ] = useState(false);

    const handleDoneClick = () => {
        if (!isDisabled) {
            setIsLoading(true);
            setIsDisabled(true);

            handleSubmit();
        }
    };

    const handleCancelClick = () => {
        if (!isDisabled) {
            setIsDisabled(true);

            onCancel?.();
            Router.replaceModal();
        }
    };

    useHotkeys('enter', (e) => {
        e.stopPropagation();
        e.preventDefault();

        handleDoneClick();
    });

    useHotkeys('esc', (e) => {
        e.stopPropagation();
        e.preventDefault();

        handleCancelClick();
    });

    return (
        <div className="px-4 py-[0.925rem] m-auto d:w-[425px] max-w-full">
            <ButtonGroup stretched mode={ConfigStore.isDesktop ? 'horizontal' : 'vertical'}>
                <Button stretched
                        size="l"
                        disabled={isDisabled}
                        mode="overlay_secondary"
                        onClick={handleCancelClick}
                        data-test="photo-edit.cancel">
                    {t('cancel')}
                </Button>

                <Button stretched
                        size="l"
                        mode="secondary"
                        loading={isLoading}
                        disabled={isDisabled}
                        onClick={handleDoneClick}
                        data-test="photo-edit.done">
                    <span className="text-accent dark:!text-white">
                        {t('ready')}
                    </span>
                </Button>
            </ButtonGroup>
        </div>
    );
});


export { ButtonPart };
