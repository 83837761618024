/**
 * PayoutOperationPart
 *
 * @author: exode <hello@exode.ru>
 */

import React from 'react';

import { Time } from '@/utils';
import { If, Link } from '@/cutils';
import { useI18n } from '@/hooks/core';

import { PayoutException } from '@/codegen/graphql';

import { SellerBalanceOperationItem } from '@/types/seller';
import { payoutOperationExceptionText, payoutOperationInitiationTypeText, payoutOperationStatus } from '@/types/payout';

import { Group, Header, InfoRow, SimpleCell } from '@exode.ru/vkui';

import { AmountText } from '@/components/Atoms/AmountText';


interface Props {
    payoutOperations: SellerBalanceOperationItem['payoutOperations'];
}


const PayoutOperationPart = (props: Props) => {

    const { payoutOperations } = props;

    const payoutOperationStatuses = payoutOperationStatus();
    const payoutOperationExceptionTexts = payoutOperationExceptionText();
    const payoutOperationInitiationTypeTexts = payoutOperationInitiationTypeText();

    const { t } = useI18n('components.Seller.Balance.OperationDetails');

    return (
        <>
            <Header className="pb-0">
                {t('payoutInformation')}
            </Header>

            <Group className="pt-0">
                {payoutOperations.map((payout, index) => {
                    const {
                        id,
                        status,
                        user,
                        currency,
                        exception,
                        createdAt,
                        initiationType,
                        transferAmount,
                        transactionAmount,
                    } = payout;

                    return (
                        <div key={index}>
                            <SimpleCell hasHover={false} hasActive={false}>
                                <InfoRow header={t('payoutOperationId')}>
                                    #{id}
                                </InfoRow>
                            </SimpleCell>

                            <SimpleCell hasHover={false} hasActive={false}>
                                <InfoRow header={t('payoutInitiated')}>
                                    {(
                                        user
                                            ? (
                                                <Link pushPage={{
                                                    id: '/@:userId([0-9_A-Za-z]+)',
                                                    params: { userId: `${user?.id}` },
                                                }}>
                                                    <span className="text-accent">
                                                        {user.profile.fullNameShort}
                                                    </span>
                                                </Link>
                                            )
                                            : payoutOperationInitiationTypeTexts[initiationType]
                                    )}
                                    {' • '}
                                    <span className="text-secondary">
                                        {Time.parseRelative(createdAt).toLowerCase()}
                                    </span>
                                </InfoRow>
                            </SimpleCell>

                            <SimpleCell hasHover={false} hasActive={false}>
                                <InfoRow header={t('payoutStatus')}>
                                    {payoutOperationStatuses[status]}
                                </InfoRow>
                            </SimpleCell>

                            <If is={!!exception}>
                                <SimpleCell hasHover={false} hasActive={false}>
                                    <InfoRow header={t('errorStatus')}>
                                        {payoutOperationExceptionTexts[exception as PayoutException]}
                                    </InfoRow>
                                </SimpleCell>
                            </If>

                            <SimpleCell hasHover={false} hasActive={false}>
                                <InfoRow header={t('transferAmount')}>
                                    <AmountText toFixed={2}
                                                semibold={false}
                                                currency={currency}
                                                amount={transferAmount}/>
                                </InfoRow>
                            </SimpleCell>

                            <SimpleCell hasHover={false} hasActive={false}>
                                <InfoRow header={t('bankCommission')}>
                                    <AmountText toFixed={2}
                                                semibold={false}
                                                currency={currency}
                                                amount={transactionAmount}/>
                                </InfoRow>
                            </SimpleCell>
                        </div>
                    );
                })}
            </Group>
        </>
    );
};


export { PayoutOperationPart };
