/**
 * InlineInfoCaptionText
 *
 * @author: exode <hello@exode.ru>
 */

import React, { ReactElement, ReactNode } from 'react';

import { Caption } from '@exode.ru/vkui';
import { Icon16StarCircleFillYellow } from '@vkontakte/icons';


interface Props {
    text: ReactNode;
    icon?: ReactElement;
    className?: string;
    alignment?: 'left' | 'center';
    isCardHeader?: boolean;
}


const InlineInfoCaptionText = (props: Props) => {

    const {
        text,
        className,
        isCardHeader,
        alignment = 'center',
        icon = <Icon16StarCircleFillYellow fill="var(--dynamic_blue)" className="min-w-[16px] m:hidden"/>,
    } = props;

    return (
        <Caption className={[
            className,
            'flex gap-2 text-secondary items-center',
            isCardHeader ? 'uppercase gap-2 fs-11 font-semibold' : '',
            alignment === 'center' ? 'w-fit mx-auto justify-center text-center' : '',
        ].join(' ')}>
            {icon}

            <span>{text}</span>
        </Caption>
    );
};


export { InlineInfoCaptionText };
